import { GetterTree } from 'vuex'
import { ReportsState, Report, DashboardReport } from '@/store/modules/reports/reportsTypes'
import { RootState } from '@/store/types/storeGlobalTypes'
import { formatFieldsTypeDateEPOCH } from '@/helpers/dateTime'

export const getters: GetterTree<ReportsState, RootState> = {
  getAllReports(state): Report[] | null {
    return state.reports
  },
  getReport(state): Report | null {
    return state.report
  },
  getReportData(state): [] | null {
    return state.reportData
  },
  getDashboardReportsList(state): DashboardReport[] {
    return state.dashboardReportsList
  },
  getTotalNumberOfReports(state): string {
    return state.total
  },
  getReportsListWithFormattedDates(state) {
    return formatFieldsTypeDateEPOCH(state.reports)
  },
  getFirstLoad(state) {
    return state.firstLoad
  },
  getSelectedReportList(state) {
    return state.selectedReportList
  }
}
