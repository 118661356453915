import { Module } from 'vuex'
import { getters } from '@/store/modules/appointments/appointmentsGetters'
import { actions } from '@/store/modules/appointments/appointmentsActions'
import { mutations } from '@/store/modules/appointments/appointmentsMutations'
import { AppointmentsState } from '@/store/modules/appointments/appointmentsTypes'
import { RootState } from '@/store/types/storeGlobalTypes'

export const state: AppointmentsState = {
  appointment: [],
  calendarAppointments: [],
  controlSidebarAlertsObject: {
    data: [],
    pagination: {
      numPage: 0,
      totalRecs: ''
    }
  },
  expedientGlobalVisionWidgetAppointments: null
}

const namespaced: boolean = true

export const appointmentsModule: Module<AppointmentsState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations
}
