<template lang="pug">

  WidgetBaseComponent(
    class="expedient-profitability-widget"
    :headerIcon="icons.headerIcon"
    :loadedData="renderWidget"
  )
    //- HEADER
    template(v-slot:header)
      h2 {{ title }}

    //- BODY
    template(v-slot:body)
      section(class="items-container")
        div(class="item")
          span(class="item-label") {{ body.costs.label }}:
          span(class="item-data") {{ body.costs.data }} €
        div(class="item")
          span(class="item-label") {{ body.income.label }}:
          div(class="item-data") {{ body.income.data }} €

    //- FOOTER
    template(v-slot:footer)
      div(class="footer-icons-container")
        div(
            v-if="userHasPermissionToViewCustomerInvoices"
            @click="launchDialog"
            class="details-container"
          )
          span(:class="[icons.visibleIcon, 'lf-icon']")
          span {{ texts.details }}
        div(class="footer-data")
          label {{ footer.label }}:
          ExpedientProfitabilityWarningTooltipComponent(:itemData="footer.data")


</template>

<script lang="ts">
import { Component, Mixins } from 'vue-property-decorator'
import WidgetBaseComponent from '@/components/widgets/WidgetBaseComponent.vue'
import { Icons } from '@/icons/icons'
import { Action, Getter, Mutation } from 'vuex-class'
import { ContextName, ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import { CustomDialogComponentName, CustomDialogData } from '@/store/modules/dialog/dialogTypes'
import { Endpoint } from '@/store/modules/endpoint/endpointTypes'
import { Entity } from '@/store/modules/entities/entitiesTypes'
import ExpedientProfitabilityWarningTooltipComponent from '@/components/expedients/ExpedientGlobalVision/ExpedientProfitabilityWarningTooltipComponent.vue'
import { ProfitabilityDetail } from '@/store/modules/expedients/expedientsTypes'
import PermissionsMixin from '@/mixins/PermissionsMixin.vue'

const dialogModule: string = ModuleNamespaces.DIALOG
const endpointModule = ModuleNamespaces.ENDPOINT
const entitiesModule = ModuleNamespaces.ENTITIES
const selectedRegisterModule = ModuleNamespaces.SELECTED_REGISTER
const expedientsModule = ModuleNamespaces.EXPEDIENTS

@Component({
  components: {
    ExpedientProfitabilityWarningTooltipComponent,
    WidgetBaseComponent
  }
})
export default class ExpedientProfitabilityWidget extends Mixins(PermissionsMixin) {
  @Action('fetchSelectedRegisterData', { namespace: selectedRegisterModule })
  fetchSelectedRegisterDataAction: ({}) => Promise<{}>

  @Action('showCustomDialog', { namespace: dialogModule })
  showCustomDialog: ({}: CustomDialogData) => {}

  @Action('fetchExpedientProfitabilityDetail', { namespace: expedientsModule })
  fetchExpedientProfitabilityDetailAction: (id: number) => Promise<any>

  @Mutation('REMOVE_SELECTED_REGISTER_DATA_CONTEXT', { namespace: selectedRegisterModule })
  removeSelectedRegisterDataContextMutation: (context: string) => void

  @Mutation('RESET_EXPEDIENT_PROFITABILITY_DETAIL', { namespace: expedientsModule })
  resetExpedientProfitabilityDetail: () => void

  @Getter('getSelectedRegisterData', { namespace: selectedRegisterModule })
  selectedRegisterDataGetter: (context: string) => {}

  @Getter('getSelectedRegisterId', { namespace: selectedRegisterModule })
  selectedRegisterIdGetter: (context: string) => number

  @Getter('getEndpoints', { namespace: endpointModule })
  endpoints: (entityType: number) => Endpoint

  @Getter('getEntity', { namespace: entitiesModule })
  entity: (context: string) => Entity

  @Getter('getExpedientProfitabilityDetail', { namespace: expedientsModule })
  expedientProfitabilityDetailGetter: ProfitabilityDetail

  icons = {
    headerIcon: Icons.HAND_SPHERE,
    tooltip: Icons.WARNING,
    visibleIcon: Icons.VISIBLE
  }

  texts = {
    details: this.$t('components.widgets.global_vision_profitability.details'),
    tooltip: this.$t('components.tooltip.profitability_global_vision_warning')
  }

  contexts = {
    profitabilityContext: ContextName.EXPEDIENT_GLOBAL_VISION_PROFITABILITY,
    expedientContext: ContextName.EXPEDIENTS
  }

  get renderWidget(): boolean {
    return !!this.selectedExpedientProfitabilityInfo
  }

  get selectedExpedientId(): number {
    return this.selectedRegisterIdGetter(this.contexts.expedientContext)
  }

  get selectedExpedientProfitabilityInfo() {
    return (this as any).selectedRegisterDataGetter(this.contexts.profitabilityContext).globalVisionExpProfitability
  }

  get title() {
    return this.selectedExpedientProfitabilityInfo.title
  }

  get body() {
    const body = this.selectedExpedientProfitabilityInfo.body
    return {
      costs: body[0],
      income: body[1]
    }
  }

  get footer() {
    return this.selectedExpedientProfitabilityInfo.footer[0]
  }

  async created() {
    await this.fetchExpedientProfitabilityDetailAction(this.selectedExpedientId)
    await this.fetchGlobalVisionProfitabilityInfo()
  }

  beforeDestroy() {
    this.removeSelectedRegisterDataContextMutation(this.contexts.profitabilityContext)
    this.resetExpedientProfitabilityDetail()
  }

  async fetchGlobalVisionProfitabilityInfo() {
    const endpoint: string = this.endpoints(this.entity(this.contexts.expedientContext).type).get

    await this.fetchSelectedRegisterDataAction({
      endpoint,
      context: this.contexts.profitabilityContext,
      post: {
        idExpedient: this.selectedExpedientId,
        source: this.contexts.profitabilityContext
      }
    })
  }

  launchDialog() {
    this.showCustomDialog({
      name: CustomDialogComponentName.GLOBAL_VISION_PROFITABILITY,
      styles: {
        maxWidth: '750px',
        minHeight: '200px'
      }
    })
  }
}
</script>

<style lang="scss" scoped>
.expedient-profitability-widget {
  .items-container {
    @include flex($flex-direction: column, $justify-content: center);
    width: 100%;
    height: calc(100% - 40px);
    padding: 0 1em;

    .item {
      @include flex($justify-content: flex-start);
      width: 100%;
      margin-bottom: 8%;

      .item-label {
        min-width: 70px;
        font-size: 14px;
        font-weight: bold;
        color: $corporate-color;
        padding-bottom: 6.8px;
      }

      .item-data {
        @include flex($justify-content: space-between);
        @include border;
        width: 100%;
        min-height: 27px;
        font-size: 14px;
      }
    }
  }

  .footer-icons-container {
    @include flex($justify-content: space-between);
    width: 100%;
    padding-right: 10px;

    .details-container {
      @include flex;
      font-size: 14px;
      margin-left: 2%;
      text-transform: capitalize;
      cursor: pointer;

      .lf-icon {
        font-size: 20px;
        margin-right: 6px;
      }
    }

    .footer-data {
      font-family: $corporate-font-bold;
      width: 100%;
      text-align: end;
      margin-left: 8px;
    }
  }
}
</style>
