<template lang="pug">

  section(class="events-container element-with-scroll")
    EventItemComponent(
      v-for="(event, eventIndex) in events"
      :key="eventIndex"
      :event="event"
      @selectedEvent="onSelectedEvent"
    )

    //- EDIT APPOINTMENT DIALOG
    PromptDialogComponent(
      :title="dialogTitle"
      :isOpened="showDialog"
      :context="context"
      :showActionButtons="false"
      :width="700"
      @execute-action="hideDialog"
    )
      template(v-slot:body)
        div(class="dialog-body")
          iframe(
            v-if="url"
            :src="url"
            frameborder="0"
            allowfullscreen
          )
          SpinnerLayerComponent(v-else)

</template>

<script lang="ts">
import { Component, Prop, Watch, Vue } from 'vue-property-decorator'
import { DialogTypes } from '@/store/modules/dialog/dialogTypes'
import { Action, Getter } from 'vuex-class'
import { ContextName, ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import EventItemComponent from '@/components/events/EventItem/EventItemComponent.vue'
import { NextEventsWidgetEvent } from '@/components/widgets/ExpedientWidgets/ExpedientNextEvents/ExpedientNextEventsWidgetTypes'
import SpinnerLayerComponent from '@/components/Spinner/SpinnerLayerComponent.vue'
import PromptDialogComponent from '@/components/Dialog/PromptDialogComponent.vue'
import { TranslateResult } from 'vue-i18n'
import { removeBodyScroll, resetBodyScroll } from '@/helpers/html'

const appointmetsModule = ModuleNamespaces.APPOINTMENTS
const authModule = ModuleNamespaces.AUTH
const dialogModule = ModuleNamespaces.DIALOG
const lexboxModule = ModuleNamespaces.LEXBOX

@Component({
  components: {
    EventItemComponent,
    SpinnerLayerComponent,
    PromptDialogComponent
  }
})
export default class EventContainerComponent extends Vue {
  @Prop({
    type: Array,
    required: true
  })
  events!: []

  @Getter('getUserName', { namespace: authModule })
  userName: string
  @Getter('getNavisionId', { namespace: authModule })
  navisionId: number
  @Getter('getUserId', { namespace: authModule })
  userId: number
  @Getter('getAccessToken', { namespace: lexboxModule })
  accessToken: string
  @Getter('getProUserId', { namespace: authModule })
  idProUser: string
  @Getter('getEventCalendarServiceUrl', { namespace: lexboxModule })
  eventServiceUrl: string
  @Getter('getCompanyId', { namespace: authModule })
  selectedCompanyId: number

  @Action('showDialog', { namespace: dialogModule })
  showDialogInfo: ({}) => {}
  @Action('deleteAppointment', { namespace: appointmetsModule })
  deleteAppointment: (id: string) => void
  @Action('fetchCalendarServiceUrl', { namespace: lexboxModule })
  fetchServiceUrl: (calendarQuery: object) => void
  @Action('fetchAccessToken', { namespace: lexboxModule })
  fetchAccessToken: (calendarQuery: object) => void

  dialogTitle: TranslateResult = this.$t('components.schedule.edit_event')

  context: string = ContextName.EXPEDIENT_SCHEDULE_WIDGET

  selectedEvent: NextEventsWidgetEvent | null = null

  showDialog: boolean = false

  url: string = ''

  @Watch('eventServiceUrl')
  getUrl(value: string) {
    this.url = value
  }

  async created() {
    window.addEventListener('message', this.eventReceived)
  }

  eventReceived = (event: any) => {
    try {
      const data = JSON.parse(event.data)
      if (event.data && data.actionCancelled) {
        if (data.removed === true) {
          this.deleteEvent()
        } else if (data.message === 'Not Found') {
          this.showDialogInfo({
            type: DialogTypes.INFO,
            message: this.$t('components.dialog.event_not_found_in_calendar'),
            action: this.deleteEvent
          })
        } else if (data.message === 'Not Account') {
          this.showDialogInfo({
            type: DialogTypes.ERROR,
            message: this.$t('components.dialog.event_not_account')
          })
        } else if (data.message === 'Not Permission') {
          this.showDialogInfo({
            type: DialogTypes.ERROR,
            message: this.$t('components.dialog.event_not_permission')
          })
        }
        this.hideDialog()
      }
    } catch (error) {}
  }

  destroyed() {
    window.removeEventListener('message', this.eventReceived)
  }

  onSelectedEvent(event: NextEventsWidgetEvent) {
    this.selectedEvent = event
    this.loadServiceUrl()
    this.openDialog()
  }

  async loadServiceUrl() {
    const { idAction, idEvent, iCalUId, idCalendar, calendarName, subject, provider } = this
      .selectedEvent as NextEventsWidgetEvent

    if (this.accessToken === '') {
      const accessParams = {
        idClienteNavision: this.idProUser,
        idCompany: this.selectedCompanyId
      }
      await this.fetchAccessToken(accessParams)
    }

    const calendarParams = {
      query: {
        idActuation: idAction,
        idEvent,
        idCalendar,
        iCalUId,
        calendarName,
        title: subject,
        idClienteLef: this.navisionId,
        idUserApp: this.userId,
        name: this.userName
      },
      provider
    }

    await this.fetchServiceUrl(calendarParams)
  }

  openDialog() {
    this.showDialog = true
    removeBodyScroll()
  }

  hideDialog() {
    this.showDialog = false
    this.url = ''
    resetBodyScroll()
    this.$emit('fetchEvents')
  }

  checkIfHideDialog($event: Event) {
    const elementClicked = $event.target as HTMLElement

    if (elementClicked.classList.contains('dialog-container')) {
      this.hideDialog()
    }
  }

  async deleteEvent() {
    await this.deleteAppointment((this as any).selectedEvent.id)
    this.hideDialog()
  }
}
</script>

<style lang="scss" scoped>
.events-container {
  @include scroll-styles;
  width: 100%;
  padding: 0 1em;
  margin-top: 5%;
  padding-bottom: 10px;
}

.dialog-body {
  @include flex;
  height: 600px;

  iframe {
    width: 698px;
    height: 600px;
  }
}
</style>
