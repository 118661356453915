import { GetterTree } from 'vuex'
import {
  AppointmentsState,
  CalendarAppointment,
  CalendarAppointmentFormatted,
  ControlSidebarAlertsObject
} from '@/store/modules/appointments/appointmentsTypes'
import { RootState } from '@/store/types/storeGlobalTypes'
import { NextEventsWidgetData } from '@/components/widgets/ExpedientWidgets/ExpedientNextEvents/ExpedientNextEventsWidgetTypes'

export const getters: GetterTree<AppointmentsState, RootState> = {
  getAppointment(state): [] {
    return state.appointment
  },
  getCalendarAppointments(state): CalendarAppointment[] {
    return state.calendarAppointments
  },
  getCalendarAppointmentsFormatted(state): CalendarAppointmentFormatted[] {
    const dashboardAppointmentsFormatted = state.calendarAppointments.map(
      ({ id, subject, start, end, color, name, typeAppointment }) => {
        return {
          id,
          subject,
          start,
          end,
          color,
          title: name,
          type: typeAppointment
        }
      }
    )
    return dashboardAppointmentsFormatted
  },
  getControlSidebarAlertsObject(state): ControlSidebarAlertsObject {
    return state.controlSidebarAlertsObject
  },
  getExpedientGlobalVisionAppointments(state): NextEventsWidgetData | null {
    return state.expedientGlobalVisionWidgetAppointments
  }
}
