export interface Audit {
  action: string
  dateEPOCH: number
  id: number
  idUser: number
  responsible: string
  section: string
}

export interface AuditsState {
  audits: Audit[] | null
}

export const auditsMutationTypes = {
  FETCH_AUDITS: 'FETCH_AUDITS'
}
