import { ActionTree } from 'vuex'
import { SearchState, searchMutationTypes } from '@/store/modules/search/searchTypes'
import { RootState } from '@/store/types/storeGlobalTypes'
import { MainService } from '@/services/MainService'

export const actions: ActionTree<SearchState, RootState> = {
  async fetchSearchData({ commit, getters }, term) {
    try {
      if (term !== getters.getSearchTerm) {
        term = encodeURIComponent(term.trim().replace(/[\\\'\"]/g, ''))
        const url = `/search/get?term=${term}`
        const { data } = await new MainService().getData(url)
        commit(searchMutationTypes.SET_SEARCH_DATA, data)
        commit(searchMutationTypes.SET_SEARCH_TERM, term)
      }
    } catch (error) {
      commit(searchMutationTypes.RESET_SEARCH_DATA)
    }
  }
}
