import { GetterTree } from 'vuex'
import { ActionsState } from '@/store/modules/actions/actionsTypes'
import { RootState } from '@/store/types/storeGlobalTypes'

export const getters: GetterTree<ActionsState, RootState> = {
  getAllActions(state) {
    return state.actions
  },
  getAction(state) {
    return state.action
  },
  getSelectedActionType(state) {
    return state.selectedActionType
  },
  getChronometerNewActionInfo(state) {
    return state.chronometerNewActionInfo
  }
}
