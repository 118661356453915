export interface ErrorsState {
  error: string | null
  endpointErrorFlag: boolean
}

export const errorsMutationTypes = {
  SET_ERROR: 'SET_ERROR',
  CLEAR_ERROR: 'CLEAR_ERROR',
  EXISTS_ENDPOINT_ERROR: 'EXISTS_ENDPOINT_ERROR',
  CLEAR_ENDPOINT_ERROR: 'CLEAR_ENDPOINT_ERROR'
}

export enum ErrorCodes {
  E400 = 400
}
