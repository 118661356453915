import { render, staticRenderFns } from "./InvoiceConfigurationComponent.vue?vue&type=template&id=28fb2d01&scoped=true&lang=pug"
import script from "./InvoiceConfigurationComponent.vue?vue&type=script&lang=ts"
export * from "./InvoiceConfigurationComponent.vue?vue&type=script&lang=ts"
import style0 from "./InvoiceConfigurationComponent.vue?vue&type=style&index=0&id=28fb2d01&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "28fb2d01",
  null
  
)

export default component.exports