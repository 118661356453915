import { MutationTree } from 'vuex'
import { MaintenanceState } from '@/store/modules/maintenance/maintenanceTypes'

export const mutations: MutationTree<MaintenanceState> = {
  FETCH_MAINTENANCE(state, maintenance) {
    state.maintenance = maintenance
  },
  FETCH_DASHBOARD_MAINTENANCE(state, maintenance) {
    state.dashboardMaintenanceList = maintenance
  },
  FETCH_TOTAL_NUMBER_OF_MAINTENANCE(state, total) {
    state.total = total
  },
  FETCH_MAINTENANCE_STAGE_STATE(state, data) {
    state.stageStatus = data
  },
  FETCH_MAINTENANCE_SUMMARY(state, summary) {
    state.summary = summary
  },
  RESET_MAINTENANCE_SUMMARY(state) {
    state.summary = []
  },
  FETCH_MAINTENANCE_ECONOMIC_DATA(state, summary) {
    state.summary = summary
  },
  FETCH_MAINTENANCE_ACTIONS_SUMMARY(state, summary) {
    state.actionsSummary = summary
  },
  RESET_MAINTENANCE_ACTIONS_SUMMARY(state) {
    state.actionsSummary = []
  },
  LAST_MAINTENANCE_SAVED(state, data) {
    state.lastMaintenanceSaved = data
  },
  SET_TAXES_ITEMS(state, items) {
    state.taxesItems = items
  },
  RESET_TAXES_ITEMS(state) {
    state.taxesItems = []
  },
  SET_ECONOMIC_DATA_SIMPLE_GRID_ITEMS(state, items) {
    state.economicDataSimpleGridItems = items
  },
  SET_SELECTED_MAINTENANCE_INSURER(state, selectedInsurer) {
    state.selectedMaintenanceInsurer = selectedInsurer
  },
  RESET_SELECTED_MAINTENANCE_INSURER(state) {
    state.selectedMaintenanceInsurer = {}
  }
}
