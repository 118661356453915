<script lang="ts">
import { Vue, Component } from 'vue-property-decorator'
import { Icons } from '@/icons/icons'
import { printInvoice } from '@/helpers/invoice'
import { ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import { Action, Getter } from 'vuex-class'
import { DialogTypes } from '@/store/modules/dialog/dialogTypes'
import { ITemplate } from '@/store/modules/template/templateTypes'
import { ComponentWhereIsRendered } from '@/store/modules/alerts/alertsTypes'

const authModule = ModuleNamespaces.AUTH
const dialogModule: string = ModuleNamespaces.DIALOG
const invoicesModule: string = ModuleNamespaces.INVOICES
const templateModule: string = ModuleNamespaces.TEMPLATE

@Component
export default class PrintInvoiceContextMenuMixin extends Vue {
  @Action('showDialog', { namespace: dialogModule })
  showDialogAction: ({}) => void

  @Action('printPdfInvoice', { namespace: invoicesModule })
  printInvoiceAction: ({}) => Promise<void>

  @Getter('getTemplateTree', { namespace: templateModule })
  tree: ITemplate[]

  @Getter('getIsPortalUser', { namespace: authModule })
  isPortalUser: boolean

  showTemplateDialog = false

  get printButton() {
    return {
      iconCss: this.isPortalUser ? Icons.OPEN_WINDOW : Icons.PRINT,
      text: this.isPortalUser ? this.$t('components.context_menu.open_window') : this.$t('action_buttons.print')
    }
  }

  getPrintContextMenuItems(items: any) {
    return [...items.slice(0, 2), this.printButton, ...items.slice(2)]
  }

  checkIfOnlyOneInvoice(
    idEntity: number | string,
    idEntityType: number | string,
    whereRenderAlert: ComponentWhereIsRendered | null = null
  ): boolean {
    if ((this.tree && this.tree.length === 0) || this.tree.length > 1) {
      return true
    }
    const template: ITemplate = this.tree[0]
    if (!template.isDirectory) {
      this.printInvoice(template, idEntity, idEntityType, whereRenderAlert)
      return false
    }
    return true
  }

  closeTemplateDialog() {
    this.showTemplateDialog = false
  }

  async printInvoice(
    templateSelected: any,
    idEntity: number | string,
    idEntityType: number | string,
    whereRenderAlert: ComponentWhereIsRendered | null = null
  ) {
    await printInvoice(templateSelected, idEntity, idEntityType, whereRenderAlert)
    this.showTemplateDialog = false
  }

  printInvoiceOld(id: string | number, invoiceType: string) {
    this.showDialogAction({
      type: DialogTypes.INFO,
      message: this.$t('components.dialog.print_pdf_info'),
      mainButtonText: this.$t('action_buttons.accept'),
      hideSecondaryButton: true,
      action: this.printingInvoice(id, invoiceType)
    })
  }

  printingInvoice(id: string | number, invoiceType: string) {
    this.printInvoiceAction({
      idInvoice: id,
      invoiceType
    })
      .then((response: any) => {
        const url = window.URL.createObjectURL(response.data)
        window.open(url)
      })
      .catch(() => {
        this.showDialogAction({
          type: DialogTypes.ERROR,
          message: this.$t('errors.generic_error')
        })
      })
  }
}
</script>
