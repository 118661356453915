import { GetterTree } from 'vuex'
import { RootState } from '@/store/types/storeGlobalTypes'
import { DownloadZipResult, FileState, MaxFileUploadSize } from '@/store/modules/fileManager/fileManagerTypes'
import { getFileExtension } from '@/helpers/file'

export const getters: GetterTree<FileState, RootState> = {
  getFile(state): Blob | null {
    return state.file
  },
  getMaxFileUploadSize(state): MaxFileUploadSize {
    return state.maxFileUploadSize
  },
  getAllowedExtensions(state): string {
    return state.allowedExtensions
  },
  getDocument(state) {
    return state.document
  },
  hasAllowedExtension:
    (_state, getters) =>
    (fileName: string): boolean => {
      const allowedExtensions: string = getters.getAllowedExtensions
      if (allowedExtensions) {
        const extension: string | undefined = getFileExtension(fileName)
        if (!extension) {
          return false
        }
        return allowedExtensions.includes(extension.toLowerCase())
      }
      return true
    },
  checkMaxFileUploadSize:
    (_state, getters) =>
    (fileSize: number): boolean => {
      const maxUploadSize: MaxFileUploadSize = getters.getMaxFileUploadSize
      if (fileSize) {
        return maxUploadSize.size > fileSize
      }
      return true
    },
  getPath(state) {
    return state.path
  },
  getCurrentPath(state) {
    return state.currentPath
  },
  getZipResult(state): DownloadZipResult {
    return state.downloadZipResult
  },
  isOpenFileManagerEditComponent(state): boolean {
    return state.isOpenFileManagerEditComponent
  },
  refreshFileManagerCounter(state): number {
    return state.refreshFileManagerCounter
  }
}
