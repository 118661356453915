export enum ComponentRenderMode {
  ECONOMIC_DATA_MODE = 'economicData',
  IMPORT_ACTIONS_MODE = 'economicDataActionsImport',
  INVOICE_EXPEDIENT_MODE = 'economicDataBill'
}

export enum EconomicDataListAlias {
  ECONOMIC_DATA = 'listExpedientEconomicData',
  IMPORT_ACTIONS = 'listExpedientActionsEconomicDataImport',
  INVOICE_EXPEDIENT = 'listExpedientEconomicDataPending'
}

export enum ComponentFormFields {
  AMOUNT = 'amount',
  CHARGED_DATE = 'chargeDate',
  COMPANY = 'idCompany',
  CONCEPT = 'concept',
  DATE = 'date',
  DESCRIPTION = 'description',
  EXERCISE = 'exercise',
  GROUPED = 'grouped',
  INVOICE_NUMBER = 'number',
  SEQUENCE = 'sequence',
  STAGE = 'idStage'
}
export interface SimpleGridImportActionsItem {
  actionType: string
  economicEstimate: string
  id: string
  stage: string
  dateEPOCH: string
  subject: string
  idCompany?: string
}
export interface InvoiceExpedientTextfieldInfo {
  exercise: string
  nextInvoice: number
  sequence: string
}
