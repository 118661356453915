<template lang="pug">

  div(
    v-resize="iconsSizeInXsBreakpoint"
    :class="['custom-field-type-selector', breakpointClass]"
  )
    div(class="row-title")
      span(class="number") 1.
      span(class="row-text") {{ texts.title }}
    ul(class="fields-list")
      li(
        v-for="(item, index) in items"
        :class="['item', { 'xs-breakpoint': isXsBreakpoint }]"
        @click.stop="emitClickedItem(item)"
      )
        span(:class="[getIcon(item.id), 'item-icon']")
        span(
          v-if="!isXsBreakpoint"
          class="item-name"
        ) {{ item.description }}

</template>

<script lang="ts">
import { TranslateResult } from 'vue-i18n'
import { Vue, Component, Emit, Prop } from 'vue-property-decorator'
import { customFieldTypesEnum, CustomFieldTypes } from '@/store/modules/customFields/customFieldsTypes'
import { Icons } from '@/icons/icons'

@Component
export default class CustomFieldTypeSelectorComponent extends Vue {
  @Prop({
    type: Array,
    required: true
  })
  items!: CustomFieldTypes[]

  icons = {
    fieldBoolean: Icons.SWITCH_OFF,
    fieldCurrency: Icons.COIN,
    fieldDate: Icons.CALENDAR,
    fieldGroup: Icons.PROCESS_STEP,
    fieldList: Icons.DROPDOWN,
    fieldNumeric: Icons.NUMBER_TEN,
    fieldText: Icons.TEXT_FIELD,
    fieldTextarea: Icons.TEXT_EDIT
  }

  @Emit()
  emitClickedItem(item: CustomFieldTypes) {
    return item
  }

  get isXsBreakpoint(): boolean {
    return this.$vuetify.breakpoint.xs
  }

  get breakpointClass(): string {
    return String(this.$vuetify.breakpoint.name)
  }

  get texts(): { title: TranslateResult } {
    return {
      title: this.$t('components.custom_field_type_selector.title')
    }
  }

  getIcon(id: number) {
    switch (Number(id)) {
      case customFieldTypesEnum.BOOLEAN:
        return this.icons.fieldBoolean
      case customFieldTypesEnum.CURRENCY:
        return this.icons.fieldCurrency
      case customFieldTypesEnum.DATE:
        return this.icons.fieldDate
      case customFieldTypesEnum.GROUP:
        return this.icons.fieldGroup
      case customFieldTypesEnum.LIST:
        return this.icons.fieldList
      case customFieldTypesEnum.NUMERIC:
        return this.icons.fieldNumeric
      case customFieldTypesEnum.TEXT:
        return this.icons.fieldText
      case customFieldTypesEnum.TEXTAREA:
        return this.icons.fieldTextarea
      default:
        return Icons.PROCESS_STEP
    }
  }

  iconsSizeInXsBreakpoint(): void {
    const items = this.$el.querySelectorAll('.item')
    items.forEach((item: any) => {
      item.style.width = this.isXsBreakpoint ? `${100 / this.items.length}%` : '100%'
    })
  }
}
</script>

<style lang="scss" scoped>
.custom-field-type-selector {
  @include borders($color: $blue-13);
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: $blue-08;
  color: $blue-04;
  font-family: $corporate-font;
  margin-right: 20px;

  .row-title {
    @include border;
    @include flex;
    justify-content: flex-start;
    text-transform: uppercase;
    margin: 20px 20px 0 20px;
    padding-bottom: 20px;

    .number {
      font-family: $corporate-font-bold;
      font-size: 16px;
      margin-right: 6px;
    }

    .row-text {
      font-size: 12px;
      padding-top: 4px;
    }
  }

  .fields-list {
    @include list;
    margin: 0 20px;
    padding-bottom: 10px;

    .item {
      @include flex($justify-content: flex-start);
      @include border($color: $blue-06);
      height: 50px;
      text-transform: uppercase;
      cursor: pointer;

      &.xs-breakpoint {
        @include flex;
        text-align: center;

        .item-icon {
          padding-left: 0;
        }
      }

      .item-icon {
        font-size: 20px;
        opacity: 0.4;
        padding-left: 8px;
      }

      .item-name {
        display: inline-block;
        font-size: 12px;
        font-family: $corporate-font-bold;
        margin-left: 10px;
      }

      &:hover {
        background-color: $corporate-color;
        color: $white-01;
      }
    }
  }

  &.xs {
    .fields-list {
      display: flex;
      flex-direction: row;
    }
  }

  &.sm {
    .item:first-of-type {
      display: flex;
      width: 100%;
    }

    .item:not(:first-of-type) {
      width: calc(50% - 20px);
      display: inline-flex;
    }

    .item:nth-child(even) {
      margin-right: 10px;
    }
  }
}
</style>
