interface SelectedRegisterDataType {
  [index: string]: object
}

interface SelectedRegisterIdType {
  [index: string]: number
}

export interface SelectedRegisterState {
  selectedRegisterData: SelectedRegisterDataType
  selectedRelatedData: object
  selectedRegisterId: SelectedRegisterIdType
  parentRegisterId: number
}

export const selectedRegisterMutationTypes = {
  FETCH_SELECTED_REGISTER_DATA: 'FETCH_SELECTED_REGISTER_DATA',
  FETCH_SELECTED_RELATED_DATA: 'FETCH_SELECTED_RELATED_DATA',
  RESET_SELECTED_RELATED_DATA: 'RESET_SELECTED_RELATED_DATA',
  RESET_SELECTED_REGISTER_DATA: 'RESET_SELECTED_REGISTER_DATA',
  SAVE_SELECTED_REGISTER_ID: 'SAVE_SELECTED_REGISTER_ID',
  SAVE_PARENT_REGISTER_ID: 'SAVE_PARENT_REGISTER_ID',
  RESET_PARENT_REGISTER_ID: 'RESET_PARENT_REGISTER_ID'
}
