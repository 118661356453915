<template let-data lang="pug">

  section(
    class="actions-panel-dialog-container"
  )
    header(class="header")
      div(class="header-title") {{ texts.dialogTitle }}
      v-spacer
      span(
        :class="[closeIcon, 'close-icon']"
        @click.stop="closeDialog"
      )
    //- Title
    LexonTextFieldComponent(
      id="title"
      v-model="info.subject"
      :label="texts.title"
    )
    //-Status options
    LexonAutocompleteComponent(
      v-model="info.statusObject"
      :prop-items="statusOptions"
      :clearable="false"
      itemName="status"
      :hasRelatedField="true"
      :label="texts.status"
      :attach="false"
    )
    //- Responsibles
    LexonAutocompleteComponent(
      v-model="info.responsible"
      :prop-items="responsibles"
      :clearable="false"
      itemName="name"
      :hasRelatedField="true"
      :label="texts.responsible"
      :attach="false"
    )
    //-Buttons
    div(class="panel-buttons-container")
      button(@click.stop="closeDialog" class="secondary-button") {{ texts.closeDialog }}
      v-spacer
      button(@click.stop="openAction" class="secondary-button") {{ texts.openNewWindow }}
      button(@click.stop="editAction" :class="['main-button', { 'disabled': saveButtonIsDisabled }]") {{ texts.save }}

</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator'
import LexonAutocompleteComponent from '@/components/forms/fields/LexonAutocomplete/LexonAutocompleteComponent.vue'
import LexonTextFieldComponent from '@/components/forms/fields/LexonTextField/LexonTextFieldComponent.vue'
import { getActionRouteName, objectActionTypes } from '@/store/modules/actions/actionsTypes'
import { Icons } from '@/icons/icons'

@Component({
  components: {
    LexonAutocompleteComponent,
    LexonTextFieldComponent
  }
})
export default class LexonKanbanDialogTemplate extends Vue {
  @Prop({
    type: Array
  })
  responsibles!: string[]

  statusOptions = objectActionTypes

  info = {
    subject: '',
    statusObject: {},
    responsible: {},
    id: '',
    idActionType: ''
  }

  texts = {
    closeDialog: this.$t('action_buttons.close'),
    dialogTitle: this.$t('components.lexon_kanban.dialog_title'),
    openNewWindow: this.$t('action_buttons.open'),
    responsible: this.$t('components.lexon_kanban.responsible'),
    save: this.$t('action_buttons.save'),
    status: this.$t('components.lexon_kanban.status'),
    title: this.$t('components.lexon_kanban.title')
  }

  closeIcon = Icons.CLOSE

  getActionRouteNameHelper = getActionRouteName

  get saveButtonIsDisabled(): boolean {
    return !this.info.subject
  }

  mounted() {
    this.initializeFieldValues()
    this.addOverlayListener()
  }

  async addOverlayListener() {
    await this.$nextTick()
    const dialogOverlay = document.querySelector('.e-dlg-overlay')
    if (dialogOverlay) {
      dialogOverlay.addEventListener('click', this.closeDialog)
    }
  }

  initializeFieldValues() {
    const { subject, state, stateId, responsible, userId, id, idActionType } = this.$data.data
    this.info.subject = subject
    this.info.statusObject = {
      id: stateId,
      status: state
    }
    this.info.responsible = {
      id: userId,
      name: responsible
    }
    this.info.id = id
    this.info.idActionType = idActionType
  }

  closeDialog() {
    this.$root.$emit('closeKanbanDialog')
  }

  openAction() {
    const routeData = this.$router.resolve({
      name: this.getActionRouteNameHelper(this.info.idActionType),
      params: { selectedRegisterId: this.info.id }
    })

    window.open((routeData as any).href, '_blank')
  }

  async editAction() {
    const reloadActions = true
    const dataToSend = {
      id: this.info.id,
      subject: this.info.subject,
      idActionType: this.info.idActionType,
      stateId: (this as any).info.statusObject.id,
      state: (this as any).info.statusObject.status,
      userId: (this as any).info.responsible.id
    }
    this.$root.$emit('saveAction', dataToSend, reloadActions)
    this.closeDialog()
  }
}
</script>

<style lang="scss" scoped>
.actions-panel-dialog-container {
  .header {
    @include flex;
    height: 48px;
    margin: 8px 0;

    .header-title {
      font-size: 22px;
      color: $corporate-color;
    }

    .close-icon {
      color: $corporate-color;
      font-size: 16px;
      cursor: pointer;
    }
  }

  .panel-buttons-container {
    @include flex;
    margin-top: 16px;

    button {
      min-width: 102px;
    }

    .main-button {
      @include main-action-button--rectangle;
    }

    .secondary-button {
      @include secondary-action-button--rectangle;
      margin: 0;
    }
  }
}
</style>

<style lang="scss">
.lexon-actions-panel-dialog {
  ~ .e-dlg-overlay {
    background-color: $dialog-background-color;
    cursor: pointer;
  }

  .e-footer-content,
  .e-dlg-header-content {
    display: none !important;
  }
}
</style>
