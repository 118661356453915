import { Route } from 'vue-router'
import store from '@/store/store'
import { ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import { addInlineJS, removeInlineJS, removeNodeByQuerySelectorAll } from '@/helpers/html'

export const chatHook = (from: Route): void => {
  const isPortalUser = store.getters[`${ModuleNamespaces.AUTH}/getIsPortalUser`]
  if (!isPortalUser && from.meta && from.meta.requiresAuth) {
    addChatScript()
  } else {
    removeChatScript()
  }
}

const addChatScript = () => {
  addInlineJS('https://code.jquery.com/jquery-3.4.1.min.js')
  addInlineJS(process.env.VUE_APP_CHAT_URL, '', {
    id: 'lf-chatservice-lefebvre',
    'data-m': 'nodep',
    'data-align': 'br',
    'data-o': 'lex-on',
    'data-c': 'lex-on-chat',
    'data-u': store.getters[`${ModuleNamespaces.AUTH}/getProUserId`],
    'data-s': 'Chat_SAC_LEX-ON'
  })
}

const removeChatScript = () => {
  removeInlineJS('https://code.jquery.com/jquery-3.4.1.min.js')
  removeInlineJS(process.env.VUE_APP_CHAT_URL)
  removeNodeByQuerySelectorAll('[class^="lex-on-chat"]')
}
