export const templateBasePath: string = 'Plantillas'

export interface TemplateState {
  templateFields: []
  templateTree: TemplateTree[]
  showTemplateGenerator: boolean
  templateTreeEntityType: number
  template: Template | null
  templateId: number
}

export interface TemplateTree {
  id: number
  isDirectory: boolean
  name: string
  children?: TemplateTree[]
  idFolder?: number
}

export interface Template {
  id?: number
  name: string
  description?: string
  idType: number
  idEntityType: number
  idDocument: number
}

export const templateMutationTypes = {
  FETCH_TEMPLATE_FIELDS: 'FETCH_TEMPLATE_FIELDS',
  FETCH_TEMPLATE_TREE: 'FETCH_TEMPLATE_TREE',
  SHOW_HIDE_TEMPLATE_GENERATOR: 'SHOW_HIDE_TEMPLATE_GENERATOR',
  SAVE_FILE_TEMPLATE: 'SAVE_FILE_TEMPLATE',
  SAVE_TEMPLATE: 'SAVE_TEMPLATE',
  SAVE_TEMPLATE_ID: 'SAVE_TEMPLATE_ID',
  SET_TEMPLATE_TREE_ENTITY_TYPE: 'SET_TEMPLATE_TREE_ENTITY_TYPE',
  RESET_TEMPLATE: 'RESET_TEMPLATE'
}

export const templateFields = {
  ID_ENTITY_TYPE: 'idEntityType'
}

export interface DropdownElement {
  idBtn: string
  dropdownOption: string
  textKey: string
  selector: HTMLElement | null
}

export interface ITemplate {
  id: number | string
  idEntity: number | string
  name: string
  templateName: string
  isDirectory?: boolean
  children?: ITemplate[]
}
