<template lang="pug">

  ejs-toolbar(id="customClosePreviewToolbar")
    e-items
      e-item(
        id="closePage"
        :prefixIcon="icons.close"
        :tooltipText="texts.close"
        :click="closeViewerEvent"
      )

</template>

<script lang="ts">
import { Vue, Component, Emit } from 'vue-property-decorator'
import { Icons } from '@/icons/icons'

@Component
export default class ClosePreviewComponent extends Vue {
  icons = {
    close: Icons.CLOSE
  }

  texts = {
    close: this.$t('components.pdf_viewer.close_document')
  }

  @Emit()
  closeViewerEvent() {}
}
</script>

<style lang="scss">
.e-filemanager {
  #customClosePreviewToolbar.e-toolbar {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    height: auto !important;
    min-height: 33px !important;
    z-index: 9999;
    border-bottom: 1px solid $corporate-color !important;
    background-color: $white-01 !important;

    #closePage {
      height: 33px !important;
    }

    .e-toolbar-items {
      min-height: 33px !important;
      margin: 0;
      background-color: $white-01 !important;

      .e-toolbar-item {
        min-height: 33px !important;
        background-color: $white-01;
        padding: 0;
        margin: 0;

        .e-icon-btn {
          min-height: 33px;
          background-color: $white-01;
          margin: 0;

          .e-btn-icon.e-icons {
            font-family: $lf-icons-font !important;
            font-size: 12px;
            color: $corporate-color !important;
            background-color: $white-01;
            border: 0;
          }
        }

        .e-toolbar-item:not(.lf-selection-item) .e-tbar-btn.e-btn:hover {
          height: 33px !important;
        }
      }

      .e-toolbar-left {
        margin-left: 2px;
        line-height: 33px !important;

        .e-icon-btn {
          min-height: 0 !important;

          .e-btn-icon {
            width: 33px !important;
            height: 33px !important;
          }

          &:hover {
            height: 33px !important;
          }
        }
      }

      .e-toolbar-center {
        @include flex;
        overflow: hidden;

        .e-toolbar-item {
          .e-btn-icon.e-icons {
            color: $gray-01 !important;
          }

          &.disabled {
            pointer-events: none;

            .e-btn-icon.e-icons {
              color: $gray-02 !important;
            }
          }

          &.e-template {
            pointer-events: none;
          }
        }
      }
    }
  }
}
</style>
