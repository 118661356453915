import { ActionTree } from 'vuex'
import {
  CustomerPortalState,
  customerPortalMutationTypes,
  StatePortal
} from '@/store/modules/customerPortal/customerPortalTypes'
import { RootState, ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import { CustomerPortalService } from '@/services/CustomerPortalService'
import { DialogTypes } from '../dialog/dialogTypes'
import { i18n } from '@/plugins/vue-i18n'
import { MainService } from '@/services/MainService'

const dialogModule: string = ModuleNamespaces.DIALOG

export const actions: ActionTree<CustomerPortalState, RootState> = {
  async fetchCustomerPortal({ commit }, customerId) {
    try {
      const url = `/customer/portal/${customerId}/get`
      const { data } = await new CustomerPortalService().getData(url)
      commit(customerPortalMutationTypes.FETCH_CUSTOMER_PORTAL, data)
    } catch (error) {}
  },
  async saveCustomerPortalData({ dispatch }, customerPortalData) {
    try {
      const { portalStatus } = customerPortalData
      const url = 'customer/portal/save'
      await new MainService().postData(url, customerPortalData)
      if (portalStatus === StatePortal.ACCESS_NOT_ACTIVATED) {
        dispatch(
          `${dialogModule}/showDialog`,
          {
            type: DialogTypes.SUCCESS,
            message: i18n.t('components.customer_portal.credentials_ok_message')
          },
          { root: true }
        )
      }
      if (portalStatus === StatePortal.ACCESS_BLOCKED) {
        dispatch(
          `${dialogModule}/showDialog`,
          {
            type: DialogTypes.SUCCESS,
            message: i18n.t('components.customer_portal.block_success')
          },
          { root: true }
        )
      }
      if (portalStatus === StatePortal.ACCESS_ACTIVATED) {
        dispatch(
          `${dialogModule}/showDialog`,
          {
            type: DialogTypes.SUCCESS,
            message: i18n.t('components.customer_portal.activated_success_message')
          },
          { root: true }
        )
      }
    } catch (error) {
      const errorMessage = error.response.data.exceptionDesc
      dispatch(
        `${dialogModule}/showDialog`,
        {
          type: DialogTypes.ERROR,
          message: errorMessage
        },
        { root: true }
      )
    }
  },
  async deleteCustomerPortal({ dispatch }, customerId) {
    try {
      const url = `/customer/portal/${customerId}/delete`
      await new MainService().postData(url)
      dispatch(
        `${dialogModule}/showDialog`,
        {
          type: DialogTypes.SUCCESS,
          message: i18n.t('components.customer_portal.removed_access_message')
        },
        { root: true }
      )
    } catch (error) {
      const errorMessage = error.response.data.exceptionDesc
      dispatch(
        `${dialogModule}/showDialog`,
        {
          type: DialogTypes.ERROR,
          message: errorMessage
        },
        { root: true }
      )
    }
  }
}
