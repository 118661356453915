import store from '@/store/store'

const getTrackUser = () => {
  return store.getters['authModule/getTrackUser']
}

const getTrackCompany = () => {
  const { idCompany } = store.getters['authModule/getCompany']
  return {
    id: idCompany
  }
}

export { getTrackUser, getTrackCompany }
