import { Module } from 'vuex'
import { getters } from '@/store/modules/lexbox/lexboxGetters'
import { actions } from '@/store/modules/lexbox/lexboxActions'
import { mutations } from '@/store/modules/lexbox/lexboxMutations'
import { LexboxState } from '@/store/modules/lexbox/lexboxTypes'
import { RootState } from '@/store/types/storeGlobalTypes'

export const state: LexboxState = {
  calendarData: null,
  calendarProvider: null,
  calendarServiceUrl: '',
  emailProvider: null,
  eventCalendarServiceUrl: '',
  accessToken: ''
}

const namespaced: boolean = true

export const lexboxModule: Module<LexboxState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations
}
