import { GetterTree } from 'vuex'
import { NumerationsState } from '@/store/modules/numerations/numerationsTypes'
import { RootState } from '@/store/types/storeGlobalTypes'

export const getters: GetterTree<NumerationsState, RootState> = {
  getNumerationMaintenance: (state, _getters) => (context: string) => {
    if (state.numerationsMaintenance[context]) {
      return state.numerationsMaintenance[context]
    }
    return {}
  }
}
