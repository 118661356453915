import { GetterTree } from 'vuex'
import { MenusState, SiteMenuItem } from '@/store/modules/menus/menusTypes'
import { RootState } from '@/store/types/storeGlobalTypes'

export const getters: GetterTree<MenusState, RootState> = {
  getSiteMenu(state): SiteMenuItem[] {
    return state.siteMenu
  },
  getConfigurationMenu(state): SiteMenuItem[] {
    return state.configurationMenu
  },
  getClientMenu(state): SiteMenuItem[] {
    return state.clientMenu
  },
  getDrawerStatus(state): boolean {
    return state.drawerInMiniStatus
  }
}
