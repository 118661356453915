export const b64toBlob = (b64Data: string, contentType: string = '', sliceSize = 512): Blob => {
  const byteCharacters = atob(b64Data)
  const byteArrays = []

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize)
    const byteNumbers = new Array(slice.length)
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i)
    }
    byteArrays.push(new Uint8Array(byteNumbers))
  }

  return new Blob(byteArrays, { type: contentType })
}

export const createLink = (blobUrl: string, fileName: string = '', forceNewPage: boolean = false) => {
  const link = document.createElement('a')

  link.href = blobUrl

  if (fileName !== '') {
    link.download = fileName
  } else {
    link.target = forceNewPage ? '_blank' : '_self'
  }

  document.body.appendChild(link)

  link.dispatchEvent(
    new MouseEvent('click', {
      bubbles: true,
      cancelable: true,
      view: window
    })
  )
  document.body.removeChild(link)
}
