import { MutationTree } from 'vuex'
import { ContactsState } from '@/store/modules/contacts/contactsTypes'

export const mutations: MutationTree<ContactsState> = {
  FETCH_CONTACTS(state, contacts) {
    state.contacts = contacts
  },
  RESET_CONTACTS(state) {
    state.contacts = null
  },
  CONTACT_TYPE_SELECTED(state, contactType) {
    state.contactTypeSelected = contactType
  }
}
