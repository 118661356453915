<template lang="pug">
div(class="actions-bar")
  button(
    v-for="(button, index) in buttons"
    @click.stop="executeAction(button.action, index)"
    :title="button.tooltip"
    :key="index"
    :class="getClassStyle(button)"
    :disabled="disabledButtons"
  )
    span(v-if="isSquareButton(button)") {{ button.tooltip }}

</template>

<script lang="ts">
import { Vue, Component, Emit, Prop } from 'vue-property-decorator'
import { ActionBarButton, ActionBarButtonMode } from './types/ActionBarComponentTypes'

@Component
export default class ActionsBarComponent extends Vue {
  @Prop({
    type: Array,
    required: true
  })
  buttons!: ActionBarButton[]

  disabledButtons = false

  timeout = 0

  buttonMode = ActionBarButtonMode

  get saveButtonIndex() {
    return this.buttons.length - 1
  }

  @Emit()
  executeAction(action: string, index: number) {
    this.disabledButtons = true

    if (this.saveButtonIndex === index) {
      this.timeout = 4000
    } else {
      this.timeout = 1000
    }

    setTimeout(() => {
      this.disabledButtons = false
    }, this.timeout)

    return action
  }

  getMode(button: ActionBarButton) {
    return button.mode || this.buttonMode.ROUND
  }

  isSquareButton(button: ActionBarButton) {
    return button.mode === ActionBarButtonMode.SQUARE
  }

  getClassStyle(button: ActionBarButton) {
    const classStyle = [{ disabled: !this.show(button) }, button.class, this.getMode(button)]

    if (button.hidden) {
      classStyle.push('hidden')
    }

    if (!this.isSquareButton(button)) {
      classStyle.push(button.icon)
    }

    return classStyle
  }
  // Añado de nuevo este comentario para probar
  show(button: any) {
    if (typeof button.show === 'function') {
      return button.show()
    } else {
      return button.show
    }
  }
}
</script>

<style lang="scss" scoped>
.actions-bar {
  display: inline-flex;

  button {
    &.round {
      @include interactive-round-button;

      &.green-color {
        @include interactive-round-button($background-color: $success-color);
      }

      &.red-color {
        @include interactive-round-button($background-color: $error-color);
      }
    }

    &.square {
      &.main-color {
        @include main-action-button--rectangle;
      }

      &.secondary-color {
        @include secondary-action-button--rectangle;
      }
    }

    &.hidden {
      display: none;
    }

    + button {
      margin-left: 2px;
    }
  }
}
</style>
