import { Module } from 'vuex'
import { getters } from '@/store/modules/tabs/tabsGetters'
import { mutations } from '@/store/modules/tabs/tabsMutations'
import { actions } from '@/store/modules/tabs/tabsActions'
import { TabNames, TabsState } from '@/store/modules/tabs/tabsTypes'
import { RootState } from '@/store/types/storeGlobalTypes'

export const state: TabsState = {
  disabledTabs: false,
  currentTabInfo: {
    name: TabNames.EXPEDIENTS,
    index: 0
  },
  currentSearchTabInfo: {
    name: '',
    index: 0
  },
  tabSelectorOptions: []
}

const namespaced: boolean = true

export const tabsModule: Module<TabsState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations
}
