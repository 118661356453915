import { Module } from 'vuex'
import { RootState } from '@/store/types/storeGlobalTypes'
import { getters } from '@/store/modules/documentOnline/documentOnlineGetters'
import { mutations } from '@/store/modules/documentOnline/documentOnlineMutations'
import { actions } from '@/store/modules/documentOnline/documentOnlineActions'
import {
  documentOnlineActions,
  documentOnlineApplications,
  DocumentOnlineState
} from '@/store/modules/documentOnline/documentOnlineTypes'

export const state: DocumentOnlineState = {
  accessToken: '',
  accessTokenTtl: 0,
  fileSize: 0,
  fileUrl: '',
  action: documentOnlineActions.EDIT,
  applicationType: documentOnlineApplications.WORD,
  fileName: '',
  fileVersion: '',
  fileToken: { id: 0, size: 0, token: '' },
  wopiUserId: 0,
  wopiUserName: '',
  wopiLocale: 'es-ES',
  needsConversion: false,
  readOnly: false
}

const namespaced: boolean = true

export const documentOnlineModule: Module<DocumentOnlineState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations
}
