<template lang="pug">
  div(class="tandem")
    div(class="tandem-search")
      TandemFiltersComponent(
        @set-filter="setTandemFilter"
      )
      div(class="tandem-no-results" v-if="isNotFoundData")
        span(class="lf-icon-document")
        p(v-html="texts.notResults")
      div(class="tandem-no-results" v-else-if="isLoadingData")
        SpinnerLayerComponent(class="spinner-layer")
      div(class="tandem-items" v-else)
        template(v-for="data in tandemData")
          div(class="tandem-item")
            div(class="tandem-separator")
            div(class="tandem-lead-in")
              div(class="tandem-vertical-separator")
                div(class="tandem-header")
                  div(class="tandem-titles")
                    div(class="tandem-title" v-html="data.metadata.name" @click.stop="openFile(data)")
                    div(class="tandem-sub-title")
                      span(:class="getEntity(data.metadata.entityType).icon")
                      router-link(
                        class="tandem-sub-title-text"
                        v-html="`${data.metadata.entityDescription} ${data.metadata.entityName}`"
                        :to="getEntityURL(data)"
                      )
                  div(class="tandem-actions e-toolbar-right")
                    div(class="tandem-button")
                      div(class="tandem-button-cover")
                        button(@click.stop="openFile(data)")
                          span(class="e-btn-icon lf-icon-visible e-icons")
                    div(class="tandem-button")
                      div(class="tandem-button-cover")
                        button(@click.stop="downloadFile(data)")
                          span(class="e-btn-icon lf-icon-download e-icons")
                div(class="tandem-summary")
                  div(class="lf-icon-quote-open tandem-icon")
                  div(class="tandem-summary-text" v-html="data.highlights[0]")
                div(class="tandem-principal-descriptors" v-if="data.metadata.principalDescriptors && data.metadata.principalDescriptors.length>0")
                  div(class="lf-icon-balance tandem-icon-descriptor")
                  div(class="tandem-descriptors")
                    div(class="tandem-principal-descriptors-text"
                        v-for="pdesc in data.metadata.principalDescriptors.slice(0, 5)"
                        @click.stop="addDescriptor(1, pdesc)") {{ pdesc.toUpperCase() }}
                div(class="tandem-secondary-descriptors" v-if="data.metadata.secondaryDescriptors && data.metadata.secondaryDescriptors.length>0")
                  div(class="lf-icon-user tandem-icon-descriptor")
                  div(class="tandem-descriptors")
                    div(class="tandem-secondary-descriptors-text"
                        v-for="sdesc in data.metadata.secondaryDescriptors.slice(0, 8)"
                        @click.stop="addDescriptor(2, sdesc)") {{ sdesc.charAt(0).toUpperCase() + sdesc.slice(1) }}
          div(class="tandem-item-separator")
      OpenFileComponent(
        ref="openFileComponent"
      )
      DocumentOnline
    div(class="tandem-footer")
      PaginationComponent(
        v-if="showPaginator"
        class="pagination-component"
        :style="cssVars"
        :totalPages="totalPages"
        :maxVisibleButtons="maxVisibleButtonsPage"
        :currentPage="getPaginationData.numPage+1"
        @pageChanged="onPageChanged"
      )
      div(class="select-wrapper")
        div(class="tandem-select-num-recs")
          v-select(
            v-if="showPaginator"
            v-model="getPaginationData.recsPerPage"
            :items="numRecsPerPage"
            :append-icon="icons.arrowDown"
            :menu-props="{ contentClass: 'tandem-paginator-list' }"
            v-on:change="refreshSearch"
          )
        div(class="recs-per-page" v-if="showPaginator") {{texts.recsPerPage}}
      div(class="tandem-page-info" v-if="showPaginator") {{numPagesLiteral}}
</template>

<script lang="ts">
import { Component, Emit, Watch, Mixins } from 'vue-property-decorator'
import { Action, Getter, Mutation } from 'vuex-class'
import { ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import TandemFiltersComponent from '@/components/search/TandemSearch/TandemFiltersComponent.vue'
import { createEntityByType } from '@/store/modules/entities/entitiesActions'
import { Entity, entity } from '@/store/modules/entities/entitiesTypes'
import { Icons } from '@/icons/icons'
import { formatNumber } from '@/helpers/numeric'
import { clone } from '@/helpers/object'
import SpinnerLayerComponent from '@/components/Spinner/SpinnerLayerComponent.vue'
import DocumentOnline from '@/components/DocumentOnline/DocumentOnlineComponent.vue'
import OpenFileComponent from '@/components/FileManager/OpenFileComponent.vue'
import PaginationComponent from '@/components/Pagination/PaginationComponent.vue'
import { vars } from '@/styles/styleVars'
import { TabNames } from '@/store/modules/tabs/tabsTypes'
import { openLefebvrePdfViewer } from '@/helpers/pdfViewer'
import { OpenMSOffice } from '@/components/FileManager/types/FileManagerTypes'
import OpenMSOfficeFiles from '@/mixins/OpenMSOfficeFilesMixin.vue'
import { getFileExtension } from '@/helpers/file'

const tandemSearchModule: string = ModuleNamespaces.TANDEM_SEARCH
const fileManagerModule: string = ModuleNamespaces.FILE_MANAGER

@Component({
  components: {
    DocumentOnline,
    OpenFileComponent,
    PaginationComponent,
    SpinnerLayerComponent,
    TandemFiltersComponent
  }
})
export default class TandemSearchComponent extends Mixins(OpenMSOfficeFiles) {
  @Action('fetchPathByBasePath', { namespace: fileManagerModule }) fetchPathByBasePath: (basePath: string) => void
  @Action('fetchSearchData', { namespace: tandemSearchModule }) fetchTandemSearchData: ({}) => void
  @Action('fetchOpenOfficeIn', { namespace: fileManagerModule })
  fetchOpenOfficeIn: () => OpenMSOffice

  @Mutation('SET_DESCRIPTORS', { namespace: tandemSearchModule }) setDescriptors: ({}) => void
  @Mutation('SET_PAGINATIONDATA', { namespace: tandemSearchModule }) setPaginationData: ({}) => void

  @Getter('getSearchData', { namespace: tandemSearchModule }) searchData: []
  @Getter('getPath', { namespace: fileManagerModule }) path: string
  @Getter('getSearchTerm', { namespace: tandemSearchModule }) getSearchTerm: string
  @Getter('getSearchMetadata', { namespace: tandemSearchModule }) getSearchMetadata: any
  @Getter('getDescriptors', { namespace: tandemSearchModule }) getDescriptors: any
  @Getter('getPaginationData', { namespace: tandemSearchModule }) getPaginationData: any

  @Getter('getTotalOccurrences', { namespace: tandemSearchModule }) totalTandemOccurrences: number

  tandemData = []
  loadingData: boolean = true

  numRecsPerPage = [5, 10, 20, 50]

  numPage: number = 0
  totalPages: number = 0
  maxVisibleButtonsPage: number = 5

  icons = {
    doubleArrowLeft: Icons.DOUBLE_ARROW_LEFT,
    doubleArrowRight: Icons.DOUBLE_ARROW_RIGHT,
    arrowDown: Icons.ANGLE_DOWN
  }

  texts = {
    notResults: this.$t('components.no_results.no_data_text'),
    of: this.$t('components.pagination.of'),
    pages: this.$t('components.pagination.pages'),
    recsPerPage: this.$t('components.pagination.records_per_page'),
    registers: this.$t('components.pagination.registers')
  }

  openMSOfficeConfig: OpenMSOffice = OpenMSOffice.WOPI

  get cssVars() {
    return {
      '--pagination-container-margin': 0,
      '--pagination-btn-active': vars.corporate_color,
      '--pagination-btn-active-border': vars.corporate_color,
      '--pagination-icon-color': vars.corporate_color,
      '--pagination-btn-not-active-border': vars.white_01,
      '--pagination-btn-color': vars.gray_01,
      '--pagination-btn-hover-color': vars.gray_01,
      '--pagination-icon-inactive-color': vars.disabled_color
    }
  }

  get showPaginator() {
    if (this.totalTandemOccurrences) {
      return this.totalTandemOccurrences >= this.numRecsPerPage[0]
    }
  }

  get numPagesLiteral() {
    if (this.showPaginator) {
      return `${this.getPaginationData.numPage + 1} ${this.texts.of} ${this.totalPages} ${
        this.texts.pages
      } (${formatNumber(this.totalTandemOccurrences)} ${this.texts.registers})`
    }
    return ''
  }

  @Watch('searchData', { immediate: true })
  changeSearchData() {
    this.totalPages = Math.ceil(this.totalTandemOccurrences / this.getPaginationData.recsPerPage)
    this.prepareData()
  }

  @Emit()
  setTandemFilter(metadata: object) {
    const data = { metadata, size: this.getPaginationData.recsPerPage }
    this.getPaginationData.numPage = 0
    this.setPaginationData(this.getPaginationData)
    return data
  }

  async mounted() {
    this.openMSOfficeConfig = await this.fetchOpenOfficeIn()
  }

  async prepareData() {
    await Promise.all(
      this.searchData.map(async (data: any) => {
        await this.fetchPathByBasePath(data.metadata.basePath)
        data.metadata.path = this.path
      })
    ).then(() => {
      this.tandemData = this.searchData
    })
  }

  addDescriptor(type: number, value: string) {
    const descriptor = {
      name: '',
      value: ''
    }
    descriptor.name = type === 1 ? 'principalDescriptors' : 'secondaryDescriptors'
    descriptor.value = value
    const descriptorFilter = {
      key: `${descriptor.name}-${descriptor.value}`,
      value: descriptor
    }
    const newDescriptors = clone(this.getDescriptors)
    if (newDescriptors.findIndex((i: any) => i.key === descriptorFilter.key) === -1) {
      newDescriptors.push(descriptorFilter)
      this.setDescriptors(newDescriptors)
    }
  }

  getEntity(entityType: number, entityId: number = 0): Entity {
    return createEntityByType(entityType, entityId)
  }

  openFile(document: any) {
    const name: string = document.metadata.name || ''
    const extension = '.' + getFileExtension(name) || ''
    const getMSOfficeLocalUri = this.getMSOfficeLocalURI(extension)

    if (this.openMSOfficeConfig === OpenMSOffice.LOCAL && getMSOfficeLocalUri) {
      const file = {
        id: document.metadata.idDocumento,
        originalFileName: document.metadata.originalFileName,
        type: extension
      }
      this.openMSOfficeLocal(file)
    } else {
      this.processDocument(document)
    }
  }

  downloadFile(document: any) {
    this.processDocument(document, true)
  }

  processDocument(document: any, download: boolean = false) {
    const file = {
      id: document.metadata.idDocumento,
      originalFileName: document.metadata.fileName
    }

    const openFileComponent: any = this.$refs.openFileComponent
    if (openFileComponent) {
      if (download) {
        openFileComponent.downloadFile(file)
      } else {
        if (file.originalFileName.split('.').pop().toLowerCase() === 'pdf') {
          openLefebvrePdfViewer(file.id)
        } else {
          openFileComponent.openFile(file)
        }
      }
    }
  }

  getEntityURL(data: any) {
    const basePath = encodeURI(btoa(data.metadata.basePath))
    const path = encodeURI(btoa(data.metadata.path))
    const currentEntity: Entity = this.getEntity(Number(data.metadata.entityType), Number(data.metadata.entityId))

    let tab: string = ''
    switch (currentEntity.type) {
      case entity.template.type:
        tab = TabNames.TEMPLATES
        break
      case entity.general.type:
        tab = ''
        break
      default:
        tab = TabNames.DOCUMENTS
        break
    }

    return currentEntity.getRoute({ basePath, path, tab, fileName: data.metadata.fileName })
  }

  onPageChanged(page: number) {
    this.getPaginationData.numPage = page - 1
    this.updateTandemSearch(
      this.getPaginationData.numPage * this.getPaginationData.recsPerPage,
      this.getPaginationData.recsPerPage
    )
  }

  refreshSearch(value: number) {
    this.getPaginationData.recsPerPage = value
    this.getPaginationData.numPage = 0
    this.setPaginationData(this.getPaginationData)
    this.updateTandemSearch(
      this.getPaginationData.numPage * this.getPaginationData.recsPerPage,
      this.getPaginationData.recsPerPage
    )
  }

  async updateTandemSearch(from: number, size: number) {
    await this.fetchTandemSearchData({
      term: this.getSearchTerm,
      metadata: this.getSearchMetadata,
      from,
      size
    })
  }

  get isLoadingData(): boolean {
    return 0 === this.tandemData.length
  }

  get isNotFoundData(): boolean {
    return 0 === this.totalTandemOccurrences
  }
}
</script>

<style lang="scss" scoped>
.tandem {
  padding: 0 20px 10px 20px;
}
.tandem-search {
  display: flex;
  border-bottom: 1px solid $gray-03;
}
.tandem-no-results {
  display: flex;
  width: 100%;
  min-height: 500px;
  padding: 50px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  span {
    display: flex;
    font-size: 100px;
    font-weight: 400;
    color: $corporate-color-60;
    padding-bottom: 15px;
  }
  p {
    display: flex;
    font-family: $corporate-font;
    font-size: 20px;
    color: $corporate-color-60;
  }
}
.tandem-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.tandem-titles {
  display: flex;
  flex-direction: column;
}
.tandem-items {
  display: flex;
  flex-direction: column;
  padding: 10px 0px 0px;
  width: 100%;
}
.tandem-item {
  display: flex;
  padding: 10px 5px 10px;
  &:hover .tandem-lead-in {
    background-color: $gray-04;
  }
  &:hover .tandem-sub-title span {
    background-color: $gray-04;
  }
}
.tandem-item-separator {
  border-bottom: 1px solid $gray-03;
  width: 98%;
  display: flex;
  align-self: center;
}

.tandem-separator {
  border-left: 3px solid $corporate-color;
  padding-right: 5px;
}

.tandem-link-wrapper {
  font-family: $corporate-font-bold;
  font-size: 12px;
  color: $corporate-color;
  display: flex;
  align-self: flex-end;
  justify-content: flex-end;
  min-width: 150px;
  margin: 0 5px 0 0;
}
.tandem-link {
  cursor: pointer;
  &:hover {
    color: $blue-04;
  }
  &::before {
    font-family: $lf-icons-font;
    content: '\e9bf';
    padding-right: 5px;
  }
}
.tandem-lead-in {
  flex: 0 0 100%;
  padding: 10px 0px;

  span {
    display: inline-flex;
    font-size: 14px;
    background-color: $gray-04;
    color: $blue-14;
    padding: 6px 20px;
    margin: 0 20px 10px;
    ::v-deep .term {
      color: $red-09;
      display: contents;
    }
  }
}
.tandem-title {
  display: flex;
  color: $corporate-color;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  line-height: 16px;
  padding-top: 5px;
  ::v-deep .term {
    color: $red-09;
    display: contents;
  }
}
.tandem-sub-title {
  padding-top: 10px;

  span {
    color: $corporate-color;
    background-color: $white-01;
    padding: 0px;
    margin: 0px;
    font-size: 20px;
  }
  .tandem-sub-title-text {
    font-family: $corporate-font-medium;
    font-size: 15px;
    color: $gray-01;
    padding-left: 10px;
    text-decoration: underline;
    cursor: pointer;
  }
}
.tandem-summary {
  display: flex;
  padding-top: 15px;
  margin-bottom: 30px;
}
.tandem-descriptors {
  display: flex;
  flex-wrap: wrap;
}
.tandem-principal-descriptors {
  display: flex;
  padding-top: 5px;
  flex-wrap: nowrap;
}
.tandem-secondary-descriptors {
  display: flex;
  flex-wrap: nowrap;
}
.tandem-icon {
  font-size: 41px;
  color: $gray-03;
  width: 35px;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  padding-right: 15px;
  margin: 0 0 0 40px;
}
.tandem-icon-descriptor {
  font-size: 21px;
  color: $blue-01;
  width: 35px;
  display: flex;
  flex-direction: row-reverse;
  align-items: flex-start;
  padding-right: 15px;
  margin: 1px 0 0 80px;
}
.tandem-summary-text {
  color: $gray-01;
  font-size: 16px;
  line-height: 16px;
  ::v-deep .term {
    color: $red-09;
    display: contents;
  }
  ::v-deep em {
    color: $red-09;
  }
}
.tandem-principal-descriptors-text {
  display: flex;
  margin: 0px 10px 10px 0px;
  border: 1px solid $blue-01;
  color: $blue-01;
  padding: 4px;
  font-size: 10px;
  cursor: pointer;
}
.tandem-secondary-descriptors-text {
  display: flex;
  margin: 0px 10px 10px 0px;
  border: 1px solid $blue-01;
  padding: 4px;
  font-size: 10px;
  cursor: pointer;
}
.tandem-vertical-separator {
  display: flex;
  flex-direction: column;
  padding: 0 15px;
}
.spinner-layer-container {
  height: 300px;
}

.tandem-actions {
  display: flex;
  align-items: stretch;
  .tandem-button {
    display: flex;
    align-items: center;
    background-color: $gray-09;
    margin: 5px 0;

    .tandem-button-cover {
      display: flex;
      border-radius: 50%;
      border: 5px solid transparent;

      &:hover {
        border-color: $corporate-color-20;
      }

      button {
        display: flex;
        background-color: $corporate-color;
        padding: 8px;
        border-radius: 50%;
        span {
          background-color: transparent;
          color: $white-01;
          font-size: 20px;
          padding: 0px;
          margin: 0px;
        }
      }
    }

    &:first-child {
      border-radius: 50% 0 0 50%;
    }

    &:last-child {
      border-radius: 0 50% 50% 0;
    }
  }
}

.tandem-footer {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 10px;
  position: relative;
  ::v-deep .pagination-component {
    display: flex;
    width: 300px;
    .pagination-item {
      button {
        width: auto;
        &.icon {
          font-size: 10px;
          width: auto;
          min-width: 24px;
        }
      }
    }
  }
  ::v-deep .tandem-select-num-recs {
    display: flex;
    width: 70px;
    max-width: 70px;
    margin: 0;
    padding: 0;
    .v-select__selection {
      font-family: $secondary-font;
      font-size: 14px;
      color: $corporate-color;
      position: relative;
      top: -3px;
    }
    .v-text-field {
      margin: 0 20px 0 0;
      padding: 4px 0;
      height: 20px;
    }
    .lf-icon-angle-down {
      padding-bottom: 0px;
      top: 4px;
      position: relative;
      &::before {
        content: '';
        border-width: 8px;
        border-style: solid;
        border-right: 8px solid transparent;
        border-color: $corporate-color transparent transparent;
        width: 0;
        height: 0;
      }
    }
    .v-input__slot {
      height: 20px;
      &::before,
      &::after {
        border: none;
      }
    }
  }
  ::v-deep .tandem-page-info {
    font-family: $corporate-font;
    font-size: 14px;
    color: $corporate-color;
    display: flex;
    align-self: end;
  }
  ::v-deep .recs-per-page {
    font-family: $corporate-font;
    font-size: 14px;
    color: $corporate-color;
    display: flex;
  }
  ::v-deep .select-wrapper {
    display: flex;
  }
}

::v-deep .v-list-item__content {
  padding: 0;
}
</style>

<style lang="scss">
.tandem-paginator-list {
  border-radius: 0;
  border-top: 1px solid $corporate-color !important;
  box-shadow: none !important;
}
</style>
