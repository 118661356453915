let inputInstance: any = null
let lastFilters: any = {}
let dateValue: any = []
let parsedFilterSettingsFlag: boolean = false

const setInputInstance = (instance: any) => {
  inputInstance = instance
}

const setLastFilters = (_lastFilters: any) => {
  lastFilters = _lastFilters
}

const removeLastFilters = () => {
  lastFilters = {}
}

const setDateValue = (_dateValue: any) => {
  dateValue = _dateValue
}

const setParsedFilterSettingsFlag = (_parsedFilterSettingsFlag: any) => {
  parsedFilterSettingsFlag = _parsedFilterSettingsFlag
}

export {
  inputInstance,
  setInputInstance,
  lastFilters,
  setLastFilters,
  removeLastFilters,
  dateValue,
  setDateValue,
  parsedFilterSettingsFlag,
  setParsedFilterSettingsFlag
}
