<template lang="pug">

  transition(name="fade" appear)
    v-expansion-panels(flat :class="['totals-container', breakpointClass, disableExpansionPanel]")
      v-expansion-panel

        v-expansion-panel-header
          TotalItemComponent(
            v-for="(item, index) in header"
            :key="`header-item-${index}`"
            :item="item"
            :fieldTotalName="fieldTotalName"
          )

        v-expansion-panel-content
          TotalItemComponent(
            v-for="(item, index) in content"
            :key="`content-item-${index}`"
            :item="item"
            :fieldTotalName="fieldTotalName"
          )

</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator'
import TotalItemComponent from '@/components/Totals/TotalItemComponent.vue'

@Component({
  components: {
    TotalItemComponent
  }
})
export default class TotalsContainerComponent extends Vue {
  @Prop({
    type: Array,
    required: true
  })
  items!: object[]

  @Prop({
    type: String,
    default: 'totalAmount'
  })
  fieldTotalName!: string

  get header() {
    switch (this.$vuetify.breakpoint.name) {
      case 'xs':
        return this.filterItemsDependingOnBreakpoint(0, 0)
      case 'sm':
        return this.filterItemsDependingOnBreakpoint(0, 3)
      case 'md':
        return this.filterItemsDependingOnBreakpoint(0, 3)
      case 'lg':
      case 'xl':
        return this.items
    }
  }

  get content() {
    switch (this.$vuetify.breakpoint.name) {
      case 'xs':
        return this.filterItemsDependingOnBreakpoint(1)
      case 'sm':
        return this.filterItemsDependingOnBreakpoint(4)
      case 'md':
        return this.filterItemsDependingOnBreakpoint(4)
      case 'lg':
      case 'xl':
        return []
    }
  }

  get breakpointClass() {
    switch (this.$vuetify.breakpoint.name) {
      case 'xs':
        return 'xs-breakpoint'
      case 'sm':
        return 'sm-breakpoint'
      case 'md':
        return 'md-breakpoint'
      case 'lg':
        return 'lg-breakpoint'
      case 'xl':
        return 'xl-breakpoint'
    }
  }

  get disableExpansionPanel() {
    return (this.$vuetify.breakpoint.smAndUp && this.items.length <= 3) ||
      this.items.length === 1 ||
      (this.$vuetify.breakpoint.mdAndUp && this.items.length <= 4)
      ? 'hide-expansion-panel-icon'
      : null
  }

  filterItemsDependingOnBreakpoint(min: number, max: number = this.items.length) {
    return this.items.filter((_item, index) => index >= min && index <= max)
  }
}
</script>

<style lang="scss" scoped>
@include fade-transition;

.totals-container {
  background-color: $blue-07 !important;
  margin: 10px 0;

  &.xs-breakpoint {
    ::v-deep .v-expansion-panel-content__wrap {
      display: block;
    }
  }

  &.lg-breakpoint,
  &.xl-breakpoint,
  &.hide-expansion-panel-icon {
    ::v-deep .v-expansion-panel-header {
      pointer-events: none;

      .v-expansion-panel-header__icon {
        display: none;
      }
    }
  }

  ::v-deep &.v-expansion-panel--active > .v-expansion-panel-header {
    min-height: 50px;
    margin-bottom: 4px;
  }

  ::v-deep .v-expansion-panel-header {
    height: 50px;
    padding: 0;
    background-color: $gray-04;

    .v-expansion-panel-header__icon {
      @include expansion-panel-icon;
    }
  }

  ::v-deep .v-expansion-panel-content__wrap {
    display: flex;
    background-color: $gray-04;
    padding: 0;
  }
}
</style>
