<template lang="pug">
ValidationProvider(
    :rules="addRegexToValidationRules"
    v-slot="{ errors }"
    tag="div"
    :vid="id"
    :mode="validationMode"
  )
  div(
    :class="[{ 'error-styles': errors[0] },'lf-textfield-container', { 'hidden-field': hidden }, { 'disabled-field': disabled }]"
  )
    div(v-if="label" class="label-container")
      div(class="label-text") {{ label }}
      span(
        v-if="requiredField"
        class="required-field") *
      v-tooltip(
          v-if="showInfo"
          top
          color="primary"
          dark
        )
        template(v-slot:activator="{ on, attrs }")
          span(v-bind="attrs" v-on="on" :class="[icons.info, 'label-icon']")
        span(class="show-info-message") {{ showInfo }}
    div(class="lf-textfield")
      input(
        :value="innerValue"
        :type="type"
        :disabled="disabled"
        :placeholder="placeholder"
        :id="id"
        :name="id"
        :class="[{ 'error-styles': errors[0] }, 'lf-textfield-input']"
        @input="emitValue"
        @blur="emitValueOnBlur"
        @change="handleChange()"
        :autocomplete="autocompleteType"
        v-bind="props"
      )
      span(
        v-if="hasValue"
        :class="[icons.clearIcon, 'clean-icon']"
        @click.stop="clearText"
      )
    div(class="messages-container")
      span(v-if="errors.length" class="alert-message") {{ errors[0] }}
      span(v-else class="help-message" v-html="helpText")
</template>

<script lang="ts">
import { Vue, Component, Prop, Emit, Watch } from 'vue-property-decorator'
import { ValidationProvider } from 'vee-validate'
import { Icons } from '@/icons/icons'
import { regexPattern } from '@/helpers/helpers'
import DniValidatorMixin from '@/mixins/DniValidatorMixin.vue'
import IDNumberProfessionalValidatorMixin from '@/mixins/IDNumberProfessionalValidatorMixin.vue'

@Component({
  components: {
    ValidationProvider
  },
  mixins: [DniValidatorMixin, IDNumberProfessionalValidatorMixin]
})
export default class LexonTextFieldComponent extends Vue {
  /*
   * string type "fileRequired|fileExtension|fileSize"
   * Object type {"required":true,"max":255,"regex":"^[^*':\"/\\|<>?]+$"}
   */
  @Prop({
    default: ''
  })
  validationRules!: string

  @Prop({
    type: String
  })
  helpText!: string

  @Prop({
    type: String
  })
  label!: string

  @Prop({
    type: String,
    default: ''
  })
  autocompleteType!: string

  @Prop({
    type: String
  })
  showInfo!: string

  @Prop({
    type: Boolean,
    default: false
  })
  disabled!: boolean

  @Prop({
    type: Boolean,
    default: false
  })
  hidden!: boolean

  @Prop({
    type: String,
    required: true
  })
  id!: string

  @Prop({
    type: [String, Number],
    default: ''
  })
  value!: string | number

  @Prop({
    type: String
  })
  placeholder!: string

  @Prop({
    type: Boolean,
    default: false
  })
  fullWidth!: boolean

  @Prop({
    type: String,
    default: 'text'
  })
  type!: string

  /**
   * Validation Mode event
   * @values  aggressive | passive | lazy | eager | onlyInput
   * Aggressive: Triggered when the user presses a key (on input).
   * Passive: Triggered when the form is submitted.
   * Lazy: Triggered when the user leaves the input (on blur or change).
   * Eager: Is a combination of aggressive and lazy, as it first validates when the user leaves the input
   *  then if the input is invalid it will behave aggressively until the input is valid again
   *  and it will go back to being lazy.
   * ...more information: https://vee-validate.logaretm.com/v3/guide/interaction-and-ux.html#interaction-modes
   * @LexOn custom modes:
   * onlyInput: Triggered only when the user presses a key
   */
  @Prop({
    type: String,
    default: 'aggressive'
  })
  validationMode!: string

  /**
   * Array of custom function on change event
   * @values validateUniqueDni
   */
  @Prop({
    type: Array,
    default: () => []
  })
  onChange!: []

  @Prop({
    type: Object,
    default: () => {}
  })
  props!: object

  icons = {
    clearIcon: Icons.CLOSE_ROUND,
    info: Icons.INFO_FULL
  }

  innerValue: string | number = ''

  @Emit()
  emitValue(event: Event) {
    const target = event.target as HTMLInputElement
    return this.$emit('input', target.value)
  }

  @Emit()
  emitValueOnBlur(event: Event) {
    const target = event.target as HTMLInputElement
    return this.$emit('blur', target.value)
  }

  get addRegexToValidationRules() {
    if (!this.validationRules && !this.validationRules.length) {
      return `regex:${regexPattern}`
    }
    if (typeof this.validationRules === 'string') {
      return this.validationRules.includes('onlyCharnumber')
        ? this.validationRules
        : `${this.validationRules}|regex:${regexPattern}`
    }
    return this.validationRules
  }

  get requiredField() {
    if (typeof this.validationRules === 'string') {
      return this.validationRules && this.validationRules.includes('required') ? true : false
    }
    return 'required' in this.validationRules ? true : false
  }

  get hasValue() {
    return (this.value && this.value.toString().length) || (this.innerValue && this.innerValue.toString().length)
  }

  @Watch('innerValue')
  changedValue(newVal: string) {
    this.$emit('input', newVal)
  }

  @Watch('value')
  changeInnerValue(newVal: string) {
    this.innerValue = newVal
  }

  clearText() {
    this.$emit('input', null)
    this.$emit('blur', null)
  }

  created() {
    if (this.value) {
      this.innerValue = this.value
    }
  }

  handleChange() {
    if (this.onChange && this.onChange.length !== 0) {
      this.onChange.forEach((action: any) => {
        ;(this as any)[action](this.value)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
$text-color: $gray-01;

.lf-textfield-container {
  @include flex($flex-direction: column, $align-items: flex-start);
  @include textfield-messages;
  width: 100%;

  ::v-deep strong {
    color: $corporate-color;
  }

  &.disabled-field {
    pointer-events: none;

    .lf-textfield:hover {
      border-color: $gray-03;

      .clean-icon {
        display: none;
      }
    }

    .lf-textfield {
      border: 1px solid $gray-03 !important;
    }

    .lf-textfield .lf-textfield-input {
      border-top: 1px solid $gray-03;
      border-bottom: 1px solid $gray-03;
      background-color: $gray-04;
    }

    .lf-textfield .lf-textfield-input {
      color: $gray-01;
    }

    .label-container .required-field,
    .label-container .label-text,
    .messages-container .help-message,
    .alert-message {
      color: $gray-02 !important;
    }
  }

  &.hidden-field {
    display: none;
  }

  &.error-styles {
    .label-container .label-text {
      color: $error-color;
    }

    .lf-textfield {
      background-color: $red-07;
      border-color: $error-color;

      &:hover {
        background-color: $red-07;
        border-color: $error-color;
      }

      .clean-icon {
        color: $error-color;
      }
    }

    .lf-textfield-input::placeholder {
      color: $error-color !important;
    }
  }

  .label-container {
    @include flex;
    color: $corporate-color;

    .label-text {
      @include label-text;
    }

    .required-field {
      @include flex;
      line-height: 1px;
      font-size: 20px;
      color: $red-01;
      margin-right: 4px;
    }

    .label-icon {
      display: block;
      font-size: 15px;
      margin-bottom: 2px;
      cursor: pointer;
    }
  }

  .lf-textfield {
    @include borders($color: $gray-03);
    @include flex;
    position: relative;
    width: 100%;
    height: 40px;

    &:hover {
      border-color: $corporate-color;
      background-color: $blue-07;
    }

    &:hover .clean-icon {
      display: block;
    }

    .show-info-message {
      background-color: red !important;
    }

    .clean-icon {
      position: absolute;
      right: 10px;
      cursor: pointer;
      font-size: 20px;
      color: $corporate-color;
      display: none;
    }

    .lf-textfield-input {
      @include flex($justify-content: flex-start);
      width: 100%;
      height: 40px;
      font-family: $secondary-font;
      font-size: 14px;
      color: $gray-01;
      padding-right: 36px;
      padding-left: 10px;
      pointer-events: all;

      &:hover::placeholder {
        color: $blue-04;
      }

      &:focus ~ .clean-icon {
        display: block;
      }
    }

    ::placeholder {
      @include placeholder;
    }
  }
}
</style>
