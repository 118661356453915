export interface NotificationsState {
  status: string | null
  data: object
  loginViewNotification: LoginViewNotification | {}
}

export interface LoginViewNotification {
  type: string
  text: string
}

export const notificationsMutationTypes = {
  FETCH_LOGIN_VIEW_NOTIFICATION: 'FETCH_LOGIN_VIEW_NOTIFICATION'
}
