<template lang="pug">

  transition(name="fade" appear)
    div(:class="['alert-container', type]")
      div(class="alert-text-container")
        span(:class="[ icon, 'alert-icon' ]")
        span(class="alert-text" v-html="message")

</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator'
import { AlertsTypes } from '@/store/modules/alerts/alertsTypes'
import { Icons } from '@/icons/icons'

@Component
export default class FormAlertComponent extends Vue {
  @Prop({
    type: String,
    required: true
  })
  message!: string

  icon = Icons.INFO

  type = AlertsTypes.INFO
}
</script>

<style lang="scss" scoped>
@include fade-transition;

.alert-container {
  @include flex($justify-content: space-between);
  width: 100%;
  min-height: 50px;
  color: $white-01;
  padding: 4px 20px;
  box-shadow: 0px 0px 7px 0px rgba(86, 86, 86, 0.5);
  z-index: 99;

  &.info-text {
    background-color: $blue-02;
  }

  .alert-text-container {
    @include flex;

    .alert-icon {
      font-size: 30px;
      opacity: 0.5;
    }

    .alert-text {
      font-family: $corporate-font;
      font-size: 14px;
      margin-left: 25px;

      ::v-deep a {
        font-family: $corporate-font;
        font-size: 14px;
        font-weight: bold;
        color: $white-01;
        text-decoration: none;
      }
    }
  }
}
</style>
