import { Module } from 'vuex'
import { getters } from '@/store/modules/customerPortal/customerPortalGetters'
import { actions } from '@/store/modules/customerPortal/customerPortalActions'
import { mutations } from '@/store/modules/customerPortal/customerPortalMutations'
import { CustomerPortalState } from '@/store/modules/customerPortal/customerPortalTypes'
import { RootState } from '@/store/types/storeGlobalTypes'

export const state: CustomerPortalState = {
  customerPortalFormData: []
}

const namespaced: boolean = true

export const customerPortalModule: Module<CustomerPortalState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations
}
