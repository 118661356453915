import axios, { AxiosRequestConfig } from 'axios'

const axiosConfiguration: AxiosRequestConfig = {
  baseURL: process.env.VUE_APP_API_URL,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
    Accept: 'application/json'
  }
}

const httpNoAuthorizationHeader = axios.create(axiosConfiguration)

export default httpNoAuthorizationHeader
