<template lang="pug">

  div(v-if="showDialog" class="dialog-container")
    div(class="dialog-background")
    div(class="dialog-message-container")
      header(:class="['header-container']")
        span(class="header-title") {{ title }}
        v-spacer
        span(
          @click.stop="closeDialog"
          class="lf-icon-close close-icon"
        )
      div(class="message-container")
        form(class="container-form save-form" @submit.prevent="actionDialog")
          v-col()
            div(class="label-text") {{ nameLabel }}
            span(class="required-field") *
            div(class="lf-textfield")
              input(
                v-model="name"
                :class="['lf-textfield-input']"
                @input="checkFields"
              )
          v-col()
            div(class="label-text") {{ descriptionLabel }}
            div(class="lf-textfield")
              textarea(
                v-model="description"
                :class="['lf-textarea']"
                :maxlength="maxlength"
              )
            div(
              v-if="maxlength"
              :class="[{ 'limit-reached': maxNumberCharactersExceed }, 'counter']"
            ) {{ description ? description.length : '0' }} / {{ maxlength }}
      div(class="actions-container")
        button(
          @click.stop="closeDialog"
          class="button secondary-action-button"
        ) {{ cancelButton  }}
        button(
          @click.stop="actionDialog"
          :disabled="disableSave"
          class="button main-action-button"
        ) {{ saveButton }}

</template>

<script lang="ts">
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'

@Component
export default class SaveDialog extends Vue {
  @Prop({
    default: false
  })
  showDialog: boolean

  @Prop({
    default: false
  })
  rename: boolean

  @Prop({
    required: true
  })
  report: any

  @Prop({
    required: true
  })
  title: any

  nameLabel: any = this.$t('forms.reports.labels.name')
  namePlaceholder: any = this.$t('forms.reports.placeholders.name')
  descriptionLabel: any = this.$t('forms.reports.labels.description')
  descriptionPlaceholder: any = this.$t('forms.reports.placeholders.description')
  saveButton: any = this.$t('action_buttons.save')
  cancelButton: any = this.$t('action_buttons.cancel')
  name: string = ''
  description: string = ''
  disableSave: boolean = false
  maxlength: number = 300

  @Watch('showDialog')
  onUpdate() {
    this.updateFields()
  }

  closeDialog() {
    this.$emit('closeDialog')
  }

  actionDialog() {
    if (this.rename) {
      this.$emit('renameDialog', {
        reportName: this.name,
        name: this.name,
        description: this.description,
        saveAs: false
      })
    } else {
      this.$emit('saveDialog', {
        reportName: this.name,
        name: this.name,
        description: this.description,
        saveAs: true
      })
    }
  }

  checkFields() {
    this.disableSave = true
    if (this.name.length > 0) {
      this.disableSave = false
    }
  }

  updateFields() {
    this.name = this.report.name
    this.description = this.report.description
    this.checkFields()
  }

  get maxNumberCharactersExceed() {
    return this.description && this.description.length > Number(this.maxlength)
  }

  async created() {
    this.updateFields()
  }
}
</script>

<style lang="scss" scoped>
.dialog-container {
  @include flex;
  position: absolute;
  left: 0;
  right: 0;
  top: 60px;
  bottom: 0;
  overflow: hidden;
  z-index: 9999999;
  align-items: flex-start;

  .dialog-background {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: $white-01;
    opacity: 0.7;
  }

  .dialog-message-container {
    position: fixed;
    width: 350px;
    min-height: 250px;
    font-family: $corporate-font;
    border: 1px solid $blue-01;
    padding: 15px;
    top: 50%;
    transform: translateY(-50%);
    background-color: $white-01;

    .header-container {
      @include flex;
      width: 100%;
      color: $blue-01;
      padding: 0;

      .header-icon {
        font-size: 50px;
      }

      .close-icon {
        @include flex;
        width: 35px;
        height: 35px;
        cursor: pointer;
      }

      .header-title {
        font-size: 22px;
      }
    }

    .message-container {
      background-color: $white-01;

      .col {
        padding-left: 0;
        padding-right: 0;
      }

      .label-text {
        float: left;
        color: $blue-01;
        font-size: 0.9em;
        font-weight: 800;
        padding-left: 10px;
      }

      .required-field {
        float: left;
        color: $red-01;
        font-size: 1.3em;
        margin-left: 3px;
      }

      .lf-textfield {
        input,
        textarea {
          width: 100%;
          border: 1px solid $gray-03;
          padding: 9px 10px;
          font-size: 0.9em;

          &:focus {
            border-color: $blue-01 !important;
          }
        }

        textarea {
          height: 80px;
        }
      }

      .counter {
        text-align: end;
        font-size: 0.7em;
        margin-bottom: 10px;
      }
    }

    .actions-container {
      @include flex($justify-content: flex-end);
      background-color: $white-01;

      .main-action-button {
        @include main-action-button--rectangle;

        &:disabled {
          opacity: 0.5;
        }
      }

      .secondary-action-button {
        @include secondary-action-button--rectangle;
      }

      .button {
        min-width: 60px;
      }
    }
  }
}
</style>
