import { i18n } from '@/plugins/vue-i18n'
import { Icons } from '@/icons/icons'

export function renderContextMenuGridItemsHelper(items: string[]) {
  const contextMenuOptions: any = {
    EDIT: {
      text: i18n.t('components.context_menu.edit'),
      iconCss: Icons.EDIT
    },
    OPEN_NEW_WINDOW: {
      text: i18n.t('components.context_menu.open_window'),
      iconCss: Icons.OPEN_WINDOW
    },
    DOWNLOAD: {
      text: i18n.t('components.context_menu.download'),
      iconCss: Icons.DOWNLOAD
    },
    GO_TO: {
      text: i18n.t('components.context_menu.go_to'),
      iconCss: Icons.OPEN_WINDOW
    },
    SEPARATOR: {
      separator: true
    },
    SEE: {
      text: i18n.t('components.context_menu.see'),
      iconCss: Icons.VISIBLE
    }
  }
  const renderedOptions: object[] = []

  items.forEach((item: string) => renderedOptions.push(contextMenuOptions[item]))

  return renderedOptions
}
