<template lang="pug">
  div(class="related-form")
    TabHeaderComponent(
      @closeForm="onCloseForm"
      @saveForm="onSaveForm"
      @removeRelated="onRemoveRelated"
      :buttons="buttons"
      :title="getTitle"
    )
    FormGeneratorComponent(
      :schema="relatedFormFieldsParsed"
      @saveFormData="onSaveFormData"
      :fieldValues="formFieldsDataParsed"
      :context="context"
      :permissionsEntity="permissionsEntityProp"
    )

</template>

<script lang="ts">
import { Component, Prop, Mixins } from 'vue-property-decorator'
import { Mutation, Action, Getter } from 'vuex-class'
import { ContextName, ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import { AlertsTypes, ComponentWhereIsRendered } from '@/store/modules/alerts/alertsTypes'
import { i18n } from '@/plugins/vue-i18n'
import TabHeaderComponent from '@/components/tabs/TabHeader/TabHeaderComponent.vue'
import FormGeneratorComponent from '@/components/forms/FormGenerator/FormGeneratorComponent.vue'
import { Icons } from '@/icons/icons'
import { ActionName } from '@/components/ActionsBar/types/ActionBarComponentTypes'
import { DialogTypes } from '@/store/modules/dialog/dialogTypes'
import { entity } from '@/store/modules/entities/entitiesTypes'
import { Endpoint, endpoints as endpointsTypes } from '@/store/modules/endpoint/endpointTypes'
import { ExpedientFields } from '@/views/Expedients/types/ExpedientFieldsTypes'
import PermissionsMixin from '@/mixins/PermissionsMixin.vue'

const alertsModule = ModuleNamespaces.ALERTS
const configurationModule = ModuleNamespaces.CONFIGURATION
const contactsModule = ModuleNamespaces.CONTACTS
const dialogModule = ModuleNamespaces.DIALOG
const errorsModule = ModuleNamespaces.ERROR
const formsModule = ModuleNamespaces.FORMS
const entitiesModule = ModuleNamespaces.ENTITIES
const expedientsModule = ModuleNamespaces.EXPEDIENTS
const selectedRegisterModule = ModuleNamespaces.SELECTED_REGISTER

@Component({
  components: {
    FormGeneratorComponent,
    TabHeaderComponent
  }
})
export default class RelatedForm extends Mixins(PermissionsMixin) {
  @Prop({
    type: [String, Number],
    required: true
  })
  selectedRegisterId!: string | number

  @Prop({
    type: String,
    required: true
  })
  context!: string

  @Prop({
    type: Object,
    default: () => ({})
  })
  endpoints!: Endpoint

  @Prop({
    type: String,
    required: true
  })
  currentTabName!: string

  @Prop({
    type: Boolean,
    required: true
  })
  editMode!: boolean

  @Prop({
    type: String,
    required: false
  })
  maintenanceType!: string

  @Prop({
    type: Number
  })
  permissionsEntityProp: number

  @Action('showAlert', { namespace: alertsModule })
  showAlert: ({}) => {}

  @Action('showDialog', { namespace: dialogModule })
  showDialog: ({}) => {}

  @Action('saveRelatedForm', { namespace: formsModule })
  saveRelatedForm: ({}) => {}

  @Action('listRelatedForm', { namespace: formsModule })
  listRelatedForm: ({}) => {}

  @Action('removeRelatedFormItem', { namespace: formsModule })
  removeRelatedFormItem: ({}) => {}

  @Action('fetchCurrentListConfiguration', { namespace: configurationModule })
  fetchCurrentListConfiguration: (alias: string) => {}

  @Mutation('CHANGE_RELATED_FORM_STATUS', { namespace: formsModule })
  changeRelatedFormStatus: ({}) => void

  @Mutation('VALIDATE_FORM', { namespace: formsModule })
  emitSignToValidateForm: () => void

  @Mutation('RESET_SELECTED_RELATED_DATA', { namespace: selectedRegisterModule })
  resetSelectedRelatedData: () => void

  @Getter('getSelectedEntityName', { namespace: entitiesModule })
  selectedEntityName: string

  @Getter('getRelatedFormFields', { namespace: configurationModule })
  relatedFormFields: []

  @Getter('getCurrentListConfigurationlistName', { namespace: configurationModule })
  listName: string

  @Getter('existsEndpointErrorStatus', { namespace: errorsModule })
  existsEndpointError: boolean

  @Getter('relatedFormWithSelectedRegisterData', { namespace: selectedRegisterModule })
  formFieldsData: object

  @Getter('getSelectedRegisterData', { namespace: selectedRegisterModule })
  selectedRegisterData: (context: string) => any

  @Getter('getSelectedRelatedData', { namespace: selectedRegisterModule })
  selectedRelatedData: any

  @Getter('checkIfFormIsValid', { namespace: formsModule }) checkIfFormIsValid: (context: string) => boolean

  @Getter('getMenuParentCategoryName', { namespace: entitiesModule })
  menuParentCategoryName: string

  @Getter('getExpedientStageState', { namespace: expedientsModule })
  expedientStageState: []

  @Getter('getContactTypeSelected', { namespace: contactsModule })
  getContactTypeSelected: string

  get buttons() {
    if (this.editMode) {
      return [
        ...(this.checkEntityPermissionsGetter(this.permissionsEntityProp).canDelete
          ? [
              {
                icon: Icons.REMOVE,
                tooltip: this.$t('action_buttons.remove'),
                class: 'blue-color',
                action: ActionName.REMOVE,
                show: true
              }
            ]
          : []),
        {
          icon: Icons.CLOSE,
          tooltip: this.$t('action_buttons.close'),
          class: 'red-color',
          action: ActionName.CLOSE,
          show: true
        },
        ...(this.editMode && this.checkEntityPermissionsGetter(this.permissionsEntityProp).canSave
          ? [
              {
                icon: Icons.CHECK,
                tooltip: this.$t('action_buttons.save'),
                class: 'green-color',
                action: ActionName.SAVE,
                show: this.checkIfFormIsValid(this.context)
              }
            ]
          : [])
      ]
    } else {
      return [
        {
          icon: Icons.CLOSE,
          tooltip: this.$t('action_buttons.close'),
          class: 'red-color',
          action: ActionName.CLOSE,
          show: true
        },
        {
          icon: Icons.CHECK,
          tooltip: this.$t('action_buttons.save'),
          class: 'green-color',
          action: ActionName.SAVE,
          show: this.checkIfFormIsValid(this.context)
        }
      ]
    }
  }

  get relatedFormFieldsParsed() {
    if ((this as any).expedientStageState && (this as any).expedientStageState['activeStages'] === 0) {
      const stageField = (this as any).relatedFormFields.find((item: any) => {
        return item.id === ExpedientFields.STAGE
      })
      if (stageField) {
        stageField.hidden = true
      }
    }
    return this.relatedFormFields
  }

  get formFieldsDataParsed() {
    if ((this as any).expedientStageState.stageId && (this as any).formFieldsData[ExpedientFields.STAGE]) {
      if ((this as any).formFieldsData[ExpedientFields.STAGE].id === undefined) {
        ;(this as any).formFieldsData[ExpedientFields.STAGE].id = (this as any).expedientStageState[
          'stageId'
        ].toString()
      }
    }
    if (
      this.maintenanceType === 'manProviderInvoicesConcepts' ||
      this.maintenanceType === 'manCustomerInvoicesConcepts'
    ) {
      return this.prepareInvoicesConceptsFormat(this.formFieldsData)
    }
    return this.formFieldsData
  }

  formData: any = {}

  formatedEndpoints: Endpoint = { get: '', save: '', delete: '', list: '' }

  get currentId(): string {
    if (this.selectedRegisterId) {
      return this.selectedRegisterId.toString()
    }
    return '0'
  }

  get getTitle() {
    const title: any = Object.keys(this.selectedRelatedData).length
      ? this.$t('forms.' + this.currentTabName + '.edit_title', {
          name: this.selectedRelatedData.name || this.selectedRelatedData.code || this.selectedRelatedData.colorName
        })
      : this.$t('forms.' + this.currentTabName + '.new_title')
    return title
  }

  prepareInvoicesConceptsFormat(register: any) {
    const formatted: any = {
      ...register,
      iva: '0',
      ivaValue: '',
      igic: '0',
      igicValue: '',
      irpf: '0',
      irpfValue: '',
      igi: '0',
      igiValue: '',
      ipsi: '0',
      ipsiValue: ''
    }

    if (register.grouping && register.defaultTax) {
      const grouping = register.grouping.split('::')
      const taxes = register.defaultTax.split('::')

      grouping.forEach((item: any, i: number) => {
        switch (item) {
          case '1':
            formatted.iva = '1'
            formatted.ivaValue = taxes[i]
            break
          case '2':
            formatted.igic = '1'
            formatted.igicValue = taxes[i]
            break
          case '3':
            formatted.irpf = '1'
            formatted.irpfValue = taxes[i]
            break
          case '4':
            formatted.igi = '1'
            formatted.igiValue = taxes[i]
            break
          case '10':
            formatted.ipsi = '1'
            formatted.ipsiValue = taxes[i]
            break
        }
      })
    }

    return formatted
  }

  prepareInvoicesConceptsFormatOnSave(data: any) {
    const formatted: any = { ...data, grouping: '', defaultTax: '' }

    // TODO: Taxes list from fetchTaxes
    const taxesIndex = {'1': 'iva', '2': 'igic', '3': 'irpf', '4': 'igi', '10': 'ipsi'}
    Object.entries(taxesIndex).forEach(([key, value]) => {
      const taxValue = `${value}Value`
      if (formatted[value] === '1' && formatted[taxValue]) {
        formatted.grouping += formatted.grouping ? `::${key}` : key
        formatted.defaultTax += formatted.defaultTax ? `::${formatted[taxValue]}` : formatted[taxValue]
      }
    })

    return formatted
  }

  onCloseForm(fetchList: boolean = true) {
    if (fetchList) {
      this.fetchCurrentListConfiguration(this.listName)
    }
    this.resetSelectedRelatedData()
    this.changeRelatedFormStatus({
      status: false,
      context: this.context
    })
    this.$emit('closeForm')
  }

  async onSaveForm() {
    this.emitSignToValidateForm()
    let formData: any = {}
    let filterList: object | null = null
    let saveOk: any

    if (this.currentTabName === entity.contacts.alias) {
      const { name, position, email, phone, notes } = (this as any).formData
      formData = {
        id: this.selectedRelatedData.id || 0,
        idContact: this.currentId,
        name,
        position: position || '',
        email: email || '',
        phone: phone || '',
        notes: notes || ''
      }
      this.formatedEndpoints.save = String.format(this.endpoints.save, this.selectedEntityName)
      this.formatedEndpoints.list = String.format(this.endpoints.list, this.selectedEntityName, this.currentId)
    } else if (this.currentTabName === entity.expedients.interveners.alias && this.formData.name) {
      formData = {
        ...this.formData,
        id: this.selectedRelatedData.id || 0
      }
      const intervenerContactEndpoint = (endpointsTypes as any).contactsGeneric
      this.formatedEndpoints.save = String.format(intervenerContactEndpoint.save, this.getContactTypeSelected)
      this.formatedEndpoints.list = String.format(this.endpoints.list, this.currentId)
      filterList = {
        idExpedient: this.currentId,
        idStage: (this as any).expedientStageState['stageId']
      }
    } else if (this.currentTabName === entity.expedients.interveners.alias) {
      const { idStage, intervener, intervenerType, publish } = (this as any).formData
      const stage = this.parseStage(idStage)
      formData = {
        id: this.selectedRelatedData.id || 0,
        idExpedient: this.currentId,
        idStage: stage || 0,
        idIntervener: intervener,
        idIntervenerType: intervenerType ? intervenerType.id : null,
        publish: publish || 0
      }
      filterList = {
        idExpedient: this.currentId,
        idStage: (this as any).expedientStageState['stageId']
      }
      this.formatedEndpoints.save = this.endpoints.save
      this.formatedEndpoints.list = String.format(this.endpoints.list, this.currentId)
    } else if (this.currentTabName === entity.expedients.courts.alias) {
      const { idStage, court, writNumber, numberExecution, numberAppeal, notes, publish } = (this as any).formData
      const stage = this.parseStage(idStage)
      formData = {
        id: this.selectedRelatedData.id || 0,
        idExpedient: this.currentId,
        idStage: stage || 0,
        idCourt: court,
        writNumber,
        numberExecution,
        numberAppeal,
        notes,
        publish: publish || 0
      }
      filterList = {
        idExpedient: this.currentId,
        idStage: (this as any).expedientStageState['stageId']
      }
      this.formatedEndpoints.save = this.endpoints.save
      this.formatedEndpoints.list = String.format(this.endpoints.list, this.currentId)
    } else if (this.context === entity.users.alias) {
      const userId: any = this.$route.params.selectedRegisterId
      const formData: any = {}

      this.formatedEndpoints.save = 'user/professionals'
      this.formatedEndpoints.list = 'maintenance/manProfessionals/get'

      if (this.currentTabName === 'agendas') {
        this.formatedEndpoints.save = 'schedule'
        this.formatedEndpoints.list = `schedule/${userId}/get`
        formData.idSchedule = this.formData.name.id
        formData.idUser = userId
      } else {
        formData.idProfessional = this.formData.name.id
        formData.idUser = userId
      }
    } else if (this.context === entity.maintenance.alias) {
      formData = {
        ...this.formData,
        id: this.selectedRelatedData ? this.selectedRelatedData.id : 0
      }
      if (
        this.maintenanceType === 'manProviderInvoicesConcepts' ||
        this.maintenanceType === 'manCustomerInvoicesConcepts'
      ) {
        formData = this.prepareInvoicesConceptsFormatOnSave(formData)
      }
      this.formatedEndpoints.save = `maintenance/${this.maintenanceType}/save/post`
      this.formatedEndpoints.list = `maintenance/${this.maintenanceType}/get`
    } else if (this.context === ContextName.MAINTENANCE_USERS_RELATED_FORM) {
      const { id: selectedUserId } = this.selectedRegisterData(ContextName.MAINTENANCE_USERS_CONFIG)
      const { name } = this.formData
      formData = {
        idCompany: name.id,
        idUser: selectedUserId
      }
      this.formatedEndpoints.save = 'user/professionals'
      this.formatedEndpoints.list = `user/professionals?idUser=${selectedUserId}`
    } else if (
      this.menuParentCategoryName === entity.actions.alias ||
      this.menuParentCategoryName === entity.expedients.alias
    ) {
      const { idActionType, idRelation } = (this as any).formData
      formData = {
        id: this.selectedRelatedData.id || 0,
        idAction: this.currentId,
        idActionRelationType: idActionType.id,
        idRelation: idRelation.id,
        idStage: null
      }

      if (this.menuParentCategoryName === entity.expedients.alias) {
        const stage: any = this.expedientStageState

        if (stage.stageId) {
          ;(formData as any).idStage = stage.stageId
        }
      }

      this.formatedEndpoints.save = this.endpoints.save
      this.formatedEndpoints.list = String.format(this.endpoints.list, this.currentId)
    }
    saveOk = await this.saveRelatedForm({
      url: this.formatedEndpoints.save,
      data: formData
    })
    await this.listRelatedForm({
      url: this.formatedEndpoints.list,
      filterData: filterList
    })

    this.onCloseForm(false)
    if (saveOk) {
      const message: any = this.editMode
        ? i18n.t(`components.alerts.${this.currentTabName}_edit`)
        : i18n.t(`components.alerts.${this.currentTabName}_save`)
      this.showAlert({
        type: AlertsTypes.SUCCESS,
        message,
        componentWhereIsRendered: ComponentWhereIsRendered.TABS_VIEW
      })
    }
  }

  parseStage(idStage: any) {
    let stage = 0
    if (typeof idStage !== 'object') {
      stage = idStage
    } else {
      idStage.id ? (stage = idStage.id) : (stage = 0)
    }
    return stage
  }

  onSaveFormData(schema: [], formData: object, field: object, value: any) {
    this.formData = formData
    this.$emit('field-changed', schema, formData, field, value)
  }

  async removeRelated() {
    let filterList: object | null = null
    if (this.currentTabName === entity.contacts.alias) {
      this.formatedEndpoints.delete = String.format(
        this.endpoints.delete,
        this.selectedEntityName,
        this.selectedRelatedData.id
      )
      this.formatedEndpoints.list = String.format(this.endpoints.list, this.selectedEntityName, this.currentId)
    } else if (this.context === entity.maintenance.alias) {
      this.formatedEndpoints.delete = `maintenance/${this.maintenanceType}/${this.selectedRelatedData.id}`
      this.formatedEndpoints.list = `maintenance/${this.maintenanceType}/get`
    } else {
      this.formatedEndpoints.delete = String.format(this.endpoints.delete, this.selectedRelatedData.id)
      this.formatedEndpoints.list = String.format(this.endpoints.list, this.currentId)
      if (
        this.currentTabName === entity.expedients.interveners.alias ||
        this.currentTabName === entity.expedients.courts.alias
      ) {
        filterList = {
          idExpedient: this.currentId,
          idStage: (this as any).expedientStageState['stageId']
        }
      }
    }

    await this.removeRelatedFormItem({
      url: this.formatedEndpoints.delete
    })
    if (!this.existsEndpointError) {
      const message: any = this.$t('components.dialog.' + this.currentTabName + '_remove_success', {
        associateRelated: this.selectedRelatedData.name || this.selectedRelatedData.code,
        related: (this as any).selectedRegisterData(this.context).name
      })
      this.showDialog({
        type: DialogTypes.SUCCESS,
        message
      })
      this.onCloseForm(false)
      await this.listRelatedForm({
        url: this.formatedEndpoints.list,
        filterData: filterList
      })
    }
  }

  onRemoveRelated() {
    const message: any = this.$t('components.dialog.' + this.currentTabName + '_remove', {
      associateRelated: this.selectedRelatedData.name || this.selectedRelatedData.code,
      related: this.selectedRegisterData(this.context).name
    })
    this.showDialog({
      type: DialogTypes.INFO,
      message,
      action: this.removeRelated
    })
  }
}
</script>
<style lang="scss" scoped>
.related-form {
  padding: 0 10px 10px 10px;
}
</style>
