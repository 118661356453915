import { ContactTypes } from '@/store/modules/contacts/contactsTypes'
import { ActionMenu } from '@/store/modules/actions/actionsTypes'
import { BillingTypes } from '@/store/modules/billing/billingTypes'

export interface RootState {
  [x: string]: any
  version: string
}

export enum ModuleNamespaces {
  ACTIONS = 'actionsModule',
  AGENCIES = 'agenciesModule',
  ALERTS = 'alertsModule',
  APPOINTMENTS = 'appointmentsModule',
  AUDITS = 'auditsModule',
  AUTH = 'authModule',
  BILLING = 'billingModule',
  CONFIGURATION = 'configurationModule',
  CONTACTS = 'contactsModule',
  CONTEXT_MENU = 'contextMenuModule',
  CUSTOMERS = 'customersModule',
  CUSTOMER_PORTAL = 'customerPortalModule',
  CUSTOM_FIELDS = 'customFieldsModule',
  DASHBOARD_WIDGETS = 'dashboardWidgetsModule',
  DIALOG = 'dialogModule',
  DOCUMENT_ONLINE = 'documentOnlineModule',
  ENDPOINT = 'endpointModule',
  ENTITIES = 'entitiesModule',
  ERROR = 'errorsModule',
  EXPEDIENTS = 'expedientsModule',
  FILE_MANAGER = 'fileManagerModule',
  FIRMA = 'firmaModule',
  FORMS = 'formsModule',
  GLOBAL = 'globalModule',
  INVOICES = 'invoicesModule',
  LEGAL_SEARCH = 'legalSearchModule',
  LEXBOX = 'lexboxModule',
  LIST_ITEMS = 'listItemsModule',
  MAINTENANCE = 'maintenanceModule',
  MENUS = 'menusModule',
  NOTES = 'notesModule',
  NOTIFICATIONS = 'notificationsModule',
  NUMERATIONS = 'numerationsModule',
  PERMISSIONS = 'permissionsModule',
  PROFESSIONALS = 'professionalsModule',
  REPORTS = 'reportsModule',
  ROUTER = 'routerModule',
  SEARCH = 'searchModule',
  SECURITY = 'securityModule',
  SELECTED_REGISTER = 'selectedRegisterModule',
  SPINNER = 'spinnerModule',
  TABS = 'tabsModule',
  TANDEM_SEARCH = 'tandemSearchModule',
  TASKS = 'tasksModule',
  TEMPLATE = 'templateModule',
  USERS = 'usersModule'
}

export const joomlaEntitiesData = [
  {
    name: ActionMenu.CALLS,
    option: 'com_wgactuaciones',
    view: 'actuaciones',
    layout: 'llamada',
    itemId: 199
  },
  {
    name: ActionMenu.MEETINGS,
    option: 'com_wgactuaciones',
    view: 'actuaciones',
    layout: 'reunion',
    itemId: 200
  },
  {
    name: ActionMenu.PROCEDURES,
    option: 'com_wgactuaciones',
    view: 'actuaciones',
    layout: 'tramite',
    itemId: 201
  },
  {
    name: ActionMenu.OTHERS,
    option: 'com_wgactuaciones',
    view: 'actuaciones',
    layout: 'otra',
    itemId: 202
  },
  {
    name: ActionMenu.EMAILS,
    option: 'com_wgactuaciones',
    view: 'actuaciones',
    layout: 'email',
    itemId: 198
  },
  {
    name: ContactTypes.AGENCIES,
    option: 'com_wgagencias',
    view: 'agencias',
    itemId: 144
  },
  {
    name: ContactTypes.COURTS,
    option: 'com_wgjuzgados',
    view: 'juzgados',
    itemId: 142
  },
  {
    name: ContactTypes.CUSTOMER,
    option: 'com_wgclientes',
    view: 'clientes',
    itemId: 132
  },
  {
    name: ContactTypes.INSURERS,
    option: 'com_wgaseguradoras',
    view: 'aseguradoras',
    itemId: 143
  },
  {
    name: ContactTypes.NOTARIES,
    option: 'com_wgnotarios',
    view: 'notarios',
    itemId: 141
  },
  {
    name: ContactTypes.OPPONENTS,
    option: 'com_wgcontrarios',
    view: 'contrarios',
    itemId: 133
  },
  {
    name: ContactTypes.OPPONENT_ATTORNEYS,
    option: 'com_wgprocuradorescontrarios',
    view: 'procuradorescontrarios',
    itemId: 139
  },
  {
    name: ContactTypes.OPPONENT_LAWYERS,
    option: 'com_wgabogadoscontrarios',
    view: 'abogadoscontrarios',
    itemId: 137
  },
  {
    name: ContactTypes.OTHERS,
    option: 'com_wgotros',
    view: 'otros',
    itemId: 140
  },
  {
    name: ContactTypes.OWN_ATTORNEYS,
    option: 'com_wgprocuradores',
    view: 'procuradores',
    itemId: 138
  },
  {
    name: ContactTypes.OWN_LAWYERS,
    option: 'com_wgabogados',
    view: 'abogados',
    itemId: 135
  },
  {
    name: ContactTypes.PROVIDERS,
    option: 'com_wgproveedores',
    view: 'proveedores',
    itemId: 134
  },
  {
    name: BillingTypes.PROVISIONS,
    option: 'com_wgprovisiones',
    view: 'provisiones',
    itemId: 215
  },
  {
    name: BillingTypes.PROFORMA_INVOICES,
    option: 'com_wgfacturasproforma',
    view: 'facturasproforma',
    itemId: 213
  },
  {
    name: BillingTypes.CUSTOMER_INVOICES,
    option: 'com_wgfacturas',
    view: 'facturas',
    itemId: 192
  },
  {
    name: BillingTypes.CUSTOMER_BANKDRAFTS,
    option: 'com_wgvctos',
    view: 'vctos',
    itemId: 193
  },
  {
    name: BillingTypes.PROVIDER_INVOICES,
    option: 'com_wgfacturasproveedores',
    view: 'facturasproveedores',
    itemId: 209
  },
  {
    name: BillingTypes.PROVIDER_BANKDRAFTS,
    option: 'com_wgvctosproveedores',
    view: 'vctosproveedores',
    itemId: 211
  }
]

export enum ContextName {
  ACTION = 'action',
  ACTION_CALLS = 'actionCalls',
  ACTION_EMAILS = 'actionEmails',
  ACTION_LEXNET = 'actionLexnet',
  ACTION_MEETINGS = 'actionMeetings',
  ACTION_OTHERS = 'actionOthers',
  ACTION_PROCEDURES = 'actionProcedures',
  AUDITS = 'audits',
  BILLING = 'billing',
  BILLING_CUSTOMER_BANKDRAFTS = 'customerBankdrafts',
  BILLING_CUSTOMER_INVOICES = 'customerInvoices',
  BILLING_ORDER_FORMS = 'orderForms',
  BILLING_PROFORMA_INVOICES = 'proformaInvoices',
  BILLING_PROVIDER_BANKDRAFTS = 'providerBankdrafts',
  BILLING_PROVIDER_INVOICES = 'providerInvoices',
  BILLING_PROVISIONS = 'provisions',
  CONTACT = 'contact',
  CONTACT_AGENCIES = 'agencies',
  CONTACT_COURTS = 'courts',
  CONTACT_CUSTOMERS = 'customers',
  CONTACT_INSURERS = 'insurers',
  CONTACT_NOTARIES = 'notaries',
  CONTACT_OPPONENTS = 'opponents',
  CONTACT_OPPONENT_ATTORNEYS = 'opponentAttorneys',
  CONTACT_OPPONENT_LAWYERS = 'opponentLawyers',
  CONTACT_OTHERS = 'others',
  CONTACT_OWN_ATTORNEYS = 'ownAttorneys',
  CONTACT_OWN_LAWYERS = 'ownLawyers',
  CONTACT_PROVIDERS = 'providers',
  CUSTOMERS = 'customers',
  CUSTOMER_INVOICES_BANKDRAFTS_DETAIL_FORM = 'customerInvoicesBankdraftsDetailsForm',
  CUSTOMER_INVOICES_BANKDRAFTS_FORM = 'customerInvoicesBankdraftsForm',
  CUSTOMER_INVOICES_DETAIL_FORM = 'customerInvoicesDetailsForm',
  CUSTOMER_INVOICES_GENERAL_DATA_FORM = 'customerInvoicesGeneralDataForm',
  CUSTOMER_INVOICES_PARTIALBANKDRAFT_FORM = 'customerInvoicesPartialbankdraftForm',
  CUSTOM_FIELDS = 'customFields',
  DEFAULT = 'default',
  DOCUMENT = 'document',
  DOCUMENT_INTERVENERS = 'documentInterveners',
  EXPEDIENTS = 'expedients',
  EXPEDIENT_ACTIONS = 'expedientActions',
  EXPEDIENT_AGENDA = 'expedientAgenda',
  EXPEDIENT_ECONOMIC_DATA = 'expedientEconomicData',
  EXPEDIENT_GLOBAL_VISION_PROFITABILITY = 'globalVisionExpProfitability',
  EXPEDIENT_GLOBAL_VISION_TOP_BAR = 'globalVisionTopBar',
  EXPEDIENT_INSURERS = 'expedientInsurers',
  EXPEDIENT_INSURERS_FORM = 'expedientInsurersForm',
  EXPEDIENT_INVOICES = 'expedientInvoices',
  EXPEDIENT_LAST_ACTIONS_WIDGET = 'globalVisionActSummary',
  EXPEDIENT_REMINDER_NOTES_WIDGET = 'globalVisionExpNotes',
  EXPEDIENT_SCHEDULE_WIDGET = 'globalVisionScheduleExp',
  EXPEDIENT_SUMMARY_WIDGET = 'globalVisionExpSummary',
  MAINTENANCE = 'maintenance',
  MAINTENANCE_CUSTOMER_PORTAL = 'maintenanceCustomerPortal',
  MAINTENANCE_USERS = 'maintenanceUsers',
  MAINTENANCE_USERS_CONFIG = 'usersConfiguration',
  MAINTENANCE_USERS_GRID = 'maintenanceUsersGrid',
  MAINTENANCE_USERS_RELATED_FORM = 'maintenanceUsersRelatedForm',
  NUMERATION = 'numeration',
  NUMERATION_FORM = 'numerationForm',
  PERIODIC_BILLING_TAB = 'periodicBillingTab',
  PROFORMA_INVOICES_DETAIL_FORM = 'proformaInvoicesDetailsForm',
  PROFORMA_INVOICES_GENERAL_DATA_FORM = 'proformaInvoicesGeneralDataForm',
  PROFORMA_INVOICES_PAYMENT_METHODS_FORM = 'proformaInvoicesPaymentMethodsForm',
  PROVIDER_INVOICES_BANKDRAFTS_DETAIL_FORM = 'providerInvoicesBankdraftsDetailsForm',
  PROVIDER_INVOICES_BANKDRAFTS_FORM = 'providerInvoicesBankdraftsForm',
  PROVIDER_INVOICES_DETAIL_FORM = 'providerInvoicesDetailsForm',
  PROVIDER_INVOICES_GENERAL_DATA_FORM = 'providerInvoicesGeneralDataForm',
  REPORTS = 'reports',
  REPORTS_LIST = 'reportsList',
  REPORT_DETAIL = 'reportDetail',
  REPOSITORY = 'repository',
  SEARCH = 'search',
  TANDEM_FILTERS = 'TandemFilter',
  TEMPLATE = 'template',
  USERS = 'users'
}

export enum ValidationRules {
  REQUIRED = 'required',
  VALIDATION_RULES = 'validationRules'
}
