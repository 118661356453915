import { createElement } from '@syncfusion/ej2-base'
import { DropDownList } from '@syncfusion/ej2-dropdowns'
import { BooleanFilter, BooleanFilterItem } from '@/store/modules/listItems/listItemsTypes'
import { IFilter } from '@syncfusion/ej2-grids'

const filterBooleanItems: BooleanFilter = {}
let dropDowInstance: DropDownList

const customBooleanFilter: IFilter = {
  ui: {
    create: (args: any) => {
      const divFilters: HTMLElement | null = args.dialogObj.element.querySelector(
        '.e-flm_optrdiv'
      ) as HTMLElement | null

      if (divFilters) {
        divFilters.style.display = 'none'
      }

      const flValInput = createElement('input', { className: 'flm-input' })
      args.target.appendChild(flValInput)

      const filters: any = filterBooleanItems[args.column.field]

      dropDowInstance = new DropDownList({
        dataSource: filters,
        fields: { value: 'value', text: 'text' }
      })

      dropDowInstance.appendTo(flValInput)

      const divDropDownList: HTMLElement | null = args.dialogObj.element.querySelector(
        '.e-flmenu-valuediv'
      ) as HTMLElement | null

      if (divDropDownList) {
        divDropDownList.style.padding = '0'
      }
    },
    write: (args: any) => {
      dropDowInstance.value = args.filteredValue ? args.filteredValue : ''
      if (args.filteredValue !== undefined) {
        const filters = filterBooleanItems[args.column.field]
        const filter: BooleanFilterItem | undefined = filters.find(
          (item: BooleanFilterItem) =>
            item.value === dropDowInstance.value || (!dropDowInstance.value && !item.booleanValue)
        )
        if (filter) {
          dropDowInstance.text = filter.text
        }
      }
    },
    read: (args: any) => {
      args.fltrObj.filterByColumn(args.column.field, args.operator, dropDowInstance.value)
    }
  }
}

export default function getCustomBooleanFilter(columnConf: any) {
  filterBooleanItems[columnConf.field] = columnConf.filterBooleanItems
  return customBooleanFilter
}
