// https://kazupon.github.io/vue-i18n/
import { Vue } from 'vue-property-decorator'
import VueI18n from 'vue-i18n'
import { createI18n } from 'vue-i18n-composable'
import englishTranslations from '../translations/en.json'
import spanishTranslations from '../translations/es.json'


Vue.use(VueI18n)

export const i18n = createI18n({
  locale: 'es',
  messages: {
    en: englishTranslations,
    es: spanishTranslations
  }
})
