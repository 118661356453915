<template lang="pug">
  div
    span( class="lf-icon-kebab-menu context-menu-launcher")

</template>

<script lang="ts">
import { Vue } from 'vue-property-decorator'
import { Component } from 'vue-property-decorator'

@Component({
  name: 'contextMenuColumnTemplate'
})
export default class ContextMenuTemplateComponent extends Vue {}
</script>
