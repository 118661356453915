export enum BreakpointTypes {
  XS = 'xs',
  SM = 'sm',
  MD = 'md',
  LG = 'lg',
  XL = 'xl'
}

export enum BreakpointClosedMenuTypes {
  XS = 'closed-menu-xs',
  SM = 'closed-menu-sm',
  MD = 'closed-menu-md',
  LG = 'closed-menu-lg',
  XL = 'closed-menu-xl'
}

export enum OffsetTypes {
  XS = 'offset-xs',
  SM = 'offset-sm',
  MD = 'offset-md',
  LG = 'offset-lg',
  XL = 'offset-xl'
}

export enum OffsetClosedMenuTypes {
  XS = 'offset-closed-menu-xs',
  SM = 'offset-closed-menu-sm',
  MD = 'offset-closed-menu-md',
  LG = 'offset-closed-menu-lg',
  XL = 'offset-closed-menu-xl'
}
