<template lang="pug">

    v-tooltip(
        class="lexon-tooltip-container"
        v-bind="tooltipPosition"
        content-class="lexon-tooltip-component"
      )
      template(v-slot:activator="{ on, attrs }")
        span(
          v-bind="attrs"
          v-on="on"
          :class="[icon, 'tooltip-icon']"
        )
      p(class="lefebvre-tooltip-message") {{ message }}

</template>

<script lang="ts">
import { Icons } from '@/icons/icons'
import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

@Component
export default class LexonTooltipComponent extends Vue {
  @Prop({
    type: String
  })
  position!: 'top' | 'left' | 'bottom' | 'right'

  @Prop({
    type: String,
    default: Icons.INFO
  })
  icon!: string

  @Prop({
    type: String,
    required: true
  })
  message!: string

  get tooltipPosition() {
    switch (this.position) {
      case 'top':
        return { top: true }
      case 'bottom':
        return { bottom: true }
      case 'left':
        return { left: true }
      default:
        return { right: true }
    }
  }
}
</script>

<style lang="scss" scoped>
.tooltip-icon {
  position: relative;
  top: 1.5px;
  font-size: 18px;
  padding-top: 2px;
  margin-left: 10px;
  cursor: pointer;
}
</style>

<style lang="scss">
.v-tooltip__content.lexon-tooltip-component {
  @include borders;
  border-radius: 0;
  padding: 0;
  opacity: 1 !important;
  z-index: 9999 !important;

  .lefebvre-tooltip-message {
    max-width: 250px;
    font-size: 11px;
    color: $gray-01;
    background-color: $white-01;
    line-height: 15px;
    opacity: 1 !important;
    padding: 10px;
    margin-bottom: 0 !important;
  }
}
</style>
