import { extend } from 'vee-validate'
import { email, max, min, regex, required, numeric } from 'vee-validate/dist/rules'
import { i18n } from '@/plugins/vue-i18n'

extend('required', {
  ...required,
  message: i18n.t('validations.required') as string
})

extend('searchLimit', {
  ...required,
  message: i18n.t('validations.search_limit') as string
})

extend('max', {
  ...max,
  message: (_, length) => i18n.t('validations.max', length) as string
})

extend('min', {
  ...min,
  message: (_, length) => i18n.t('validations.min', length) as string
})

extend('regex', {
  ...regex,
  message: i18n.t('validations.invalid_characters') as string
})

extend('email', {
  ...email,
  message: i18n.t('validations.email') as string
})

extend('numeric', {
  ...numeric,
  message: i18n.t('validations.numeric') as string
})
