import { extend } from 'vee-validate'
import { required } from 'vee-validate/dist/rules'
import { i18n } from '@/plugins/vue-i18n'
import store from '@/store/store'
import { ModuleNamespaces } from '@/store/types/storeGlobalTypes'

extend('fileRequired', {
  ...required,
  validate(value) {
    return null !== value && typeof value.name !== 'undefined'
  },
  message: i18n.t('validations.required') as string
})

extend('fileExtension', {
  validate(value) {
    if (value && value.name) {
      return store.getters[`${ModuleNamespaces.FILE_MANAGER}/hasAllowedExtension`](value.name)
    }
  },
  message: i18n.t('validations.extension_not_allowed') as string
})

extend('fileSize', {
  validate(value) {
    if (value && value.size) {
      return store.getters[`${ModuleNamespaces.FILE_MANAGER}/checkMaxFileUploadSize`](value.size)
    }
  },
  message: (_field: string, _params: Record<string, any>): string => {
    const maxFileUploadSize = store.getters[`${ModuleNamespaces.FILE_MANAGER}/getMaxFileUploadSize`]
    return i18n.t('validations.max_file_upload_size_exceeded', {
      maxFileUploadSize: maxFileUploadSize.sizeFormatted
    }) as string
  }
})
