<template lang="pug">

  DashboardWidgetListType(
    class="dashboard-widget"
    v-bind="lastCustomersDashboardListProps"
  )

</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import DashboardWidgetListType from '@/components/widgets/DashboardWidgets/widgetTypes/DashboardWidgetListType.vue'
import { DashboardListProps } from '@/store/modules/dashboardWidgets/dashboardWidgetsTypes'
import { Action, Getter } from 'vuex-class'
import { ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import { entity } from '@/store/modules/entities/entitiesTypes'
import { URLS } from '@/router/routes/urlRoutes'
import { SpinnerDashboardContext } from '@/store/modules/spinner/spinnerTypes'
import { Icons } from '@/icons/icons'
import { DashboardCustomer } from '@/store/modules/dashboardWidgets/dashboardWidgetsTypes'

const dashboardWidgetsModule: string = ModuleNamespaces.DASHBOARD_WIDGETS
const spinnersModule: string = ModuleNamespaces.SPINNER

@Component({
  components: {
    DashboardWidgetListType
  }
})
export default class DashboardLastCustomersListWidget extends Vue {
  @Getter('getDashboardLastCustomersList', { namespace: dashboardWidgetsModule })
  lastCustomersGetter: DashboardCustomer[]

  @Getter('getDashboardWidgetsSpinner', { namespace: spinnersModule })
  showSpinner: (context: string) => boolean

  @Action('fetchDashboardLastCustomers', { namespace: dashboardWidgetsModule })
  fetchDashboardLastCustomersAction: () => DashboardCustomer[]

  get lastCustomersDashboardListProps(): DashboardListProps {
    return {
      items: this.lastCustomersGetter,
      entity: entity.contacts.customers.alias,
      headers: [
        'components.dashboard_widget_list_type.customers.headers.name',
        'components.dashboard_widget_list_type.customers.headers.phone',
        'components.dashboard_widget_list_type.customers.headers.email'
      ],
      headerText: 'components.dashboard_widget_list_type.customers.last_customers',
      url: `${URLS.CONTACTS}/${URLS.CUSTOMERS}`,
      showSpinner: this.showSpinner(SpinnerDashboardContext.LAST_CUSTOMERS_LIST),
      noResultIcon: Icons.CONTACTS,
      goToAllText: this.$t('components.dashboard_widget_list_type.customers.go_to_all_text') as string
    }
  }

  created() {
    // Hace la petición solo si ha guardado algun contacto. Utiliza el estado del spinner como un flag
    if (this.showSpinner(SpinnerDashboardContext.LAST_CUSTOMERS_LIST)) {
      this.fetchDashboardLastCustomersAction()
    }
  }
}
</script>

<style lang="scss" scoped>
.dashboard-widget {
  ::v-deep .table-header tr.table-row:last-child th {
    border-bottom-color: $customer-color !important;
  }
}
</style>
