import ExpedientsView from '@/views/Expedients/ExpedientsView.vue'
import { URLS } from '@/router/routes/urlRoutes'
import { entity } from '@/store/modules/entities/entitiesTypes'
import { ContextName } from '@/store/types/storeGlobalTypes'
import { ConfigurationAlias, ConfigurationTypes } from '@/store/modules/configuration/configurationTypes'
import ExpedientDetailView from '@/views/Expedients/ExpedientDetailView.vue'
import { ExpedientEntityId } from '@/general/entityIds'

export const expedientsRoutes = [
  {
    path: `/${URLS.EXPEDIENTS}`,
    name: URLS.EXPEDIENTS,
    component: ExpedientsView,
    meta: {
      requiresAuth: true,
      title: URLS.EXPEDIENTS,
      entityType: ExpedientEntityId.EXPEDIENTS
    }
  },
  {
    path: `/${URLS.EXPEDIENT}/:selectedRegisterId`,
    name: `${URLS.EXPEDIENTS}-${URLS.EXPEDIENTS}`,
    component: ExpedientDetailView,
    props: (route: { params: { selectedRegisterId: any } }) => ({
      menuName: URLS.EXPEDIENTS,
      submenuName: null,
      context: ContextName.EXPEDIENTS,
      configuration: {
        type: ConfigurationTypes.VIEW,
        alias: ConfigurationAlias.EXPEDIENT
      },
      entityType: entity.expedients.type,
      entityId: !isNaN(parseInt(route.params.selectedRegisterId, 10))
        ? parseInt(route.params.selectedRegisterId, 10)
        : 0
    }),
    meta: {
      requiresAuth: true,
      parentUrl: URLS.EXPEDIENTS,
      title: URLS.EXPEDIENT,
      entityType: ExpedientEntityId.EXPEDIENTS
    }
  }
]
