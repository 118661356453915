import { MutationTree } from 'vuex'
import { DialogState } from '@/store/modules/dialog/dialogTypes'

export const mutations: MutationTree<DialogState> = {
  SHOW_DIALOG(state, dialogData) {
    state.showDialog = true
    state.dialogData = dialogData
  },
  CLOSE_DIALOG(state) {
    state.showDialog = false
    state.dialogData = null
  },
  SHOW_CUSTOM_DIALOG(state, customDialogData) {
    state.customDialogData = customDialogData
    state.showCustomDialog = true
  },
  CLOSE_CUSTOM_DIALOG(state) {
    state.showCustomDialog = false
    state.customDialogData = null
  }
}
