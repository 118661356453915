interface NumerationMaintenanceType {
  [index: string]: object
}

export interface NumerationsState {
  numerationsMaintenance: NumerationMaintenanceType
  numerationsValues: object
}

export const numerationsMutationTypes = {
  FETCH_NUMERATIONS_MAINTENANCE: 'FETCH_NUMERATIONS_MAINTENANCE',
  FETCH_NUMERATIONS_VALUES: 'FETCH_NUMERATIONS_VALUES'
}
