import { GetterTree } from 'vuex'
import {
  AuthState,
  Company,
  UserInfo,
  UserType,
  AREA_FIRMA,
  AREA_TANDEM,
  PermissionVerbs,
  PermissionItem
} from '@/store/modules/auth/authTypes'
import { RootState } from '@/store/types/storeGlobalTypes'
import { ConfigurationAppointmentVersion } from '@/store/modules/configuration/configurationTypes'

export const getters: GetterTree<AuthState, RootState> = {
  getUser(state): UserInfo | null {
    if (state.authData && state.authData.user) {
      return state.authData.user
    }
    return null
  },
  userIsAuthenticated(state): boolean {
    return !!state.authData
  },
  getUserId(state): number | null {
    if (state.authData) {
      return state.authData.user.id
    }
    return null
  },
  getUserType(state): UserType {
    if (state.authData) {
      return state.authData.user.type as UserType
    }
    return UserType.UNDEFINED
  },
  getUserNif(state): string | null {
    if (state.authData) {
      return state.authData.user.nif
    }
    return null
  },
  getUserMobilePhone(state): string | null {
    if (state.authData) {
      return state.authData.user.mobilePhone
    }
    return null
  },
  getCompanies(state): Company[] | null {
    if (state.preAuthData) {
      return state.preAuthData.user.companies
    }
    return null
  },
  getCompany(state): Company | null {
    if (state.authData) {
      return state.authData.user.company
    }
    return null
  },
  getCompanyConfiguration(_state, getters) {
    const company = getters.getCompany
    const companyParams = company.params
    return JSON.parse(companyParams)
  },
  getCompanyAppointmentsVersion(_state, getters): ConfigurationAppointmentVersion {
    const configuration = getters.getCompanyConfiguration
    const appointmentVersion =
      configuration && configuration['agenda_app_version'] ? parseInt(configuration['agenda_app_version'], 10) : 1
    return 2 === appointmentVersion
      ? ConfigurationAppointmentVersion.LEXBOX_VERSION
      : ConfigurationAppointmentVersion.INTERNAL_VERSION
  },
  getCompanyMailVersion(_state, getters): ConfigurationAppointmentVersion {
    const configuration = getters.getCompanyConfiguration
    const mailVersion =
      configuration && configuration['mail_app_version'] ? parseInt(configuration['mail_app_version'], 10) : 1
    return 2 === mailVersion
      ? ConfigurationAppointmentVersion.LEXBOX_VERSION
      : ConfigurationAppointmentVersion.INTERNAL_VERSION
  },
  getCompanyTemplate(_state, getters): boolean {
    const configuration = getters.getCompanyConfiguration
    return !!(configuration && Number(configuration['template']) === 1)
  },
  getCompanyTemplateInvoices(_state, getters): boolean {
    const configuration = getters.getCompanyConfiguration
    return !!(configuration && configuration['templateInvoice'] && Number(configuration['templateInvoice']) === 1)
  },
  getCompanyDefaultStage(_state, getters): boolean {
    const configuration = getters.getCompanyConfiguration
    return (configuration && Number(configuration['defaultStage']) === 1)
  },
  getCompanyId(state): number | null {
    if (state.authData) {
      return state.authData.user.company.idCompany
    }
    return null
  },
  getCompanyDataBase(state): string | null {
    if (state.authData) {
      return state.authData.user.company.database
    }
    return null
  },
  getProUserId(state): string | null {
    if (state.authData) {
      return state.authData.user.idProUser
    }
    return null
  },
  getLastAccess(state): string | null {
    if (state.authData) {
      return state.authData.user.lastAccess as string
    }
    return null
  },
  userHasAccessToLegalProducts(state): boolean {
    if (state.authData) {
      return state.authData.user.hasAccessToLegalProducts
    }
    return false
  },
  userHasDoubleOptinLogin(state): boolean {
    if (state.preAuthData) {
      return state.preAuthData.user.doubleOptin
    }
    return false
  },
  userHasFirma(state): boolean {
    return Boolean(state!.authData!.user!.subscription.includes(AREA_FIRMA))
  },
  userHasTandem(state): boolean {
    if (state.authData) {
      return state.authData.user.subscription.includes(AREA_TANDEM)
    }
    return false
  },
  getEncryptedIdProUser(state): string | null {
    if (state.authData) {
      return state.encryptIdProUser
    }
    return null
  },
  getNavisionId(state): string | null {
    if (state.authData) {
      return state.authData.user.company.navisionId
    }
    return null
  },
  getUserName(state): string {
    if (state.authData) {
      const { firstName, lastName } = state.authData!.user
      return lastName ? `${firstName} ${lastName}` : firstName
    }
    return ''
  },
  getIsPortalUser(state): boolean | null {
    if (state.authData) {
      return state.authData.user.type === UserType.PORTAL
    }
    return null
  },
  getPortalCustomerId(state): number | null {
    if (state.authData) {
      return state.authData.user.customerId
    }
    return null
  },
  getIsLexonDmsUser(state): boolean | null {
    if (state.authData) {
      return state.authData.user.type === UserType.LEXON_DMS
    }
    return null
  },
  getIsLexonUser(state): boolean | null {
    if (state.authData) {
      return state.authData.user.type === UserType.LEXON
    }
    return null
  },
  getUserNameAbbreviation(_state, getters) {
    const userName = getters.getUserName
    const userNameAbbreviation = userName
      .split(' ')
      .map((word: string, index: number) => {
        return index <= 2 ? word[0] : null
      })
      .join('')

    return userNameAbbreviation
  },
  getCompanyName(state): string {
    return state.companyName
  },
  getUserEmail(state): string | null {
    if (state.authData) {
      return state.authData.user.email
    }
    return null
  },
  getRefreshToken(state): string | null {
    if (state.authData) {
      return state.authData.refreshToken
    }
    return null
  },
  getAuthToken(state): string | null {
    if (state.authData) {
      return state.authData.accessToken
    }
    return null
  },
  userIsPreAuthenticated(state): boolean {
    return !!state.preAuthData
  },
  getCancelToken(state) {
    return state.cancelTokens
  },
  getLastRefreshTokenTime(state) {
    return state.lastRefreshTokenTime
  },
  getAllPermission(state): PermissionItem[] | null {
    if (state.authData && state.authData.user) {
      return state.authData.user.permission
    }
    return null
  },
  getPermission:
    (_state, getters) =>
    (entityId: number | string): PermissionVerbs => {
      try {
        const { getAllPermission } = getters
        const { permission } = getAllPermission.find(
          ({ idEntityType }: PermissionItem) => idEntityType === Number(entityId)
        )
        return permission
      } catch (error) {
        // tslint:disable-next-line: no-console
        console.log({ error, entityId })
        return { canDelete: 1, canSave: 1, canView: 1 }
      }
    },
  getTrackUser(state) {
    const { authData } = state
    const { id, idProUser } = authData!.user
    const { navisionId, idCompany } = authData!.user.company
    return {
      id,
      ccNavision: navisionId,
      userId: idProUser,
      companyId: idCompany
    }
  }
}
