import { Module } from 'vuex'
import { getters } from '@/store/modules/legalSearch/legalSearchGetters'
import { actions } from '@/store/modules/legalSearch/legalSearchActions'
import { mutations } from '@/store/modules/legalSearch/legalSearchMutations'
import { LegalSearchState } from '@/store/modules/legalSearch/legalSearchTypes'
import { RootState } from '@/store/types/storeGlobalTypes'

export const state: LegalSearchState = {
  data: [],
  tabs: [],
  sessionId: '',
  isSearchView: false,
  searchTerm: '',
  totalOccurrences: 0
}

const namespaced: boolean = true

export const legalSearchModule: Module<LegalSearchState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations
}
