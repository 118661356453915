import { ActionTree } from 'vuex'
import { AuditsState } from '@/store/modules/audits/auditsTypes'
import { RootState } from '@/store/types/storeGlobalTypes'
import { auditsMutationTypes } from '@/store/modules/audits/auditsTypes'
import { MainService } from '@/services/MainService'

export const actions: ActionTree<AuditsState, RootState> = {
  async fetchAudits({ commit }, filterData): Promise<void> {
    try {
      const url = 'audits/get'
      const { data } = await new MainService().postData(url, filterData)
      commit(auditsMutationTypes.FETCH_AUDITS, data)
      return data
    } catch (error) {}
  }
}
