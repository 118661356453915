export enum RenderedForm {
  CUSTOMER_DETAIL_FORM = 'customerInvoicesDetailsForm',
  CUSTOMER_GENERAL_DATA_FORM = 'customerInvoicesGeneralDataForm',
  CUSTOMER_BANKDRAFTS_FORM = 'customerInvoicesBankdraftsForm',
  CUSTOMER_BANKDRAFTS_DETAIL_FORM = 'customerInvoicesBankdraftsDetailsForm',
  PROFORMA_DETAIL_FORM = 'proformaInvoicesDetailsForm',
  PROFORMA_GENERAL_DATA_FORM = 'proformaInvoicesGeneralDataForm',
  PROFORMA_PAYMENT_METHODS_FORM = 'proformaInvoicesPaymentMethodsForm',
  PROVIDER_DETAIL_FORM = 'providerInvoicesDetailsForm',
  PROVIDER_GENERAL_DATA_FORM = 'providerInvoicesGeneralDataForm',
  PROVIDER_BANKDRAFTS_FORM = 'providerInvoicesBankdraftsForm',
  PROVIDER_BANKDRAFTS_DETAIL_FORM = 'providerInvoicesBankdraftsDetailsForm',
  CUSTOMER_PARTIALBANKDRAFTS_FORM = 'customerInvoicesPartialbankdraftsForm'
}

export enum InvoiceTabName {
  CUSTOMER_BANKDRAFTS = 'customerInvoicesBankdrafts',
  CUSTOMER_GENERAL_DATA = 'customerInvoicesGeneralData',
  PROFORMA_GENERAL_DATA = 'proformaInvoicesGeneralData',
  PROFORMA_PAYMENT_METHODS = 'proformaInvoicesPaymentMethods',
  PROVIDER_GENERAL_DATA = 'providerInvoicesGeneralData',
  PROVIDER_BANKDRAFTS = 'providerInvoicesBankdrafts'
}

export enum InvoicesFormFields {
  CHARGED = 'charged',
  CHARGE_DATE = 'chargeDate',
  CORRECTIVE_SELECT = 'correctiveSource',
  CORRECTIVE_SWITCH = 'isCorrective',
  DISCOUNT_FIELDSET = 'appliedDiscount',
  DISCOUNT_TYPE = 'idDiscountType',
  DISCOUNT_VALUE = 'discountValue',
  EXPEDIENT_CUSTOMER_FACT = 'customerfact',
  EXPEDIENT_ID = 'idExpedient',
  EXPIRATION_DAY_BETWEEN = 'daysBetweenExpirations',
  EXPIRATION_DAY_FIRST = 'dayFirstExpiration',
  EXPIRATION_NUMBER = 'numberExpirations',
  EXPIRATION_TEXT = 'textInvoicePaymentType',
  FORM_BUTTON = 'formButton',
  PAID = 'paid',
  PAYMENT_DATE = 'paymentDate',
  PAYMENT_TYPE = 'idPaymentType'
}

export enum ViewMode {
  INVOICE_DETAIL = 'INVOICE_DETAIL',
  PARTIAL_BANKDRAFT = 'PARTIAL_BANKDRAFT',
}
