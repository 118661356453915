import { GetterTree } from 'vuex'
import { TabsState } from '@/store/modules/tabs/tabsTypes'
import { RootState } from '@/store/types/storeGlobalTypes'

export const getters: GetterTree<TabsState, RootState> = {
  getDisabledTabs(state): boolean {
    return state.disabledTabs
  },
  getCurrentTabInfo(state) {
    return state.currentTabInfo
  },
  getCurrentSearchTabInfo(state) {
    return state.currentSearchTabInfo
  },
  getTabSelectorOptions: (state) => (idEntity: number) => {
    return state.tabSelectorOptions[idEntity]
  }
}
