import { GetterTree } from 'vuex'
import { FirmaState } from '@/store/modules/firma/firmaTypes'
import { RootState } from '@/store/types/storeGlobalTypes'

export const getters: GetterTree<FirmaState, RootState> = {
  getSignInfo(state) {
    return state.firmaSignInfo
  },
  getMonitoredAuditsListItems(state) {
    return state.monitoredAudits
  },
  getAuditsStatusInfo(state) {
    return state.auditsStatusInfo
  },
  getFirmaToken(state) {
    return state.firmaToken
  }
}
