import { ActionTree } from 'vuex'
import { RootState } from '@/store/types/storeGlobalTypes'
import {
  TandemFilter,
  TandemFilterType,
  tandemSearchMutationTypes,
  TandemSearchState
} from '@/store/modules/tandemSearch/tandemSearchTypes'
import { MainService } from '@/services/MainService'

export const actions: ActionTree<TandemSearchState, RootState> = {
  async fetchSearchData({ commit }, { term, metadata, from = 0, size = 10 }) {
    try {
      commit(tandemSearchMutationTypes.SET_SEARCH_TERM, term)
      commit(tandemSearchMutationTypes.SET_SEARCH_METADATA, metadata)

      term = encodeURIComponent(term.trim().replace(/[\\\'\"]/g, ''))

      const url = `/search/tandem/get?term=${term}&from=${from}&size=${size}`

      const metadataParsed: any = {}
      if (metadata && metadata.length) {
        metadata.forEach((item: any) => {
          if (metadataParsed[item.name]) {
            metadataParsed[item.name].push(item.value)
          } else {
            metadataParsed[item.name] = [item.value]
          }
        })
      }
      const { data } = await new MainService().postData(url, metadataParsed)

      commit(tandemSearchMutationTypes.SET_SEARCH_DATA, data)
    } catch (error) {
      commit(tandemSearchMutationTypes.RESET_SEARCH_DATA)
    }
  },
  async initFilters({ commit }, filters: TandemFilter[]) {
    await Promise.all(
      filters.map(async (filter: TandemFilter) => {
        if (filter.endpoint) {
          const { data } = await new MainService().getData(filter.endpoint)
          filter.items = data
          filter.value = false
        } else if (TandemFilterType.DATE_INTERVAL === filter.type) {
          filter.items = []
          filter.value = ''
        } else if (TandemFilterType.SWITCH === filter.type) {
          filter.items = []
          filter.value = false
        }
      })
    )
    commit(tandemSearchMutationTypes.SET_FILTERS, filters)
  }
}
