import { Module } from 'vuex'
import { getters } from '@/store/modules/customers/customersGetters'
import { actions } from '@/store/modules/customers/customersActions'
import { mutations } from '@/store/modules/customers/customersMutations'
import { CustomersState } from '@/store/modules/customers/customersTypes'
import { RootState } from '@/store/types/storeGlobalTypes'

export const state: CustomersState = {
  total: ''
}

const namespaced: boolean = true

export const customersModule: Module<CustomersState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations
}
