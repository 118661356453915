import { ActionTree } from 'vuex'
import { MenusState, menusMutationTypes } from '@/store/modules/menus/menusTypes'
import { RootState } from '@/store/types/storeGlobalTypes'
import { MenusService } from '@/services/MenusService'

export const actions: ActionTree<MenusState, RootState> = {
  async fetchSiteMenu({ commit }) {
    try {
      const url = 'menus/site'
      const response = await new MenusService().getData(url)
      const { data } = response
      commit(menusMutationTypes.FETCH_SITE_MENU, data)
    } catch (error) {}
  },
  async fetchConfigurationMenu({ commit }) {
    try {
      const url = 'menus/configuration'
      const response = await new MenusService().getData(url)
      const { data } = response
      commit(menusMutationTypes.FETCH_CONFIGURATION_MENU, data)
    } catch (error) {}
  },
  async fetchClientMenu({ commit }) {
    try {
      const url = 'menus/client'
      const response = await new MenusService().getData(url)
      const { data } = response
      commit(menusMutationTypes.FETCH_CLIENT_MENU, data)
    } catch (error) {}
  }
}
