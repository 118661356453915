<template lang="pug">

  section(class="remember-container")

    HeaderComponent

    section(class="remember-content")
      LoginFormComponent

</template>

<script lang="ts">
import { Vue, Component } from 'vue-property-decorator'
import HeaderComponent from '@/components/Header/HeaderComponent.vue'
import LoginFormComponent from '@/components/forms/loginForm/LoginFormComponent.vue'

@Component({
  components: {
    HeaderComponent,
    LoginFormComponent
  }
})
export default class RememberPasswordView extends Vue {}
</script>

<style lang="scss" scoped>
.remember-container {
  display: grid;
  grid-template-rows: 60px 1fr;
  height: 100%;
}

.remember-content {
  @include flex;
  background-color: $gray-04;
  font-family: $corporate-font-medium;
  margin: 30px;
}
</style>
