import { Module } from 'vuex'
import { getters } from '@/store/modules/firma/firmaGetters'
import { mutations } from '@/store/modules/firma/firmaMutations'
import { actions } from '@/store/modules/firma/firmaActions'
import { FirmaState } from '@/store/modules/firma/firmaTypes'
import { RootState } from '@/store/types/storeGlobalTypes'

export const state: FirmaState = {
  auditsStatusInfo: [],
  firmaToken: null,
  monitoredAudits: null,
  firmaSignInfo: null
}

const namespaced: boolean = true

export const firmaModule: Module<FirmaState, RootState> = {
  namespaced,
  actions,
  state,
  getters,
  mutations
}
