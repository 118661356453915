import { ActionTree } from 'vuex'
import { NotesState, notesMutationTypes, moduleEndpoints } from '@/store/modules/notes/notesTypes'
import { RootState } from '@/store/types/storeGlobalTypes'
import { MainService } from '@/services/MainService'

export const actions: ActionTree<NotesState, RootState> = {
  async fetchNotes({ commit }, filter) {
    try {
      const url = moduleEndpoints.FETCH
      const { data } = await new MainService().postData(url, filter)
      commit(notesMutationTypes.FETCH_NOTES, data)
    } catch (error) {}
  },
  async saveNote(_context, noteData) {
    try {
      const url = moduleEndpoints.SAVE
      await new MainService().postData(url, noteData)
    } catch (error) {}
  },
  async deleteNote(_context, noteId) {
    try {
      const url = String.format(moduleEndpoints.DELETE, noteId)
      await new MainService().deleteData(url)
    } catch (error) {}
  }
}
