import { externalServices } from '@/plugins/axios'
import { AxiosRequestConfig } from 'axios'

const defaultConfig: AxiosRequestConfig = {
  headers: { 'Content-Type': 'application/json' }
}
export class ExternalService {
  constructor() {}

  getData(url: string) {
    return externalServices.get(url)
  }

  putData(url: string, data: object) {
    return externalServices.put(url, data, { headers: { 'Content-Type': 'application/json' } })
  }

  postData(url: string, data: object, config: AxiosRequestConfig = defaultConfig) {
    return externalServices.post(url, data, config)
  }

  postFormData(url: string, formData: {}, config: AxiosRequestConfig = defaultConfig) {
    const params = new URLSearchParams()
    Object.entries(formData).forEach(([key, value]) => params.append(key, String(value)))
    return externalServices.post(url, params, config)
  }
}
