import { GetterTree } from 'vuex'
import { UsersState } from '@/store/modules/users/usersTypes'
import { RootState } from '@/store/types/storeGlobalTypes'
import { formatFieldsTypeDateEPOCH } from '@/helpers/dateTime'

export const getters: GetterTree<UsersState, RootState> = {
  getUsersListWithFormattedDates(state) {
    return formatFieldsTypeDateEPOCH(state.users)
  },
  getPermissions(state): UsersState[] | null {
    return state.permissions
  },
  getUsersData(state): [] | null {
    return state.users
  },
  permissionsHaveChanged(state): boolean {
    return JSON.stringify(state.permissions) !== JSON.stringify(state.originPermissions)
  }
}
