import { Module } from 'vuex'
import { RootState } from '@/store/types/storeGlobalTypes'
import { EndpointState } from '@/store/modules/endpoint/endpointTypes'
import { getters } from '@/store/modules/endpoint/endpointGetters'
import { mutations } from '@/store/modules/endpoint/endpointMutations'
import { actions } from '@/store/modules/endpoint/endpointActions'

export const state: EndpointState = {
  endpoints: {}
}

const namespaced: boolean = true

export const endpointModule: Module<EndpointState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations
}
