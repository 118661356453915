import { GetterTree } from 'vuex'
import { RootState } from '@/store/types/storeGlobalTypes'
import { TandemSearchState } from '@/store/modules/tandemSearch/tandemSearchTypes'

export const getters: GetterTree<TandemSearchState, RootState> = {
  getSearchData(state) {
    return state.data
  },
  getSearchFacets(state) {
    return state.facets
  },
  getSearchMetadata(state) {
    return state.searchMetadata
  },
  getSearchTerm(state) {
    return state.searchTerm
  },
  getTotalOccurrences(state) {
    return state.totalOccurrences
  },
  getFilters(state) {
    return state.filters
  },
  getDescriptors(state) {
    return state.descriptors
  },
  getPaginationData(state) {
    return state.paginationData
  }
}
