import { Module } from 'vuex'
import { getters } from '@/store/modules/users/usersGetters'
import { actions } from '@/store/modules/users/usersActions'
import { mutations } from '@/store/modules/users/usersMutations'
import { UsersState } from '@/store/modules/users/usersTypes'
import { RootState } from '@/store/types/storeGlobalTypes'

export const state: UsersState = {
  users: [],
  permissions: [],
  originPermissions: []
}

const namespaced: boolean = true

export const usersModule: Module<UsersState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations
}
