import { MutationTree } from 'vuex'
import { BillingState } from '@/store/modules/billing/billingTypes'

export const mutations: MutationTree<BillingState> = {
  FETCH_BILLING_DATA(state, data) {
    state.billing = data
  },
  FETCH_TOTALS(state, totals) {
    state.totals = totals
  },
  RESET_BILLING_DATA(state) {
    state.billing = null
  },
  RESET_TOTALS(state) {
    state.totals = null
  },
  SET_LAST_LISTBILLING_FILTER(state, item) {
    state.lastListBillingFilter = item
  }
}
