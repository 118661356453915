import { Module } from 'vuex'
import { getters } from '@/store/modules/maintenance/maintenanceGetters'
import { actions } from '@/store/modules/maintenance/maintenanceActions'
import { mutations } from '@/store/modules/maintenance/maintenanceMutations'
import { MaintenanceState } from '@/store/modules/maintenance/maintenanceTypes'
import { RootState } from '@/store/types/storeGlobalTypes'

export const state: MaintenanceState = {
  actionsSummary: [],
  dashboardMaintenanceList: [],
  maintenance: [],
  lastMaintenanceSaved: null,
  stageStatus: [],
  summary: [],
  taxesItems: [],
  total: '',
  economicDataSimpleGridItems: [],
  selectedMaintenanceInsurer: {}
}

const namespaced: boolean = true

export const maintenanceModule: Module<MaintenanceState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations
}
