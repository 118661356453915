import { Route } from 'vue-router'
import { URLS } from '@/router/routes/urlRoutes'
import store from '@/store/store'
import { ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import { UserType } from '@/store/modules/auth/authTypes'

export const dmsGuard = (to: Route, _from: Route, next: any): void => {
  const userType: UserType = store.getters[`${ModuleNamespaces.AUTH}/getUserType`]
  if (UserType.LEXON_DMS === userType && to.name) {
    if (
      ![
        URLS.REPOSITORY,
        URLS.DASHBOARD,
        URLS.DOUBLE_OPTIN,
        URLS.LOGIN,
        URLS.EXTERNAL_LOGOUT,
        URLS.SEARCH,
        URLS.MAINTENANCE_DOCUMENTS
      ].includes(to.name)
    ) {
      next(`/${URLS.REPOSITORY}`)
    }
  }
  next()
}


