<template lang="pug">

div(class="lex-on-app")
  v-app(full-height)
    SpinnerComponent
    SpinnerLayerComponent(
      v-if="showSpinnerLayer"
      class="spinner-layer-component"
    )
    DialogComponent
    CustomDialogComponent
    v-main
      v-container(fluid pa-0 class="app-container")
        NavigationContainerComponent(v-if="!hideNavigation")
        router-view(:key="ckeckIfReloadURL" :style="{width: viewWidth}")

  LefebvreFooterComponent

</template>

<script lang="ts">
import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import { URLS } from '@/router/routes/urlRoutes'
import { Getter } from 'vuex-class'
import { ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import CustomDialogComponent from '@/components/Dialog/CustomDialog/CustomDialogComponent.vue'
import DialogComponent from '@/components/Dialog/DialogComponent.vue'
import LefebvreFooterComponent from '@/components/Footer/LefebvreFooterComponent.vue'
import NavigationContainerComponent from '@/components/Navigation/NavigationContainer/NavigationContainerComponent.vue'
import SpinnerComponent from '@/components/Spinner/SpinnerComponent.vue'
import SpinnerLayerComponent from '@/components/Spinner/SpinnerLayerComponent.vue'
 import { vars } from '@/styles/styleVars'

const expedientsModule = ModuleNamespaces.EXPEDIENTS
const spinnerModule = ModuleNamespaces.SPINNER
const menusModule = ModuleNamespaces.MENUS

@Component({
  components: {
    CustomDialogComponent,
    DialogComponent,
    NavigationContainerComponent,
    SpinnerComponent,
    SpinnerLayerComponent,
    LefebvreFooterComponent
  }
})
export default class App extends Vue {
  @Getter('getDuplicateExpedientId', { namespace: expedientsModule })
  duplicateExpedientId: number

  @Getter('getShowSpinnerLayer', { namespace: spinnerModule })
  showSpinnerLayer: boolean

  @Getter('getDrawerStatus', { namespace: menusModule })
  drawerInMiniStatus: boolean

  get hideNavigation(): boolean {
    return [
      URLS.AGENDA,
      URLS.EMAIL,
      `${URLS.EMAIL}-${URLS.EDIT}`,
      `${URLS.EMAIL}-${URLS.ACTIONS}`,
      `${URLS.EMAIL}-${URLS.NEW}`,
      URLS.DOUBLE_OPTIN,
      URLS.LEXNET,
      URLS.LOGIN,
      URLS.NAVISION_LOGIN,
      URLS.NOT_FOUND_PAGE,
      URLS.REMEMBER_PASSWORD,
      URLS.SELECT_COMPANY,
      `${URLS.ANOTHER_APPLICATIONS}-${URLS.SSO_PAYMENTS}`,
      `${URLS.ANOTHER_APPLICATIONS}-${URLS.SSO_UNPAID}`,
      URLS.CALCULATORS,
      URLS.ONLINE
    ].includes(this.$route.name!)
  }

  get ckeckIfReloadURL() {
    if (
      this.$route.name === `${URLS.EXPEDIENTS}-${URLS.EXPEDIENTS}` &&
      (this.$route.query.action || this.duplicateExpedientId)
    ) {
      return Number(this.$route.query)
    }
    return 0
  }

  get viewWidth() {
    const width = this.drawerInMiniStatus ? '63px' : vars.navigation_drawer_width
    return this.hideNavigation ? '100%' : `calc(100% - ${width})`
  }
}
</script>

<style lang="scss">
@import '~@syncfusion/ej2-base/styles/material.css';
@import '~@syncfusion/ej2-buttons/styles/material.css';
@import '~@syncfusion/ej2-calendars/styles/material.css';
@import '~@syncfusion/ej2-dropdowns/styles/material.css';
@import '~@syncfusion/ej2-inputs/styles/material.css';
@import '~@syncfusion/ej2-navigations/styles/material.css';
@import '~@syncfusion/ej2-popups/styles/material.css';
@import '~@syncfusion/ej2-splitbuttons/styles/material.css';
@import '~@syncfusion/ej2-vue-grids/styles/material.css';
@import '~@syncfusion/ej2-vue-richtexteditor/styles/material.css';

html.overflow-y-hidden {
  overflow-y: auto !important;
}

.lex-on-app .theme--light.v-application,
body {
  background-color: $gray-04;
  font-family: $secondary-font;
  color: $gray-01;
  width: 100vw;
}

.v-main {
  min-height: calc(100vh - 120px);
}

.app-container {
  display: flex;
  height: 100%;
}

.v-application--wrap {
  z-index: 201;
  min-height: 100%;
}

.popover {
  z-index: 9999;
}

/* Change Autocomplete styles in Chrome*/
input:-webkit-autofill,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border-top: 1px solid $gray-03;
  border-bottom: 1px solid $gray-03;
  -webkit-text-fill-color: $gray-01;
  box-shadow: 0 0 1px 1000px $white-01 inset;
  -webkit-box-shadow: 0 0 1px 1000px $white-01 inset;
  transition: none;
}
input:-webkit-autofill:hover {
  border-color: $corporate-color;
  box-shadow: 0 0 1px 1000px $blue-07 inset;
}

.stage-selector-input {
  .v-input__append-inner {
    margin-top: 4px;
  }

  .v-menu__content.menuable__content__active.v-autocomplete__content {
    top: 40px !important;
  }
}

.lexon-view-container {
  padding: 167px 30px 15px;
}

.spinner-layer-component {
  --spinner-layer-min-height: 100vh;
  --spinner-layer-background: #f4f4f4;
  --spinner-layer-position: absolute;
}

@include tabletAndMore {
  .lexon-view-container {
    padding-top: $app-bar-height;
  }
}
</style>
