import { entity } from '@/store/modules/entities/entitiesTypes'
import { URLS } from '@/router/routes/urlRoutes'
import { router } from '@/router/router'

/**
 * Get Url by type in this case only for billing types.
 *
 * @param {string} type
 * @param {string} id
 * @return {string} default /
 */
export const getUrlByIdEntity = (type: number, id: string = ''): string => {
  switch (type) {
    case entity.invoices.customers.type:
      return `/${URLS.BILLING}/${URLS.CUSTOMER_INVOICES}/${id}`
    case entity.invoices.providers.type:
      return `/${URLS.BILLING}/${URLS.PROVIDER_INVOICES}/${id}`
    case entity.invoices.proforma.type:
      return `/${URLS.BILLING}/${URLS.PROFORMA_INVOICES}/${id}`
    case entity.invoices.provisions.type:
      return `/${URLS.BILLING}/${URLS.PROVISIONS}/${id}`
    case entity.invoices.orderForms.type:
      return `/${URLS.BILLING}/${URLS.ORDER_FORMS}/${id}`
    default:
      return '/'
  }
}

/**
 * Redirect to anywhere.
 *
 * @param {string} url
 * @param {string} target [self, parent, blank]
 * @return {void}
 */
export const redirectTo = (url: string, target: string = 'self'): void => {
  switch (target) {
    case 'self':
      router.push(url)
      break
    case 'parent':
      router.replace(url)
      break
    case 'blank':
    case '_blank':
      window.open(url, '_blank')
      break
  }
}

export const goToPreviousPage = () => {
  router.back()
}
