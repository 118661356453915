export const encodeSearchTerm = (term: string) => {
  // TODO: Eliminar cuando se elimine la redireccion desde joomla
  return term.replace('&', '-JTJA-').replace('%2F', '-JTJG-').replace('%3F', '-JTNG-').replace('%23', '-JTIz-')
}

export const decodeSearchTerm = (term: string) => {
  // TODO: Eliminar cuando se elimine la redireccion desde joomla
  if (term) {
    term = term.replace('-JTJG-', '/').replace('-JTNG-', '?').replace('-JTIz-', '#').replace('-JTJA-', '&')
  }
  return term
}
