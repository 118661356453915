<template lang="pug">
  div
    GridTable(
      v-if="renderGrid && !showSpinnerLayer"
      :itemsData="isPortalUser ? listItems : billingData"
      :gridConfiguration="listConfiguration['Config']"
      :title="$t('views.order_forms.title')"
      :toolbarOptions="toolbarOptions"
      :contextMenuItems="isPortalUser ? [printButton] : invoiceContextMenuItems"
      :listName="listConfiguration['Alias']"
      :context="context"
      :totals="totals"
      :archivedFilter="archivedSelectedOption"
      :disabledGridCellClicked="getDisableGridProperty"
      @contextMenuClicked="onContextMenuClicked"
      @gridTableFilter="onGridTableFilter"
      @toolbarClicked="onToolbarClicked"
    )

    ejs-contextmenu(
      :id="idContextMenu"
      class="context-menu-component e-contextmenu-archived"
      :items="invoicesArchivedContextMenuItemsParsed"
      :select="onInvoicesArchivedClick"
    )

    TemplateSelectorComponent(
      :showDialog="showTemplateDialog"
      :idEntityType="idEntityType"
      @closeTemplateDialog="closeTemplateDialog"
      @generateTemplateDialog="generateTemplateDialog"
    )

</template>

<script lang="ts">
import { mixins } from 'vue-class-component'
import { Component } from 'vue-property-decorator'
import GridTable from '@/components/grids/GridTable/GridTableComponent.vue'
import { ContextName, ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import ListViewMixin from '@/mixins/ListViewMixin.vue'
import ArchivedFilterMixin from '@/mixins/ArchivedFilterMixin.vue'
import PortalUserMixin from '@/mixins/PortalUserMixin.vue'
import { Action, Getter } from 'vuex-class'
import { ListNames } from '@/store/modules/configuration/configurationTypes'
import { BillingTypes, TotalItem } from '@/store/modules/billing/billingTypes'
import PrintInvoiceContextMenuMixin from '@/mixins/PrintInvoiceContextMenuMixin.vue'
import { entity } from '@/store/modules/entities/entitiesTypes'
import TemplateSelectorComponent from '@/components/template/TemplateSelectorComponent/TemplateSelectorComponent.vue'
import { UserType } from '@/store/modules/auth/authTypes'
import { ComponentWhereIsRendered } from '@/store/modules/alerts/alertsTypes'

const authModule = ModuleNamespaces.AUTH
const billingModule: string = ModuleNamespaces.BILLING
const invoicesModule = ModuleNamespaces.INVOICES

@Component({
  components: {
    GridTable,
    TemplateSelectorComponent
  }
})
export default class OrderFormsView extends mixins(
  ListViewMixin,
  ArchivedFilterMixin,
  PrintInvoiceContextMenuMixin,
  PortalUserMixin
) {
  @Action('fetchBillingData', { namespace: billingModule })
  fetchBillingData: (contactType: {}) => []

  @Action('fetchTotals', { namespace: billingModule })
  fetchTotals: (source: string) => []

  @Action('fetchInvoices', { namespace: invoicesModule })
  fetchPortalInvoices: (filter: {}) => []

  @Getter('getTotals', { namespace: billingModule })
  totals: TotalItem[]

  @Getter('getCompanyTemplateInvoices', { namespace: authModule })
  hasCompanyTemplateInvoice: boolean

  idContextMenu: string = 'invoicesArchivedContextMenuComponent'

  idEntity: number | string = 0
  idEntityType: number = entity.invoices.orderForms.type
  gridFilters: any = {}

  get context() {
    return ContextName.BILLING_ORDER_FORMS
  }

  get invoiceContextMenuItems() {
    const items = this.contextMenuItemsDefault
    return this.getPrintContextMenuItems(items)
  }

  get filters() {
    const filters = {
      ...this.gridFilters,
      archived: this.archivedSelectedOption
    }

    return this.isPortalUser ? { ...filters, ...this.portalFilters } : filters
  }

  async created() {
    await this.fetchCurrentListConfiguration(ListNames.ORDER_FORMS)
    this.initializeArchivedSelectedOption({ selectedOption: 1, contextMenu: this.idContextMenu })
    await this.fetchGridItems()
    this.saveSelectedEntityName(BillingTypes.ORDER_FORMS)
    this.renderGrid = true
    this.hideSpinnerLayerAction()
  }

  async fetchGridItems() {
    if (this.isPortalUser) {
      await this.fetchPortalInvoices({
        archived: this.invoicesDefaulArchivedOption,
        userType: UserType.PORTAL,
        selectedRegisterId: this.portalCustomerId,
        listName: ListNames.ORDER_FORMS
      })
    } else {
      await this.fetchBillingData({
        billingType: ListNames.ORDER_FORMS,
        filter: { archived: this.archivedSelectedOption }
      })
    }
  }

  onContextMenuClicked(args: any, selectedRegister: any) {
    if (
      args.item.text === this.$t('action_buttons.print') ||
      (this.isPortalUser && args.item.text === this.$t('components.context_menu.open_window'))
    ) {
      if (this.hasCompanyTemplateInvoice) {
        this.idEntity = selectedRegister.id
        this.showTemplateDialog = this.checkIfOnlyOneInvoice(
          this.idEntity,
          this.idEntityType,
          ComponentWhereIsRendered.GRID_TABLE
        )
      } else {
        this.printInvoiceOld(selectedRegister.id, entity.invoices.provisions.alias)
      }
    }
  }

  generateTemplateDialog(templateSelected: any) {
    this.printInvoice(templateSelected, this.idEntity, this.idEntityType, ComponentWhereIsRendered.GRID_TABLE)
  }

  onGridTableFilter(filters: any) {
    this.gridFilters = filters
    this.fetchTotals({
      source: ListNames.ORDER_FORMS,
      ...this.filters
    })
  }

  onToolbarClicked(args: any) {
    const target = args.originalEvent.target.closest('button')
    if (target && target.id === 'archived') {
      this.toggleArchivedContextMenu(args.originalEvent, this.idContextMenu)
      args.cancel = true
    }
  }

  async onInvoicesArchivedClick(args: any) {
    this.archivedSelectedOption = args.item.actionType
    this.changeContextMenuIcons(args.item.actionType, this.idContextMenu)
    this.fetchGridItems()
    await this.fetchTotals({ source: ListNames.ORDER_FORMS, ...this.filters })
  }
}
</script>
