<template lang="pug">
  validation-provider(:rules="validationRules" class="component-container" v-slot="{ errors }")
    div(class="input-container")
      div(class="prepend-icon")
        span(:class="icon")
      input(
        :value="value"
        :type="type"
        class="input-field"
        :placeholder="placeholder"
        :id="id"
        :name="id"
        @input="emitValue"
        :autocomplete="autocompleteType"
      )
      div(class="append-icon" v-if="errors[0]")
        span(class="lf-icon-close-round-full")
    div(class="alert-container" v-if="errors")
      span(class="alert-text") {{ errors[0] }}
</template>

<script lang="ts">
import { Vue, Component, Prop, Emit } from 'vue-property-decorator'
import { ValidationProvider } from 'vee-validate'

@Component({
  components: {
    ValidationProvider
  }
})
export default class TextFieldComponent extends Vue {
  @Prop({
    type: String
  })
  validationRules!: string

  @Prop({
    type: String,
    default: ''
  })
  autocompleteType!: string

  @Prop({
    type: String,
    required: true
  })
  id!: string

  @Prop({ type: [String, Number], default: '' })
  value!: string | number

  @Prop({
    type: String
  })
  icon!: string

  @Prop({
    type: String
  })
  placeholder!: string

  @Prop({
    type: String,
    default: 'text'
  })
  type!: string

  @Emit()
  emitValue(event: Event) {
    const target = event.target as HTMLInputElement
    return this.$emit('input', target.value)
  }
}
</script>

<style lang="scss" scoped>
$text-color: $gray-01;

.component-container {
  display: flex;
  flex-direction: column;
  width: 352px;

  ~ .component-container {
    margin-top: 10px;
  }
}

.input-container {
  @include flex;
  position: relative;
}

.prepend-icon {
  @include flex;
  color: $corporate-color;
  font-size: 21px;
  width: 45px;
  height: 45px;
  background: $blue-07;
}

.append-icon {
  @include flex;
  position: absolute;
  right: 0;
  padding: 10px;
  color: $error-color;
  height: 45px;
}

.input-field {
  @include borders($color: $gray-03);
  font-family: $secondary-font;
  font-size: 16px;
  height: 45px;
  width: 307px;
  padding: 0 14px;
  color: $text-color;

  &:focus {
    box-shadow: $hover-rectangle;
  }
}

.alert-text {
  display: inline-block;
  margin: 2px 0 0 4px;
  font-size: 14px;
  color: $error-color;
}

::placeholder {
  color: $text-color;
  text-transform: capitalize;
}
</style>
