<script lang="ts">
import { Vue, Component } from 'vue-property-decorator'
import { Getter } from 'vuex-class'
import { ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import { UserType } from '@/store/modules/auth/authTypes'

const authModule = ModuleNamespaces.AUTH

@Component
export default class PortalUserMixin extends Vue {
  @Getter('getIsPortalUser', { namespace: authModule }) isPortalUser: boolean

  @Getter('getPortalCustomerId', { namespace: authModule }) portalCustomerId: number

  invoicesDefaulArchivedOption: number = 3

  get portalFilters() {
    return {
      public: 1,
      idContact: this.portalCustomerId,
      userType: UserType.PORTAL,
      archived: this.invoicesDefaulArchivedOption
    }
  }

  get getDisableGridProperty() {
    return this.isPortalUser ? true : false
  }
}
</script>
