import { render, staticRenderFns } from "./LexonSearchFieldComponent.vue?vue&type=template&id=579f5b40&scoped=true&lang=pug"
import script from "./LexonSearchFieldComponent.vue?vue&type=script&lang=ts"
export * from "./LexonSearchFieldComponent.vue?vue&type=script&lang=ts"
import style0 from "./LexonSearchFieldComponent.vue?vue&type=style&index=0&id=579f5b40&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "579f5b40",
  null
  
)

export default component.exports