<template lang="pug">

  div(class="column-template-container")
    span(:class="[renderIcon(data), 'column-icon']")
    div(class="column-text") {{ data.headerText }}

</template>

<script lang="ts">
import { Vue, Component } from 'vue-property-decorator'
import { Icons } from '@/icons/icons'

@Component
export default class LexonKanbanColumnTemplate extends Vue {
  renderIcon(data: any) {
    switch (data.keyField) {
      case this.$t('components.lexon_kanban.columns.planned'):
        return Icons.CLOCK
      case this.$t('components.lexon_kanban.columns.initiated'):
        return Icons.CHECK_ROUND
      case this.$t('components.lexon_kanban.columns.done'):
        return Icons.ARCHIVE
      case this.$t('components.lexon_kanban.columns.out_of_time'):
        return Icons.ARCHIVE_EMPTY
      case this.$t('components.lexon_kanban.columns.cancelled'):
        return Icons.CLOSE_DATE
      case this.$t('components.lexon_kanban.columns.pending_resolution'):
        return Icons.PERIODIC_INSTANCE
    }
  }
}
</script>

<style lang="scss" scoped>
.column-template-container {
  @include ellipsis;
  @include flex;
  padding: 0 6px;
  min-height: 28px;

  .column-icon {
    font-size: 23px;
    color: $corporate-color;
    margin-right: 8px;
  }

  .column-text {
    @include ellipsis;
    font-family: $corporate-font-medium;
    font-weight: normal;
    font-size: 16px;
    color: $corporate-color;
    text-transform: uppercase;
  }
}
</style>
