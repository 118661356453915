import { MutationTree } from 'vuex'
import { SearchState } from '@/store/modules/search/searchTypes'

export const mutations: MutationTree<SearchState> = {
  SET_SEARCH_DATA(state, data) {
    state.data = data
  },
  RESET_SEARCH_DATA(state) {
    state.data = {}
    state.searchTerm = ''
  },
  SET_IS_SEARCH_VIEW(state, status) {
    state.isSearchView = status
  },
  SET_SEARCH_TERM(state, term) {
    state.searchTerm = term
  }
}
