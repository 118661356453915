export enum ExpedientFields {
  ACTION_TYPE = 'typeId',
  ACTIVE_STAGES = 'activeStages',
  ARCHIVED = 'archived',
  ARCHIVE_DATE = 'dateArchive',
  ARCHIVE_REFERENCE = 'fileReference',
  CUSTOMER_FACT = 'customerfact',
  EXPEDIENT_TYPE_ID = 'idExpedientType',
  EXPEDIENT_TYPE_SUBJECT_ID = 'idExpedientSubjectType',
  PERIODIC_BILLING = 'periodicBillingSwitch',
  STAGE = 'idStage',
  TAGS = 'tags',
  USER_ID = 'idUser'
}

export enum ExpedientSendEmail {
  CLIENT = 'client',
  INTERN = 'intern'
}
