<template lang="pug">

  PrintOrSaveInvoicesFieldsetComponent(
    :stepNumber="stepNumber"
    :legend="texts.fieldsetLegend"
    showInfoIcon
  )

    TemplateSelectorTreeComponent(
      :idEntityType="idEntityType"
      class="invoices-template-selector-container"
      @templateSelected="templateSelected"
    )

</template>

<script lang="ts">
import { Component, Emit, Prop, Vue } from 'vue-property-decorator'
import PrintOrSaveInvoicesFieldsetComponent from '@/components/billing/PrintOrSaveInvoicesDialog/PrintOrSaveInvoicesFieldsetComponent.vue'
import TemplateSelectorTreeComponent from '@/components/template/TemplateSelectorComponent/TemplateSelectorTreeComponent.vue'
import { ITemplate } from '@/store/modules/template/templateTypes'
import { PrintOrSaveInvoicesSteps } from './types/PrintOrSaveInvoicesDialogTypes'

@Component({
  components: {
    PrintOrSaveInvoicesFieldsetComponent,
    TemplateSelectorTreeComponent
  }
})
export default class InvoicesTemplateSelectorComponent extends Vue {
  @Prop({
    type: [Number, String],
    required: true
  })
  idEntityType!: number

  texts = {
    fieldsetLegend: this.$t('components.invoice_template_selector.fieldsetLegend')
  }

  stepNumber = PrintOrSaveInvoicesSteps.SELECT_INVOICES

  @Emit('templateSelectedInvoice')
  templateSelected(templateSelected: ITemplate) {
    return templateSelected
  }
}
</script>

<style lang="scss" scoped>
.invoices-template-selector-container {
  ::v-deep .no-result-dialog {
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    height: 343px;
    max-height: 100%;
  }
}
.invoices-template-selector-container {
  margin-top: 0;
  padding-top: 0;

  ::v-deep .v-treeview {
    .v-icon {
      &::before {
        font-size: 18px;
        color: $corporate-color;
      }

      &::after {
        display: none;
      }
    }

    .is-directory,
    .is-template {
      font-size: 14px;
    }

    .is-directory {
      color: $corporate-color;
    }

    .is-template {
      color: $black-01;
    }
  }
}
</style>
