<script lang="ts">
import { ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import { Vue, Component } from 'vue-property-decorator'
import { Action } from 'vuex-class'
import { InvoiceMaxNumberFormFields } from '@/mixins/InvoicesMaxNumberFormFieldsLogic/types/InvoicesMaxNumberFormFieldsLogicMixinTypes'

const invoicesModule = ModuleNamespaces.INVOICES

@Component
export default class InvoicesMaxNumberFormFieldsLogicMixin extends Vue {
  @Action('fetchMaxNumberInvoice', { namespace: invoicesModule })
  fetchMaxNumberInvoiceAction: ({}) => Promise<number>

  executingAPICall = false

  maxNumberActionsFlag = false

  async fetchMaxNumberInvoice(value: number, formData: any): Promise<void> {
    if (!this.executingAPICall) {
      this.executingAPICall = true
      if (value) {
        const data = (await this.fetchMaxNumberInvoiceAction({
          invoiceType: (this as any).invoiceInfo.type,
          filter: {
            idCompany: value,
            exercise: formData.exercise || null,
            sequence: formData.sequence || null
          }
        })) as any
        Vue.set(formData, InvoiceMaxNumberFormFields.EXERCISE, data['exercise'])
        Vue.set(formData, InvoiceMaxNumberFormFields.SEQUENCE, data['sequence'])
        Vue.set(formData, InvoiceMaxNumberFormFields.INVOICE_NUMBER, data['nextInvoice'])
      } else {
        Vue.delete(formData, InvoiceMaxNumberFormFields.EXERCISE)
        Vue.delete(formData, InvoiceMaxNumberFormFields.SEQUENCE)
        Vue.delete(formData, InvoiceMaxNumberFormFields.INVOICE_NUMBER)
      }
    }
    this.executingAPICall = false
  }

  async fillTextfieldFields(fieldValues: any, selectedInvoiceCompanyInfoCloned: any = null) {
    if (fieldValues.idCompany && this.maxNumberActionsFlag) {
      const data = (await this.fetchMaxNumberInvoiceAction({
        invoiceType: (this as any).invoiceInfo.type,
        filter: {
          idCompany: fieldValues.idCompany,
          sequence: fieldValues.sequence,
          exercise: fieldValues.exercise || null
        }
      })) as any
      if (data) {
        Vue.set(fieldValues, InvoiceMaxNumberFormFields.EXERCISE, data[InvoiceMaxNumberFormFields.EXERCISE])
        const sequenceValue = data[InvoiceMaxNumberFormFields.SEQUENCE] ? data[InvoiceMaxNumberFormFields.SEQUENCE] : ''
        Vue.set(fieldValues, InvoiceMaxNumberFormFields.SEQUENCE, sequenceValue)
      }
      if (
        selectedInvoiceCompanyInfoCloned &&
        String(selectedInvoiceCompanyInfoCloned.idCompany) ===
          String(fieldValues[InvoiceMaxNumberFormFields.COMPANY]) &&
        String(selectedInvoiceCompanyInfoCloned.exercise) ===
          String(fieldValues[InvoiceMaxNumberFormFields.EXERCISE]) &&
        String(selectedInvoiceCompanyInfoCloned.sequence) === String(fieldValues[InvoiceMaxNumberFormFields.SEQUENCE])
      ) {
        Vue.set(fieldValues, InvoiceMaxNumberFormFields.INVOICE_NUMBER, selectedInvoiceCompanyInfoCloned.number)
      } else if (data) {
        Vue.set(fieldValues, InvoiceMaxNumberFormFields.INVOICE_NUMBER, data[InvoiceMaxNumberFormFields.NEXT_NUMBER])
      }
      this.maxNumberActionsFlag = false
    }
  }

  makeMaxNumberActions() {
    this.maxNumberActionsFlag = true
  }
}
</script>
