<template lang="pug">

  v-dialog(
    v-model="showFormDialog"
    v-if="showFormDialog"
    class="custom-fields-form-dialog"
    width="500"
    hide-overlay
  )

    div(
      class="form-content"
      v-click-outside="closeFormDialog"
    )
      div(class="form-title-container")
        span(class="form-title") {{ typeOfFieldToRenderInForm }}
        span(
          :class="[closeIcon, 'close-icon']"
          @click.stop="closeFormDialog"
        )
      FormGeneratorComponent(
        :schema="schema"
        :fieldValues="formData"
        :context="context"
        formTag="customFieldsFormDialog"
      )
      ActionsBarComponent(
        :buttons="buttons"
        @execute-action="executeAction"
        class="action-buttons"
      )

</template>

<script lang="ts">
import { ContextName, ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import { Vue, Component, Prop, Emit } from 'vue-property-decorator'
import { Getter, Mutation } from 'vuex-class'
import ActionsBarComponent from '@/components/ActionsBar/ActionsBarComponent.vue'
import FormGeneratorComponent from '@/components/forms/FormGenerator/FormGeneratorComponent.vue'
import { Icons } from '@/icons/icons'
import { ActionBarButton, ActionBarButtonMode, ActionName } from '@/components/ActionsBar/types/ActionBarComponentTypes'
import { DialogEvent } from '@/store/modules/customFields/customFieldsTypes'

const customFieldsModule: string = ModuleNamespaces.CUSTOM_FIELDS
const formsModule = ModuleNamespaces.FORMS

@Component({
  components: {
    FormGeneratorComponent,
    ActionsBarComponent
  }
})
export default class CustomFieldsFormDialogComponent extends Vue {
  @Prop({
    type: Boolean,
    required: true
  })
  showFormDialog!: boolean

  @Prop({
    type: Array,
    required: true,
    default: () => []
  })
  schema!: []

  @Prop({
    type: Object
  })
  formData!: {}

  @Prop({
    type: String,
    required: true
  })
  typeOfFieldToRenderInForm!: string

  @Getter('checkIfFormIsValid', { namespace: formsModule })
  checkIfFormIsValid: (context: string) => boolean
  @Getter('getModeCustomFieldsFormDialog', { namespace: customFieldsModule })
  getModeCustomFieldsFormDialog: boolean

  @Mutation('CLEAR_CUSTOM_FIELD_FORM_DATA', { namespace: customFieldsModule })
  clearFormData: () => void
  @Mutation('MODE_CUSTOM_FIELDS_FORM_DIALOG', { namespace: customFieldsModule })
  modeCustomFieldsFormDialog: (editMode: boolean) => void

  closeIcon: string = Icons.CLOSE

  context = ContextName.CUSTOM_FIELDS

  @Emit()
  dialogEvent(e: string): DialogEvent {
    return {
      action: e,
      formData: this.formData
    }
  }

  get buttons(): ActionBarButton[] {
    return [
      {
        tooltip: this.$t('action_buttons.cancel'),
        class: 'secondary-color',
        mode: ActionBarButtonMode.SQUARE,
        action: ActionName.CLOSE,
        show: true
      },
      {
        tooltip: this.$t('action_buttons.add'),
        class: 'main-color',
        mode: ActionBarButtonMode.SQUARE,
        action: ActionName.SAVE,
        hidden: this.getModeCustomFieldsFormDialog,
        show: this.checkIfFormIsValid(this.context)
      },
      {
        tooltip: this.$t('action_buttons.save'),
        class: 'main-color',
        mode: ActionBarButtonMode.SQUARE,
        action: ActionName.EDIT,
        hidden: !this.getModeCustomFieldsFormDialog,
        show: this.checkIfFormIsValid(this.context)
      }
    ]
  }

  closeFormDialog(e: MouseEvent): void {
    const target = e.target as HTMLElement
    const checkClassList: boolean =
      target.classList.contains('v-dialog__content--active') || target.classList.contains('close-icon')
    if (checkClassList) {
      this.dialogEvent(ActionName.CLOSE)
      this.clearForm()
    }
  }

  executeAction(actionName: string): void {
    this.dialogEvent(actionName)
    this.clearForm()
    this.modeCustomFieldsFormDialog(false)
  }

  clearForm(): void {
    this.clearFormData()
  }
}
</script>

<style lang="scss" scoped>
.v-dialog__content {
  background-color: $dialog-background-color;
  pointer-events: unset;
}

::v-deep .v-dialog.v-dialog--active {
  border-radius: 0;
}

.form-title-container {
  @include flex($justify-content: space-between);

  .form-title {
    @include flex($justify-content: flex-start);
    font-size: 24px;
    font-family: $corporate-font;
    color: $corporate-color;
  }

  .close-icon {
    cursor: pointer;
  }
}

.form-content {
  @include borders;
  background: $white-01;
  overflow: hidden;
  padding: 20px;
}

.action-buttons {
  @include flex($justify-content: flex-end);
  width: 100%;
  margin-top: 20px;
}
</style>
