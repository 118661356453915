<template lang="pug">

  WidgetBaseComponent(
    ref="widget"
    class="expedient-reminder-notes-widget"
    :headerIcon="icons.headerIcon"
    :loadedData="allDataLoaded"
  )
    //- HEADER
    template(v-slot:header)
      div(class="header-bar")
        h2 {{ widgetDataGetter.header }}
        button(
          v-if="!newNoteLayerVisible && canSave"
          :class="[icons.addButton, 'add-button']"
          @click.stop="showAddNewNoteLayer"
        )

    //- BODY
    template(v-slot:body)
      div(class="widget-body")
        //- Create new note
        section(v-if="newNoteLayerVisible && canSave" class="new-note-container")
          v-textarea(
            v-model="newNote"
            class="note-textarea"
            solo
            :placeholder="texts.placeholder"
            no-resize
            :counter="maxNumberOfCharacters"
            :maxlength="maxNumberOfCharacters + 1"
          )
          ActionsBarComponent(
            :buttons="buttons"
            @execute-action="executeAction"
            class="action-buttons"
          )

        //- Notes container
        ul(v-else class="notes-container element-with-scroll")
          li(
            v-for="(note, noteIndex) in widgetDataGetter.notes"
            :key="noteIndex"
            class="note-card"
          )
            div(class="remove-card" v-if="canSave")
              button(type="button" class="remove-action-button" @click.stop="showRemoveDialog(note.id)")
                i(:class="icons.removeNote")
            v-textarea(
              @blur="editNote($event, note.id)"
              class="note-text"
              :value="note.note"
              auto-grow
              row-height="0"
              :counter="maxNumberOfCharacters"
              :maxlength="maxNumberOfCharacters + 1"
            )

    //- FOOTER
    template(v-slot:footer)
      div(v-if="widgetDataGetter.footer" class="footer-data-container")
        span {{ `${widgetDataGetter.footer.label}: ` }}
        span(class="footer-total-number") {{ widgetDataGetter.footer.totalNumber }}

</template>

<script lang="ts">
import { Component, Mixins } from 'vue-property-decorator'
import ActionsBarComponent from '@/components/ActionsBar/ActionsBarComponent.vue'
import WidgetBaseComponent from '@/components/widgets/WidgetBaseComponent.vue'
import { Icons } from '@/icons/icons'
import { ActionName } from '@/components/ActionsBar/types/ActionBarComponentTypes'
import { Action, Getter } from 'vuex-class'
import { ContextName, ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import { entity } from '@/store/modules/entities/entitiesTypes'
import {
  ExpedientReminderNotesWidgetData,
  FetchNotesFilter,
  SaveNoteObject
} from '@/components/widgets/ExpedientWidgets/ExpedientReminderNotes/ExpedientReminderNotesComponentTypes'
import { initialState } from '@/store/modules/notes/notesTypes'
import { DialogTypes } from '@/store/modules/dialog/dialogTypes'
import PermissionsMixin from '@/mixins/PermissionsMixin.vue'

const dialogModule: string = ModuleNamespaces.DIALOG
const globalModule: string = ModuleNamespaces.GLOBAL
const notesModule: string = ModuleNamespaces.NOTES
const selectedRegisterModule: string = ModuleNamespaces.SELECTED_REGISTER

@Component({
  components: {
    ActionsBarComponent,
    WidgetBaseComponent
  }
})
export default class ExpedientReminderNotesComponent extends Mixins(PermissionsMixin) {
  @Action('fetchNotes', { namespace: notesModule })
  fetchNotesAction: ({}: FetchNotesFilter) => ExpedientReminderNotesWidgetData
  @Action('saveNote', { namespace: notesModule })
  saveNoteAction: ({}: SaveNoteObject) => void
  @Action('deleteNote', { namespace: notesModule })
  deleteNoteAction: (idNote: number) => void
  @Action('resetModuleInitialState', { namespace: globalModule })
  resetModuleInitialStateAction: (initialState: any) => void
  @Action('showDialog', { namespace: dialogModule })
  showDialogAction: ({}) => {}

  @Getter('getNotes', { namespace: notesModule })
  widgetDataGetter: ExpedientReminderNotesWidgetData
  @Getter('getSelectedRegisterId', { namespace: selectedRegisterModule })
  selectedRegisterIdGetter: (context: string) => number

  $refs!: {
    widget: any
  }

  newNoteLayerVisible: boolean = false

  newNote = ''

  maxNumberOfCharacters: number = 300

  icons = {
    addButton: Icons.ADD,
    headerIcon: Icons.INTELLECTUAL,
    removeNote: Icons.CLOSE
  }

  texts = {
    cancelNote: this.$t('action_buttons.cancel'),
    placeholder: this.$t('components.widgets.expedient_reminder_notes.textarea_placeholder'),
    removeConfirmation: this.$t('components.widgets.expedient_reminder_notes.remove_confirmation_text'),
    saveButton: this.$t('action_buttons.save')
  }

  contexts = {
    expedientReminderNotes: ContextName.EXPEDIENT_REMINDER_NOTES_WIDGET,
    expedientContext: ContextName.EXPEDIENTS
  }

  noteContainerHeight = 0

  get buttons() {
    return [
      {
        tooltip: this.texts.cancelNote,
        class: 'main-color',
        action: ActionName.CANCEL,
        mode: 'square',
        show:
          (this.newNote && this.newNote.length <= this.maxNumberOfCharacters) ||
          this.widgetDataGetter.footer.totalNumber > 0,
        hidden: !this.existNotes && !this.canSave
      },
      {
        tooltip: this.texts.saveButton,
        class: 'main-color',
        mode: 'square',
        action: ActionName.SAVE,
        show: this.newNote && this.newNote.length <= this.maxNumberOfCharacters,
        hidden: !this.canSave
      }
    ]
  }

  get canSave() {
    const { canSave } = this.checkEntityPermissionsGetter(entity.expedients.type)
    return canSave
  }

  get allDataLoaded() {
    return !!(this.widgetDataGetter && Object.keys(this.widgetDataGetter).length)
  }

  get selectedExpedientId() {
    return this.selectedRegisterIdGetter(this.contexts.expedientContext)
  }

  get existNotes() {
    if (this.widgetDataGetter && Object.keys(this.widgetDataGetter).length) {
      return !!this.widgetDataGetter.notes.length
    }
  }

  async mounted() {
    await this.fetchWidgetData()
    this.showOrHideNewNoteLayer()
  }

  beforeDestroy() {
    this.resetModuleInitialStateAction({
      initialState,
      moduleName: ModuleNamespaces.NOTES
    })
  }

  showOrHideNewNoteLayer() {
    if (this.existNotes) {
      this.newNoteLayerVisible = false
    } else {
      this.newNoteLayerVisible = true
    }
  }

  executeAction(actionName: string): void {
    switch (actionName) {
      case ActionName.CANCEL:
        if (this.widgetDataGetter.footer.totalNumber > 0) {
          this.newNoteLayerVisible = false
        }
        this.newNote = ''
        this.calculateNoteContainerHeight()
        break
      case ActionName.SAVE:
        this.saveNote()
        this.calculateNoteContainerHeight()
        break
    }
  }

  showAddNewNoteLayer() {
    this.newNoteLayerVisible = true
  }

  async fetchWidgetData() {
    await this.fetchNotesAction({
      idEntity: this.selectedExpedientId,
      idEntityType: entity.expedients.type,
      source: this.contexts.expedientReminderNotes
    })
  }

  async saveNote() {
    await this.saveNoteAction({
      idEntity: this.selectedExpedientId,
      idEntityType: entity.expedients.type,
      note: this.newNote
    })
    await this.fetchWidgetData()
    this.calculateNoteContainerHeight()
    this.newNote = ''
    this.newNoteLayerVisible = false
  }

  async editNote(e: Event, noteId: number) {
    const textarea = e.target as HTMLInputElement
    const textareaValue = textarea.value

    if (textareaValue && noteId) {
      await this.saveNoteAction({
        idEntity: this.selectedExpedientId,
        idEntityType: entity.expedients.type,
        note: textareaValue,
        id: noteId
      })
      await this.fetchWidgetData()
    }
  }

  async removeNote(noteId: number) {
    await this.deleteNoteAction(noteId)
    await this.fetchWidgetData()
    this.showOrHideNewNoteLayer()
  }

  async showRemoveDialog(noteId: number) {
    this.showDialogAction({
      type: DialogTypes.INFO,
      message: this.texts.removeConfirmation,
      action: () => this.removeNote(noteId)
    })
  }

  calculateNoteContainerHeight() {
    setTimeout(() => {
      const height = this.$refs.widget.$el.clientHeight
      const containerHTMLElement: HTMLElement | null = this.$el.querySelector('.notes-container')
      this.noteContainerHeight = height

      if (containerHTMLElement) {
        containerHTMLElement.style.height = `${height - 120}px`
      }
    })
  }
}
</script>

<style lang="scss" scoped>
.expedient-reminder-notes-widget {
  .header-bar {
    @include flex($justify-content: space-between);
    width: 100%;

    .add-button {
      @include interactive-round-button;
    }
  }

  .widget-body {
    @include flex($flex-direction: column);
    width: 100%;

    .new-note-container {
      @include flex($flex-direction: column);
      width: 100%;
      padding: 4%;

      .note-textarea {
        @include scroll-styles;
        @include border($direction: top, $color: $blue-04, $width: 4px);
        width: 100%;
        font-size: 12px;

        ::v-deep textarea {
          @include scroll-styles;
          line-height: 16px;
        }

        ::v-deep .v-input__slot {
          @include border($color: $blue-06, $direction: left);
          @include border($color: $blue-06, $direction: right);
          border-radius: 0 0 4px 4px;
          height: 15em;
        }

        ::v-deep .v-counter {
          color: $corporate-color;
        }
      }

      .action-buttons {
        align-self: flex-end;
        margin-top: 4%;
      }

      ::v-deep .actions-bar button {
        background: transparent;

        &:hover {
          background: $corporate-color;
        }
      }
    }

    .notes-container {
      @include list;
      @include scroll-styles;
      width: 100%;
      overflow: auto;
      padding: 4%;

      .note-card {
        @include border($width: 3px, $direction: top, $color: $blue-04);
        width: 100%;
        background-color: $white-01;
        margin-bottom: 1.2em;
        border-radius: 4px;
        word-wrap: break-word;

        .remove-card {
          @include flex($justify-content: flex-end);
          @include border($color: $blue-06, $direction: left);
          @include border($color: $blue-06, $direction: right);
          color: $gray-04;
          padding: 10px 10px 0 10px;

          .remove-action-button {
            font-size: 12px;
            color: $corporate-color;
            padding: 0 6px;
          }
        }

        .v-textarea {
          margin: 0;

          ::v-deep .v-text-field__details {
            width: 100%;
          }

          ::v-deep textarea {
            width: 100%;
            padding: 0 10px;
          }

          ::v-deep .v-counter {
            color: $corporate-color;
            margin-top: 5px;
            padding-right: 2px;
          }
        }

        .note-text {
          width: 100%;
          font-size: 12px;
          font-family: $secondary-font;
          color: $corporate-color;
          padding-top: 0;

          ::v-deep .v-input__slot {
            @include borders($color: $blue-06);
            border-top: none;
            border-radius: 0 0 4px 4px;
            margin: 0;
            box-shadow: 0 10px 4px -8px rgba(86, 86, 86, 0.2);
            &::before,
            &::after {
              display: none;
            }
          }
        }
      }
    }
  }

  .footer-data-container {
    width: 100%;
    text-align: right;
    padding-right: 3%;

    .footer-total-number {
      display: inline-block;
      font-size: 18px;
      padding-left: 2px;
    }
  }
}
</style>
