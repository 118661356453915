export interface DocumentOnlineFileToken {
  id: number
  size: number
  token: string
}

export interface DocumentOnlineState {
  accessToken: string
  accessTokenTtl: number
  fileSize: number
  fileUrl: string
  action: string
  applicationType: string
  fileName: string
  fileVersion: string
  fileToken: DocumentOnlineFileToken
  wopiUserId: number
  wopiUserName: string
  wopiLocale: string
  needsConversion: boolean
  readOnly: boolean
}

export const documentOnlineActions = {
  EDIT: 'edit',
  CONVERT: 'convert',
  NEW: 'editnew'
}

export const documentOnlineApplications = {
  WORD: 'Word',
  EXCEL: 'Excel',
  POWER_POINT: 'PPoint'
}

export const documentOnlineMutationTypes = {
  SAVE_ACCESS_TOKEN: 'SAVE_ACCESS_TOKEN',
  SAVE_ACCESS_TOKEN_TTL: 'SAVE_ACCESS_TOKEN_TTL',
  SAVE_APP_TYPE: 'SAVE_APP_TYPE',
  SAVE_FILE_URL: 'SAVE_FILE_URL',
  SAVE_FILE_NAME: 'SAVE_FILE_NAME',
  SAVE_FILE_VERSION: 'SAVE_FILE_VERSION',
  SAVE_FILE_TOKEN: 'SAVE_FILE_TOKEN',
  SAVE_FILE_SIZE: 'SAVE_FILE_SIZE',
  SAVE_ACTION: 'SAVE_ACTION',
  SAVE_NEEDS_CONVERSION: 'SAVE_NEEDS_CONVERSION',
  SAVE_READ_ONLY: 'SAVE_READ_ONLY',
  RESET_FILE_URL: 'RESET_FILE_URL'
}
