export enum ActionEntityId {
  ALL = 28,
  CALL = 30,
  MEETING = 31,
  PROCEDURE = 32,
  OTHER = 33,
  EMAIL = 45,
  LEXNET = 51
}

export enum ContactEntityId {
  CONTACTS = 42,
  CUSTOMERS = 2,
  OPPONENTS = 3,
  PROVIDERS = 4,
  OWN_LAWYERS = 5,
  OPPONENT_LAWYERS = 6,
  OWN_ATTORNEYS = 7,
  OPPONENT_ATTORNEYS = 8,
  NOTARIES = 9,
  COURTS = 10,
  INSURERS = 11,
  OTHERS = 12,
  AGENCIES = 44
}

export enum InvoiceEntityId {
  INVOICES = 29,
  CUSTOMER_BAKDRAFTS = 38, //
  CUSTOMER_INVOICES = 34, // facturas clientes
  PROFORMA_INVOICES = 36, //
  PROVISIONS = 37, // provisiones
  PROVIDER_BANKDRAFTS = 39, //
  PROVIDER_INVOICES = 35, //
  ORDER_FORMS = 80 // Hojas de encargo
}

export enum MaintenanceEntityId {
  MAINTENANCE = 71,
  PROFESSIONALS = 73,
  USERS = 72
}

export enum ExpedientEntityId {
  EXPEDIENTS = 1,
  INTERVENERS = 19,
  COURTS = 20
}

export const documentEntityId: number = 14

export const generalEntityId: number = 74

export const signatureEntityId: number = 79

export const templateEntityId: number = 75

export const reportsEntityId: number = 77

export const driveEntityId: number = 78

export const appointmentsEntityId: number = 56

export const agendaEntityId: number = 46

export const profileEntityId: number = 49

export const configurationEntityId: number = 50

export const legalBaseEntityId: number = 81
