export const formatNumber = (num: number, decimals: number = 0, locale = 'es-ES'): string => {
  // TODO: Cuando haya barra de cambio de idiomas, se obtendrá del Store (o del mecanismo que haya creado para ello
  const config = { minimumFractionDigits: 0 }

  if (decimals) {
    config.minimumFractionDigits = decimals
  }
  return Number(num).toLocaleString(locale, config)
}

export const calculateNumberOfDecimalPlaces = (num: number, separator: string = '.') => {
  const numStr = String(num)
  if (numStr.includes(separator)) {
    return numStr.split(separator)[1].length
  }
  return 0
}

export const formatNumberColumnsGrid = (data: any, columns: any) => {
  const numberCol: any = []
  columns.map((col: any) => {
    if (col.type === 'number' && col.format === 'N') {
      numberCol.push(col.field)
    }
  })

  data.map((item: any) => {
    numberCol.forEach((element: any) => {
      if (item.hasOwnProperty(element)) {
        item[element] = parseInt(item[element], 10)
      }
    })
  })
  return data
}
