export const getObjectByKeyAndValue = (object: any, key: string, value: any) => {
  let result: object | null = null
  Object.keys(object).some((k) => {
    if (k === key && value === object[k]) {
      result = object
      return true
    }
    if (object[k] && typeof object[k] === 'object') {
      result = getObjectByKeyAndValue(object[k], key, value)
      return result !== null
    }
    return false
  })
  return result
}

export const getObjectByKey = (object: any, key: string) => {
  let result: object | null = null
  Object.keys(object).some((k) => {
    if (k === key) {
      result = object[k]
      return true
    }
    if (object[k] && typeof object[k] === 'object') {
      return getObjectByKey(object[k], key) !== null
    }
    return false
  })
  return result
}

export const getParentObjectByKeyAndValue = (object: any, key: string, value: any) => {
  let result: any = null
  Object.keys(object).some((k) => {
    if (k === key && value === object[k]) {
      result = object
      return true
    }
    if (object[k] && typeof object[k] === 'object') {
      result = getParentObjectByKeyAndValue(object[k], key, value)
      if (null !== result) {
        result = object[k]
      }
      return result !== null
    }
    return false
  })
  return result
}

export const clone = (object: any) => {
  if (typeof object === 'undefined' || object === null) {
    return object
  }

  const newObj = JSON.parse(JSON.stringify(object))

  for (const field of Object.keys(object)) {
    if (typeof object[field] === 'object') {
      newObj[field] = clone(object[field])
    } else {
      newObj[field] = object[field]
    }
  }

  return newObj
}
