<template lang="pug">
div(class="image-preview-content")
  ImagePreviewToolbarComponent(
    ref="toolbar"
    @zoom-in-event="zoomIn"
    @zoom-out-event="zoomOut"
    @close-viewer-event="closeViewerEvent"
    :zoom="zoom"
  )

  SpinnerLayerComponent(
    v-if="isLoading"
    class="spinner-layer-component"
  )

  ejs-imageeditor(
    id="image-editor"
    ref="imageEditorObj"
    width="100%"
    height="100%"
    :toolbar=[]
  )

</template>

<script lang="ts">
import { Vue, Component, Emit } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'
import { DocumentPreview } from '@/components/FileManager/Preview/types/PreviewTypes'
import { ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import { ImageEditorPlugin } from '@syncfusion/ej2-vue-image-editor'
import ImagePreviewToolbarComponent from '@/components/FileManager/Preview/components/ImagePreviewComponent/ImagePreviewToolbarComponent.vue'
import SpinnerLayerComponent from '@/components/Spinner/SpinnerLayerComponent.vue'

Vue.use(ImageEditorPlugin)
const fileManagerModule: string = ModuleNamespaces.FILE_MANAGER

@Component({
  components: {
    ImagePreviewToolbarComponent,
    SpinnerLayerComponent
  }
})
export default class ImagePreviewComponent extends Vue {
  @Action('downloadFile', { namespace: fileManagerModule })
  downloadFile: (id: string) => Blob

  @Getter('getFile', { namespace: fileManagerModule })
  blobFile: Blob

  toolbar: ['ZoomIn', 'ZoomOut']
  isLoading = true
  zoom = 1

  get ImageViewerInstance(): any {
    return this.$refs.imageEditorObj || null
  }

  @Emit()
  closeViewerEvent() {}

  async preview(documentPreview: DocumentPreview) {
    try {
      this.isLoading = true
      this.zoom = 1
      await this.downloadFile(documentPreview.id.toString())
      this.isLoading = false
      this.blobToBase64(this.blobFile).then((base64) => {
        this.ImageViewerInstance.open('data:image/jpeg;base64,' + base64)
      })
    } catch (error) {
      return this.onServiceFailure()
    }
  }

  blobToBase64(blob: Blob) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader() as any
      reader.onload = () => {
        const base64String = reader.result.split(',')[1]
        resolve(base64String)
      }
      reader.onerror = (error: any) => reject(error)

      reader.readAsDataURL(blob)
    })
  }

  onServiceFailure() {
    this.$emit('failPreview')
  }

  zoomIn() {
    if (this.zoom < 10) {
      this.zoom = this.zoom + 1
      this.ImageViewerInstance.zoom(this.zoom)
    }
  }

  zoomOut() {
    if (this.zoom > 1) {
      this.zoom = this.zoom - 1
      this.ImageViewerInstance.zoom(this.zoom)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@syncfusion/ej2-base/styles/material.css';
@import '~@syncfusion/ej2-buttons/styles/material.css';
@import '~@syncfusion/ej2-splitbuttons/styles/material.css';
@import '~@syncfusion/ej2-lists/styles/material.css';
@import '~@syncfusion/ej2-popups/styles/material.css';
@import '~@syncfusion/ej2-inputs/styles/material.css';
@import '~@syncfusion/ej2-navigations/styles/material.css';
@import '~@syncfusion/ej2-image-editor/styles/material.css';

.image-preview-content {
  position: relative;
  width: 100% !important;
  padding-top: 33px;

  .spinner-layer-component {
    background-color: $white-01;
    min-height: 100%;
  }
}
</style>
