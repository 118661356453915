import { GetterTree } from 'vuex'
import { CustomerPortal, CustomerPortalState } from '@/store/modules/customerPortal/customerPortalTypes'
import { RootState } from '@/store/types/storeGlobalTypes'

export const getters: GetterTree<CustomerPortalState, RootState> = {
  getCustomerPortal(state): CustomerPortal {
    return state.customerPortalFormData[0]
  },
  getCustomerEmailPortal(state, getter): string | null {
    if (getter.getCustomerPortal) {
      return state.customerPortalFormData[0].emailPortal
    }
    return null
  },
  getCustomerPortalStatePortal(state, getter): string | null {
    if (getter.getCustomerPortal) {
      return state.customerPortalFormData[0].statePortal
    }
    return null
  }
}
