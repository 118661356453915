import { ActionTree } from 'vuex'
import { AlertsState } from '@/store/modules/alerts/alertsTypes'
import { RootState } from '@/store/types/storeGlobalTypes'
import { alertsMutationTypes } from '@/store/modules/alerts/alertsTypes'

export const actions: ActionTree<AlertsState, RootState> = {
  showAlert({ commit }, alertData) {
    commit(alertsMutationTypes.SHOW_ALERT, alertData)
  },
  closeAlert({ commit }) {
    commit(alertsMutationTypes.CLOSE_ALERT)
  }
}
