<script lang="ts">
import { Vue, Component } from 'vue-property-decorator'

@Component
export default class DatePickerClearButtonMixin extends Vue {
  datePickerOpened = false

  onDatePickerCreated() {
    const datePicker: any = this.$refs.datePicker || this.$refs.dateTimePicker
    if (datePicker) {
      datePicker.$el.addEventListener('mouseover', this.showClearButton)
      datePicker.$el.addEventListener('mouseout', this.hideClearButton)
    }
  }

  onDatePickerDestroyed() {
    const datePicker: any = this.$refs.datePicker || this.$refs.dateTimePicker
    if (datePicker) {
      datePicker.$el.removeEventListener('mouseover', this.showClearButton)
      datePicker.$el.removeEventListener('mouseout', this.hideClearButton)
    }
  }

  showClearButton() {
    const datePicker: any = this.$refs.datePicker || this.$refs.dateTimePicker
    if (datePicker) {
      const clearButton = datePicker.$el.parentNode.querySelector('.e-clear-icon')
      if (clearButton.classList.contains('e-clear-icon-hide') && datePicker.value) {
        clearButton.classList.remove('e-clear-icon-hide')
      }
    }
  }

  hideClearButton(event: MouseEvent) {
    const datePicker: any = this.$refs.datePicker || this.$refs.dateTimePicker
    if (datePicker) {
      const clearButton = datePicker.$el.parentNode.querySelector('.e-clear-icon')
      const isTarget: boolean = event.relatedTarget === clearButton
      if (!clearButton.classList.contains('e-clear-icon-hide') && !this.datePickerOpened && !isTarget) {
        clearButton.classList.add('e-clear-icon-hide')
      }
    }
  }

  onDatePickerOpen() {
    this.datePickerOpened = true
  }

  onDatePickerClose() {
    this.datePickerOpened = false
  }
}
</script>
