<template lang="pug">

div(class="expedient-global-vision-container")

  ExpedientGlobalVisionTopBarComponent

  DynamicLayoutComponent(
    :widgets="widgets"
    :allowDragging="false"
  )

</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import { DashboardLayoutPlugin } from '@syncfusion/ej2-vue-layouts'
import DynamicLayoutComponent from '@/components/DynamicLayout/DynamicLayoutComponent.vue'
import ExpedientGlobalVisionTopBarComponent from '@/components/expedients/ExpedientGlobalVision/ExpedientGlobalVisionTopBarComponent.vue'
import ExpedientLastActionsWidget from '@/components/widgets/ExpedientWidgets/ExpedientLastActions/ExpedientLastActionsWidget.vue'
import ExpedientNextEventsWidget from '@/components/widgets/ExpedientWidgets/ExpedientNextEvents/ExpedientNextEventsWidget.vue'
import ExpedientProfitabilityWidget from '@/components/widgets/ExpedientWidgets/ExpedientProfitabilityWidget.vue'
import ExpedientReminderNotesComponent from '@/components/widgets/ExpedientWidgets/ExpedientReminderNotes/ExpedientReminderNotesComponent.vue'
import ExpedientSummaryWidget from '@/components/widgets/ExpedientWidgets/ExpedientSummaryWidget.vue'

Vue.use(DashboardLayoutPlugin)

@Component({
  components: {
    DynamicLayoutComponent,
    ExpedientGlobalVisionTopBarComponent,
    ExpedientLastActionsWidget,
    ExpedientNextEventsWidget,
    ExpedientProfitabilityWidget,
    ExpedientReminderNotesComponent,
    ExpedientSummaryWidget
  }
})
export default class ExpedientGlobalVisionComponent extends Vue {
  widgets = [
    {
      id: 'expedient-resume',
      dataRow: '0',
      dataRowSmAndMd: '0',
      dataCol: '0',
      dataSizeX: '6',
      dataSizeY: '3',
      dataSizeYSmAndMd: '7',
      renderedComponent: ExpedientSummaryWidget
    },
    {
      id: 'two',
      dataRow: '0',
      dataRowSmAndMd: '7',
      dataCol: '6',
      dataSizeX: '3',
      dataSizeY: '6',
      dataSizeYSmAndMd: '18',
      renderedComponent: ExpedientNextEventsWidget
    },
    {
      id: 'three',
      dataRow: '0',
      dataRowSmAndMd: '26',
      dataCol: '9',
      dataSizeX: '3',
      dataSizeY: '4',
      dataSizeYSmAndMd: '10',
      renderedComponent: ExpedientReminderNotesComponent
    },
    {
      id: 'four',
      dataRow: '6',
      dataRowSmAndMd: '36',
      dataCol: '0',
      dataSizeX: '6',
      dataSizeY: '3',
      dataSizeYSmAndMd: '6',
      renderedComponent: ExpedientLastActionsWidget
    },
    {
      id: 'five',
      dataRow: '7',
      dataRowSmAndMd: '26',
      dataCol: '9',
      dataSizeX: '3',
      dataSizeY: '2',
      dataSizeYSmAndMd: '4',
      renderedComponent: ExpedientProfitabilityWidget
    }
  ]

  mounted() {
    this.$emit('expedientGlobalVisionComponentMounted')
  }
}
</script>
