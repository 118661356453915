import { GetterTree } from 'vuex'
import { AgenciesState, Agency, AgencyFormatted } from '@/store/modules/agencies/agenciesTypes'
import { RootState } from '@/store/types/storeGlobalTypes'
import { entity } from '@/store/modules/entities/entitiesTypes'

export const getters: GetterTree<AgenciesState, RootState> = {
  getAllDataAgencies(state): Agency[] {
    return state.agencies
  },
  agenciesDataFormattedToGridTable(state): AgencyFormatted[] {
    return state.agencies.map(({ id, name, province, mobilePhone, email }) => {
      return { id, name, province, mobilePhone, email, entity: entity.contacts.agencies.alias }
    })
  },
  getSelectedAgency(state): Agency | null {
    return state.selectedAgency
  }
}
