<template lang="pug">
  div(class="lf-dashboard-calendar")
    iframe(v-if="url"
      :src="url"
      frameborder="0"
      allowfullscreen)
    div(v-else class="lf-default-calendar")
      div(class="lf-default-calendar-header")
        img(
          src="https://assets.lefebvre.es/media/logos/web/comunes/icon-cal.svg"
          class="lf-default-calendar-header-icon"
          alt="Lefebvre calendar icon"
        )
        span(class="lf-default-calendar-header-text") {{ texts.calendarSync }}
      div(class="lf-default-calendar-text") {{ texts.calendarSyncText }}
      div(class="lf-default-calendar-btn")
        button(
          v-if="this.urlAccess!==''"
          @click.stop="selectCalendar"
        ) {{ texts.configAccount }}
      div( v-if="showRefresh" class="lf-default-calendar-refresh")
        span(:class="[icons.refresh,'lf-default-calendar-refresh-icon']")
        span(class="lf-default-calendar-refresh-text") {{ texts.calendarRefresh }}

</template>

<script lang="ts">
import { Icons } from '@/icons/icons'
import { Vue, Watch, Component } from 'vue-property-decorator'
import { Action, Getter, Mutation } from 'vuex-class'
import { ModuleNamespaces } from '@/store/types/storeGlobalTypes'

const lexboxModule = ModuleNamespaces.LEXBOX
const authModule = ModuleNamespaces.AUTH

@Component
export default class LexboxCalendarComponent extends Vue {
  @Getter('getCalendarServiceUrl', { namespace: lexboxModule }) serviceUrl: string

  @Getter('getProUserId', { namespace: authModule }) idProUser: string

  @Getter('getUserName', { namespace: authModule }) userName: string

  @Getter('getAccessToken', { namespace: lexboxModule }) accessToken: string

  @Getter('getNavisionId', { namespace: authModule }) navisionId: number

  @Getter('getUserId', { namespace: authModule }) userId: number

  @Getter('getCompanyId', { namespace: authModule }) selectedCompanyId: number

  @Mutation('RESET_CALENDAR_SERVICE_URL', { namespace: lexboxModule })
  resetServiceUrl: () => void

  @Action('fetchCalendarServiceUrl', { namespace: lexboxModule })
  fetchServiceUrl: (calendarQuery: object) => void

  @Action('fetchAccessToken', { namespace: lexboxModule })
  fetchAccessToken: (calendarQuery: object) => void

  url: string = ''
  urlAccess: string = ''

  icons = {
    refresh: Icons.REFRESH
  }

  texts = {
    calendarSync: this.$t('components.calendar.calendar_sync'),
    calendarSyncText: this.$t('components.calendar.calendar_sync_text'),
    configAccount: this.$t('components.calendar.config_account'),
    calendarRefresh: this.$t('components.calendar.calendar_refresh')
  }

  showRefresh = false

  @Watch('serviceUrl')
  getUrl(value: string) {
    this.url = value
  }

  @Watch('accessToken')
  getUrlAccess(value: string) {
    this.urlAccess = value
  }

  async created() {
    const calendarParams = {
      query: {
        idClienteLef: this.navisionId,
        idUserApp: this.userId,
        name: this.userName,
        origin: 'LexboxCalendarComponent'
      },
      provider: null
    }
    this.serviceUrl ? (this.url = this.serviceUrl) : this.fetchServiceUrl(calendarParams)

    if (this.accessToken === '') {
      const accessParams = {
        idClienteNavision: this.idProUser,
        idCompany: this.selectedCompanyId
      }
      await this.fetchAccessToken(accessParams)
    } else {
      this.urlAccess = this.accessToken
    }

    window.addEventListener('message', this.eventReceived)
  }

  eventReceived = (event: any) => {
    try {
      const data = JSON.parse(event.data)
      if (event.data && data.closeIframe) {
        this.resetServiceUrl()
      }
    } catch (error) {}
  }

  destroyed() {
    window.removeEventListener('message', this.eventReceived)
  }

  selectCalendar() {
    window.open(`${process.env.VUE_APP_LEXBOX_ACCESS}${this.accessToken}`)
    this.showRefresh = true
  }
}
</script>

<style lang="scss" scoped>
iframe {
  border: 0;
  height: 453px;
  width: 100%;
  height: 100%;
  border-radius: 10px;
}

.lf-dashboard-calendar {
  height: 100%;
  background-color: $white-01;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  border-radius: $widget-border-radius;

  .lf-default-calendar {
    border: 1px solid $blue-01;
    padding: 25px;
    color: $blue-01;
    font-family: $corporate-font;
    max-width: 490px;
    margin-top: 0%;

    .lf-default-calendar-header {
      .lf-default-calendar-header-icon {
        height: 25px;
        width: 25px;
      }

      .lf-default-calendar-header-text {
        font-family: $corporate-font-bold;
        font-size: 20px;
        margin-left: 10px;
        position: absolute;
      }
    }

    .lf-default-calendar-text {
      line-height: 18px;
      padding: 15px 0px;
    }

    .lf-default-calendar-btn {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;

      button {
        background-color: $gray-04;
        font-family: $corporate-font-bold;
        font-size: 15px;
        color: $blue-01;
        padding: 10px 21px;
        margin: 10px;
        border: 2px solid $blue-01;
      }
    }

    .lf-default-calendar-refresh {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      padding-top: 10px;

      .lf-default-calendar-refresh-icon {
        font-size: 20px;
      }

      .lf-default-calendar-refresh-text {
        font-family: $corporate-font-bold;
        font-size: 16px;
        margin-left: 10px;
      }
    }
  }
}
</style>
