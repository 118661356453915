<template lang="pug">

  DashboardWidgetListType(
    class=".dashboard-widget"
    v-bind="lastActionsDashboardListProps"
  )

</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import DashboardWidgetListType from '@/components/widgets/DashboardWidgets/widgetTypes/DashboardWidgetListType.vue'
import { DashboardListProps } from '@/store/modules/dashboardWidgets/dashboardWidgetsTypes'
import { Action, Getter } from 'vuex-class'
import { ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import { entity } from '@/store/modules/entities/entitiesTypes'
import { URLS } from '@/router/routes/urlRoutes'
import { SpinnerDashboardContext } from '@/store/modules/spinner/spinnerTypes'
import { Icons } from '@/icons/icons'
import { DashboardAction } from '@/store/modules/dashboardWidgets/dashboardWidgetsTypes'

const dashboardWidgetsModule: string = ModuleNamespaces.DASHBOARD_WIDGETS
const spinnersModule: string = ModuleNamespaces.SPINNER

@Component({
  components: {
    DashboardWidgetListType
  }
})
export default class DashboardLastActionsListWidget extends Vue {
  @Action('fetchDashboardLastActionsList', { namespace: dashboardWidgetsModule })
  fetchDashboardLastActionsAction: () => Promise<void>

  @Getter('getDashboardLastActionsList', { namespace: dashboardWidgetsModule })
  lastActionsGetter: DashboardAction[]

  @Getter('getDashboardWidgetsSpinner', { namespace: spinnersModule })
  showSpinner: (context: string) => boolean

  get lastActionsDashboardListProps(): DashboardListProps {
    return {
      items: this.lastActionsGetter,
      entity: entity.actions.alias,
      headers: [
        'components.dashboard_widget_list_type.actions.headers.type',
        'components.dashboard_widget_list_type.actions.headers.subject',
        'components.dashboard_widget_list_type.actions.headers.file',
        'components.dashboard_widget_list_type.actions.headers.date'
      ],
      headerText: 'components.dashboard_widget_list_type.actions.last_actions',
      url: `/${URLS.ACTIONS}/${URLS.ACTION_ALL}`,
      showSpinner: this.showSpinner(SpinnerDashboardContext.LAST_ACTIONS_LIST),
      noResultIcon: Icons.ACTIONS,
      goToAllText: this.$t('components.dashboard_widget_list_type.actions.go_to_all_text') as string
    }
  }

  created() {
    // Hace la petición solo si ha guardado alguna actuación. Utiliza el estado del spinner como un flag
    if (this.showSpinner(SpinnerDashboardContext.LAST_ACTIONS_LIST)) {
      this.fetchDashboardLastActionsAction()
    }
  }
}
</script>

<style lang="scss" scoped>
.dashboard-widget {
  ::v-deep .table-header tr.table-row:last-child th {
    border-bottom-color: $action-color !important;
  }

  ::v-deep .table-body .table-row .body-cell:nth-child(3) {
    color: $corporate-color !important;
  }
}
</style>
