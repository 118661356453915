<template lang="pug">

  WidgetBaseComponent(
    class="dashboard-list-widget"
    :loadedData="!showSpinner"
    :showFooter="false"
    :showHeader="false"
    customClass="dashboard-widget"
  )

    template(v-slot:body)
      div(class="simple-table-container" v-resize="calculateHeightToSetScroll")

        header(class="header") {{ $t(headerText) }}

        v-simple-table(dense class="simple-table")
          template(v-slot:default)
            thead(class="table-header")
              tr(class="table-row")
                th(
                  v-for="(header, headerIndex) in headers"
                  class="header-cell"
                  :key="headerIndex"
                ) {{ $t(header) }}


            tbody(v-if="items && items.length" class="table-body" :style="{maxHeight: `${expedientListHeight}px`}")
              tr(v-for="(item, itemIndex) in formattedItems" :key="itemIndex" class="table-row")
                router-link(
                  v-for="(cell, cellIndex) in numberOfColumns"
                  :key="cellIndex"
                  :class="['body-cell', getStyleClass(cellIndex)]"
                  @mouseover.native="checkIfEllipsifiedText"
                  tag="td"
                  :to="goTo(itemIndex, cellIndex)"
                )

                  template(v-if="Object.keys(item)[cellIndex] === 'typeId'")
                    span(class="table-icon" :class="checkActionIconHelper(Object.values(item)[cellIndex])")
                    span {{ $t(checkActionTextHelper(Object.values(item)[cellIndex])) }}

                  //- Columna clasificación del listado de ÚLTIMOS DOCUMENTOS
                  template(v-else-if="Object.keys(item)[cellIndex] === 'type'")
                    span(class="table-icon" :class="getDocumentEntityIcon(item.type)")
                    span {{ getDocumentText(Object.values(item)[cellIndex]) }}

                  template(v-else-if="!Object.values(item)[cellIndex]")
                    span -

                  template(v-else)
                    ul(
                      v-if="typeof Object.values(item)[cellIndex] === 'object'"
                      class="items-list"
                    )
                      //- li(v-for="(item, listIndex) in Object.values(item)[cellIndex]") {{ checkIfUnderScore(item) }}
                      li {{ checkIfUnderScore(Object.values(item)[cellIndex][0]) }}

                    span(v-else) {{ Object.values(item)[cellIndex] }}

            DashboardNoResultsComponent(v-else :icon="noResultIcon" class="no-results-component")

        footer(class="footer")
          router-link(v-if="url && items && items.length" class="action-container" :to="url")
            span(class="action-text") {{ goToAllText }}
            span(class="footer-icon" :class="iconRight")

</template>

<script lang="ts">
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import DashboardNoResultsComponent from '@/components/widgets/DashboardWidgets/DashboardNoResultsComponent.vue'
import WidgetBaseComponent from '@/components/widgets/WidgetBaseComponent.vue'
import { Icons } from '@/icons/icons'
import { entity } from '@/store/modules/entities/entitiesTypes'
import { ActionTypes, checkActionIcon, checkActionText } from '@/store/modules/actions/actionsTypes'
import { ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import { Action, Getter } from 'vuex-class'
import { goToDocumentRoute } from '@/helpers/file'
import { URLS } from '@/router/routes/urlRoutes'
import {
  ActionEntityId,
  ContactEntityId,
  driveEntityId,
  ExpedientEntityId,
  generalEntityId,
  InvoiceEntityId,
  templateEntityId,
  signatureEntityId
} from '@/general/entityIds'
import { DocumentPathInfo } from '@/store/modules/fileManager/fileManagerTypes'

const authModule: string = ModuleNamespaces.AUTH
const fileManagerModule: string = ModuleNamespaces.FILE_MANAGER

@Component({
  components: {
    DashboardNoResultsComponent,
    WidgetBaseComponent
  }
})
export default class DashboardWidgetListType extends Vue {
  @Prop({
    type: String,
    required: true
  })
  headerText!: string

  @Prop({
    type: String,
    required: true
  })
  entity!: string

  @Prop({
    type: Array,
    required: true
  })
  items!: []

  @Prop({
    type: Array,
    required: true
  })
  headers!: string[]

  @Prop({
    type: String
  })
  goToAllText!: string

  @Prop({
    type: Array
  })
  activeCells!: number[]

  @Prop({
    type: String
  })
  url!: string

  @Prop({
    type: String
  })
  noResultIcon!: string

  @Prop({
    type: Array,
    default: () => []
  })
  columnClasses!: string[]

  @Prop({
    type: Boolean,
    default: false
  })
  showSpinner!: boolean

  @Action('fetchDocumentPathInfo', { namespace: fileManagerModule })
  fetchDocumentPathInfoAction: (idDocument: string) => DocumentPathInfo

  @Getter('getCompanyMailVersion', { namespace: authModule })
  companyMailVersion: number

  iconRight = Icons.ANGLE_RIGHT

  userCanEditExpedients = false

  checkActionIconHelper = checkActionIcon

  checkActionTextHelper = checkActionText

  expedientListHeight = 0

  @Watch('items')
  checkIfCanEdit(items: any[]) {
    if (items && this.entity === entity.expedients.alias && (this as any).items[0].canEdit === '1') {
      this.userCanEditExpedients = true
    }
  }

  get numberOfColumns() {
    return Object.keys((this as any).formattedItems[0]).length
  }

  get formattedItems() {
    const formattedItems = this.items.map((item: any) => {
      switch (this.entity) {
        case entity.contacts.customers.alias:
          const { name, phone, email } = item
          const customerItemFormatted = {
            name,
            phone,
            email
          }
          return customerItemFormatted
        case entity.actions.alias:
          const { typeId, subject, dateActionAt } = item
          const files = JSON.parse(item.files).Files
          const fileIds = files.map((file: any) => {
            return file.idOwnFile
          })
          const actionItemFormatted = {
            typeId,
            subject,
            files: fileIds,
            dateActionAt
          }
          return actionItemFormatted
        case entity.expedients.alias:
          const { idOwnFile, description, customerName, modifiedDate } = item
          const expedientsItemFormatted = {
            idOwnFile,
            description,
            customerName,
            modifiedDate
          }
          return expedientsItemFormatted
        case entity.invoices.alias:
          const { invoiceDate, customer, totalInvoice } = item
          const invoicesItemFormatted = {
            invoiceDate,
            description: item.description,
            customer,
            totalInvoice
          }
          return invoicesItemFormatted
        case entity.documents.alias:
          const { type, creationDate, modificationDate, idEntityType } = item
          const documentsItemFormatted = {
            type: `${idEntityType}-${type}`,
            name: item.name,
            creationDate,
            modificationDate
          }
          return documentsItemFormatted
      }
    })
    return formattedItems
  }

  checkIfEllipsifiedText(event: Event): void {
    const target = event.target as any
    const container = target!.className === 'body-cell' ? target : target.parentNode
    const title = target.textContent
    const overflowed = container.scrollWidth > container.clientWidth
    target.title = overflowed ? title : ''
  }

  checkIfUnderScore(value: string) {
    return !value ? '-' : value
  }

  getStyleClass(cellIndex: any) {
    return this.columnClasses ? this.columnClasses[cellIndex] : ''
  }

  getDocumentEntityIcon(entityFormatedText: string) {
    const splittedText = entityFormatedText.split('-')
    const entityId = Number(splittedText[0])
    switch (entityId) {
      case ActionEntityId.CALL:
      case ActionEntityId.ALL:
      case ActionEntityId.MEETING:
      case ActionEntityId.PROCEDURE:
      case ActionEntityId.OTHER:
      case ActionEntityId.EMAIL:
      case ActionEntityId.LEXNET:
        return Icons.CLOCK
      case ExpedientEntityId.EXPEDIENTS:
        return Icons.EXPEDIENT
      case ContactEntityId.CONTACTS:
      case ContactEntityId.CUSTOMERS:
      case ContactEntityId.OPPONENTS:
      case ContactEntityId.PROVIDERS:
      case ContactEntityId.OWN_LAWYERS:
      case ContactEntityId.OPPONENT_LAWYERS:
      case ContactEntityId.OWN_ATTORNEYS:
      case ContactEntityId.OPPONENT_ATTORNEYS:
      case ContactEntityId.NOTARIES:
      case ContactEntityId.COURTS:
      case ContactEntityId.INSURERS:
      case ContactEntityId.OTHERS:
      case ContactEntityId.AGENCIES:
        return Icons.CONTACTS
      case InvoiceEntityId.INVOICES:
      case InvoiceEntityId.CUSTOMER_BAKDRAFTS:
      case InvoiceEntityId.CUSTOMER_INVOICES:
      case InvoiceEntityId.PROFORMA_INVOICES:
      case InvoiceEntityId.PROVISIONS:
      case InvoiceEntityId.PROVIDER_BANKDRAFTS:
      case InvoiceEntityId.PROVIDER_INVOICES:
      case InvoiceEntityId.ORDER_FORMS:
        return Icons.BILLING
      case signatureEntityId:
      case templateEntityId:
      case driveEntityId:
      case generalEntityId:
        return Icons.DOCUMENTATION
      default:
        return Icons.CIRCLE_O
    }
  }

  getDocumentText(entityFormatedText: string) {
    const splittedText = entityFormatedText.split('-')
    const entityText = splittedText[1]
    return this.checkIfUnderScore(entityText)
  }

  goTo(itemIndex: number, cellIndex: number, listIndex: number = 0) {
    const item: any = this.items[itemIndex]

    const getFileId = () => {
      const parseFiles = JSON.parse(item.files)
      const files = parseFiles.Files
      return files[listIndex].idExpedient
    }

    switch (this.entity) {
      case entity.expedients.alias:
        return `/${URLS.EXPEDIENT}/${item.idExpedient}`

      case entity.actions.alias:
        const idExpedient = getFileId()
        if (cellIndex === 2 && idExpedient) {
          return `/${URLS.EXPEDIENT}/${idExpedient}`
        } else {
          switch (item.typeId) {
            case ActionTypes.CALLS:
              return `/${URLS.ACTIONS}/${URLS.ACTION_CALLS}/${item.actionId}`
            case ActionTypes.EMAILS:
              if (this.companyMailVersion === 1) {
                return `${process.env.VUE_APP_URL}/emails?id=${item.actionId}&returnURL=${window.location.origin}`
              } else {
                return `/${URLS.ACTIONS}/${URLS.ACTION_EMAILS}/${item.actionId}`
              }

            case ActionTypes.LEXNET:
              return `/${URLS.ACTIONS}/${URLS.ACTION_LEXNET}/${item.actionId}`
            case ActionTypes.MEETINGS:
              return `/${URLS.ACTIONS}/${URLS.ACTION_MEETINGS}/${item.actionId}`
            case ActionTypes.PROCEDURES:
              return `/${URLS.ACTIONS}/${URLS.ACTION_PROCEDURES}/${item.actionId}`
            case ActionTypes.OTHERS:
              return `/${URLS.ACTIONS}/${URLS.ACTION_OTHERS}/${item.actionId}`
          }
        }
        break

      case entity.invoices.alias:
        return `/${URLS.BILLING}/${URLS.CUSTOMER_INVOICES}/${item.idInvoice}`

      case entity.contacts.customers.alias:
        return `/${URLS.CONTACTS}/${URLS.CUSTOMERS}/${item.id}`

      case entity.documents.alias:
        const route = goToDocumentRoute(item, { basePath: item.basePath, path: item.path })
        return route
    }
  }

  async calculateHeightToSetScroll() {
    await this.$nextTick()
    const mainSection = this.$el.querySelector('.main-section')
    if (mainSection) {
      const mainSectionHeight = mainSection.getBoundingClientRect().height
      this.expedientListHeight = mainSectionHeight - 100
    }
  }
}
</script>

<style lang="scss" scoped>
$cells-font-size: 12px;

.dashboard-list-widget {
  &.spinner-layer-container {
    height: 100%;
  }

  .simple-table-container {
    @include flex($flex-direction: column, $justify-content: space-around);
    width: 100%;
    height: 100%;
    margin: 20px;
  }

  .header {
    align-self: flex-start;
    font-family: $corporate-font-black;
    font-size: 1vw;
    text-transform: uppercase;
    color: $corporate-color;
    padding: 0;
    margin-top: 0;
  }

  .simple-table {
    font-family: $secondary-font;
    width: 100%;
    color: $gray-02;

    .table-header tr.table-row:last-child th {
      @include border($direction: bottom);
      text-transform: capitalize;
    }

    .table-body {
      @include scroll-styles;

      .table-row .body-cell {
        height: 1.8vw;
        font-size: 0.7vw;
        color: $corporate-color;
        vertical-align: middle;
        cursor: pointer;

        &:nth-child(n + 3) {
          color: $gray-02;
        }
      }
    }

    .table-header,
    .table-body tr {
      display: table;
      width: 100%;
      table-layout: fixed;
    }

    .table-header {
      margin-bottom: 5px;
    }

    .table-body tr.table-row:hover {
      background-color: $gray-03;
    }

    .table-body .table-row:nth-child(even) {
      background-color: $gray-04;
    }

    .header-cell,
    .body-cell {
      padding: 0;
      height: 25px;
    }

    .header-cell {
      font-size: $cells-font-size;
      color: $gray-01;
      padding-bottom: 3px;
      padding-left: 6px;
    }

    .body-cell {
      @include ellipsis;
      font-size: $cells-font-size;
      border: none !important;
      word-wrap: break-word;
      vertical-align: bottom;

      span {
        padding-left: 6px;
      }
    }

    .items-list {
      @include list;
      padding-left: 6px;
    }

    .table-icon {
      font-size: 0.8vw;
      color: $corporate-color;
      margin-right: 8px;
    }
  }

  .footer {
    @include flex($justify-content: flex-end);
    height: 24px;
    font-family: $corporate-font-bold;
    font-size: 16px;
    font-weight: inherit;
    color: $corporate-color;
    padding: 0;
    margin: 4px 0;
    text-transform: inherit;
    background: transparent;
  }

  .action-container {
    @include flex;
    text-decoration: none;
    margin-right: 12px;
    cursor: pointer;

    .action-text {
      font-size: 0.9vw;
    }

    &:hover {
      color: $blue-04;
    }
  }

  .footer-icon {
    font-size: 12px;
    margin-left: 3px;
    padding-top: 1px;
  }
}

@include syncfusionSmAndMdBreakpoint {
  .dashboard-list-widget {
    .header {
      font-size: 24px;
    }
  }
}
</style>
