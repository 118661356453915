<template lang="pug">
div(class="navigation-container")
  AppBarComponent(
    @toogle-navigation-drawer="changeDrawerStatus"
    @open-control-sidebar="openControlSidebar"
    :window-width="windowWidth"
  )
  DrawerComponent(
    :mini="drawerInMiniStatus"
    :window-width="windowWidth"
    :burguerButtonIsClicked="burguerButtonIsClicked"
  )
  ControlSidebarComponent(
    ref="controlSidebar"
    :opened="controlSidebarOpened"
    @close-control-sidebar="closeControlSidebar"
    :selectedItem="selectedControlSidebarItem"
    @change-selected-item="changeSelectedItemFromChildren"
  )
</template>

<script lang="ts">
import { Vue, Component } from 'vue-property-decorator'
import AppBarComponent from '@/components/Navigation/AppBar/AppBarComponent.vue'
import DrawerComponent from '@/components/Navigation/Drawer/DrawerComponent.vue'
import ControlSidebarComponent from '@/components/Navigation/ControlSidebar/ControlSidebarComponent.vue'
import { Getter, Mutation } from 'vuex-class'
import { ModuleNamespaces } from '@/store/types/storeGlobalTypes'

const menusModule = ModuleNamespaces.MENUS

@Component({
  components: {
    AppBarComponent,
    DrawerComponent,
    ControlSidebarComponent
  }
})
export default class NavigationContainerComponent extends Vue {
  @Getter('getDrawerStatus', { namespace: menusModule })
  drawerInMiniStatus: boolean

  @Mutation('TOOGLE_DRAWER_STATUS', { namespace: menusModule })
  toogleDrawerStatus: () => boolean

  controlSidebarOpened = false

  selectedControlSidebarItem = ''

  windowWidth = window.innerWidth

  burguerButtonIsClicked = false

  async changeDrawerStatus() {
    this.toogleDrawerStatus()
    this.burguerButtonIsClicked = true
    setTimeout(() => {
      this.burguerButtonIsClicked = false
    }, 500)
  }

  openControlSidebar(selectedItem: string) {
    this.selectedControlSidebarItem = selectedItem
    this.controlSidebarOpened = true
  }

  closeControlSidebar() {
    this.controlSidebarOpened = false
  }

  changeSelectedItemFromChildren(item: string) {
    this.selectedControlSidebarItem = item
  }

  mounted() {
    window.onresize = () => {
      this.windowWidth = window.innerWidth
    }
  }
}
</script>

<style lang="scss" scoped>
.navigation-container {
  font-family: $secondary-font;
  font-size: 300px;
  color: $corporate-color;
  font-size: 14px;
  z-index: 99998;
}
</style>
