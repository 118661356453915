<template lang="pug">

  section(
      v-if="!getEntityId"
      :class="{'lexon-view-container': !showSpinnerLayer}"
    )
    router-view
  router-view(v-else)

</template>

<script lang="ts">
import { ParentCategory } from '@/store/modules/menus/menusTypes'
import { ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import { Vue, Component, Watch } from 'vue-property-decorator'
import { Getter, Mutation } from 'vuex-class'

const entitiesModule = ModuleNamespaces.ENTITIES
const spinnerModule = ModuleNamespaces.SPINNER
const billingsModule = ModuleNamespaces.BILLING

@Component
export default class BilllingView extends Vue {
  @Mutation('SAVE_MENU_PARENT_CATEGORY_NAME', { namespace: entitiesModule })
  saveMenuParentCategoryName: (parentCategoryName: string) => void

  @Mutation('RESET_TOTALS', { namespace: billingsModule })
  resetBillingTotals: () => void

  @Getter('getShowSpinnerLayer', { namespace: spinnerModule })
  showSpinnerLayer: boolean

  get getEntityId() {
    return this.$route.params.selectedRegisterId
  }

  @Watch('$route')
  async onRouteChange() {
    this.resetBillingTotals()
  }

  created() {
    this.saveMenuParentCategoryName(ParentCategory.BILLING)
  }
}
</script>
