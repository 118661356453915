<template lang="pug">

  v-navigation-drawer(
    clipped
    dark
    :width="drawerWidth"
    :mini-variant-width="calculateWidth"
    :class="['navigation-drawer', checkDrawerStatus, { 'is-portal': isPortalUser }]"
    :mini-variant="mini"
    permanent
    :temporary="$vuetify.breakpoint.mdAndUp ? false : true"
  )

    v-list(dense class="pa-0" )
      template(v-for="(item, index) in siteMenu")

        //- Item with children
        v-list-group(
          v-if="item.children.length"
          :key="`${item.text}-${index}`"
          class="submenu-list"
          :value="isPortalUser ? true : checkIfOpened(item.text)"
          :append-icon="iconRight"
          :prepend-icon="!mini ? item.icon : ''"
          :ripple="false"
          :disabled="mini"

          )

          v-popover(
              offset="0"
              placement ="right"
              slot="prependIcon"
              v-if="mini"
              trigger="hover"
              :class="checkIfHighlight(item)"
            )
            v-icon {{ item.icon }}
            template(slot="popover")
              ul(class="popover popover-menu")
                li(v-for="(child, i) in item.children" :key="i" :class="checkPopoverItemClasses(!!child.children)")

                  //- Popover with subchild
                  v-list-group(
                      v-if="child.children.length"
                      :key="`${child.text}-${index}`"
                      :prepend-icon="child.icon"
                      :append-icon="iconRight"
                      :ripple="false"
                    )
                    template(v-slot:activator)
                      v-list-item(:ripple="false")  {{ child.text.toUpperCase() }}

                    v-list-item(
                        v-for="(subchild, subIndex) in child.children"
                        :key="`${subchild.text}-${subIndex}`"
                        class="subchild-item"
                        :to="subchild.internalUrl ? `/${subchild.url}` : ''"
                        :target="subchild.target"
                        :href="subchild.internalUrl ? '' : formatUrl(subchild.url)"
                        :ripple="false"
                      )
                      v-list-item-action(v-if="subchild.icon")
                          v-icon(:class="checkIfCircleIconClass(subchild.icon, true)") {{ subchild.icon }}
                      v-list-item-content
                        v-list-item-title(class="subchild-title") {{ subchild.text.toUpperCase() }}

                  //- Popover without subchilds
                  div(v-else class="popover-no-subchild-item")
                    router-link(
                        v-if="child.internalUrl"
                        class="popover-item-text"
                        :to="`/${child.url}`"
                        :target="child.target"
                      ) {{ child.text }}
                    a(
                      v-else
                      class="popover-item-text"
                      :target="child.target"
                      :href="formatUrl(child.url)"
                      ) {{ child.text }}


          template(v-slot:activator)
            v-list-item(class="header-submenu-text-item" :ripple="false")
              v-list-item-content
                v-list-item-title(class="list-item-title px-0") {{ item.text.toUpperCase() }}

          template(v-for="(child, index) in item.children")

            //- Child with subchilds
            v-list-group(
              v-if="child.children.length"
              :key="`${child.text}-${index}`"
              class="subchild-list"
              sub-group
              :ripple="false"
              :disabled="mini"
              )
              template(v-slot:activator)
                v-list-item(:ripple="false")
                  v-list-item-content
                    v-list-item-title {{ child.text.toUpperCase() }}

              template(slot="prependIcon")
                v-icon {{ child.icon }}

              template(slot="appendIcon")
                v-icon {{ iconRight }}

              v-list-item(
                  v-for="(subchild, subindex) in child.children"
                  :key="`${subchild.text}-${index}`"
                  class="subchild-item px-2"
                  :to="subchild.internalUrl ? `/${subchild.url}` : ''"
                  :href="subchild.internalUrl ? '' : formatUrl(subchild.url)"
                  :target="subchild.target"
                  :ripple="false"
                )
                v-list-item-action(v-if="subchild.icon")
                    v-icon(:class="checkIfCircleIconClass(subchild.icon, true)") {{ subchild.icon }}
                v-list-item-content(class="pa-1")
                  v-list-item-title {{ subchild.text.toUpperCase() }}


            //- Child without subchilds
            v-list-item(
                v-if="!child.children.length && !mini"
                class="submenu-item"
                :to="child.internalUrl ? `/${child.url}` : ''"
                :href="child.internalUrl ? '' : formatUrl(child.url)"
                :target="child.target"
                :ripple="false"
              )
              v-list-item-content
                v-list-item-title(class="list-item-title") {{ child.text.toUpperCase() }}

        //- Item without children
        v-list-item(
            v-else
            :key="`${item.text}-${index}`"
            class="menu-item"
            :class="checkSpecialItemClasses(item.text)"
            :to="item.internalUrl ? getInternalUrl(item.url, item.internalUrl) : ''"
            :href="item.internalUrl ? '' : formatUrl(item.url)"
            :target="item.target"
            :ripple="false"
          )

          template(v-if="!mini")
            v-list-item-action(:class="{'mr-3': !mini}")
              v-icon(class="drawer-icon") {{ item.icon }}
            v-list-item-content
              v-list-item-title(
                  class="list-item-title"
                ) {{ item.text.toUpperCase() }}

          template(v-else)
            //- Popover without children
            v-popover(offset="0"  placement ="right" trigger="hover" :popoverClass ="[item.text, { 'is-portal': isPortalUser }]")
              v-icon {{ item.icon }}
              template(slot="popover")
                router-link(
                  v-if="item.internalUrl"
                  :class="['popover', 'popover-link', checkSpecialItemClasses(item.text)]"
                  :to="`/${item.url}`"
                  :target="item.target"
                ) {{ item.text }}
                a(
                  v-else
                  class="popover popover-link"
                  :href="formatUrl(item.url)"
                  :target="item.target"
                  ) {{ item.text }}


</template>

<script lang="ts">
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { Icons } from '@/icons/icons'
import { Getter } from 'vuex-class'
import { ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import { SiteMenuItem } from '@/store/modules/menus/menusTypes'
import { normalizeText } from '@/helpers/helpers'
import {
  MENU_MINI_STATUS_MIN_WIDTH,
  MENU_MINI_STATUS_MAX_WIDTH,
  userPortalDrawerNames
} from '@/components/Navigation/NavigationConsts'
import { URLS } from '@/router/routes/urlRoutes'
 import { vars } from '@/styles/styleVars'

const menusModule = ModuleNamespaces.MENUS
const authModule = ModuleNamespaces.AUTH

@Component
export default class DrawerComponent extends Vue {
  @Prop({
    type: Boolean
  })
  mini!: boolean

  @Prop({
    type: Number
  })
  windowWidth!: number

  @Prop({
    type: Boolean
  })
  burguerButtonIsClicked!: boolean

  @Getter('getSiteMenu', { namespace: menusModule }) siteMenu: SiteMenuItem[]
  @Getter('getIsPortalUser', { namespace: authModule }) isPortalUser: boolean

  iconRight = Icons.ANGLE_RIGHT

  get checkDrawerStatus(): string {
    return this.mini ? 'mini' : 'opened'
  }

  get calculateWidth(): number {
    return this.windowWidth <= 960 ? 0 : 63
  }

  get drawerWidth() {
    return vars.navigation_drawer_width
  }

  @Watch('windowWidth', { immediate: true })
  collapse() {
    this.checkIfCollapse()
  }

  checkIfOpened(itemText: string): boolean {
    const itemWithoutAccents = itemText.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
    return itemWithoutAccents.toLowerCase() === this.$route.meta?.parentUrl
  }

  formatUrl(url: string) {
    return `${process.env.VUE_APP_URL}/${url}`
  }

  checkIfCollapse() {
    if (this.windowWidth > MENU_MINI_STATUS_MIN_WIDTH && this.windowWidth <= MENU_MINI_STATUS_MAX_WIDTH) {
      this.$store.state.menusModule.drawerInMiniStatus = true
    } else if (!this.burguerButtonIsClicked && this.windowWidth > MENU_MINI_STATUS_MAX_WIDTH) {
      this.$store.state.menusModule.drawerInMiniStatus = false
    }
  }

  checkIfHighlight(item: SiteMenuItem) {
    let highlighted = false

    item.children.forEach((c) => {
      const normalize = normalizeText(c.text)

      if (normalize.replace(/\s/g, '-').toLowerCase() === this.$route.name) {
        highlighted = true
      }
    })

    return { highlighted }
  }

  checkSpecialItemClasses(itemText: string): object | string {
    const itemTextNormalize = normalizeText(itemText)
    let highlighted = false

    if (this.$route.name) {
      highlighted =
        this.$route.name.includes(itemTextNormalize.toLowerCase()) ||
        (itemTextNormalize === userPortalDrawerNames.EXPEDIENTS && this.$route.name.includes(URLS.EXPEDIENTS))
    }

    if (this.$t('routes.actions_lexnet').toString() === itemText.toLowerCase()) {
      highlighted = false
    }

    return { highlighted }
  }

  checkPopoverItemClasses(hasSubchild: boolean): string {
    return hasSubchild ? 'popover-subchild-list' : 'popover-menu-item'
  }

  checkIfCircleIconClass(icon: string, subchildtype: boolean): object {
    return subchildtype
      ? { 'subchild-circle-icon': icon === 'lf-icon-step-first' }
      : { 'child-circle-icon': icon === 'lf-icon-step-first' }
  }

  getInternalUrl(url: string): string {
    return this.mini ? '' : `/${url}`
  }

  getExternalUrl(url: string) {
    return this.mini ? '' : this.formatUrl(url)
  }
}
</script>

<style lang="scss" scoped>
$item-menu-height: 50px;
$submenu-item-background-color: $blue-07;
$popover-padding: 15px 5px 12px 20px;
$icon-size: 20px;
$rotate-transition: transform 0.1s linear;

.popover {
  width: 233px;
  font-family: $corporate-font-medium;
  font-size: 12px;
  color: $white-01;
  margin-left: 44px;
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
  cursor: pointer;

  &.popover-menu {
    margin-left: 0;
  }
}

.popover-link {
  display: block;
  height: $item-menu-height;
  text-decoration: none;
  background-color: $blue-15;
  padding: $popover-padding;
  text-transform: uppercase;

  &.highlighted {
    background-color: $corporate-color;

    &::after {
      border-left: 10px solid $corporate-color;
    }
  }

  &::after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    top: 0;
    right: -9px;
    border-top: 25px solid transparent;
    border-bottom: 25px solid transparent;
    border-left: 10px solid $blue-15;
  }
}

.popover-no-subchild-item {
  @include flex($justify-content: flex-start);
  height: 38px;
  font-size: 14px;
  background-color: $white-01;
  position: relative;

  &:hover {
    background-color: $blue-15;

    .popover-item-text {
      width: 100%;
      color: $white-01;

      &::after {
        content: '';
        position: absolute;
        width: 0;
        height: 0;
        top: 0;
        right: 0;
        border-top: 19px solid $white-01;
        border-bottom: 19px solid $white-01;
        border-left: 8px solid $blue-15;
      }
    }
  }

  .popover-item-text {
    width: 100%;
    color: $corporate-color;
    margin-left: 12px;
    text-decoration: none;

    &.router-link-active {
      display: flex;
      align-items: center;
      height: 100%;
      width: 100%;
      background-color: $corporate-color;
      color: $white-01;
      margin-left: 0;
      padding-left: 12px;

      &::after {
        content: '';
        position: absolute;
        width: 0;
        height: 0;
        top: 0;
        right: 0;
        border-top: 19px solid $white-01;
        border-bottom: 19px solid $white-01;
        border-left: 8px solid $corporate-color;
      }
    }
  }
}

.popover-menu {
  @include list;
  position: absolute;
  top: -23px;
  color: $corporate-color;
  background-color: $submenu-item-background-color;
  padding: 12px;

  .popover-menu-item {
    @include flex;
    @include border($direction: bottom, $color: $blue-04);
    height: $item-menu-height;
    padding-left: 15px;

    .popover-item-text {
      @include flex($justify-content: flex-start);
      width: 100%;
      height: 46px;
      text-decoration: none;
      vertical-align: bottom;
      color: $corporate-color;
      padding-left: 18px;
    }

    &:hover {
      background-color: $white-01;
    }
  }

  .popover-subchild-list {
    background-color: $submenu-item-background-color;
    position: relative;

    &:not(:last-of-type)::after {
      content: '';
      position: absolute;
      bottom: 0;
      width: 191px;
      left: 9px;
      border-bottom: 1px solid $blue-04;
    }

    ::v-deep .v-list-group__items {
      background-color: $gray-02;
      padding-left: 11px;
    }

    .subchild-item {
      background-color: $submenu-item-background-color;
    }

    ::v-deep .v-list-group__header > .v-list-group__header__append-icon .v-icon {
      transition: $rotate-transition;
    }

    ::v-deep .v-list-group--active > .v-list-group__header > .v-list-group__header__append-icon .v-icon {
      transform: rotate(90deg);
      transition: $rotate-transition;
    }

    ::v-deep .v-list-group__header {
      @include border($direction: bottom, $color: $blue-04);
      height: $item-menu-height;

      &:hover {
        background-color: $white-01;

        .v-icon {
          color: $corporate-color;
        }

        &::before {
          opacity: 0;
        }
      }
    }

    ::v-deep .v-icon {
      font-size: 20px;

      &.subchild-circle-icon {
        font-size: 12px;
        padding: 0;
      }
    }

    ::v-deep .v-list-item__icon {
      align-self: center;
      margin: 0;
    }

    ::v-deep .v-list-group__items {
      .subchild-item {
        @include border($direction: bottom, $color: $blue-04);

        &:hover {
          background-color: $white-01;

          .v-icon {
            color: $corporate-color;
          }
        }
      }
    }

    ::v-deep .v-list-group .v-list-item {
      color: $corporate-color !important;
      padding-top: 2px;
    }

    ::v-deep .v-list-group__header__append-icon {
      .v-icon {
        font-size: 12px;
        color: $corporate-color;
        transition: none;
      }
    }

    .subchild-title {
      font-size: 13px;
      padding-left: 8px;
    }
  }
}

.navigation-drawer {
  font-family: $corporate-font-medium;
  background-color: transparent;
  padding-top: 77px;
  box-shadow: none;

  .v-list {
    border-right: 1px solid $blue-07;

    .submenu-list {
      &:last-of-type {
        border-radius: 0 0 4px 4px;
        ::v-deep .v-list-group__header {
          border-radius: 0 0 4px 4px;
        }
      }
    }
  }

  .menu-item {
    background-color: $blue-06;

    &:first-of-type {
      color: $corporate-color;
      background-color: $white-01;
      padding: 0;

      &.highlighted {
        .v-list-item__action {
          background-color: $corporate-color;
        }
      }

      .v-list-item__action {
        display: flex;
        justify-content: center;
        height: 100%;
        width: 60px;
        background-color: $blue-06;
        margin: 0;
        border-radius: 0 50px 50px 0;
        padding-right: 7px;

        .v-icon {
          font-size: 26px;
        }
      }

      .list-item-title {
        font-size: 16px;
      }

      &:hover {
        background-color: $blue-15;

        .v-list-item__action {
          background-color: $blue-15;
        }
      }
    }

    &:not(:last-child)::after {
      content: '';
      position: absolute;
      top: 9px;
      width: 208px;
      border-bottom: 1px solid $white-01;
    }

    &:hover::after {
      content: '';
      position: absolute;
      width: 0;
      height: 0;
      top: 0;
      right: -9px;
      border-top: 25px solid transparent;
      border-bottom: 25px solid transparent;
      border-left: 10px solid $blue-15;
    }
  }

  //- Scroll behaviour
  ::v-deep .v-navigation-drawer__content {
    scrollbar-width: none;
    -ms-overflow-style: none;
    padding-right: 10px;

    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }
  }

  ::v-deep .v-icon {
    position: relative;
    top: -2px;
    color: $corporate-color;
    font-size: $icon-size;
    transition: none;

    &.child-circle-icon {
      font-size: 12px;
      padding-left: 4px;
    }

    &.subchild-circle-icon {
      font-size: 12px;
      padding: 0 8px;
    }
  }

  ::v-deep .v-list-group__header__append-icon .v-icon {
    font-size: 12px;
    padding-top: 3px;
  }

  ::v-deep .v-list-group__header > .v-list-group__header__append-icon .v-icon {
    transition: $rotate-transition;
  }

  ::v-deep .v-list-group--active > .v-list-group__header > .v-list-group__header__append-icon .v-icon {
    transform: rotate(90deg);
    transition: $rotate-transition;
  }

  .v-list-item__title.list-item-title {
    font-size: 12px;
  }

  ::v-deep .v-list-group__header__append-icon {
    padding-right: 5px;
  }

  .submenu-item {
    background-color: $white-01;
    text-transform: lowercase;
    padding: 0;
    height: 38px !important;
    min-height: 38px !important;

    &::before {
      display: none;
    }

    &.v-list-item--active:hover {
      background-color: $corporate-color;
    }

    &:not(.v-list-item--active) {
      &:not(:last-of-type) .v-list-item__content::after {
        content: '';
        position: absolute;
        bottom: 0;
        width: 191px;
        left: 9px;
        border-bottom: 1px solid $blue-04;
      }

      &:hover {
        .v-list-item__content {
          background-color: $blue-15;
        }

        &::after {
          content: '';
          position: absolute;
          width: 0;
          height: 0;
          top: 0;
          right: 0;
          border-top: 19px solid $white-01;
          border-bottom: 19px solid $white-01;
          border-left: 8px solid $blue-15;
        }
      }
    }

    .v-list-item__content {
      height: 38px;
      padding-left: 8px;

      .list-item-title {
        font-size: 14px;
      }
    }

    .list-item-title::first-letter {
      text-transform: uppercase;
    }

    &.v-list-item--active {
      background-color: $corporate-color;

      &::after {
        content: '';
        position: absolute;
        width: 0;
        height: 0;
        top: 0;
        right: 0;
        border-top: 19px solid $white-01;
        border-bottom: 19px solid $white-01;
        border-left: 8px solid $corporate-color;
      }

      .list-item-title {
        color: $white-01;
      }

      &::before {
        opacity: 0;
      }
    }
  }

  .v-list-item {
    height: $item-menu-height;
    cursor: pointer;

    &:hover {
      .v-icon,
      .v-list-item__content {
        color: $corporate-color;
      }
    }

    &.highlighted {
      background-color: $corporate-color;
      border-radius: 4px 0 0 4px;

      .v-icon,
      .v-list-item__content {
        color: $white-01;
      }

      &::after {
        content: '';
        position: absolute;
        width: 0;
        height: 0;
        top: 0;
        right: -9px;
        border-top: 25px solid transparent;
        border-bottom: 25px solid transparent;
        border-left: 10px solid $corporate-color;
      }

      &:hover::after {
        border-left: 10px solid $blue-15;
      }
    }
  }

  .submenu-item:hover {
    background: $white-01;
  }

  ::v-deep .v-list-group__header,
  .menu-item {
    padding-right: 16px;

    &::before {
      opacity: 0;
    }

    &:hover {
      background-color: $blue-15;

      .v-icon {
        color: $white-01;
      }
    }
  }

  .submenu-list {
    ::v-deep .v-list-group__items {
      padding: 0 10px 10px;
      background-color: $blue-07;
    }

    ::v-deep .v-list-item__icon {
      height: $item-menu-height;
      margin: 0 12px 0 0;
    }

    ::v-deep .v-list-item__icon.v-list-group__header__append-icon {
      margin: 0;
      padding: 0;
    }

    ::v-deep > .v-list-item:not(.v-list-item--active) {
      background: $blue-06;

      &::after {
        content: '';
        position: absolute;
        top: 9px;
        width: 208px;
        border-bottom: 1px solid $white-01;
      }

      &:hover {
        background-color: $blue-15;

        .header-submenu-text-item {
          background-color: $blue-15;
        }

        &::after {
          content: '';
          position: absolute;
          width: 0;
          height: 0;
          top: 0;
          right: -9px;
          border-top: 25px solid transparent;
          border-bottom: 25px solid transparent;
          border-left: 10px solid $blue-15;
        }
      }
    }

    &:last-child {
      ::v-deep > .v-list-item {
        &::after {
          border-bottom: none;
        }
      }
    }

    &.v-list-group--active {
      background-color: $submenu-item-background-color;

      ::v-deep .v-list-group__header {
        border-radius: 4px 4px 0 0;
      }

      .v-list-item__action {
        margin: 0;
      }

      .v-icon {
        color: $corporate-color;
      }

      .v-list-item__content {
        color: $corporate-color;
      }

      ::v-deep .v-list-group__header {
        background-color: $blue-07;

        &:hover {
          border: none;

          .v-list-item__content,
          .v-icon {
            color: $corporate-color;
          }
        }
      }

      &:hover {
        ::v-deep .v-list-group__header:before {
          opacity: 0;
        }
      }
    }

    .subchild-list {
      ::v-deep .v-list-group__header {
        @include border($direction: bottom, $color: $blue-04);
        background-color: $submenu-item-background-color;
        padding-left: 16px;

        .v-list-item__icon:first-child {
          margin-right: 0;
        }

        .v-list-item {
          padding: 0;
        }

        &:hover {
          @include border($direction: bottom, $color: $blue-04);
          background: $white-01;
        }

        .v-icon {
          color: $corporate-color;
        }

        .v-list-group__header__prepend-icon {
          .v-icon {
            transform: none;
          }
        }
      }

      ::v-deep .v-list-group__items {
        background-color: $gray-02;
        padding-left: 11px;
      }

      .subchild-item {
        @include border($direction: bottom, $color: $blue-04);
        background-color: $submenu-item-background-color;
      }

      .subchild-item:hover {
        background-color: $white-01;
      }
    }

    // &:last-child {
    //   ::v-deep .v-list-item {
    //     &::after {
    //       border: none;
    //     }
    //     &:hover {
    //       background-color: $blue-15;
    //     }
    //   }
    // }
  }

  .header-submenu-text-item {
    padding: 0;
  }

  // DRAWER MENU MINI STATUS
  &.mini {
    padding-top: 77px;
    margin-left: 0;

    ::v-deep .v-icon {
      padding-right: 10px;
    }

    .menu-item {
      &:not(.v-list-item--link):first-of-type {
        background-color: $white-01;
      }

      &.v-list-item--link:first-of-type {
        background-color: $blue-06;
      }

      &:hover {
        background-color: $blue-15 !important;

        &:not(.highlighted):nth-child(1) {
          ::v-deep .trigger .v-icon {
            background-color: $blue-15;
            border-radius: 0;
          }
        }
      }

      &:not(.highlighted):nth-child(1) {
        ::v-deep .trigger .v-icon {
          display: flex;
          justify-content: center;
          height: 100%;
          width: 100%;
          font-size: 26px;
          background: $blue-06;
          border-radius: 0 50px 50px 0;
        }
      }

      &:not(:last-child):not(.highlighted)::after {
        top: 10px;
        left: 0;
        width: 40px;
      }

      &.v-list-item.highlighted {
        border-radius: 0;
        background-color: $corporate-color;

        &::after {
          content: '';
          position: absolute;
          width: 0;
          top: 0;
          height: 0;
          border-top: 25px solid transparent;
          border-bottom: 25px solid transparent;
          border-left: 10px solid $corporate-color;
        }
      }

      &:hover {
        &::after {
          display: none;
        }
      }
    }

    .v-popover {
      text-align: center;

      &.highlighted:not(.open) {
        background: $corporate-color;

        .v-icon {
          color: $white-01 !important;
        }

        &::after {
          content: '';
          position: absolute;
          width: 0;
          top: 0;
          height: 0;
          border-top: 25px solid transparent;
          border-bottom: 25px solid transparent;
          border-left: 10px solid #001978;
        }
      }
    }

    ::v-deep .trigger {
      width: 53px;
      height: $item-menu-height;
      line-height: $item-menu-height;
    }

    .submenu-list.v-list-group--active ::v-deep .v-list-group__header {
      background-color: $blue-06;

      .v-icon {
        color: $corporate-color;
      }
    }

    .submenu-list ::v-deep .v-list-group__items {
      padding-bottom: 0;
    }

    ::v-deep .v-list-group__header.v-list-item {
      padding: 0;

      &:hover {
        color: $corporate-color;
        background-color: $blue-07 !important;

        .v-icon {
          color: $corporate-color;
        }
      }
    }

    .submenu-list ::v-deep > .v-list-item:not(.v-list-item--active) {
      background: $blue-06;

      &::after {
        top: 10px;
        left: 0;
        width: 40px;
      }
    }

    .submenu-list ::v-deep > .v-list-item:not(.v-list-item--active):hover::after {
      display: none !important;
    }

    .v-list-item.highlighted {
      background-color: $corporate-color;

      .v-icon,
      .v-list-item__content {
        color: $white-01;
      }
    }

    .v-list-item__action {
      @include flex;
    }

    ::v-deep .theme--dark.v-list-item:hover::before {
      opacity: 0;
    }

    ::v-deep .v-list-group__header .v-list-group__header__prepend-icon .v-popover.open {
      background-color: $blue-07;
    }

    ::v-deep .v-list-group__header,
    .menu-item {
      &:hover {
        background-color: $corporate-color;
        border-right: none;

        .v-icon {
          color: $white-01;
        }
      }
    }

    ::v-deep .v-list-group__header:hover {
      background-color: $corporate-color;

      &::before {
        opacity: 0;
      }
    }

    ::v-deep .v-list-group__header__prepend-icon {
      @include flex;
    }

    ::v-deep .v-list-item__icon {
      margin: 0;
    }
  }

  .theme--dark.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) {
    color: $corporate-color !important;
  }

  .v-list-item:hover .v-list-item__content {
    color: $white-01;
  }

  &.is-portal {
    .menu-item:first-child {
      display: none;
    }

    .submenu-list ::v-deep > .v-list-item::after {
      border-bottom: none;
    }
  }
}

@include untilTablet {
  .navigation-drawer {
    padding-top: 132px;

    &.mini {
      padding-top: 132px;
    }
  }
}
</style>

<style lang="scss">
.tooltip.popover.Inicio.open {
  top: -5px !important;
}

.tooltip.popover.expedientes.open.is-portal {
  top: -5px !important;
}

.menu-item.v-list-item {
  &.highlighted {
    .v-popover.open {
      background-color: $corporate-color;

      .trigger .v-icon {
        background-color: $corporate-color !important;
      }
    }
  }

  .v-popover.open {
    background-color: $blue-15;

    .trigger .v-icon {
      background-color: $blue-15 !important;
    }

    * {
      color: $white-01 !important;
    }
  }
}
</style>
