import { GetterTree } from 'vuex'
import { ListItemsState } from '@/store/modules/listItems/listItemsTypes'
import { RootState } from '@/store/types/storeGlobalTypes'
import { formatFieldsTypeDateEPOCH } from '@/helpers/dateTime'

export const getters: GetterTree<ListItemsState, RootState> = {
  getListItems(state) {
    return state.listItems
  },
  getListItemsWithFormattedDates(_state, getters) {
    const listItems = getters.getListItems
    const fieldsTypeDateEPOCH = listItems[0] ? Object.keys(listItems[0]).filter((key) => key.includes('EPOCH')) : null
    return fieldsTypeDateEPOCH ? formatFieldsTypeDateEPOCH(listItems) : listItems
  }
}
