import { Route } from 'vue-router'
import { URLS } from '@/router/routes/urlRoutes'
import store from '@/store/store'
import { ModuleNamespaces } from '@/store/types/storeGlobalTypes'

export const spinnerGuard = (to: Route, from: Route, next: any): void => {
  const routes: string[] = [
    URLS.AUDITS,
    URLS.DASHBOARD,
    URLS.EXPEDIENTS,
    URLS.REPORT,
    URLS.REPORTS,
    URLS.REPORTS_LIST,
    URLS.REPORT_DETAIL,
    URLS.SEARCH
  ]
  const parentRoutes: string[] = [URLS.ACTIONS, URLS.BILLING, URLS.CONTACTS, URLS.MAINTENANCE, URLS.EXPEDIENTS]
  const comesFromTheSameRoute = to.name === from.name
  const launchSpinnerLayer: boolean =
    (routes.includes(to.name || '') || parentRoutes.includes(to.meta?.parentUrl)) && !comesFromTheSameRoute

  if (launchSpinnerLayer) {
    store.dispatch(`${ModuleNamespaces.SPINNER}/showSpinnerLayer`)
  }
  next()
}


