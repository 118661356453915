<template lang="pug">

  ValidationProvider(
    ref="selectValidator"
    :name="$attrs.id"
    :rules="validationRules"
    v-slot="{ errors }"
    tag="div"
    :vid="$attrs.id"
  )
    div(v-show="hasRelatedData" :class="[{ 'form-layer-is-open': formLayerIsOpen },{ 'error-styles': errors[0] },'lf-select-container', { 'disabled-field': disabled }, {'show-all-chips': isMultiselect && renderChips && showAllChips}, {'show-chips': isMultiselect && renderChips && !showAllChips}]")
      span(:class="[{ 'required-field': requiredField }, 'label-text']") {{ $attrs.label }}
      v-select(
        ref="vSelect"
        :value="getSelectedItems"
        :items="filteredList"
        height="39"
        :multiple="isMultiselect ? true : false"
        :chips="renderChips ? true : false"
        :item-text="keyName"
        :item-value="keyValue"
        :key="componentKey"
        :append-icon="icons.arrowDown"
        :clear-icon="icons.clearIcon"
        :id="$attrs.id"
        :name="$attrs.name"
        :placeholder="$attrs.placeholder"
        v-on="$listeners"
        hide-details
        :return-object="returnObject"
        :disabled="disabled"
        dense
        :attach="attach"
        clearable
        @click:clear="clearSearch"
        :class="['lf-select', 'lf-multiselect-list', {'is-mobile': isMobile, 'error-styles': errors[0], 'is-source-table': sourceIsATable }]"
        :menu-props="{ offsetY: true }"
        @input="setSelectedItems"
      )

        template(v-slot:no-data)
          span

        template(v-if="renderChips" v-slot:selection="{ item, index }")
          v-chip(
              v-if="index <= 1 || showAllChips"
              close
              class="chip"
              @click:close="removeChipItem(item[keyId], item[keyName])"
            )
            span {{ item[keyName] }}
          span(
            v-if="index === 2 && !showAllChips"
            class="more-items-selected-number"
          ) (+{{ getSelectedItems.length - 2 }})

        template(v-if="isMultiselect && !sourceIsATable" v-slot:prepend-item)
          v-list-item(@click="toggleSelectAll" class="select-all")
            v-list-item-action
              v-icon(class="select-all-icon") {{ allSelected ? icons.close : icons.selectAllEmpty }}
            v-list-item-content
              v-list-item-title {{ $t('action_buttons.select_all') }}
          v-divider

        template(v-slot:item="{ item }" v-if="sourceIsATable && !loading")
          div(class="table-item-container")
            span(
              v-for="(header, itemIndex) in getTableHeaders"
              class="table-item"
              :data-title="item[header.name]"
              :key="itemIndex"
              :style="`width: ${header.width}%;`"
            ) {{ item[header.name] }}

        template(v-slot:item="{ item }" v-else-if="loading")
          span

        //- MODE COLOR SELECTOR
        template(v-slot:item="{ item }" v-else-if="isColorSelector" )
          div(class="color-item")
            div(
              :style="`backgroundColor: ${item.color};`"
              class="color-square"
            )
            span {{ item[keyName]}}
        //- END MODE COLOR SELECTOR

        template(v-slot:append-item v-if="showPaginator")
          SpinnerLayerComponent(class="spinner-layer" v-if="loading" :loadingText="$t('components.grid_table.updating')")
          PaginationComponent(
            class="pagination-component"
            :style="cssVars"
            :totalPages="numberOfPages"
            :currentPage="currentPage"
            @pageChanged="changedPage"
            :maxVisibleButtons="paginatorMaxVisibleButtons"
          )

        template(v-slot:prepend-item v-if="showSearch")
          div(class="sticky")
            div(class="search-field-and-buttons-container")
              div(class="search-field-container")
                input(class="search-field" v-model="search" :placeholder="$t('action_buttons.search')")
                div(
                  class="search-icon-container"
                  @click="clearSearch"
                )
                  span(:class="['search-icon', icons.searchIcon]")
              button(
                v-if="canSave"
                :class="['add-new-button', icons.addNew]"
                @click.stop="openForm"
                :title="$t('components.tooltip.add_new')"
              )
            //- Table headers
            div(
                v-if="sourceIsATable && !isMobile && hasItems"
                class="table-headers-container"
              )
              span(
                v-for="header of tableHeadersWithAlias"
                class="table-header"
                :style="`width: ${header.width}%;`"
              ) {{ $t(`components.lexon_select.table_headers.${header.title || header.name}`) }}

            p(class="no-results-message" v-if="showNoResultsText") {{ $t('components.no_results.no_search_results_text') }}


      div(class="messages-container")
        span(v-if="errors.length" class="alert-message") {{ errors[0] }}
        span(v-else class="help-message") {{ helpText }}

    v-dialog(
      v-if="formLayerIsOpen"
      v-model="formLayerIsOpen"
      transition="dialog-bottom-transition"
      persistent
      max-width="750px"
      height="450px"
      scrollable
      hide-overlay
    )
      v-card
        v-card-title(class="dialog-title")
          span {{ formTitle }}
          v-spacer
          span(
            @click.stop="closeForm"
            :class="[icons.closeIcon, 'close-icon']"
          )
        v-card-text
          FormGeneratorComponent(
            :schema="selectFormFields"
            :fieldValues="selectFormData"
            :context="context"
            formTag="selectForm"
          )
        v-card-actions
          v-spacer
          ActionsBarComponent(
              :buttons="buttons"
              @execute-action="executeAction"
              class="action-buttons"
            )
</template>

<script lang="ts">
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator'
import { Action, Getter, Mutation } from 'vuex-class'
import { Icons } from '@/icons/icons'
import PaginationComponent from '@/components/Pagination/PaginationComponent.vue'
import ActionsBarComponent from '@/components/ActionsBar/ActionsBarComponent.vue'
import { ActionName } from '@/components/ActionsBar/types/ActionBarComponentTypes'
import { ValidationProvider } from 'vee-validate'
import { MaintenanceService } from '@/services/MaintenanceService'
import { MainService } from '@/services/MainService'
import { vars } from '@/styles/styleVars'
import { ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import { ConfigurationTypes } from '@/store/modules/configuration/configurationTypes'
import FieldBehaviorsMixin from '@/mixins/FieldBehaviorsMixin.vue'
import { EndpointEntity } from '@/store/modules/endpoint/endpointTypes'
import SpinnerLayerComponent from '@/components/Spinner/SpinnerLayerComponent.vue'
import PermissionsMixin from '@/mixins/PermissionsMixin.vue'

const configurationModule: string = ModuleNamespaces.CONFIGURATION
const formsModule = ModuleNamespaces.FORMS
const alertsModule = ModuleNamespaces.ALERTS

@Component({
  components: {
    PaginationComponent,
    ActionsBarComponent,
    SpinnerLayerComponent,
    ValidationProvider,
    FormGeneratorComponent: () => import('@/components/forms/FormGenerator/FormGeneratorComponent.vue')
  }
})
export default class LexonSelectComponent extends Mixins(FieldBehaviorsMixin, PermissionsMixin) {
  @Prop() value!: any

  @Prop({
    type: [Array, Object]
  })
  propItems!: [] | {}

  @Prop({
    type: String
  })
  loadMethod!: string

  @Prop({
    type: String
  })
  saveMethod!: string

  @Prop({
    type: Boolean,
    default: false
  })
  isMultiselect!: boolean

  @Prop({
    type: Boolean,
    default: false
  })
  renderChips!: boolean

  @Prop({
    type: Boolean,
    default: false
  })
  showAllChips!: boolean

  @Prop({
    type: Boolean,
    default: true
  })
  paginated: boolean

  @Prop({
    type: Number,
    default: 5
  })
  paginatorMaxVisibleButtons!: number

  @Prop({
    type: Array
  })
  tableHeaders!: string[]

  @Prop({
    type: String
  })
  helpText!: string

  @Prop({
    type: Boolean,
    default: false
  })
  showAddNewButton!: boolean

  @Prop({
    type: String
  })
  endPoint!: string

  @Prop({
    type: Boolean,
    default: false
  })
  hasRelatedField!: boolean

  @Prop({
    type: String
  })
  itemRelatedField!: string

  @Prop({
    type: String
  })
  relatedField!: string

  @Prop({
    type: String,
    default: 'name'
  })
  keyName!: string

  @Prop({
    type: String,
    default: 'name'
  })
  keyValue!: string

  @Prop({
    type: String,
    default: 'id'
  })
  keyId!: string

  @Prop({
    type: Boolean,
    default: true
  })
  showSearch!: boolean

  @Prop({
    type: Boolean,
    default: true
  })
  returnObject!: boolean

  @Prop({
    type: String
  })
  formAlias!: string

  @Prop({
    type: String
  })
  filterField!: string

  @Prop({
    type: String
  })
  filteredValue!: string

  @Prop({
    type: Object
  })
  endPointEntity!: EndpointEntity

  @Prop({
    type: Array
  })
  filterKeys!: string[]

  @Prop({
    type: Boolean,
    default: true
  })
  attach!: boolean

  @Prop({
    type: Boolean,
    default: false
  })
  serverPagination!: boolean

  @Prop({
    type: Boolean,
    default: false
  })
  validateOnLoad!: boolean

  @Prop({
    type: Boolean,
    default: false
  })
  isColorSelector!: boolean

  @Prop({
    type: Number
  })
  permissionsEntity!: number

  @Action('fetchCurrentViewConfiguration', { namespace: configurationModule })
  fetchCurrentViewConfiguration: ({}) => {}

  @Action('showAlert', { namespace: alertsModule })
  showAlert: ({}) => {}

  @Mutation('RESET_RELATED_DATA', { namespace: formsModule })
  resetRelatedData: () => void

  @Getter('getRelatedData', { namespace: formsModule })
  getRelatedData: (relatedAlias: string) => ''

  @Getter('checkIfFormIsValid', { namespace: formsModule }) checkIfFormIsValid: (context: string) => boolean

  @Getter('getCurrentViewConfiguration', { namespace: configurationModule })
  getCurrentViewConfiguration: (context: string) => []

  selectFormFields: any = []

  selectFormData: object = {}

  items: any = []

  context = this.formAlias

  componentKey: number = 0

  icons = {
    addNew: Icons.ADD,
    arrowDown: Icons.ANGLE_DOWN,
    clearIcon: Icons.CLOSE_ROUND,
    clearSearchIcon: Icons.CLOSE_SEARCH,
    close: Icons.CLOSE_FULL,
    closeIcon: Icons.CLOSE,
    searchIcon: Icons.SEARCH,
    selectAllEmpty: Icons.BOX_INACTIVE
  }

  selectedItems: object[] = []
  selectedItem: any

  pageSize: number = 5
  totalItems: number = 0

  search = ''

  currentPage = 1

  foundItems: any = this.items
  filterItems: any = this.items

  texts = {
    closeButton: this.$t('action_buttons.close'),
    saveButton: this.$t('action_buttons.save'),
    saveSuccess: this.$t('components.alerts.register_save')
  }

  formLayerIsOpen: boolean = false

  isInitializingDataByEndpoint: boolean = true

  isLoading: boolean = false

  $refs!: {
    selectValidator: InstanceType<typeof ValidationProvider>
    vSelect: any
  }

  get loading() {
    return this.isLoading
  }

  set loading(loading) {
    this.isLoading = loading
  }

  get formTitle() {
    return this.$t('fields.' + this.$attrs.name + '.new')
  }

  get canSave() {
    if (this.permissionsEntity) {
      const { canSave } = this.checkEntityPermissionsGetter(this.permissionsEntity)
      return this.showAddNewButton && canSave
    }
    return this.showAddNewButton
  }

  get buttons() {
    return [
      {
        icon: Icons.CLOSE,
        tooltip: this.texts.closeButton,
        class: 'secondary-color',
        action: ActionName.CLOSE,
        mode: 'square',
        show: true
      },
      {
        icon: Icons.CHECK,
        tooltip: this.texts.saveButton,
        class: 'main-color',
        mode: 'square',
        action: ActionName.SAVE,
        show: this.checkIfFormIsValid(this.context)
      }
    ]
  }

  get getSelectedItems() {
    if (this.renderChips && this.value === null) {
      return this.selectedItems
    }

    if (this.isInitializingDataByEndpoint) {
      this.selectedItems = this.value
      if (this.value) {
        if ('object' !== typeof this.value) {
          if (!this.items) {
            return []
          }
          this.selectedItems = this.items.find((item: any) => item[this.keyId].toString() === this.value.toString())
        }
      }
    }

    return this.selectedItems
  }

  get hasItems() {
    if (this.tableHeadersFromAPI) {
      return typeof this.items !== 'undefined' && typeof this.items.items !== 'undefined' && this.items.items.length > 0
    }
    if (this.serverPagination) {
      return this.items && this.items.length > 0
    }
    return typeof this.items !== 'undefined' && this.items.length > 0
  }

  get hasRelatedData() {
    if (this.relatedField) {
      return typeof this.getRelatedData(this.relatedField) !== 'undefined'
    }
    return true
  }

  get relatedData(): string {
    if (this.relatedField) {
      return this.getRelatedData(this.relatedField)
    }
    return ''
  }

  get showPaginator() {
    if (this.serverPagination) {
      return this.totalItems > this.pageSize
    }
    return this.paginated && this.foundItems.length > this.paginatorMaxVisibleButtons
  }

  get allSelected() {
    return this.selectedItems.length === this.foundItems.length
  }

  get selectedSome() {
    return this.selectedItems.length > 0 && !this.allSelected
  }

  get numberOfItemsPerPage() {
    return this.$vuetify.breakpoint.xs ? 3 : 5
  }

  get sourceIsATable() {
    return this.tableHeaders || this.tableHeadersFromAPI ? true : false
  }

  get tableHeadersFromAPI() {
    return this.items && this.items.hasOwnProperty('tableHeaders')
  }

  get hasSelectItems(): boolean {
    if (!this.selectedItems) {
      return false
    }
    if (typeof this.selectedItems === 'object') {
      return Object.keys(this.selectedItems).length !== 0
    }

    if (Array.isArray(this.selectedItems)) {
      return (this.selectedItems as any[]).length > 0
    }

    return true
  }

  get filteredList() {
    if (!this.hasRelatedData || !this.items) {
      return []
    }

    if (this.serverPagination) {
      if (this.hasSelectItems) {
        return this.addItemGhost(this.items)
      }
      return this.items
    }

    const lowSearch = this.search
      .toLowerCase()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')

    if (this.tableHeadersFromAPI) {
      this.foundItems = this.items.items.filter((item: any) => {
        return Object.values(item).some((val) => {
          return this.compareSearch(lowSearch, String(val))
        })
      })
    } else if (this.tableHeaders) {
      ;(this as any).foundItems = this.filterItems.filter((item: any) => {
        return Object.values(item).some((val) => {
          return this.compareSearch(lowSearch, String(val))
        })
      })
    } else {
      if (this.filteredValue && this.filterField) {
        this.filterItems = this.items.filter((item: any) => (item as any)[this.filterField] === this.filteredValue)
      }
      ;(this as any).foundItems = this.filterItems.filter((item: any) => {
        if (this.filterKeys) {
          if ((this as any).filterKeys[0] !== this.keyName) {
            this.filterKeys.unshift(this.keyName)
          }
          const filterMatch = this.filterKeys.filter((itemKey: string) => {
            return item[itemKey] && this.compareSearch(lowSearch, item[itemKey])
          })
          if (filterMatch.length > 0) {
            return item
          }
        } else if (!this.paginated && this.selectedItems && this.selectedItems.length) {
          return this.compareSearch(lowSearch, item[this.keyName]) || this.selectedItems.includes(item[this.keyName])
        } else {
          return this.compareSearch(lowSearch, item[this.keyName])
        }
      })
    }

    return this.paginate(this.foundItems)
  }

  get numberOfPages(): number {
    const numberOfItems: number = this.serverPagination ? this.totalItems : (this as any).foundItems.length
    return Math.ceil(numberOfItems / this.numberOfItemsPerPage)
  }

  get isMobile() {
    return this.$vuetify.breakpoint.xs
  }

  get cssVars() {
    return {
      '--pagination-container-margin': 0,
      '--pagination-btn-active': vars.corporate_color,
      '--pagination-btn-active-border': vars.corporate_color,
      '--pagination-icon-color': vars.corporate_color,
      '--pagination-btn-not-active-border': vars.white_01,
      '--pagination-btn-color': vars.gray_01,
      '--pagination-btn-hover-color': vars.gray_01,
      '--pagination-icon-inactive-color': vars.disabled_color
    }
  }

  get getTableHeaders() {
    const headers = this.getTableHeadersType()
    return headers.map((item: any) => {
      let name = item.hasOwnProperty('name') ? item.name : item
      name = `${name.charAt(0).toLowerCase()}${name.slice(1)}`
      const width = item.hasOwnProperty('width') ? item.width : '100'
      const title = item.hasOwnProperty('title') ? item.title : ''
      return { name, width, title }
    })
  }

  get tableHeadersWithAlias() {
    return this.getTableHeaders.map((header: any) => {
      const name = header.name === 'name' ? this.$attrs.name : header.name
      return {
        name,
        title: header.title,
        width: header.width
      }
    })
  }

  get showNoResultsText() {
    return (
      !this.hasItems || !this.filteredList.length || (this.filteredList.length === 1 && this.filteredList[0].divider)
    )
  }

  @Watch('relatedData')
  async changeRelatedData(relatedValue: string) {
    if (relatedValue) {
      if (!this.isInitializingDataByEndpoint) {
        this.selectedItems = []
      }
      this.currentPage = 1
      if (this.endPoint) {
        await this.loadItems(relatedValue)
      }
      if (this.endPointEntity) {
        this.items = this.filterItems = await this.fetchItems()
      }
      if (this.isInitializingDataByEndpoint) {
        ;(this as any).$refs.selectValidator.validate()
      }
      this.isInitializingDataByEndpoint = false
    }
  }

  @Watch('search')
  changeSearchFieldIcon(value: string) {
    const searchIcon = this.$el.querySelector('.search-icon')
    if (searchIcon) {
      if (value.length) {
        searchIcon.classList.remove(this.icons.searchIcon)
        searchIcon.classList.add(this.icons.clearSearchIcon)
        this.changedPage(1)
      } else {
        searchIcon.classList.remove(this.icons.clearSearchIcon)
        searchIcon.classList.add(this.icons.searchIcon)
        this.changedPage(this.currentPage)
      }
    }
  }

  @Watch('foundItems')
  showOrHideTableHeaders(value: object[]) {
    const tableHeadersContainer = this.$el.querySelector('.table-headers-container') as HTMLElement

    if (tableHeadersContainer) {
      if (value.length) {
        tableHeadersContainer.style.display = 'flex'
      } else {
        tableHeadersContainer.style.display = 'none'
      }
    }
  }

  @Watch('propItems')
  updateItems(value: object[]) {
    this.items = this.filterItems = value
  }

  mounted() {
    this.initValidator(this.$refs.selectValidator)
    if (this.validateOnLoad) {
      this.$refs.selectValidator.validate()
    }
  }

  async created() {
    this.isInitializingDataByEndpoint = true
    if (this.loadMethod || (this.endPointEntity && !this.relatedField)) {
      this.items = this.filterItems = await this.fetchItems()
    } else {
      this.items = this.filterItems = this.propItems
    }
    if (this.showAddNewButton) {
      if (this.formAlias) {
        // Get FormSchema
        await this.fetchCurrentViewConfiguration({
          objectType: ConfigurationTypes.VIEW,
          alias: this.formAlias,
          context: this.context
        })
        this.selectFormFields = this.getCurrentViewConfiguration(this.formAlias)
      } else {
        this.context = this.$attrs.id
        this.selectFormFields = [
          {
            fieldType: 'LexonTextFieldComponent',
            id: 'selectForm-' + this.$attrs.id,
            label: this.$attrs.label,
            name: 'name',
            type: 'text',
            validationRules: 'required',
            'offset-lg': 0,
            xl: 12
          }
        ]
      }
    }
  }

  /**
   * @description Get the table headers from the API
   * @type Simply array
   * const test = ["Name", "email", "mobilePhone", "Phone"]
   * @type Multi Array
   * const test = [{"name":"Name","width":"40"},{"name":"Email","width":"20"},{"name":"MobilePhone","width":"10"}]
   * @type Multi Array with custom title
   * const test = [{"name":"Name", "title":"translationKey", "width":"40"},{"name":"Email","width":"20"}]
   * @type Multi Table
   * const test = [{"table":"default","columns":["Name","Email","MobilePhone","Phone"]},
   * {"table":"EXP","columns":[{"name":"Ref","width":20},{"name":"Description","width":40}}]
   * @returns Headers columns
   */
  getTableHeadersType() {
    if (this.tableHeaders[0].hasOwnProperty('table')) {
      let table: any = null
      const defaultTable: any = this.tableHeaders.find((item: any) => item.table === 'default')
      if (this.relatedData) {
        table = this.tableHeaders.find((item: any) => item.table === this.relatedData)
      }
      return !table ? defaultTable!.columns : table.columns
    }
    return this.tableHeaders
  }

  async getServerPagination() {
    const endpoint = this.relatedData
      ? this.endPointEntity.url.replace(/\{(.+?)\}/g, this.relatedData)
      : this.endPointEntity.url

    const params = {
      ...this.endPointEntity.params,
      page: this.currentPage,
      pageSize: this.pageSize,
      ...(this.search !== '' && { filter: this.search })
    }
    const { data } = await new MainService().postData(endpoint, params)
    return { data: data.data, pagination: data.pagination }
  }

  compareSearch(search: string, item: string) {
    if (item) {
      return item
        .toLocaleLowerCase()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .includes(search)
    }
  }

  async fetchItems() {
    try {
      if (!this.endPointEntity) {
        const { data } = await new MaintenanceService().getData(this.loadMethod)
        return data
      }

      this.endPointEntity = this.parseEndpointEntity(this.endPointEntity)
      if (this.endPointEntity.method === 'GET') {
        const { data } = await new MainService().getData(this.endPointEntity.url)
        return data
      }

      if (this.serverPagination) {
        this.loading = true
        const { data, pagination } = await this.getServerPagination()
        this.totalItems = Number(pagination.totalRecs)
        this.loading = false
        return data
      }

      const { data } = await new MainService().postData(this.endPointEntity.url, this.endPointEntity.params)
      this.$emit('emit-data-length', data.length)
      return data
    } catch (error) {}
  }

  async saveItems(json: any) {
    let saveEndPoint = ''
    if (this.relatedData) {
      saveEndPoint = this.saveMethod.replace(/\{(.+?)\}/g, (this as any).relatedData)
    } else {
      saveEndPoint = this.saveMethod
    }
    const { data } = await new MainService().postData(saveEndPoint, json)
    return data
  }

  async loadItems(fieldRefValue: string) {
    const endPoint = this.endPoint.replace(/\{(.+?)\}/g, fieldRefValue)
    const { data } = await new MaintenanceService().getData(endPoint)
    this.items = this.filterItems = data
  }

  paginate(items: string[]) {
    if (!this.paginated) {
      if (this.isMultiselect || !this.selectedItems || !this.selectedItems.length) {
        return items
      } else {
        return [...items, ...this.selectedItems]
      }
    }

    const paginatedItems = items.slice(
      (this.currentPage - 1) * this.numberOfItemsPerPage,
      this.currentPage * this.numberOfItemsPerPage
    )

    if (this.hasSelectItems) {
      return this.addItemGhost(paginatedItems)
    }

    return paginatedItems
  }

  addItemGhost(items: any) {
    let selectedItems: any[] = []
    const selectedItemsCloned = JSON.parse(JSON.stringify(this.selectedItems))

    if (!Array.isArray(this.selectedItems)) {
      selectedItems.push(selectedItemsCloned)
    } else {
      selectedItems = selectedItemsCloned
    }

    if (selectedItems.length) {
      selectedItems.forEach((item: any) => {
        if (item && 'object' === typeof item) {
          item.divider = true
        }
      })
      return [...items, ...selectedItems]
    }
  }

  toggleSelectAll() {
    if (this.allSelected) {
      this.selectedItems = []
    } else {
      this.selectedItems = this.foundItems
    }
  }

  removeChipItem(id: string, name: string) {
    const indexById = this.selectedItems.findIndex((item) => (item as any)[this.keyId] === id || (item as any) === id)
    const indexByName = this.selectedItems.findIndex(
      (item) => (item as any)[this.keyName] === name || (item as any) === name
    )
    const index = indexById !== -1 ? indexById : indexByName
    this.selectedItems.splice(index, 1)
    if (!this.paginated) {
      this.$emit('input', this.selectedItems)
    }
    this.calcTopMenu()
  }

  async calcTopMenu() {
    const menu: HTMLElement | null = this.$el.querySelector('.menuable__content__active')
    const input: HTMLElement | null = this.$el.querySelector('.v-select__slot')
    if (menu) {
      await this.$nextTick()
      menu.style.top = (input as any).offsetHeight + 'px'
    }
  }

  async clearSearch() {
    this.search = ''
    const searchInput = this.$el.querySelector('.search-field') as any
    if (searchInput) {
      searchInput!.value = ''
    }
    if (this.serverPagination) {
      this.items = this.filterItems = await this.fetchItems()
    }
    this.calcTopMenu()
  }

  async changedPage(page: number) {
    this.currentPage = page
    if (this.serverPagination) {
      this.items = this.filterItems = await this.fetchItems()
    }
  }

  destroyed() {
    this.resetRelatedData()
  }

  resetValue() {
    this.setSelectedItems(null)
    this.componentKey += 1
  }

  openForm() {
    document.body.style.overflow = 'hidden'
    document.body.style.position = 'fixed'
    this.formLayerIsOpen = true
  }

  async saveSelectFormData() {
    if (this.filterField && this.filteredValue) {
      ;(this as any).selectFormData[this.filterField] = this.filteredValue
    }

    const { id } = await this.saveItems(this.selectFormData)
    const newItem: any = this.selectFormData
    newItem.id = id

    this.items.push(newItem) // Añadimos el item al combo
    this.setSelectedItems(newItem)

    const input = this.returnObject ? newItem : newItem[this.keyValue]
    this.$emit('input', input) // Seleccionamos el nuevo item

    this.closeForm()
  }

  executeAction(actionName: string) {
    switch (actionName) {
      case ActionName.CLOSE:
        return this.closeForm()
      case ActionName.SAVE:
        return this.saveSelectFormData()
    }
  }

  closeForm() {
    document.body.style.overflow = 'visible'
    document.body.style.position = 'initial'
    this.formLayerIsOpen = false
    this.selectFormData = {}
  }

  setSelectedItems(items: any) {
    this.selectedItems = items
  }
}
</script>

<style lang="scss" scoped>
.spinner-layer-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding-top: 65px;
  background-color: $white-01;
  ::v-deep .spinner-lefebvre {
    width: $spinner-md;
    height: $spinner-md;
  }
}

.v-dialog__content {
  background-color: $dialog-background-color;
  pointer-events: unset;
}

.v-dialog {
  .v-card {
    @include borders;
    border-radius: 0;

    .dialog-title {
      font-family: $corporate-font;
      color: $corporate-color;
      font-size: 24px;

      .close-icon {
        font-size: 14px;
        cursor: pointer;
      }
    }
  }

  &.v-dialog--scrollable {
    .v-card__text {
      @include scroll-styles;
    }
  }
}

.lf-multiselect-list {
  @include lf-multiselect-list;
}

.lf-select-container {
  @include textfield-messages;
  width: 100%;

  ::v-deep .v-select__selections .v-select__selection.v-select__selection--comma.v-select__selection--disabled,
  ::v-deep .v-select__selections .v-select__selection.v-select__selection--comma {
    color: $gray-01;
  }

  &.error-styles {
    .label-text {
      color: $error-color;
    }

    ::v-deep .v-input {
      input::placeholder {
        color: $error-color !important;
      }

      .v-icon {
        color: $error-color;
      }

      &.v-select--is-menu-active {
        input::placeholder {
          color: $gray-02 !important;
        }
      }
    }

    ::v-deep .v-menu__content.menuable__content__active {
      input::placeholder {
        color: $gray-02 !important;
      }
    }
  }

  &.disabled-field {
    .label-text,
    .help-message,
    .alert-message {
      color: $disabled-color;
    }

    .label-text.required-field::after {
      color: $disabled-color;
    }

    ::v-deep .v-input {
      border-color: $gray-03 !important;
      background-color: $gray-04 !important;

      input::placeholder {
        color: $disabled-color !important;
      }
    }
  }

  &.form-layer-is-open {
    .v-menu__content {
      min-height: 391px;
    }

    .lf-new-item-form {
      ::v-deep .v-list-item.v-list-item--link {
        display: block;
      }
      ::v-deep .v-list.v-select-list {
        max-height: 215px;
      }
    }
    ::v-deep .v-select__slot {
      .v-icon {
        color: $blue-02;
        transform: rotate(1turn);
      }
    }
  }

  ::v-deep .v-simple-checkbox .v-icon,
  .select-all-icon {
    font-size: 18px !important;
    padding-top: 4px;
  }

  .label-text {
    @include label-text;

    &.required-field::after {
      position: inherit;
    }
  }

  ::v-deep .v-input {
    background-color: $white-01;
  }

  ::v-deep .v-text-field > .v-input__control > .v-input__slot:after,
  ::v-deep .v-text-field > .v-input__control > .v-input__slot:before {
    border: none;
  }

  ::v-deep
    .v-text-field.v-input--dense:not(.v-text-field--enclosed):not(.v-text-field--full-width)
    .v-input__append-inner
    .v-input__icon
    > .v-icon {
    margin-top: 6px;
  }
  ::v-deep
    .v-text-field.v-input--dense:not(.v-text-field--enclosed):not(.v-text-field--full-width)
    .v-input__append-inner
    .v-input__icon
    > .v-icon.lf-icon-angle-down {
    margin-top: 9px;
  }

  ::v-deep .v-list-item__title {
    display: flex;
    align-items: center;
    height: 30px;
  }

  .lf-select {
    @include textfield-styles;
    padding: 0;
    margin-top: 0;

    &.is-mobile {
      .table-item-container {
        flex-direction: column;
        padding: 4px 0;

        .table-item {
          width: 100%;
          font-size: 11px;
          line-height: inherit;
          overflow: auto;
          padding: 0;
          border: none;
        }
      }

      ::v-deep .v-menu__content.menuable__content__active .v-list-item {
        height: 60px;
        max-height: 60px;

        &.v-list-item--active {
          background-color: $blue-07;

          .table-item:not(:first-of-type) {
            color: $blue-04;
          }
        }

        .table-item:first-of-type {
          font-weight: bold;
          font-size: 12px;
        }
      }
    }

    &.is-source-table {
      ::v-deep .v-menu__content.menuable__content__active .v-list-item {
        min-height: 34px;
      }
    }

    &.v-input--is-focused {
      border: none;

      ::v-deep .v-select__slot {
        @include borders;
        background-color: $blue-07;

        .v-input__icon--clear {
          display: block;
        }
      }
    }

    ::v-deep .v-menu__content {
      box-shadow: none;
      border-radius: 0;
      overflow-y: hidden !important;
      max-height: 750px !important;
    }

    ::v-deep .v-select-list {
      @include scroll-styles;
      max-height: 370px;
      overflow-y: auto;
      position: relative;
    }

    ::v-deep .v-select__slot {
      height: 40px;
      padding: 0 8px 0 10px;

      .color-item {
        @include flex;

        .color-square {
          height: 18px;
          width: 18px;
          margin-right: 11px;
          border-radius: 4px;
        }
      }

      .v-input__icon--clear {
        display: none;
        padding-top: 1px;
      }

      &:hover {
        .v-input__icon--clear {
          display: block;
        }
      }

      .v-input__append-inner {
        cursor: pointer;
        padding-top: 3px;
        padding-left: 0;
      }

      .search-field {
        min-height: 40px;
        color: $gray-01;

        &::placeholder {
          @include placeholder;
        }
      }

      .v-icon {
        font-size: 20px;
        padding-bottom: 0;
      }
    }

    .pagination-component {
      padding: 0 8px 12px 8px;
    }
  }

  &.show-all-chips {
    .lf-select,
    ::v-deep .v-input__slot,
    ::v-deep .v-select__slot {
      height: auto !important;
    }
    ::v-deep .v-select__slot {
      padding: 2px 10px;
    }
    ::v-deep .v-chip {
      margin: 4px;
    }

    .v-chip__content span {
      max-width: 90%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  &.show-chips {
    ::v-deep .v-chip {
      max-width: 41%;

      .v-chip__content span {
        max-width: 90%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }

  ::v-deep .v-list-item:last-of-type {
    margin-bottom: 4px;
  }

  ::v-deep .v-menu__content.menuable__content__active .v-list-item {
    min-height: 42px;

    &:before {
      opacity: 0;
    }

    &.v-list-item--active {
      background-color: $blue-07;

      &:hover {
        background-color: $blue-07;
      }

      .table-item {
        color: $corporate-color;
      }
    }

    &:hover {
      background-color: $gray-04;
    }

    .color-item {
      @include flex;
      color: $gray-01;

      .color-square {
        height: 18px;
        width: 18px;
        margin-right: 11px;
        border-radius: 4px;
      }
    }
  }

  .table-item-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;

    .table-item {
      @include border($direction: right, $color: $gray-03);
      flex: auto;
      width: 33.3333%;
      font-family: $secondary-font;
      font-size: 12px;
      color: $gray-01;
      line-height: 30px;
      padding: 0 10px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      &:first-of-type {
        padding-left: 0;
      }

      &:last-of-type {
        border-right: none;
      }
    }

    [data-title]:hover::before {
      content: attr(data-title);
      position: absolute;
      bottom: 4px;
      background: $gray-04;
      color: $gray-07;
      white-space: nowrap;
    }
  }
}

::v-deep .v-list-item--disabled {
  .table-item {
    color: $gray-02 !important;
  }
}

::v-deep .v-list {
  .v-divider {
    display: none;
  }
}
</style>

<style lang="scss">
.v-select {
  input {
    padding-bottom: 3px !important;

    &::placeholder {
      color: $gray-02 !important;
      font-size: 12px;
      font-family: $secondary-font;
      padding: 5px;
    }
  }

  input.search-field::placeholder {
    color: $gray-02 !important;
  }

  &.v-input--is-focused input:not(.search-field)::placeholder {
    color: $blue-04 !important;
  }
}

.lf-select-container {
  .lf-select {
    &.is-mobile {
      .search-field-and-buttons-container {
        margin-bottom: 8px;
      }
    }

    .sticky {
      position: sticky;
      background-color: $white-01;
      top: 0;
      padding-top: 1px;
      z-index: 99;

      .search-field-and-buttons-container {
        @include flex;
        flex-wrap: nowrap;
        width: 100%;
        padding: 0 8px 8px;
        margin: 10px 0 0 0;

        .search-field-container {
          @include borders;
          @include flex;
          height: 42px;
          padding: 0 8px;
          flex-grow: 1;

          &:focus-within,
          &:hover {
            background-color: $blue-07;

            .search-field::placeholder {
              color: $blue-04;
            }
          }

          .search-field {
            padding-right: 8px;
            color: $corporate-color;
            pointer-events: all;

            &::placeholder {
              font-size: 12px;
              font-family: $secondary-font;
              color: $gray-02;
            }
          }

          .search-icon-container {
            @include flex;
            @include border($direction: left);
            height: 32px;
            font-size: 20px;
            padding: 2px 0 2px 8px;
            cursor: pointer;
          }
        }

        .add-new-button {
          @include interactive-round-button;
          margin: 0 2px 0 6px;
        }
      }
    }

    .table-headers-container {
      display: flex;
      width: 100%;
      height: 20px;
      padding: 0 10px;
      margin-top: 10px;

      .table-header {
        @include border($direction: right, $color: $gray-03);
        flex: auto;
        width: 33.3333%;
        color: $corporate-color;
        font-size: 11px;
        font-family: $secondary-font;
        font-weight: bold;
        padding: 0 10px;
        text-transform: capitalize;

        &:last-of-type {
          border-right: none;
        }

        &:first-of-type {
          padding-left: 0;
        }
      }
    }
  }
}
</style>
