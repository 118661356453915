<template lang="pug">
  div
    GridTableComponent(
      v-if="renderGrid && !showSpinnerLayer"
      :itemsData="isPortalUser ? listItems : billingData"
      :gridConfiguration="listConfig"
      :listName="listName"
      :title="$t('views.billing_customer_invoices.title')"
      :toolbarOptions="customToolbarOptions"
      :contextMenuItems="isPortalUser ? [printButton] : invoiceContextMenuItems"
      :context="context"
      :totals="totals"
      :archivedFilter="archivedSelectedOption"
      :disabledGridCellClicked="getDisableGridProperty"
      :activeOnClickCell="selectedOnlyOneInvoice"
      :checkboxSelectColumn="hasCompanyTemplateInvoice"
      :frozenColumns="isPortalUser ? 1 : 3"
      @contextMenuClicked="onContextMenuClicked"
      @contextMenuBeforeOpen="onContextMenuBeforeOpen"
      @gridTableFilter="onGridTableFilter"
      @toolbarClicked="onToolbarClicked"
      @rectifyCustomerInvoiceEvent="rectifyCustomerInvoice"
      @selectedRecords="onSelectedRecords"
      ref="grid"
    )

    ejs-contextmenu(
      :id="idContextMenu"
      class="context-menu-component e-contextmenu-archived"
      :items="invoicesArchivedContextMenuItemsParsed"
      :select="onInvoicesArchivedClick"
    )

    PrintOrSaveInvoicesDialog(
      :showDialog="showTemplateDialog"
      :idEntityType="idEntityType"
      @closePrintOrSaveInvoiceDialog="closeTemplateDialog"
      @generateTemplateDialog="generateTemplateDialog"
    )

    ejs-contextmenu(
      id="invoicesActionsContextMenuComponent"
      class="context-menu-component"
      :items="invoicesExportContextMenuItems"
      :select="onInvoicesExportContextMenuAddClick"
    )

</template>

<script lang="ts">
import { mixins } from 'vue-class-component'
import { Component } from 'vue-property-decorator'
import GridTableComponent from '@/components/grids/GridTable/GridTableComponent.vue'
import { ContextName, ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import ListViewMixin from '@/mixins/ListViewMixin.vue'
import ArchivedFilterMixin from '@/mixins/ArchivedFilterMixin.vue'
import PortalUserMixin from '@/mixins/PortalUserMixin.vue'
import { Action, Getter, Mutation } from 'vuex-class'
import { ListNames } from '@/store/modules/configuration/configurationTypes'
import { BillingTypes, TotalItem } from '@/store/modules/billing/billingTypes'
import PrintInvoiceContextMenuMixin from '@/mixins/PrintInvoiceContextMenuMixin.vue'
import { entity } from '@/store/modules/entities/entitiesTypes'
import PrintOrSaveInvoicesDialog from '@/components/billing/PrintOrSaveInvoicesDialog/PrintOrSaveInvoicesDialog.vue'
import { Icons } from '@/icons/icons'
import { URLS } from '@/router/routes/urlRoutes'
import { UserType } from '@/store/modules/auth/authTypes'
import { ComponentWhereIsRendered } from '@/store/modules/alerts/alertsTypes'
import { InvoiceEntityId } from '@/general/entityIds'
import { MenuItem, MenuItemModel } from '@syncfusion/ej2-vue-navigations'
import { DialogTypes } from '@/store/modules/dialog/dialogTypes'
import { InvoiceGenerated } from '@/store/modules/invoices/invoicesTypes'
import { MAX_SELECTED_INVOICE_PRINT } from '@/components/billing/PrintOrSaveInvoicesDialog/types/PrintOrSaveInvoicesDialogTypes'
import { InvoiceStatus } from '@/store/modules/invoices/invoicesTypes'
import { addItemInArrayByIndex, removeItemByPropValue } from '@/helpers/array'
import InvoicesToolBarMixin from '@/mixins/InvoicesToolBarMixin.vue'
import { TrackerEvents, trackEvent } from '@/plugins/tracker'

const authModule = ModuleNamespaces.AUTH
const billingModule: string = ModuleNamespaces.BILLING
const entitiesModule: string = ModuleNamespaces.ENTITIES
const invoicesModule = ModuleNamespaces.INVOICES

@Component({
  components: {
    GridTableComponent,
    PrintOrSaveInvoicesDialog
  }
})
export default class CustomerInvoicesView extends mixins(
  ListViewMixin,
  ArchivedFilterMixin,
  PrintInvoiceContextMenuMixin,
  PortalUserMixin,
  InvoicesToolBarMixin
) {
  @Action('fetchBillingData', { namespace: billingModule })
  fetchBillingData: (contactType: {}) => []

  @Action('fetchTotals', { namespace: billingModule })
  fetchTotals: (source: string) => []

  @Action('saveSelectedIdEntityType', { namespace: entitiesModule })
  saveSelectedIdEntityType: (type: number) => void

  @Action('rectifyCustomerInvoice', { namespace: invoicesModule })
  rectifyCustomerInvoiceAction: ({}) => Promise<string>

  @Action('fetchInvoices', { namespace: invoicesModule })
  fetchPortalInvoices: (filter: {}) => []

  @Getter('getTotals', { namespace: billingModule })
  totals: TotalItem[]

  @Getter('getCompanyTemplateInvoices', { namespace: authModule })
  hasCompanyTemplateInvoice: boolean

  @Mutation('ADD_GENERATE_INVOICE', { namespace: invoicesModule })
  addGenerateInvoice: (selectedInvoices: InvoiceGenerated[]) => void

  idContextMenu: string = 'invoicesArchivedContextMenuComponent'

  idEntity: number | string = 0
  idEntityType: number = entity.invoices.customers.type
  gridFilters: any = {}

  selectedInvoices = []

  contextMenuItemsText = {
    openNewWindow: this.$t('components.context_menu.open_window'),
    print: this.$t('action_buttons.print'),
    printOrSave: this.$t('action_buttons.print_or_save'),
    rectifyCustomerInvoice: this.$t('components.context_menu.rectify_customer_invoice')
  }

  get listConfig() {
    const config = JSON.parse((this as any).listConfiguration['Config'])
    const index = config.columns.findIndex(({ editType }: any) => editType === 'booleanedit')
    if (index && index !== -1) {
      config.columns[index].visible = this.hasCompanyTemplateInvoice
    }
    return JSON.stringify(config)
  }

  get listName() {
    return (this as any).listConfiguration['Alias']
  }

  get selectedOnlyOneInvoice() {
    return this.selectedInvoices.length <= 1
  }

  get context() {
    return ContextName.BILLING
  }

  get invoiceContextMenuItems() {
    const items = this.contextMenuItemsDefault
    const getPrintContextMenuItems = this.getPrintContextMenuItems(items)
    const { canSave } = this.checkEntityPermissionsGetter(InvoiceEntityId.CUSTOMER_INVOICES)

    if (canSave) {
      getPrintContextMenuItems.splice(3, 0, {
        text: this.contextMenuItemsText.rectifyCustomerInvoice,
        iconCss: Icons.RECTIFY_CUSTOMER_INVOICE,
        action: this.showDialog
      })
    }

    // Cambia el literal del menu por defecto de IMPRIMIR a IMPRIMIR/GUARDAR
    this.changePrintContextMenuLiteral(getPrintContextMenuItems)

    return getPrintContextMenuItems
  }

  get filters() {
    const filters = {
      ...this.gridFilters,
      archived: this.archivedSelectedOption
    }

    return this.isPortalUser ? { ...filters, ...this.portalFilters } : filters
  }

  get customToolbarOptions() {
    const newToolbarOption = {
      id: 'downloadExcel',
      prefixIcon: Icons.DOWNLOAD,
      tooltipText: this.$t('components.grid_table.tooltip.export'),
      align: 'right'
    }

    const toolbarOptions = addItemInArrayByIndex(this.toolbarOptions, newToolbarOption, 4)
    return removeItemByPropValue(toolbarOptions, 'id', 'export')
  }

  async created() {
    await this.fetchCurrentListConfiguration(ListNames.CUSTOMER_INVOICES)
    this.initializeArchivedSelectedOption({ selectedOption: 1, contextMenu: this.idContextMenu })
    await this.fetchGridItems()
    this.saveSelectedEntityName(BillingTypes.CUSTOMER_INVOICES)
    this.renderGrid = true
    this.hideSpinnerLayerAction()
  }

  async fetchGridItems() {
    if (this.isPortalUser) {
      await this.fetchPortalInvoices({
        archived: this.invoicesDefaulArchivedOption,
        userType: UserType.PORTAL,
        selectedRegisterId: this.portalCustomerId,
        listName: ListNames.LIST_CUSTOMER_INVOICES
      })
    } else {
      await this.fetchBillingData({
        billingType: ListNames.CUSTOMER_INVOICES,
        filter: {
          archived: this.archivedSelectedOption
        }
      })
    }
  }

  onContextMenuClicked(args: any, selectedRegister: any) {
    if (
      args.item.text === this.contextMenuItemsText.printOrSave ||
      (this.isPortalUser && args.item.text === this.contextMenuItemsText.openNewWindow)
    ) {
      if (this.selectedInvoices.length > MAX_SELECTED_INVOICE_PRINT) {
        this.showDialog({
          type: DialogTypes.WARNING,
          hideSecondaryButton: true,
          message: this.$t('components.dialog.exceeded_number_of_selected_invoices', {
            selectedInvoicesLength: this.selectedInvoices.length,
            maxInvoiceSelected: MAX_SELECTED_INVOICE_PRINT
          })
        })
        args.cancel = true
        return
      }
      if (this.hasCompanyTemplateInvoice) {
        this.idEntity = selectedRegister.id
        this.showTemplateDialog = true
        const selectedInvoices: InvoiceGenerated[] = this.selectedInvoices.map(({ idInvoice, numberInvoice }) => {
          return {
            idEntity: idInvoice,
            idEntityType: this.idEntityType,
            numberInvoice,
            status: InvoiceStatus.LOADING
          }
        })
        this.addGenerateInvoice(selectedInvoices)
      } else {
        // Estos no pueden imprimir más de una factura
        this.printInvoiceOld(selectedRegister.id, entity.invoices.customers.alias)
      }
    }
  }

  generateTemplateDialog(templateSelected: any) {
    this.printInvoice(templateSelected, this.idEntity, this.idEntityType, ComponentWhereIsRendered.GRID_TABLE)
  }

  onGridTableFilter(filters: any) {
    this.gridFilters = filters
    this.fetchTotals({
      source: ListNames.CUSTOMER_INVOICES,
      ...this.filters
    })
  }

  onToolbarClicked(args: any) {
    trackEvent(TrackerEvents.CREATE_INVOICE)
    const target = args.originalEvent.target.closest('button')
    if (target && target.id === 'archived') {
      this.toggleArchivedContextMenu(args.originalEvent, this.idContextMenu)
      args.cancel = true
    }
    this.onInvoiceExportToolbarClicked(args)
  }

  async onInvoicesArchivedClick(args: any) {
    this.archivedSelectedOption = args.item.actionType
    this.changeContextMenuIcons(args.item.actionType, this.idContextMenu)
    await this.fetchGridItems()
    await this.fetchTotals({ source: ListNames.CUSTOMER_INVOICES, ...this.filters })
  }

  async rectifyCustomerInvoice(selectedInvoiceId: number) {
    const returnedId = await this.rectifyCustomerInvoiceAction(selectedInvoiceId)
    await this.fetchGridItems()

    const routeData = this.$router.resolve({
      name: `${URLS.BILLING}-${URLS.CUSTOMER_INVOICES}`,
      params: { selectedRegisterId: returnedId }
    })

    window.open(routeData.href, '_blank')
  }

  changePrintContextMenuLiteral(contextMenuItems: MenuItem[]) {
    contextMenuItems.forEach((option: MenuItem) => {
      if (option.text === this.contextMenuItemsText.print) {
        option.text = this.contextMenuItemsText.printOrSave as string
      }
    })
  }

  onSelectedRecords(selectedItems: any) {
    this.selectedInvoices = Array.isArray(selectedItems) ? selectedItems : ([] as any)
  }

  onContextMenuBeforeOpen(contextMenuModule: any, contextMenuGridObject: any, _selectedRegister: any) {
    contextMenuGridObject.items.forEach((item: MenuItemModel) => {
      const enable = !(item.text !== this.contextMenuItemsText.printOrSave && !this.selectedOnlyOneInvoice)
      contextMenuModule.enableItems([item.text], enable)
      contextMenuGridObject.enableItems([item.text], enable)
    })
  }

  closeTemplateDialog() {
    this.showTemplateDialog = false
  }
}
</script>
