import { Workbook } from '@syncfusion/ej2-excel-export'
import { arrayIsListObjects } from './array'
import { Worksheet, Rows, Row, Cells, Cell } from '@syncfusion/ej2-excel-export'

const XLSX = 'xlsx'
const FILE_NAME = 'data'
const SHEET_NAME = 'sheet_1'

export const downloadExcelByArray = (
  data: any[],
  fileName?: string,
  sheetName?: string,
  boldHeader?: boolean
): Boolean => {
  fileName = fileName || FILE_NAME
  sheetName = sheetName || SHEET_NAME
  boldHeader = typeof boldHeader === 'undefined' ? true : boldHeader

  if (!arrayIsListObjects(data)) {
    return false
  }

  // TODO: multiple sheets
  const worksheets = { worksheets: [getWorksheetByArray(data, sheetName, boldHeader)] }
  const workbook = new Workbook(worksheets, XLSX)

  workbook.save(`${fileName}.${XLSX}`)

  return true
}

const getWorksheetByArray = (data: any[], name?: string, boldHeader?: boolean): Worksheet => {
  const worksheet: Worksheet = new Worksheet()
  const rows: Rows = new Rows()
  const header: Row | Boolean = getHeaderRow(data, boldHeader)

  let baseIndex = 1

  if (header instanceof Row) {
    rows.add(header)
    baseIndex++
  }

  data.forEach((item: any[]) => {
    const row: Row = getRow(item, baseIndex)

    if (row && row instanceof Row) {
      rows.add(row)
      baseIndex++
    }
  })

  worksheet.rows = rows
  if (name) {
    worksheet.name = name
  }

  return worksheet
}

const getHeaderRow = (data: any[], boldHeader?: boolean): Row | Boolean => {
  const header: Row = new Row()
  const keys: any = Object.keys(data[0]) || []

  if (keys) {
    header.cells = new Cells()

    keys.forEach((item: any, index: number) => {
      header.index = 1
      header.cells.add({ index: index + 1, value: boldHeader ? `<b>${item}</b>` : item } as Cell)
    })

    return header
  }

  return false
}

const getRow = (item: any[], index: number): Row => {
  const row: Row = new Row()

  row.index = index
  row.cells = new Cells()

  Object.entries(item).forEach((item, index: number) => {
    row.cells.add({ index: index + 1, value: item[1] } as Cell)
  })

  return row
}
