import { ActionTree } from 'vuex'
import { MaintenanceState, maintenanceMutationTypes } from '@/store/modules/maintenance/maintenanceTypes'
import { RootState, ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import { MaintenanceService } from '@/services/MaintenanceService'
import { MainService } from '@/services/MainService'
import { listItemsMutationTypes } from '@/store/modules//listItems/listItemsTypes'
import { DialogTypes } from '../dialog/dialogTypes'
import { i18n } from '@/plugins/vue-i18n'
import { ErrorCodes } from '@/store/modules/errors/errorsTypes'
import { AlertsTypes, ComponentWhereIsRendered } from '@/store/modules/alerts/alertsTypes'

const alertsModule: string = ModuleNamespaces.ALERTS
const dialogModule: string = ModuleNamespaces.DIALOG

export const actions: ActionTree<MaintenanceState, RootState> = {
  async fetchListMaintenance({ commit }) {
    try {
      const url = 'maintenance/listMaintenance'
      const { data } = await new MaintenanceService().getData(url)
      commit(maintenanceMutationTypes.FETCH_MAINTENANCE, data)
    } catch (error) {}
  },
  async fetchMaintenance({ commit }, { selectedRegisterId, listName }) {
    try {
      const url = `maintenance/${listName}/${selectedRegisterId}/get`
      const { data } = await new MaintenanceService().getData(url)
      commit(`${ModuleNamespaces.LIST_ITEMS}/${listItemsMutationTypes.SET_LIST_ITEMS}`, data, { root: true })
    } catch (error) {}
  },
  async fetchDashboardMaintenance({ commit }) {
    try {
      const url = 'maintenance/dashboard'
      const { data } = await new MaintenanceService().getData(url)
      commit(maintenanceMutationTypes.FETCH_DASHBOARD_MAINTENANCE, data)
    } catch (error) {}
  },
  async fetchTotalNumberOfMaintenance({ commit }) {
    try {
      const url = '/maintenance/total/dashboard'
      const { data } = await new MaintenanceService().getData(url)
      commit(maintenanceMutationTypes.FETCH_TOTAL_NUMBER_OF_MAINTENANCE, data[0]['Total'])
    } catch (error) {}
  },
  async saveMaintenanceStage({ commit }, saveData) {
    try {
      const url = 'maintenance/stage/save'
      const { data } = await new MainService().postData(url, saveData)
      commit(maintenanceMutationTypes.LAST_MAINTENANCE_SAVED, data['id'])
    } catch (error) {}
  },
  async setMaintenanceStageState({ commit }, data) {
    try {
      commit(maintenanceMutationTypes.FETCH_MAINTENANCE_STAGE_STATE, data)
    } catch (error) {}
  },
  async fetchMaintenanceActions({ commit }, { selectedRegisterId, filter }) {
    try {
      const url = `maintenance/${selectedRegisterId}/actions/get`
      const { data } = await new MainService().postData(url, filter)
      commit(`${ModuleNamespaces.LIST_ITEMS}/${listItemsMutationTypes.SET_LIST_ITEMS}`, data, { root: true })
    } catch (error) {}
  },
  async removeEconomicData({ dispatch }, { id, maintenanceNumber }) {
    try {
      const url = `maintenance/economic/${id}/delete`
      await new MainService().deleteData(url)
      await dispatch(
        `${dialogModule}/showDialog`,
        {
          type: DialogTypes.SUCCESS,
          message: i18n.t('components.dialog.success_economic_data_removed', { maintenanceNumber })
        },
        { root: true }
      )
    } catch (error) {
      if (error.response.data.exceptionCode === ErrorCodes.E400) {
        const errorMessage = error.response.data.exceptionDesc
        dispatch(
          `${dialogModule}/showDialog`,
          {
            type: DialogTypes.ERROR,
            message: errorMessage
          },
          { root: true }
        )
      }
    }
  },
  async saveEconomicData({}, economicData) {
    try {
      const url = 'maintenance/economic/save'
      await new MainService().postData(url, economicData)
    } catch (error) {}
  },
  async fetchMaintenanceType({ commit }, { maintenanceType }) {
    try {
      const url = `maintenance/${maintenanceType}/get`
      const { data } = await new MainService().getData(url)
      commit(`${ModuleNamespaces.LIST_ITEMS}/${listItemsMutationTypes.SET_LIST_ITEMS}`, data, { root: true })
    } catch (error) {}
  },
  async removeMaintenanceType({ commit }, { maintenanceType, id }) {
    try {
      const url = `maintenance/${maintenanceType}/${id}`
      const { data } = await new MainService().deleteData(url)
      commit(`${ModuleNamespaces.LIST_ITEMS}/${listItemsMutationTypes.SET_LIST_ITEMS}`, data, { root: true })
    } catch (error) {}
  },
  async saveImportedActions({}, { idMaintenance, filter = null }) {
    try {
      const url = `maintenance/${idMaintenance}/economic/import/actions/save`
      await new MainService().postData(url, filter)
    } catch (error) {}
  },
  async fetchEconomicDataTaxesItems({ commit }, id) {
    try {
      const url = `/maintenance/economic/${id}/taxes/get`
      const { data } = await new MainService().getData(url)
      commit(maintenanceMutationTypes.SET_TAXES_ITEMS, data)
    } catch (error) {}
  },
  async fetchMaintenanceInsurers({ commit }, { selectedRegisterId, filter }) {
    try {
      const url = `maintenance/${selectedRegisterId}/insurances/get`
      const { data } = await new MainService().postData(url, filter)
      commit(`${ModuleNamespaces.LIST_ITEMS}/${listItemsMutationTypes.SET_LIST_ITEMS}`, data, { root: true })
    } catch (error) {}
  },
  async removeMaintenanceInsurer({ dispatch }, idMaintenanceInsurance) {
    try {
      const url = `maintenance/insurance/${idMaintenanceInsurance}/delete`
      await new MainService().deleteData(url)
      await dispatch(
        `${dialogModule}/showDialog`,
        {
          type: DialogTypes.SUCCESS,
          message: i18n.t('components.dialog.success_remove_maintenance_insurer', { idMaintenanceInsurance })
        },
        { root: true }
      )
    } catch (error) {
      if (error.response.data.exceptionCode === ErrorCodes.E400) {
        const errorMessage = error.response.data.exceptionDesc
        dispatch(
          `${dialogModule}/showDialog`,
          {
            type: DialogTypes.ERROR,
            message: errorMessage
          },
          { root: true }
        )
      }
    }
  },
  async fetchSelectedMaintenanceInsurer({ commit }, { idMaintenanceInsurance, filter }) {
    try {
      const url = `maintenance/insurance/${idMaintenanceInsurance}/get`
      const { data } = await new MainService().postData(url, filter)
      commit(maintenanceMutationTypes.SET_SELECTED_MAINTENANCE_INSURER, data[0])
    } catch (error) {}
  },
  async saveMaintenanceInsurer({}, filter) {
    try {
      const url = 'maintenance/insurance/save'
      await new MainService().postData(url, filter)
    } catch (error) {}
  },
  async fetchEmailTemplate({}, emailTemplateId) {
    try {
      const filter = { id: emailTemplateId }
      const url = 'maintenance/manCustomerPortal/post'
      const { data } = await new MainService().postData(url, filter)
      return data[0]
    } catch (error) {}
  },
  async saveEmailTemplate({ dispatch }, emailTemplate) {
    try {
      const url = 'maintenance/manCustomerPortal/save/post'
      await new MainService().postData(url, emailTemplate, false)
      dispatch(
        `${alertsModule}/showAlert`,
        {
          type: AlertsTypes.SUCCESS,
          message: i18n.t('components.alerts.register_save'),
          componentWhereIsRendered: ComponentWhereIsRendered.TABS_VIEW
        },
        { root: true }
      )
    } catch (error) {}
  },
  async saveMaintenanceCustomerPortalFormData({}, data) {
    try {
      const url = 'maintenance/manCustomerDatosPortal/save/post'
      await new MainService().postData(url, data)
    } catch (error) {}
  }
}
