import { Route } from 'vue-router'
import { AuthData } from '@/store/modules/auth/authTypes'
import store from '@/store/store'
import { ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import { AuthService } from '@/services/AuthService'
import { authenticate, loadInitConfig, reloadApp } from '@/router/services/InitializerService'

export const partnerGuard = async (to: Route, _from: Route, next: any): Promise<void> => {
  const partnerToken: string = to.query.partnerToken as string
  if (!partnerToken || partnerToken.trim() === '') {
    next()
    return
  }
  await store.dispatch(`${ ModuleNamespaces.SPINNER }/showSpinner`)
  try {
    const authData: AuthData = await new AuthService().partnerTokenExchange(partnerToken)
    await authenticate(authData)
    await loadInitConfig()
    reloadApp(to.path)
  } catch (error) {
    await store.dispatch(`${ ModuleNamespaces.SPINNER }/hideSpinner`)
    next()
  }
}


