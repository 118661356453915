import { ActionTree } from 'vuex'
import { UsersState, usersMutationTypes } from '@/store/modules/users/usersTypes'
import { ModuleNamespaces, RootState } from '@/store/types/storeGlobalTypes'
import { UsersService } from '@/services/UsersService'
import { MainService } from '@/services/MainService'
import { AlertsTypes, ComponentWhereIsRendered } from '@/store/modules/alerts/alertsTypes'
import { DialogTypes } from '@/store/modules/dialog/dialogTypes'
import { i18n } from '@/plugins/vue-i18n'

const alertsModule: string = ModuleNamespaces.ALERTS

export const actions: ActionTree<UsersState, RootState> = {
  async fetchListUsers({ commit }) {
    try {
      const url = 'user'
      const { data } = await new UsersService().getData(url)
      commit(usersMutationTypes.FETCH_USERS, data)
    } catch (error) {}
  },
  async fetchPermissions({ commit }, id: any) {
    try {
      const url = `/user/permissions/${id}`
      const { data } = await new UsersService().getData(url)
      commit(usersMutationTypes.FETCH_PERMISSIONS, data)
    } catch (error) {}
  },
  async fetchResponsibles() {
    try {
      const endpoint = 'general/data/responsible'
      const { data } = await new MainService().getData(endpoint)
      return data
    } catch (error) {}
  },
  async fetchFilteredResponsibles(_context, params) {
    try {
      const endpoint = 'general/data/responsible'
      const { data } = await new MainService().postData(endpoint, params)
      return data
    } catch (error) {}
  },
  async saveMaintenanceUser(_context, userData: any) {
    const url = `/user`
    await new MainService().postData(url, userData)
  },
  async savePermissions({ state, commit, dispatch }): Promise<void> {
    const url = `/user/permissions`
    try {
      await new MainService().postData(url, state.permissions)
      commit(usersMutationTypes.FETCH_PERMISSIONS, state.permissions)
      dispatch(
        `${alertsModule}/showAlert`,
        {
          type: AlertsTypes.SUCCESS,
          message: i18n.t('components.alerts.permissions_save_success'),
          componentWhereIsRendered: ComponentWhereIsRendered.TABS_VIEW
        },
        { root: true }
      )
    } catch (error) {
      dispatch(
        `${alertsModule}/showAlert`,
        {
          type: DialogTypes.ERROR,
          message: i18n.t('components.alerts.permissions_save_error'),
          componentWhereIsRendered: ComponentWhereIsRendered.TABS_VIEW
        },
        { root: true }
      )
    }
  }
}
