import { ActionTree } from 'vuex'
import { FirmaState } from '@/store/modules/firma/firmaTypes'
import { RootState, ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import { i18n } from '@/plugins/vue-i18n'
import { firmaMutationTypes } from '@/store/modules/firma/firmaTypes'
import { MainService } from '@/services/MainService'
import { DialogTypes } from '@/store/modules/dialog/dialogTypes'

const dialogModule: string = ModuleNamespaces.DIALOG

export const actions: ActionTree<FirmaState, RootState> = {
  async signFirmaStart({ commit }, signInfo) {
    const url = 'sign/start'
    const { data } = await new MainService().postData(url, signInfo)
    commit(firmaMutationTypes.SET_FIRMA_TOKEN, data.token)
  },
  async signFirmaOpen({ commit }, signInfo) {
    const url = 'sign/open'
    const { data } = await new MainService().postData(url, signInfo)
    commit(firmaMutationTypes.SET_FIRMA_TOKEN, data.token)
  },
  // async signFirmaCancel( { commit }, guid: number) {
  //   const url = `sign/cancel/${guid}`
  //   const { data } = await new MainService().getData(url)
  // },
  async fetchSignaturitCredits({ dispatch }, idDocument: number) {
    const url = `sign/${idDocument}/checkAvailable`
    const { data } = await new MainService().getData(url)

    if (parseInt(data.credits, 10) === 0) {
      dispatch(
        `${dialogModule}/showDialog`,
        {
          type: DialogTypes.WARNING,
          message: i18n.t('components.firma_dialogs.warning_no_signaturit_credits'),
          hideSecondaryButton: true
        },
        { root: true }
      )
    } else if (!data.fileSizeOk) {
      dispatch(
        `${dialogModule}/showDialog`,
        {
          type: DialogTypes.WARNING,
          message: i18n.t('components.firma_dialogs.warning_max_file_size'),
          hideSecondaryButton: true
        },
        { root: true }
      )
    }
    return data.credits > 0 && data.fileSizeOk
  },
  async fetchMonitoredAuditsList({ commit }, idDocument) {
    const url = 'sign/get'
    const json = { idDocument }
    const { data } = await new MainService().postData(url, json)
    commit(firmaMutationTypes.SET_MONITORED_AUDITS, data)
  },
  async fetchOneAuditInfo({ commit }, i: any) {
    const url = `sign/audit/detail/${i.type}/${i.guid}`
    const { data } = await new MainService().getData(url)
    commit(firmaMutationTypes.SET_ONE_AUDIT_INFO, data)
  }
}
