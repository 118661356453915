import { LocaleMessage, TranslateResult } from 'vue-i18n'

export interface ContextMenuItem {
  text?: string | LocaleMessage | TranslateResult
  icon?: string
  iconCss?: string
  separator?: boolean
  action?: any
}

export interface ContextMenuState {
  selectedRegister: object
  items: ContextMenuItem[]
}

export const contextMenuMutationTypes = {
  SAVE_SELECTED_REGISTER_INFO: 'SAVE_SELECTED_REGISTER_INFO',
  SET_ITEMS: 'SET_ITEMS'
}
