import { UserInfo } from '@/store/modules/auth/authTypes'
import store from '@/store/store'
import { ModuleNamespaces } from '@/store/types/storeGlobalTypes'

export enum SecurityVerbs {
  VIEW = 'view',
  WRITE = 'write'
}

export enum SecurityCapacities {
  ALERTS = 'ALERTS',
  CHRONOMETER = 'CHRONOMETER',
  CONFIGURATION = 'CONFIGURATION',
  DASHBOARD_WIDGETS = 'DASHBOARD_WIDGETS',
  MAIN_SEARCH = 'MAIN_SEARCH',
  PROFILE = 'PROFILE',
  TASK = 'TASK'
}

export interface SecurityPermissions {
  view: string[]
  write: string[]
}

export interface SecurityState {
  security: Security | null
}

export class Security {
  constructor() {}

  getVerbFromModifiers(modifiers: { [key: string]: boolean }): string {
    let currentVerb: string = ''
    Object.values(SecurityVerbs).map((verb: string) => {
      if (modifiers[verb]) {
        currentVerb = verb
      }
    })
    return currentVerb
  }

  executeDomSecurity(el: HTMLElement, verb: string, capacity: string) {
    switch (verb) {
      case SecurityVerbs.VIEW:
        this.executeDomViewSecurity(el, capacity)
        break
    }
  }

  executeDomViewSecurity(el: HTMLElement, capacity: string) {
    if (!this.hasPermission(SecurityVerbs.VIEW, capacity)) {
      el.parentNode!.removeChild(el)
    }
  }

  hasPermission(verb: string, capacity: string): boolean {
    try {
      if (store) {
        const user: UserInfo | null = store.getters[`${ModuleNamespaces.AUTH}/getUser`]
        if (user) {
          const userPermissions: string[] = (user as any).permissions[verb]
          if (userPermissions) {
            return userPermissions.indexOf(capacity) < 0
          }
        }
      }
    } catch (e) {}
    return false
  }
}
