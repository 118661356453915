import { Route } from 'vue-router'
import { URLS } from '@/router/routes/urlRoutes'
import store from '@/store/store'
import { ModuleNamespaces } from '@/store/types/storeGlobalTypes'

export const alertsGuard = (to: Route, from: Route, next: any): void => {
  const isPortalUser = store.getters[`${ModuleNamespaces.AUTH}/getIsPortalUser`]

  if (from.name !== URLS.LOGIN && to.name !== URLS.LOGIN && !isPortalUser) {
    store.dispatch(`${ModuleNamespaces.APPOINTMENTS}/fetchControlSidebarAlertsObject`)
  }
  next()
}


