<script lang="ts">
import { MainService } from '@/services/MainService'
import { Vue, Component } from 'vue-property-decorator'

@Component
export default class OpenMSOfficeFilesMixin extends Vue {
  urlBase = process.env.VUE_APP_API_URL

  getMSOfficeLocalURI(extension: string) {
    switch (extension) {
      case '.doc':
      case '.docx':
      case 'doc':
      case 'docx':
        return 'ms-word:ofe|u|'
      case '.xls':
      case '.xlsx':
      case 'xls':
      case 'xlsx':
        return 'ms-excel:ofe|u|'
      case '.ppt':
      case '.pptx':
      case 'ppt':
      case 'pptx':
        return 'ms-powerpoint:ofe|u|'
      default:
        return ''
    }
  }

  async openMSOfficeLocal(file: any | null) {
    const selectedFileId = file.id
    const selectedFileName = file.originalFileName
    const url = `/repository/webdav-token/${selectedFileId}`
    const { data } = await new MainService().getData(url)
    const extension = file.type || file.extension
    const openWord = this.getMSOfficeLocalURI(extension)
    const urlFile = `${openWord}${this.urlBase}webdav/${data}/${encodeURIComponent(selectedFileName)}`
    window.open(urlFile, '_blank')
  }
}
</script>
