<template lang="pug">
  div
    GridTable(
      v-if="renderGrid && !showSpinnerLayer"
      :itemsData="billingData"
      :gridConfiguration="listConfig"
      :listName="listName"
      :title="$t('views.billing_provider_invoices.title')"
      :toolbarOptions="toolbarOptions"
      :contextMenuItems="invoiceContextMenuItems"
      :context="context"
      :totals="totals"
      :archivedFilter="archivedSelectedOption"
      :activeOnClickCell="selectedOnlyOneInvoice"
      :checkboxSelectColumn="hasCompanyTemplateInvoice"
      :frozenColumns="isPortalUser ? 1 : 3"
      @contextMenuClicked="onContextMenuClicked"
      @gridTableFilter="onGridTableFilter"
      @toolbarClicked="onToolbarClicked"
      @selectedRecords="onSelectedRecords"
      @contextMenuBeforeOpen="onContextMenuBeforeOpen"
    )

    ejs-contextmenu(
      :id="idContextMenu"
      class="context-menu-component e-contextmenu-archived"
      :items="invoicesArchivedContextMenuItemsParsed"
      :select="onInvoicesArchivedClick"
    )

    PrintOrSaveInvoicesDialog(
      :showDialog="showTemplateDialog"
      :idEntityType="idEntityType"
      @closePrintOrSaveInvoiceDialog="closeTemplateDialog"
      @generateTemplateDialog="generateTemplateDialog"
    )

</template>

<script lang="ts">
import { mixins } from 'vue-class-component'
import { Component } from 'vue-property-decorator'
import GridTable from '@/components/grids/GridTable/GridTableComponent.vue'
import { ContextName, ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import ListViewMixin from '@/mixins/ListViewMixin.vue'
import ArchivedFilterMixin from '@/mixins/ArchivedFilterMixin.vue'
import { Action, Getter, Mutation } from 'vuex-class'
import { ListNames } from '@/store/modules/configuration/configurationTypes'
import { BillingTypes, TotalItem } from '@/store/modules/billing/billingTypes'
import PrintInvoiceContextMenuMixin from '@/mixins/PrintInvoiceContextMenuMixin.vue'
import { entity } from '@/store/modules/entities/entitiesTypes'
import PrintOrSaveInvoicesDialog from '@/components/billing/PrintOrSaveInvoicesDialog/PrintOrSaveInvoicesDialog.vue'
import { ComponentWhereIsRendered } from '@/store/modules/alerts/alertsTypes'
import { InvoiceGenerated } from '@/store/modules/invoices/invoicesTypes'
import { MenuItem, MenuItemModel } from '@syncfusion/ej2-vue-navigations'
import { MAX_SELECTED_INVOICE_PRINT } from '@/components/billing/PrintOrSaveInvoicesDialog/types/PrintOrSaveInvoicesDialogTypes'
import { DialogTypes } from '@/store/modules/dialog/dialogTypes'
import { InvoiceStatus } from '@/store/modules/invoices/invoicesTypes'

const billingModule: string = ModuleNamespaces.BILLING
const entitiesModule: string = ModuleNamespaces.ENTITIES
const invoicesModule = ModuleNamespaces.INVOICES
const authModule = ModuleNamespaces.AUTH

@Component({
  components: {
    GridTable,
    PrintOrSaveInvoicesDialog
  }
})
export default class ProviderInvoicesView extends mixins(
  ListViewMixin,
  ArchivedFilterMixin,
  PrintInvoiceContextMenuMixin
) {
  @Action('fetchBillingData', { namespace: billingModule })
  fetchBillingData: (contactType: {}) => Promise<[]>

  @Action('fetchTotals', { namespace: billingModule })
  fetchTotals: (source: string) => []

  @Action('saveSelectedIdEntityType', { namespace: entitiesModule })
  saveSelectedIdEntityType: (type: number) => void

  @Getter('getTotals', { namespace: billingModule })
  totals: TotalItem[]

  @Getter('getCompanyTemplateInvoices', { namespace: authModule })
  hasCompanyTemplateInvoice: boolean

  @Mutation('ADD_GENERATE_INVOICE', { namespace: invoicesModule })
  addGenerateInvoice: (selectedInvoices: InvoiceGenerated[]) => void

  idContextMenu: string = 'invoicesArchivedContextMenuComponent'

  idEntity: number | string = 0
  idEntityType: number = entity.invoices.providers.type
  gridFilters: any = {}

  selectedInvoices = []

  contextMenuItemsText = {
    openNewWindow: this.$t('components.context_menu.open_window'),
    print: this.$t('action_buttons.print'),
    printOrSave: this.$t('action_buttons.print_or_save'),
    rectifyCustomerInvoice: this.$t('components.context_menu.rectify_customer_invoice')
  }

  get context() {
    return ContextName.BILLING
  }

  get listConfig() {
    const config = JSON.parse((this as any).listConfiguration['Config'])
    const index = config.columns.findIndex(({ editType }: any) => editType === 'booleanedit')
    if (index && index !== -1) {
      config.columns[index].visible = this.hasCompanyTemplateInvoice
    }
    return JSON.stringify(config)
  }

  get listName() {
    return (this as any).listConfiguration['Alias']
  }

  get selectedOnlyOneInvoice() {
    return this.selectedInvoices.length <= 1
  }

  get invoiceContextMenuItems() {
    const items = this.contextMenuItemsDefault
    const getPrintContextMenuItems = this.getPrintContextMenuItems(items)
    // Cambia el literal del menu por defecto de IMPRIMIR a IMPRIMIR/GUARDAR
    this.changePrintContextMenuLiteral(getPrintContextMenuItems)

    return getPrintContextMenuItems
  }

  changePrintContextMenuLiteral(contextMenuItems: MenuItem[]) {
    contextMenuItems.forEach((option: MenuItem) => {
      if (option.text === this.contextMenuItemsText.print) {
        option.text = this.contextMenuItemsText.printOrSave as string
      }
    })
  }

  get filters() {
    return {
      ...this.gridFilters,
      archived: this.archivedSelectedOption
    }
  }

  async created() {
    await this.fetchCurrentListConfiguration(ListNames.PROVIDER_INVOICES)
    this.initializeArchivedSelectedOption({ selectedOption: 1, contextMenu: this.idContextMenu })
    await this.fetchGridItems()
    this.saveSelectedEntityName(BillingTypes.PROVIDER_INVOICES)
    this.renderGrid = true
    this.hideSpinnerLayerAction()
  }

  async fetchGridItems() {
    await this.fetchBillingData({
      billingType: ListNames.PROVIDER_INVOICES,
      filter: { archived: this.archivedSelectedOption }
    })
  }

  onContextMenuClicked(args: any, selectedRegister: any) {
    if (args.item.text === this.contextMenuItemsText.printOrSave) {
      args.cancel = this.contextMenuPrintOrSave(selectedRegister)
    }
  }

  contextMenuPrintOrSave(selectedRegister: any) {
    if (this.selectedInvoices.length > MAX_SELECTED_INVOICE_PRINT) {
      this.showDialog({
        type: DialogTypes.WARNING,
        hideSecondaryButton: true,
        message: this.$t('components.dialog.exceeded_number_of_selected_invoices', {
          selectedInvoicesLength: this.selectedInvoices.length,
          maxInvoiceSelected: MAX_SELECTED_INVOICE_PRINT
        })
      })
      return true
    }
    if (this.hasCompanyTemplateInvoice) {
      this.idEntity = selectedRegister.id
      this.showTemplateDialog = true
      const selectedInvoices: InvoiceGenerated[] = this.selectedInvoices.map(({ idInvoice, numberInvoice }) => {
        return {
          idEntity: idInvoice,
          idEntityType: this.idEntityType,
          numberInvoice,
          status: InvoiceStatus.LOADING
        }
      })
      this.addGenerateInvoice(selectedInvoices)
    } else {
      this.printInvoiceOld(selectedRegister.id, entity.invoices.providers.alias)
    }
    return false
  }

  generateTemplateDialog(templateSelected: any) {
    this.printInvoice(templateSelected, this.idEntity, this.idEntityType, ComponentWhereIsRendered.GRID_TABLE)
  }

  onGridTableFilter(filters: any) {
    this.gridFilters = filters
    this.fetchTotals({
      source: ListNames.PROVIDER_INVOICES,
      ...this.filters
    })
  }

  onToolbarClicked(args: any) {
    const target = args.originalEvent.target.closest('button')
    if (target && target.id === 'archived') {
      this.toggleArchivedContextMenu(args.originalEvent, this.idContextMenu)
      args.cancel = true
    }
  }

  async onInvoicesArchivedClick(args: any) {
    this.archivedSelectedOption = args.item.actionType
    this.changeContextMenuIcons(args.item.actionType, this.idContextMenu)
    await this.fetchGridItems()
    await this.fetchTotals({ source: ListNames.PROVIDER_INVOICES, ...this.filters })
  }

  onSelectedRecords(selectedItems: any) {
    this.selectedInvoices = Array.isArray(selectedItems) ? selectedItems : ([] as any)
  }

  onContextMenuBeforeOpen(contextMenuModule: any, contextMenuGridObject: any, _selectedRegister: any) {
    contextMenuGridObject.items.forEach((item: MenuItemModel) => {
      const enable = !(item.text !== this.contextMenuItemsText.printOrSave && !this.selectedOnlyOneInvoice)
      contextMenuModule.enableItems([item.text], enable)
      contextMenuGridObject.enableItems([item.text], enable)
    })
  }

  closeTemplateDialog() {
    this.showTemplateDialog = false
  }
}
</script>
