<template lang="pug">

div(class="view-associated-actions-detail-dialog-container")
  CustomDialogHeaderComponent(:headerTitle="title" class="header")

  SpinnerLayerComponent(v-if="showSpinner" class="spinner-layer")

  section(v-else)
    HTMLTableComponent(
      ref="htmlTableComponent"
      v-if="hasRelatedActionList"
      class="data-table"
      :key="text.table.name"
      :tableData="tableData"
      :button-callback="showRemoveAssociationDialog"
      :button-icon="removeIcon"
    )

    div(
      v-else
      class="info-message-container"
    )
      div(class="info-message")
        span(:class="[infoIcon, 'icon']")
        span(class="text") {{  text.noAssociatedAction }}

    div(class="custom-dialog-action-buttons-container")
      button(
        v-if="hasRelatedActionList"
        :class="['button', 'secondary-action-button']"
        @click.stop="downloadData"
      ) {{ text.download }}
      button(
        :class="['button', 'main-action-button']"
        @click.stop="closeCustomDialogAction"
      ) {{ text.close }}

</template>

<script lang="ts">
import { Icons } from '@/icons/icons'
import { ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { Action } from 'vuex-class'
import { RelatedAction, RelatedActionResponse, RelatedActionSummary } from '@/store/modules/actions/actionsTypes'
import { downloadExcelByArray } from '@/helpers/downloadExcel'
import { DialogTypes } from '@/store/modules/dialog/dialogTypes'
import { ItemTypeEnum } from '@/components/HTMLTable/types/HTMLTableComponentTypes'
import HTMLTableComponent from '@/components/HTMLTable/HTMLTableComponent.vue'

const actionsModule: string = ModuleNamespaces.ACTIONS
const dialogModule = ModuleNamespaces.DIALOG

@Component({
  components: {
    CustomDialogActionButtonsComponent: () =>
      import('@/components/Dialog/CustomDialog/CustomDialogActionButtonsComponent.vue'),
    CustomDialogHeaderComponent: () => import('@/components/Dialog/CustomDialog/CustomDialogHeaderComponent.vue'),
    HTMLTableComponent: () => import('@/components/HTMLTable/HTMLTableComponent.vue'),
    SpinnerLayerComponent: () => import('@/components/Spinner/SpinnerLayerComponent.vue'),
  }
})
export default class ViewEconomicDataActionsDetailDialogComponent extends Vue {
  @Prop({
    type: Object,
    required: true
  })
  componentProps!: { selectedRegister: { id: number; description: string } }

  @Action('viewActionsDetail', { namespace: actionsModule })
  viewActionsDetailAction: (economicDataId: number) => RelatedActionResponse

  @Action('showDialog', { namespace: dialogModule })
  openDialogAction: ({}) => void

  @Action('closeCustomDialog', { namespace: dialogModule })
  closeCustomDialogAction: () => {}

  @Action('disassociateEconomicDataToAction', { namespace: actionsModule })
  disassociateEconomicDataAction: (relatedAction: RelatedAction) => Promise <void>

  text = {
    total: this.$t('components.HTML_table.total'),
    close: this.$t('action_buttons.close'),
    download: 'Excel',
    downloadError: 'Error',
    noAssociatedAction: this.$t('components.view_associated_actions_detail_dialog.no_associated_action'),
    title: this.$t('components.view_associated_actions_detail_dialog.title'),
    table: {
      name: this.$t('components.view_associated_actions_detail_dialog.table.name'),
      headers: {
        type: this.$t('components.view_associated_actions_detail_dialog.table.header.type'),
        subject: this.$t('components.view_associated_actions_detail_dialog.table.header.subject'),
        date: this.$t('components.view_associated_actions_detail_dialog.table.header.date'),
        duration: this.$t('components.view_associated_actions_detail_dialog.table.header.duration'),
        amount: this.$t('components.view_associated_actions_detail_dialog.table.header.amount')
      }
    }
  }

  infoIcon = Icons.INFO
  removeIcon = Icons.REMOVE

  relatedActionList: RelatedAction [] | null = null
  relatedActionSummary: RelatedActionSummary | null = null

  tableData: any = {};

  showSpinner = true

  relatedActionToRemove: RelatedAction

  get hasRelatedActionList () : boolean {
    return !!this.relatedActionList?.length
  }

  get title() {
    return `${this.text.title} ${this.componentProps.selectedRegister.description}`
  }

  mounted() {
    this.loadData()
  }

  async loadData() {
    const relatedActionResponse = await this.viewActionsDetailAction(this.componentProps.selectedRegister.id)
    this.relatedActionList = relatedActionResponse.actionList
    this.relatedActionSummary = relatedActionResponse.summary
    this.showSpinner = false
    this.tableData = {
      headers: [
        {
          title: this.text.table.headers.type,
          key: 'type',
          type: ItemTypeEnum.STRING
        },
        {
          title: this.text.table.headers.subject,
          key: 'subject',
          type: ItemTypeEnum.STRING
        },
        {
          title: this.text.table.headers.date,
          key: 'dateTimeAt',
          type: ItemTypeEnum.STRING
        },
        {
          title: this.text.table.headers.duration,
          key: 'duration',
          type: ItemTypeEnum.STRING
        },
        {
          title: this.text.table.headers.amount,
          key: 'economicEstimate',
          type: ItemTypeEnum.STRING
        },
        {
          title: null,
          key: 'operations',
          type: ItemTypeEnum.BUTTON
        }
      ],
      items: this.parseRelatedActionsForTable(),
      summary: [
        {
          key: 'duration',
          value: this.relatedActionSummary?.duration,
          type: ItemTypeEnum.STRING
        },
        {
          key: 'economicEstimate',
          value: this.relatedActionSummary?.economicEstimate,
          type: ItemTypeEnum.STRING
        }
      ]
    }
  }

  reloadData(){
    this.loadData()
    let table: HTMLTableComponent = this.$refs.htmlTableComponent as HTMLTableComponent
    table.$forceUpdate()
  }

  async downloadData (): Promise<void> {
    if(!this.hasRelatedActionList){
      return
    }

    const isDownloaded = downloadExcelByArray(
      this.parseDataForDownload(),
      this.title
    )

    if (!isDownloaded) {
      this.openDialogAction({
        type: DialogTypes.ERROR,
        message: this.text.downloadError
      })
    }
  }

  parseRelatedActionsForTable (): any [] {
    let data: any[] = []
    this.relatedActionList?.forEach((relatedAction: RelatedAction, _index) => {
      data.push(
        {
          'actionId': relatedAction.actionId,
          'economicDataId': relatedAction.economicDataId,
          'type': relatedAction.type,
          'subject': relatedAction.subject,
          'dateTimeAt': relatedAction.dateTimeAt,
          'duration': relatedAction.duration,
          'economicEstimate': relatedAction.economicEstimate,
          'buttons': !relatedAction.invoiced
        }
      )
    })
    return data;
  }

  parseDataForDownload (): any [] {
    let data: any[] = []
    this.relatedActionList?.forEach((relatedAction: RelatedAction, _index) => {
      data.push(
        {
          [this.text.table.headers.type.toString()]: relatedAction.type,
          [this.text.table.headers.subject.toString()]: relatedAction.subject,
          [this.text.table.headers.date.toString()]: relatedAction.dateTimeAt,
          [this.text.table.headers.duration.toString()]: relatedAction.duration,
          [this.text.table.headers.amount.toString()]: relatedAction.economicEstimate
        }
      )
    })
    data.push(
      {
        [this.text.table.headers.type.toString()]: this.text.total,
        [this.text.table.headers.subject.toString()]: null,
        [this.text.table.headers.date.toString()]: null,
        [this.text.table.headers.duration.toString()]: this.relatedActionSummary?.duration,
        [this.text.table.headers.amount.toString()]: this.relatedActionSummary?.economicEstimate
      }
    )

    return data
  }

  showRemoveAssociationDialog (relatedAction: RelatedAction) : void {
    this.relatedActionToRemove = relatedAction
    this.openDialogAction({
      type: DialogTypes.INFO,
      message: this.$t('components.dialog.remove_relate_action_text', {
        register: relatedAction.subject,
      }),
      action: () => this.removeRelatedAction (relatedAction)
    })
  }

  async removeRelatedAction(relatedAction: RelatedAction) {
    await this.disassociateEconomicDataAction (relatedAction)
    this.reloadData()
  }

}
</script>

<style lang="scss" scoped>
.view-associated-actions-detail-dialog-container {
  --spinner-layer-min-height: 150px;

  .data-table {
    margin: 5px 0 14px 0;
    @include scroll-styles;
    max-height: calc(11 * 28px);
    overflow-y: auto;

    ::v-deep table {
      .head-item,
      .body-item {
        text-align: left;
        padding-bottom: 0;
        padding-top: 0;
      }

      .head-item {
        &:nth-child(1) {
          width: 15%;
        }
        &:nth-child(2) {
          width: 48%;
        }
        &:nth-child(3) {
          width: 15%;
        }
        &:nth-child(4) {
          width: 10%;
        }
        &:nth-child(5) {
          width: 10%;
        }
        &:nth-child(6) {
          width: 2%;
        }
      }

      .body-item:first-of-type {
        color: $gray-02;
      }

      .summary-item {
        text-align: left;
      }
    }
  }

  .info-message-container {
    @include flex($justify-content: space-between);
    width: 100%;
    height: 50px;
    color: $white-01;
    padding: 0 20px;
    margin: 10px 0 20px 0;
    background-color: $blue-02;

    .info-message {
      @include flex;

      .icon {
        font-size: 30px;
        margin-right: 20px;
        opacity: 0.5;
      }

      .text {
        font-family: $corporate-font;
        font-size: 14px;
      }
    }
  }
  .custom-dialog-action-buttons-container {
    @include flex($justify-content: flex-end);

    .main-action-button {
      @include main-action-button--rectangle;
    }

    .secondary-action-button {
      @include secondary-action-button--rectangle;
    }
  }

}
</style>
