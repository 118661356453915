<template lang="pug">

  button(class="totals-button" @click.stop="toggleTotals")
    span(:class="[iconTotals, 'totals-icon']")
    span(class="toggle-totals-text") {{ totalsText }}

</template>

<script lang="ts">
import { Icons } from '@/icons/icons'
import { Vue, Component, Prop, Emit } from 'vue-property-decorator'

@Component
export default class ShowOrHideTotalsButtonComponent extends Vue {
  @Prop({
    type: Boolean,
    required: true
  })
  showTotals!: boolean

  @Emit()
  toggleTotals() {}

  get totalsText() {
    const status = this.showTotals ? this.$t('action_buttons.hide') : this.$t('action_buttons.show')
    return this.$t('components.grid_table.toggle_totals', { status })
  }

  get iconTotals() {
    return this.showTotals ? Icons.NOT_VISIBLE : Icons.VISIBLE
  }
}
</script>

<style lang="scss" scoped>
.toggle-totals-text {
  min-width: 105px;
}

.totals-button {
  @include flex;
  font-family: $corporate-font-bold;
  font-size: 14px;
  color: var(--toggle-btn-color, $corporate-color);

  .totals-icon {
    font-size: 20px;
    padding: 0 5px 5px 0;
  }
}
</style>
