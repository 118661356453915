<template lang="pug">
  div(class="expedient-invoices")
    GridTableComponent(
      ref="grid"
      v-if="!showAddInvoice"
      :itemsData="gridData"
      :gridConfiguration="gridColumns"
      :title="title"
      :toolbarOptions="customToolbarOptions"
      :contextMenuItems="gridContextMenuItems"
      :listName="listName"
      :context="invoiceContext"
      :showHeader="false"
      :disabledContextMenuBeforeOpen="false"
      @cellClicked="onCellClicked"
      @contextMenuBeforeOpen="onContextMenuBeforeOpen"
      @contextMenuClicked="onContextMenuClicked"
      @toolbarClicked="onToolbarClicked"
    )

    ejs-contextmenu(
      id="expedientInvoicesContextMenuComponent"
      class="context-menu-component"
      :items="invoicesContextMenuItems"
      :select="onInvoiceAddClick"
    )

    TemplateSelectorComponent(
      :showDialog="showTemplateDialog"
      :idEntityType="idEntityType"
      @closeTemplateDialog="closeTemplateDialog"
      @generateTemplateDialog="generateTemplateDialog"
    )

</template>

<script lang="ts">
import { Component, Mixins, Prop } from 'vue-property-decorator'
import { Action, Getter, Mutation } from 'vuex-class'
import GridTableComponent from '@/components/grids/GridTable/GridTableComponent.vue'
import { Icons } from '@/icons/icons'
import { ContextName, ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import { Entity, entity, entityKeyFields } from '@/store/modules/entities/entitiesTypes'
import { Endpoint } from '@/store/modules/endpoint/endpointTypes'
import { CustomDialogComponentName, CustomDialogData, DialogTypes } from '@/store/modules/dialog/dialogTypes'
import PrintInvoiceContextMenuMixin from '@/mixins/PrintInvoiceContextMenuMixin.vue'
import { getUrlByIdEntity, redirectTo } from '@/helpers/routes'
import TemplateSelectorComponent from '@/components/template/TemplateSelectorComponent/TemplateSelectorComponent.vue'
import { ComponentWhereIsRendered } from '@/store/modules/alerts/alertsTypes'
import { ExpedientCustomerFact } from '@/store/modules/expedients/expedientsTypes'
import GridContextMenuOptionsMixin from '@/mixins/GridContextMenuOptionsMixin.vue'
import { InvoiceTypesNames } from '@/store/modules/invoices/invoicesTypes'
import { TrackerEvents, trackEvent } from '@/plugins/tracker'

const authModule = ModuleNamespaces.AUTH
const configurationModule: string = ModuleNamespaces.CONFIGURATION
const endpointModule: string = ModuleNamespaces.ENDPOINT
const entitiesModule: string = ModuleNamespaces.ENTITIES
const expedientsModule = ModuleNamespaces.EXPEDIENTS
const listItemsModule = ModuleNamespaces.LIST_ITEMS
const selectedRegisterModule: string = ModuleNamespaces.SELECTED_REGISTER
const dialogModule = ModuleNamespaces.DIALOG

@Component({
  components: {
    GridTableComponent,
    TemplateSelectorComponent
  }
})
export default class ExpedientInvoicesComponent extends Mixins(
  PrintInvoiceContextMenuMixin,
  GridContextMenuOptionsMixin
) {
  @Prop({
    type: Number,
    required: true
  })
  expedientId!: string

  @Prop({
    type: String,
    default: ''
  })
  columns!: string

  @Prop({
    type: String,
    default: ''
  })
  title!: string

  @Prop({
    type: String,
    default: ''
  })
  listName!: string

  @Prop({
    type: Boolean,
    default: false
  })
  showGrid!: boolean

  @Prop({
    type: [Array, Object],
    required: true
  })
  itemsData!: []

  @Prop({
    type: Boolean,
    default: false
  })
  showFilters!: boolean

  @Action('createEntity', { namespace: entitiesModule })
  createEntity: ({}) => Promise<void>

  @Action('saveSelectedRegisterId', { namespace: selectedRegisterModule })
  saveSelectedRegisterId: ({}) => Promise<void>

  @Action('deleteRegister', { namespace: selectedRegisterModule })
  deleteInvoice: ({}) => Promise<void>

  @Action('fetchCurrentListConfiguration', { namespace: configurationModule })
  fetchCurrentListConfiguration: (alias: string) => Promise<void>

  @Action('showCustomDialog', { namespace: dialogModule })
  showCustomDialogAction: ({}: CustomDialogData) => {}

  @Mutation('REMOVE_LIST_ITEMS', { namespace: listItemsModule })
  removeListItems: () => Promise<[]>

  @Mutation('SAVE_EXPEDIENT_CUSTOMER_FACT', { namespace: expedientsModule })
  saveExpedientCustomerFactMutation: (expedientCustomerFact: ExpedientCustomerFact) => void

  @Getter('getEntity', { namespace: entitiesModule })
  entity: (context: string) => Entity

  @Getter('getEndpoints', { namespace: endpointModule })
  endpoints: (entityType: number) => Endpoint

  @Getter('getSelectedRegisterId', { namespace: selectedRegisterModule })
  selectedRegisterId: (context: string) => any

  @Getter('getListItemsWithFormattedDates', { namespace: listItemsModule })
  listItems: object

  @Getter('getCurrentListConfiguration', { namespace: configurationModule })
  listConfiguration: object

  @Getter('getCompanyTemplateInvoices', { namespace: authModule })
  hasCompanyTemplateInvoice: boolean

  @Getter('getSelectedRegisterData', { namespace: selectedRegisterModule })
  selectedRegisterData: (context: string) => {}

  entityType: number = 0

  showAddInvoice: boolean = false
  idEntityType: number = entity.invoices.customers.type
  idEntity: number = 0

  gridData = this.itemsData
  gridColumns = this.columns
  invoiceContext = ContextName.EXPEDIENT_INVOICES

  icons = {
    addNew: Icons.ADD,
    invoice: Icons.BILL
  }

  numberInvoice: string = ''

  get customToolbarOptions() {
    return [
      {
        id: 'filter',
        prefixIcon: Icons.FILTER,
        text: this.$t('components.grid_table.tooltip.clean_filter'),
        align: 'left',
        cssClass: 'lf-clean-filter-button'
      },
      {
        id: 'search',
        text: 'Search',
        align: 'right'
      },
      ...(!this.isPortalUser
        ? [
            {
              id: 'archived',
              prefixIcon: Icons.REPORTS_T,
              tooltipText: this.$t('components.grid_table.tooltip.archived'),
              align: 'right',
              cssClass: 'lf-gray-btn'
            }
          ]
        : []),
      {
        id: 'print',
        prefixIcon: Icons.PRINT,
        tooltipText: this.$t('components.grid_table.tooltip.print'),
        align: 'right'
      },
      {
        id: 'export',
        prefixIcon: Icons.DOWNLOAD,
        tooltipText: this.$t('components.grid_table.tooltip.export'),
        align: 'right'
      },
      ...(this.userHasPermissionToInvoiceAtLeastOneInvoiceType
        ? [
            {
              id: 'add',
              prefixIcon: Icons.ADD,
              tooltipText: this.$t('components.grid_table.tooltip.new'),
              align: 'right'
            }
          ]
        : [])
    ]
  }

  get invoicesContextMenuItems() {
    const invoiceTypes = [
      { type: entity.invoices.orderForms.type, text: this.$t('components.context_menu.order_form') },
      { type: entity.invoices.proforma.type, text: this.$t('navigation.drawer-menu-items.invoice_proforma') },
      { type: entity.invoices.customers.type, text: this.$t('navigation.drawer-menu-items.invoice_customer') },
      { type: entity.invoices.providers.type, text: this.$t('navigation.drawer-menu-items.invoice_provider') }
    ]

    return invoiceTypes
      .filter((invoiceType) => this.checkEntityPermissionsGetter(invoiceType.type).canSave)
      .map((invoiceType) => ({
        text: invoiceType.text,
        iconCss: this.icons.invoice,
        idEntity: invoiceType.type
      }))
  }

  get gridContextMenuItems() {
    const { openWindow, editItem, lookItem, removeItem } = this.contextMenuOptionsTexts

    const items = this.isPortalUser
      ? [{ iconCss: Icons.OPEN_WINDOW, text: openWindow }]
      : [
          { text: editItem, iconCss: Icons.EDIT },
          { text: removeItem, iconCss: Icons.REMOVE, action: this.showDialog },
          { text: lookItem, iconCss: Icons.VISIBLE },
          { iconCss: Icons.OPEN_WINDOW, text: openWindow },
        ]

    return items
  }

  get getViewActionsDetailContextMenuItem () {
    const { viewActionsDetail } = this.contextMenuOptionsTexts
    return { text: viewActionsDetail, iconCss: Icons.FOLDER_OPEN }
  }

  created() {
    this.invoiceContext = ContextName.EXPEDIENT_INVOICES
  }

  async onCellClicked(selectedRegister: any, cancel: boolean) {
    trackEvent(TrackerEvents.EDIT_INVOICE)
    if (!cancel && !this.isPortalUser) {
      cancel = true
      this.invoiceRedirect(selectedRegister)
      const urlInvoice = getUrlByIdEntity(selectedRegister.idEntity, selectedRegister.idInvoice)
      redirectTo(urlInvoice)
    }
  }

  onContextMenuBeforeOpen(contextMenuModule: any, contextMenuGridObject: any, selectedRegister: any) {
    const selectedContactTypePermission = this.checkEntityPermissionsGetter(selectedRegister.idEntity)
    const { canSave, canDelete } = selectedContactTypePermission

    this.renderEditOrLookOptionInContextMenu(contextMenuModule, contextMenuGridObject, canSave)
    const { removeItem, separator, editItem, viewActionsDetail } = this.contextMenuOptionsTexts

    if (contextMenuGridObject.items.findIndex(({ text }: { text: string }) => text === removeItem) >= 0) {
      contextMenuModule.removeItems([separator, removeItem])
      contextMenuGridObject.removeItems([separator, removeItem])
    }

    contextMenuModule.removeItems([viewActionsDetail])
    contextMenuGridObject.removeItems([viewActionsDetail])
    if (selectedRegister.type.toString().toLowerCase() === InvoiceTypesNames.CUSTOMER.toLowerCase()) {
      contextMenuModule.insertAfter([this.getViewActionsDetailContextMenuItem], editItem, false)
      contextMenuGridObject.insertAfter([this.getViewActionsDetailContextMenuItem], editItem, false)
    }

    this.renderRemoveOptionInContextMenu(
      contextMenuModule,
      contextMenuGridObject,
      canDelete,
      this.contextMenuOptionsTexts.openWindow
    )
  }

  getEntityAlias(entityType: number) {
    const selectedEntity = Object.values(entity.invoices).find((entity: any) => entity.type === entityType)
    return (selectedEntity as any).alias
  }

  async onContextMenuClicked(args: any, selectedRegister: any) {
    this.numberInvoice = selectedRegister.numberInvoice
    const { openWindow, editItem, lookItem, removeItem, viewActionsDetail } = this.contextMenuOptionsTexts
    args.cancel = true
    if (args.item.text === editItem || args.item.text === lookItem) {
      trackEvent(TrackerEvents.EDIT_INVOICE)
      this.invoiceRedirect(selectedRegister)
    } else if (args.item.text === removeItem) {
      this.onRemoveInvoice(selectedRegister)
    } else if (args.item.text === openWindow) {
      trackEvent(TrackerEvents.EDIT_INVOICE)
      if (this.isPortalUser) {
        this.idEntity = selectedRegister.idInvoice
        this.idEntityType = selectedRegister.idEntity
        if (this.hasCompanyTemplateInvoice) {
          this.showTemplateDialog = this.checkIfOnlyOneInvoice(this.idEntity, this.idEntityType)
        } else {
          const entityAlias = this.getEntityAlias(this.idEntityType)
          this.printInvoiceOld(this.idEntity, entityAlias)
        }
      } else {
        this.invoiceRedirect(selectedRegister, '_blank')
      }
    } else if (args.item && args.item.text === viewActionsDetail) {
      this.showCustomDialogAction({
        name: CustomDialogComponentName.VIEW_INVOICE_ACTIONS,
        styles: {
          maxWidth: '1000px',
          maxHeight: '600px'
        },
        props: {
          selectedRegister: {
            id: selectedRegister[entityKeyFields.invoices.id],
            description: selectedRegister.numberInvoice
          }
        }
      })
    }
  }

  generateTemplateDialog(templateSelected: any) {
    this.printInvoice(templateSelected, this.idEntity, this.idEntityType, ComponentWhereIsRendered.TABS_VIEW)
  }

  async onRemoveInvoice(selectedRegister: any) {
    await this.createEntity({
      entityType: selectedRegister.idEntity,
      idEntity: selectedRegister[entityKeyFields.invoices.id],
      context: this.invoiceContext
    })

    await this.saveSelectedRegisterId({
      id: selectedRegister[entityKeyFields.invoices.id],
      context: this.invoiceContext
    })

    this.showRemoveDialog(selectedRegister.numberInvoice)
  }

  showRemoveDialog(title: string) {
    this.showDialog({
      type: DialogTypes.INFO,
      message: this.$t('components.dialog.remove_register_text', {
        text: this.$t('components.expedient_economic_data.invoice_delete'),
        register: title
      }),
      action: this.removeInvoice
    })
  }

  async removeInvoice() {
    await this.deleteInvoice({
      deleteEndpoint: this.endpoints(this.entity(this.invoiceContext).type).delete,
      listEndpoint: this.endpoints(this.entity(this.invoiceContext).type).list,
      goToURL: null,
      name: this.numberInvoice,
      dialogText: this.$t('components.dialog.invoices_literal')
    })
    await this.refreshInvoiceList()
  }

  async refreshInvoiceList() {
    await this.removeListItems()
    await this.fetchCurrentListConfiguration('listExpedientInvoices')
    await this.$store.dispatch(`expedientsModule/fetchExpedientsInvoices`, {
      selectedRegisterId: this.expedientId,
      listName: 'listExpedientsInvoices'
    })

    this.gridColumns = (this as any).listConfiguration['Config']
    this.gridData = (this as any).listItems
  }

  invoiceRedirect(selectedRegister: any, target: string = 'self') {
    const urlInvoice = getUrlByIdEntity(selectedRegister.idEntity, selectedRegister.idInvoice)
    redirectTo(urlInvoice, target)
  }

  onToolbarClicked(args: any) {
    const target = args.originalEvent.target.closest('button')

    if (target && target.id === 'add') {
      this.toggleContextMenu(args.originalEvent)
      args.cancel = true
    }
  }

  toggleContextMenu(event: MouseEvent) {
    if (event) {
      const btn: EventTarget | null = event.target
      if (btn) {
        const rect: ClientRect | DOMRect = (btn as HTMLHtmlElement).getBoundingClientRect()
        const elem: any = document.getElementById('expedientInvoicesContextMenuComponent')
        if (null !== elem) {
          elem.ej2_instances[0].open(rect.top + window.scrollY + 50, rect.left + 20)
        }
      }
    }
  }

  async onInvoiceAddClick({item}: any) {
    const { idEntity } = item
    this.trackClickAdd(idEntity)
    const urlInvoice = getUrlByIdEntity(idEntity, (this as any).$t('views.selected_register.new'))
    this.saveExpedientCustomerFact()
    redirectTo(urlInvoice)
  }

  trackClickAdd(idEntity: number) {
    if (idEntity === entity.invoices.proforma.type) {
      trackEvent(TrackerEvents.CREATE_INVOICE)
    }
    if (idEntity === entity.invoices.customers.type) {
      trackEvent(TrackerEvents.CREATE_INVOICE)
    }
  }

  saveExpedientCustomerFact() {
    const customerFactInfo = (this as any).selectedRegisterData(ContextName.EXPEDIENTS).customerfact

    if (customerFactInfo) {
      this.saveExpedientCustomerFactMutation(customerFactInfo)
    }
  }
}
</script>
<style lang="scss" scoped>
.expedient-invoices {
  .tab-header-container {
    border: none;
    margin-bottom: 0;
    padding-bottom: 0;
  }
}
</style>
