import { ActionTree } from 'vuex'
import { GlobalState, globalMutationTypes } from '@/store/modules/global/globalTypes'
import { RootState } from '@/store/types/storeGlobalTypes'

export const actions: ActionTree<GlobalState, RootState> = {
  resetModuleInitialState({ commit, rootState }, { moduleName, initialState }) {
    const moduleData = {
      rootState,
      moduleName,
      initialState
    }
    commit(globalMutationTypes.RESET_MODULE_INITIAL_STATE, moduleData)
  }
}
