import { MutationTree } from 'vuex'
import { ReportsState, ReportList } from '@/store/modules/reports/reportsTypes'
import { router } from '@/router/router'
import { URLS } from '@/router/routes/urlRoutes'

export const mutations: MutationTree<ReportsState> = {
  FETCH_REPORTS(state, reports) {
    const list = reports
    list.map((item: any) => {
      item.reportName = item.name
      item.config = typeof item.config === 'string' ? JSON.parse(item.config) : item.config
      item.date = item.dateEPOCH
      item.fecha = item.dateEPOCH
    })
    state.reports = list
  },
  POST_REPORT(state, data) {
    if (data.id !== 'OK') {
      router.push(`/${URLS.REPORTS}/${data.id}`)
      const repo = { id: 0, ...state.report }
      repo.id = data.id
    }
  },
  DELETE_REPORT(state) {
    const list = state.reports
    if (list) {
      for (const item of list) {
        item.reportName = item.name
      }
    }
    state.reports = list
  },
  FETCH_REPORT(state, data) {
    const report = data
    report.reportName = data.name
    report.config = data.config ? JSON.parse(data.config) : null
    state.report = report
  },
  FETCH_REPORT_DATA(state, data) {
    const report = state.report
    if (report && report.config) {
      ;(report as any).config.dataSource = JSON.parse(data)
    }
    state.report = report
  },
  FETCH_DASHBOARD_REPORTS(state, reports) {
    state.dashboardReportsList = reports
  },
  FETCH_TOTAL_NUMBER_OF_REPORTS(state, total) {
    state.total = total
  },
  SET_FIRST_LOAD(state, status) {
    state.firstLoad = status
  },
  RESET_REPORT(state) {
    state.report = {
      id: null,
      idCategory: null,
      idType: null,
      name: null,
      reportName: null,
      category: null,
      config: null,
      description: null,
      date: null
    }
    state.firstLoad = true
  },
  SET_SELECTED_REPORT_LIST(state: ReportsState, report: ReportList) {
    state.selectedReportList = report
  }
}
