import { GetterTree } from 'vuex'
import { BillingState } from '@/store/modules/billing/billingTypes'
import { RootState } from '@/store/types/storeGlobalTypes'
import { formatFieldsTypeDateEPOCH } from '@/helpers/dateTime'

export const getters: GetterTree<BillingState, RootState> = {
  getBillingData(state) {
    return state.billing
  },
  getTotals(state) {
    return state.totals
  },
  getBillingWithFormattedDates(state) {
    if (state.billing) {
      return formatFieldsTypeDateEPOCH(state.billing)
    }
    return ''
  },
  getLastListBillingFilter(state) {
    return state.lastListBillingFilter
  }
}
