import { MutationTree } from 'vuex'
import { EntityState } from '@/store/modules/entities/entitiesTypes'

export const mutations: MutationTree<EntityState> = {
  SAVE_ENTITY(state, { entity, context }) {
    state.entity[context] = entity
  },
  SAVE_ID_ENTITY(state, { idEntity, context }) {
    if (state.entity[context]) {
      state.entity[context].id = idEntity
    }
  },
  SAVE_ID_ENTITY_TYPE(state, idEntityType: number) {
    state.idEntityType = idEntityType
  },
  SAVE_SELECTED_ENTITY_NAME(state, entityName) {
    state.selectedEntityName = entityName
  },
  SAVE_MENU_PARENT_CATEGORY_NAME(state, parentCategoryName) {
    state.menuParentCategoryName = parentCategoryName
  }
}
