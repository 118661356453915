<template lang="pug">

  div(class="billing-totals-container")

    div(
      v-for="(item, index) in items"
      :key="index"
    )

      //- Accordion type
      v-expansion-panels(v-if="item.children" :value="0")
        v-expansion-panel
          v-expansion-panel-header
            BillingTotalItemComponent(
              :item="item"
            )

          v-expansion-panel-content
            BillingTotalItemComponent(
              v-for="(child, index) in item.children"
              :key="index"
              :item="child"
              isAccordionType
            )

      //- Is not an accordion
      BillingTotalItemComponent(
        v-else
        :item="item"
      )

</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator'
import BillingTotalItemComponent from '@/components/billing/BillingTotals/BillingTotalItemComponent.vue'

@Component({
  components: {
    BillingTotalItemComponent
  }
})
export default class BillingTotalsContainerComponent extends Vue {
  @Prop({
    type: Array,
    required: true
  })
  items!: object[]
}
</script>

<style lang="scss" scoped>
.billing-totals-container {
  @include borders($color: $blue-13);
  height: 100%;
  background-color: $gray-04;
  padding: 10px;

  ::v-deep .v-expansion-panels {
    border-radius: 0 !important;

    .v-expansion-panel {
      border-radius: 0 !important;

      &::before {
        display: none;
      }
    }

    .v-expansion-panel-header {
      padding: 0;
      min-height: 50px;
    }

    .v-expansion-panel-content__wrap {
      padding: 0;
    }

    .v-expansion-panel-header__icon {
      @include expansion-panel-icon;
      top: 17px;
    }
  }
}
</style>
