export enum ContactTypes {
  AGENCIES = 'agencies',
  COURTS = 'courts',
  CUSTOMER = 'customers',
  INSURERS = 'insurers',
  NOTARIES = 'notaries',
  OPPONENTS = 'opponents',
  OPPONENT_ATTORNEYS = 'opposing_attorneys',
  OPPONENT_LAWYERS = 'opposing_lawyers',
  OTHERS = 'others',
  OWN_ATTORNEYS = 'own_attorneys',
  OWN_LAWYERS = 'own_lawyers',
  PROVIDERS = 'providers'
}

export enum ContactTypeId {
  AGENCIES = 44,
  COURTS = 10,
  CUSTOMER = 2,
  INSURERS = 11,
  NOTARIES = 9,
  OPPONENTS = 3,
  OPPONENT_ATTORNEYS = 8,
  OPPONENT_LAWYERS = 6,
  OTHERS = 12,
  OWN_ATTORNEYS = 7,
  OWN_LAWYERS = 5,
  PROVIDERS = 4
}

export enum IntervenerTypeId {
  CUSTOMER = 1,
  OPPONENTS = 2,
  OWN_LAWYERS = 3,
  OPPONENT_LAWYERS = 4,
  OWN_ATTORNEYS = 5,
  OPPONENT_ATTORNEYS = 6,
  NOTARIES = 7,
  OTHERS = 8
}

export enum ContactForms {
  CUSTOMER = 'customersInterveners',
  NOTARIES = 'notariesInterveners',
  OPPONENTS = 'opponentsInterveners',
  OPPONENT_LAWYERS = 'opponentsLawyersInterveners',
  OWN_ATTORNEYS = 'attorneysInterveners',
  OPPONENT_ATTORNEYS = 'opponentsAttorneysInterveners',
  OTHERS = 'othersInterveners',
  OWN_LAWYERS = 'lawyersInterveners'
}

export interface ContactsState {
  contacts: any[] | null
  contactTypeSelected: ContactTypes | null
}

export const contactsMutationTypes = {
  FETCH_CONTACTS: 'FETCH_CONTACTS',
  RESET_CONTACTS: 'RESET_CONTACTS',
  CONTACT_TYPE_SELECTED: 'CONTACT_TYPE_SELECTED'
}
