<template lang="pug">
  aside(
    v-if="Object.keys(userProfileConfigurationObject).length"
    :class="[{open: opened}, 'control-sidebar']"
    :style="elementTopPosition"
    v-click-outside="closeControlSidebarEvent"
  )
    ul(:class="[{ 'customer-type': isPortalUser }, 'control-sidebar-tabs', 'list']")
      li(
          v-for="(item, index) in getItems"
          :key="index"
          :class="[{active: selectedItem === item.name}, 'item']"
          @click="changeSelectedItem(item.name)"
        )
        span(class="tab")
          i(:class="[item.icon, 'icon']")

    div(class="tabs-container")

      //- User tab
      div(v-if="selectedItem === 'user'" class="user-info")
        div(class="purple-circle") {{ userNameAbbreviation }}
        h3(class="user-name") {{ userName }}
        p(class="company-name") {{ companyName }}
        button(class="close-button" type="button" @click.stop="closeSession") {{this.$t('navigation.control_sidebar.close_session')}}

      //- Alerts tab
      div(v-if="selectedItem === 'alerts'" class="alerts-info")
        span(class="section-title") {{this.$t('navigation.control_sidebar.alerts')}}
        ul(v-if="alertsObject.data.length" class="alerts-list list")
          li(
              class="alert-item"
              v-for="(alert, index) in alertsObject.data"
              :key="index"
            )
            routerLink(class="alert-link" target= '_blank' :to="{name: urlAgenda , query: {id: alert.id}}")
              i(class="menu-icon" :class="[icons.alert]")
              div(class="alert-info")
                span(class="alert-title") {{ ellipsified(alert.subject) }}
                p(class="alert-date") {{ alert.start }}
            div(class="actions")
              button(@click.stop="postponeAlert(alert)" type="button" class="action-button") {{ $t('navigation.control_sidebar.postpone') }}
              button(@click.stop="deleteAlert(alert)" type="button" class="action-button") {{ $t('navigation.control_sidebar.finalize') }}

        p(v-else class="no-items-message") {{ $t('navigation.control_sidebar.no_alerts') }}

        PaginationComponent(
          v-if="calculateNumberOfPagesToPaginate(alertsObject.pagination.totalRecs)"
          :totalPages="numberOfPages('alerts')"
          :currentPage="currentAlertsPage"
          @pageChanged="changedAlertsPage"
        )

      //- Tasks tab
      div(v-if="selectedItem === 'tasks'" class="tasks-info")
        div(class="add-task-container")
          span(class="section-title") {{ this.$t('navigation.control_sidebar.tasks_manager.tasks') }}
          button(
            v-if="!showTasksManager"
            type="button"
            class="add-task-button"
            @click="openTasksManager"
          )
            span(:class="[icons.add, 'add-task-icon']")

        //- Tasks list
        div(v-if="!showTasksManager" key="tasks-list")
          ul(v-if="tasksObject.data.length" class="tasks-container list")
            li(
              v-for="(task, taskIndex) in tasksObject.data"
              :key="taskIndex"
              class="task-card"
            )
              div(class="remove-card")
                button(
                  type="button"
                  class="remove-task-button"
                  :title="removeNoteTooltipText"
                  @click.stop="removeTask(task)")
                  i(:class="icons.close")
              p(class="task-text") {{ task.subject }}
          //- Without tasks
          div(v-else class="no-items-message") {{ $t('navigation.control_sidebar.tasks_manager.no_tasks') }}

          PaginationComponent(
            v-if="calculateNumberOfPagesToPaginate(tasksObject.pagination.totalRecs)"
            :totalPages="numberOfPages('tasks')"
            :currentPage="currentTasksPage"
            @pageChanged="changedTasksPage"
          )

        //- Tasks manager
        div(class="tasks-manager" v-else key="tasks-manager")
          v-textarea(
            class="tasks-textarea"
            solo
            :placeholder="this.$t('navigation.control_sidebar.tasks_manager.manager_placeholder')"
            no-resize
            :counter="numberOfValidNewTaskCharacters"
            v-model="newTask.subject"
            :maxlength="numberOfValidNewTaskCharacters + 1"
          )

          div(class="confidential-container")
            label(class="switch")
              input(
                type="checkbox"
                v-model="confidentialSwitch"
              )
              span(class="slider round")
            span(for="confidential" class="task-checkbox-label") {{ $t('components.control_sidebar.checkbox_label') }}

          div(class="tasks-manager-buttons-container")
            button(type="button" class="main-action-button" @click.stop="closeTasksManager") {{ $t('action_buttons.cancel') }}
            button(type="button" :disabled="checkIfMainButtonIsDisabled" class="main-action-button" @click.stop="addNewTask") {{ $t('action_buttons.save') }}

      //- Config tab
      div(v-if="selectedItem === 'options'" class="config-info")
        ul(class="config-list list")
          li(v-for="(item, index) in configurationMenu" :key="index" class="config-item" @click.stop="closeControlSidebarEvent")
            a(v-if="!item.internalUrl" class="config-link" :href="formatURL(item.url)") {{ item.text }}
            router-link(v-else :to="`/${item.url}`" class="config-link") {{ item.text }}

</template>

<script lang="ts">
import { Component, Prop, Emit, Mixins } from 'vue-property-decorator'
import {
  ControlSidebarItem,
  ControlSidebarTask
} from '@/components/Navigation/ControlSidebar/types/ControlSidebarComponentTypes'
import PaginationComponent from '@/components/Pagination/PaginationComponent.vue'
import { Icons } from '@/icons/icons'
import { URLS } from '@/router/routes/urlRoutes'
import { ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import { Getter, Action } from 'vuex-class'
import { Alert, ControlSidebarAlertsObject } from '@/store/modules/appointments/appointmentsTypes'
import { ControlSidebarTasksObject, Task } from '@/store/modules/tasks/tasksTypes'
import { hasPermission } from '@/helpers/security'
import { SecurityCapacities, SecurityVerbs } from '@/store/modules/security/securityTypes'
import { NavigationItems } from '@/components/Navigation/NavigationTypes'
import PermissionsMixin from '@/mixins/PermissionsMixin.vue'
import { resetTracker } from '@/plugins/tracker'

const authModule = ModuleNamespaces.AUTH
const appointmetsModule = ModuleNamespaces.APPOINTMENTS
const tasksModule = ModuleNamespaces.TASKS
const menusModule = ModuleNamespaces.MENUS
const configurationModule = ModuleNamespaces.CONFIGURATION

@Component({
  components: {
    PaginationComponent
  }
})
export default class ControlSidebarComponent extends Mixins(PermissionsMixin) {
  @Prop({
    type: Boolean
  })
  opened!: boolean

  @Prop({
    type: String
  })
  selectedItem!: string

  @Getter('getUserProfileConfiguration', { namespace: configurationModule }) userProfileConfigurationObject: any
  @Getter('getUserName', { namespace: authModule }) userName: string
  @Getter('getIsPortalUser', { namespace: authModule }) isPortalUser: string
  @Getter('getUserNameAbbreviation', { namespace: authModule }) userNameAbbreviation: string
  @Getter('getCompanyName', { namespace: authModule }) companyName: string
  @Getter('getControlSidebarAlertsObject', { namespace: appointmetsModule }) alertsObject: ControlSidebarAlertsObject
  @Getter('getControlSidebarTasksObject', { namespace: tasksModule }) tasksObject: ControlSidebarTasksObject
  @Getter('getConfigurationMenu', { namespace: menusModule }) configurationMenu: string

  @Action('postponeAlert', { namespace: appointmetsModule }) postpone: (id: string) => void
  @Action('deleteAlert', { namespace: appointmetsModule }) delete: (id: string) => void
  @Action('fetchControlSidebarAlertsObject', { namespace: appointmetsModule })
  fetchAlertsPage: ({}) => ControlSidebarAlertsObject
  @Action('fetchControlSidebarTasksObject', { namespace: tasksModule })
  fetchTasksPage: ({}) => ControlSidebarTasksObject
  @Action('deleteTask', { namespace: tasksModule }) deleteTask: (id: string) => void
  @Action('createTask', { namespace: tasksModule }) createTask: (createdTask: object) => void
  @Action('logout', { namespace: authModule }) logout: () => {}

  numberOfValidNewTaskCharacters = 300

  numberOfItemsPerPage = 5

  currentAlertsPage = 1

  currentTasksPage = 1

  confidentialSwitch = false

  top: number = 0

  urlAgenda = URLS.AGENDA

  removeNoteTooltipText = this.$t('components.tooltip.delete_note')

  icons: object = {
    edit: Icons.EDIT,
    alert: Icons.ALERT_O,
    add: Icons.ADD,
    close: Icons.CLOSE
  }

  newTask: ControlSidebarTask = {
    id: '0',
    subject: '',
    startDate: '',
    completed: '0',
    confidential: ''
  }

  showTasksManager: boolean = false

  items: ControlSidebarItem[] = [
    {
      name: 'user',
      icon: Icons.USER
    },
    {
      name: 'alerts',
      icon: Icons.ALERT
    },
    {
      name: 'tasks',
      icon: Icons.TASKS
    },
    {
      name: 'options',
      icon: Icons.OPTIONS
    }
  ]

  @Emit()
  closeControlSidebar() {
    setTimeout(() => {
      this.showTasksManager = false
      this.resetNote()
    }, 1000)
  }

  closeControlSidebarEvent(e: Event) {
    const clickedElement = e.target as HTMLElement
    const elementMustOpenControlSidebar = clickedElement.classList.contains('open-control-sidebar')

    if (!elementMustOpenControlSidebar && this.opened) {
      this.closeControlSidebar()
    }
  }

  get getItems() {
    let filteredItems = JSON.parse(JSON.stringify(this.items))

    if (!hasPermission(SecurityVerbs.VIEW, SecurityCapacities.CONFIGURATION)) {
      filteredItems = filteredItems.filter((item: any) => item.name !== NavigationItems.OPTIONS)
    }
    if (!hasPermission(SecurityVerbs.VIEW, SecurityCapacities.TASK)) {
      filteredItems = filteredItems.filter((item: any) => item.name !== NavigationItems.TASKS)
    }
    if (!hasPermission(SecurityVerbs.VIEW, SecurityCapacities.ALERTS)) {
      filteredItems = filteredItems.filter((item: any) => item.name !== NavigationItems.ALERTS)
    }

    return filteredItems
  }

  get checkIfMainButtonIsDisabled() {
    return !this.newTask.subject.length || this.newTask.subject.length > this.numberOfValidNewTaskCharacters
  }

  get elementTopPosition() {
    return {
      top: `${this.top + 76}px`
    }
  }

  created() {
    window.addEventListener('scroll', this.changeElementTopPosition)
  }

  destroyed() {
    window.removeEventListener('scroll', this.changeElementTopPosition)
  }

  ellipsified(text: string) {
    const numberOfCharactersToShow = 32
    return text.length > numberOfCharactersToShow ? `${text.substring(0, numberOfCharactersToShow)}...` : text
  }

  calculateNumberOfPagesToPaginate(numberOfItems: string): boolean {
    return Number(numberOfItems) > this.numberOfItemsPerPage
  }

  formatURL(url: string) {
    return `${process.env.VUE_APP_URL}/${url}`
  }

  numberOfPages(type: string): number {
    let numberOfItems: string = ''
    if (type === 'alerts') {
      numberOfItems = this.alertsObject.pagination.totalRecs
    } else {
      numberOfItems = this.tasksObject.pagination.totalRecs
    }
    return Math.ceil(Number(numberOfItems) / this.numberOfItemsPerPage)
  }

  updateAlertsPage() {
    this.fetchAlertsPage({
      pageNumber: this.currentAlertsPage,
      pageSize: this.numberOfItemsPerPage
    })
  }

  updateTasksPage() {
    this.fetchTasksPage({
      pageNumber: this.currentTasksPage,
      pageSize: this.numberOfItemsPerPage
    })
  }

  calcNewAlertPagination() {
    const newTotalRecs = (this as any).alertsObject.pagination.totalRecs - 1
    const newTotalPages = Math.ceil(Number(newTotalRecs) / this.numberOfItemsPerPage)
    if (this.currentAlertsPage > newTotalPages) {
      this.currentAlertsPage -= 1
    }
  }

  async postponeAlert(alert: Alert): Promise<void> {
    const { id } = alert
    await this.postpone(id)
    this.calcNewAlertPagination()
    this.updateAlertsPage()
  }

  async deleteAlert(alert: Alert): Promise<void> {
    const { id } = alert
    await this.delete(id)
    this.calcNewAlertPagination()
    this.updateAlertsPage()
  }

  async changedTasksPage(page: number): Promise<void> {
    await this.fetchTasksPage({
      pageNumber: page,
      pageSize: this.numberOfItemsPerPage
    })
    this.currentTasksPage = page
  }

  async changedAlertsPage(page: number): Promise<void> {
    await this.fetchAlertsPage({
      pageNumber: page,
      pageSize: this.numberOfItemsPerPage
    })
    this.currentAlertsPage = page
  }

  changeElementTopPosition() {
    this.top = window.scrollY
  }

  resetNote(): void {
    this.newTask = {
      id: '0',
      subject: '',
      startDate: '',
      completed: '0',
      confidential: ''
    }
  }

  getCurrentDateFormatted() {
    const currentDate = new Date()
    const currentDateFormatted =
      currentDate.getFullYear() +
      '-' +
      ('0' + (currentDate.getMonth() + 1)).slice(-2) +
      '-' +
      ('0' + currentDate.getDate()).slice(-2) +
      ' ' +
      ('0' + currentDate.getHours()).slice(-2) +
      ':' +
      ('0' + currentDate.getMinutes()).slice(-2) +
      ':' +
      ('0' + currentDate.getSeconds()).slice(-2)

    return currentDateFormatted
  }

  async addNewTask() {
    if (this.newTask.subject) {
      this.newTask.startDate = this.getCurrentDateFormatted()
      this.newTask.confidential = this.confidentialSwitch ? '1' : '0'
      await this.createTask(this.newTask)
      await this.updateTasksPage()
      this.showTasksManager = false
      this.resetNote()
      this.confidentialSwitch = false
    }
  }

  changeSelectedItem(item: string) {
    this.$emit('change-selected-item', item)
  }

  closeTasksManager() {
    this.resetNote()
    this.showTasksManager = false
  }

  openTasksManager() {
    this.showTasksManager = true
  }

  closeSession() {
    resetTracker()
    this.logout()
  }

  async removeTask(task: Task) {
    await this.deleteTask(task.id)
    const newTotalRecs = (this as any).tasksObject.pagination.totalRecs - 1
    const newTotalPages = Math.ceil(Number(newTotalRecs) / this.numberOfItemsPerPage)
    if (this.currentTasksPage > newTotalPages) {
      this.currentTasksPage -= 1
    }
    this.updateTasksPage()
  }
}
</script>

<style lang="scss" scoped>
$tab-heigth: 44px;

.control-sidebar {
  @include borders($color: $white-01);
  position: absolute;
  right: -260px;
  width: 260px;
  min-height: 300px;
  max-height: 650px;
  color: $corporate-color;
  background-color: $blue-06;
  border-top: none;
  transition: right 0.3s ease-in-out;
  z-index: 4;

  .no-items-message {
    @include flex;
    font-family: $corporate-font-medium;
    font-size: 15px;
    color: $blue-04;
    margin-top: 40px;
  }

  .list {
    @include list;
  }

  &.open {
    right: 0;
  }

  .control-sidebar-tabs {
    @include flex;
    height: $tab-heigth;
    padding-right: 18px;

    &.customer-type {
      @include border($color: $white-01);
    }

    .item {
      @include border($color: transparent, $width: 2px);
      @include flex;
      width: 100%;
      height: $tab-heigth;
      cursor: pointer;

      &:hover {
        @include border($color: $corporate-color, $width: 2px);
      }

      .icon {
        font-size: 1.2em;
        color: $corporate-color;
        padding: 10px 15px;
      }

      &.active {
        @include border($color: $corporate-color, $width: 2px);

        .tab {
          cursor: default;
        }
      }
    }
  }

  .tabs-container {
    font-family: $corporate-font;
    padding: 6px 35px 10px 15px;

    .section-title {
      display: block;
      font-size: 18px;
      line-height: 20px;
      margin: 5px 5px 10px 5px;
    }

    .user-info {
      @include flex;
      flex-direction: column;
      text-transform: uppercase;
      text-align: center;
      padding: 10px 10px 0 10px;

      .purple-circle {
        position: relative;
        width: 100px;
        height: 100px;
        font-size: 28px;
        color: $blue-04;
        font-weight: 700;
        text-align: center;
        line-height: 100px;
        background-color: $white-01;
        margin: 0 auto 30px;
        border-radius: 50%;
      }

      .user-name {
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 6px;
        text-transform: uppercase;
      }

      .company-name {
        font-size: 12px;
        text-align: center;
        margin-bottom: 35px;
      }

      .close-button {
        @include main-action-button--rectangle;
        width: 180px;
        margin-bottom: 20px;
      }
    }

    .alerts-info {
      text-align: left;

      .alert-title {
        font-family: $corporate-font-medium;
        font-size: 14px;
      }

      .alerts-list {
        display: block;
        margin: 0 -15px 0 -8px;

        .alert-item {
          background-color: $white-01;
          font-size: 1em;
          line-height: 1.6em;
          color: $corporate-color;
          padding: 10px 0 2px 0;

          &:hover {
            @include border($direction: left);
            background-color: $blue-15;

            .alert-link {
              color: $white-01;
            }

            .action-button {
              color: $white-01;
            }
          }

          &:last-of-type .actions {
            border: none;
          }

          .alert-link {
            display: block;
            width: 100%;
            text-decoration: none;
            cursor: pointer;
          }

          .menu-icon {
            float: left;
            width: 35px;
            height: 35px;
            font-size: 20px;
            text-align: center;
            line-height: 20px;
            border-radius: 50%;
          }

          .alert-info {
            font-size: 12px;
            font-weight: 400;
            margin-left: 45px;

            .alert-date {
              font-family: $secondary-font;
              font-size: 11px;
              font-weight: bold;
              line-height: 12px;
              margin-bottom: 4px;
            }
          }

          .actions {
            @include border;
            font-size: 12px;
            padding: 0 0 0 30px;
            margin: 0 10px;

            .action-button {
              font-family: $corporate-font-bold;
              text-transform: uppercase;
              text-decoration: underline;
              padding: 2px 4px;
            }

            .action-button:nth-child(2) {
              margin-left: 12px;
            }
          }
        }
      }
    }

    .tasks-info {
      text-align: left;

      .add-task-container {
        @include flex($justify-content: space-between);
        margin-bottom: 5px;

        .add-task-button {
          width: 44px;
          height: 44px;
          line-height: 12px;
          background-color: $corporate-color;
          margin-bottom: 14px;
          border: 3px solid $blue-06;
          border-radius: 50%;

          &:hover {
            border-color: $blue-05;
          }
        }

        .add-task-icon {
          font-size: 18px;
          color: $white-01;
          padding-right: 1px;
        }
      }

      .tasks-container {
        @include scroll-styles($color: $corporate-color);
        max-height: 480px;
        overflow: auto;

        .task-card {
          @include border($width: 3px, $direction: top, $color: $blue-04);
          background-color: $white-01;
          margin-bottom: 1.2em;
          border-radius: 4px;
          word-wrap: break-word;

          .remove-card {
            @include flex($justify-content: flex-end);
            color: $gray-04;
            padding: 10px;

            .remove-task-button {
              font-size: 12px;
              color: $corporate-color;
              padding: 0 5px;
            }
          }

          .task-text {
            font-size: 12px;
            font-family: $secondary-font;
            color: $corporate-color;
            padding: 0 10px 5px;
          }
        }
      }

      .tasks-manager {
        margin-bottom: 15px;

        .tasks-textarea {
          @include scroll-styles;
          font-size: 12px;

          ::v-deep .v-input__slot {
            @include border($direction: top, $width: 3px, $color: $blue-05);
            border-radius: 4px;
          }

          ::v-deep .v-counter {
            color: $blue-04;
            font-weight: bold;
          }
        }

        ::v-deep .v-textarea {
          font-family: $secondary-font;
          border-radius: 0;

          &.v-text-field--enclosed .v-text-field__slot {
            padding-right: 2px;

            textarea {
              @include scroll-styles;
              color: $gray-01;
              margin: 0;

              &::placeholder {
                font-family: $secondary-font;
                color: $gray-02;
              }
            }
          }
        }

        .confidential-container {
          @include flex($justify-content: flex-start);
          margin: 0 0 18px;
        }

        .task-checkbox-label {
          font-family: $corporate-font-bold;
          padding-top: 3px;
          margin-left: 8px;
        }

        .tasks-manager-buttons-container {
          @include flex($justify-content: space-between);
        }

        .main-action-button {
          @include main-action-button--rectangle;
          min-width: 101px;
          height: 40px;
          padding: 0;

          &:first-of-type {
            margin-left: 0;
          }

          &:disabled {
            border-color: $blue-05;
            color: $blue-05;
            background-color: $blue-08;

            &:hover {
              background-color: transparent;
            }
          }
        }
      }
    }

    .config-info {
      background-color: $white-01;
      margin: 0 -6px;

      .config-item {
        position: relative;
        color: $corporate-color;
        height: 38px;
        padding: 0 8px;

        &:hover::after {
          content: '';
          position: absolute;
          width: 0;
          height: 0;
          top: 0;
          right: 0;
          border-top: 19px solid #ffffff;
          border-bottom: 19px solid #ffffff;
          border-left: 8px solid #5363a2;
        }

        &:last-child .config-link {
          border: none;
        }

        &:hover {
          background-color: $blue-15;

          .config-link {
            color: $white-01;
          }
        }
      }

      .config-link {
        @include border;
        display: flex;
        align-items: center;
        height: 38px;
        font-family: $corporate-font-medium;
        font-size: 14px;
        text-decoration: none;
      }
    }
  }

  .pagination {
    --pagination-btn-active: $corporate-color;
    --pagination-btn-active-border: $corporate-color;
    --pagination-btn-not-active-border: transparent;
    --pagination-icon-color: $corporate-color;
  }
}

//- Switch
.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  @include borders($width: 2px, $color: $gray-02);
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $blue-06;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  height: 13px;
  width: 13px;
  left: 3px;
  top: 1.5px;
  content: '';
  border: 2px solid $gray-02;
  transition: 0.4s;
}

input:checked + .slider {
  border-color: $corporate-color;
}

input:checked + .slider:before {
  border-color: $corporate-color;
  transform: translateX(18px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

@include untilTablet {
  .control-sidebar {
    padding-top: calc(#{$app-bar-height} - 20px);
  }
}
</style>
