export enum InvoiceMaxNumberFormFields {
  COMPANY = 'idCompany',
  EXERCISE = 'exercise',
  INVOICE_NUMBER = 'number',
  NEXT_NUMBER = 'nextInvoice',
  SEQUENCE = 'sequence'
}
export interface InvoiceMaxNumberFormFieldsType {
  idCompany: string
  exercise: number
  sequence: string
  number: number
}
