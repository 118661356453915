import { ActionTree } from 'vuex'
import { TemplateState, templateMutationTypes, Template } from '@/store/modules/template/templateTypes'
import { RootState, ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import { MainService } from '@/services/MainService'
import { ExternalService } from '@/services/ExternalService'
import { DialogTypes } from '@/store/modules/dialog/dialogTypes'
import { ErrorCodes } from '@/store/modules/errors/errorsTypes'
import { i18n } from '@/plugins/vue-i18n'

const dialogModule: string = ModuleNamespaces.DIALOG

export const actions: ActionTree<TemplateState, RootState> = {
  async fetchTemplateFields({ commit }, filter) {
    try {
      const url = '/template/fields/get'
      const { data } = await new MainService().postData(url, filter)
      commit(templateMutationTypes.FETCH_TEMPLATE_FIELDS, data)
    } catch (error) {}
  },
  async fetchTemplate({ commit }, id: number) {
    try {
      const url = `/template/${id}/get`
      const { data } = await new MainService().postData(url)
      commit(templateMutationTypes.SAVE_TEMPLATE, data)
    } catch (error) {}
  },
  async convertingToSFDT({}, formData: FormData) {
    try {
      const url = `${process.env.VUE_APP_SYNCFUSION_TEMPLATE_API}import`
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
      const { data } = await new ExternalService().postData(url, formData, config)
      return JSON.stringify(data)
    } catch (error) {
      return null
    }
  },
  async deleteTemplate({ commit, dispatch }, id: number) {
    try {
      const url = `/template/${id}/delete`
      await new MainService().deleteData(url)
      await dispatch(
        `${dialogModule}/showDialog`,
        {
          type: DialogTypes.SUCCESS,
          message: i18n.t('components.dialog.success_template_removed')
        },
        { root: true }
      )
      commit(templateMutationTypes.RESET_TEMPLATE)
    } catch (error) {
      if (error.response.data.exceptionCode === ErrorCodes.E400) {
        const errorMessage = error.response.data.exceptionDesc
        dispatch(
          `${dialogModule}/showDialog`,
          {
            type: DialogTypes.ERROR,
            message: errorMessage
          },
          { root: true }
        )
      }
    }
  },
  async saveDocument({ dispatch }, formData: FormData) {
    try {
      const url = '/repository/binary/file/save'
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
      await new MainService().postBody(url, formData, config)
      await dispatch(
        `${dialogModule}/showDialog`,
        {
          type: DialogTypes.SUCCESS,
          message: i18n.t('components.dialog.success_template_saved')
        },
        { root: true }
      )
    } catch (error) {}
  },
  async saveTemplate({ commit }, template: Template) {
    try {
      const url = '/template/save'
      const { data } = await new MainService().postData(url, template)
      commit(templateMutationTypes.SAVE_TEMPLATE_ID, Number(data.id))
    } catch (error) {}
  },
  async saveTemplateAndDocument({ commit, dispatch }, formData: FormData) {
    try {
      const url = '/template/save'
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
      const { data } = await new MainService().postBody(url, formData, config)
      commit(templateMutationTypes.SAVE_TEMPLATE_ID, Number(data.id))
      await dispatch(
        `${dialogModule}/showDialog`,
        {
          type: DialogTypes.SUCCESS,
          message: i18n.t('components.dialog.success_template_saved')
        },
        { root: true }
      )
    } catch (error) {}
  },
  async generateTemplate({}, filter) {
    try {
      const url = `/template/generate`
      const { data } = await new MainService().postData(url, filter)
      if (!filter.convertToPDF) {
        return JSON.stringify(data)
      } else {
        return data
      }
    } catch (error) {}
  },
  async fetchTemplateTree({ commit }, filter) {
    try {
      const url = `/template/tree`
      const { data } = await new MainService().postData(url, filter)
      if (!filter.id) {
        commit(templateMutationTypes.FETCH_TEMPLATE_TREE, data)
      }
      return data
    } catch (error) {}
  }
}
