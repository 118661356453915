import { GetterTree } from 'vuex'
import { LexboxCalendarData, LexboxState, EmailProvider } from '@/store/modules/lexbox/lexboxTypes'
import { RootState } from '@/store/types/storeGlobalTypes'

export const getters: GetterTree<LexboxState, RootState> = {
  getCalendarData(state): LexboxCalendarData | null {
    return state.calendarData
  },
  getCalendarProvider(state): string | null {
    return state.calendarProvider
  },
  getEmailProvider(state): EmailProvider | null {
    return state.emailProvider
  },
  getCalendarToken(_state, getters): string {
    const calendarData = getters.getCalendarData
    if (calendarData && calendarData.data && calendarData.data.token) {
      return calendarData.data.token
    }
    return ''
  },
  getCalendarServiceUrl(state) {
    return state.calendarServiceUrl
  },
  getEventCalendarServiceUrl(state) {
    return state.eventCalendarServiceUrl
  },
  getAccessToken(state) {
    return state.accessToken
  }
}
