<template lang="pug">

  div(class="custom-fields")
    SpinnerLayerComponent(v-if="loadingData" class="spinner-layer")
    template(v-else)
      FormGeneratorComponent(
        v-if="formSchema.length > 0 && fieldValues"
        :schema="formSchema"
        :fieldValues="fieldValues"
        :context="context"
        validateOnLoad
        :permissionsEntity="permissionsEntity"
        @saveFormData="onChangeFormFieldValue"
      )
      CustomFieldsEmptyMessageComponent(v-else)

</template>

<script lang="ts">
import CustomFieldsEmptyMessageComponent from '@/components/customFields/CustomFieldsCustomFieldsEmptyMessage/CustomFieldsEmptyMessageComponent.vue'
import FormGeneratorComponent from '@/components/forms/FormGenerator/FormGeneratorComponent.vue'
import SpinnerLayerComponent from '@/components/Spinner/SpinnerLayerComponent.vue'
import { Action, Getter } from 'vuex-class'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import { CustomFieldType } from '@/store/modules/customFields/customFieldsTypes'

const formsModule = ModuleNamespaces.FORMS
const entitiesModule = ModuleNamespaces.ENTITIES
const selectedRegisterModule = ModuleNamespaces.SELECTED_REGISTER
const customFieldsModule = ModuleNamespaces.CUSTOM_FIELDS

@Component({
  components: {
    CustomFieldsEmptyMessageComponent,
    FormGeneratorComponent,
    SpinnerLayerComponent
  }
})
export default class CustomFieldsTabComponent extends Vue {
  @Prop({
    type: String,
    required: true
  })
  context!: string

  @Prop({
    type: Number
  })
  permissionsEntity!: number

  @Getter('getSelectedIdEntityType', { namespace: entitiesModule })
  selectedIdEntityType: number

  @Getter('getSelectedRegisterId', { namespace: selectedRegisterModule })
  selectedRegisterId: (context: string) => number

  @Getter('getCustomFieldsForm', { namespace: customFieldsModule })
  getCustomFieldsForm: []

  @Getter('getCustomFieldsData', { namespace: customFieldsModule })
  getCustomFieldsData: []

  @Getter('checkIfFormIsValid', { namespace: formsModule })
  checkIfFormIsValid: (context: string) => boolean

  @Action('prepareRegisterFormDataToSave', { namespace: formsModule })
  prepareFormData: ({}) => Promise<void>

  @Action('fetchCustomFieldsForm', { namespace: customFieldsModule })
  fetchFormSchema: ({}) => Promise<void>

  @Action('fetchCustomFieldsData', { namespace: customFieldsModule })
  fetchFieldValues: ({}) => Promise<void>

  loadingData = true

  get fieldValues() {
    return this.getCustomFieldsData
  }

  get formSchema() {
    return this.getCustomFieldsForm
  }

  get isFormValid() {
    return this.checkIfFormIsValid(this.context)
  }

  @Watch('isFormValid')
  checkIsFormValid(value: boolean) {
    if (value) {
      this.$emit('enabledTabs')
    } else {
      this.$emit('disabledTabs')
    }
  }

  async created() {
    await this.fetchFormSchema({
      idEntityType: this.selectedIdEntityType,
      idEntity: this.selectedRegisterId(this.context)
    })
    await this.fetchFieldValues({
      idEntityType: this.selectedIdEntityType,
      idEntity: this.selectedRegisterId(this.context)
    })
    this.loadingData = false
  }

  async onChangeFormFieldValue(_schema: [], formData: object, _field: any, _value: any) {
    const formDataParsed: CustomFieldType[] = []
    Object.entries(formData).forEach((item) => {
      formDataParsed.push({ name: item[0], value: item[1] })
    })
    this.prepareFormData({ customFields: formDataParsed })
  }
}
</script>

<style lang="scss" scoped>
.custom-fields {
  .spinner-layer {
    --spinner-layer-min-height: 470px;
  }
}
</style>
