import { Module } from 'vuex'
import { SecurityState } from '@/store/modules/security/securityTypes'
import { RootState } from '@/store/types/storeGlobalTypes'

export const state: SecurityState = {
  security: null
}

const namespaced: boolean = true

export const securityModule: Module<SecurityState, RootState> = {
  namespaced,
  state
}
