import { ActionTree } from 'vuex'
import { AgenciesState, agenciesMutationTypes } from '@/store/modules/agencies/agenciesTypes'
import { RootState } from '@/store/types/storeGlobalTypes'
import { AgenciesService } from '@/services/AgenciesService'

export const actions: ActionTree<AgenciesState, RootState> = {
  async fetchAgencies({ commit }) {
    try {
      const url = `contacts/agencies`
      const response = await new AgenciesService().getData(url)
      const { data } = response
      commit(agenciesMutationTypes.FETCH_AGENCIES, data)
    } catch (error) {
      // commit(userMutationTypes.SET_USER_ERROR, 'errors.server_error')
    }
  },
  async fetchOneAgency({ commit }, id: number) {
    try {
      const url = `contacts/agency/${id}`
      const response = await new AgenciesService().getData(url)
      const { data } = response
      commit(agenciesMutationTypes.FETCH_ONE_AGENCY, data)
    } catch (error) {
      // commit(userMutationTypes.SET_USER_ERROR, 'errors.server_error')
    }
  }
}
