// https://vuetifyjs.com/en/
import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import { Icons } from '@/icons/icons'

Vue.use(Vuetify)

const LF_ICONS = {
  complete: '1..',
  cancel: '2..',
  close: '3..',
  delete: Icons.CLOSE_ROUND,
  clear: Icons.CLOSE_ROUND,
  success: '4..',
  info: '5..',
  warning: '6..',
  error: '7..',
  prev: '8..',
  next: '9..',
  checkboxOn: Icons.CHECK_FULL,
  checkboxOff: Icons.BOX_INACTIVE,
  checkboxIndeterminate: Icons.BOX_ACTIVE,
  delimiter: '11.', // for carousel
  sort: '12.',
  expand: Icons.ANGLE_DOWN,
  menu: '13.',
  subgroup: Icons.ANGLE_DOWN,
  dropdown: '15.',
  radioOn: '16.',
  radioOff: '17.',
  edit: '18.',
  ratingEmpty: '19.',
  ratingFull: '20.',
  ratingHalf: '21.',
  loading: '22.',
  first: '23.',
  last: '24.',
  unfold: '25.',
  file: Icons.UPLOAD
}

export enum VuetifyBreakpointNames {
  XS = 'xs',
  SM = 'sm',
  MD = 'md',
  LG = 'lg',
  XL = 'xl'
}

export default new Vuetify({
  icons: {
    values: LF_ICONS
  },
  theme: {
    themes: {
      light: {
        primary: '#001978',
        secondary: '#ffffff',
        alert: '#D81F2A'
      }
    }
  }
})
