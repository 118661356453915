<template lang="pug">

  transition-group(name="reorder-transition" tag="div")
    div(
      v-for="(item, index) in value"
      :key="item[itemId]"
      class="custom-field-container"
    )

      CustomFieldGeneratorComponent(
        :item="item"
        :position="index"
        @move-up="onMoveUp"
        @move-down="onMoveDown"
        @edit-field="onEditField"
        @remove-field="showWarningRemoveDialog"
      )

      CustomFieldActionButtonsComponent(
        v-if="showButtons"
        :index="index"
        :item="item"
        :fieldsNumber="value.length"
        @move-up="onMoveUp"
        @move-down="onMoveDown"
        @edit-field="onEditField"
        @remove-field="showWarningRemoveDialog(item)"
      )

</template>

<script lang="ts">
import { Vue, Component, Prop, Emit } from 'vue-property-decorator'
import CustomFieldGeneratorComponent from '@/components/customFields/customFieldsPlayground/CustomFieldGenerator/CustomFieldGeneratorComponent.vue'
import CustomFieldActionButtonsComponent from '@/components/customFields/customFieldsPlayground/CustomFieldActionButtons/CustomFieldActionButtonsComponent.vue'
import { Action, Mutation, Getter } from 'vuex-class'
import {
  CustomField,
  CustomFieldGroup,
  customFieldTypesEnum,
  CustomFieldsConfig,
  FieldsetPayload,
  moveDirection
} from '@/store/modules/customFields/customFieldsTypes'
import { ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import { DialogTypes } from '@/store/modules/dialog/dialogTypes'

const customFieldsModule: string = ModuleNamespaces.CUSTOM_FIELDS
const dialogModule: string = ModuleNamespaces.DIALOG

@Component({
  components: {
    CustomFieldGeneratorComponent,
    CustomFieldActionButtonsComponent
  }
})
export default class CustomFieldsListComponent extends Vue {
  @Prop({
    type: Array
  })
  value!: []

  @Prop({
    type: String,
    default: 'uuid'
  })
  itemId!: string

  @Prop({
    type: Boolean,
    default: true
  })
  showButtons!: string

  @Getter('getCustomFieldsConfig', { namespace: customFieldsModule })
  config: CustomFieldsConfig

  @Mutation('CHANGE_CUSTOM_FIELDS_CONFIG', { namespace: customFieldsModule })
  changeCustomFieldsConfig: (payload: any) => void
  @Mutation('TOGGLE_CUSTOM_FIELDS_FORM_DIALOG_FLAG_STATUS', { namespace: customFieldsModule })
  toggleCustomFieldsFormDialogStatus: () => void
  @Mutation('SET_CUSTOM_FIELD_FORM_DATA', { namespace: customFieldsModule })
  setFormCustomField: ({}) => void
  @Mutation('SET_CUSTOM_FIELD_SELECTED_DATA', { namespace: customFieldsModule })
  setSelectedCustomField: (customFieldData: CustomField | CustomFieldGroup) => void
  @Mutation('MODE_CUSTOM_FIELDS_FORM_DIALOG', { namespace: customFieldsModule })
  modeCustomFieldsFormDialog: (editMode: boolean) => void

  @Mutation('REMOVE_CUSTOM_FIELD', { namespace: customFieldsModule })
  removeCustomFields: (customFieldInfo: object) => void
  @Mutation('MOVE_CUSTOM_FIELD', { namespace: customFieldsModule })
  moveCustomFields: ({}) => void

  @Action('showDialog', { namespace: dialogModule })
  showDialogAction: ({}) => void

  @Emit()
  removeField(index: number) {
    return index
  }

  onMoveUp(payload: FieldsetPayload | number) {
    this.moveCustomFields({
      payload,
      direction: moveDirection.UP
    })
  }

  onMoveDown(payload: FieldsetPayload | number) {
    this.moveCustomFields({
      payload,
      direction: moveDirection.DOWN
    })
  }

  onEditField(payload: any) {
    const parentFieldset = this.getParentFieldset(payload.item.uuid) as CustomFieldGroup

    this.setFormCustomField({
      customFieldData: payload.item,
      parentFieldset
    })

    this.setSelectedCustomField(payload.item)
    this.toggleCustomFieldsFormDialogStatus()
    this.modeCustomFieldsFormDialog(true)
  }

  getParentFieldset(uuid: string) {
    return this.config.customFields.find((item: CustomField) => {
      return item.idCustomFieldType === customFieldTypesEnum.GROUP && (item as CustomFieldGroup).fields
        ? (item as CustomFieldGroup).fields.find((field: CustomField) => field.uuid === uuid)
        : undefined
    })
  }

  showWarningRemoveDialog(item: CustomFieldGroup | CustomField) {
    this.showDialogAction({
      type: DialogTypes.WARNING,
      message: this.$t('components.custom_fields_list.remove_warning_message'),
      action: () => {
        this.removeCustomFields({ field: item })
        this.removeField(item.idNumerationType)
      }
    })
  }
}
</script>

<style scoped lang="scss" scoped>
.custom-field-container {
  @include flex($justify-content: space-between);
}

@include reorder-transition;
</style>
