import { Module } from 'vuex'
import { getters } from '@/store/modules/audits/auditsGetters'
import { actions } from '@/store/modules/audits/auditsActions'
import { mutations } from '@/store/modules/audits/auditsMutations'
import { AuditsState } from '@/store/modules/audits/auditsTypes'
import { RootState } from '@/store/types/storeGlobalTypes'

export const state: AuditsState = {
  audits: null
}

const namespaced: boolean = true

export const auditsModule: Module<AuditsState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations
}
