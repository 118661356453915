import { GetterTree } from 'vuex'
import { TemplateState } from '@/store/modules/template/templateTypes'
import { RootState } from '@/store/types/storeGlobalTypes'

export const getters: GetterTree<TemplateState, RootState> = {
  getTemplateFields(state) {
    return state.templateFields
  },
  getTemplateTree(state) {
    return state.templateTree
  },
  getTemplateTreeEntityType(state) {
    return state.templateTreeEntityType
  },
  showTemplateGenerator(state) {
    return state.showTemplateGenerator
  },
  getTemplate(state) {
    return state.template
  },
  getTemplateId(state) {
    return state.templateId
  }
}
