<template lang="pug">
  div(class="document-form")
    TabHeaderComponent(
        @closeForm="onCloseForm"
        @saveForm="onSaveForm"
        @removeItem="onRemoveItem"
        :buttons="buttons"
        :title="formTitle"
        class="tab-header"
      )

    FormGeneratorComponent(
      v-if="formSchema"
      :key="componentKey"
      :schema="formSchema"
      :fieldValues="fieldValues"
      :context="context"
      validateOnLoad
      @saveFormData="onChangeFormFieldValue"
    )
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'
import { Action, Getter, Mutation } from 'vuex-class'
import FormGeneratorComponent from '@/components/forms/FormGenerator/FormGeneratorComponent.vue'
import TabHeaderComponent from '@/components/tabs/TabHeader/TabHeaderComponent.vue'
import { ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import { Icons } from '@/icons/icons'
import { ActionName } from '@/components/ActionsBar/types/ActionBarComponentTypes'
import { ConfigurationTypes } from '@/store/modules/configuration/configurationTypes'
import { Template, templateFields } from '@/store/modules/template/templateTypes'
import { DialogTypes } from '@/store/modules/dialog/dialogTypes'

const formsModule: string = ModuleNamespaces.FORMS
const configurationModule: string = ModuleNamespaces.CONFIGURATION
const templateModule: string = ModuleNamespaces.TEMPLATE
const dialogModule: string = ModuleNamespaces.DIALOG

@Component({
  components: {
    FormGeneratorComponent,
    TabHeaderComponent
  }
})
export default class FileManagerEditTemplateComponent extends Vue {
  @Prop({
    type: String,
    required: true
  })
  context!: string

  @Prop({
    type: String
  })
  basePath!: string

  @Prop({
    type: Number
  })
  templateType!: number

  @Prop({
    type: Number
  })
  id!: number

  @Getter('checkIfFormIsValid', { namespace: formsModule })
  checkIfFormIsValid: (context: string) => boolean
  @Getter('getCurrentViewConfiguration', { namespace: configurationModule })
  currentViewConfiguration: (context: string) => []
  @Getter('getTemplate', { namespace: templateModule })
  template: Template
  @Getter('getTemplateId', { namespace: templateModule })
  templateId: number

  @Action('fetchCurrentViewConfiguration', { namespace: configurationModule })
  fetchingCurrentViewConfiguration: ({}) => Promise<{}>
  @Action('saveTemplate', { namespace: templateModule })
  saveTemplate: ({}) => Promise<{}>
  @Action('fetchTemplate', { namespace: templateModule })
  fetchTemplate: (templateId: number) => Template
  @Action('deleteTemplate', { namespace: templateModule })
  deleteTemplate: (templateId: number) => void
  @Action('showDialog', { namespace: dialogModule })
  showingDialog: ({}) => {}

  @Mutation('SHOW_HIDE_TEMPLATE_GENERATOR', { namespace: templateModule })
  showHideTemplateGenerator: (show: boolean) => void
  @Mutation('RESET_TEMPLATE', { namespace: templateModule })
  resetTemplate: () => void
  @Mutation('SET_FORM_IS_VALID', { namespace: formsModule })
  setFormIsValid: (context: string) => void

  formData: any = {}

  componentKey: number = 0

  loadingData: boolean = false

  editMode: boolean = false

  texts = {
    buttons: {
      close: this.$t('action_buttons.close'),
      remove: this.$t('action_buttons.remove'),
      save: this.$t('action_buttons.save')
    },
    formTitles: {
      editDetail: this.$t('components.file_manager.actions.edit_template'),
      newDetail: this.$t('components.file_manager.actions.new_template')
    }
  }

  get buttons() {
    const buttons = [
      {
        icon: Icons.REMOVE,
        tooltip: this.texts.buttons.remove,
        action: ActionName.REMOVE,
        show: true
      },
      {
        icon: Icons.CLOSE,
        tooltip: this.texts.buttons.close,
        class: 'red-color',
        action: ActionName.CLOSE,
        show: true
      },
      {
        icon: Icons.CHECK,
        tooltip: this.texts.buttons.save,
        class: 'green-color',
        action: ActionName.SAVE,
        show: this.checkIfFormIsValid(this.context)
      }
    ]

    return this.editMode ? buttons : buttons.filter((button) => button.icon !== Icons.REMOVE)
  }

  get formTitle() {
    return this.editMode ? this.texts.formTitles.editDetail : this.texts.formTitles.newDetail
  }

  get formSchema() {
    return this.currentViewConfiguration(this.context)
  }

  get fieldValues() {
    return this.formData
  }

  set fieldValues(value) {
    this.formData = value
  }

  created() {
    this.editMode = !!this.id
    this.init()
  }

  async init() {
    await this.fetchingCurrentViewConfiguration({
      objectType: ConfigurationTypes.VIEW,
      alias: `${this.context}Form`,
      context: this.context
    })

    if (this.editMode) {
      await this.fetchTemplate(this.id)
      this.fieldValues = this.template

      const idEntityTypeField: any = this.formSchema.find((item: any) => item.id === templateFields.ID_ENTITY_TYPE)

      if (idEntityTypeField) {
        idEntityTypeField.disabled = true
      }

      setTimeout(() => {
        this.setFormIsValid(this.context)
      })
    } else {
      this.fieldValues.idEntityType = this.templateType
    }

    await this.$nextTick()
    this.componentKey += 1
    this.loadingData = true
  }

  onChangeFormFieldValue() {}

  async onSaveForm() {
    this.fieldValues.id = this.fieldValues.id ? this.fieldValues.id : 0
    this.fieldValues.path = this.basePath
    this.fieldValues.extension = 'docx'
    this.fieldValues.ArchivoInicial = `${this.fieldValues.name}.${this.fieldValues.extension}`

    await this.saveTemplate(this.fieldValues)
    this.showHideTemplateGenerator(true)
    this.onCloseForm()
  }

  onRemoveItem() {
    this.showingDialog({
      type: DialogTypes.INFO,
      message: this.$t('components.dialog.remove_template'),
      action: async () => {
        await this.deleteTemplate(this.templateId)
        this.onCloseForm()
      }
    })
  }

  async onCloseForm() {
    this.$emit('close')
    this.loadingData = true
    this.editMode = false
    this.resetFormData()
  }

  resetFormData() {
    this.fieldValues = {}
  }
}
</script>

<style lang="scss" scoped>
.tab-header-container {
  margin-top: 20px;
}
</style>
