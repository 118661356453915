export enum LexnetContextMenuId {
  PROCEDURE = 'procedure',
  LEXNET = 'lexnet',
  SUBJECT = 'subject',
  EXECUTION = 'execution',
  DEFENSE = 'defense',
  PERSONATION = 'personation',
  APPEAL = 'appeal'
}

export enum LexnetEscritoId {
  PROCEDURE = 2,
  SUBJECT = 6,
  EXECUTION = 7,
  PERSONATION = 14
}
