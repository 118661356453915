import { Module } from 'vuex'
import { getters } from '@/store/modules/notifications/notificationsGetters'
import { actions } from '@/store/modules/notifications/notificationsActions'
import { mutations } from '@/store/modules/notifications/notificationsMutations'
import { NotificationsState } from '@/store/modules/notifications/notificationsTypes'
import { RootState } from '@/store/types/storeGlobalTypes'

export const state: NotificationsState = {
  status: null,
  data: {},
  loginViewNotification: {}
}

const namespaced: boolean = true

export const notificationsModule: Module<NotificationsState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations
}
