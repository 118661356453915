export interface DashboardListProps {
  items: DashboardExpedient[] | DashboardAction[] | DashboardInvoice[] | DashboardCustomer[] | DashboardDocument[]
  entity: string
  headers: string[]
  headerText: string
  noResultIcon?: string
  female?: boolean
  url?: string
  goToAllText?: string
  showSpinner?: boolean
  headerIcon?: string
  columnClasses?: string[]
}

export interface DashboardExpedient {
  canDelete: string
  canEdit: string
  customerName: string
  description: string
  idExpedient: string
  idOwnFile: string
  modifiedDate: string
  startDate: string
}

export interface DashboardCustomer {
  id: string
  name: string
  phone: string
  email: string
}

export interface DashboardRecentActivityItem {
  title: string
  date: string
  hour: string
  entity: string
}

export interface DashboardAction {
  actionId: string
  typeId: string
  dateActionAt: string
  subject: string
  files: string
  canEdit: string
  canDelete: string
}

export interface DashboardDocument {
  creationDate: string
  modificationDate: string
  name: string
  type: string
  idEntity: number
  idEntityType: string
  basePath: string
  path: string
  initialFile: string
}
export interface DashboardInvoice {
  customer: string
  description: string
  invoiceDate: string
  idInvoice: string
  totalInvoice: string
  canEdit: string
  canDelete: string
}

export interface DashboardResumeItem {
  text: string
  qty: string
}

export interface DashboardChartBarItem {
  month: string
  hoursInvoiced?: number
  hoursWorked?: number
  qty?: number
  color?: string
}

export interface DashboardChartDonutItem {
  data: string
  label: string
  qty?: number
}

export interface DashboardWidgetItem {
  id: string
  dataRow: string
  dataRowSmAndMd: string
  dataCol: string
  dataSizeX: string
  dataSizeY: string
  dataSizeYSmAndMd: string
  renderedComponent: string
  text: string
  visible: number
  enabled: number
}

export interface DashboardWidgetsUserConfiguration {
  widgets: DashboardWidgetItem[]
  userSettings: {
    allowDragging: boolean
  }
}

export interface DashboardWidgetsState {
  dashboardAmountOutstandingResume: DashboardResumeItem[]
  dashboardCustomerResume: DashboardResumeItem[]
  dashboardCustomersCounterChart: DashboardChartBarItem[]
  dashboardExpedientResume: DashboardResumeItem[]
  dashboardExpedientsCounterChart: DashboardChartBarItem[]
  dashboardInactiveExpedientsList: DashboardExpedient[]
  dashboardInvoicedAmountsResume: DashboardResumeItem[]
  dashboardLastActionsList: DashboardAction[]
  dashboardLastCustomersList: DashboardCustomer[]
  dashboardLastDocumentsList: DashboardDocument[]
  dashboardLastExpedientsList: DashboardExpedient[]
  dashboardOfficeDailyWorkingTimeResume: DashboardResumeItem[]
  dashboardOfficeMonthlyWorkingTimeResume: DashboardResumeItem[]
  dashboardOfficeWeeklyWorkingTimeResume: DashboardResumeItem[]
  dashboardRecentActivityItems: DashboardRecentActivityItem[]
  dashboardUnpaidInvoicesList: DashboardInvoice[]
  dashboardUserDailyWorkingTimeResume: DashboardResumeItem[]
  dashboardUserMonthlyWorkingTimeResume: DashboardResumeItem[]
  dashboardUserWeeklyWorkingTimeResume: DashboardResumeItem[]
  dashboardWidgetsConfiguration: object
  dashboardWidgetsConfigurationLoadedFlag: boolean
}

export const dashboardWidgetsMutationTypes = {
  SET_DASHBOARD_AMOUNT_OUTSTANDING_RESUME: 'SET_DASHBOARD_AMOUNT_OUTSTANDING_RESUME',
  SET_DASHBOARD_CUSTOMERS_COUNTER_CHART: 'SET_DASHBOARD_CUSTOMERS_COUNTER_CHART',
  SET_DASHBOARD_CUSTOMER_RESUME: 'SET_DASHBOARD_CUSTOMER_RESUME',
  SET_DASHBOARD_EXPEDIENTS_COUNTER_CHART: 'SET_DASHBOARD_EXPEDIENTS_COUNTER_CHART',
  SET_DASHBOARD_EXPEDIENT_RESUME: 'SET_DASHBOARD_EXPEDIENT_RESUME',
  SET_DASHBOARD_INACTIVE_EXPEDIENTS: 'SET_DASHBOARD_INACTIVE_EXPEDIENTS',
  SET_DASHBOARD_INVOICED_AMOUNTS_RESUME: 'SET_DASHBOARD_INVOICED_AMOUNTS_RESUME',
  SET_DASHBOARD_LAST_ACTIONS_LIST: 'SET_DASHBOARD_LAST_ACTIONS_LIST',
  SET_DASHBOARD_LAST_CUSTOMERS_LIST: 'SET_DASHBOARD_LAST_CUSTOMERS_LIST',
  SET_DASHBOARD_LAST_DOCUMENTS_LIST: 'SET_DASHBOARD_LAST_DOCUMENTS_LIST',
  SET_DASHBOARD_LAST_EXPEDIENTS_LIST: 'SET_DASHBOARD_LAST_EXPEDIENTS_LIST',
  SET_DASHBOARD_OFFICE_DAILY_WORKING_TIME_RESUME: 'SET_DASHBOARD_OFFICE_DAILY_WORKING_TIME_RESUME',
  SET_DASHBOARD_OFFICE_MONTHLY_WORKING_TIME_RESUME: 'SET_DASHBOARD_OFFICE_MONTHLY_WORKING_TIME_RESUME',
  SET_DASHBOARD_OFFICE_WEEKLY_WORKING_TIME_RESUME: 'SET_DASHBOARD_OFFICE_WEEKLY_WORKING_TIME_RESUME',
  SET_DASHBOARD_RECENT_ACTIVITY: 'SET_DASHBOARD_RECENT_ACTIVITY',
  SET_DASHBOARD_UNPAID_INVOICES_LIST: 'SET_DASHBOARD_UNPAID_INVOICES_LIST',
  SET_DASHBOARD_USER_DAILY_WORKING_TIME_RESUME: 'SET_DASHBOARD_USER_DAILY_WORKING_TIME_RESUME',
  SET_DASHBOARD_USER_MONTHLY_WORKING_TIME_RESUME: 'SET_DASHBOARD_USER_MONTHLY_WORKING_TIME_RESUME',
  SET_DASHBOARD_USER_WEEKLY_WORKING_TIME_RESUME: 'SET_DASHBOARD_USER_WEEKLY_WORKING_TIME_RESUME',
  SET_DASHBOARD_WIDGETS_CONFIGURATION: 'SET_DASHBOARD_WIDGETS_CONFIGURATION',
  SET_DASHBOARD_WIDGETS_CONFIGURATION_LOADED_FLAG: 'SET_DASHBOARD_WIDGETS_CONFIGURATION_LOADED_FLAG'
}
