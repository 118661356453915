export interface Agency {
  id: number
  name: string
  dni: string | null
  reference: string | null
  address: string | null
  postalCode: string | null
  town: string | null
  idProvince: number | null
  province: string | null
  idCountry: number | null
  country: string | null
  phone: string | null
  mobilePhone: string | null
  fax: string | null
  email: string | null
  web: string | null
  notes: string | null
  idEntry: string | null
  UID: string | null
  createdBy: number | null
  createdDate: string | null
  modifiedBy: number | null
  modifiedDate: string | null
}

export interface AgenciesState {
  agencies: Agency[]
  selectedAgency: Agency | null
}

export interface AgencyFormatted {
  id: number
  name: string
  province: string | null
  mobilePhone: string | null
  email: string | null
  entity: string
}

export const agenciesMutationTypes = {
  FETCH_AGENCIES: 'FETCH_AGENCIES',
  FETCH_ONE_AGENCY: 'FETCH_ONE_AGENCY',
  RESET_SELECTED_AGENCY: 'RESET_SELECTED_AGENCY'
}
