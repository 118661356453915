import Vue from 'vue'
import { DirectiveBinding } from 'vue/types/options'
import { Security, SecurityVerbs } from '@/store/modules/security/securityTypes'

Vue.directive('security', {
  inserted: (el: HTMLElement, binding: DirectiveBinding) => {
    const security = new Security()
    let verb: string = SecurityVerbs.VIEW

    if (binding.modifiers) {
      verb = security.getVerbFromModifiers(binding.modifiers)
    }

    const capacity: string = binding.value
    security.executeDomSecurity(el, verb, capacity)
  }
})
