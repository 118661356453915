import { createElement } from '@syncfusion/ej2-base'
import { TextBox } from '@syncfusion/ej2-inputs'
import { i18n } from '@/plugins/vue-i18n'
import { numberToHourFormat } from '@/helpers/helpers'
import { inputInstance, setInputInstance } from '@/components/grids/CustomFilters/sharedVariables'
import { IFilter } from '@syncfusion/ej2-grids'

const customFilerDuration: IFilter = {
  ui: {
    create: (args: any) => {
      const flValInput = createElement('input', { className: 'flm-input' })
      flValInput.setAttribute('placeholder', i18n.t('components.grid_table.enter_search').toString())
      args.target.appendChild(flValInput)
      const inputInstance = new TextBox({})
      inputInstance.appendTo(flValInput)
      setInputInstance(inputInstance)
    },
    write: (args: any) => {
      if (args.filteredValue && args.filteredValue > 0) {
        inputInstance.value = numberToHourFormat(args.filteredValue)
      } else {
        inputInstance.value = ''
      }
    },
    read: (args: any) => {
      let value: number = -1
      if (/^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/.test(inputInstance.value)) {
        const aux = inputInstance.value.split(':')
        value = aux[0] * 60 + parseInt(aux[1], 10)
      } else if (/\d+/.test(inputInstance.value)) {
        value = inputInstance.value
      }
      args.fltrObj.filterByColumn(args.column.field, args.operator, value)
    }
  }
}

export default function getCustomFilerDuration() {
  return customFilerDuration
}
