import { ActionTree } from 'vuex'
import { ExternalService } from '@/services/ExternalService'
import { LexboxState, lexboxMutationTypes, calendarEndpoints, CalendarParams } from '@/store/modules/lexbox/lexboxTypes'
import { MainService } from '@/services/MainService'
import { ModuleNamespaces, RootState } from '@/store/types/storeGlobalTypes'
import { URLS } from '@/router/routes/urlRoutes'

const authModule: string = ModuleNamespaces.AUTH

export const actions: ActionTree<LexboxState, RootState> = {
  async fetchCalendarProvider({ commit, rootGetters }) {
    try {
      const url = String.format(`${process.env.VUE_APP_LEXBOX_PROVIDER}`, rootGetters[`${authModule}/getProUserId`])
      const { data } = await new ExternalService().getData(url)
      if (data.data.configCalendar && data.data.configCalendar.provider) {
        commit(lexboxMutationTypes.FETCH_CALENDAR_PROVIDER, data.data.configCalendar.provider)
      } else if (data.data.provider) {
        commit(lexboxMutationTypes.FETCH_CALENDAR_PROVIDER, data.data.provider)
      } else {
        commit(lexboxMutationTypes.RESET_CALENDAR_PROVIDER)
      }
    } catch (error) {
      commit(lexboxMutationTypes.RESET_CALENDAR_PROVIDER)
    }
  },

  async fetchEmailProvider({ commit, rootGetters }) {
    try {
      const url = String.format(`${process.env.VUE_APP_LEXBOX_PROVIDER}`, rootGetters[`${authModule}/getProUserId`])
      const { data } = await new ExternalService().getData(url)
      if (data.data.provider) {
        commit(lexboxMutationTypes.FETCH_EMAIL_PROVIDER, data.data)
      } else {
        commit(lexboxMutationTypes.RESET_EMAIL_PROVIDER)
      }
    } catch (error) {
      commit(lexboxMutationTypes.RESET_EMAIL_PROVIDER)
    }
  },

  async fetchAccessToken({ commit }, params) {
    try {
      const url = `${process.env.VUE_APP_LEXBOX_GET_ACCESS_TOKEN}`
      const { data } = await new MainService().putData(url, params)
      commit(lexboxMutationTypes.FETCH_ACCESS_TOKEN, data)
    } catch (error) {
      commit(lexboxMutationTypes.RESET_CALENDAR_PROVIDER)
    }
  },

  async fetchCalendarData({ commit }, params) {
    try {
      let url = `${process.env.VUE_APP_LEXBOX_GET_TOKEN_NEW}`
      if (params.idEvent && params.idEvent !== '') {
        url = `${process.env.VUE_APP_LEXBOX_GET_TOKEN_OPEN}`
      }
      if (params.origin && params.origin === URLS.AGENDA) {
        url = `${process.env.VUE_APP_LEXBOX_GET_TOKEN}`
      }
      delete params.origin
      const { data } = await new MainService().putData(url, params)
      commit(lexboxMutationTypes.FETCH_CALENDAR_DATA, data)
    } catch (error) {
      commit(lexboxMutationTypes.RESET_CALENDAR_DATA)
    }
  },
  async fetchCalendarServiceUrl({ commit, dispatch, getters, rootGetters }, calendarParams: CalendarParams) {
    let url = null
    try {
      const params = {
        ...calendarParams.query,
        idClienteNavision: rootGetters[`${authModule}/getProUserId`],
        bbdd: rootGetters[`${authModule}/getCompanyDataBase`],
        idCompany: rootGetters[`${authModule}/getCompanyId`],
        env: process.env.VUE_APP_LEXBOX_ENV ? process.env.VUE_APP_LEXBOX_ENV : ''
      }
      let provider: string | null = calendarParams.provider
      if (calendarParams.provider === null || calendarParams.provider === '') {
        await dispatch(`fetchCalendarProvider`)
        provider = getters.getCalendarProvider
      } else {
        commit(lexboxMutationTypes.FETCH_CALENDAR_PROVIDER, calendarParams.provider)
      }

      if (provider !== null) {
        const endpoint = (calendarEndpoints as any)[provider]
        await dispatch(`fetchCalendarData`, params)
        const token = getters.getCalendarToken
        url = `${endpoint.url}${token}${endpoint.params}`
      } else {
        url = null
      }
    } catch (error) {
      url = null
    }

    if (calendarParams.query === null || (calendarParams as any).query.origin === 'LexboxCalendarComponent') {
      commit(lexboxMutationTypes.SAVE_CALENDAR_SERVICE_URL, url)
    } else {
      commit(lexboxMutationTypes.SAVE_EVENT_CALENDAR_SERVICE_URL, url)
    }
  }
}
