import { http } from '@/plugins/axios'

export class ActionsService {
  constructor() {}

  getData(url: string) {
    return http.get(url)
  }

  postData(url: string, data: string) {
    const params = new URLSearchParams()
    params.append('json', data)
    return http.post(url, params)
  }
}
