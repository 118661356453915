<template lang="pug">

  ejs-toolbar(id="magnificationToolbar")
    e-items
      e-item(
        :prefixIcon="icons.fitPage"
        id="fitPage"
        :tooltipText="texts.fitPage"
        :click="pageFitEvent"
      )
      e-item(
        :prefixIcon="icons.zoomIn"
        id="zoomIn"
        :tooltipText="texts.zoomIn"
        :click="zoomInEvent"
      )
      e-item(
        :prefixIcon="icons.zoomOut"
        id="zoomOut"
        :tooltipText="texts.zoomOut"
        :click="zoomOutEvent"
      )

</template>

<script lang="ts">
import { Vue, Component, Emit } from 'vue-property-decorator'
import { Icons } from '@/icons/icons'

@Component
export default class PdfPreviewMagnificationComponent extends Vue {
  icons = {
    fitPage: Icons.COLLAPSE,
    zoomIn: Icons.ADD,
    zoomOut: Icons.LESS
  }

  texts = {
    fitPage: this.$t('components.pdf_viewer.fit_to_page'),
    zoomIn: this.$t('components.pdf_viewer.zoom_in'),
    zoomOut: this.$t('components.pdf_viewer.zoom_out')
  }

  @Emit()
  pageFitEvent() {}

  @Emit()
  zoomInEvent() {}

  @Emit()
  zoomOutEvent() {}
}
</script>

<style lang="scss">
.e-filemanager {
  #magnificationToolbar.e-toolbar {
    position: absolute;
    right: 15px;
    bottom: 75px;
    background-color: transparent !important;
    z-index: 9;

    .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item:first-child {
      margin: 0;
    }

    .e-toolbar-items {
      display: flex;
      flex-direction: column;
      height: 120px !important;
      background-color: transparent;

      .e-toolbar-item {
        background-color: transparent;
      }

      .e-icon-btn {
        @include flex;
        @include borders($color: $gray-02);
        height: 30px !important;
        width: 30px !important;
        border-radius: 50% !important;
        background-color: $white-01 !important;

        &:hover {
          border-radius: 50%;
          border-color: $corporate-color !important;
        }

        .e-btn-icon.e-icons {
          font-size: 12px;
          padding: 5px;
          color: $gray-01 !important;
          background-color: $white-01 !important;
        }
      }
    }
  }
}
</style>
