import { GetterTree } from 'vuex'
import { SearchState } from '@/store/modules/search/searchTypes'
import { RootState } from '@/store/types/storeGlobalTypes'

export const getters: GetterTree<SearchState, RootState> = {
  getSearchData(state) {
    return state.data
  },
  checkIfItsSearchRoute(state) {
    return state.isSearchView
  },
  getSearchTerm(state) {
    return state.searchTerm
  }
}
