<template lang="pug">

  div(v-if="loadedData" :class="['widget-base-component', customClass]")
    div(v-resize="calculateMainHeight" class="main-section")
      header(v-if="showHeader")
        span(:class="[headerIcon, 'header-icon']")
        slot(name="header")
      main
        slot(name="body")
    footer(v-if="showFooter")
      slot(name="footer")

  SpinnerLayerComponent(v-else class="spinner-layer")

</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'
import SpinnerLayerComponent from '@/components/Spinner/SpinnerLayerComponent.vue'

@Component({
  components: {
    SpinnerLayerComponent
  }
})
export default class WidgetBaseComponent extends Vue {
  @Prop({
    type: String
  })
  headerIcon!: string

  @Prop({
    type: Boolean,
    default: true
  })
  loadedData!: boolean

  @Prop({
    type: Boolean,
    default: true
  })
  showFooter!: boolean

  @Prop({
    type: String
  })
  customClass!: string

  @Prop({
    type: Boolean,
    default: true
  })
  showHeader!: boolean

  calculateMainHeight() {
    setTimeout(() => {
      const height = this.$el.clientHeight
      const elementWithScroll: HTMLElement | null = this.$el.querySelector('.element-with-scroll')

      if (elementWithScroll) {
        elementWithScroll.style.height = `${height - 120}px`
      }
    })
  }
}
</script>

<style lang="scss" scoped>
.widget-base-component {
  @include flex($flex-direction: column, $justify-content: space-between);
  width: 100%;
  height: 100%;
  background-color: $white-01;
  padding-top: 0.5em;
  box-shadow: 0 0 4px 1px rgba(86, 86, 86, 0.3);
  border-radius: $widget-border-radius;

  .main-section {
    width: 100%;
    height: calc(100% - 50px);
  }

  header {
    @include flex($justify-content: flex-start);
    width: 100%;
    min-height: 43px;
    color: $corporate-color;
    font-family: $corporate-font-bold;
    font-size: 13px;
    padding: 0 1em;

    .header-icon {
      font-size: 2.5em;
      margin-right: 10px;
    }
  }

  main {
    @include flex($align-items: flex-start);
    width: 100%;
    height: 100%;
    margin-top: 0.3em;
  }

  footer {
    @include flex($justify-content: space-between);
    width: 100%;
    height: 40px;
    background-color: $blue-06;
    font-family: $corporate-font-bold;
    font-size: 12px;
    color: $corporate-color;
    font-weight: bold;
    text-transform: uppercase;
    padding: 1%;
    z-index: 1;
    border-radius: 0 0 $widget-border-radius $widget-border-radius;
  }

  &.dashboard-widget {
    padding: 0;

    .main-section {
      height: 100%;
    }

    main {
      @include flex;
      margin: 0;
    }
  }
}

.spinner-layer {
  background-color: $white-01;
  border-radius: $widget-border-radius;
}
</style>
