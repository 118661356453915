import { ActionTree } from 'vuex'
import { RootState } from '@/store/types/storeGlobalTypes'
import { EndpointState, Endpoint, endpointsMutationTypes, endpoints } from '@/store/modules/endpoint/endpointTypes'
import { Entity } from '@/store/modules/entities/entitiesTypes'
import { getObjectByKey } from '@/helpers/object'

export const getEndPointsByEntityAlias = (alias: string) => {
  const entityEndpoints: any = getObjectByKey(endpoints, alias)
  return Object.assign({}, entityEndpoints) as Endpoint
}

export const updateEndpoint = (endpoint: string, entity: Entity): string => {
  const match = endpoint.match(/{(.*?)}/g)
  if (match) {
    const found = match.map((token) => {
      let field = token.replace('{', '')
      field = field.replace('}', '')
      const parts = field.split('.')
      let value = ''
      if (parts.length === 2 && Object.keys(entity).includes(parts[1])) {
        value = (entity as any)[parts[1]]
      }
      return { token, value }
    })
    if (found) {
      found.forEach((item: any) => {
        endpoint = endpoint.replace(item.token, item.value)
      })
    }
  }
  return endpoint
}

export const actions: ActionTree<EndpointState, RootState> = {
  saveEndpointsByEntity({ commit }, entity: Entity) {
    let entityEndpoints: Endpoint = getEndPointsByEntityAlias(entity.alias)

    if (!Object.keys(entityEndpoints).length) {
      entityEndpoints = getEndPointsByEntityAlias(entity.parent.alias)
    }

    if (entityEndpoints) {
      entityEndpoints.get = updateEndpoint(entityEndpoints.get, entity)
      entityEndpoints.list = updateEndpoint(entityEndpoints.list, entity)
      entityEndpoints.save = updateEndpoint(entityEndpoints.save, entity)
      entityEndpoints.delete = updateEndpoint(entityEndpoints.delete, entity)
    }
    commit(endpointsMutationTypes.SAVE_ENDPOINTS, {
      endpoints: entityEndpoints,
      entityType: entity.type
    })
  },
  async saveEndpoints({ commit }, { endpoints, entityType }) {
    commit(endpointsMutationTypes.SAVE_ENDPOINTS, {
      endpoints,
      entityType
    })
  }
}
