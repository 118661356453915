import he from 'he'
import DOMPurify from 'dompurify'

export const stripHtml = (html: string): string => {
  const tmp = document.createElement('DIV')
  tmp.innerHTML = html
  return tmp.textContent || tmp.innerText || ''
}

export const removeBodyScroll = (): void => {
  document.body.style.overflow = 'hidden'
  document.body.style.position = 'fixed'
}

export const resetBodyScroll = (): void => {
  document.body.style.overflow = 'visible'
  document.body.style.position = 'initial'
}

const removeNode = (elem: Element, parent: HTMLHeadElement | Document | null = null) => {
  if (elem.parentElement) {
    if (parent === null) {
      parent = elem.parentElement
    }
    parent.removeChild(elem)
  }
}

export const removeNodeByQuerySelectorAll = (
  selector: string = '',
  parent: HTMLHeadElement | Document | null = null
) => {
  if (selector) {
    const elems: NodeListOf<Element> = document.querySelectorAll(selector)
    if (elems !== null && elems.length) {
      elems.forEach((elem: Element) => {
        removeNode(elem, parent)
      })
    }
  }
}

export const removeNodeByQuerySelector = (selector: string = '', parent: HTMLHeadElement | Document | null = null) => {
  if (selector) {
    const elem: Element | null = document.querySelector(selector)
    if (elem !== null) {
      removeNode(elem, parent)
    }
  }
}

export const addInlineJS = (file: string, selector: string = '', params: object | null = null) => {
  if (!selector) {
    selector = `script[src="${file}"]`
  }
  if (document.querySelector(selector) === null) {
    const script = document.createElement('script')
    document.head.appendChild(script)
    if (params != null) {
      Object.keys(params).forEach((key: string) => script.setAttribute(key, (params as any)[key]))
    }
    script.src = file
  }
}

export const removeInlineJS = (file: string, selector: string = '') => {
  if (!selector) {
    selector = `script[src="${file}"]`
  }
  removeNodeByQuerySelector(selector, document.head)
}

export function entityToHtml(html: string): string {
  return he.decode(html)
}

export function sanitizeHtml(dirty: string): string {
  return DOMPurify.sanitize(dirty, { ALLOWED_TAGS: [] })
}

export function purifyHtml(dirty: string): string {
  const disallowedTags = ['iframe', 'script', 'object', 'embed']
  const tagRegex = new RegExp(`<(\\/?(${disallowedTags.join('|')})[^>]*>)`, 'gi')
  
  return dirty.replace(tagRegex, '')
}
