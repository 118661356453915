<template lang="pug">

div(class="associate-action-dialog-container")
  CustomDialogHeaderComponent(:headerTitle="text.title" class="header")

  SpinnerLayerComponent(v-if="!loadedSelectItems" class="spinner-layer")

  LexonSelectComponent(
    v-show="loadedSelectItems && !getRenderNoRegistersWarning"
    id="economicData"
    name="economicData"
    class="lexon-select"
    v-model="economicDataId"
    :label="text.label"
    keyName="description"
    keyValue="id"
    :returnObject="false"
    :tableHeaders="tableHeaders"
    :propItems="economicDataCollection"
  )
  div(
      v-if="loadedSelectItems && getRenderNoRegistersWarning"
      class="info-message-container"
    )
      div(class="info-message")
        span(:class="[infoIcon, 'icon']")
        span(class="text") {{  text.noEconomicData }}


  CustomDialogActionButtonsComponent(
    v-if="loadedSelectItems"
    class="actions"
    :primaryButtonText="text.save"
    :mainAction="saveAssociation"
    :disabledButtons="!economicDataId"
  )

</template>

<script lang="ts">
import { Icons } from '@/icons/icons'
import { ContextName, ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import { Vue, Component, Prop } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'
import { AssociateEconomicDataToActionRequest } from '@/store/modules/actions/actionsTypes'
import { AlertsTypes, ComponentWhereIsRendered } from '@/store/modules/alerts/alertsTypes'
import { MainService } from '@/services/MainService'
import { formatNumber } from '@/helpers/numeric'

const actionsModule: string = ModuleNamespaces.ACTIONS
const dialogModule: string = ModuleNamespaces.DIALOG
const selectedRegisterModule = ModuleNamespaces.SELECTED_REGISTER
const alertsModule: string = ModuleNamespaces.ALERTS

@Component({
  components: {
    CustomDialogActionButtonsComponent: () =>
      import('@/components/Dialog/CustomDialog/CustomDialogActionButtonsComponent.vue'),
    CustomDialogHeaderComponent: () => import('@/components/Dialog/CustomDialog/CustomDialogHeaderComponent.vue'),
    LexonSelectComponent: () => import('@/components/forms/fields/LexonSelect/LexonSelectComponent.vue'),
    SpinnerLayerComponent: () => import('@/components/Spinner/SpinnerLayerComponent.vue')
  }
})
export default class AssociateActionDialogComponent extends Vue {
  @Prop({
    type: Object,
    required: true
  })
  componentProps!: {
    selectedActionIds: number[]
  }

  @Action('associateEconomicDataToAction', { namespace: actionsModule })
  associateEconomicDataAction: (associateRequest: AssociateEconomicDataToActionRequest) => void

  @Action('closeCustomDialog', { namespace: dialogModule })
  closeCustomDialog: () => {}

  @Getter('getSelectedRegisterId', { namespace: selectedRegisterModule })
  selectedRegisterId: (context: string) => number

  text = {
    title: this.$t('components.associate_action_dialog.associate_action'),
    label: this.$t('components.associate_action_dialog.economic_data'),
    save: this.$t('action_buttons.save'),
    error: this.$t('components.alerts.associated_economic_data_error'),
    noEconomicData: this.$t('components.associate_action_dialog.no_economic_data')
  }

  tableHeaders = ['economicDateType', 'description', 'amount', 'numberInvoice']

  infoIcon = Icons.INFO

  economicDataId: null | number = null

  loadedSelectItems = false

  economicDataCollection: any [] = []

  get getRenderNoRegistersWarning (): boolean {
    return !this.economicDataCollection.length
  }

  get selectedExpedientId() {
    return this.selectedRegisterId(ContextName.EXPEDIENTS)
  }

  async created() {
    await this.fetchEconomicDataCollection()
  }

  async fetchEconomicDataCollection (){
    const { data } = await new MainService().postData(
      `expedient/${this.selectedRegisterId(ContextName.EXPEDIENTS)}/economic/get`
    )
    this.loadedSelectItems = true
    if (!Array.isArray(data) || !data.length) {
      return
    }

    this.economicDataCollection = data.map(
      economicData => ({
        ...economicData,
        amount: `${formatNumber(economicData.amount, 2, 'de-DE')} €`
      })
    )
  }

  saveAssociation() {
    if (!this.economicDataId || 1 > this.componentProps.selectedActionIds.length) {
      this.showAlert(this.text.error)
      this.closeCustomDialog()
      return
    }
    this.associateEconomicDataAction({
      actionIdLot: this.componentProps.selectedActionIds,
      economicDataId: this.economicDataId as number
    } as AssociateEconomicDataToActionRequest)
    this.closeCustomDialog()
  }

  showAlert(message: any) {
    this.$store.dispatch(
      `${alertsModule}/showAlert`,
      {
        type: AlertsTypes.ERROR,
        message,
        componentWhereIsRendered: ComponentWhereIsRendered.TABS_VIEW
      },
      { root: true }
    )
  }
}
</script>

<style lang="scss" scoped>
.associate-action-dialog-container {
  @include flex($flex-direction: column, $justify-content: space-between);
  height: 200px;

  .header,
  .lexon-select,
  .actions {
    width: 100%;
  }

  .lexon-select {
    padding-bottom: 30px;
  }

  .spinner-layer {
    --spinner-layer-min-height: 150px;
  }

  .info-message-container {
    @include flex($justify-content: space-between);
    width: 100%;
    height: 50px;
    color: $white-01;
    padding: 0 20px;
    margin-bottom: 30px;
    background-color: $blue-02;

    .info-message {
      @include flex;

      .icon {
        font-size: 30px;
        margin-right: 20px;
        opacity: 0.5;
      }

      .text {
        font-family: $corporate-font;
        font-size: 14px;
      }
    }
  }
}
</style>
