import { GetterTree } from 'vuex'
import { AlertsState, AlertsData } from '@/store/modules/alerts/alertsTypes'
import { RootState } from '@/store/types/storeGlobalTypes'

export const getters: GetterTree<AlertsState, RootState> = {
  showAlert(state) {
    return state.showAlert
  },
  getAlertData(state): AlertsData | null {
    return state.alertData
  }
}
