<template lang="pug">

  header(class="tab-header-container")
    div(class="header-title-container")
      p(class="header-title" v-html="title")
    v-spacer
    ActionsBarComponent(
      :buttons="buttons"
      @execute-action="executeAction"
      class="action-buttons"
    )

</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator'
import ActionsBarComponent from '@/components/ActionsBar/ActionsBarComponent.vue'
import { ActionName } from '@/components/ActionsBar/types/ActionBarComponentTypes'

@Component({
  components: {
    ActionsBarComponent
  }
})
export default class TabHeaderComponent extends Vue {
  @Prop({
    type: String,
    required: true
  })
  title!: string

  @Prop({
    type: Array,
    required: true
  })
  buttons!: []

  close() {
    this.$emit('closeForm')
  }

  save() {
    this.$emit('saveForm')
  }

  remove() {
    this.$emit('removeRelated')
    this.$emit('removeItem')
  }

  executeAction(actionName: string) {
    switch (actionName) {
      case ActionName.CLOSE:
        return this.close()
      case ActionName.SAVE:
        return this.save()
      case ActionName.REMOVE:
        return this.remove()
    }
    this.$emit('executeAction', actionName)
  }
}
</script>

<style lang="scss" scoped>
.tab-header-container {
  @include border($width: 2px);
  @include flex;
  padding-bottom: 8px;
  margin-bottom: 10px;

  .header-title-container {
    @include flex($justify-content: flex-start);
    align-self: flex-start;
    width: 1050px;
    color: $blue-05;
    font-size: 15px;
    font-family: $corporate-font-medium;
    padding: 4px 20px;
    margin-top: 8px;

    .header-title {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      margin: 7px 0;
    }
  }

  .action-buttons {
    @include flex;
    height: 47px;
    background-color: $gray-09;
    padding: 0 4px;
    margin: 8px 8px 0 8px;
    border-radius: 47px;
  }
}
</style>
