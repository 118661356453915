import { ActionTree } from 'vuex'
import {
  CustomFieldsState,
  customFieldsMutationTypes,
  CustomFieldsConfig,
  CustomField,
  CustomFieldGroup
} from '@/store/modules/customFields/customFieldsTypes'
import { RootState } from '@/store/types/storeGlobalTypes'
import { MainService } from '@/services/MainService'
import { uuid } from '@/helpers/helpers'

function setUUID(customFields: CustomField[]) {
  customFields.forEach((item: CustomField) => {
    item.uuid = uuid()
    if ((item as CustomFieldGroup).fields) {
      setUUID((item as CustomFieldGroup).fields)
    }
  })
}

export const actions: ActionTree<CustomFieldsState, RootState> = {
  // Related to expedients (or other entity) tab
  async fetchCustomFieldsForm({ commit }, { idEntityType, idEntity }) {
    try {
      const url = `custom/fields/form/${idEntityType}/${idEntity}/get`
      const { data } = await new MainService().getData(url)
      commit(customFieldsMutationTypes.FETCH_CUSTOM_FIELDS_FORM, data)
    } catch (error) {}
  },
  async fetchCustomFieldsData({ commit }, { idEntityType, idEntity }) {
    try {
      const url = `custom/fields/data/${idEntityType}/${idEntity}/get`
      const { data } = await new MainService().getData(url)
      commit(customFieldsMutationTypes.FETCH_CUSTOM_FIELDS_DATA, data)
    } catch (error) {
      commit(customFieldsMutationTypes.RESET_CUSTOM_FIELDS_DATA)
    }
  },
  // Related to maintenance tab
  async fetchCustomFieldsTypes({ commit }) {
    try {
      const url = `custom/fields/types/get`
      const { data } = await new MainService().getData(url)
      commit(customFieldsMutationTypes.FETCH_CUSTOM_FIELDS_TYPES, data)
    } catch (error) {}
  },
  async fetchCustomFieldsConfig({ commit }, idEntityType) {
    try {
      const url = `custom/fields/config/${idEntityType}/get`
      const { data } = await new MainService().getData(url)
      setUUID(data.customFields)
      commit(customFieldsMutationTypes.FETCH_CUSTOM_FIELDS_CONFIG, data)
      commit(customFieldsMutationTypes.FETCH_CUSTOM_FIELDS_ORIGINAL_CONFIG, data)
    } catch (error) {}
  },
  async saveCustomFieldsConfig({ commit }, config: CustomFieldsConfig): Promise<boolean> {
    try {
      const url = `custom/fields/config/save`
      const { data } = await new MainService().postData(url, config)
      const result = (data as CustomFieldsConfig).customFields !== undefined
      if (result) {
        commit(customFieldsMutationTypes.FETCH_CUSTOM_FIELDS_CONFIG, data)
        commit(customFieldsMutationTypes.FETCH_CUSTOM_FIELDS_ORIGINAL_CONFIG, data)
      }
      return result
    } catch (error) {
      return false
    }
  }
}
