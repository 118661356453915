export enum LexboxTypes {
  CALENDAR = 'calendar',
  MAIL = 'mail'
}

export interface LexboxCalendarData {
  errors: []
  data: {
    aplicaciones: string | null
    companies: [] | null
    extraElements: string | null
    id: string | null
    idNavision: string | null
    idUser: string | null
    name: string | null
    token: string | null
  }
}
export interface EmailProvider {
  provider: string
  email: string
}

export interface LexboxState {
  calendarData: LexboxCalendarData | null
  calendarProvider: string | null
  emailProvider: EmailProvider | null
  calendarServiceUrl: string | null
  eventCalendarServiceUrl: string
  accessToken: string
}

export interface CalendarParams {
  id: string | null
  idProUser: string
  query: object | null
  provider: string | null
}

export const lexboxMutationTypes = {
  SAVE_CALENDAR_SERVICE_URL: 'SAVE_CALENDAR_SERVICE_URL',
  RESET_CALENDAR_SERVICE_URL: 'RESET_CALENDAR_SERVICE_URL',
  SAVE_EVENT_CALENDAR_SERVICE_URL: 'SAVE_EVENT_CALENDAR_SERVICE_URL',
  RESET_EVENT_CALENDAR_SERVICE_URL: 'RESET_EVENT_CALENDAR_SERVICE_URL',
  FETCH_CALENDAR_DATA: 'FETCH_CALENDAR_DATA',
  RESET_CALENDAR_DATA: 'RESET_CALENDAR_DATA',
  FETCH_EMAIL_PROVIDER: 'FETCH_EMAIL_PROVIDER',
  RESET_EMAIL_PROVIDER: 'RESET_EMAIL_PROVIDER',
  FETCH_CALENDAR_PROVIDER: 'FETCH_CALENDAR_PROVIDER',
  RESET_CALENDAR_PROVIDER: 'RESET_CALENDAR_PROVIDER',
  FETCH_ACCESS_TOKEN: 'FETCH_ACCESS_TOKEN'
}

export const calendarEndpoints = {
  GOOGLE: {
    url: `${process.env.VUE_APP_LEXBOX_GOOGLE_CALENDAR_SERVICE}`,
    params: '?prov=GO0'
  },
  OUTLOOK: {
    url: `${process.env.VUE_APP_LEXBOX_OUTLOOK_CALENDAR_SERVICE}`,
    params: '?prov=OU0'
  },
  IMAP: {
    url: `${process.env.VUE_APP_LEXBOX_IMAP_CALENDAR_SERVICE}`,
    params: '?prov=IM0'
  }
}

export const emailEndpoints = {
  GOOGLE: {
    url: `${process.env.VUE_APP_LEXBOX_GOOGLE}`
  },
  OUTLOOK: {
    url: `${process.env.VUE_APP_LEXBOX_OUTLOOK}`
  },
  IMAP: {
    url: `${process.env.VUE_APP_LEXBOX_IMAP}`
  }
}

export const emailProviderName = {
  GOOGLE: 'GOOGLE',
  OUTLOOK: 'OUTLOOK',
  IMAP: 'IMAP'
}
