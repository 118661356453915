import { Module } from 'vuex'
import { GlobalState } from '@/store/modules/global/globalTypes'
import { actions } from '@/store/modules/global/globalActions'
import { mutations } from '@/store/modules/global/globalMutations'
import { RootState } from '@/store/types/storeGlobalTypes'

const namespaced: boolean = true

export const state: GlobalState = {}

export const globalModule: Module<GlobalState, RootState> = {
  namespaced,
  state,
  actions,
  mutations
}
