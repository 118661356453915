import { Module } from 'vuex'
import { getters } from '@/store/modules/listItems/listItemsGetters'
import { mutations } from '@/store/modules/listItems/listItemsMutations'
import { ListItemsState } from '@/store/modules/listItems/listItemsTypes'
import { RootState } from '@/store/types/storeGlobalTypes'

export const state: ListItemsState = {
  listItems: []
}

const namespaced: boolean = true

export const listItemsModule: Module<ListItemsState, RootState> = {
  namespaced,
  state,
  getters,
  mutations
}
