import { Module } from 'vuex'
import { getters } from '@/store/modules/billing/billingGetters'
import { actions } from '@/store/modules/billing/billingActions'
import { mutations } from '@/store/modules/billing/billingMutations'
import { BillingState } from '@/store/modules/billing/billingTypes'
import { RootState } from '@/store/types/storeGlobalTypes'

export const state: BillingState = {
  billing: null,
  lastListBillingFilter: [],
  totals: null
}

const namespaced: boolean = true

export const billingModule: Module<BillingState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations
}
