<template lang="pug">
  section(class="lexbox-redirect-container")
    HeaderComponent
    section(class="lexbox-redirect-section")

</template>

<script lang="ts">
import { Vue, Component } from 'vue-property-decorator'
import PromptDialogComponent from '@/components/Dialog/PromptDialogComponent.vue'
import HeaderComponent from '@/components/Header/HeaderComponent.vue'
import { ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import { Mutation } from 'vuex-class'

const spinnerModule: string = ModuleNamespaces.SPINNER

@Component({
  components: {
    HeaderComponent,
    PromptDialogComponent
  }
})
export default class SsoUnpaidView extends Vue {
  @Mutation('SHOW_SPINNER', { namespace: spinnerModule })
  showSpinner: (showText: boolean) => void

  created() {
    this.showSpinner(true)
    this.redirectToService()
  }

  async redirectToService() {
    window.location.replace(process.env.VUE_APP_SSO_UNPAID)
  }
}
</script>

<style lang="scss" scoped>
.lexbox-redirect {
  &-container {
    display: grid;
    grid-template-rows: 60px 1fr;
    height: 100%;
    font-family: $corporate-font;
    color: $corporate-color;
  }

  &-section {
    @include flex;
    flex-direction: column;
    margin: 30px;
  }

  &-card {
    @include flex($flex-direction: column, $justify-content: flex-start);
    @include borders;
    width: 450px;
    padding-top: 50px;
  }
}
</style>
