import { MutationTree } from 'vuex'
import { ListItemsState } from '@/store/modules/listItems/listItemsTypes'

export const mutations: MutationTree<ListItemsState> = {
  SET_LIST_ITEMS(state, items) {
    state.listItems = items
  },
  REMOVE_LIST_ITEMS(state) {
    state.listItems = []
  }
}
