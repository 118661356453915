<template lang="pug">

  header(class="header")

    div(class="logo-container")
      img(src="@/assets/images/logos/LogoLefebvre.png" alt="Lefebvre logo" class="header-logo")

    a(:href="shopLink" target="_blank" class="shop-link")
      span(class="lf-icon-shop shop-icon")
      span(class="shop-text") {{ $t('views.login.shop') }}

</template>

<script lang="ts">
import { Vue, Component } from 'vue-property-decorator'
import { externalURLS } from '@/router/routes/urlRoutes'

@Component
export default class HeaderComponent extends Vue {
  shopLink = externalURLS.shop
}
</script>

<style lang="scss" scoped>
.header {
  display: flex;
  justify-content: space-between;
  height: 60px;
  width: 100%;
  background: $corporate-color;
  color: $white-01;
  font-size: 20px;
  padding: 0 $padding-percentage-mobile;
}

.logo-container {
  @include flex;
}

.header-logo {
  width: 120px;
  height: 24px;
}

.shop-link {
  @include flex;
  text-decoration: none;
  color: $white-01;
}

.shop-icon {
  font-size: 27px;
}

.shop-text {
  text-transform: uppercase;
  padding-top: 7px;
  font-size: 14px;
  font-family: $corporate-font-bold;
  margin-left: 12px;
}

@include tabletAndMore {
  .header {
    padding: 0 $padding-percentage-rest;
  }
}
</style>
