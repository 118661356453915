import { URLS } from '@/router/routes/urlRoutes'
import RepositoryView from '@/views/Repository/RepositoryView.vue'

export const repositoryRoutes = [
  {
    path: `/${URLS.REPOSITORY}`,
    name: URLS.REPOSITORY,
    component: RepositoryView,
    meta: {
      title: URLS.REPOSITORY,
      requiresAuth: true,
      entityType: 14
    }
  }
]
