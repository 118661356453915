<template lang="pug">

div(class="word-preview")

  ClosePreviewComponent(
    @close-viewer-event="closeViewerEvent"
  )

  SpinnerLayerComponent(
    v-if="isLoading"
    class="spinner-layer-component"
  )

  ejs-documenteditorcontainer(
    ref="documentEditor"
    class="document-editor"
    width="100%"
    :isReadOnly="true"
    :restrictEditing="true"
    :documentChange="onDocumentChange"
    @failure="onServiceFailure"
  )

</template>

<script lang="ts">
import { Vue, Component, Emit } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'
import { DocumentEditor, DocumentEditorContainerPlugin } from '@syncfusion/ej2-vue-documenteditor'
import { DocumentPreview } from '@/components/FileManager/Preview/types/PreviewTypes'
import { ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import ClosePreviewComponent from '@/components/FileManager/Preview/components/ClosePreviewComponent.vue'
import SpinnerLayerComponent from '@/components/Spinner/SpinnerLayerComponent.vue'

Vue.use(DocumentEditorContainerPlugin)

const authModule: string = ModuleNamespaces.AUTH
const fileManagerModule: string = ModuleNamespaces.FILE_MANAGER
const templateModule: string = ModuleNamespaces.TEMPLATE

// https://ej2.syncfusion.com/vue/documentation/document-editor/web-services/mvc/
// Word documents (.dotx,.docx,.docm,.dot,.doc),
// rich text format documents (.rtf),
// and text documents (.txt)
// into SFDT format
@Component({
  components: {
    ClosePreviewComponent,
    SpinnerLayerComponent
  }
})
export default class WordPreviewComponent extends Vue {
  @Action('downloadFile', { namespace: fileManagerModule })
  downloadFile: (id: string) => Blob
  @Action('convertingToSFDT', { namespace: templateModule })
  convertingToSFDT: (formData: FormData) => string
  @Action('cancelPendingRequests', { namespace: authModule })
  cancelPendingRequests: () => {}

  @Getter('getFile', { namespace: fileManagerModule })
  blobFile: Blob

  isLoading = true

  $refs!: {
    documentEditor: any
  }

  @Emit()
  closeViewerEvent() {}

  destroyed() {
    this.cancelPendingRequests()
  }

  mounted() {
    this.$refs.documentEditor.ej2Instances.serviceUrl = process.env.VUE_APP_SYNCFUSION_WORD_API
  }

  onServiceFailure() {
    this.$emit('failPreview')
  }

  onDocumentChange() {
    this.isLoading = false
  }

  async preview(documentPreview: DocumentPreview) {
    this.isLoading = true

    const editor: DocumentEditor = this.$refs.documentEditor.ej2Instances.documentEditor as DocumentEditor

    if (!editor) {
      return this.onServiceFailure()
    }

    editor.height = '300px'
    editor.zoomFactor = 1
    editor.showRevisions = false

    await this.downloadFile(documentPreview.id.toString())

    if (!this.blobFile) {
      return this.onServiceFailure()
    }

    const formData: FormData = new FormData()
    formData.append('files', this.blobFile)
    const templateSFDT = await this.convertingToSFDT(formData)
    if (templateSFDT) {
      editor.open(templateSFDT)
      editor.fitPage('FitPageWidth')
    } else {
      this.$emit('failPreview')
    }
  }
}
</script>

<style lang="scss" scoped>
.word-preview {
  width: 100%;
  height: 100%;

  .e-documenteditorcontainer {
    height: 100% !important;
  }

  ::v-deep .e-de-tool-ctnr-properties-pane,
  ::v-deep .e-documenteditor,
  ::v-deep .e-documenteditor-optionspane > div:first-of-type:not(.spinner-layer-component) {
    height: 100% !important;
  }

  ::v-deep .e-de-status-bar,
  ::v-deep .e-de-ctnr-toolbar {
    display: none;
  }

  ::v-deep .e-documenteditor-optionspane > div:first-of-type {
    @include scroll-styles;
  }

  ::v-deep .e-de-ctn {
    border: none;
    .e-de-tool-ctnr-properties-pane {
      border: none;
    }
  }

  .spinner-layer-component {
    background-color: $white-01;
    min-height: 100%;
  }
}
</style>
