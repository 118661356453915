export enum NumerationPatternTypes {
  CODE = 2,
  DATE = 3,
  CLIENT = 4,
  TEXT = 1
}

export enum NumerationMaintenanceTypes {
  SEPARATORS = 'manNumerationSeparators',
  FORMATS = 'manNumerationFormats'
}
