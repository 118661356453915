import { GetterTree } from 'vuex'
import { CustomFieldsState } from '@/store/modules/customFields/customFieldsTypes'
import { RootState } from '@/store/types/storeGlobalTypes'

export const getters: GetterTree<CustomFieldsState, RootState> = {
  getCustomFieldsForm(state) {
    return state.customFieldsForm
  },
  getCustomFieldsData(state) {
    return state.customFieldsData
  },
  getCustomFieldsTypes(state) {
    return state.customFieldsTypes
  },
  getCustomFieldsConfig(state) {
    return state.customFieldsConfig
  },
  getCustomFieldsOriginalConfig(state) {
    return state.customFieldsOriginalConfig
  },
  launchCustomFieldFormDialogFlagStatus(state) {
    return state.openCustomFieldsFormDialogFlag
  },
  getModeCustomFieldsFormDialog(state) {
    return state.modeCustomFieldsFormDialog
  },
  getCustomFieldFormData(state) {
    return state.customFieldFormData
  },
  getCustomFieldSelectedData(state) {
    return state.customFieldSelectedData
  }
}
