<template lang="pug">
ejs-toolbar(id="customToolbar")
  e-items
    e-item(
      id="closePage"
      :prefixIcon="icons.close"
      :tooltipText="texts.close"
      :click="closeViewerEvent"
    )
    e-item(
      :prefixIcon="icons.zoomIn"
      id="zoomIn"
      align="Center"
      :tooltipText="texts.zoomIn"
      :click="zoomInEvent"
      :disabled="zoom===10"
    )
    e-item(
      :prefixIcon="icons.zoomOut"
      id="zoomOut"
      align="Center"
      :tooltipText="texts.zoomOut"
      :click="zoomOutEvent"
      :disabled="zoom===1"
    )

</template>

<script lang="ts">
import { Vue, Component, Emit, Prop } from 'vue-property-decorator'
import { Icons } from '@/icons/icons'

@Component
export default class PdfPreviewToolbarComponent extends Vue {
  @Prop({
    type: Number,
    required: true
  })
  zoom: number

  icons = {
    close: Icons.CLOSE,
    zoomIn: Icons.ADD,
    zoomOut: Icons.LESS
  }

  texts = {
    close: this.$t('components.pdf_viewer.close_document'),
    zoomIn: this.$t('components.pdf_viewer.zoom_in'),
    zoomOut: this.$t('components.pdf_viewer.zoom_out')
  }

  @Emit()
  zoomInEvent() {}

  @Emit()
  zoomOutEvent() {}

  @Emit()
  closeViewerEvent() {}
}
</script>

<style lang="scss">
.e-filemanager {
  #customToolbar.e-toolbar {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    height: 33px !important;
    min-height: 33px !important;
    z-index: 999;

    .e-toolbar-items {
      min-height: 33px !important;
      margin: 0;
      background-color: $white-01 !important;

      .e-toolbar-item {
        min-height: 33px !important;
        background-color: $white-01;
        padding: 0;
        margin: 0;

        .e-icon-btn {
          min-height: 43px;
          background-color: $white-01;
          margin: 0;

          .e-btn-icon.e-icons {
            font-family: $lf-icons-font !important;
            font-size: 12px;
            color: $corporate-color !important;
            background-color: $white-01;
            border: 0;
          }
        }
      }

      .e-toolbar-left {
        margin-left: 2px;
        line-height: 33px !important;

        .e-icon-btn {
          min-height: 0 !important;

          .e-btn-icon {
            width: 33px !important;
            height: 33px !important;
          }

          &:hover {
            height: 33px !important;
          }
        }
      }

      .e-toolbar-center {
        @include flex;
        overflow: hidden;

        .e-toolbar-item {
          .e-btn-icon.e-icons {
            color: $gray-01 !important;
          }

          &.e-template {
            pointer-events: none;
          }
        }

        .e-overlay {
          .e-icon-btn {
            .e-icons {
              background-color: transparent !important;
              opacity: 0.5;
            }
          }
        }
      }
    }
  }
}
</style>
