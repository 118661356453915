export interface NotesState {
  notes: object
}

export const initialState: NotesState = {
  notes: []
}

export const moduleEndpoints = {
  DELETE: '/note/{0}/delete',
  FETCH: '/notes/list',
  SAVE: 'note/save'
}

export const notesMutationTypes = {
  DELETE_NOTE: 'DELETE_NOTE',
  FETCH_NOTES: 'FETCH_NOTES',
  SAVE_NOTE: 'SAVE_NOTE'
}
