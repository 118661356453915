<template lang="pug">

  GridTableComponent(
    v-if="Object.keys(listConfiguration).length && itemsData"
    ref="grid"
    :itemsData="itemsData"
    :gridConfiguration="listConfiguration['Config']"
    title="Title"
    :toolbarOptions="toolbarOptions"
    :contextMenuItems="gridContextMenuItems"
    :listName="listConfiguration['Alias']"
    :context="context"
    :showHeader="false"
    @cellClicked="openForm"
    @contextMenuClicked="onContextMenuClicked"
  )

</template>

<script lang="ts">
import { Mixins, Prop, Component, Emit } from 'vue-property-decorator'
import { Icons } from '@/icons/icons'
import { ContextName } from '@/store/types/storeGlobalTypes'
import GridTableComponent from '@/components/grids/GridTable/GridTableComponent.vue'
import GridContextMenuOptionsMixin from '@/mixins/GridContextMenuOptionsMixin.vue'
import GridToolbarOptionsMixin from '@/mixins/GridToolbarOptionsMixin.vue'

@Component({
  components: {
    GridTableComponent
  }
})
export default class UsersMaintenanceGridComponent extends Mixins(
  GridToolbarOptionsMixin,
  GridContextMenuOptionsMixin
) {
  @Prop({
    type: Array
  })
  itemsData!: []

  @Prop({
    type: Object
  })
  listConfiguration!: []

  context = ContextName.MAINTENANCE_USERS_GRID

  get gridContextMenuItems() {
    return [this.editMenuItem]
  }

  get toolbarOptions() {
    const { exportText, print, filter } = this.toolbarOptionsTooltipTexts
    return [
      {
        id: 'filter',
        prefixIcon: Icons.FILTER,
        text: filter,
        align: 'left',
        cssClass: 'lf-clean-filter-button'
      },
      {
        id: 'search',
        text: 'Search',
        align: 'right'
      },
      {
        id: 'print',
        prefixIcon: Icons.PRINT,
        tooltipText: print,
        align: 'right'
      },
      {
        id: 'export',
        prefixIcon: Icons.DOWNLOAD,
        tooltipText: exportText,
        align: 'right'
      }
    ]
  }

  @Emit('openFormEvent')
  openForm(selectedRegister: any | null = null) {
    return selectedRegister
  }

  onContextMenuClicked(_args: any, selectedRegister: any) {
    this.openForm(selectedRegister)
  }
}
</script>
