import { Module } from 'vuex'
import { getters } from '@/store/modules/router/routerGetters'
import { actions } from '@/store/modules/router/routerActions'
import { mutations } from '@/store/modules/router/routerMutations'
import { RouterState } from '@/store/modules/router/routerTypes'
import { RootState } from '@/store/types/storeGlobalTypes'

export const state: RouterState = {
  nextUrl: ''
}

const namespaced: boolean = true

export const routerModule: Module<RouterState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations
}
