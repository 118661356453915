import { ActionTree } from 'vuex'
import { NotificationsState, notificationsMutationTypes } from '@/store/modules/notifications/notificationsTypes'
import { RootState, ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import { MainService } from '@/services/MainService'
import { ExternalService } from '@/services/ExternalService'
import { DialogTypes } from '@/store/modules/dialog/dialogTypes'

const dialogModule: string = ModuleNamespaces.DIALOG

export const actions: ActionTree<NotificationsState, RootState> = {
  async sendMailNotification({ dispatch }, { templateType, notificationData }) {
    try {
      const url = `/mail/template/${templateType}/send`
      const { data } = await new MainService().postData(url, notificationData)
      const message = data.exceptionDesc
      dispatch(
        `${dialogModule}/showDialog`,
        {
          type: DialogTypes.SUCCESS,
          message
        },
        { root: true }
      )
    } catch (error) {
      const errorMessage = error.response.data.exceptionDesc
      dispatch(
        `${dialogModule}/showDialog`,
        {
          type: DialogTypes.ERROR,
          message: errorMessage
        },
        { root: true }
      )
    }
  },
  async fetchLoginViewNotification({ commit }) {
    try {
      const url = `${process.env.VUE_APP_API_URL}notification/get`
      const { data } = await new ExternalService().getData(url)
      commit(notificationsMutationTypes.FETCH_LOGIN_VIEW_NOTIFICATION, data)
    } catch (error) {
      throw new Error(error)
    }
  }
}
