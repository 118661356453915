import { Module } from 'vuex'
import { getters } from '@/store/modules/dashboardWidgets/dashboardWidgetsGetters'
import { actions } from '@/store/modules/dashboardWidgets/dashboardWidgetsActions'
import { mutations } from '@/store/modules/dashboardWidgets/dashboardWidgetsMutations'
import { DashboardWidgetsState } from '@/store/modules/dashboardWidgets/dashboardWidgetsTypes'
import { RootState } from '@/store/types/storeGlobalTypes'

export const state: DashboardWidgetsState = {
  dashboardAmountOutstandingResume: [],
  dashboardCustomerResume: [],
  dashboardCustomersCounterChart: [],
  dashboardExpedientResume: [],
  dashboardExpedientsCounterChart: [],
  dashboardInactiveExpedientsList: [],
  dashboardInvoicedAmountsResume: [],
  dashboardLastActionsList: [],
  dashboardLastCustomersList: [],
  dashboardLastDocumentsList: [],
  dashboardLastExpedientsList: [],
  dashboardOfficeDailyWorkingTimeResume: [],
  dashboardOfficeMonthlyWorkingTimeResume: [],
  dashboardOfficeWeeklyWorkingTimeResume: [],
  dashboardRecentActivityItems: [],
  dashboardUnpaidInvoicesList: [],
  dashboardUserDailyWorkingTimeResume: [],
  dashboardUserMonthlyWorkingTimeResume: [],
  dashboardUserWeeklyWorkingTimeResume: [],
  dashboardWidgetsConfiguration: {},
  dashboardWidgetsConfigurationLoadedFlag: false
}

const namespaced: boolean = true

export const dashboardWidgetsModule: Module<DashboardWidgetsState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations
}
