import { ActionTree } from 'vuex'
import { NumerationsState, numerationsMutationTypes } from '@/store/modules/numerations/numerationsTypes'
import { RootState, ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import { AlertsTypes, ComponentWhereIsRendered } from '@/store/modules/alerts/alertsTypes'
import { MainService } from '@/services/MainService'
import { listItemsMutationTypes } from '@/store/modules//listItems/listItemsTypes'
import { selectedRegisterMutationTypes } from '@/store/modules/selectedRegister/selectedRegisterTypes'
import { DialogTypes } from '../dialog/dialogTypes'
import { i18n } from '@/plugins/vue-i18n'
import { ErrorCodes } from '@/store/modules/errors/errorsTypes'

const dialogModule: string = ModuleNamespaces.DIALOG
const alertsModule: string = ModuleNamespaces.ALERTS

export const actions: ActionTree<NumerationsState, RootState> = {
  async fetchNumerations({ commit }) {
    try {
      const url = `numerations/get`
      const { data } = await new MainService().getData(url)
      commit(`${ModuleNamespaces.LIST_ITEMS}/${listItemsMutationTypes.SET_LIST_ITEMS}`, data, { root: true })
    } catch (error) {}
  },
  async fetchNumeration({ commit }, { selectedRegisterId, context }) {
    try {
      const url = `numeration/${selectedRegisterId}/get`
      const { data } = await new MainService().getData(url)
      if (data.idUser && data.idUser.id) {
        data.idUser.id = String(data.idUser.id)
      }
      commit(
        `${ModuleNamespaces.SELECTED_REGISTER}/${selectedRegisterMutationTypes.FETCH_SELECTED_REGISTER_DATA}`,
        {
          selectedRegisterData: data,
          context
        },
        { root: true }
      )
    } catch (error) {}
  },
  async saveNumeration({ dispatch }, filter) {
    try {
      const url = 'numeration/save'
      await new MainService().postData(url, filter)
      const message =
        filter.id === 0 ? i18n.t('components.alerts.numeration_save') : i18n.t('components.alerts.numeration_edit')
      dispatch(
        `${alertsModule}/showAlert`,
        {
          type: AlertsTypes.SUCCESS,
          message,
          componentWhereIsRendered: ComponentWhereIsRendered.SIMPLE_GRID_TABLE
        },
        { root: true }
      )
      return true
    } catch (error) {
      if (error.response && error.response.data) {
        const errorMessage = error.response.data.exceptionDesc
        dispatch(
          `${dialogModule}/showDialog`,
          {
            type: DialogTypes.ERROR,
            message: errorMessage
          },
          { root: true }
        )
      }
      return false
    }
  },
  async removeNumeration({ dispatch }, { idNumeration, name }) {
    try {
      const url = `numeration/${idNumeration}/delete`
      await new MainService().deleteData(url)
      await dispatch(
        `${dialogModule}/showDialog`,
        {
          type: DialogTypes.SUCCESS,
          message: i18n.t('components.dialog.success_remove_numeration', { name })
        },
        { root: true }
      )
    } catch (error) {
      if (error.response.data.exceptionCode === ErrorCodes.E400) {
        const errorMessage = error.response.data.exceptionDesc
        dispatch(
          `${dialogModule}/showDialog`,
          {
            type: DialogTypes.ERROR,
            message: errorMessage
          },
          { root: true }
        )
      }
    }
  },
  async fetchNumerationMaintenance({ commit }, { type }) {
    try {
      const url = `maintenance/${type}/get`
      const { data } = await new MainService().getData(url)
      commit(numerationsMutationTypes.FETCH_NUMERATIONS_MAINTENANCE, {
        data,
        context: type
      })
    } catch (error) {}
  }
}
