import { ActionTree } from 'vuex'
import { DialogState } from '@/store/modules/dialog/dialogTypes'
import { RootState } from '@/store/types/storeGlobalTypes'
import { dialogMutationTypes } from '@/store/modules/dialog/dialogTypes'

export const actions: ActionTree<DialogState, RootState> = {
  showDialog({ commit }, dialogData) {
    document.body.style.overflow = 'hidden'
    document.body.style.position = 'fixed'
    commit(dialogMutationTypes.SHOW_DIALOG, dialogData)
  },
  closeDialog({ commit }) {
    document.body.style.overflow = 'visible'
    document.body.style.position = 'initial'
    commit(dialogMutationTypes.CLOSE_DIALOG)
  },
  showCustomDialog({ commit }, customDialogData) {
    document.body.style.overflow = 'hidden'
    document.body.style.position = 'fixed'
    commit(dialogMutationTypes.SHOW_CUSTOM_DIALOG, customDialogData)
  },
  closeCustomDialog({ commit }) {
    document.body.style.overflow = 'visible'
    document.body.style.position = 'initial'
    commit(dialogMutationTypes.CLOSE_CUSTOM_DIALOG)
  }
}
