export interface SearchState {
  data: object
  isSearchView: boolean
  searchTerm: string
}

export const searchMutationTypes = {
  SET_SEARCH_DATA: 'SET_SEARCH_DATA',
  RESET_SEARCH_DATA: 'RESET_SEARCH_DATA',
  SET_IS_SEARCH_VIEW: 'SET_IS_SEARCH_VIEW',
  SET_SEARCH_TERM: 'SET_SEARCH_TERM'
}

export const searchAlias = {
  LEGAL: 'legalSearch',
  TANDEM: 'tandemSearch'
}
