import { Route } from 'vue-router'
import store from '@/store/store'
import { ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import { i18n } from '@/plugins/vue-i18n'
import { URLS } from '@/router/routes/urlRoutes'

export const permissionsGuard = async (to: Route, _from: Route, next: any): Promise<void> => {
  const { entityType } = to.meta as any

  if (entityType) {
    const { canView, canSave } = await store.getters[`${ ModuleNamespaces.AUTH }/getPermission`](entityType)
    const isNewRegisterView = to.path.includes(i18n.t('routes.new').toString())

    if (!canView || (!canSave && isNewRegisterView)) {
      next({ path: `/${ URLS.DASHBOARD }` })
      return
    }
  }
  next()
}


