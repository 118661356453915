<template lang="pug">

  div(v-if="title" class="expedient-tabs-header-container")
    div(class="expedient-title-container")
      span(class="expedient-title" v-html="title")
      v-spacer
      ShowOrHideTotalsButtonComponent(
        v-if="(isEconomicDataTab || isActionsTab) && totals.length"
        :showTotals="showTotals"
        @toggle-totals="toggleTotals"
        :style="cssVars"
      )
    TotalsContainerComponent(
      v-if="showComponentTotals"
      :items="totals"
      :fieldTotalName="fieldTotalName"
    )

</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator'
import ShowOrHideTotalsButtonComponent from '@/components/Totals/ShowOrHideTotalsButtonComponent.vue'
import TotalsContainerComponent from '@/components/Totals/TotalsContainerComponent.vue'
import { vars } from '@/styles/styleVars'
import { ConfigurationElements } from '@/store/modules/configuration/configurationTypes'
import { ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import { Getter } from 'vuex-class'

const expedientsModule = ModuleNamespaces.EXPEDIENTS

@Component({
  components: {
    ShowOrHideTotalsButtonComponent,
    TotalsContainerComponent
  }
})
export default class ExpedientDetailHeaderComponent extends Vue {
  @Prop({
    type: String
  })
  title!: string

  @Prop({
    type: Object
  })
  selectedTabInfo!: { index: number; objectType: string }

  @Getter('getExpedientSummary', { namespace: expedientsModule }) expedientSummaryTotals: []
  @Getter('getExpedientActionsSummary', { namespace: expedientsModule }) expedientActionsSummaryTotals: []

  showTotals = true

  get cssVars() {
    return {
      '--toggle-btn-margin': 0,
      '--toggle-btn-color': vars.white_01
    }
  }

  get isEconomicDataTab() {
    return this.selectedTabInfo.objectType === ConfigurationElements.EXPEDIENT_ECONOMIC_DATA
  }

  get isActionsTab() {
    return this.selectedTabInfo.objectType === ConfigurationElements.EXPEDIENT_ACTIONS
  }

  get showComponentTotals() {
    return this.totals && this.totals.length && this.showTotals && (this.isEconomicDataTab || this.isActionsTab)
  }

  get totals(): [] {
    if (this.isEconomicDataTab) {
      return this.expedientSummaryTotals
    } else if (this.isActionsTab) {
      return this.expedientActionsSummaryTotals
    }
    return []
  }

  get fieldTotalName() {
    if (this.isActionsTab) {
      return 'total'
    }
    return 'totalAmount'
  }

  toggleTotals() {
    this.showTotals = !this.showTotals
  }
}
</script>

<style lang="scss" scoped>
.expedient-tabs-header-container {
  @include flex($flex-direction: column);

  .expedient-title-container {
    @include flex($justify-content: flex-start);
    min-height: 30px;
    width: 100%;
    background-color: $blue-04;
    padding: 0 14px;

    .expedient-title {
      font-family: $secondary-font;
      font-size: 12px;
      color: $white-01;

      &::first-letter {
        text-transform: capitalize;
      }
    }
  }

  .toggle-button {
    background-color: red;
  }
}
</style>
