import { Module } from 'vuex'
import { getters } from '@/store/modules/template/templateGetters'
import { actions } from '@/store/modules/template/templateActions'
import { mutations } from '@/store/modules/template/templateMutations'
import { TemplateState } from '@/store/modules/template/templateTypes'
import { RootState } from '@/store/types/storeGlobalTypes'

export const state: TemplateState = {
  templateFields: [],
  templateTree: [],
  templateTreeEntityType: 0,
  showTemplateGenerator: false,
  template: null,
  templateId: 0
}

const namespaced: boolean = true

export const templateModule: Module<TemplateState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations
}
