<template lang="pug">
  GridTable(
    v-if="Object.keys(listConfiguration).length && Object.keys(serverSideData).length > 0 && !showSpinnerLayer"
    :gridConfiguration="listConfiguration['Config']"
    :title="$t('views.contacts_customers.title')"
    :toolbarOptions="toolbarOptions"
    :contextMenuItems="contextMenuItems"
    :listName="listConfiguration['Alias']"
    :context="context"
    :itemsData="serverSideData"
    :itemsDataExport="serverSideExportData"
    :useServerPagination="useServerPagination"
    @gridActionChange="gridActionChange"
    @gridExportData="gridExportData"
  )

</template>

<script lang="ts">
import { mixins } from 'vue-class-component'
import { Component } from 'vue-property-decorator'
import GridTable from '@/components/grids/GridTable/GridTableComponent.vue'
import { ContextName, ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import { ContactTypes } from '@/store/modules/contacts/contactsTypes'
import ListViewMixin from '@/mixins/ListViewMixin.vue'
import { ListNames, ListConfiguration } from '@/store/modules/configuration/configurationTypes'
import { Action, Getter } from 'vuex-class'
import { DataResult } from '@syncfusion/ej2-vue-grids'
import { formatFieldsTypeDateEPOCH } from '@/helpers/dateTime'

const contactsModule: string = ModuleNamespaces.CONTACTS
const configurationModule: string = ModuleNamespaces.CONFIGURATION

interface SortColumn {
  column: string
  order: 'desc' | 'asc'
}

interface RequestParams {
  page: number
  pageSize: number
  columnOrder?: SortColumn[]
  filter?: []
  searchSettings?: string
}

@Component({
  components: {
    GridTable
  }
})
export default class CustomersView extends mixins(ListViewMixin) {
  useServerPagination: boolean = true

  serverSideData: DataResult | object = {}
  serverSideExportData: DataResult | object = {}

  defaultPageSize: number = 10

  get context() {
    return ContextName.CONTACT_CUSTOMERS
  }

  @Action('fetchContactsFilter', { namespace: contactsModule })
  fetchContactsFilter: ({ contactType, params }: { contactType: string; params: RequestParams }) => []

  @Getter('getPaginateContacts', { namespace: contactsModule })
  contactsDataPaginated: []

  @Getter('getListConfig', { namespace: configurationModule })
  listConfig: ListConfiguration

  async created() {
    await this.fetchCurrentListConfiguration(ListNames.CUSTOMERS)
    await this.loadData()

    this.saveSelectedEntityName(ContactTypes.CUSTOMER)
  }

  async loadData() {
    try {
      if (this.useServerPagination) {
        const params = await this.parseUserConfig()
        await this.fetchContactsFilter({ contactType: ContactTypes.CUSTOMER, params })
        this.serverSideData = this.formatDataPaginated(this.contactsDataPaginated)
      } else {
        await this.fetchContacts(ContactTypes.CUSTOMER)
        this.serverSideData = this.contactsDataPaginated
      }
    } catch (error) {
    } finally {
      this.hideSpinnerLayerAction()
    }
  }

  parseUserConfig() {
    const sortColumns: SortColumn[] = []
    const { pageSettings, sortSettings, searchSettings, filterSettings } = this.listConfig

    if (sortSettings && sortSettings.length > 0) {
      sortSettings.forEach(({ field, direction }) => {
        const column: SortColumn = {
          column: field,
          order: direction === 'Ascending' ? 'asc' : 'desc'
        }
        sortColumns.push(column)
      })
    }
    const isFilterSetting = filterSettings && filterSettings.length > 0 && this.rememberUserConfig
    const isSearchSetting = searchSettings !== undefined && searchSettings !== '' && this.rememberUserConfig
    const config = {
      page: this.rememberUserConfig ? pageSettings.currentPage : 0,
      pageSize: pageSettings.pageSize ? pageSettings.pageSize : this.defaultPageSize,
      columnOrder: sortColumns,
      ...(isFilterSetting && { filter: filterSettings }),
      ...(isSearchSetting && { search: searchSettings })
    }

    return config
  }

  formatDataPaginated(data: any) {
    const items = formatFieldsTypeDateEPOCH(data.data)
    const gridResult: DataResult = { result: items, count: parseInt(data.pagination.totalRecs.toString(), 10) }
    return gridResult
  }

  async gridActionChange(serverSideParams: string) {
    const params = JSON.parse(serverSideParams)
    await this.fetchContactsFilter({ contactType: ContactTypes.CUSTOMER, params })
    this.serverSideData = this.formatDataPaginated(this.contactsDataPaginated)
  }

  async gridExportData(serverSideParams: string) {
    const params = JSON.parse(serverSideParams)
    await this.fetchContactsFilter({ contactType: ContactTypes.CUSTOMER, params })
    const { result } = this.formatDataPaginated(this.contactsDataPaginated)
    this.serverSideExportData = result
  }
}
</script>
