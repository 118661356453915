<template lang="pug">

  div(class="profitability-component-container")
    div(class="column-1")
      div(class="title-container")
        span(:class="[iconHand, 'icon']")
        span(class="title-text") {{ texts.costCalculation }}
      div(class="explanation-container")
        p(class="paragraph")  {{ texts.paragraph_1 }}
        p(class="paragraph") {{ texts.paragraph_2 }}
    div(class="column-2")
      div(class="input-container")
        div(class="input-title") {{ texts.hourCost }}
        div(:class="['value-container', textColorClass]")
          span(class="hour-cost-value" aria-hidden="true") {{ hourCostValue }}
          span(class="money-symbol") €
</template>

<script lang="ts">
import Vue from 'vue'
import { Component, Watch } from 'vue-property-decorator'
import { Icons } from '@/icons/icons'
import { formatNumber } from '@/helpers/numeric'

@Component
export default class ProfitabilityComponent extends Vue {
  hourCostValue = 0

  iconHand = Icons.HAND_SPHERE

  texts = {
    costCalculation: this.$t('components.profitability.cost_calculation'),
    hourCost: this.$t('components.profitability.hour_cost'),
    paragraph_1: this.$t('components.profitability.paragraph_1'),
    paragraph_2: this.$t('components.profitability.paragraph_2')
  }

  @Watch('$attrs.value')
  changeValue(value: number) {
    const hourCostSpan = this.$el.querySelector('.hour-cost-value')
    if (hourCostSpan) {
      const roundedValue = Number(value.toFixed(2))
      const formattedNumber = formatNumber(roundedValue, 0, 'de-DE')
      hourCostSpan.innerHTML = formattedNumber
    }
  }

  get textColorClass() {
    return this.$attrs.value ? 'blue-color' : 'gray-color'
  }
}
</script>

<style lang="scss" scoped>
.profitability-component-container {
  width: 100%;
  background-color: $blue-07;
  color: $blue-04;
  padding: 16px 28px;
  margin-bottom: 10px;

  .column-1 {
    width: 50%;
    min-height: 102px;

    .title-container {
      @include flex($justify-content: flex-start);

      .title-text {
        font-family: $corporate-font-bold;
        font-size: 16px;
        margin-left: 6px;
        padding-top: 8px;
      }

      .icon {
        color: $corporate-color;
        font-size: 35px;
      }
    }

    .explanation-container {
      margin-top: 7px;
      padding: 0 42px;

      .paragraph {
        font-family: $corporate-font;
        font-size: 12px;
        margin-bottom: 0;
      }
    }
  }

  .column-2 {
    .input-container {
      @include borders;
      width: 270px;

      .input-title {
        @include flex;
        width: 100%;
        height: 40px;
        font-family: $corporate-font;
        font-size: 16px;
        background-color: $corporate-color;
        color: $white-01;
      }
    }

    .value-container {
      @include flex;
      min-height: 60px;
      font-family: $corporate-font-bold;
      font-size: 18px;
      color: $gray-02;

      &.blue-color {
        color: $corporate-color;
      }

      &.gray-color {
        color: $gray-02;
      }

      .money-symbol {
        margin-left: 4px;
      }
    }
  }
}
</style>
