import { extend } from 'vee-validate'
import { i18n } from '@/plugins/vue-i18n'
import { changeDateFormat } from '@/helpers/dateTime'

extend('date', {
  validate: (value) => value instanceof Date && !isNaN(value.getTime()),
  message: i18n.t('validations.date') as string
})

extend('dateMayorOrEqual', {
  params: ['target'],
  // @ts-ignore
  validate(value: any, { target }) {
    if (target && value instanceof Date) {
      return value >= target
    }
    return true
  },
  message: (_, values) => {
    values._target_ = changeDateFormat(values._target_, '', 'DD/MM/YYYY HH:mm')
    return i18n.t('validations.date_mayor_than', values) as string
  }
})

extend('dateMinorOrEqual', {
  params: ['target'],
  // @ts-ignore
  validate(value, { target }) {
    if (target && value instanceof Date) {
      return value <= target
    }
    return true
  },
  message: (_, values) => {
    values._target_ = changeDateFormat(values._target_, '', 'DD/MM/YYYY HH:mm')
    return i18n.t('validations.date_minor_than', values) as string
  }
})
