<template lang="pug">

  div(:class="[$vuetify.breakpoint.name, 'event-container']" :style="`borderColor: ${event.calendarColor};`")
    //- TITLE
    p(class="event-subject" @click="goToEvent") {{ event.subject }}

    //- DATE ROW
    div(class="event-row")
      div(class="event-date")
        span(:class="[icons.calendar, 'icon']")
        div {{ event.startDate }}
      div(class="event-type")
        span(
          :style="`backgroundColor: ${event.typeEventColor}`"
          class="event-type calendar"
        )

    //- HOUR ROW
    div(class="event-row")
      div(class="event-hour")
        div(class="hour-container")
          span(:class="[icons.clock, 'icon']")
          div {{ renderHour }}
        div(v-if="event.idActionType" class="action-type-container")
          span(:class="[checkActionIconHelper(event.idActionType), 'icon']")
          span {{ $t(checkActionTextHelper(event.idActionType)) }}

</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'
import { Icons } from '@/icons/icons'
import { URLS } from '@/router/routes/urlRoutes'
import { Getter } from 'vuex-class'
import { NextEventsWidgetEvent } from '@/components/widgets/ExpedientWidgets/ExpedientNextEvents/ExpedientNextEventsWidgetTypes'
import { checkActionIcon, checkActionText } from '@/store/modules/actions/actionsTypes'
import { ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import { ConfigurationAppointmentVersion } from '@/store/modules/configuration/configurationTypes'
import SpinnerLayerComponent from '@/components/Spinner/SpinnerLayerComponent.vue'
import { TranslateResult } from 'vue-i18n'

const authModule = ModuleNamespaces.AUTH

@Component({
  components: {
    SpinnerLayerComponent
  }
})
export default class EventItemComponent extends Vue {
  @Prop({
    type: Object,
    required: true
  })
  event!: NextEventsWidgetEvent

  @Getter('getCompanyAppointmentsVersion', { namespace: authModule })
  calendarVersion: number

  icons = {
    calendar: Icons.CALENDAR_CHECK,
    clock: Icons.CLOCK
  }

  defaultCalendarsNumber: number = 4

  isAllDayText: TranslateResult = this.$t('components.widgets.expedient_next_events.all_day')

  checkActionIconHelper = checkActionIcon

  checkActionTextHelper = checkActionText

  get renderHour(): string {
    return this.eventIsAllDay ? (this.isAllDayText as string) : `${this.event.hourStart}h - ${this.event.hourEnd}h`
  }

  get eventIsAllDay(): boolean {
    const { hourStart, hourEnd } = this.event
    const hour = '00:00'
    return hourStart === hour && hourEnd === hour
  }

  goToEvent(): void {
    if (ConfigurationAppointmentVersion.INTERNAL_VERSION === this.calendarVersion) {
      const routeData = this.$router.resolve({ name: `${URLS.AGENDA}`, query: { id: (this as any).event.id } })
      window.open(routeData.href, '_blank')
    } else {
      this.$emit('selectedEvent', this.event)
    }
  }
}
</script>

<style lang="scss" scoped>
.event-container {
  @include flex($align-items: flex-start, $flex-direction: column);
  @include border($direction: left, $width: 5px);
  width: 100%;
  padding-left: 10px;

  &.xl {
    .event-subject {
      width: 300px;
    }
  }

  ~ .event-container {
    margin-top: 20px;
  }

  .icon {
    font-size: 14px;
    margin-right: 4px;
  }

  .event-subject {
    @include ellipsis;
    width: 200px;
    font-family: $secondary-font;
    font-size: 12px;
    color: $blue-03;
    margin-bottom: 3px;
    cursor: pointer;

    &:hover {
      color: $corporate-color;
    }
  }

  .event-row {
    @include flex($justify-content: space-between);
    width: 100%;

    ~ .event-row {
      @include border($color: $blue-13);
      margin-top: 7px;
      padding-bottom: 5px;
    }

    .event-date,
    .event-hour {
      @include flex;
      font-family: $secondary-font;
      font-weight: bold;
      color: $corporate-color;
    }

    .event-hour {
      @include flex($justify-content: space-between);
      width: 100%;
      color: $corporate-color;

      .hour-container {
        @include flex;
      }

      .action-type-container .icon {
        position: relative;
        top: 1.5px;
      }
    }

    .event-type {
      @include flex;

      .calendar {
        display: inline-block;
        width: 15px;
        height: 15px;
        border-radius: 50%;

        ~ .calendar {
          margin-left: 7px;
        }
      }

      .more-calendars-number {
        font-family: $secondary-font;
        font-size: 11px;
        color: $blue-04;
        margin-left: 5px;
      }
    }
  }
}
</style>
