import { MutationTree } from 'vuex'
import { ActionsState } from '@/store/modules/actions/actionsTypes'
import { ChronometerNewActionInfo } from '@/store/modules/actions/actionsTypes'

export const mutations: MutationTree<ActionsState> = {
  FETCH_ACTIONS(state, actions) {
    state.actions = actions
  },
  FETCH_ACTION(state, action) {
    state.action = action
  },
  RESET_ACTIONS(state) {
    state.actions = null
  },
  SAVE_SELECTED_ACTION_TYPE(state, actionType = '') {
    state.selectedActionType = actionType
  },
  SET_CHRONOMETER_NEW_ACTION_INFO(state, data) {
    state.chronometerNewActionInfo = data
  },
  RESET_CHRONOMETER_NEW_ACTION_INFO(state) {
    const chronometerInfo: ChronometerNewActionInfo = {
      start: '',
      end: '',
      duration: 0
    }
    state.chronometerNewActionInfo = chronometerInfo
  }
}
