<template lang="pug">
  input(
    type="hidden"
    v-model="innerValue"
    v-bind="$attrs"
  )
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class LexonHiddenComponent extends Vue {
  @Prop({
    type: [String, Number, Array, Object]
  })
  value!: string | number | [] | {}

  innerValue: any = null

  created() {
    if (this.value) {
      this.innerValue = this.value
    }
  }
}
</script>
