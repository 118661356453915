<template lang="pug">
  section(
    v-if="!isForm()"
    :class="{'lexon-view-container': !showSpinnerLayer}"
    :key="componentKey"
  )
    router-view
  router-view(v-else :key="componentKey")
</template>

<script lang="ts">
import { ParentCategory } from '@/store/modules/menus/menusTypes'
import { ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import { Vue, Component, Watch } from 'vue-property-decorator'
import { Getter, Mutation } from 'vuex-class'
import { URLS } from '@/router/routes/urlRoutes'
import { RouteConfig } from 'vue-router'

const entitiesModule = ModuleNamespaces.ENTITIES
const formsModule = ModuleNamespaces.FORMS
const spinnerModule = ModuleNamespaces.SPINNER

@Component
export default class ActionsView extends Vue {
  componentKey: number = 0

  @Mutation('SAVE_MENU_PARENT_CATEGORY_NAME', { namespace: entitiesModule })
  saveMenuParentCategoryName: (parentCategoryName: string) => void

  @Mutation('RESET_REGISTER_FORM_DATA', { namespace: formsModule })
  resetFormData: () => void

  @Getter('getShowSpinnerLayer', { namespace: spinnerModule })
  showSpinnerLayer: boolean

  @Watch('$route')
  async onRouteChange(to: RouteConfig, from: RouteConfig) {
    if (to.name !== from.name || this.$route.params.selectedRegisterId === URLS.NEW) {
      this.resetFormData()
      this.componentKey++
    }
  }

  isForm() {
    return this.$route.params.selectedRegisterId || this.$route.meta?.title === URLS.NEW
  }

  created() {
    this.saveMenuParentCategoryName(ParentCategory.ACTIONS)
  }
}
</script>
