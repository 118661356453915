import { http, wopi } from '@/plugins/axios'

export class DocumentOnlineService {
  constructor() {}

  getRequestToken(url: string, params: any) {
    return wopi.post(url, params)
  }
  getAppType(url: string, params: any) {
    return wopi.post(url, params)
  }
  getFileToken(url: string) {
    return http.get(url)
  }
}
