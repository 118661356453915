<template lang="pug">
  div(class="periodic-billing")
    SpinnerLayerComponent(v-if="loadingData" class="spinner-layer")
    FormGeneratorComponent(
      v-if="formSchema"
      :schema="formSchema"
      :fieldValues="formData"
      :context="context"
      :permissionsEntity="permissionsEntity"
      @saveFormData="onChangeFormFieldValue"
    )

</template>

<script lang="ts">
import { Component, Watch } from 'vue-property-decorator'
import { mixins } from 'vue-class-component'
import FormGeneratorComponent from '@/components/forms/FormGenerator/FormGeneratorComponent.vue'
import TaxesDynamicFormFieldsMixin from '@/mixins/TaxesDynamicFormFields/TaxesDynamicFormFieldsMixin.vue'
import { ContextName, ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import { Action, Getter, Mutation } from 'vuex-class'
import { ConfigurationTypes } from '@/store/modules/configuration/configurationTypes'
import SpinnerLayerComponent from '@/components/Spinner/SpinnerLayerComponent.vue'
import { RenderedForm } from '@/components/expedients/ExpedientPeriodicBilling/types/ExpedientPeriodicBillingComponentTypes'
import { TaxesFormFields } from '@/mixins/TaxesDynamicFormFields/types/TaxesDynamicFormFieldsMixinTypes'

const configurationModule = ModuleNamespaces.CONFIGURATION
const formsModule = ModuleNamespaces.FORMS
const selectedRegisterModule = ModuleNamespaces.SELECTED_REGISTER
const tabsModule = ModuleNamespaces.TABS

@Component({
  components: {
    FormGeneratorComponent,
    SpinnerLayerComponent
  }
})
export default class ExpedientPeriodicBillingComponent extends mixins(TaxesDynamicFormFieldsMixin) {
  @Getter('getCurrentViewConfiguration', { namespace: configurationModule })
  getCurrentViewConfiguration: (context: string) => []
  @Getter('getSelectedRegisterPeriodicBillingData', { namespace: selectedRegisterModule })
  periodicBillingData: {}

  @Getter('checkIfFormIsValid', { namespace: formsModule })
  checkIfFormIsValidGetter: (context: string) => boolean

  @Action('fetchCurrentViewConfiguration', { namespace: configurationModule })
  fetchCurrentViewConfigurationAction: ({}) => Promise<{}>
  @Action('prepareRegisterFormDataToSave', { namespace: formsModule })
  prepareFormData: ({}) => void

  @Mutation('CHANGE_DISABLED_TABS', { namespace: tabsModule })
  changeDisplayTabs: (status: boolean) => void
  @Mutation('RESET_CURRENT_VIEW_CONFIGURATION', { namespace: configurationModule })
  resetViewConfiguration: (context: string) => void

  context = ContextName.PERIODIC_BILLING_TAB

  loadingData = true

  get formSchema() {
    return this.getCurrentViewConfiguration(this.context)
  }

  get formData() {
    return { ...this.periodicBillingData }
  }

  get checkIfFormIsValid() {
    return this.checkIfFormIsValidGetter(this.context)
  }

  get permissionsEntity() {
    return this.$route.meta!.entityType
  }

  @Watch('checkIfFormIsValid', { immediate: true })
  enableOrDisableTabs(value: boolean) {
    this.changeDisplayTabs(!value)
  }

  async created() {
    await this.fetchCurrentViewConfigurationAction({
      objectType: ConfigurationTypes.VIEW,
      alias: RenderedForm.EXPEDIENT_PERIODIC_BILLING_FORM,
      context: this.context
    })
    this.loadingData = false
  }

  beforeDestroy() {
    this.resetViewConfiguration(this.context)
    this.changeDisplayTabs(false)
  }

  async onChangeFormFieldValue(schema: [], formData: object, field: any, value: any) {
    const formDataWithDefaultSwithValue = {
      ...formData,
      periodicBillingSwitch: 1
    }

    this.prepareFormData(formDataWithDefaultSwithValue)

    if (TaxesFormFields.PERIODIC_BILLING_ID_ECONOMIC_DATA_TYPE === field.id) {
      const isPeriodicBillingTab = true
      this.addTaxesFields(schema, value, 6, isPeriodicBillingTab)
    }

    // Charged date field logic when payment type provission selected
    if (TaxesFormFields.CHARGED === field.id) {
      this.addChargedDate(schema, value, 7)
    }
  }
}
</script>

<style lang="scss" scoped>
.periodic-billing {
  .spinner-layer {
    --spinner-layer-min-height: 470px;
  }
}
</style>
