import { Module } from 'vuex'
import { getters } from '@/store/modules/expedients/expedientsGetters'
import { actions } from '@/store/modules/expedients/expedientsActions'
import { mutations } from '@/store/modules/expedients/expedientsMutations'
import { ExpedientsState } from '@/store/modules/expedients/expedientsTypes'
import { RootState } from '@/store/types/storeGlobalTypes'

export const state: ExpedientsState = {
  actionsSummary: [],
  duplicateExpedientId: null,
  economicDataSimpleGridItems: [],
  expedientProfitabilityDetail: {},
  expedients: [],
  lastExpedientSaved: null,
  lastListExpedientFilter: [],
  sectionsToDuplicate: [],
  selectedExpedientInsurer: {},
  stageStatus: [],
  stageStatusForm: '',
  summary: [],
  taxesItems: [],
  total: '',
  expedientCustomerFact: null
}

const namespaced: boolean = true

export const expedientsModule: Module<ExpedientsState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations
}
