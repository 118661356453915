import { extend } from 'vee-validate'
import { i18n } from '@/plugins/vue-i18n'

const onlyCharnumber = async (code: string) => {
  const regexPattern = new RegExp('^[A-Za-zÀ-ÿ0-9]+$')
  return regexPattern.test(code)
}

extend('onlyCharnumber', {
  validate: onlyCharnumber,
  message: i18n.t('validations.only_charnumber') as string
})
