import { Route } from 'vue-router'
import store from '@/store/store'
import { ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import { loadInitConfig } from '@/router/services/InitializerService'
import { URLS } from '@/router/routes/urlRoutes'

export const nextUrlGuard = async (to: Route, _from: Route, next: any): Promise<void> => {
  const nextUrl = store.getters[`${ ModuleNamespaces.ROUTER }/getNextUrl`]
  if (nextUrl && to.name === URLS.DASHBOARD) {
    store.dispatch(`${ ModuleNamespaces.ROUTER }/resetNextUrl`)
    await loadInitConfig()

    next({
      path: nextUrl
    })
  }
  next()
}


