<template lang="pug">

  DashboardWidgetListType(
    class="dashboard-widget"
    v-bind="lastExpedientsDashboardListProps"
  )

</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import { DashboardListProps } from '@/store/modules/dashboardWidgets/dashboardWidgetsTypes'
import DashboardWidgetListType from '@/components/widgets/DashboardWidgets/widgetTypes/DashboardWidgetListType.vue'
import { Action, Getter } from 'vuex-class'
import { ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import { DashboardExpedient } from '@/store/modules/dashboardWidgets/dashboardWidgetsTypes'
import { entity } from '@/store/modules/entities/entitiesTypes'
import { URLS } from '@/router/routes/urlRoutes'
import { SpinnerDashboardContext } from '@/store/modules/spinner/spinnerTypes'
import { Icons } from '@/icons/icons'

const dashboardWidgets: string = ModuleNamespaces.DASHBOARD_WIDGETS
const spinnersModule: string = ModuleNamespaces.SPINNER

@Component({
  components: {
    DashboardWidgetListType
  }
})
export default class DashboardLastExpedientsListWidget extends Vue {
  @Getter('getDashboardLastExpedientsList', { namespace: dashboardWidgets })
  lastExpedientsListGetter: DashboardExpedient[]

  @Getter('getDashboardWidgetsSpinner', { namespace: spinnersModule })
  showSpinner: (context: string) => boolean

  @Action('fetchDashboardLastExpedientsList', { namespace: dashboardWidgets })
  fetchDashboardLastExpedientsListAction: () => DashboardExpedient[]

  get lastExpedientsDashboardListProps(): DashboardListProps {
    return {
      items: this.lastExpedientsListGetter,
      entity: entity.expedients.alias,
      headers: [
        'components.dashboard_widget_list_type.expedients.headers.reference',
        'components.dashboard_widget_list_type.expedients.headers.description',
        'components.dashboard_widget_list_type.expedients.headers.customer',
        'components.dashboard_widget_list_type.expedients.headers.update'
      ],
      headerText: 'components.dashboard_widget_list_type.expedients.last_expedients',
      url: `/${URLS.EXPEDIENTS}`,
      showSpinner: this.showSpinner(SpinnerDashboardContext.LAST_EXPEDIENTS_LIST),
      noResultIcon: Icons.EXPEDIENT,
      goToAllText: this.$t('components.dashboard_widget_list_type.expedients.go_to_all_text') as string
    }
  }

  created() {
    this.fetchDashboardLastExpedientsListAction()
  }
}
</script>

<style lang="scss" scoped>
.dashboard-widget {
  ::v-deep .table-header tr.table-row:last-child th {
    border-bottom-color: $expedient-color !important;
  }
}
</style>
