import { Module } from 'vuex'
import { getters } from '@/store/modules/selectedRegister/selectedRegisterGetters'
import { actions } from '@/store/modules/selectedRegister/selectedRegisterActions'
import { mutations } from '@/store/modules/selectedRegister/selectedRegisterMutations'
import { SelectedRegisterState } from '@/store/modules/selectedRegister/selectedRegisterTypes'
import { RootState } from '@/store/types/storeGlobalTypes'

export const state: SelectedRegisterState = {
  selectedRegisterData: {},
  selectedRelatedData: {},
  selectedRegisterId: {},
  parentRegisterId: 0
}

const namespaced: boolean = true

export const selectedRegisterModule: Module<SelectedRegisterState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations
}
