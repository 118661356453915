import { GetterTree } from 'vuex'
import { SelectedRegisterState } from '@/store/modules/selectedRegister/selectedRegisterTypes'
import { RootState, ModuleNamespaces, ContextName } from '@/store/types/storeGlobalTypes'

const configurationModule = ModuleNamespaces.CONFIGURATION

export const getters: GetterTree<SelectedRegisterState, RootState> = {
  tabsViewFormWithSelectedRegisterData: (state, _getters, rootState) => (context: string, formName: string) => {
    const tabsViewConfiguration = rootState[configurationModule].currentViewConfiguration[context]

    if (!tabsViewConfiguration) {
      return {}
    }

    let formFieldsInfo: any[] = []
    if (tabsViewConfiguration.tabs) {
      const tabs = tabsViewConfiguration.tabs

      for (const tab of tabs) {
        for (const item of tab.items) {
          if (item.name === formName) {
            formFieldsInfo = item.fields
            break
          }
          if (formFieldsInfo.length) {
            break
          }
        }
      }
    } else if (Array.isArray(tabsViewConfiguration)) {
      const items: any = tabsViewConfiguration
      for (const item of items) {
        if (item.name === formName) {
          formFieldsInfo = item.fields
          break
        }
      }
    }

    if (!formFieldsInfo) {
      return {}
    }

    const formFieldNamesAndPreloadedValuesFromBBDD: Array<{ name: string; value: string }> = []
    formFieldsInfo.forEach((field: any) => {
      if (field.name) {
        formFieldNamesAndPreloadedValuesFromBBDD.push({ name: field.name, value: field.value })
      } else {
        field.fields.forEach((field: any) => {
          formFieldNamesAndPreloadedValuesFromBBDD.push({ name: field.name, value: field.value })
        })
      }
    })

    const objectWithFieldValues = {} as any
    if (state.selectedRegisterData[context]) {
      formFieldNamesAndPreloadedValuesFromBBDD.forEach((field) => {
        objectWithFieldValues[field.name] = (state as any).selectedRegisterData[context][field.name] || field.value
      })
      return objectWithFieldValues
    } else {
      return {}
    }
  },
  relatedFormWithSelectedRegisterData(state, _getters, rootState) {
    const formItems: any = {}

    if (!rootState[configurationModule].relatedFormConfiguration.length) {
      return []
    }

    const formConfig = JSON.parse(rootState[configurationModule].relatedFormConfiguration[0].config)
    formConfig.map((i: any) => {
      if (i.fieldset) {
        return i.fields
      }
    })
    formConfig.forEach((item: any) => {
      if (item.fieldset) {
        item.fields.forEach((i: any) => {
          formConfig.push(i)
        })
      }
    })

    formConfig.forEach((item: any) => {
      switch (item.fieldType) {
        case 'LexonAutocompleteComponent':
          let lexAutoComItem = null
          if ((state as any).selectedRelatedData[item.loadRelatedField]) {
            lexAutoComItem = {
              id: String((state as any).selectedRelatedData[item.loadRelatedField]),
              alias: (state as any).selectedRelatedData.alias
            }
            if (item.returnObject === false) {
              formItems[item.name] = lexAutoComItem.id
            } else {
              formItems[item.name] = lexAutoComItem
            }
          }
          break
        case 'LexonSelectComponent':
          if ((item.isMultiselect || item.isColorSelector) && (state as any).selectedRelatedData[item.id]) {
            const data = JSON.parse((state as any).selectedRelatedData[item.id])
            formItems[item.name] = data
          } else if ((state as any).selectedRelatedData[item.id]) {
            const lexSelComItem = {
              id: (state as any).selectedRelatedData[item.id],
              name: (state as any).selectedRelatedData.name || (state as any).selectedRelatedData[item.name]
            }
            if (item.returnObject === false) {
              formItems[item.name] = lexSelComItem.id
            } else {
              formItems[item.name] = lexSelComItem
            }
          }
          break
        default:
          formItems[item.name] = (state as any).selectedRelatedData[item.name]
          break
      }
    })
    return formItems
  },
  getSelectedRegisterData: (state, _getters) => (context: string) => {
    if (state.selectedRegisterData[context]) {
      return state.selectedRegisterData[context]
    }
    return {}
  },
  getSelectedRegisterId: (state) => (context: string) => {
    return state.selectedRegisterId[context]
  },
  getSelectedRegisterPeriodicBillingData(state) {
    if (state.selectedRegisterData[ContextName.EXPEDIENTS]) {
      return (state as any).selectedRegisterData[ContextName.EXPEDIENTS].periodicBilling
    }
    return {}
  },
  getSelectedRegisterName: (_state, getters) => (context: string) => {
    const data = getters.getSelectedRegisterData(context)
    if (data && data.name) {
      return data.name
    }
    return ''
  },
  getSelectedRegisterEmail: (_state, getters) => (context: string) => {
    const data = getters.getSelectedRegisterData(context)
    if (data && data.email) {
      return data.email
    }
    return ''
  },
  getSelectedRelatedData(state) {
    return state.selectedRelatedData
  },
  getAllSelectedRegisterData(state) {
    return state.selectedRegisterData
  },
  getAllSelectedRegisterId(state) {
    return state.selectedRegisterId
  },
  getParentRegisterId(state) {
    return state.parentRegisterId
  }
}
