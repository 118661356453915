<template lang="pug">
  div(class="file-manager-container")

    div
      form(
        ref="form"
        :action="urlService"
        method="post"
        enctype="application/x-www-form-urlencoded"
        name="editFileOnline"
        target="_blank"
      )
        input(
          type="hidden"
          name="AccessToken"
          :value="requestToken"
        )
        input(
          type="hidden"
          name="AccessTokenTtl"
          :value="requestTokenTtl"
        )
        input(
          type="hidden"
          name="FileName"
          :value="fileName"
        )
        input(
          type="hidden"
          name="FileSize"
          :value="fileSize"
        )
        input(
          type="hidden"
          name="FileUrl"
          :value="fileUrl"
        )
        input(
          type="hidden"
          name="FileVersion"
          :value="fileVersion"
        )
        input(
          type="hidden"
          name="Action"
          :value="fileAction"
        )
        input(
          type="hidden"
          name="Application"
          :value="applicationType"
        )
        input(
          type="hidden"
          name="wopiUserId"
          :value="idUser"
        )
        input(
          type="hidden"
          name="OwnerId"
          value="not-defined"
        )
        input(
          type="hidden"
          name="wopiUserName"
          value="es-ES"
        )
        input(
          type="hidden"
          name="wopiLocale"
          value="es-ES"
        )


</template>
<script lang="ts">
import { Component, Vue, Watch } from 'vue-property-decorator'
import { ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import { Getter, Mutation } from 'vuex-class'

const documentOnlineModule = ModuleNamespaces.DOCUMENT_ONLINE
const authModule = ModuleNamespaces.AUTH

@Component
export default class DocumentOnlineComponent extends Vue {
  urlService: string = ''

  @Getter('getRequestToken', { namespace: documentOnlineModule }) requestToken: string
  @Getter('getRequestTokenTtl', { namespace: documentOnlineModule }) requestTokenTtl: string
  @Getter('getFileUrl', { namespace: documentOnlineModule }) fileUrl: string
  @Getter('getFileName', { namespace: documentOnlineModule }) fileName: string
  @Getter('getFileVersion', { namespace: documentOnlineModule }) fileVersion: string
  @Getter('getFileSize', { namespace: documentOnlineModule }) fileSize: string
  @Getter('getApplicationType', { namespace: documentOnlineModule }) applicationType: string
  @Getter('getFileAction', { namespace: documentOnlineModule }) fileAction: string
  @Getter('getUserId', { namespace: authModule }) idUser: number

  @Mutation('RESET_FILE_URL', { namespace: documentOnlineModule })
  resetFileUrl: () => void

  @Watch('fileUrl')
  submitForm() {
    if (this.fileUrl) {
      this.$nextTick(() => {
        ;(this as any).$refs.form.submit()
        this.resetFileUrl()
      })
    }
  }

  mounted() {
    this.urlService = process.env.VUE_APP_WOPI_URL
  }
}
</script>
<style lang="scss"></style>
