import { MutationTree } from 'vuex'
import { NumerationsState } from '@/store/modules/numerations/numerationsTypes'
import { Vue } from 'vue-property-decorator'

export const mutations: MutationTree<NumerationsState> = {
  FETCH_NUMERATIONS_MAINTENANCE(state, { data, context }) {
    Vue.set(state.numerationsMaintenance, context, data)
  },
  FETCH_NUMERATIONS_VALUES(state, data) {
    state.numerationsValues = data
  }
}
