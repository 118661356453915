<template lang="pug">

  DashboardWidgetListType(
    class=".dashboard-widget "
    v-bind="lastDocumentsDashboardListProps"
  )

</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import { DashboardListProps } from '@/store/modules/dashboardWidgets/dashboardWidgetsTypes'
import DashboardWidgetListType from '@/components/widgets/DashboardWidgets/widgetTypes/DashboardWidgetListType.vue'
import { Action, Getter } from 'vuex-class'
import { ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import { entity } from '@/store/modules/entities/entitiesTypes'
import { URLS } from '@/router/routes/urlRoutes'
import { SpinnerDashboardContext } from '@/store/modules/spinner/spinnerTypes'
import { Icons } from '@/icons/icons'
import { DashboardDocument } from '@/store/modules/dashboardWidgets/dashboardWidgetsTypes'

const dashboardWidgetsModule: string = ModuleNamespaces.DASHBOARD_WIDGETS
const spinnersModule: string = ModuleNamespaces.SPINNER

@Component({
  components: {
    DashboardWidgetListType
  }
})
export default class DashboardLastDocumentsListWidget extends Vue {
  @Action('fetchDashboardLastDocumentsList', { namespace: dashboardWidgetsModule })
  fetchDashboardLastDocumentsAction: () => DashboardDocument[]

  @Getter('getDashboardLastDocumentsList', { namespace: dashboardWidgetsModule })
  lastDocumentsListGetter: DashboardDocument[]

  @Getter('getDashboardWidgetsSpinner', { namespace: spinnersModule })
  showSpinner: (context: string) => boolean

  get lastDocumentsDashboardListProps(): DashboardListProps {
    return {
      items: this.lastDocumentsListGetter,
      entity: entity.documents.alias,
      headers: [
        'components.dashboard_widget_list_type.documents.headers.type',
        'components.dashboard_widget_list_type.documents.headers.name',
        'components.dashboard_widget_list_type.documents.headers.creationDate',
        'components.dashboard_widget_list_type.documents.headers.modificationDate'
      ],
      headerText: 'components.dashboard_widget_list_type.documents.last_documents',
      url: URLS.REPOSITORY,
      showSpinner: this.showSpinner(SpinnerDashboardContext.LAST_DOCUMENTS_LIST),
      noResultIcon: Icons.DOCUMENTATION,
      goToAllText: this.$t('components.dashboard_widget_list_type.documents.go_to_all_text') as string
    }
  }

  created() {
    this.fetchDashboardLastDocumentsAction()
  }
}
</script>

<style lang="scss" scoped>
.dashboard-widget {
  ::v-deep .table-header tr.table-row:last-child th {
    border-bottom-color: $document-color !important;
  }
}
</style>
