<template lang="pug">

  ValidationProvider(
    :rules="addRegexToValidationRules"
    v-slot="{ errors }"
    tag="div"
    :vid="$attrs.id"
  )
    div(
      :class="[{ 'error-styles': ( errors[0] || maxNumberCharactersExceed ) }, { 'hidden-field': hidden }, { 'disabled-field': $attrs.disabled },  'lf-textarea-container']"
    )
      span(:class="[{ 'required-field': requiredField }, 'label-text']") {{ $attrs.label }}
      div(class="lf-textarea-input")
        textarea(
          v-bind="$attrs"
          :class="[{ 'error-styles': errors[0] || maxNumberCharactersExceed }, 'lf-textarea']"
          :value="innerValue"
          @input="emitValue"
          :maxlength="$attrs.maxlength + 1"
        )
        span(
          v-if="value && value.length"
          :class="[icons.clearIcon, 'clean-icon']"
          @click.stop="cleanText"
        )
      div(class="messages-container")
        div(class="messages")
          span(v-if="errors.length" class="alert-message") {{ errors[0] }}
          span(v-else class="help-message") {{ helpText }}
        div(
          v-if="$attrs.maxlength"
          :class="[{ 'limit-reached': maxNumberCharactersExceed }, 'counter']"
        ) {{ value ? value.length : '0' }} / {{ $attrs.maxlength }}

</template>

<script lang="ts">
import { Vue, Component, Prop, Emit, Watch } from 'vue-property-decorator'
import { ValidationProvider } from 'vee-validate'
import { Icons } from '@/icons/icons'
import { regexPattern } from '@/helpers/helpers'

@Component({
  components: {
    ValidationProvider
  }
})
export default class LexonTextAreaComponent extends Vue {
  @Prop({
    type: String
  })
  validationRules!: string

  @Prop({
    type: String,
    default: ''
  })
  value!: string

  @Prop({
    type: String
  })
  helpText!: string

  @Prop({
    type: Boolean,
    default: false
  })
  hidden!: boolean

  icons = {
    clearIcon: Icons.CLOSE_ROUND
  }

  innerValue = ''

  @Emit()
  emitValue(event: Event) {
    const target = event.target as HTMLInputElement
    this.$emit('input', target.value)
  }

  @Emit()
  cleanText() {
    return this.$emit('input', '')
  }

  @Watch('innerValue')
  changedValue(newVal: string) {
    this.$emit('input', newVal)
  }

  @Watch('value')
  changeInnerValue(newVal: string) {
    this.innerValue = newVal
  }

  get maxNumberCharactersExceed() {
    return this.value && this.value.length > Number(this.$attrs.maxlength)
  }

  get requiredField() {
    return this.validationRules && this.validationRules.includes('required') ? true : false
  }

  get addRegexToValidationRules() {
    if (this.validationRules && this.validationRules.length) {
      return `${this.validationRules}|regex:${regexPattern}`
    } else {
      return `regex:${regexPattern}`
    }
  }

  created() {
    if (this.value) {
      this.innerValue = this.value
    }
  }
}
</script>

<style lang="scss" scoped>
.lf-textarea-container {
  @include flex($flex-direction: column, $align-items: flex-start);
  @include textfield-messages;
  position: relative;
  width: 100%;

  &.disabled-field {
    .label-text,
    .messages-container .help-message,
    .messages-container .counter {
      color: $gray-02;
      pointer-events: none;
    }

    &::after {
      color: $gray-02;
    }

    .lf-textarea-input textarea:hover {
      border-color: $gray-03;
      background-color: $gray-04;
    }

    .lf-textarea-input {
      background-color: $gray-04;

      &:hover .clean-icon {
        display: none;
      }
    }

    .required-field.label-text::after {
      color: $gray-02;
    }
  }

  &.hidden-field {
    display: none;
  }

  &.error-styles {
    .label-text {
      color: $error-color;
    }

    .clean-icon {
      color: $error-color;
    }
  }
  .lf-textarea-input {
    width: 100%;
    height: 120px;
    &:hover .lf-textarea {
      border-color: $corporate-color;
      background-color: $blue-07;
    }
    &:hover .clean-icon {
      display: block;
    }
    .lf-textarea {
      @include textfield-styles;
      @include scroll-styles;
      resize: none;
      font-size: 13px;
      height: 120px;
      line-height: 20px;
      padding: 10px 36px 10px 10px;

      &:focus ~ .clean-icon {
        display: block;
      }
    }
  }

  .label-text {
    @include label-text;
  }

  .clean-icon {
    position: absolute;
    top: 30px;
    right: 10px;
    cursor: pointer;
    font-size: 20px;
    color: $corporate-color;
    display: none;
  }

  .messages-container {
    @include flex($justify-content: space-between);
    width: 100%;

    .counter {
      color: $blue-04;
      font-size: 11px;

      &.limit-reached {
        color: $error-color;
      }
    }
  }
}

::-webkit-resizer {
  display: none;
}

.lf-textarea-container::after {
  @include flex;
  position: absolute;
  width: 20px;
  height: 20px;
  bottom: 17px;
  right: 2px;
  font-size: 15px;
  font-family: $lf-icons-font;
  color: $gray-02;
  background-color: transparent;
  content: '\e942';
}

.lf-textarea-container.error-styles::after {
  color: $error-color;
}
</style>
