import { MutationTree } from 'vuex'
import { documentOnlineActions, DocumentOnlineState } from '@/store/modules/documentOnline/documentOnlineTypes'

export const mutations: MutationTree<DocumentOnlineState> = {
  SAVE_ACCESS_TOKEN(state, accessToken) {
    state.accessToken = accessToken
  },
  SAVE_ACCESS_TOKEN_TTL(state, accessTokenTtl) {
    state.accessTokenTtl = accessTokenTtl
  },
  SAVE_APP_TYPE(state, appType) {
    state.applicationType = appType
  },
  SAVE_FILE_URL(state, fileUrl) {
    state.fileUrl = fileUrl
  },
  SAVE_FILE_NAME(state, fileName) {
    state.fileName = fileName
  },
  SAVE_FILE_VERSION(state, fileVersion) {
    state.fileVersion = fileVersion
  },
  SAVE_FILE_SIZE(state, fileSize) {
    state.fileSize = fileSize
  },
  SAVE_FILE_TOKEN(state, fileToken) {
    state.fileToken = fileToken
    if (fileToken.size) {
      state.fileSize = fileToken.size
    }
  },
  SAVE_ACTION(state, action) {
    state.action = action
  },
  SAVE_NEEDS_CONVERSION(state, needsConversion) {
    state.needsConversion = needsConversion
    if (needsConversion) {
      state.action = documentOnlineActions.CONVERT
    }
  },
  SAVE_READ_ONLY(state, readOnly) {
    state.readOnly = readOnly
  },
  RESET_FILE_URL(state) {
    state.fileUrl = ''
  }
}
