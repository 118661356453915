import { ActionTree } from 'vuex'
import { ContextMenuState, contextMenuMutationTypes } from '@/store/modules/contextMenu/contextMenuTypes'
import { RootState } from '@/store/types/storeGlobalTypes'

export const actions: ActionTree<ContextMenuState, RootState> = {
  saveSelectedRegisterInfo({ commit }, register) {
    commit(contextMenuMutationTypes.SAVE_SELECTED_REGISTER_INFO, register)
  },
  setItemsDependingOnWhichGridTableWeWantToShow({ commit }, items) {
    commit(contextMenuMutationTypes.SET_ITEMS, items)
  }
}
