import md5 from 'md5'
import store from '@/store/store'
import { CredentialsPlusCompany, NavisionTokenPlusCompany } from '@/store/modules/auth/authTypes'
import { addTimeZoneOffset } from '@/helpers/dateTime'

export const createToken = (salt: number): string => {
  const currentDate: Date = addTimeZoneOffset(new Date())
  const currentFormattedDate = currentDate.toISOString().substring(0, 10).replace(/-/g, '')
  const token = salt + currentFormattedDate
  return md5(token)
}

export const getDataFromLocalStorage = (dataName: string) => {
  const data: any = localStorage.getItem(dataName)
  return JSON.parse(data)
}

export const makeLoginInJoomlaPlatform = (info: CredentialsPlusCompany): void => {
  const salt = (store.state as any).authModule.salt
  const url: string = `${process.env.VUE_APP_URL}/index.php?option=com_lexon&task=api_security.autologin`
  const email: string = info.email
  const password: string = info.password
  const companyId: number = info.companyId
  const token: string = createToken(salt)
  document.location.href = `${url}&_username=${email}&_password=${password}&idEmpresa=${companyId}&token=${token}`
}

export const makeNavisionLoginInJoomlaPlatform = (info: NavisionTokenPlusCompany): void => {
  const salt = (store.state as any).authModule.salt
  const url: string = `${process.env.VUE_APP_URL}/index.php?option=com_lexon&task=api_security.navisionLogin`
  const navisionToken: string = info.navisionToken
  const companyId: number = info.companyId
  const token: string = createToken(salt)
  const joomlaLoginURL = `${url}&navisionToken=${navisionToken}&idEmpresa=${companyId}&token=${token}`
  document.location.href = joomlaLoginURL
}

const getFloatValue = (value: string | null) => {
  return value ? parseFloat(value) : null
}

export const formatNumberFields = (items: any, fieldsTypeDate: string | string[]) => {
  const formattedItems = items.map((item: any) => {
    if (Array.isArray(fieldsTypeDate)) {
      fieldsTypeDate.forEach((field: any) => {
        item[field] = getFloatValue(item[field])
      })
    } else {
      item[fieldsTypeDate] = getFloatValue(item[fieldsTypeDate])
    }
    return item
  })
  return formattedItems
}

export const regexPattern = '^[\x20-\xA5À-ÿ]+'

export const numberToHourFormat = (value: number) => {
  const hours = Math.floor(value / 60)
  const minutes = value % 60
  return String(hours).padStart(2, '0') + ':' + String(minutes).padStart(2, '0')
}

export const uuid = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (char) => {
    // tslint:disable-next-line:no-bitwise
    const random = (Math.random() * 16) | 0
    const value = char === 'x' ? random : (random % 4) + 8
    return value.toString(16)
  })
}

export const normalizeText = (text: string) => {
  return text.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
}
