import { Module } from 'vuex'
import { getters } from '@/store/modules/configuration/configurationGetters'
import { actions } from '@/store/modules/configuration/configurationActions'
import { mutations } from '@/store/modules/configuration/configurationMutations'
import { ConfigurationState } from '@/store/modules/configuration/configurationTypes'
import { RootState } from '@/store/types/storeGlobalTypes'

export const state: ConfigurationState = {
  currentListConfiguration: {},
  currentViewConfiguration: {},
  viewsConfiguration: [],
  customConfigText: '',
  editingPeriodicBilling: false,
  enabledPeriodicBillingTab: false,
  lastListConfiguration: {},
  relatedFormConfiguration: [],
  rememberUserCustomConfigurationList: false,
  routeFromName: '',
  userProfileConfiguration: {},
  visiblePeriodicBillingTab: false
}

const namespaced: boolean = true

export const configurationModule: Module<ConfigurationState, RootState> = {
  actions,
  getters,
  mutations,
  namespaced,
  state
}
