<template lang="pug">

  div(class="total-item" :class="[breakpointClass, item.alias]")
    span(:class="[getIcon(item.alias), 'total-icon']")
    div(class="total-data-container")
      div(class="total-title") {{ item.detail }}
      div(class="total-quantity") {{ item[fieldTotalName] }}

</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator'
import { Icons } from '@/icons/icons'
import { TotalItem } from '@/store/modules/billing/billingTypes'

@Component
export default class TotalItemComponent extends Vue {
  @Prop({
    type: Object,
    required: true
  })
  item!: TotalItem

  @Prop({
    type: String,
    default: 'totalAmount'
  })
  fieldTotalName!: string

  get breakpointClass() {
    switch (this.$vuetify.breakpoint.name) {
      case 'xs':
        return 'xs-breakpoint'
      case 'sm':
        return 'sm-breakpoint'
      case 'md':
        return 'md-breakpoint'
      case 'lg':
        return 'lg-breakpoint'
      case 'xl':
        return 'xl-breakpoint'
    }
  }

  getIcon(alias: string) {
    switch (alias) {
      case 'outstandingPayment':
      case 'totalPending':
      case 'totalProviderBankdraftPending':
      case 'totalCustomerBankdraftPending':
      case 'totalCustomersInvoicesPending':
      case 'feePending':
      case 'provisionsPending':
      case 'billsPending':
      case 'expensesPending':
        return Icons.HAND_SPHERE
      case 'billed':
      case 'totalInvoices':
      case 'totalProvidersInvoices':
      case 'totalProviderBankdraftPaid':
      case 'totalCustomersInvoices':
      case 'totalCustomerBankdraftCharged':
      case 'provisionsBilled':
      case 'feeBilled':
      case 'expedientActionsTotalAmount':
      case 'expedientActionsTotalPricePerHours':
      case 'billsBilled':
      case 'expensesBilled':
        return Icons.BILL
      case 'duePayment':
      case 'totalTaxBase':
      case 'totalProvidersInvoicesTaxBase':
      case 'totalProviderBankdraftExpired':
      case 'totalCustomersInvoicesTaxBase':
      case 'totalAmount':
      case 'totalCustomerBankdraftExpired':
        return Icons.COIN
      case 'totalTaxes':
      case 'totalProvidersInvoicesTaxes':
      case 'totalCustomersInvoicesTaxes':
        return Icons.MONEY_LOCALIZATION
      case 'totalIRPF':
      case 'totalProvidersInvoicesIRPF':
      case 'totalCustomersInvoicesIRPF':
        return Icons.MONEY_BAG
      case 'expedientActionsTotalTime':
        return Icons.ACTIONS
      case 'totalToBill':
        return Icons.HAND_SPHERE
      case 'totalHour':
        return Icons.HOURGLASS
      default:
        return Icons.CIRCLE_O
    }
  }
}
</script>

<style lang="scss" scoped>
.total-item {
  @include flex($justify-content: flex-start);
  color: $white-01;
  font-family: $corporate-font-bold;
  height: 50px;
  background-color: $purple-01;
  padding: 10px;

  &.billed,
  &.totalInvoices,
  &.totalProvidersInvoices,
  &.totalProviderBankdraftPaid,
  &.totalCustomersInvoices,
  &.totalCustomerBankdraftCharged,
  &.provisionsBilled,
  &.feeBilled,
  &.expensesBilled,
  &.billsBilled,
  &.expedientActionsTotalTime {
    background-color: $blue-09;
  }

  &.duePayment,
  &.totalTaxBase,
  &.totalProvidersInvoicesTaxBase,
  &.totalProviderBankdraftExpired,
  &.totalCustomersInvoicesTaxBase,
  &.totalCustomerBankdraftExpired {
    background-color: $blue-02;
  }

  &.totalTaxes,
  &.totalProvidersInvoicesTaxes,
  &.totalCustomersInvoicesTaxes {
    background-color: $blue-03;
  }

  &.totalIRPF,
  &.totalProvidersInvoicesIRPF,
  &.totalCustomersInvoicesIRPF {
    background-color: $blue-04;
  }

  &.xs-breakpoint ~ .total-item {
    margin-top: 4px;
  }

  &.sm-breakpoint {
    width: calc(100% / 4);

    &:not(:nth-child(4)) {
      border-right: 10px solid $gray-04;
    }
  }

  &.md-breakpoint {
    width: calc(100% / 4);

    &:not(:nth-child(4)) {
      border-right: 10px solid $gray-04;
    }
  }
  &.xl-breakpoint {
    max-width: 250px;

    &:not(:last-child) {
      margin-right: 10px;
    }
  }

  .total-icon {
    font-size: 30px;
    opacity: 0.4;
    margin-right: 5px;
  }
  &.lg-breakpoint {
    max-width: 250px;
    .total-icon {
      font-size: 0px;
    }
    &:not(:last-child) {
      margin-right: 5px;
    }
    .total-data-container {
      .total-title {
        font-size: 11px;
        opacity: 0.6;
      }
    }
  }

  .total-data-container {
    @include flex($flex-direction: column, $align-items: stretch);
    min-width: 0;

    .total-title {
      @include ellipsis;
      line-height: 21px;
      font-size: 12px;
      text-transform: uppercase;
      opacity: 0.4;
    }

    .total-quantity {
      @include ellipsis;
      line-height: 20px;
      font-size: 20px;
    }
  }
}
</style>
