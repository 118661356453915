<template lang="pug">

  div(class="custom-fields-playground")

    div(class="row-title")
      span(class="number") 2.
      span(class="row-text") {{ texts.title }}

    div(class="playground-area")
      CustomFieldsListComponent(
        v-if="config.customFields && config.customFields.length"
        v-model="customFields"
      )

      div(v-else class="empty-playground")
        span(:class="[emptyPlaygroundIcon, 'empty-icon']")
        p(class="empty-title") {{ texts.emptyTitle }}
        p(class="empty-message") {{ texts.emptyMessage }}

</template>

<script lang="ts">
import { Icons } from '@/icons/icons'
import { Vue, Component } from 'vue-property-decorator'
import CustomFieldsListComponent from '@/components/customFields/customFieldsPlayground/CustomFieldsList/CustomFieldsListComponent.vue'
import { Getter } from 'vuex-class'
import { CustomFieldsConfig } from '@/store/modules/customFields/customFieldsTypes'
import { ModuleNamespaces } from '@/store/types/storeGlobalTypes'

const customFieldsModule: string = ModuleNamespaces.CUSTOM_FIELDS

@Component({
  components: {
    CustomFieldsListComponent
  }
})
export default class CustomFieldsPlaygroundComponent extends Vue {
  @Getter('getCustomFieldsConfig', { namespace: customFieldsModule })
  config: CustomFieldsConfig

  emptyPlaygroundIcon: string = Icons.SPECIAL_AREA

  get texts() {
    return {
      title: this.$t('components.custom_fields_playground.title'),
      emptyTitle: this.$t('components.custom_fields_playground.empty_title'),
      emptyMessage: this.$t('components.custom_fields_playground.empty_message')
    }
  }

  get customFields() {
    return this.config.customFields
  }
}
</script>

<style lang="scss" scoped>
.custom-fields-playground {
  @include borders($color: $blue-13);
  display: flex;
  flex-direction: column;
  background-color: $blue-07;
  color: $blue-04;
  font-family: $corporate-font;

  .playground-area {
    padding: 0 20px;
  }

  .row-title {
    @include flex;
    align-self: flex-start;
    text-transform: uppercase;
    padding: 20px 20px 6px 20px;

    .number {
      font-family: $corporate-font-bold;
      font-size: 16px;
      margin-right: 6px;
    }

    .row-text {
      font-size: 12px;
      padding-top: 2px;
    }
  }

  .empty-playground {
    @include flex($flex-direction: column);
    min-height: 350px;

    .empty-icon {
      display: inline-block;
      font-size: 100px;
      opacity: 0.3;
      margin-bottom: 14px;
    }

    .empty-title {
      font-family: $corporate-font-medium;
      font-size: 16px;
      text-align: center;
      margin-bottom: 0;
    }

    .empty-message {
      font-family: $corporate-font-medium;
      font-size: 13px;
      text-align: center;
    }
  }
}
</style>
