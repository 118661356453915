<template lang="pug">

  ejs-toolbar(id="customToolbar")
    e-items
      e-item(
        id="closePage"
        :prefixIcon="icons.close"
        :tooltipText="texts.close"
        :click="closeViewerEvent"
      )
      e-item(
        id="previousPage"
        :prefixIcon="icons.previousArrow"
        :tooltipText="texts.previousPage"
        align="Center"
        :cssClass="checkIfPreviousArrowIsDisabled"
        :click="previousClicked"
      )
      e-item(
        id="nextPage"
        :prefixIcon="icons.nextArrow"
        :tooltipText="texts.nextPage"
        align="Center"
        :cssClass="checkIfNextArrowIsDisabled"
        :click="nextClicked"
      )
      e-item(:template="numberOfPagesTemplate" align='Center')

</template>

<script lang="ts">
import { Vue, Component, Emit, Prop } from 'vue-property-decorator'
import { Icons } from '@/icons/icons'

@Component
export default class PdfPreviewToolbarComponent extends Vue {
  disabledClassName = 'disabled'

  @Prop({
    type: Number,
    required: true
  })
  totalNumberOfPages: number

  currentPage = 1

  icons = {
    close: Icons.CLOSE,
    previousArrow: Icons.ANGLE_UP,
    nextArrow: Icons.ANGLE_DOWN
  }

  texts = {
    close: this.$t('components.pdf_viewer.close_document'),
    previousPage: this.$t('components.pdf_viewer.previous_page'),
    nextPage: this.$t('components.pdf_viewer.next_page')
  }

  get numberOfPagesTemplate() {
    return `<div class="pages-template">
              <input type="text" id="currentPage" value="${this.currentPage}" />
              <span class="of">de</span>
              <span class="total-number">${this.totalNumberOfPages}</span>
            </div>
            `
  }

  get checkIfNextArrowIsDisabled() {
    return this.totalNumberOfPages === 1 || this.currentPage === this.totalNumberOfPages ? this.disabledClassName : ''
  }

  get checkIfPreviousArrowIsDisabled() {
    return this.currentPage === 1 ? this.disabledClassName : ''
  }

  @Emit()
  nextPageEvent() {}

  @Emit()
  previousPageEvent() {}

  @Emit()
  closeViewerEvent() {}

  previousClicked() {
    const currentPageInput: HTMLFormElement | null = this.$el.querySelector('#currentPage')
    const nextArrow: HTMLElement | null = this.$el.querySelector(`[title="${this.texts.nextPage}"]`)
    nextArrow!.classList.remove(this.disabledClassName)

    if (currentPageInput && currentPageInput.value > 1) {
      currentPageInput.value = Number(this.currentPage) - 1
      this.currentPage = this.currentPage - 1
      this.previousPageEvent()
    } else {
      const previousArrow: HTMLElement | null = this.$el.querySelector(`[title="${this.texts.previousPage}"]`)
      previousArrow!.classList.add(this.disabledClassName)
    }
  }

  nextClicked() {
    const currentPageInput: HTMLFormElement | null = this.$el.querySelector('#currentPage')
    const previousArrow: HTMLElement | null = this.$el.querySelector(`[title="${this.texts.previousPage}"]`)

    previousArrow!.classList.remove(this.disabledClassName)
    currentPageInput!.value = Number(this.currentPage) + 1
    this.currentPage = this.currentPage + 1
    this.nextPageEvent()
  }
}
</script>

<style lang="scss">
.e-filemanager {
  #customToolbar.e-toolbar {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    height: 33px !important;
    min-height: 33px !important;
    z-index: 999;

    .e-toolbar-items {
      min-height: 33px !important;
      margin: 0;
      background-color: $white-01 !important;

      .e-toolbar-item {
        min-height: 33px !important;
        background-color: $white-01;
        padding: 0;
        margin: 0;

        .e-icon-btn {
          min-height: 43px;
          background-color: $white-01;
          margin: 0;

          .e-btn-icon.e-icons {
            font-family: $lf-icons-font !important;
            font-size: 12px;
            color: $corporate-color !important;
            background-color: $white-01;
            border: 0;
          }
        }
      }

      .e-toolbar-left {
        margin-left: 2px;
        line-height: 33px !important;

        .e-icon-btn {
          min-height: 0 !important;

          .e-btn-icon {
            width: 33px !important;
            height: 33px !important;
          }

          &:hover {
            height: 33px !important;
          }
        }
      }

      .e-toolbar-center {
        @include flex;
        overflow: hidden;

        .e-toolbar-item {
          .e-btn-icon.e-icons {
            color: $gray-01 !important;
          }

          &.disabled {
            pointer-events: none;

            .e-btn-icon.e-icons {
              color: $gray-02 !important;
            }
          }

          &.e-template {
            pointer-events: none;
          }
        }

        .pages-template {
          @include flex;
          font-family: $corporate-font;

          #currentPage {
            @include border;
            text-align: center;
            max-width: 35px;
          }

          .total-number,
          .of {
            margin-left: 8px;
          }
        }
      }
    }
  }
}
</style>
