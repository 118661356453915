import { MutationTree } from 'vuex'
import { TemplateState } from '@/store/modules/template/templateTypes'

export const mutations: MutationTree<TemplateState> = {
  FETCH_TEMPLATE_FIELDS(state, data) {
    state.templateFields = data
  },
  FETCH_TEMPLATE_TREE(state, data) {
    state.templateTree = data
  },
  SHOW_HIDE_TEMPLATE_GENERATOR(state, data) {
    state.showTemplateGenerator = data
  },
  RESET_TEMPLATE(state) {
    state.template = null
    state.templateFields = []
    state.templateId = 0
  },
  RESET_TEMPLATE_TREE(state) {
    state.templateTree = []
  },
  SAVE_TEMPLATE(state, data) {
    state.template = data
  },
  SAVE_TEMPLATE_ID(state, data) {
    state.templateId = data
  },
  SET_TEMPLATE_TREE_ENTITY_TYPE(state, idEntityType) {
    state.templateTreeEntityType = idEntityType
  }
}
