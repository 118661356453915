import { Module } from 'vuex'
import { getters } from '@/store/modules/dialog/dialogGetters'
import { actions } from '@/store/modules/dialog/dialogActions'
import { mutations } from '@/store/modules/dialog/dialogMutations'
import { DialogState } from '@/store/modules/dialog/dialogTypes'
import { RootState } from '@/store/types/storeGlobalTypes'

export const state: DialogState = {
  dialogData: null,
  customDialogData: null,
  showDialog: false,
  showCustomDialog: false
}

const namespaced: boolean = true

export const dialogModule: Module<DialogState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations
}
