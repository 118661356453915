import { Module } from 'vuex'
import { getters } from '@/store/modules/spinner/spinnerGetters'
import { actions } from '@/store/modules/spinner/spinnerActions'
import { mutations } from '@/store/modules/spinner/spinnerMutations'
import { SpinnerState } from '@/store/modules/spinner/spinnerTypes'
import { RootState } from '@/store/types/storeGlobalTypes'

export const state: SpinnerState = {
  spinner: {
    message: false,
    show: false
  },
  showSpinnerLayer: false,
  dashboardWidgetSpinners: {
    amountsOutstandingResume: true,
    customersCounterChart: true,
    customersResume: true,
    expedientsCounterChart: true,
    expedientsResume: true,
    inactiveExpedientsList: true,
    invoicedAmountsResume: true,
    lastActionsList: true,
    lastCustomersList: true,
    lastDocumentsList: true,
    lastExpedientsList: true,
    officeDailyWorkingTimeResume: true,
    officeWeeklyWorkingTimeResume: true,
    officeMonthlyWorkingTimeResume: true,
    recentActivity: true,
    unpaidInvoicesList: true,
    userDailyWorkingTimeResume: true,
    userWeeklyWorkingTimeResume: true,
    userMonthlyWorkingTimeResume: true
  }
}

const namespaced: boolean = true

export const spinnerModule: Module<SpinnerState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations
}
