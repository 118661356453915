<template lang="pug">
  //- Los estilos se aplican en este archivo y en el partial _spinner.css (clase spinner-lefebvre)
  div(class="spinner-layer-container")
    div(class="spinner-lefebvre")
    div(class="spinner-text" v-if="loadingText") {{loadingText}}
    div(class="spinner-image" v-if="spinnerImageSrc")
      img(:src="imgSrc")
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class SpinnerLayerComponent extends Vue {
  @Prop({
    type: String
  })
  loadingText!: string

  @Prop({
    type: String,
    default: ''
  })
  spinnerImageSrc!: string

  get imgSrc() {
    return require(`@/assets/images/logos/${this.spinnerImageSrc}`)
  }
}
</script>

<style lang="scss" scoped>
.spinner-layer-container {
  @include flex;
  flex-direction: column;
  position: var(--spinner-layer-position, relative);
  min-height: var(--spinner-layer-min-height, 100%);
  width: var(--spinner-layer-width, 100%);
  background-color: var(--spinner-layer-background, $white-01);
  z-index: var(--spinner-layer-z-index, 9999);

  & .spinner-text {
    font-family: $corporate-font;
    color: $black-03;
    font-size: 13px;
    margin-top: 4px;
    z-index: 2;
  }

  & .spinner-image {
    margin-top: 10px;
  }
}
</style>
