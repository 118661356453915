import { Module } from 'vuex'
import { getters } from '@/store/modules/numerations/numerationsGetters'
import { actions } from '@/store/modules/numerations/numerationsActions'
import { mutations } from '@/store/modules/numerations/numerationsMutations'
import { NumerationsState } from '@/store/modules/numerations/numerationsTypes'
import { RootState } from '@/store/types/storeGlobalTypes'

export const state: NumerationsState = {
  numerationsMaintenance: {},
  numerationsValues: {}
}

const namespaced: boolean = true

export const numerationsModule: Module<NumerationsState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations
}
