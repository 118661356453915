import { Module } from 'vuex'
import { getters } from '@/store/modules/contextMenu/contextMenuGetters'
import { actions } from '@/store/modules/contextMenu/contextMenuActions'
import { mutations } from '@/store/modules/contextMenu/contextMenuMutations'
import { ContextMenuState } from '@/store/modules/contextMenu/contextMenuTypes'
import { RootState } from '@/store/types/storeGlobalTypes'

export const state: ContextMenuState = {
  selectedRegister: {},
  items: []
}

const namespaced: boolean = true

export const contextMenuModule: Module<ContextMenuState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations
}
