import axios, { AxiosRequestConfig } from 'axios'

const axiosConfiguration: AxiosRequestConfig = {
  baseURL: process.env.VUE_APP_WOPI_URL,
  headers: {
    'Content-Type': 'multipart/form-data'
  }
}

const wopi = axios.create(axiosConfiguration)

export default wopi
