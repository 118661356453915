// https://blog.andrewjamesbibby.com/2019/08/01/cancel-all-pending-axios-requests-in-vue-spa-on-router-change/
import Vue from 'vue'
import Router from 'vue-router'
import PageNotFoundView from '@/views/PageNotFound/PageNotFoundView.vue'
import DashboardView from '@/views/Dashboard/DashboardView.vue'
import SearchView from '@/views/Search/SearchView.vue'
import TabsView from '@/views/Tabs/TabsView.vue'
import LexboxRedirectView from '@/views/Lexbox/LexboxRedirectView.vue'
import LexnetView from '@/views/Lexnet/LexnetView.vue'
import store from '@/store/store'
import { URLS } from '@/router/routes/urlRoutes'
import { ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import { authMutationTypes } from '@/store/modules/auth/authTypes'
import { actionsRoutes } from '@/router/routes/actionsRoutes'
import { billlingRoutes } from '@/router/routes/billingRoutes'
import { contactsRoutes } from '@/router/routes/contactsRoutes'
import { expedientsRoutes } from '@/router/routes/expedientsRoutes'
import { loginRoutes } from '@/router/routes/loginRoutes'
import { maintenanceRoutes } from '@/router/routes/maintenanceRoutes'
import { reportsRoutes } from '@/router/routes/reportsRoutes'
import { repositoryRoutes } from '@/router/routes/respositoryRoutes'
import AuditsView from '@/views/Audits/AuditsView.vue'
import CalculatorsView from '@/views/Calculators/CalculatorsView.vue'
import OnlineView from '@/views/Online/OnlineView.vue'
import { externalApplicationsRoutes } from '@/router/routes/externalApplicationsRoutes'
import { partnerGuard } from '@/router/guards/partner-guard'
import { chatHook } from '@/router/hook/chat-hook'
import { trackViewPageGuard } from '@/router/guards/track-view-page-guard'
import { spinnerGuard } from '@/router/guards/spinner-guard'
import { dmsGuard } from '@/router/guards/dms-guard'
import { alertsGuard } from '@/router/guards/alerts-guard'
import { keepAliveGuard } from '@/router/guards/keep-alive-guard'
import { nextUrlGuard } from '@/router/guards/next-url-guard'
import { loginGuard } from '@/router/guards/login-guard'
import { setPageTitleGuard } from '@/router/guards/set-page-title-guard'
import { cancelRequestGuard } from '@/router/guards/cancel-request-guard'
import { permissionsGuard } from '@/router/guards/permissions-guard'

Vue.use(Router)

export const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    ...actionsRoutes,
    ...billlingRoutes,
    ...contactsRoutes,
    ...expedientsRoutes,
    ...externalApplicationsRoutes,
    ...loginRoutes,
    ...maintenanceRoutes,
    ...reportsRoutes,
    ...repositoryRoutes,
    {
      path: '/',
      redirect: `/${ URLS.LOGIN }`
    },
    {
      path: `/${ URLS.EXTERNAL_LOGOUT }`,
      name: URLS.EXTERNAL_LOGOUT,
      beforeEnter: () => {
        store.commit(`${ ModuleNamespaces.AUTH }/${ authMutationTypes.LOGOUT }`)
      }
    },
    {
      path: `/${ URLS.AGENDA }`,
      name: URLS.AGENDA,
      component: LexboxRedirectView,
      meta: {
        requiresAuth: true,
        title: URLS.AGENDA
      }
    },
    {
      path: `/${ URLS.AGENDA }/:selectedRegisterId`,
      name: `${ URLS.AGENDA }-${ URLS.EDIT }`,
      component: LexboxRedirectView,
      props: (route: { params: { selectedRegisterId: any } }) => ({
        appointmentId: route.params.selectedRegisterId
      }),
      meta: {
        requiresAuth: true,
        title: URLS.AGENDA
      }
    },
    {
      path: `/${ URLS.DASHBOARD }`,
      name: URLS.DASHBOARD,
      component: DashboardView,
      meta: {
        requiresAuth: true,
        title: URLS.DASHBOARD
      }
    },
    {
      path: `/${ URLS.EMAIL }`,
      name: URLS.EMAIL,
      component: LexboxRedirectView,
      meta: {
        requiresAuth: true,
        title: URLS.EMAIL
      }
    },
    {
      path: `/${ URLS.EMAIL }/:idEntity`,
      name: `${ URLS.EMAIL }-${ URLS.EDIT }`,
      component: LexboxRedirectView,
      meta: {
        requiresAuth: true,
        title: URLS.EMAIL
      }
    },
    {
      path: `/${ URLS.EMAIL }/new/action`,
      name: `${ URLS.EMAIL }-${ URLS.NEW }`,
      component: LexboxRedirectView,
      meta: {
        requiresAuth: true,
        title: `${ URLS.EMAIL }-${ URLS.NEW }`
      }
    },
    {
      path: `/${ URLS.EMAIL }/edit/:idAction`,
      name: `${ URLS.EMAIL }-${ URLS.ACTIONS }`,
      component: LexboxRedirectView,
      meta: {
        requiresAuth: true,
        title: URLS.EMAIL
      }
    },
    {
      path: `/${ URLS.SEARCH }/:searchTerm/:tabName`,
      name: URLS.SEARCH,
      component: SearchView,
      meta: {
        requiresAuth: true,
        title: URLS.SEARCH
      },
      props: true
    },
    {
      path: `/${ URLS.LEXNET }`,
      name: URLS.LEXNET,
      component: LexnetView,
      meta: {
        requiresAuth: true,
        title: URLS.LEXNET
      },
      props: true
    },
    {
      path: `/${ URLS.AUDITS }`,
      name: URLS.AUDITS,
      component: AuditsView,
      meta: {
        requiresAuth: true,
        title: URLS.AUDITS
      }
    },
    {
      path: `/${ URLS.NOT_FOUND_PAGE }`,
      name: URLS.NOT_FOUND_PAGE,
      component: PageNotFoundView
    },
    {
      path: '/:selectedMenuEntity/:submenuName/:selectedRegisterId',
      name: URLS.TABS_VIEW,
      props: true,
      component: TabsView,
      meta: {
        requiresAuth: true
      },
      beforeEnter: (to, _from, next) => {
        if (to.meta) {
          to.meta.parentUrl = to.params.selectedMenuEntity
        }
        next()
      }
    },
    {
      path: `/${ URLS.ONLINE }`,
      name: URLS.ONLINE,
      component: OnlineView,
      meta: {
        requiresAuth: true,
        title: URLS.ONLINE
      }
    },
    {
      path: `/${ URLS.CALCULATORS }`,
      name: URLS.CALCULATORS,
      component: CalculatorsView,
      meta: {
        requiresAuth: true,
        title: URLS.CALCULATORS
      }
    },
    {
      path: '*',
      redirect: `/${ URLS.NOT_FOUND_PAGE }`
    }
  ]
})

// GUARDS
router.beforeEach(partnerGuard)         // Partner token
router.beforeEach(permissionsGuard)     // Permissions
router.beforeEach(cancelRequestGuard)   // Cancel pending request
router.beforeEach(setPageTitleGuard)    // Set page title
router.beforeEach(loginGuard)           // Redirect to login when user is not logged
router.beforeEach(nextUrlGuard)         // Redirect to nextUrl: urls called before login
router.beforeEach(keepAliveGuard)       // Keeping session with Joomla by sending keep-alive
router.beforeEach(alertsGuard)          // Update app. alerts
router.beforeEach(dmsGuard)             // Lex-ON DMS: Redirect to repository
router.beforeEach(spinnerGuard)         // Show spinner layer logic
router.beforeEach(trackViewPageGuard)   // Track view page

// HOOKS
router.afterEach(chatHook)              // Render chat
