import { EndpointEntity } from '@/store/modules/endpoint/endpointTypes'

export enum TandemFilterType {
  LIST = 'list',
  DATE_INTERVAL = 'dateInterval',
  SWITCH = 'switch'
}

export interface TandemFilterItem {
  id: number
  name: string
  active: boolean
}

export interface TandemFilter {
  id: string
  name: string
  label: string
  endpoint: string
  endPointEntity: EndpointEntity
  metadataName: string
  type: string
  items?: TandemFilterItem[]
  value: string | boolean | number
  multiSelect?: boolean
  keyValue?: string
}

export interface TandemSelectedFiler {
  tagName: string
  key: string
  value: string | boolean | number
  filter: TandemFilter
}

export interface TandemSearchState {
  data: []
  descriptors: []
  facets: []
  paginationData: PaginationData
  searchTerm: string
  searchMetadata: []
  totalOccurrences: number
  filters: TandemFilter[]
}

export interface PaginationData {
  recsPerPage: number
  numPage: number
}

export const tandemSearchMutationTypes = {
  SET_SEARCH_DATA: 'SET_SEARCH_DATA',
  RESET_SEARCH_DATA: 'RESET_SEARCH_DATA',
  SET_SEARCH_TERM: 'SET_SEARCH_TERM',
  SET_SEARCH_METADATA: 'SET_SEARCH_METADATA',
  SET_FILTERS: 'SET_FILTERS',
  SET_DESCRIPTORS: 'SET_DESCRIPTORS',
  SET_RECSPERPAGE: 'SET_RECSPERPAGE',
  SET_TOTAL_OCCURRENCES: 'SET_TOTAL_OCCURRENCES'
}
