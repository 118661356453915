<template lang="pug">

div(v-if="!showSpinnerLayer" class="search-view-container")

  header(class="search-header")
    p(class="search-results-text") {{ $t('views.search.search_results') }}
    div(class="close-results")
      button(
        @click="returnToUrl"
        :class="[closeIcon, 'close-icon']"
      )

  template(v-if="searchIsFinished")
    TabsComponent(
      ref="tabComponent"
      v-show="existsSomeResultToRender"
      :tabs="tabsWithTotalsIncluded"
      :context="context"
      :allDataLoaded="everythingLoaded"
      :selectedTab= "Number(currentTabInfo.index)"
      :key="componentKey"
      @changed-tab="getSearchListData"
      @set-tandem-filter="setTandemFilter"
    )

    NoResultsComponent(v-show="!existsSomeResultToRender")

</template>

<script lang="ts">
import { Vue, Component, Watch, Prop } from 'vue-property-decorator'
import { Icons } from '@/icons/icons'
import GridTableComponent from '@/components/grids/GridTable/GridTableComponent.vue'
import NoResultsComponent from '@/components/search/NoResults/NoResultsComponent.vue'
import TabsComponent from '@/components/tabs/TabsComponent/TabsComponent.vue'
import { ContextName, ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import { to, from, next } from '@/router/types/routerTypes'
import { Action, Getter, Mutation } from 'vuex-class'
import store from '@/store/store'
import { ConfigurationAlias, ConfigurationTypes } from '@/store/modules/configuration/configurationTypes'
import { URLS } from '@/router/routes/urlRoutes'
import { SearchTab } from '@/store/modules/tabs/tabsTypes'
import { decodeSearchTerm } from '@/helpers/codeTerm'
import { searchAlias } from '@/store/modules/search/searchTypes'
import { formatNumber } from '@/helpers/numeric'
import { UserType } from '@/store/modules/auth/authTypes'
import { Dictionary } from 'vue-router/types/router'

const authModule: string = ModuleNamespaces.AUTH
const configurationModule: string = ModuleNamespaces.CONFIGURATION
const legalSearchModule: string = ModuleNamespaces.LEGAL_SEARCH
const listItemsModule: string = ModuleNamespaces.LIST_ITEMS
const searchModule: string = ModuleNamespaces.SEARCH
const spinnerModule: string = ModuleNamespaces.SPINNER
const tabsModule: string = ModuleNamespaces.TABS
const tandemSearchModule: string = ModuleNamespaces.TANDEM_SEARCH

@Component({
  components: {
    GridTableComponent,
    NoResultsComponent,
    TabsComponent
  }
})
export default class SearchView extends Vue {
  @Prop({
    type: String
  })
  tabName!: string

  @Mutation('RESET_SEARCH_DATA', { namespace: searchModule }) resetSearchData: () => void
  @Mutation('SET_IS_SEARCH_VIEW', { namespace: searchModule }) setSearchView: (status: boolean) => void
  @Mutation('SET_CURRENT_SEARCH_TAB_INFO', { namespace: tabsModule }) setCurrentTabInfo: (
    tabInfo: SearchTab
  ) => Promise<void>
  @Mutation('REMOVE_LIST_ITEMS', { namespace: listItemsModule }) removeListItems: () => []
  @Mutation('RESET_CURRENT_LIST_CONFIGURATION', { namespace: configurationModule }) resetListConfiguration: () => {}
  @Mutation('SET_LIST_ITEMS', { namespace: listItemsModule }) setListItemsDependingOnTab: ([]) => Promise<void>

  @Action('fetchCurrentViewConfiguration', { namespace: configurationModule })
  fetchCurrentViewConfiguration: ({}) => Promise<{}>
  @Action('fetchCurrentListConfiguration', { namespace: configurationModule })
  fetchCurrentListConfiguration: (alias: string) => Promise<{}>
  @Action('fetchSearchTabs', { namespace: legalSearchModule }) fetchSearchTabs: ({}) => Promise<void>
  @Action('fetchSessionId', { namespace: legalSearchModule }) fetchSessionId: (
    encryptedIdProUser: string
  ) => Promise<void>
  @Action('encryptIdProUser', { namespace: authModule }) getIdProUserEncrypted: () => Promise<void>
  @Action('fetchSearchData', { namespace: tandemSearchModule }) fetchTandemSearchData: ({}) => Promise<void>
  @Action('hideSpinnerLayer', { namespace: spinnerModule }) hideSpinnerLayerAction: () => void

  @Getter('getSearchData', { namespace: searchModule }) searchData: any
  @Getter('getCurrentSearchTabInfo', { namespace: tabsModule }) currentTabInfo: SearchTab
  @Getter('getCurrentViewTabs', { namespace: configurationModule }) getCurrentViewTabs: (context: string) => never[]
  @Getter('getTotalOccurrences', { namespace: legalSearchModule }) totalLegalOccurrences: number
  @Getter('getTotalOccurrences', { namespace: tandemSearchModule }) totalTandemOccurrences: number
  @Getter('getSearchTerm', { namespace: searchModule }) getSearchTerm: string
  @Getter('getEncryptedIdProUser', { namespace: authModule }) encryptedIdProUser: any
  @Getter('getSessionId', { namespace: legalSearchModule }) sessionId: string
  @Getter('userHasAccessToLegalProducts', { namespace: authModule }) canViewLegalProducts: boolean
  @Getter('getUserType', { namespace: authModule }) userType: UserType
  @Getter('getShowSpinnerLayer', { namespace: spinnerModule }) showSpinnerLayer: boolean

  context = ContextName.SEARCH

  componentKey = 0

  oneTabHasBeenClicked = false

  searchIsFinished = false

  configuration = {
    type: ConfigurationTypes.VIEW,
    alias: ConfigurationAlias.SEARCH
  }

  closeIcon = Icons.CLOSE_ROUND_FULL

  routeFromName: string | undefined = ''
  routeFromParams: Dictionary<string>

  tabRefreshPromises: any[] = []
  lastRejects: any[] = []

  everythingLoaded = false

  currentTotalTandemOccurrences = 0

  @Watch('$route')
  async fetchNewSearchData() {
    if (!this.oneTabHasBeenClicked) {
      this.componentKey += 1
    }
    this.oneTabHasBeenClicked = false
  }

  get searchTerm() {
    return this.$route.params.searchTerm
  }

  get currentTab() {
    const { name: currentTabName } = this.currentTabInfo
    if (currentTabName === '') {
      return this.tabs![0]
    }
    return this.tabs!.find(({ name }) => name === currentTabName)
  }

  get tabs() {
    const tabs = this.getCurrentViewTabs(this.context)

    if (!tabs) {
      return null
    }

    tabs.map((tab: any, index: number) => (tab.numOrder = index.toString()))
    return tabs.filter(
      (tab: any) =>
        tab.name !== this.$t(`views.search.tab_names.online`) ||
        (tab.name === this.$t(`views.search.tab_names.online`) && this.canViewLegalProducts)
    )
  }

  get tabItems() {
    return this.searchData[this.currentTabInfo?.name]?.items || []
  }

  get tabsWithTotalsIncluded() {
    if (this.tabs?.length) {
      const tabsWithTotals = this.tabs.map((tab: any) => {
        const tabName = this.searchData[tab.name]
        let total = tabName ? tabName.totalReg.toString() : 0
        if (tab.name === this.$t(`views.search.tab_names.online`)) {
          total = this.totalLegalOccurrences
        }
        if (tab.items[0].tabName === this.$t(`views.search.tab_names.tandem`)) {
          total = this.totalTandemOccurrences
        }
        total = formatNumber(total)
        return {
          ...tab,
          total
        }
      })
      return tabsWithTotals
    }
    return null
  }

  get existsSomeResultToRender(): boolean {
    return Array.isArray(this.tabsWithTotalsIncluded) && this.tabsWithTotalsIncluded.some((tab: any) => tab.total > 0)
  }

  beforeRouteEnter(_to: to, from: from, next: next<SearchView>) {
    next(async (vm) => {
      try {
        vm.routeFromName = from.name || ''
        vm.routeFromParams = from.params || ''
        next()
      } catch (error) {}
    })
  }

  async created() {
    this.setSearchView(true)

    await this.getLegalSearchTabs()

    await this.fetchCurrentViewConfiguration({
      objectType: this.configuration.type,
      alias: this.configuration.alias,
      context: this.context
    })

    const { name, numOrder } = this.currentTab as any

    await this.setCurrentTabInfo({ name, index: numOrder })

    await store.dispatch(`${searchModule}/fetchSearchData`, decodeSearchTerm(this.searchTerm))
    await this.fetchTandemSearchData({ term: decodeSearchTerm(this.searchTerm) })

    this.searchIsFinished = true
    this.hideSpinnerLayerAction()
  }

  async getLegalSearchTabs() {
    await this.getIdProUserEncrypted()
    if (!this.sessionId) {
      await this.fetchSessionId(this.encryptedIdProUser)
    }
    await this.fetchSearchTabs({
      term: this.searchTerm,
      sessionId: this.sessionId
    })
  }

  beforeDestroy() {
    this.removeListItems()
    this.resetSearchData()
    this.resetListConfiguration()
    this.setSearchView(false)
  }

  returnToUrl() {
    const redirectUrlName = this.routeFromName || URLS.DASHBOARD
    this.$router.push({ name: redirectUrlName, params: this.routeFromParams })
  }

  async getSearchListData(tabIndex: number, _objectType: string, resolve: any) {
    const currentTabData = (this as any).getCurrentViewTabs(this.context)[tabIndex].items[0]
    const { alias, tabName } = currentTabData

    this.oneTabHasBeenClicked = true
    this.removeListItems()
    this.resetListConfiguration()
    this.setCurrentTabInfo({ name: tabName, index: tabIndex })
    if (this.searchTerm !== this.getSearchTerm) {
      await this.fetchTandemSearchData({ term: decodeSearchTerm(this.searchTerm) })
    }
    await store.dispatch(`${searchModule}/fetchSearchData`, decodeSearchTerm(this.searchTerm))

    this.currentTotalTandemOccurrences = this.totalTandemOccurrences
    await this.getLegalSearchTabs()

    this.$router.replace({
      name: URLS.SEARCH,
      params: {
        searchTerm: this.searchTerm,
        tabName: this.$t(`views.search.tab_names.${tabName}`).toString()
      }
    })
    await this.refreshData(alias, resolve)
    await this.$nextTick()
    this.oneTabHasBeenClicked = false
  }

  async refreshData(alias: string, resolve: any) {
    if (alias !== searchAlias.LEGAL && alias !== searchAlias.TANDEM) {
      await this.fetchCurrentListConfiguration(alias)
      await this.setListItemsDependingOnTab(this.tabItems)
    }
    resolve()
  }

  async setTandemFilter(data: any) {
    await this.fetchTandemSearchData({
      term: decodeSearchTerm(this.searchTerm),
      metadata: Array.from(data.metadata, ([_name, value]) => ({ name: value.name, value: value.value })),
      size: data.size
    })
  }
}
</script>

<style lang="scss" scoped>
.search-view-container {
  width: 100%;
  height: 100%;
  padding: 167px 30px 15px;

  .search-header {
    @include flex($justify-content: space-between);
    font-family: $corporate-font;
    color: $corporate-color;
    text-transform: uppercase;
    padding-bottom: 5px;
  }

  .search-results-text {
    display: flex;
    font-size: 15px;
    margin-bottom: 0;
    font-family: $corporate-font-medium;
    padding-top: 7px;
  }

  .close-results {
    @include flex;
    font-size: 15px;
  }

  .results-icon {
    font-size: 26px;
    margin-right: 14px;
  }

  .close-icon {
    font-size: 35px;
    margin-left: 14px;
    color: $red-01;
  }

  .close-text {
    padding-top: 4px;
  }

  ::v-deep .e-content {
    justify-content: center;
  }
}

@include tabletAndMore {
  .search-view-container {
    padding-top: $app-bar-height + 15px;
  }
}
</style>
