import Vue from 'vue'

export enum customFieldTypesEnum {
  UNDEFINED,
  TEXT,
  NUMERIC,
  CURRENCY,
  DATE,
  LIST,
  BOOLEAN,
  TEXTAREA,
  GROUP,
  NUMERATION
}
export interface CustomFieldListItem {
  id: number
  value: string
  order: number
}

export interface CustomField {
  description: string
  id: number
  idCustomFieldType: customFieldTypesEnum
  idNumerationType: number
  label: string
  length?: number
  name: string
  order: CacheQueryOptions
  required: boolean
  listOptions?: string[]
  multipleSelection?: number
  idList?: number
  uuid: string
}
export interface CustomFieldType {
  name: string
  value: string
}
export interface CustomFieldList extends CustomField {
  idList: number
  items: CustomFieldListItem[]
}

export interface CustomFieldGroup extends CustomField {
  fields: CustomField[]
}

export interface CustomFieldsConfig {
  idEntityType: number
  customFields: CustomField[]
}

export interface CustomFieldTypes {
  id: number
  description: string
  formAlias: string
}

export interface CustomFieldsState {
  customFieldsForm: object
  customFieldsData: object
  customFieldsTypes: CustomFieldTypes[]
  customFieldsConfig?: CustomFieldsConfig | null
  customFieldsOriginalConfig?: CustomFieldsConfig | null
  openCustomFieldsFormDialogFlag: boolean
  modeCustomFieldsFormDialog: boolean
  customFieldFormData: object
  customFieldSelectedData: CustomField | CustomFieldGroup | object
}

export const customFieldsMutationTypes = {
  ADD_CUSTOM_FIELD: 'ADD_CUSTOM_FIELD',
  CLEAR_CUSTOM_FIELD_FORM_DATA: 'CLEAR_CUSTOM_FIELD_FORM_DATA',
  DISCARD_CHANGES_CUSTOM_FIELDS_CONFIG: 'DISCARD_CHANGES_CUSTOM_FIELDS_CONFIG',
  EDIT_CUSTOM_FIELD: 'EDIT_CUSTOM_FIELD',
  FETCH_CUSTOM_FIELDS_CONFIG: 'FETCH_CUSTOM_FIELDS_CONFIG',
  RESET_CUSTOM_FIELDS_CONFIG: 'RESET_CUSTOM_FIELDS_CONFIG',
  FETCH_CUSTOM_FIELDS_DATA: 'FETCH_CUSTOM_FIELDS_DATA',
  FETCH_CUSTOM_FIELDS_FORM: 'FETCH_CUSTOM_FIELDS_FORM',
  FETCH_CUSTOM_FIELDS_ORIGINAL_CONFIG: 'FETCH_CUSTOM_FIELDS_ORIGINAL_CONFIG',
  FETCH_CUSTOM_FIELDS_TYPES: 'FETCH_CUSTOM_FIELDS_TYPES',
  MOVE_CUSTOM_FIELD: 'MOVE_CUSTOM_FIELD',
  REMOVE_CUSTOM_FIELD: 'REMOVE_CUSTOM_FIELD',
  RESET_CUSTOM_FIELDS_DATA: 'RESET_CUSTOM_FIELDS_DATA',
  SAVE_CUSTOM_FIELDS_CONFIG: 'SAVE_CUSTOM_FIELDS_CONFIG',
  SET_CUSTOM_FIELD_FORM_DATA: 'SET_CUSTOM_FIELD_FORM_DATA',
  SET_CUSTOM_FIELD_SELECTED_DATA: 'SET_CUSTOM_FIELD_SELECTED_DATA',
  TOGGLE_CUSTOM_FIELDS_FORM_DIALOG_MODE: 'FORM_DIALOG_MODE',
  TOGGLE_CUSTOM_FIELDS_FORM_DIALOG_FLAG_STATUS: 'TOGGLE_CUSTOM_FIELDS_FORM_DIALOG_FLAG_STATUS'
}

export interface DialogEvent {
  action: string
  formData?: object
}

export enum selectFieldTypes {
  GROUPING = 'grouping',
  FIELDS = 'groupingFields'
}

export interface CustomFieldFormData {
  uuid?: string
  idCustomFieldType?: number
  id?: number
  name?: string
  label: string
  maxCharacters?: number
  grouping?: string
  groupingFields?: string[]
  multipleSelection?: number
  dropdownListOptions?: string[]
  idList?: number
}

export interface FieldsetPayload {
  index: number
  isFieldset: boolean
  position: number
}

export interface FieldItem {
  item: CustomField | CustomFieldGroup
  index: number
}

export interface Field {
  item: FieldItem
  parent?: FieldItem
}

export enum moveDirection {
  UP = 0,
  DOWN = 1
}
export class CustomFieldsClass {
  constructor(private customFields: any = []) {}

  add(newField: CustomFieldGroup, position: any) {
    const { grouping, groupingFields } = position
    if (grouping) {
      const fieldset = this.customFields.find((item: any) => item.uuid === grouping)
      if (fieldset && fieldset.fields) {
        fieldset.fields.push(newField)
      } else {
        Vue.set(fieldset, 'fields', [])
        fieldset.fields.push(newField)
      }
    } else if (groupingFields) {
      const newFieldsetFields: any[] = []

      groupingFields.forEach((uuid: string) => {
        const field: CustomField = Object.assign(this.get(uuid))
        this.delete(uuid)
        newFieldsetFields.push(field)
      })
      newField.fields = newFieldsetFields
      this.customFields.push(newField)
    } else {
      this.customFields.push(newField)
    }
  }

  delete(uuid: string) {
    const { item, parent } = this.getItem(uuid) as Field
    let fieldset = this.customFields
    if (parent) {
      fieldset = this.customFields.find((i: CustomField) => parent && i.uuid === parent.item.uuid)
      return fieldset.fields.splice(item.index, 1)[0]
    } else {
      return this.customFields.splice(item.index, 1)[0]
    }
  }

  edit(editField: CustomFieldGroup, position: any) {
    const { item, parent } = this.getItem(editField.uuid) as Field
    const { grouping, groupingFields } = position

    if (grouping) {
      const fieldset: CustomFieldGroup = this.customFields.find((field: CustomFieldGroup) => field.uuid === grouping)

      this.delete(editField.uuid)
      const fieldsetFields = (fieldset as CustomFieldGroup).fields

      if (fieldsetFields) {
        fieldsetFields.splice(item.index, 0, editField)
      } else {
        Vue.set(fieldset, 'fields', [])
        fieldset.fields.push(editField)
      }
    } else if (groupingFields) {
      const newFieldsetFields: CustomField[] = []
      groupingFields.forEach((uuid: string) => {
        const field: CustomField = this.delete(uuid)
        newFieldsetFields.push(field)
      })

      const fields: CustomField[] = (item.item as CustomFieldGroup).fields

      if (fields) {
        fields
          .filter(
            (field: any) => newFieldsetFields.filter((newField: any) => field.uuid === newField.uuid).length === 0
          )
          .forEach((field: any) => this.customFields.push(field))
      }

      editField.fields = newFieldsetFields
      Vue.set(this.customFields, item.index, editField)
    } else {
      if (parent) {
        this.delete(editField.uuid)
        this.customFields.push(editField)
      } else {
        this.customFields[item.index] = editField
        Vue.set(this.customFields, item.index, editField)
      }
    }
  }

  getItem(uuid: string) {
    let response: Field | object = {}
    this.customFields.forEach((item: any, index: number) => {
      if (item.uuid === uuid) {
        response = { item: { item, index } }
      } else if (item.idCustomFieldType === customFieldTypesEnum.GROUP && item.fields) {
        item.fields.forEach((field: any, i: number) => {
          if (field.uuid === uuid) {
            response = {
              parent: { item, index },
              item: { item: field, index: i }
            }
          }
        })
      }
    })
    return response
  }

  get(uuid: string): CustomField | object {
    let response: CustomField | object = {}
    this.customFields.forEach((item: CustomField) => {
      if (item.uuid === uuid) {
        response = item
      } else {
        if (item.idCustomFieldType === customFieldTypesEnum.GROUP && (item as CustomFieldGroup).fields) {
          ;(item as CustomFieldGroup).fields.forEach((field: CustomField) => {
            if (field.uuid === uuid) {
              response = field
            }
          })
        }
      }
    })
    return response
  }

  move(payload: any, direction: number) {
    const index = typeof payload === 'object' ? payload.index : payload
    const newOrder = [...this.customFields] as any
    const newIndex = direction ? index + 1 : index - 1

    if (typeof payload !== 'object') {
      newOrder[index] = this.customFields[newIndex]
      newOrder[newIndex] = this.customFields[index]
    } else {
      const fieldsetFields = [...newOrder[payload.position].fields]
      fieldsetFields[index] = this.customFields[payload.position].fields[newIndex]
      fieldsetFields[newIndex] = this.customFields[payload.position].fields[index]
      newOrder[payload.position].fields = fieldsetFields
    }

    return newOrder
  }
}
