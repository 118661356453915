export interface CalendarEvent {
  title: string
  type?: string
  start: string
  end?: string
  color?: string
}

export enum CalendarEventColor {
  BLUE = 'azul',
  GRAY = 'gris',
  YELLOW = 'amarillo',
  RED = 'rojo',
  ORANGE = 'naranja',
  GREEN = 'verde',
  NONE = 'ninguno'
}

export const calendarEventColors = {
  orange: {
    textColor: '#735100',
    borderColor: '#cc8f00',
    backgroundColor: 'rgba(255,232,179,.7)'
  },
  gray: {
    textColor: '#545454',
    borderColor: '#bebebe',
    backgroundColor: 'rgba(242,242,242,.7)'
  },
  yellow: {
    textColor: '#665f00',
    borderColor: '#d9ca00',
    backgroundColor: 'rgba(255,250,179,.7)'
  },
  none: {
    textColor: 'white',
    borderColor: '#3a87ad',
    backgroundColor: '#3a87ad'
  },
  blue: {
    textColor: '#006767',
    borderColor: '#00cece',
    backgroundColor: 'rgba(179,247,247,.7)'
  },
  green: {
    textColor: '#4c7300',
    borderColor: '#8c0',
    backgroundColor: 'rgba(229,255,179,.7)'
  },
  red: {
    textColor: '#731d00',
    borderColor: '#ce3300',
    backgroundColor: 'rgba(247,196,179,.7)'
  }
}
