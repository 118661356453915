import { Route } from 'vue-router'
import { URLS } from '@/router/routes/urlRoutes'
import store from '@/store/store'
import { ModuleNamespaces } from '@/store/types/storeGlobalTypes'

export const keepAliveGuard = (to: Route, _from: Route, next: any): void => {
  const routes: string[] = [
    URLS.LOGIN,
    URLS.SELECT_COMPANY,
    URLS.REMEMBER_PASSWORD,
    URLS.NAVISION_LOGIN,
    URLS.DOUBLE_OPTIN
  ]
  const noLaunchKeepAlive: boolean = routes.includes(to.name || '')

  if (!noLaunchKeepAlive) {
    store.dispatch(`${ModuleNamespaces.AUTH}/keepSessionAlive`)
  }
  next()
}


