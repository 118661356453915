import { Module } from 'vuex'
import { getters } from '@/store/modules/customFields/customFieldsGetters'
import { actions } from '@/store/modules/customFields/customFieldsActions'
import { mutations } from '@/store/modules/customFields/customFieldsMutations'
import { CustomFieldsState } from '@/store/modules/customFields/customFieldsTypes'
import { RootState } from '@/store/types/storeGlobalTypes'

export const state: CustomFieldsState = {
  customFieldsForm: [],
  customFieldsData: [],
  customFieldsTypes: [],
  customFieldsConfig: null,
  customFieldsOriginalConfig: null,
  openCustomFieldsFormDialogFlag: false,
  modeCustomFieldsFormDialog: false,
  customFieldFormData: {},
  customFieldSelectedData: {}
}

const namespaced: boolean = true

export const customFieldsModule: Module<CustomFieldsState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations
}
