export interface TotalItem {
  totalAmount: string
  detail: string
  alias: string
}

export enum BillingTypes {
  CUSTOMER_BANKDRAFTS = 'customer_bankdrafts',
  CUSTOMER_INVOICES = 'customer_invoices',
  ORDER_FORMS = 'order_forms',
  PROFORMA_INVOICES = 'proforma_invoices',
  PROVIDER_BANKDRAFTS = 'provider_bankdrafts',
  PROVIDER_INVOICES = 'provider_invoices',
  PROVISIONS = 'provisions'
}

export interface BillingState {
  billing: any[] | null
  lastListBillingFilter: []
  totals: TotalItem[] | null
}

export const billingMutationTypes = {
  SET_LAST_LISTBILLING_FILTER: 'SET_LAST_LISTBILLING_FILTER',
  RESET_TOTALS: 'RESET_TOTALS',
  RESET_BILLING_DATA: 'RESET_BILLING_DATA',
  FETCH_TOTALS: 'FETCH_TOTALS',
  FETCH_BILLING_DATA: 'FETCH_BILLING_DATA'
}
