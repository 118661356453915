import { MutationTree } from 'vuex'
import {
  LegalSearchComposeType,
  LegalSearchDataType,
  LegalSearchFields,
  LegalSearchState,
  LegalSearchTabType,
  LegalSearchTypes,
  LegalSearchTypesTitles
} from '@/store/modules/legalSearch/legalSearchTypes'
import { stripHtml } from '@/helpers/html'
import { formatNumber } from '@/helpers/numeric'

export const legalDataType: LegalSearchComposeType[] = [
  {
    index: LegalSearchTypes.mementos,
    leadInField: null,
    titleField: LegalSearchFields.title1,
    summaryField: LegalSearchFields.summary
  },
  {
    index: LegalSearchTypes.jurisprudencia,
    leadInField: null,
    titleField: LegalSearchFields.title2,
    summaryField: LegalSearchFields.summary
  },
  {
    index: LegalSearchTypes.doctrina,
    leadInField: null,
    titleField: LegalSearchFields.title3,
    summaryField: LegalSearchFields.summary
  },
  {
    index: LegalSearchTypes.legislacion,
    leadInField: LegalSearchFields.leadIn,
    titleField: LegalSearchFields.title4,
    summaryField: LegalSearchFields.summary
  },
  {
    index: LegalSearchTypes.convenios,
    leadInField: LegalSearchFields.leadIn,
    titleField: LegalSearchFields.title5,
    summaryField: LegalSearchFields.summary
  },
  {
    index: LegalSearchTypes.subvenciones,
    leadInField: null,
    titleField: LegalSearchFields.title6,
    summaryField: LegalSearchFields.summary
  },
  {
    index: LegalSearchTypes.comentarios,
    leadInField: LegalSearchFields.leadIn,
    titleField: LegalSearchFields.title7,
    summaryField: LegalSearchFields.summary
  },
  {
    index: LegalSearchTypes.apuntesConsejos,
    leadInField: null,
    titleField: LegalSearchFields.title7,
    summaryField: LegalSearchFields.summary
  },
  {
    index: LegalSearchTypes.apuntesConsejosHerramientas,
    leadInField: null,
    titleField: LegalSearchFields.title7,
    summaryField: LegalSearchFields.summary
  },
  {
    index: LegalSearchTypes.esquemas,
    leadInField: null,
    titleField: LegalSearchFields.title1,
    summaryField: null
  },
  {
    index: LegalSearchTypes.formularios,
    leadInField: LegalSearchFields.leadIn,
    titleField: LegalSearchFields.title8,
    summaryField: LegalSearchFields.summary
  },
  {
    index: LegalSearchTypes.formulariosEfl,
    leadInField: LegalSearchFields.leadIn,
    titleField: LegalSearchFields.title1,
    summaryField: null
  },
  {
    index: LegalSearchTypes.esquemasQuantor,
    leadInField: null,
    titleField: LegalSearchFields.title1,
    summaryField: null
  },
  {
    index: LegalSearchTypes.consultas,
    leadInField: LegalSearchFields.leadIn,
    titleField: LegalSearchFields.title9,
    summaryField: LegalSearchFields.summary
  },
  {
    index: LegalSearchTypes.bibliografia,
    leadInField: null,
    titleField: LegalSearchFields.title1,
    summaryField: LegalSearchFields.summary1
  },
  {
    index: LegalSearchTypes.actualidad,
    leadInField: LegalSearchFields.leadIn,
    titleField: LegalSearchFields.title1,
    summaryField: LegalSearchFields.summary
  },
  {
    index: LegalSearchTypes.ejemplos,
    leadInField: LegalSearchFields.leadIn,
    titleField: LegalSearchFields.title11,
    summaryField: LegalSearchFields.summary
  },
  {
    index: LegalSearchTypes.casosPracticos,
    leadInField: LegalSearchFields.leadIn,
    titleField: LegalSearchFields.title1,
    summaryField: null
  },
  {
    index: LegalSearchTypes.doctrina_libros,
    leadInField: null,
    titleField: LegalSearchFields.title1,
    summaryField: null
  },
  {
    index: LegalSearchTypes.doctrina_articulos,
    leadInField: null,
    titleField: LegalSearchFields.title1,
    summaryField: LegalSearchFields.summary
  },
  {
    index: LegalSearchTypes.otros,
    leadInField: LegalSearchFields.leadIn,
    titleField: LegalSearchFields.title10,
    summaryField: LegalSearchFields.summary
  },
  {
    index: LegalSearchTypes.notas_practicas,
    leadInField: null,
    titleField: LegalSearchFields.title1,
    summaryField: LegalSearchFields.summary
  },
  {
    index: LegalSearchTypes.tendencias_guidance,
    leadInField: null,
    titleField: LegalSearchFields.title1,
    summaryField: LegalSearchFields.summary
  },
  {
    index: LegalSearchTypes.novedades,
    leadInField: null,
    titleField: LegalSearchFields.title1,
    summaryField: LegalSearchFields.summary
  },
  {
    index: LegalSearchTypes.eventos,
    leadInField: null,
    titleField: LegalSearchFields.title12,
    summaryField: LegalSearchFields.summary
  },
  {
    index: LegalSearchTypes.empleoPublico,
    leadInField: null,
    titleField: LegalSearchFields.title1,
    summaryField: null
  }
]

export const setFoundText = (text: string, searchTerms: string[], checkAccents: boolean = true): string => {
  searchTerms.map((searchTerm: string) => {
    const lowerCase = searchTerm.toLowerCase().replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
    const upperCase = searchTerm.toUpperCase().replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
    const capitalize = searchTerm
      .replace(/[a-z\u00E0-\u00FC]|[A-Z\u00C0-\u00DC]/, (l: string) => l.toUpperCase())
      .replace(/[.*+?^${}()|[\]\\]/g, '\\$&')

    const regExpLowerCase = new RegExp(lowerCase, 'g')
    const regExpUpperCase = new RegExp(upperCase, 'g')
    const regExpCapitalize = new RegExp(capitalize, 'g')

    text = text.replace(regExpLowerCase, `<span class="term">${lowerCase}</span>`)
    text = text.replace(regExpUpperCase, `<span class="term">${upperCase}</span>`)
    text = text.replace(regExpCapitalize, `<span class="term">${capitalize}</span>`)

    if (checkAccents) {
      searchTerm.split('').forEach((char: string, pos: number) => {
        if ('aeiouAEIOU'.includes(char)) {
          const charWithAccent = 'áéíóúÁÉÍÓÚ'.charAt('aeiouAEIOU'.indexOf(char))
          const accentSearchTem: string =
            searchTerm.substr(0, pos) + charWithAccent + searchTerm.substr(pos + char.length)
          text = setFoundText(text, [accentSearchTem], false)
        }
      })
    }
  })
  return text
}

export const setOriginalFoundText = (text: string): string => {
  text = text.replace(/<resalte(.+?)>/g, '%1%')
  text = text.replace(/<\/resalte>/g, '%2%')
  text = stripHtml(text)
  text = text.replace(/%1%/g, '<span class="term">')
  text = text.replace(/%2%/g, '</span>')
  return text
}

export const extractSearchTerm = (data: any): string[] => {
  const query: string = data['CONSULTA']
  let extracted: string[] = []

  let match = query.match(/_0_(.*?)_0_/g)
  if (match) {
    extracted = match.map((item: string) => item.replace(/_0_/g, ''))
    extracted = [...new Set(extracted)]
  }

  if (!extracted || !extracted.length) {
    match = query.match(/__(.*?)__/g)
    if (match) {
      extracted = match.map((item: string) => item.replace(/__/g, ''))
      extracted = [...new Set(extracted)]
    }
  }

  if (!extracted || !extracted.length) {
    match = query.match(/FULLTEXT:(.*?)[\s|)!^]/g)
    if (match) {
      extracted = match.map((item: string) => item.replace(/FULLTEXT:|^|\s|\)]/g, ''))
      extracted = [...new Set(extracted)]
    }
  }

  if (extracted.length) {
    extracted = extracted.map((item: string) => item.replace('_ny_', 'ñ').replace(/_/g, ' ').trim())
    extracted = extracted.join(' ').split(' ')
  }

  return extracted
}

export const extractData = (data: any, fieldsSchema: string | null, strip: boolean = true): string => {
  let result = ''

  if (!fieldsSchema || !data) {
    return result
  }

  if (fieldsSchema.includes('+')) {
    fieldsSchema.split('+').map((field) => {
      result += extractData(data, field)
    })
  } else if (fieldsSchema.includes(':')) {
    const fieldIndexed = fieldsSchema.split(':')
    if (fieldIndexed && fieldIndexed[2]) {
      if (
        fieldIndexed[1].toLowerCase() !== 'x' &&
        data[fieldIndexed[0]] &&
        data[fieldIndexed[0]][fieldIndexed[1]] &&
        data[fieldIndexed[0]][fieldIndexed[1]][fieldIndexed[2]]
      ) {
        result += data[fieldIndexed[0]][fieldIndexed[1]][fieldIndexed[2]]
      } else if (data[fieldIndexed[0]]) {
        data[fieldIndexed[0]].map((item: any) => {
          const parts = fieldIndexed[2].split(';')
          if (parts && parts.length === 2 && item[parts[0]]) {
            if (item[parts[1]]) {
              result += item[parts[0]]
            }
          }
        })
      }
    }
  } else {
    if (fieldsSchema.includes('"')) {
      result += fieldsSchema.replace(/"/g, '')
    } else if (data[fieldsSchema]) {
      result += data[fieldsSchema]
    }
  }

  if (strip) {
    return stripHtml(result)
  } else {
    return result
  }
}

export const getSearchTerms = (data: any, searchTerm: string): string[] => {
  let searchTerms: string[] = searchTerm.split(' ')

  let alternativeSearchTerms: string[] = extractSearchTerm(data)

  if (alternativeSearchTerms.length) {
    alternativeSearchTerms = alternativeSearchTerms.filter((term: string) => '' !== term)
    searchTerms = searchTerms.concat(alternativeSearchTerms)
    searchTerms.unshift(searchTerm)
    searchTerms = [...new Set(searchTerms)]
    searchTerms = searchTerms.filter((term: string) => term.length > 2)
  }

  return searchTerms
}

export const createData = (data: any, index: string, searchTerm: string): LegalSearchDataType[] => {
  const legalSearchData: LegalSearchDataType[] = []
  const searchTerms = getSearchTerms(data, searchTerm)
  const field = legalDataType.find((itemData) => itemData.index === index)

  if (field) {
    data['DOCUMENTOS'].map((item: any) => {
      const nRef: string = item['NREF'] ? item['NREF'] : ''
      const searchDataItem: LegalSearchDataType = {
        index: field.index,
        leadIn: '',
        title: '',
        summary: '',
        nRef
      }

      if (field.leadInField) {
        searchDataItem.leadIn = item[field.leadInField]
        if (searchDataItem.leadIn) {
          searchDataItem.leadIn = setFoundText(searchDataItem.leadIn, searchTerms)
        }
      }

      if (field.titleField) {
        searchDataItem.title = extractData(item, field.titleField)
        if (searchDataItem.title) {
          searchDataItem.title = setFoundText(searchDataItem.title, searchTerms)
        }
      }

      if (field.summaryField) {
        searchDataItem.summary = extractData(item, field.summaryField, false)
        if (searchDataItem.summary) {
          searchDataItem.summary = setOriginalFoundText(searchDataItem.summary)
        }
      }

      legalSearchData.push(searchDataItem)
    })
  }
  return legalSearchData
}

export const mutations: MutationTree<LegalSearchState> = {
  SET_SEARCH_DATA(state, { data, index }) {
    state.data = createData(data, index, state.searchTerm)
  },
  SET_SEARCH_TABS(state, tabs) {
    const legalSearchTabs: LegalSearchTabType[] = []
    let totalOccurrences: number = 0
    Object.keys(tabs).map((area) => {
      totalOccurrences += Number(tabs[area])
      const tabTitle = (LegalSearchTypesTitles as any)[area]
      const occurrences = Number(tabs[area])
      const occurrencesFormatted = formatNumber(occurrences)
      const title = `${tabTitle} (${occurrencesFormatted})`
      legalSearchTabs.push({ area, occurrences, title })
    })
    state.tabs = legalSearchTabs
    state.totalOccurrences = totalOccurrences
  },
  SET_SEARCH_SESSION_ID(state, sessionId) {
    const { JSESSIONID } = sessionId
    state.sessionId = JSESSIONID
  },
  RESET_SEARCH_DATA(state) {
    state.data = []
    state.searchTerm = ''
  },
  RESET_SEARCH_TABS(state) {
    state.tabs = []
  },
  RESET_SEARCH_SESSION_ID(state) {
    state.sessionId = ''
  },
  SET_IS_SEARCH_VIEW(state, status) {
    state.isSearchView = status
  },
  SET_SEARCH_TERM(state, term) {
    state.searchTerm = term
  }
}
