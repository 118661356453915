import { MutationTree } from 'vuex'
import { CustomerPortalState } from '@/store/modules/customerPortal/customerPortalTypes'

export const mutations: MutationTree<CustomerPortalState> = {
  CLEAR_CUSTOMER_PORTAL(state) {
    state.customerPortalFormData = []
  },
  FETCH_CUSTOMER_PORTAL(state, customerPortal) {
    state.customerPortalFormData = customerPortal
  },
  SAVE_CUSTOMER_PORTAL(state, customerPortal) {
    state.customerPortalFormData = customerPortal
  }
}
