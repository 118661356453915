import { clone } from '@/helpers/object'
import {
  CustomField,
  customFieldTypesEnum,
  CustomFieldsState,
  CustomFieldGroup,
  CustomFieldFormData,
  CustomFieldsClass
} from '@/store/modules/customFields/customFieldsTypes'
import { OptionItem } from '@/components/forms/fields/LexonDropdownListOptions/types/LexonDropdownListOptionsComponentTypes'
import { MutationTree } from 'vuex'
import { uuid } from '@/helpers/helpers'
import Vue from 'vue'

export const mutations: MutationTree<CustomFieldsState> = {
  FETCH_CUSTOM_FIELDS_FORM(state, data) {
    state.customFieldsForm = data
  },
  FETCH_CUSTOM_FIELDS_DATA(state, data) {
    state.customFieldsData = data
  },
  RESET_CUSTOM_FIELDS_DATA(state) {
    state.customFieldsData = []
  },
  FETCH_CUSTOM_FIELDS_TYPES(state, data) {
    state.customFieldsTypes = data
  },
  FETCH_CUSTOM_FIELDS_CONFIG(state, data) {
    state.customFieldsConfig = data
  },
  RESET_CUSTOM_FIELDS_CONFIG(state) {
    state.customFieldsConfig = null
  },
  FETCH_CUSTOM_FIELDS_ORIGINAL_CONFIG(state, data) {
    state.customFieldsOriginalConfig = clone(data)
  },
  TOGGLE_CUSTOM_FIELDS_FORM_DIALOG_FLAG_STATUS(state) {
    state.openCustomFieldsFormDialogFlag = !state.openCustomFieldsFormDialogFlag
  },
  MODE_CUSTOM_FIELDS_FORM_DIALOG(state, editMode: boolean) {
    state.modeCustomFieldsFormDialog = editMode
  },
  SET_CUSTOM_FIELD_FORM_DATA(state, { customFieldData, parentFieldset }) {
    const formData: CustomFieldFormData = {
      uuid: customFieldData.uuid,
      id: customFieldData.id,
      name: customFieldData.name,
      label: customFieldData.label,
      idCustomFieldType: customFieldData.idCustomFieldType
    }

    if (customFieldData.length) {
      formData.maxCharacters = customFieldData.length
    }

    if (customFieldData.idCustomFieldType === customFieldTypesEnum.LIST) {
      const optionValues = customFieldData.listOptions.map((option: OptionItem) => option.value || option)
      formData.multipleSelection = Number(customFieldData.multipleSelection)
      formData.dropdownListOptions = optionValues
      formData.idList = customFieldData.idList
    }

    if (parentFieldset) {
      formData.grouping = parentFieldset.uuid
    }

    if (customFieldData.idCustomFieldType === customFieldTypesEnum.GROUP && customFieldData.fields) {
      formData.groupingFields = customFieldData.fields.map((field: CustomField) => field.uuid)
    }

    state.customFieldFormData = formData
  },
  SET_CUSTOM_FIELD_SELECTED_DATA(state, customFieldData) {
    state.customFieldSelectedData = customFieldData
  },
  CLEAR_CUSTOM_FIELD_FORM_DATA(state) {
    state.customFieldFormData = {}
  },
  ADD_CUSTOM_FIELD(state, { formData, idCustomFieldType }) {
    const {
      label,
      grouping = null,
      groupingFields = null,
      maxCharacters = null,
      dropdownListOptions = null,
      multipleSelection = null,
      idNumerationType = null
    } = formData
    const customFields = state.customFieldsConfig!.customFields
    const newField = {
      description: label,
      id: customFieldTypesEnum.UNDEFINED,
      idNumerationType,
      idCustomFieldType,
      label,
      name: label,
      order: 0 as CacheQueryOptions,
      required: Boolean(0),
      uuid: uuid()
    } as CustomFieldGroup

    if (maxCharacters) {
      newField.length = maxCharacters
    }

    if (idCustomFieldType === customFieldTypesEnum.LIST) {
      const filterOptions = dropdownListOptions.filter((option: string) => option !== '')
      const formattedOptions = filterOptions.map((option: string) => {
        return {
          id: 0,
          order: 0,
          value: option
        }
      })

      newField.listOptions = formattedOptions
      newField.multipleSelection = multipleSelection || 0
      newField.idList = 0
    }

    if (idCustomFieldType === customFieldTypesEnum.NUMERATION) {
      newField.fields = formData.fields
    }

    const cf = new CustomFieldsClass(customFields)
    cf.add(newField, { grouping, groupingFields })
  },
  EDIT_CUSTOM_FIELD(state, { formData }) {
    const customFields = state.customFieldsConfig!.customFields
    const customFieldSelectedData = state.customFieldSelectedData
    const {
      id,
      name,
      label,
      grouping = null,
      groupingFields = null,
      maxCharacters = null,
      uuid,
      idCustomFieldType,
      dropdownListOptions = null,
      multipleSelection = null,
      idList = null
    } = formData

    const editField = {
      description: label,
      id,
      name,
      idCustomFieldType,
      label,
      order: 0 as CacheQueryOptions,
      required: Boolean(0),
      uuid
    } as CustomFieldGroup

    if (maxCharacters) {
      editField.length = maxCharacters
    }

    if (idCustomFieldType === customFieldTypesEnum.LIST) {
      const { listOptions } = customFieldSelectedData as any
      const formattedOptions = dropdownListOptions
        .filter((option: string) => option)
        .map((option: string, index: number) => {
          const i = listOptions.findIndex((item: any) => item.value === option)
          return {
            id: listOptions[i] ? listOptions[i].id : listOptions[index] ? listOptions[index].id : 0,
            order: listOptions[index] ? listOptions[index].order : 0,
            value: option
          }
        })

      editField.listOptions = formattedOptions
      editField.multipleSelection = multipleSelection || 0
      editField.idList = idList
    }

    const cf = new CustomFieldsClass(customFields)
    cf.edit(editField, { grouping, groupingFields })
  },
  REMOVE_CUSTOM_FIELD(state, { field }) {
    const customFields = state.customFieldsConfig!.customFields
    const cf = new CustomFieldsClass(customFields)
    cf.delete(field.uuid)
  },
  MOVE_CUSTOM_FIELD(state, { payload, direction }) {
    const customFields = state.customFieldsConfig!.customFields
    const cf = new CustomFieldsClass(customFields)
    const newOrder = cf.move(payload, direction)
    Vue.set(state.customFieldsConfig!, 'customFields', newOrder)
  },
  DISCARD_CHANGES_CUSTOM_FIELDS_CONFIG(state) {
    state.customFieldsConfig = clone(state.customFieldsOriginalConfig)
  }
}
