<template lang="pug">

  section(
      v-if="statePortal"
      :class="[{'md-and-up': $vuetify.breakpoint.mdAndUp }, 'customer-portal-container']"
    )
    p(class="intro-text" v-html="$t('components.customer_portal.introduction')")
    div(:class="getClasses")
      span(:class="[iconType, 'access-icon']")
      span(class="access-status-text") {{ renderedText }}
      v-spacer

    //- Email input
    ValidationObserver(
        ref="emailInput"
        v-slot="{ validate }"
        class="email-field"
      )
      LexonTextFieldComponent(
        v-model="email"
        label="Email"
        id="email"
        name="email"
        type="text"
        validationRules="required|email"
        :disabled="!canSave"
      )

    div(class="buttons-container")
      button(
        v-if="credentialsSent && canSave"
        class="secondary-btn"
        @click.stop="askIfWantToDeleteAccess"
      ) {{ this.$t('components.customer_portal.remove_access') }}

      button(
        v-if="accessExists && canSave"
        :class="[{ 'activate-access': blockedAccess }, 'secondary-btn']"
        @click.stop="blockOrActivateAccess"
      ) {{ renderedSecondaryButtonText }}

      button(
        v-if="canSave"
        class="primary-btn"
        @click.stop="executeMainAction"
      ) {{ renderedPrimaryButtonText }}

</template>

<script lang="ts">
import { Mixins, Component, Watch, Prop } from 'vue-property-decorator'
import { Icons } from '@/icons/icons'
import LexonTextFieldComponent from '@/components/forms/fields/LexonTextField/LexonTextFieldComponent.vue'
import { ValidationObserver } from 'vee-validate'
import { Action, Getter } from 'vuex-class'
import { ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import { DialogTypes } from '@/store/modules/dialog/dialogTypes'
import { StatePortal, CustomerPortal } from '@/store/modules/customerPortal/customerPortalTypes'
import PermissionsMixin from '@/mixins/PermissionsMixin.vue'

const dialogModule = ModuleNamespaces.DIALOG
const customerPortalModule = ModuleNamespaces.CUSTOMER_PORTAL
const authModule = ModuleNamespaces.AUTH
const selectedRegisterModule = ModuleNamespaces.SELECTED_REGISTER

@Component({
  components: {
    LexonTextFieldComponent,
    ValidationObserver
  }
})
export default class CustomerPortalComponent extends Mixins(PermissionsMixin) {
  @Prop({
    type: String,
    required: true
  })
  context!: string

  @Prop({
    type: Number
  })
  permissionsEntity!: number

  @Getter('getCustomerPortal', { namespace: customerPortalModule })
  customerPortalData: CustomerPortal

  @Getter('getSelectedRegisterId', { namespace: selectedRegisterModule })
  selectedRegisterId: (context: string) => number

  @Getter('getSelectedRegisterName', { namespace: selectedRegisterModule })
  selectedRegisterName: (context: string) => string

  @Getter('getSelectedRegisterEmail', { namespace: selectedRegisterModule })
  selectedRegisterEmail: (context: string) => string

  @Getter('getCustomerEmailPortal', { namespace: customerPortalModule })
  getCustomerEmailPortal: string

  @Getter('getCustomerPortalStatePortal', { namespace: customerPortalModule })
  statePortal: string | null

  @Getter('getCompanyId', { namespace: authModule })
  selectedCompanyId: number

  @Action('showDialog', { namespace: dialogModule })
  showDialog: ({}) => void

  @Action('saveCustomerPortalData', { namespace: customerPortalModule })
  saveCustomerPortalData: ({}) => void

  @Action('deleteCustomerPortal', { namespace: customerPortalModule })
  deleteCustomerPortal: ({}) => void

  @Action('fetchCustomerPortal', { namespace: customerPortalModule })
  fetchCustomerPortal: (customerId: string | number) => CustomerPortal[]

  $refs!: {
    emailInput: InstanceType<typeof ValidationObserver>
  }

  icons = {
    lock: Icons.LOCK,
    doubleLock: Icons.DOUBLE_LOCK,
    unlock: Icons.UNLOCK
  }

  email: string | null = ''

  get accessExists() {
    switch (this.statePortal) {
      case StatePortal.ACCESS_BLOCKED:
        return true
      case StatePortal.ACCESS_ACTIVATED:
        return true
    }
  }

  get credentialsSent() {
    return this.statePortal !== StatePortal.ACCESS_NOT_SENT
  }

  get blockedAccess() {
    return this.statePortal === StatePortal.ACCESS_BLOCKED
  }

  get iconType() {
    switch (this.statePortal) {
      case StatePortal.ACCESS_BLOCKED:
        return this.icons.lock
      case StatePortal.ACCESS_ACTIVATED:
        return this.icons.unlock
      case StatePortal.ACCESS_NOT_ACTIVATED:
        return this.icons.doubleLock
      case StatePortal.ACCESS_NOT_SENT:
        return this.icons.lock
    }
  }

  get renderedText() {
    switch (this.statePortal) {
      case StatePortal.ACCESS_BLOCKED:
        return this.$t('components.customer_portal.blocked_access') as string
      case StatePortal.ACCESS_ACTIVATED:
        return this.$t('components.customer_portal.access_activated') as string
      case StatePortal.ACCESS_NOT_ACTIVATED:
        return this.$t('components.customer_portal.access_sent') as string
      case StatePortal.ACCESS_NOT_SENT:
        return this.$t('components.customer_portal.no_sent') as string
    }
  }

  get renderedPrimaryButtonText() {
    return this.statePortal === StatePortal.ACCESS_NOT_SENT
      ? this.$t('components.customer_portal.send_credentials_button')
      : this.$t('components.customer_portal.forward_access')
  }

  get renderedSecondaryButtonText() {
    return this.statePortal === StatePortal.ACCESS_BLOCKED
      ? this.$t('components.customer_portal.activate_access')
      : this.$t('components.customer_portal.block_access')
  }

  get getClasses() {
    return [
      { 'credentials-sent': this.statePortal === StatePortal.ACCESS_NOT_ACTIVATED },
      { 'access-exists': this.statePortal === StatePortal.ACCESS_ACTIVATED },
      { 'blocked-access': this.statePortal === StatePortal.ACCESS_BLOCKED },
      'access-container'
    ]
  }

  get dataToSendExceptPortalStatus() {
    return {
      id: this.selectedRegisterId(this.context) || null,
      name: this.selectedRegisterName(this.context) || this.customerPortalData.userPortal || null,
      idCompany: this.selectedCompanyId,
      portalEmail: this.email || this.customerPortalData.emailPortal || null
    }
  }

  get canSave() {
    if (this.permissionsEntity) {
      const { canSave } = this.checkEntityPermissionsGetter(this.permissionsEntity)
      return canSave
    }
    return 1
  }

  @Watch('getCustomerEmailPortal')
  changeEmailValue(value: any) {
    this.email = value
  }

  mounted() {
    this.email = this.getCustomerEmailPortal || this.selectedRegisterEmail(this.context)
  }

  async deleteAccess() {
    await this.deleteCustomerPortal(this.selectedRegisterId(this.context))
    this.fetchCustomerPortal(this.selectedRegisterId(this.context))
  }

  askIfWantToDeleteAccess() {
    this.showDialog({
      type: DialogTypes.INFO,
      message: this.$t('components.customer_portal.delete_access_message'),
      action: this.deleteAccess
    })
  }

  executeMainAction() {
    const dataToSend = {
      ...this.dataToSendExceptPortalStatus,
      portalStatus: StatePortal.ACCESS_NOT_ACTIVATED
    }
    this.sendData(dataToSend)
  }

  blockAccess() {
    const dataToSend = {
      ...this.dataToSendExceptPortalStatus,
      portalStatus: StatePortal.ACCESS_BLOCKED
    }
    this.sendData(dataToSend)
  }

  blockOrActivateAccess() {
    if (this.blockedAccess) {
      const dataToSend = {
        ...this.dataToSendExceptPortalStatus,
        portalStatus: StatePortal.ACCESS_ACTIVATED
      }
      this.sendData(dataToSend)
    } else {
      this.showDialog({
        type: DialogTypes.INFO,
        message: this.$t('components.customer_portal.block_access_message'),
        action: this.blockAccess
      })
    }
  }

  async sendData(dataToSend: object) {
    const validatedForm = await this.$refs.emailInput.validate()

    if (validatedForm) {
      await this.saveCustomerPortalData(dataToSend)
      this.fetchCustomerPortal(this.selectedRegisterId(this.context))
    }
  }
}
</script>

<style lang="scss">
.customer-portal-container {
  @include flex($flex-direction: column);
  max-width: 895px;
  margin: auto;
  margin-bottom: 10px;

  .intro-text {
    font-family: $secondary-font;
    font-size: 13px;
    color: $blue-03;
    line-height: 20px;
    margin-bottom: 0;
  }

  .access-container {
    @include flex;
    width: 100%;
    height: 50px;
    background-color: $blue-02;
    color: $white-01;
    margin: 25px 0 15px;

    .access-icon {
      font-size: 30px;
      margin: 0 30px 0 23px;
    }

    .access-status-text {
      font-size: 14px;
      font-family: $corporate-font-medium;
    }

    &.credentials-sent {
      background-color: $warning-color;
    }

    &.access-exists {
      background-color: $success-color;
    }

    &.blocked-access {
      background-color: $error-color;
    }
  }

  .email-field {
    width: 100%;
  }

  .buttons-container {
    @include flex($flex-direction: column);
    align-self: flex-end;
    width: 100%;

    .secondary-btn {
      @include secondary-action-button--rectangle;
      min-width: 164px;
      margin-left: 0;
      margin-top: 10px;

      &.activate-access {
        @include main-action-button--rectangle;
        min-width: 164px;
        margin-left: 0;
      }
    }

    .primary-btn {
      @include main-action-button--rectangle;
      margin-top: 10px;
      min-width: 164px;
      margin-left: 0;
    }
  }

  &.md-and-up {
    padding: 30px 80px 25px;

    .buttons-container {
      flex-direction: row;
      width: auto;

      .secondary-btn {
        min-width: auto;
        margin-left: 10px;
        margin-top: 30px;

        &.activate-access {
          min-width: auto;
          margin-left: 10px;
        }
      }

      .primary-btn {
        min-width: auto;
        margin-top: 30px;
        margin-left: 10px;
      }
    }
  }
}
</style>
