import { Module } from 'vuex'
import { RootState } from '@/store/types/storeGlobalTypes'
import { EntityState } from '@/store/modules/entities/entitiesTypes'
import { getters } from '@/store/modules/entities/entitiesGetters'
import { mutations } from '@/store/modules/entities/entitiesMutations'
import { actions } from '@/store/modules/entities/entitiesActions'

export const state: EntityState = {
  entity: {},
  idEntityType: 0,
  selectedEntityName: '',
  menuParentCategoryName: ''
}

const namespaced: boolean = true

export const entitiesModule: Module<EntityState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations
}
