<template lang="pug">

  div(class="lexon-dropdown-list-options")

    //- HIDDEN INPUT
    input(
      ref="hiddenInput"
      v-model="listOptions"
      @focus="emitComponentValue"
      class="hidden-input"
    )

    //- LIST OPTIONS
    transition-group(name="reorder-transition" tag="div" class="transition-div")
      div(
        v-for="(item, index) in items"
        class="option"
        :key="item.uuid"
      )
        LexonTextFieldComponent(
        :ref="`option-${index}`"
        :id ="`option-${index}`"
        :name="`option-${index}`"
        :label="texts.optionLabel"
        :validationRules="index === 0 && !items[0].value ? 'required' : ''"
        v-model="item.value"
        class="textfield"
        @blur="addFieldValueToListOptions($event, index)"
      )
        div(class="actions-container")
          button(
            :class="[icons.arrowDown, 'arrow', { disabled: index + 1 >= items.length }]"
            @click.stop="index + 1 >= items.length ? false : move(index, movementDirection.down)"
          )
          button(
            :class="[icons.arrowUp, 'arrow', { disabled: index === 0 }]"
            @click.stop="index === 0 ? false : move(index, movementDirection.up)"
          )
          button(
            :class="[icons.remove, 'round-btn', { disabled: items.length <= defaultVisibleFields }]"
            @click.stop="removeField(index)"
          )

    //- ADD NEW OPTION BOX
    div(class="add-new-option-container")
      p(class="add-new-option-text") {{ texts.addNewOption }}
      button(
        :class="[icons.add, 'round-btn', { 'disabled': disabledAddOptionButton }]"
        @click.stop="addNewOption"
      )

</template>

<script lang="ts">
import { Vue, Component, Emit, Prop } from 'vue-property-decorator'
import LexonTextFieldComponent from '@/components/forms/fields/LexonTextField/LexonTextFieldComponent.vue'
import { Icons } from '@/icons/icons'
import { uuid } from '@/helpers/helpers'
import {
  OptionItem,
  MovementDirection
} from '@/components/forms/fields/LexonDropdownListOptions/types/LexonDropdownListOptionsComponentTypes'

@Component({
  components: {
    LexonTextFieldComponent
  }
})
export default class LexonDropdownListOptionsComponent extends Vue {
  @Prop({
    type: Array
  })
  value!: string[]

  $refs!: {
    hiddenInput: InstanceType<typeof HTMLInputElement>
  }

  listOptions: string[] = []

  editionMode: boolean = false

  defaultItems: OptionItem[] = [
    {
      value: '',
      uuid: uuid()
    },
    {
      value: '',
      uuid: uuid()
    },
    {
      value: '',
      uuid: uuid()
    }
  ]

  items: OptionItem[] = []

  icons = {
    add: Icons.ADD,
    arrowDown: Icons.ARROW_DOWN,
    arrowUp: Icons.ARROW_UP,
    remove: Icons.REMOVE
  }

  movementDirection = {
    up: MovementDirection.UP,
    down: MovementDirection.DOWN
  }

  defaultVisibleFields: number = 3

  selectedOptionPosition: number = 0

  @Emit()
  emitComponentValue() {
    this.setFocusOnTheNextInputOption()
    return this.$emit('input', this.listOptions)
  }

  get texts() {
    return {
      addNewOption: this.$t('components.dropdown_list_options.add_new_option'),
      optionLabel: this.$t('components.dropdown_list_options.option_label')
    }
  }

  get disabledAddOptionButton() {
    if (this.items && this.items.length) {
      const firstItemHasValue = this.items[0].value
      return !firstItemHasValue
    }
  }

  mounted() {
    if (this.value) {
      this.editionMode = true
    }
    this.initListOptionsValues()
  }

  initListOptionsValues() {
    if (this.value && this.value.length) {
      this.value.forEach((item: any, index: number) => {
        this.items.push({
          value: item,
          uuid: uuid()
        })
        if (this.editionMode) {
          this.listOptions[index] = item
        }
      })
      if (this.value.length < this.defaultItems.length) {
        this.addEmptyFieldsOnEditionMode()
      }
    } else {
      this.items = this.defaultItems
    }

    this.items.forEach((item, index) => {
      const itemValue = this.value && this.value[index] ? this.value[index] : ''
      this.addFieldValueToListOptions(itemValue, index)
      if (itemValue) {
        item.value = itemValue
        this.emitComponentValue()
      }
    })
  }

  addFieldValueToListOptions(text: string, position: number) {
    if (text) {
      this.listOptions[position] = text
      this.selectedOptionPosition = position
      this.$refs.hiddenInput.focus()
    } else {
      this.listOptions.splice(position, 1)
    }
  }

  addNewOption() {
    const newOption = {
      value: '',
      uuid: uuid()
    }
    this.items.push(newOption)
    this.moveScrollToBottom()
  }

  move(position: number, direction: string) {
    const newOrder = [...this.items]
    const newIndex = direction === MovementDirection.DOWN ? position + 1 : position - 1
    newOrder[position] = this.items[newIndex]
    newOrder[newIndex] = this.items[position]
    this.items = newOrder
    this.listOptions[position] = this.items[position].value
    this.listOptions[newIndex] = this.items[newIndex].value
    if (this.editionMode) {
      this.$refs.hiddenInput.focus()
    }
  }

  removeField(index: number) {
    if (this.items.length > this.defaultVisibleFields) {
      this.items.splice(index, 1)
      this.listOptions.splice(index, 1)
      this.$refs.hiddenInput.focus()
    }
  }

  setFocusOnTheNextInputOption() {
    const optionField = (this as any).$refs[`option-${this.selectedOptionPosition + 1}`]
    if (optionField) {
      const nextSelectedOptionField = optionField[0].$el
      const nextSelectedOptionInput = nextSelectedOptionField.querySelector('input')
      nextSelectedOptionInput.focus()
    }
  }

  async moveScrollToBottom() {
    await Vue.nextTick
    const optionsContainer: HTMLElement | null = document.querySelector('.transition-div')
    if (optionsContainer) {
      optionsContainer.scrollTo({
        top: 10000,
        behavior: 'smooth'
      })
    }
  }

  addEmptyFieldsOnEditionMode() {
    const numberOfEmptyFieldsToAdd = this.defaultItems.length - this.value.length
    for (let i = 0; i < numberOfEmptyFieldsToAdd; i++) {
      this.items.push({
        value: '',
        uuid: uuid()
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@include reorder-transition;

.lexon-dropdown-list-options.form-element {
  @include flex($flex-direction: column);
  position: relative;

  .hidden-input {
    position: absolute;
    top: 70px;
    z-index: -1;
  }

  .transition-div {
    @include scroll-styles;
    width: 100%;
    height: 222px;
    overflow: auto;
    margin: 8px 0;
    scroll-behavior: smooth;
  }

  .option {
    @include flex;
    width: 100%;
    padding-right: 8px;

    .textfield {
      flex-grow: 1;
    }

    .actions-container {
      @include flex;
      width: 100px;
      height: 40px;
      background-color: $gray-06;
      margin-left: 6px;
      padding-left: 6px;
      border-radius: 20px;

      .arrow {
        @include flex;
        width: 28px;
        height: 28px;
        color: $corporate-color;
        border-radius: 50%;

        &:not(.disabled):hover {
          background-color: $blue-06;
        }

        &.disabled {
          color: $gray-02;
          cursor: inherit;
        }
      }
    }
  }

  .round-btn {
    @include interactive-round-button($size: 28px);

    &::before {
      position: relative;
      font-size: 15px;
      top: -1.5px;
    }
  }

  button.disabled {
    color: $blue-05;
  }

  .add-new-option-container {
    @include border($color: $gray-03);
    @include flex;
    width: 100%;
    padding-bottom: 10px;
    margin: 2px 0 20px;

    .add-new-option-text {
      flex-grow: 1;
      text-transform: uppercase;
      font-size: 12px;
      font-family: $corporate-font-bold;
      color: $gray-02;
      margin: 0;
    }
  }
}
</style>

<style>
.form-content .multiple-selection-switch {
  height: 50px;
}
</style>
