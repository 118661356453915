export interface UsersState {
  users: [] | null
  permissions: [] | null
  originPermissions: [] | null
}

export const usersMutationTypes = {
  FETCH_USERS: 'FETCH_USERS',
  FETCH_PERMISSIONS: 'FETCH_PERMISSIONS',
  RESET_PERMISSIONS: 'RESET_PERMISSIONS',
  RESTORED_PERMISSIONS: 'RESTORED_PERMISSIONS'
}
