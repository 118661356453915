import { ActionTree } from 'vuex'
import { tabsMutationTypes, TabsState } from '@/store/modules/tabs/tabsTypes'
import { RootState } from '@/store/types/storeGlobalTypes'
import { MainService } from '@/services/MainService'

export const actions: ActionTree<TabsState, RootState> = {
  async fetchTabSelectorOptions({ commit }, idEntity: number) {
    try {
      const url = 'tabs/get'
      const { data } = await new MainService().postData(url, { idEntity })
      commit(tabsMutationTypes.FETCH_TAB_SELECTOR_OPTIONS, { idEntity, tabs: data })
    } catch (error) {}
  },
  async saveSelectedTabOptions({}, data) {
    try {
      const url = 'tabs/save'
      await new MainService().postData(url, data)
    } catch (error) {}
  }
}
