import { GetterTree } from 'vuex'
import { FormsState } from '@/store/modules/forms/formsTypes'
import { RootState } from '@/store/types/storeGlobalTypes'

export const getters: GetterTree<FormsState, RootState> = {
  checkIfFormIsValid:
    (state) =>
    (context: string): boolean => {
      return state.formIsValid[context]
    },
  registerNameFilled(state) {
    return (state as any).registerFormData['name'] ? true : false
  },
  getRegisterFormData(state) {
    return state.registerFormData
  },
  showRelatedForm:
    (state) =>
    (context: string): boolean => {
      return state.showRelatedForm[context]
    },
  showAditionalForm:
    (state) =>
    (context: string): boolean => {
      return state.showAditionalForm[context]
    },
  tryToValidateContactForm(state) {
    return state.validateFormFlag
  },
  getLoadingFormData(state) {
    return state.loadingFormData
  },
  getRelatedData: (state) => (relatedAlias: string) => {
    return state.relatedData[relatedAlias]
  },
  getCustomValidateField: (state) => (key: string) => {
    return state.customValidateField[key]
  }
}
