export interface LegalSearchComposeType {
  index: string
  leadInField: string | null
  titleField: string | null
  summaryField: string | null
}

export enum LegalSearchFields {
  title1 = 'POSICION+".- "+TITULO',
  title2 = 'POSICION+".- "+DESCRIPCION+" (EDJ "+NREF+")"',
  title3 = 'POSICION+".- "+TITULO+" (EDD "+NREF+")"',
  title4 = 'POSICION+".- "+TITULO+" (EDL "+NREF+")"',
  title5 = 'POSICION+".- "+TITULO+" ("+BOLETIN+") "+CODIGO',
  title6 = 'POSICION+".- "+TITULO+" (EDS "+NREF+")"',
  title7 = 'POSICION+".- "+TITULO+" (EDC "+NREF+")"',
  title8 = 'POSICION+".- "+TITULO+" (EDF "+NREF+")"',
  title9 = 'POSICION+".- "+TITULO+" (EDE "+NREF+")"',
  title10 = 'POSICION+".- "+TITULO+" (EDO "+NREF+")"',
  title11 = 'POSICION+".- "+FRAGMENTOS:X:CONTENIDO;TITULO',
  title12 = 'POSICION+".- "+FRAGMENTOS:0:CONTENIDO+" (EDH "+NREF+")"',
  leadIn = 'ENTRADILLA',
  summary = 'FRAGMENTOS:0:CONTENIDO',
  summary1 = 'FRAGMENTOS:0:CONTENIDO+" "+AUTORES+" "+FECHA'
}

export enum LegalSearchTypes {
  mementos = 'mementos',
  jurisprudencia = 'jurisprudencia',
  doctrina = 'doctrina',
  legislacion = 'legislacion',
  convenios = 'convenios',
  subvenciones = 'subvenciones',
  comentarios = 'comentarios',
  apuntesConsejos = 'apuntesConsejos',
  apuntesConsejosHerramientas = 'apuntesConsejosHerramientas',
  esquemas = 'esquemas',
  formularios = 'formularios',
  formulariosEfl = 'formulariosEfl',
  esquemasQuantor = 'esquemasQuantor',
  consultas = 'consultas',
  bibliografia = 'bibliografia',
  actualidad = 'actum',
  ejemplos = 'ejemplos',
  casosPracticos = 'casos_practicos',
  doctrina_libros = 'doctrina_libros',
  doctrina_articulos = 'doctrina_articulos',
  otros = 'otros',
  notas_practicas = 'notas_practicas',
  tendencias_guidance = 'tendencias_guidance',
  novedades = 'novedades_guidance',
  eventos = 'eventos',
  empleoPublico = 'empleo_publico'
}

export enum LegalSearchTypesTitles {
  mementos = 'Mementos',
  jurisprudencia = 'Jurisprudencia',
  doctrina = 'Doctrina',
  legislacion = 'Legislación',
  convenios = 'Convenios',
  subvenciones = 'Subvenciones',
  comentarios = 'Comentarios',
  apuntesConsejos = 'Apuntes&Consejos',
  apuntesConsejosHerramientas = 'Modelos y herramientas',
  esquemas = 'Esquemas procesales',
  formularios = 'Expedientes',
  formulariosEfl = 'Formularios editables',
  esquemasQuantor = 'Esquemas',
  consultas = 'Consultas',
  bibliografia = 'bibliografía',
  actum = 'Actualidad',
  ejemplos = 'ejemplos',
  casos_practicos = 'Casos prácticos',
  doctrina_libros = 'Libros',
  doctrina_articulos = 'Artículos Doctrina',
  otros = 'Otra documentación',
  notas_practicas = 'Notas prácticas',
  tendencias_guidance = 'Tendencias',
  novedades_guidance = 'Novedades',
  eventos = 'Eventos',
  empleo_publico = 'Empleo público'
}

export interface LegalSearchTabType {
  area: string
  occurrences: number
  title: string
}

export interface LegalSearchDataType {
  index: string
  leadIn: string | null
  title: string | null
  summary: string | null
  nRef: string
}

export interface LegalSearchState {
  data: LegalSearchDataType[]
  tabs: LegalSearchTabType[]
  sessionId: string
  isSearchView: boolean
  searchTerm: string
  totalOccurrences: number
}

export const legalSearchMutationTypes = {
  SET_SEARCH_TABS: 'SET_SEARCH_TABS',
  SET_SEARCH_DATA: 'SET_SEARCH_DATA',
  SET_SEARCH_SESSION_ID: 'SET_SEARCH_SESSION_ID',
  RESET_SEARCH_TABS: 'RESET_SEARCH_TABS',
  RESET_SEARCH_DATA: 'RESET_SEARCH_DATA',
  RESET_SEARCH_SESSION_ID: 'RESET_SEARCH_SESSION_ID',
  SET_SEARCH_TERM: 'SET_SEARCH_TERM'
}
