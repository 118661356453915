import { MutationTree } from 'vuex'
import { ContextMenuState } from '@/store/modules/contextMenu/contextMenuTypes'

export const mutations: MutationTree<ContextMenuState> = {
  SAVE_SELECTED_REGISTER_INFO(state, register) {
    state.selectedRegister = register
  },
  SET_ITEMS(state, items) {
    state.items = items
  }
}
