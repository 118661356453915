// tslint:disable-next-line
export interface GlobalState {}

export const setDefaultStateModule = (initialState: any) => {
  return initialState
}

export const globalMutationTypes = {
  RESET_MODULE_INITIAL_STATE: 'RESET_MODULE_INITIAL_STATE'
}
