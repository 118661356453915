<template lang="pug">

  div(class="firma-dialog-container")

    //- HEADER
    CustomDialogHeaderComponent(:headerTitle="texts.title")

    p(class="explanation") {{ texts.explanation }}

    ReceiverFieldSelectComponent(
      :source="smsSource"
      @emit-receiver="setReceiver"
    )

    InfoBarComponent(
      :infoText="texts.infoText"
    )

    LexonSwitchComponent(
      v-model="firmaSwitch"
      :class="['switch-field', { 'error-type': showErrorBar }]"
      id="switch"
      :label="texts.firmaSwitchLabel"
      name="switch"
      hide-details
      @input="checkIfRenderErrorStyles"
    )

    InfoBarComponent(
      v-if="showErrorBar"
      :infoText="texts.errorText"
      :type="errorBar"
      class="error-message"
      )

    //- BUTTONS
    CustomDialogActionButtonsComponent(
      :disabledButtons="disabledActionButtonSMS"
      :mainAction="signFirma"
      :primaryButtonText="texts.primaryButtonText"
    )

    //- SPINNER
    SpinnerLayerComponent(v-if="loadingSpinner" class="spinner-layer" :loadingText="spinnerText.sending")

</template>

<script lang="ts">
import { Component, Mixins } from 'vue-property-decorator'
import { Getter } from 'vuex-class'
import FirmaDialogsMixin from '@/mixins/firma/FirmaDialogsMixin.vue'
import { InfoBarTypes } from '@/components/InfoBar/types/InfoBarComponentTypes'
import { ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import { sendTypeSource } from '@/components/Dialog/customDialogs/firma/ReceiverFieldSelectComponent.vue'

const authModule = ModuleNamespaces.AUTH

@Component({
  components: {
    CustomDialogActionButtonsComponent: () =>
      import('@/components/Dialog/CustomDialog/CustomDialogActionButtonsComponent.vue'),
    CustomDialogHeaderComponent: () => import('@/components/Dialog/CustomDialog/CustomDialogHeaderComponent.vue'),
    InfoBarComponent: () => import('@/components/InfoBar/InfoBarComponent.vue'),
    LexonSwitchComponent: () => import('@/components/forms/fields/LexonSwitch/LexonSwitchComponent.vue'),
    ReceiverFieldSelectComponent: () =>
      import('@/components/Dialog/customDialogs/firma/ReceiverFieldSelectComponent.vue'),
    SpinnerLayerComponent: () => import('@/components/Spinner/SpinnerLayerComponent.vue')
  }
})
export default class SendCertifiedSMSFirmaDialogComponent extends Mixins(FirmaDialogsMixin) {
  @Getter('getUserMobilePhone', { namespace: authModule })
  mobilePhone: string

  texts = {
    explanation: this.$t('components.firma_dialogs.send_certified_SMS.explanation'),
    firmaSwitchLabel: this.$t('components.firma_dialogs.send_certified_SMS.firma_switch'),
    primaryButtonText: this.$t('action_buttons.continue'),
    title: this.$t('components.firma_dialogs.send_certified_SMS.title'),
    infoText: this.$t('components.firma_dialogs.send_certified_SMS.info_text'),
    errorText: this.$t('components.firma_dialogs.send_certified_SMS.error_text')
  }

  smsSource = sendTypeSource.SMS

  errorBar = InfoBarTypes.ERROR

  showErrorBar = false

  checkIfRenderErrorStyles() {
    if ((this as any).firmaSwitch === '1') {
      const regexPattern = new RegExp('^[+,0-9]+$')
      this.showErrorBar = !regexPattern.test(this.mobilePhone)
    } else {
      this.showErrorBar = false
    }
  }

  get disabledActionButtonSMS() {
    return !(!!this.selectedReceiver.length && !this.showErrorBar)
  }
}
</script>
