<template lang="pug">
  span(:class="iconClass")
</template>

<script lang="ts">
import { Vue } from 'vue-property-decorator'
import { Component } from 'vue-property-decorator'
import { Icons } from '@/icons/icons'

@Component({
  name: 'documentAttachment'
})
export default class DocumentsAttachmentTemplateComponent extends Vue {
  data: any

  get iconClass() {
    if (this.data.hasDocuments) {
      return Icons.DOCUMENT_ATTACHMENT
    }
    return ''
  }
}
</script>

<style lang="scss" scoped>
.e-templatecell {
  span {
    &:before {
      font-family: $lf-icons-font !important;
      font-size: $icon-size;
      color: $corporate-color;
    }
  }
}
</style>
