import { i18n } from '@/plugins/vue-i18n'

export const MENU_MINI_STATUS_MIN_WIDTH = 960
export const MENU_MINI_STATUS_MAX_WIDTH = 1366

export const userPortalDrawerNames = {
  EXPEDIENTS: i18n.t('routes.user_portal_expedients'),
  CUSTOMERS: i18n.t('routes.user_portal_customer_invoices'),
  PROFORMA: i18n.t('routes.user_portal_proforma_invoices'),
  PROVISIONS: i18n.t('routes.user_portal_provision_invoices')
}
