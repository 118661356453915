export interface SiteMenuItem {
  children: SiteMenuItem[]
  icon: string
  internalUrl: boolean
  target: string
  text: string
  url: string
}

export enum ParentCategory {
  ACTIONS = 'actions',
  BILLING = 'billing',
  CONTACTS = 'contacts',
  EXPEDIENTS = 'expedients',
  MAINTENANCE = 'maintenance',
  PROFESSIONALS = 'professionals',
  REPORTS = 'reports',
  USERS = 'users'
}

export interface MenusState {
  clientMenu: SiteMenuItem[]
  configurationMenu: SiteMenuItem[]
  drawerInMiniStatus: boolean
  siteMenu: SiteMenuItem[]
}

export const menusMutationTypes = {
  FETCH_CLIENT_MENU: 'FETCH_CLIENT_MENU',
  FETCH_CONFIGURATION_MENU: 'FETCH_CONFIGURATION_MENU',
  FETCH_SITE_MENU: 'FETCH_SITE_MENU',
  TOOGLE_DRAWER_STATUS: 'TOOGLE_DRAWER_STATUS'
}
