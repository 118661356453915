<template lang="pug">

  ValidationProvider(
    :rules="validatorRules"
    ref="autocompleteValidator"
    :name="validationName"
    :vid="validationName"
    v-slot="{ errors }"
    tag="div"
  )
    div(
      :class="[{ 'error-styles': errors[0] }, { 'hidden-field': hidden }, { 'disabled-field': disabled },  'lf-autocomplete-container']"
    )
      span(:class="[{ 'required-field': requiredField }, 'label-text']") {{ $attrs.label }}
      v-autocomplete(
        ref="vAutocomplete"
        :value="innerValue"
        :height="height"
        :items="loadedItems"
        :chips="chips"
        single-line
        :deletable-chips="deletableChip"
        :multiple="multiple"
        :class="[{ 'error-styles': errors[0] }, 'lf-autocomplete']"
        :append-icon="icons.arrowDown"
        :error-messages="errors"
        v-on="$listeners"
        hide-details
        :attach="attach"
        :clearable="clearable"
        :id="$attrs.id"
        :name="$attrs.name"
        :placeholder="placeholder"
        :item-text="itemName"
        :item-value="itemValue"
        :return-object="hasRelatedField || returnObject"
        :clear-icon="icons.clearIcon"
        :filter="customFilter"
        :search-input.sync="search"
        autocomplete="off"
        :disabled="disabled"
        @keyup.enter="update"
        :key="componentKey"
      )

        template(v-slot:no-data)
          p(class="no-results-message") {{ $t('components.no_results.no_search_results_text') }}

        template(v-if="showSelectAll" v-slot:prepend-item)
          v-list-item(@click="toggleSelectAll" class="select-all")
            v-list-item-action
              v-icon(class="select-all-icon") {{ allSelected ? icons.close : icons.selectAllEmpty }}
            v-list-item-content
              v-list-item-title {{ selectAllText }}
          v-divider

      div(class="messages-container")
        span(v-if="errors.length" class="alert-message") {{ errors[0] }}
        span(v-else class="help-message") {{ helpText }}

</template>

<script lang="ts">
import FieldBehaviorsMixin from '@/mixins/FieldBehaviorsMixin.vue'
import { Component, Prop, Watch } from 'vue-property-decorator'
import { EndpointEntity } from '@/store/modules/endpoint/endpointTypes'
import { MainService } from '@/services/MainService'
import { Icons } from '@/icons/icons'
import { ValidationProvider } from 'vee-validate'
import { mixins } from 'vue-class-component'
import { clone } from '@/helpers/object'
import { i18n } from '@/plugins/vue-i18n'

@Component({
  components: {
    ValidationProvider
  }
})
export default class LexonAutocompleteComponent extends mixins(FieldBehaviorsMixin) {
  @Prop({
    type: [Object, String, Array, Number]
  })
  value!: string

  @Prop({
    type: Array
  })
  propItems!: string[]

  @Prop({
    type: String
  })
  items!: string

  @Prop({
    type: String
  })
  helpText!: string

  @Prop({
    type: String
  })
  itemName!: string

  @Prop({
    type: String
  })
  itemValue!: string

  @Prop({
    type: String
  })
  endPoint!: string

  @Prop({
    type: Object
  })
  endPointEntity!: EndpointEntity

  @Prop({
    type: String
  })
  placeholder!: string

  @Prop({
    type: Boolean
  })
  chips!: boolean

  @Prop({
    type: Boolean
  })
  multiple!: boolean

  @Prop({
    type: Boolean,
    default: false
  })
  hasRelatedField!: boolean

  @Prop({
    type: Boolean,
    default: false
  })
  returnObject!: boolean

  @Prop({
    type: String
  })
  itemRelatedField!: string

  @Prop({
    type: String
  })
  relatedField!: string

  @Prop({
    type: Boolean,
    default: false
  })
  initializeFirst!: boolean

  @Prop({
    type: String
  })
  filterField!: string

  @Prop({
    type: String
  })
  filteredValue!: string

  @Prop({
    type: Boolean,
    default: false
  })
  hidden!: boolean

  @Prop({
    type: Boolean,
    default: true
  })
  attach!: boolean

  @Prop({
    type: Boolean,
    default: true
  })
  clearable!: boolean

  @Prop({
    type: Boolean,
    default: false
  })
  showSelectAll!: boolean

  @Prop({
    type: Boolean,
    default: true
  })
  addNew!: boolean

  @Prop({
    type: String,
    default: i18n.t('action_buttons.select_all')
  })
  selectAllText!: string

  $refs!: {
    autocompleteValidator: InstanceType<typeof ValidationProvider>
    vAutocomplete: any
  }

  loadedItems: string[] = []
  originalItems: string[] = []

  icons = {
    clearIcon: Icons.CLOSE_ROUND,
    arrowDown: Icons.ANGLE_DOWN,
    selectAllEmpty: Icons.BOX_INACTIVE,
    close: Icons.CLOSE_FULL
  }

  innerValue: number | object | string | string[] = ''
  search = ''

  deletableChip = false

  height = '39'

  initialValue: any = this.value

  componentKey = 0

  @Watch('innerValue')
  changedValue(newVal: string | string[]) {
    this.validateField()
    this.$emit('input', newVal)
  }

  @Watch('propItems')
  changedPropItems(newVal: string[]) {
    this.loadedItems = newVal
  }

  @Watch('value')
  changeInnerValue(newVal: string | string[]) {
    this.validateField()
    this.innerValue = newVal
  }

  @Watch('filteredValue')
  changedFilteredValue(filteredValue: string | number) {
    if (this.filterField) {
      this.loadedItems = this.originalItems.filter((item) => (item as any)[this.filterField] === filteredValue)
    }
  }

  get requiredField() {
    return this.validationRules && this.validationRules.includes('required') ? true : false
  }

  get allSelected() {
    return (this as any).innerValue.length === this.loadedItems.length
  }

  mounted() {
    this.initValidator(this.$refs.autocompleteValidator)
  }

  async created() {
    await this.fetchItems()

    if (this.chips) {
      this.deletableChip = true
      this.multiple = true
      this.height = 'auto'
    }

    if (this.value) {
      this.innerValue = this.regularizeDataTypes(this.value)
    }

    if (!this.disabled) {
      this.doInitializeFirst()
    }
  }

  async reloadComponent() {
    await this.fetchItems()
    this.componentKey += 1
    this.innerValue = ''
  }

  onChangeDisabled(disabled: boolean) {
    if (!disabled) {
      this.doInitializeFirst()
    } else if (!this.initialValue) {
      this.innerValue = ''
    }
  }

  doInitializeFirst() {
    if (
      this.initializeFirst &&
      (this.innerValue === '' ||
        this.innerValue === undefined ||
        (typeof this.innerValue === 'object' && (this as any).innerValue.id === undefined))
    ) {
      let field = 'id'

      if (this.itemValue) {
        field = this.itemValue
      }

      if (this.loadedItems.length && (this.loadedItems[0] as any)[field]) {
        this.innerValue = (this.loadedItems[0] as any)[field]
      }
    }
  }

  regularizeDataTypes(value: any) {
    // El tipo de de value comparado con el tipo de datos del campo [itemValue] de la lista de items loadedItems[0]
    // TODO: Método a eliminar cuando se devuelva tipado los datos de los procedimietos almacenados
    if (this.chips && typeof value === 'string') {
      return value.split(',')
    }
    if (['string', 'number', 'boolean'].indexOf(typeof value) >= 0 && this.loadedItems.length) {
      const listType = typeof (this as any).loadedItems[0][this.itemValue]
      if (typeof this.value !== listType) {
        switch (listType) {
          case 'string':
            return value.toString()
          case 'number':
            return Number(value)
          case 'boolean':
            return value === 1 || value === '1'
        }
      }
    }
    return value
  }

  async fetchItems() {
    try {
      if (this.propItems) {
        this.loadedItems = this.propItems
      } else {
        let url = ''
        if (this.endPointEntity) {
          const endPointEntityParsed = this.parseEndpointEntity(clone(this.endPointEntity))
          if (endPointEntityParsed.method === 'GET') {
            const { data } = await new MainService().getData(endPointEntityParsed.url)
            this.loadedItems = data
          } else {
            const { data } = await new MainService().postData(endPointEntityParsed.url, endPointEntityParsed.params)
            this.loadedItems = data
          }
        } else {
          url = `general/data/${this.items}`
          if (this.endPoint) {
            url = this.endPoint.toString()
          }
          const { data } = await new MainService().getData(url)
          this.loadedItems = data
        }
      }

      this.originalItems = this.loadedItems
    } catch (error) {}
  }

  customFilter(item: { name: string }, queryText: string, itemText: string | any[]) {
    if (item && item.name) {
      itemText = item.name
        .toLocaleLowerCase()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
      queryText = queryText
        .toLocaleLowerCase()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
      return itemText.indexOf(queryText) > -1
    }
    return false
  }

  async update() {
    if (!this.addNew) {
      return
    }
    await this.$nextTick()
    if (this.chips && this.search) {
      const items = this.search.split(',')
      this.loadedItems.push(...items)
      if (this.innerValue === '') {
        this.innerValue = []
      }
      ;(this as any).innerValue.push(...items)
      this.$nextTick(() => {
        this.search = ''
      })
    }
  }

  toggleSelectAll() {
    if (this.allSelected) {
      this.innerValue = []
    } else {
      this.innerValue = this.loadedItems
    }
  }
}
</script>

<style lang="scss" scoped>
.lf-autocomplete-container {
  @include textfield-messages;
  width: 100%;

  ::v-deep div.v-list-item__action {
    @include flex;

    & > .v-simple-checkbox {
      padding-bottom: 9px !important;
    }
  }

  ::v-deep .select-all-icon {
    font-size: 18px !important;

    &.lf-icon-close-full {
      color: $corporate-color;
    }
  }

  ::v-deeo .theme--light.v-list-item--active::before {
    opacity: 0 !important;
  }

  ::v-deep .v-menu__content.menuable__content__active .v-list-item--active .v-list-item__title {
    color: $gray-01;
  }

  &.error-styles .label-text {
    color: $error-color;
  }

  &.disabled-field {
    .lf-autocomplete:hover {
      border-color: $gray-02;
      background-color: $gray-04;
    }

    .lf-autocomplete {
      background-color: $gray-04;
    }

    .label-text,
    .messages-container .help-message {
      color: $gray-02;
    }

    .required-field.label-text::after {
      color: $gray-02;
    }
  }

  &.hidden-field {
    display: none;
  }

  ::v-deep .v-text-field > .v-input__control > .v-input__slot:after {
    border: none;
  }

  .label-text {
    @include label-text;
  }

  .lf-autocomplete {
    @include textfield-styles;
    padding: 0;
    margin-top: 0;

    .v-list {
      max-height: 200px;
    }

    &:hover ::v-deep .v-select__slot {
      .v-input__icon--clear {
        opacity: 1;
      }
    }

    &.v-input--is-focused {
      border: none;

      ::v-deep .v-select__slot {
        @include borders;
        background-color: $blue-07;

        .v-input__icon--clear {
          opacity: 1;
        }
      }
    }

    &.error-styles.v-input--is-focused {
      ::v-deep .v-select__slot {
        @include borders;
        background-color: $red-07;
        border-color: $error-color;
      }
    }

    ::v-deep .v-simple-checkbox .v-icon {
      top: 3.3px;
      font-size: 18px;
    }

    ::v-deep .v-list-item__action:first-child {
      margin-right: 8px;
    }

    ::v-deep .v-list-item__content {
      padding: 10px 0;

      .v-list-item__title {
        white-space: normal;
      }
    }

    ::v-deep .v-input__control div::before {
      border: none;
    }

    ::v-deep .v-select__slot {
      height: 40px;
      padding: 0 8px 0 10px;

      .v-input__icon--clear {
        opacity: 0;
        padding-top: 1px;
      }

      .v-input__append-inner {
        cursor: pointer;
        padding-top: 5px;
        padding-left: 0;
      }

      input {
        min-height: 40px;
        color: $gray-01;
        margin-bottom: 5px;

        &::placeholder {
          @include placeholder;
        }
      }

      .v-icon {
        font-size: 20px;
        padding-bottom: 0;
        color: $blue-01;
      }
    }
  }
  .v-select--chips {
    height: auto;

    ::v-deep .v-select__slot {
      height: auto;
    }

    ::v-deep .v-chip--select {
      background-color: $blue-04;
      color: $white-01;
    }
    ::v-deep .v-chip .v-chip__content {
      @include ellipsis;
      max-width: 250px;
      padding-right: 20px;
      display: block;
      line-height: 32px;
    }

    ::v-deep .v-chip__close {
      position: absolute;
      right: 12px;
      top: 7px;
      &::before {
        color: $white-01;
      }
    }
  }
}
</style>
