<template lang="pug">
  div(class="tandem-filters-column")
    div(v-if="showFilterButton" @click="clearFilters" class="tandem-filters-button")
      span() {{ texts.cleanFilter }}
    div(v-if="showDescriptors"
        class="tandem-aditional-filters")
      div(class="tandem-aditional-filter-tbox") {{ texts.aditionalFilters }}
      div(class="tandem-aditional-filter-tbox-arrow")
      div(class="tandem-descriptor"
          v-for="descriptor in getDescriptors")
          div(class="lf-icon-close-round-full tandem-descriptor-close"
              @click.stop="removeDescriptor(descriptor.key)")
          div(class="tandem-descriptor-text") {{ descriptor.value.value }}
    div(class="tandem-filters")
      span(class="tandem-filters-title") {{ texts.textSearch }}
      div(v-for="filter in tandemFilters")
        div(v-if="filter.type===filterTypes.List")
          LexonSelectComponent(
            :ref="filter.label"
            :value="null"
            :label="filter.label"
            :propItems="filter.items"
            :isMultiselect="filter.multiSelect"
            :renderChips="filter.multiSelect"
            :keyValue="filter.keyValue?filter.keyValue:''"
            :returnObject="false"
            :paginated="false"
            showAllChips
            @input="setSelected($event, filter)"
          )
        div(v-if="filter.type===filterTypes.DateInterval")
          LexonDateRangePickerComponent(
            :ref="filter.label"
            :value="null"
            :tandemSearch="true",
            :label="filter.label"
            @input="setSelected($event, filter)"
          )
        div(v-if="filter.type===filterTypes.Switch")
</template>

<script lang="ts">
import { Component, Vue, Watch } from 'vue-property-decorator'
import { Action, Getter, Mutation } from 'vuex-class'
import { ContextName, ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import { ConfigurationAlias, ConfigurationTypes } from '@/store/modules/configuration/configurationTypes'
import FieldBehaviorsMixin from '@/mixins/FieldBehaviorsMixin.vue'
import { mixins } from 'vue-class-component'
import { entity } from '@/store/modules/entities/entitiesTypes'
import { clone } from '@/helpers/object'
import { TandemFilter, TandemFilterType, TandemSelectedFiler } from '@/store/modules/tandemSearch/tandemSearchTypes'

const configurationModule: string = ModuleNamespaces.CONFIGURATION
const tandemSearchModule: string = ModuleNamespaces.TANDEM_SEARCH

@Component({
  components: {
    LexonSelectComponent: () => import('@/components/forms/fields/LexonSelect/LexonSelectComponent.vue'),
    LexonDateRangePickerComponent: () =>
      import('@/components/forms/fields/LexonDateRangePicker/LexonDateRangePickerComponent.vue')
  }
})
export default class TandemFiltersComponent extends mixins(FieldBehaviorsMixin) {
  @Action('fetchCurrentViewConfiguration', { namespace: configurationModule }) fetchCurrentViewConfiguration: ({}) => {}
  @Action('initFilters', { namespace: tandemSearchModule }) initFilters: (filters: TandemFilter[]) => {}

  @Mutation('SET_FILTERS', { namespace: tandemSearchModule }) setFilters: (filters: TandemFilter[]) => void
  @Mutation('SET_DESCRIPTORS', { namespace: tandemSearchModule }) setDescriptors: ({}) => void

  @Getter('getCurrentViewConfiguration', { namespace: configurationModule })
  filtersConfig: (context: string) => TandemFilter[]
  @Getter('getFilters', { namespace: tandemSearchModule }) filters: TandemFilter[]
  @Getter('getDescriptors', { namespace: tandemSearchModule }) getDescriptors: any
  @Getter('getSearchFacets', { namespace: tandemSearchModule }) facets: []

  context: string = ContextName.TANDEM_FILTERS

  filterTypes = {
    List: TandemFilterType.LIST,
    DateInterval: TandemFilterType.DATE_INTERVAL,
    Switch: TandemFilterType.SWITCH
  }

  active = false

  selectedFilters = new Map()
  tandemSelectedFilters: TandemSelectedFiler[] = []
  showFilterButton: boolean = false
  showDescriptors: boolean = false

  texts = {
    textSearch: this.$t('views.search.search_on_results'),
    cleanFilter: this.$t('components.grid_table.tooltip.clean_filter'),
    aditionalFilters: this.$t('views.search.tandem_aditional_filters')
  }

  lastFilterSelected: string = ''
  filterItemsCount = []
  reviewFilterFlag: boolean = false

  get tandemFilters() {
    Object.entries(this.facets).forEach((facet: any) => {
      const facetFilter: any = {
        id: facet[0],
        name: facet[0],
        type: this.filterTypes.List,
        label: this.$t(`components.tandem.facet.${facet[0]}`).toString(),
        keyValue: 'id',
        metadataName: facet[0],
        multiSelect: true,
        items: []
      }
      if (facet[0] === 'entityType') {
        let items: any = []
        Object.values(entity).forEach((ent: any) => {
          let totalChildItems = 0
          const childItems: any = []
          Object.values(ent).forEach((e: any) => {
            if (e.type) {
              ;(this as any).facets['entityType'].forEach((f: any) => {
                if (f.value.toString() === e.type.toString()) {
                  const label = this.$t(`components.tandem.filter.entityType.${ent.alias}.${e.alias}`)
                  childItems.push({ id: e.type, name: `${label} (${f.numdocs})` })
                  totalChildItems += f.numdocs
                }
              })
            }
          })
          if (totalChildItems > 0) {
            const label = this.$t(`components.tandem.filter.entityType.${ent.alias}._`)
            items.push({ id: ent.type, name: `${label}` })
            items = items.concat(childItems)
          } else {
            ;(this as any).facets['entityType'].forEach((f: any) => {
              if (f.value.toString() === ent.type.toString()) {
                const label = this.$t(`components.tandem.filter.entityType.${ent.alias}`)
                items.push({ id: ent.type, name: `${label} (${f.numdocs})` })
              }
            })
          }
        })
        facetFilter.items = items
      } else {
        facet[1].forEach((item: any) => {
          const label =
            item.value !== 'none'
              ? item.value
              : this.$t('components.tandem.facet.without') + ' ' + facetFilter.label.toLowerCase()
          const i: any = {
            id: item.value,
            name: `${label} (${item.numdocs})`
          }
          facetFilter.items.push(i)
        })
      }
      const filter = this.filters.filter((item: any) => item.id === facetFilter.id)

      if (filter.length > 0) {
        let fieldFilter = false
        for (const key of this.selectedFilters.keys()) {
          if (key.includes(facetFilter.metadataName)) {
            fieldFilter = true
          }
        }
        if (facetFilter.id !== this.lastFilterSelected || !fieldFilter) {
          Vue.set(filter[0], 'items', facetFilter.items)
        }
      } else {
        this.filters.unshift(facetFilter)
      }
    })

    return this.filters
  }

  @Watch('getDescriptors', { immediate: true })
  changeDescriptors() {
    this.selectedFilters.forEach((_value: TandemSelectedFiler, key: string) => {
      if (key.includes('principalDescriptors-') || key.includes('secondaryDescriptors-')) {
        this.selectedFilters.delete(key)
      }
    })
    this.getDescriptors.forEach((item: any) => {
      const key: string = item.key
      this.selectedFilters.set(key, {
        name: item.value.name,
        value: item.value.value,
        tag: item.value.value,
        filter: ''
      })
    })
    this.showDescriptors = this.getDescriptors.length > 0 ? true : false
    this.emitFilters()
  }

  async created() {
    await this.fetchCurrentViewConfiguration({
      objectType: ConfigurationTypes.VIEW,
      alias: ConfigurationAlias.TANDEM_FILTERS,
      context: this.context
    })

    this.filtersConfig(this.context).forEach((item: any) => {
      if (item.endPointEntity) {
        item.endpoint = this.parseEndpointEntity(item.endPointEntity).url
      }
    })

    await this.initFilters(this.filtersConfig(this.context))
  }

  destroyed() {
    this.selectedFilters.clear()
    this.setDescriptors([])
  }

  setSelected(items: string[], filter: TandemFilter) {
    const specialKeyFilters: string[] = [
      `entityType-${entity.actions.type}`,
      `entityType-${entity.contacts.type}`,
      `entityType-${entity.invoices.type}`
    ]
    this.lastFilterSelected = filter.metadataName
    this.selectedFilters.forEach((_value: TandemSelectedFiler, key: string) => {
      if (key.includes(`${filter.metadataName}-`)) {
        this.selectedFilters.delete(key)
      }
    })

    if (!Array.isArray(items)) {
      if (null !== items) {
        items = [items]
      }
    }

    if (items) {
      items.forEach((item) => {
        const key: string = `${filter.metadataName}-${item}`
        this.selectedFilters.set(key, { name: filter.metadataName, value: item, tag: item, filter })
      })
    }

    if (this.reviewFilterFlag) {
      this.selectedFilters.forEach((_value: TandemSelectedFiler, key: string) => {
        const tandemKey = key.split('-')
        if (
          !(this as any).facets[tandemKey[0]].find((f: any) => f.value === tandemKey[1]) &&
          !specialKeyFilters.includes(key)
        ) {
          this.selectedFilters.delete(key)
        }
      })
    }

    if (items !== null) {
      if (
        (this as any).filterItemsCount[filter.metadataName] &&
        (this as any).filterItemsCount[filter.metadataName] > items.length
      ) {
        this.reviewFilterFlag = true
      } else {
        this.reviewFilterFlag = false
      }

      ;(this as any).filterItemsCount[filter.metadataName] = items.length
    }
    this.emitFilters()
  }

  async clearFilters() {
    Object.entries(this.$refs).forEach(([_key, item]) => {
      ;(item as any)[0].resetValue()
    })
    this.lastFilterSelected = ''
    this.selectedFilters.clear()
    this.setDescriptors([])
    this.emitFilters()
  }

  removeDescriptor(key: string) {
    const newDescriptors = clone(this.getDescriptors)
    const index = newDescriptors.findIndex((i: any) => i.key === key)
    if (index !== -1) {
      newDescriptors.splice(index, 1)
      this.setDescriptors(newDescriptors)
    }
  }

  emitFilters() {
    this.showFilterButton = this.selectedFilters.size === 0 ? false : true
    this.$emit('set-filter', this.selectedFilters)
  }
}
</script>

<style lang="scss" scoped>
.tandem-filters-column {
  max-width: 300px;
}
.tandem-filters-button {
  text-transform: uppercase;
  color: $white-01;
  background-color: $blue-04;
  font-family: $corporate-font;
  text-align: center;
  cursor: pointer;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 6px;
  border-radius: 50px;
  width: 63%;
  float: right;
  border: 4px solid $white-01;
  span {
    position: relative;
    left: -5px;
    top: -3px;
  }
  &:hover {
    background-color: $blue-05;
    border: 4px solid $blue-06;
  }
}
.tandem-filters-button:before {
  font-family: $lf-icons-font;
  content: '\e960';
  background-color: $blue-01;
  border-radius: 50px;
  margin-right: 10px;
  font-size: 20px;
  padding: 6px;
}

.tandem-filters {
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  min-width: 300px;
  max-width: 300px;
  background-color: $gray-04;
  padding: 10px;
  margin-top: 20px;

  .tandem-filters-title {
    font-family: $corporate-font-bold;
    font-size: 16px;
    color: $corporate-color;
    padding: 10px;
  }

  .tandem-filter-items {
    max-height: 200px;
    overflow-y: scroll;
  }

  ::v-deep .v-select.v-select--chips.v-input--dense .v-select__selections {
    min-height: min-content;
  }

  ::v-deep .lf-select-container.show-all-chips .v-select__slot {
    padding: 0 8px 0 10px;
    min-height: 40px !important;
  }
}
.tandem-aditional-filters {
  display: flex;
  flex-wrap: wrap;
  min-width: 300px;
  max-width: 300px;
  background-color: $gray-04;
  padding: 10px;
  margin-top: 20px;
}
.tandem-aditional-filter-tbox {
  text-transform: uppercase;
  color: $white-01;
  background-color: $blue-04;
  font-family: $corporate-font;
  height: 2rem;
  line-height: 2rem;
  width: 85%;
  text-align: left;
  padding-left: 15px;
}
.tandem-aditional-filter-tbox-arrow {
  border-top: 1rem solid transparent;
  border-bottom: 1rem solid transparent;
  border-right: 1rem solid transparent;
  border-left: 1rem solid $blue-04;
}
.tandem-descriptor {
  display: flex;
  flex-wrap: wrap;
  padding: 5px;
  font-size: 12px;
}
.tandem-descriptor-close {
  color: $blue-01;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  margin-right: 5px;
  cursor: pointer;
}
</style>
