export interface FilterSettings {
  type: string
  ignoreAccent: boolean
  columns: FilterColumns | object
  operators: FilterOperator
}

export interface FilterColumns {
  field: string
  operator: string
  predicate: string
  value: string
}

export interface FilterOperator {
  stringOperator: StringOperator | object
  numberOperator?: NumberOperator | object
}

export interface StringOperator {
  value: string
  text: string
}

export interface NumberOperator {
  value: string
  text: string
}

export interface SearchSettings {
  ignoreAccent: boolean
  key: string
}

export const filterSettingsTypesOperator = {
  stringOperator: [
    { value: 'contains', text: 'Contiene' },
    { value: 'startsWith', text: 'Empieza por' }
  ],
  numberOperator: [
    { value: 'equal', text: 'Igual a' },
    { value: 'notEqual', text: 'Distinto' },
    { value: 'greaterThan', text: 'Mayor que' },
    { value: 'greaterThanOrEqual', text: 'Igual o mayor que' },
    { value: 'lessThan', text: 'Menor que' },
    { value: 'lessThanOrEqual', text: 'Igual o menor que' }
  ]
}
