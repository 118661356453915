import { MutationTree } from 'vuex'
import { AgenciesState } from '@/store/modules/agencies/agenciesTypes'

export const mutations: MutationTree<AgenciesState> = {
  FETCH_AGENCIES(state, agencies) {
    state.agencies = agencies
  },
  FETCH_ONE_AGENCY(state, agency) {
    state.selectedAgency = agency
  },
  RESET_SELECTED_AGENCY(state) {
    state.selectedAgency = null
  }
}
