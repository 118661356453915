<template lang="pug">

div(class="form-card")

  ValidationObserver(
      ref="form"
      v-slot="{ validate }"
      class="login-form"
      tag="form"
      @submit.prevent="login"
    )
    div(class="form-logo-container")
      img(src="@/assets/images/logos/lefebvre-lexon-173x60.png" alt="Lefebvre logo" class="form-logo")
    template(v-if="!responseDisplayType")
      p(v-if="!isLoginView" class="remember-password-title") {{ $t('forms.login.remember_password_title') }}

      //- ERRORS MESSSAGE
      span(v-if="isLoginView" class="error-text") {{ $t(apiError) }}

      //- FORM INPUTS
      TextFieldComponent(
        v-if="isLoginView || isRememberPasswordView"
        validationRules="required|email"
        icon="lf-icon-user"
        placeholder="usuario"
        id="email"
        v-model.trim="credentials.email"
      )
      TextFieldComponent(
        v-if="isLoginView"
        validationRules="required"
        id="password"
        icon="lf-icon-lock"
        placeholder="contraseña"
        type="password"
        v-model.trim="credentials.password"
      )

      //- REMEMBER ME CHECKBOX
      //- div(
      //-     v-if="isLoginView"
      //-     class="password-remember-container"
      //-   )
      //-   input(
      //-     class="remember-checkbox"
      //-     id="remember-me"
      //-     type="checkbox"
      //-     v-model="rememberMe"
      //-   )
      //-   label(for="remember-me" class="remember-label") {{ $t('forms.login.remember') }}
      //-   router-link(
      //-     to="/remember-password"
      //-     class="remember-me-link"
      //-   ) {{ $t('forms.login.remember_text') }}

      //-p(v-if="isRememberPasswordView" class="remember-message") {{ $t('forms.login.remember_message')}}

      //- FORM BUTTON
      button(
        type="submit"
        name="submit"
        class="login-button"
        ) {{ getButtonText }}

      div(v-if="isRememberPasswordView" class="wrong-user-container")
        p(v-show="error" class="wrong-user-text") {{ $t('forms.login.wrong_user') }}

    //- RESPONSE DISPLAY TYPE
    template(v-else)
      span(:class="getRememberPasswordResponseClasses")

      p(class="response-text") {{ $t(getRememberPasswordResponseMessage) }}
      p(class="success-info") {{ $t(getRememberPasswordResponseInfo)}}

  CardFooterComponent

</template>

<script lang="ts">
import { Vue, Component } from 'vue-property-decorator'
import { URLS } from '@/router/routes/urlRoutes'
import TextFieldComponent from '@/components/TextField/TextFieldComponent.vue'
import { LocaleMessage } from 'vue-i18n'
import { Action, Getter } from 'vuex-class'
import { Credentials, Company, UserInfo, CredentialsPlusCompany } from '@/store/modules/auth/authTypes'
import { ValidationObserver } from 'vee-validate'
import { ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import { makeLoginInJoomlaPlatform } from '@/helpers/helpers'
import CardFooterComponent from '@/components/CardFooter/CardFooterComponent.vue'

const authNamespace: string = ModuleNamespaces.AUTH
const errorsNamespace: string = ModuleNamespaces.ERROR

@Component({
  components: {
    TextFieldComponent,
    ValidationObserver,
    CardFooterComponent
  }
})
export default class LoginFormComponent extends Vue {
  @Getter('getError', { namespace: errorsNamespace }) apiError: string
  @Getter('getCompanies', { namespace: authNamespace }) companies: () => Company[]
  @Getter('userHasDoubleOptinLogin', { namespace: authNamespace }) userHasDoubleOptinLogin: boolean

  @Action('login', { namespace: authNamespace }) fetchUserData: ({}: Credentials) => UserInfo[]
  @Action('selectCompany', { namespace: authNamespace }) selectCompany: (id: number) => void
  @Action('clearError', { namespace: errorsNamespace }) clearApiError: () => void

  credentials: Credentials = {
    email: '',
    password: ''
  }

  responseDisplayType = false

  successResponse = false

  rememberMe = false

  error = null

  get isLoginView(): boolean {
    return this.$route.name === URLS.LOGIN
  }

  get isRememberPasswordView(): boolean {
    return this.$route.name === URLS.REMEMBER_PASSWORD
  }

  get getButtonText(): LocaleMessage {
    return this.isLoginView ? this.$t('forms.login.login') : this.$t('forms.login.continue')
  }

  get getRememberPasswordResponseClasses(): string {
    return this.successResponse ? 'lf-icon-check-round-full success-icon' : 'lf-icon-close-round-full error-icon'
  }

  get getRememberPasswordResponseMessage(): string {
    return this.successResponse ? 'forms.login.success_message' : 'forms.login.unexpected_error'
  }

  get getRememberPasswordResponseInfo(): string {
    return this.successResponse ? 'forms.login.success_info' : 'forms.login.error_info'
  }

  async login() {
    this.clearApiError()
    const formIsValid = await (this as any).$refs.form.validate()

    if (formIsValid) {
      await this.fetchUserData(this.credentials)

      if (!this.apiError) {

        if (this.companies && this.companies.length > 1) {
          this.$router.push({ name: URLS.SELECT_COMPANY, params: { credentials: JSON.stringify(this.credentials) } })
        } else {
          const info: CredentialsPlusCompany = {
            ...this.credentials,
            companyId: (this as any).companies[0].idCompany
          }
          const companyName = (this as any).companies[0].name
          await this.selectCompany(info.companyId)
          if (!this.apiError) {
            localStorage.setItem('company-name', companyName)
            makeLoginInJoomlaPlatform(info)
          }
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
$elements-size: 352px;

.error-text {
  @include error-text;
  margin-bottom: 7px;
}

.form-card {
  @include flex($justify-content: space-between);
  @include borders;
  flex-direction: column;
  width: 450px;
  background-color: $white-01;
}

.remember-password-title {
  font-size: 26px;
  color: $corporate-color;
}

.login-form {
  @include flex;
  flex-direction: column;
  padding-top: 26px;
}

.form-logo-container {
  height: 60px;
  text-align: center;
  margin-bottom: 25px;
}

.login-button {
  @include blue-button;
  margin-bottom: 40px;
}

.password-remember-container {
  @include flex($justify-content: space-between);
  width: 100%;
  max-width: $elements-size;
  margin: 10px 0 30px;
}

.remember-label {
  @include flex;
  font-size: 15px;
  color: $gray-01;
}

.remember-checkbox {
  position: absolute;
  opacity: 0;

  & + .remember-label {
    position: relative;
    cursor: pointer;
    padding: 0;
  }

  & + .remember-label:before {
    @include borders($color: $gray-02);
    content: '';
    margin-right: 10px;
    display: inline-block;
    vertical-align: text-top;
    width: 20px;
    height: 20px;
    background: $white-01;
  }

  &:checked + .remember-label:after {
    content: url(../../../assets/images/check.png);
    position: absolute;
    left: 2px;
    top: 1px;
  }
}

.remember-me-link {
  text-decoration: none;
}

.remember-message {
  color: $gray-01;
  padding: 0 49px;
}

.wrong-user-container {
  display: inline-block;
  height: 14px;
  align-self: end;
  font-size: 14px;
  color: $error-color;
  margin: 30px 0 30px 51px;
}

.success-icon {
  font-size: 34px;
  color: $success-color;
  margin-bottom: 22px;
}

.error-icon {
  font-size: 34px;
  color: $error-color;
  margin-bottom: 22px;
}

.response-text {
  font-size: 24px;
  color: $corporate-color;
}

.success-info {
  color: $gray-01;
  margin-bottom: 40px;
  padding: 0 49px;
}
</style>
