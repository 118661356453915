<template lang="pug">

  div(class="send-notification-expedient-container")

    //- HEADER
    CustomDialogHeaderComponent(:headerTitle="texts.title")

    p(class="explanation") {{ texts.explanation }}

    //- FIELDS
    ValidationObserver(
        ref="nameInput"
        v-slot="{ validate }"
        class="buttons-container"
      )
      LexonAutocompleteComponent(
        id="sections"
        name="sections"
        v-model="selectedResponsibles"
        :propItems="responsibles"
        :label="texts.usersLabel"
        multiple
        chips
        hasRelatedField
        itemName="name"
        itemValue="id"
        returnObject
        validationRules="required"
      )
      LexonTextFieldComponent(
        v-model="subject"
        :label="texts.subjectLabel"
        id="name"
        name="name"
        validationRules="required|max:255"
        @input="validateSubjectField"
      )
      LexonTextAreaComponent(
        v-model="noteText"
        :label="texts.noteLabel"
        :id="texts.noteLabel"
        :name="texts.noteLabel"
        :placeholder="texts.notePlaceholder"
        :maxlength="maxNumberOfCharactersInNotesField"
      )

    //- BUTTONS
    CustomDialogActionButtonsComponent(:disabledButtons="disabledActionButton" :mainAction="sendMail" :primaryButtonText="texts.primaryButton")

</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator'
import { Icons } from '@/icons/icons'
import { Action, Getter } from 'vuex-class'
import { ValidationObserver } from 'vee-validate'
import { ModuleNamespaces } from '@/store/types/storeGlobalTypes'

const dialogModule: string = ModuleNamespaces.DIALOG
const selectedRegisterModule: string = ModuleNamespaces.SELECTED_REGISTER
const usersModule = ModuleNamespaces.USERS
const notificationModule: string = ModuleNamespaces.NOTIFICATIONS
const spinnerModule: string = ModuleNamespaces.SPINNER

@Component({
  components: {
    CustomDialogActionButtonsComponent: () =>
      import('@/components/Dialog/CustomDialog/CustomDialogActionButtonsComponent.vue'),
    CustomDialogHeaderComponent: () => import('@/components/Dialog/CustomDialog/CustomDialogHeaderComponent.vue'),
    LexonAutocompleteComponent: () =>
      import('@/components/forms/fields/LexonAutocomplete/LexonAutocompleteComponent.vue'),
    LexonTextFieldComponent: () => import('@/components/forms/fields/LexonTextField/LexonTextFieldComponent.vue'),
    LexonTextAreaComponent: () => import('@/components/forms/fields/LexonTextArea/LexonTextAreaComponent.vue'),
    ValidationObserver
  }
})
export default class SendNotificationMailInternComponent extends Vue {
  @Prop({
    type: Object,
    required: true
  })
  componentProps!: { id: string; name: string; context: string }

  @Action('closeCustomDialog', { namespace: dialogModule })
  closeCustomDialog: () => {}

  @Action('showDialog', { namespace: dialogModule })
  showDialogAction: ({}) => {}

  @Action('fetchFilteredResponsibles', { namespace: usersModule })
  fetchFilteredResponsibles: (params: object) => []

  @Action('sendMailNotification', { namespace: notificationModule })
  sendMailNotification: ({}) => Promise<void>

  @Action('showSpinner', { namespace: spinnerModule }) showSpinner: () => void
  @Action('hideSpinner', { namespace: spinnerModule }) hideSpinner: () => void

  @Getter('getSelectedRegisterData', { namespace: selectedRegisterModule })
  selectedRegisterData: (context: string) => any

  closeIcon = Icons.CLOSE

  subject = ''
  responsibles = []
  selectedResponsibles = []
  maxNumberOfCharactersInNotesField: number = 2000
  noteText: string = ''

  texts = {
    allSections: this.$t('action_buttons.select_all'),
    explanation: this.$t('components.notifications.explanation'),
    noteLabel: this.$t('components.notifications.note_label'),
    notePlaceholder: this.$t('components.notifications.note_placeholder'),
    primaryButton: this.$t('components.notifications.primary_button'),
    subjectLabel: this.$t('components.notifications.subject_label'),
    title: this.$t('components.notifications.title'),
    usersLabel: this.$t('components.notifications.users_label')
  }

  $refs!: {
    nameInput: InstanceType<typeof ValidationObserver>
  }

  get disabledActionButton() {
    return !this.selectedResponsibles.length || !this.subject
  }

  async created() {
    const { idExpedient } = this.selectedRegisterData(this.componentProps.context)
    const params = {
      idEntityType: 1,
      idEntity: idExpedient
    }
    this.responsibles = await this.fetchFilteredResponsibles(params)
    await this.parseResponsibles()
    this.validateSubjectField()
  }

  async validateSubjectField() {
    await this.$nextTick()
    this.$refs.nameInput.validate()
  }

  parseResponsibles() {
    const { idUser } = this.selectedRegisterData(this.componentProps.context)
    this.responsibles.forEach((resp: any) => {
      if (idUser.includes(resp.id)) {
        ;(this as any).selectedResponsibles.push(resp)
      }
    })
  }

  async sendMail() {
    this.showSpinner()
    const { idCustomer, idExpedient, idOwnFile } = this.selectedRegisterData(this.componentProps.context)
    await this.sendMailNotification({
      templateType: this.noteText === '' ? 'internal' : 'internalNote',
      notificationData: {
        idExpedient,
        idCustomer,
        refExpedient: idOwnFile,
        selectedResponsibles: this.filterResponsibibles(this.selectedResponsibles),
        subject: this.subject,
        noteText: this.noteText
      }
    })
    this.closeCustomDialog()
    this.hideSpinner()
  }

  filterResponsibibles(resp: any) {
    const respFiltered: any = []
    resp.forEach((element: any) => {
      respFiltered.push({ name: element.name, email: element.email })
    })
    return respFiltered
  }
}
</script>

<style lang="scss" scoped>
.send-notification-expedient-container {
  .explanation {
    font-family: $corporate-font-medium;
    font-size: 20px;
    color: $blue-04;
    text-align: center;
    margin-top: 70px;
  }

  .buttons-container {
    display: block;
    margin-bottom: 50px;

    #sections {
      ::v-deep .v-menu__content.menuable__content__active {
        overflow: auto;
        padding-bottom: 10px;
      }
    }
  }
}
</style>
