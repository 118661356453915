<template lang="pug">
  section(
    v-if="!getEntityId"
    :class="{'lexon-view-container': !showSpinnerLayer}"
  )
    router-view
  router-view(v-else)

</template>

<script lang="ts">
import { ParentCategory } from '@/store/modules/menus/menusTypes'
import { ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import { Vue, Component } from 'vue-property-decorator'
import { Getter, Mutation } from 'vuex-class'

const entitiesModule = ModuleNamespaces.ENTITIES
const spinnerModule = ModuleNamespaces.SPINNER

@Component
export default class ContactsView extends Vue {
  @Mutation('SAVE_MENU_PARENT_CATEGORY_NAME', { namespace: entitiesModule })
  saveMenuParentCategoryName: (parentCategoryName: string) => void

  @Getter('getShowSpinnerLayer', { namespace: spinnerModule })
  showSpinnerLayer: boolean

  get getEntityId() {
    return this.$route.params.selectedRegisterId
  }

  created() {
    this.saveMenuParentCategoryName(ParentCategory.CONTACTS)
  }
}
</script>
