import { extend } from 'vee-validate'
import { required } from 'vee-validate/dist/rules'
import { i18n } from '@/plugins/vue-i18n'

extend('objectNotEmpty', {
  ...required,
  validate(object) {
    return object && !Object.values(object).every((value) => value === undefined)
  },
  message: i18n.t('validations.required') as string
})
