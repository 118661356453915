import { ActionTree } from 'vuex'
import { RouterState } from '@/store/modules/router/routerTypes'
import { RootState } from '@/store/types/storeGlobalTypes'
import { routerMutationTypes } from '@/store/modules/router/routerTypes'

export const actions: ActionTree<RouterState, RootState> = {
  setNextUrl({ commit }, nextUrl: string) {
    commit(routerMutationTypes.SET_NEXT_URL, nextUrl)
  },
  resetNextUrl({ commit }) {
    commit(routerMutationTypes.RESET_NEXT_URL)
  }
}
