import { Route } from 'vue-router'
import { trackPageView } from '@/plugins/tracker'
import { URLS } from '@/router/routes/urlRoutes'

export const trackViewPageGuard = (to: Route, _from: Route, next: any): void => {
  const { name } = to
  if (name !== URLS.LOGIN) {
    trackPageView({ page: to.name, title: to.meta?.title })
  }
  next()
}
