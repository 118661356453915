<template let-data lang="pug">

  div(class="card-template-container")

    div(class="title-row e-tooltip-text")
      span(class="ellipsis-text") {{ data.subject }}
      span(:class="[checkActionIconHelper(data.idActionType), 'template-icon']")

    span(v-if="data.description" class="template-row ellipsis-text")  {{ data.description }}

    div(class="template-row ellipsis-text")
      span(class="row-label") {{ $t('components.lexon_kanban.start_date') }}:
      span {{ data.dateActionAt }}

    div(
      v-for="(row, index) in data.rows"
      :key="index"
      class="template-row"
    )
      span(class="row-label") {{ row.label }}:
      span {{ row.value }}

    div(class="tags-row")
      span(class="tag") {{ data.responsible }}
      span(:class="[renderHasDocumentsIcon, 'template-icon']")

</template>

<script lang="ts">
import { Vue, Component } from 'vue-property-decorator'
import { Icons } from '@/icons/icons'
import { checkActionIcon } from '@/store/modules/actions/actionsTypes'
import { KanbanCardItem } from '@/components/LexonKanban/types/LexonKanbanTypes'

@Component
export default class LexonKanbanCardTemplate extends Vue {
  data: KanbanCardItem | {} = {}

  hasDocumentsIcon: string = Icons.DOCUMENT_ATTACHMENT

  checkActionIconHelper = checkActionIcon

  get renderHasDocumentsIcon(): string {
    return (this as any).data.hasDocuments ? this.hasDocumentsIcon : ''
  }
}
</script>

<style lang="scss" scoped>
.card-template-container {
  @include flex($flex-direction: column);
  @include border($direction: left, $width: 4px);
  font-family: $secondary-font;
  font-size: 13px;
  padding: 10px;

  .ellipsis-text {
    @include ellipsis;
  }

  .template-row {
    width: 100%;
    height: 25px;

    .row-label {
      font-weight: bold;
      margin-right: 4px;
    }
  }

  .title-row,
  .tags-row {
    @include flex($justify-content: space-between);
    width: 100%;
    font-size: 14px;

    .template-icon {
      font-size: 20px;
      color: $corporate-color;
      padding-right: 2px;
    }
  }

  .title-row {
    height: 20px;
  }

  .tags-row {
    margin-top: 10px;
  }

  .tag {
    @include ellipsis;
    @include flex;
    display: block;
    height: 34px;
    color: $white-01;
    background-color: $blue-04;
    padding: 0 14px;
    border-radius: 25px;
  }
}
</style>
