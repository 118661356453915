import { MutationTree } from 'vuex'
import { AlertsState } from '@/store/modules/alerts/alertsTypes'

export const mutations: MutationTree<AlertsState> = {
  SHOW_ALERT(state, alertData) {
    state.showAlert = true
    state.alertData = alertData
  },
  CLOSE_ALERT(state) {
    state.showAlert = false
    state.alertData = null
  }
}
