import MaintenanceView from '@/views/Maintenance/MaintenanceView.vue'
import MaintenanceCustomerPortalView from '@/views/Maintenance/MaintenanceCustomerPortalView.vue'
import { URLS } from '@/router/routes/urlRoutes'
import { entity } from '@/store/modules/entities/entitiesTypes'
import { ContextName } from '@/store/types/storeGlobalTypes'
import { ConfigurationAlias, ConfigurationTypes } from '@/store/modules/configuration/configurationTypes'
import { MaintenanceEntityId } from '@/general/entityIds'
import { normalizeText } from '@/helpers/helpers'

export const maintenanceRoutes = [
  {
    path: normalizeText(`/${URLS.MAINTENANCE}`),
    name: URLS.MAINTENANCE,
    component: MaintenanceView,
    meta: {
      requiresAuth: true,
      title: URLS.MAINTENANCE
    }
  },
  {
    path: normalizeText(`/${URLS.MAINTENANCE}/${URLS.MAINTENANCE_EXPEDIENTS}`),
    name: `${URLS.MAINTENANCE}-${URLS.MAINTENANCE_EXPEDIENTS}`,
    component: MaintenanceView,
    props: () => ({
      menuName: URLS.MAINTENANCE_EXPEDIENTS,
      submenuName: URLS.MAINTENANCE,
      context: ContextName.MAINTENANCE,
      configuration: {
        type: ConfigurationTypes.VIEW,
        alias: ConfigurationAlias.MAINTENANCE_EXPEDIENTS
      },
      entityType: entity.expedients.type,
      entityId: MaintenanceEntityId.MAINTENANCE
    }),
    meta: {
      requiresAuth: true,
      parentUrl: URLS.MAINTENANCE,
      title: URLS.MAINTENANCE
    }
  },
  {
    path: normalizeText(`/${URLS.MAINTENANCE}/${URLS.MAINTENANCE_CONTACTS}`),
    name: `${URLS.MAINTENANCE}-${URLS.MAINTENANCE_CONTACTS}`,
    component: MaintenanceView,
    props: () => ({
      menuName: URLS.MAINTENANCE_CONTACTS,
      submenuName: URLS.MAINTENANCE,
      context: ContextName.MAINTENANCE,
      configuration: {
        type: ConfigurationTypes.VIEW,
        alias: ConfigurationAlias.MAINTENANCE_CONTACTS
      },
      entityType: entity.contacts.type,
      entityId: MaintenanceEntityId.MAINTENANCE
    }),
    meta: {
      requiresAuth: true,
      parentUrl: URLS.MAINTENANCE,
      title: URLS.MAINTENANCE
    }
  },
  {
    path: normalizeText(`/${URLS.MAINTENANCE}/${URLS.MAINTENANCE_ACTIONS}`),
    name: `${URLS.MAINTENANCE}-${URLS.MAINTENANCE_ACTIONS}`,
    component: MaintenanceView,
    props: () => ({
      menuName: URLS.MAINTENANCE_ACTIONS,
      submenuName: URLS.MAINTENANCE,
      context: ContextName.MAINTENANCE,
      configuration: {
        type: ConfigurationTypes.VIEW,
        alias: ConfigurationAlias.MAINTENANCE_ACTIONS
      },
      entityType: entity.maintenance.type,
      entityId: MaintenanceEntityId.MAINTENANCE
    }),
    meta: {
      requiresAuth: true,
      parentUrl: URLS.MAINTENANCE,
      title: URLS.MAINTENANCE
    }
  },
  {
    path: normalizeText(`/${URLS.MAINTENANCE}/${URLS.MAINTENANCE_DOCUMENTS}`),
    name: `${URLS.MAINTENANCE}-${URLS.MAINTENANCE_DOCUMENTS}`,
    component: MaintenanceView,
    props: () => ({
      menuName: URLS.MAINTENANCE_DOCUMENTS,
      submenuName: URLS.MAINTENANCE,
      context: ContextName.MAINTENANCE,
      configuration: {
        type: ConfigurationTypes.VIEW,
        alias: ConfigurationAlias.MAINTENANCE_DOCUMENTS
      },
      entityType: entity.maintenance.type
    }),
    meta: {
      requiresAuth: true,
      parentUrl: URLS.MAINTENANCE,
      title: URLS.MAINTENANCE
    }
  },
  {
    path: normalizeText(`/${URLS.MAINTENANCE}/${URLS.MAINTENANCE_INVOICES}`),
    name: `${URLS.MAINTENANCE}-${URLS.MAINTENANCE_INVOICES}`,
    component: MaintenanceView,
    props: () => ({
      menuName: URLS.MAINTENANCE_INVOICES,
      submenuName: URLS.MAINTENANCE,
      context: ContextName.MAINTENANCE,
      configuration: {
        type: ConfigurationTypes.VIEW,
        alias: ConfigurationAlias.MAINTENANCE_INVOICES
      },
      entityType: entity.professionals.type,
      entityId: MaintenanceEntityId.MAINTENANCE
    }),
    meta: {
      requiresAuth: true,
      parentUrl: URLS.MAINTENANCE,
      title: URLS.MAINTENANCE
    }
  },
  {
    path: normalizeText(`/${URLS.MAINTENANCE}/${URLS.MAINTENANCE_USERS}`),
    name: `${URLS.MAINTENANCE}-${URLS.MAINTENANCE_USERS}`,
    component: MaintenanceView,
    props: () => ({
      menuName: URLS.MAINTENANCE_USERS,
      submenuName: URLS.MAINTENANCE,
      context: ContextName.MAINTENANCE,
      configuration: {
        type: ConfigurationTypes.VIEW,
        alias: ConfigurationAlias.MAINTENANCE_USERS
      },
      entityType: entity.maintenance.type,
      entityId: MaintenanceEntityId.MAINTENANCE
    }),
    meta: {
      requiresAuth: true,
      parentUrl: URLS.MAINTENANCE,
      title: URLS.MAINTENANCE
    }
  },
  {
    path: normalizeText(`/${URLS.MAINTENANCE}/${URLS.MAINTENANCE_CUSTOMER_PORTAL}`),
    name: `${URLS.MAINTENANCE}-${URLS.MAINTENANCE_CUSTOMER_PORTAL}`,
    component: MaintenanceCustomerPortalView,
    props: () => ({
      menuName: URLS.MAINTENANCE_CUSTOMER_PORTAL,
      submenuName: URLS.MAINTENANCE,
      context: ContextName.MAINTENANCE_CUSTOMER_PORTAL,
      configuration: {
        type: ConfigurationTypes.VIEW,
        alias: ConfigurationAlias.MAINTENANCE_CUSTOMER_PORTAL
      },
      entityType: entity.maintenance.type,
      entityId: MaintenanceEntityId.MAINTENANCE
    }),
    meta: {
      requiresAuth: true,
      parentUrl: URLS.MAINTENANCE,
      title: URLS.MAINTENANCE
    }
  }
]
