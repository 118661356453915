import { Module } from 'vuex'
import { getters } from '@/store/modules/fileManager/fileManagerGetters'
import { actions } from '@/store/modules/fileManager/fileManagerActions'
import { mutations } from '@/store/modules/fileManager/fileManagerMutations'
import { RootState } from '@/store/types/storeGlobalTypes'
import { FileState } from '@/store/modules/fileManager/fileManagerTypes'

export const state: FileState = {
  file: new Blob(),
  maxFileUploadSize: { size: 0, sizeFormatted: '' },
  allowedExtensions: '',
  document: {},
  path: '',
  currentPath: '',
  downloadZipResult: { wrongFiles: [], zip: '' },
  fileManagerItem: {},
  refreshFileManagerCounter: 0,
  isOpenFileManagerEditComponent: false
}

const namespaced: boolean = true

export const fileManagerModule: Module<FileState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations
}
