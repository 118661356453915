import { MutationTree } from 'vuex'
import { RouterState } from '@/store/modules/router/routerTypes'

export const mutations: MutationTree<RouterState> = {
  SET_NEXT_URL(state, nextUrl: string) {
    state.nextUrl = nextUrl
  },
  RESET_NEXT_URL(state) {
    state.nextUrl = ''
  }
}
