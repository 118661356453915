import { MutationTree } from 'vuex'
import { UsersState } from '@/store/modules/users/usersTypes'

export const mutations: MutationTree<UsersState> = {
  FETCH_USERS(state, users) {
    state.users = users
  },
  FETCH_PERMISSIONS(state, permissions) {
    state.permissions = permissions
    state.originPermissions = JSON.parse(JSON.stringify(permissions))
  },
  RESET_PERMISSIONS(state) {
    state.permissions = []
    state.originPermissions = []
  }
}
