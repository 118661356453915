import { TranslateResult } from 'vue-i18n'

export enum PrintOrSaveInvoicesSteps {
  SELECT_INVOICES = 1,
  CONFIGURE_OPTIONS = 2,
  GENERATION_PROGRESS = 3,
  FINAL_RESUME_LIST = 4
}

export enum PrintInvoiceEvents {
  CANCEL = 'cancelPrintOrSaveInvoiceDialog',
  GENERATE = 'generatePrintOrSaveInvoiceDialog',
  CLOSE = 'closePrintOrSaveInvoiceDialog',
  FINISH = 'finishPrintOrSaveInvoiceDialog'
}

export const MAX_SELECTED_INVOICE_PRINT = 100

export interface TranslationTexts {
  title: {
    [key: number]: TranslateResult
  }
}
