import { render, staticRenderFns } from "./DashboardWidgetResumeType.vue?vue&type=template&id=805f0d90&scoped=true&lang=pug"
import script from "./DashboardWidgetResumeType.vue?vue&type=script&lang=ts"
export * from "./DashboardWidgetResumeType.vue?vue&type=script&lang=ts"
import style0 from "./DashboardWidgetResumeType.vue?vue&type=style&index=0&id=805f0d90&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "805f0d90",
  null
  
)

export default component.exports