import { externalServices } from '@/plugins/axios'
import { AxiosRequestConfig } from 'axios'

export class SyncfusionService {
  constructor() {}

  exportToPdf(fileData: Blob) {
    const url = `${process.env.VUE_APP_SYNCFUSION_PDF_API}/export`
    const config: AxiosRequestConfig = {
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      responseType: 'blob'
    }
    const formData: FormData = new FormData()
    formData.append('files', fileData)
    return externalServices.post(url, formData, config)
  }
}
