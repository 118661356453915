import { MutationTree } from 'vuex'
import { InvoicesState } from '@/store/modules/invoices/invoicesTypes'
import { Vue } from 'vue-property-decorator'

export const mutations: MutationTree<InvoicesState> = {
  FETCH_INVOICES(state, invoices) {
    state.invoices = invoices
  },
  FETCH_TOTAL_UNBILLED(state, total) {
    state.totalUnbilled = total
  },
  FETCH_TOTAL_RECEIVABLE(state, total) {
    state.totalReceivable = total
  },
  SAVE_INVOICE_TYPE(state, invoiceType) {
    state.invoiceType = invoiceType
  },
  SAVE_GENERATE_INVOICE(state, generateInvoice) {
    const index = state.invoicesGenerated.findIndex((invoice) => {
      return invoice.idEntity === generateInvoice.idEntity
    })
    const oldInvoice = state.invoicesGenerated[index]
    const newInvoice = { ...oldInvoice, ...generateInvoice }
    Vue.set(state.invoicesGenerated, index, newInvoice)
  },
  ADD_GENERATE_INVOICE(state, generateInvoices) {
    state.invoicesGenerated = generateInvoices
  },
  RESET_GENERATE_INVOICES(state) {
    state.invoicesGenerated = []
  }
}
