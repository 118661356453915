import { ActionTree } from 'vuex'
import { BillingState, billingMutationTypes } from '@/store/modules/billing/billingTypes'
import { RootState } from '@/store/types/storeGlobalTypes'
import { MainService } from '@/services/MainService'

export const actions: ActionTree<BillingState, RootState> = {
  async fetchBillingData({ commit }, { billingType, filter = {} }) {
    try {
      const url = `/invoices/${billingType}/get`
      const { data } = await new MainService().postData(url, filter)
      commit(billingMutationTypes.FETCH_BILLING_DATA, data)
      commit(billingMutationTypes.SET_LAST_LISTBILLING_FILTER, filter)
    } catch (error) {
      commit(billingMutationTypes.RESET_BILLING_DATA)
    }
  },
  async fetchTotals({ commit }, { source, ...params }) {
    try {
      const url = `/invoices/${source}/summary/get`
      const { data } = await new MainService().postData(url, params)
      commit(billingMutationTypes.FETCH_TOTALS, data)
    } catch (error) {
      commit(billingMutationTypes.RESET_TOTALS)
    }
  }
}
