<template lang="pug">

ejs-sidebar(
  ref="sidebar"
  class="sidebar"
  enableDock="true"
  width="330"
  dockSize="50"
  :isOpen="!dockIsClosedProp"
  :enableGestures="false"
  position="Right"
  type="Push"
)
  div(:class="[ 'dock', { 'dock-is-closed': dockIsClosed } ]")
    header(class="header-row" @click.stop="toggleSidebarStatus")
      div(class="configuration-text-container")
        span(:class="[icons.configuration, 'dock-icon']")
        span(class="configuration-text") {{ texts.configurationText }}
      div(
        :class="[icons.arrowLeft, 'dock-icon', 'arrow-icon']"
      )
    div(v-if="!dockIsClosed" class="actions-container")
      //- Group by autocomplete
      LexonAutocompleteComponent(
        class="group-autocomplete"
        v-model="groupByTerm"
        :prop-items="groupByAutocompleteOptions"
        :label="texts.groupByText"
        :clearable="false"
        @change="emitGroupByTerm"
      )
      //- Sort autocomplete
      LexonAutocompleteComponent(
        class="sort-autocomplete"
        v-model="sortByOption"
        :prop-items="sortAutocompleteOptions"
        :label="texts.sortText"
        :clearable="false"
        @change="emitSortByOption"
      )
      //- Filters
      span(class="filters-title") {{ texts.filter }}:
      div(class="filters-container")
        LexonAutocompleteComponent(
          v-model="filters.responsible"
          :prop-items="responsibles"
          itemName="name"
          :placeholder="texts.responsible"
          :label="`${texts.responsible}:`"
          multiple
          chips
          @change="emitFiltersInfo($event, 'responsible')"
        )
        LexonAutocompleteComponent(
          v-model="filters.actionTypeText"
          :prop-items="actionTypeSidebarFilterOptions"
          itemName="text"
          :placeholder="texts.type"
          :label="`${texts.type}:`"
          multiple
          chips
          @change="emitFiltersInfo($event, 'actionTypeText')"
        )
        LexonTextFieldComponent(
          id="search"
          class="filter-input"
          v-model="searchTerm"
          :label="`${texts.search}:`"
          :placeholder="texts.searchPlaceholder"
          @input="emitSearchTerm"
        )
        div(class="clear-button-container")
          button(
            @click.stop="clearFilters"
            :class="['clear-button', { disabled: filterButtonStatus }]"
          ) {{ texts.clear }}

      //- Show/hide column
      div(class="checkboxes-container")
        span(class="checkboxes-title") {{ texts.showHide }}
        table(class="checkboxes-table")
          tbody
            tr
              td
                CheckboxComponent(
                  v-for="(checkbox, checkboxIndex) in checkboxes"
                  :key="checkboxIndex"
                  :id="checkbox.keyField"
                  :value="checkbox.value"
                  :labelText="checkbox.headerText"
                  @input="emitToggleColumn($event, checkbox.keyField, checkboxIndex)"
                )

</template>

<script lang="ts">
import { Component, Emit, Prop, Watch, Mixins } from 'vue-property-decorator'
import { Icons } from '@/icons/icons'
import { TranslateResult } from 'vue-i18n'
import {
  SortByOptions,
  KanbanGroupBy,
  actionTypeSidebarFilterOptions as actionTypesFilterOptions
} from '@/components/LexonKanban/types/LexonKanbanTypes'
import CheckboxComponent from '@/components/Checkbox/CheckboxComponent.vue'
import LexonTextFieldComponent from '@/components/forms/fields/LexonTextField/LexonTextFieldComponent.vue'
import LexonAutocompleteComponent from '@/components/forms/fields/LexonAutocomplete/LexonAutocompleteComponent.vue'
import PermissionsMixin from '@/mixins/PermissionsMixin.vue'

@Component({
  components: {
    CheckboxComponent,
    LexonTextFieldComponent,
    LexonAutocompleteComponent
  }
})
export default class LexonKanbanSidebar extends Mixins(PermissionsMixin) {
  @Prop({
    type: String
  })
  groupByTermProp!: string

  @Prop({
    type: String
  })
  sortByOptionProp!: string

  @Prop({
    type: Boolean
  })
  dockIsClosedProp!: boolean

  @Prop({
    type: Array
  })
  checkboxes!: []

  @Prop({
    type: Array
  })
  responsibles!: []

  @Prop({
    type: Object
  })
  filtersInfo!: { responsible: []; actionTypeText: [] }

  $refs!: {
    sidebar: any
  }

  texts = {
    ascendingAutocompleteOption: this.$t('components.lexon_kanban.ascending'),
    clearFilters: this.$t('components.grid_table.tooltip.clean_filter'),
    clear: this.$t('action_buttons.clear'),
    configurationText: this.$t('components.lexon_kanban.configuration'),
    descendingAutocompleteOption: this.$t('components.lexon_kanban.descending'),
    filter: this.$t('action_buttons.filter'),
    groupByText: `${this.$t('components.lexon_kanban.group_by')}:`,
    responsible: this.$t('components.lexon_kanban.responsible'),
    search: this.$t('components.lexon_kanban.search'),
    searchPlaceholder: this.$t('components.lexon_kanban.search_placeholder'),
    showHide: this.$t('components.lexon_kanban.show_hide'),
    sortText: `${this.$t('components.lexon_kanban.sort')}:`,
    startDate: this.$t('components.lexon_kanban.start_date'),
    type: this.$t('components.lexon_kanban.type')
  }

  searchTerm: string = ''

  groupByTerm: string = ''

  sortByOption: string = ''

  filters = {
    responsible: [],
    actionTypeText: []
  }

  groupByAutocompleteOptions: TranslateResult[] = [this.texts.startDate, this.texts.responsible, this.texts.type]

  sortAutocompleteOptions = [
    {
      text: this.texts.ascendingAutocompleteOption,
      value: SortByOptions.ASCENDING
    },
    {
      text: this.texts.descendingAutocompleteOption,
      value: SortByOptions.DESCENDING
    }
  ]

  dockIsClosed: boolean = true

  icons = {
    arrowLeft: Icons.ANGLE_LEFT,
    configuration: Icons.CONFIGURATION
  }

  @Watch('groupByTermProp', { immediate: true })
  changeGroupByTerm(): void {
    switch (this.groupByTermProp) {
      case KanbanGroupBy.ACTION_TYPE:
        const typeText = this.texts.type as string
        this.groupByTerm = `${typeText.charAt(0).toUpperCase()}${typeText.slice(1)}`
        break
      case KanbanGroupBy.RESPONSIBLE:
        const responsibleText = this.texts.responsible as string
        this.groupByTerm = `${responsibleText.charAt(0).toUpperCase()}${responsibleText.slice(1)}`
        break
      case KanbanGroupBy.START_DATE:
        const startText = this.texts.startDate as string
        this.groupByTerm = `${startText.charAt(0).toUpperCase()}${startText.slice(1)}`
        break
    }
  }

  @Watch('sortByOptionProp', { immediate: true })
  changeSortByOption(): void {
    this.sortByOption = this.sortByOptionProp
  }

  @Watch('dockIsClosedProp', { immediate: true })
  toggleSidebar(): void {
    this.dockIsClosed = this.dockIsClosedProp
  }

  @Emit()
  emitSearchTerm(): string {
    return this.searchTerm
  }

  @Emit()
  emitGroupByTerm() {
    return this.groupByTerm
  }

  @Emit()
  emitSortByOption(): string {
    return this.sortByOption
  }

  @Emit()
  emitToggleColumn(status: boolean, columnKeyField: string, checkboxIndex: number) {
    const checkboxInfo = {
      visible: status,
      keyField: columnKeyField,
      index: checkboxIndex
    }
    ;(this as any).checkboxes[checkboxIndex].value = status
    return checkboxInfo
  }

  @Emit()
  emitClearFilters(): void {}

  @Emit()
  emitFiltersInfo(e: any, term: string) {
    const filterInfo = {
      term,
      value: []
    }
    if (e) {
      filterInfo.value = e
    }
    return filterInfo
  }

  get filterButtonStatus(): boolean {
    const { responsible, actionTypeText } = this.filters
    return responsible.length || actionTypeText.length || this.searchTerm ? false : true
  }

  get actionTypeSidebarFilterOptions() {
    return actionTypesFilterOptions.filter(
      ({ actionEntityId }) => this.checkEntityPermissionsGetter(actionEntityId).canView
    )
  }

  async toggleSidebarStatus(): Promise<void> {
    this.dockIsClosed = !this.dockIsClosed
    await this.$nextTick()
    this.$refs.sidebar.toggle()
  }

  mounted() {
    this.filters = this.filtersInfo
  }

  clearFilters() {
    this.filters = {
      responsible: [],
      actionTypeText: []
    }
    this.emitClearFilters()
  }
}
</script>

<style lang="scss" scoped>
$rotate-transition: transform 0.4s ease-in-out;

.sidebar {
  @include scroll-styles;
  position: absolute;
  background-color: $white-01;
  border-left: none;

  .dock {
    @include flex($justify-content: flex-start, $flex-direction: column);
    min-height: calc(100% - 26px);
    background-color: $gray-04;
    border-radius: 4px;
    margin-top: 16px;
    margin-right: 10px;
    padding: 14px 8px;

    .header-row {
      @include flex;
      width: 100%;
      justify-content: space-between;
      cursor: pointer;

      .arrow-icon {
        transition: $rotate-transition;
      }
    }

    .configuration-text-container {
      @include flex;
      transition: $rotate-transition;
      transform-origin: top left;

      .configuration-text {
        font-family: $corporate-font-medium;
        font-size: 16px;
        color: $corporate-color;
        text-transform: uppercase;
        margin-left: 4px;
      }
    }

    .dock-icon {
      @include flex;
      font-size: 23px;
      color: $corporate-color;
    }

    &.dock-is-closed {
      flex-direction: column;

      .header-row {
        position: relative;

        .configuration-text-container {
          position: absolute;
          left: 27px;
          top: 25px;
          order: 2;
          margin-top: 12px;
          transform: rotate(90deg);

          .configuration-text {
            padding-top: 4px;
          }
        }

        .arrow-icon {
          transform: rotate(180deg);
        }
      }
    }
  }

  .actions-container {
    @include flex($flex-direction: column);
    width: 100%;
    margin-top: 15px;

    .clear-button-container {
      @include flex($justify-content: flex-end);
      @include border($direction: top);
      padding-top: 7px;

      .clear-button {
        @include main-action-button--rectangle;
      }
    }

    .filters-container,
    .filters-title,
    .group-autocomplete,
    .sort-autocomplete,
    .checkboxes-container,
    .checkboxes-table {
      width: 100%;
    }

    .group-autocomplete,
    .sort-autocomplete {
      ::v-deep .v-input {
        background-color: $white-01;
      }
    }

    .filters-container {
      background: $white-01;
      padding: 10px;
      margin-bottom: 15px;

      ::v-deep .lf-autocomplete-container .lf-autocomplete .v-simple-checkbox .v-icon {
        top: 0;
      }
    }

    .checkboxes-title,
    .filters-title {
      font-family: $corporate-font-bold;
      font-size: 14px;
      color: $corporate-color;
      padding-left: 10px;
    }

    .checkboxes-container {
      .checkboxes-table {
        width: 100%;
        background-color: $white-01;
        padding: 10px 15px;
      }
    }
  }
}
</style>
