<template lang="pug">

  div(class="kanban-tooltip-container")
    table(v-for="(row, index) in rows" :key="index" class="tooltip-row")
      tr
        td(class="tooltip-label") {{ row.text }}:
        td(class="tooltip-value") {{ row.value }}

</template>

<script lang="ts">
import { Vue } from 'vue-property-decorator'
import { Component } from 'vue-property-decorator'
import { KanbanCardItem } from '@/components/LexonKanban/types/LexonKanbanTypes'

@Component
export default class LexonKanbanTooltipTemplate extends Vue {
  actionInfo: KanbanCardItem | null = null

  texts = {
    billable: this.$t('components.lexon_kanban.billable'),
    description: this.$t('components.lexon_kanban.description'),
    interveners: this.$t('components.lexon_kanban.interveners'),
    published: this.$t('components.lexon_kanban.published'),
    responsible: this.$t('components.lexon_kanban.responsible'),
    startDate: this.$t('components.lexon_kanban.start_date'),
    status: this.$t('components.lexon_kanban.status'),
    subject: this.$t('components.lexon_kanban.subject')
  }

  get rows() {
    if (this.actionInfo && Object.keys(this.actionInfo).length) {
      const { billable, description, interveners, isPublic, responsible, rows, dateActionAt, state, subject } = this
        .actionInfo as KanbanCardItem

      return [
        {
          text: this.texts.startDate,
          value: dateActionAt
        },
        {
          text: this.texts.responsible,
          value: responsible
        },
        {
          text: this.texts.subject,
          value: subject
        },
        {
          text: this.texts.description,
          value: description
        },
        {
          text: this.texts.interveners,
          value: interveners
        },
        {
          text: this.texts.status,
          value: state
        },
        {
          text: rows[0].label,
          value: rows[0].value
        },
        {
          text: rows[1].label,
          value: rows[1].value
        },
        {
          text: this.texts.billable,
          value: billable ? this.$t('action_buttons.yes') : this.$t('action_buttons.no')
        },
        {
          text: this.texts.published,
          value: isPublic ? this.$t('action_buttons.yes') : this.$t('action_buttons.no')
        }
      ]
    }
  }

  mounted() {
    this.actionInfo = (this as any).data
  }
}
</script>

<style lang="scss" scoped>
.kanban-tooltip-container {
  padding: 10px 9px;

  .tooltip-row {
    font-family: $secondary-font;
    font-size: 12px;
    margin: 4px 0;

    .tooltip-label {
      font-weight: bold;
      min-width: 100px;
      vertical-align: top;
      margin-right: 4px;
    }
  }
}
</style>

<style lang="scss">
.e-kanban-tooltip {
  background-color: $purple-03 !important;
  opacity: 1;
}
</style>
