import { GetterTree } from 'vuex'
import { ConfigurationAlias, ConfigurationState } from '@/store/modules/configuration/configurationTypes'
import { ContextName, RootState } from '@/store/types/storeGlobalTypes'
import { RenderedForm } from '@/components/expedients/ExpedientInsurers/types/ExpedientInsurersComponentTypes'

export const getters: GetterTree<ConfigurationState, RootState> = {
  getCurrentListConfiguration(state): object {
    return state.currentListConfiguration
  },
  getCurrentListConfigurationlistName(state): object {
    return (state as any).currentListConfiguration.Alias
  },
  getCurrentViewConfiguration: (state) => (context: string) => {
    return state.currentViewConfiguration[context]
  },
  checkIfViewConfigurationExists: (state) => (alias: string) => {
    return Boolean(state.viewsConfiguration.find((view) => view.alias === alias))
  },
  checkIfRepeatFetchConfigurationAnywayByAlias: (_state) => (alias: ConfigurationAlias | RenderedForm) => {
    const omittedAliases = [
      ConfigurationAlias.ACTION,
      ConfigurationAlias.DOCUMENT_PROPERTIES,
      ConfigurationAlias.EXPEDIENT,
      ConfigurationAlias.EXPEDIENT_PERIODIC_BILLING_FORM,
      ConfigurationAlias.FOLDER_PROPERTIES,
      RenderedForm.EXPEDIENT_INSURER,
      RenderedForm.EXPEDIENT_INSURER_FORM
    ]
    return omittedAliases.includes(alias)
  },
  checkIfRepeatFetchConfigurationAnywayByContext: (_state) => (context: ContextName) => {
    const omittedContexts = [
      ContextName.CUSTOMER_INVOICES_GENERAL_DATA_FORM,
      ContextName.CUSTOM_FIELDS,
      ContextName.EXPEDIENT_ECONOMIC_DATA
    ]
    return omittedContexts.includes(context)
  },
  getCurrentViewTabs: (_state, getters) => (context: string) => {
    const currentViewConfiguration = getters.getCurrentViewConfiguration(context)
    if (currentViewConfiguration) {
      return currentViewConfiguration.tabs
    }
    return { tabs: [] }
  },
  getUserProfileConfiguration(state): object {
    return state.userProfileConfiguration
  },
  getRelatedFormFields(state): [] {
    let formFields = []
    if (state.relatedFormConfiguration[0]) {
      formFields = JSON.parse(state.relatedFormConfiguration[0].config)
    }
    return formFields
  },
  getRememberUserCustomConfigurationList(state): boolean {
    return state.rememberUserCustomConfigurationList
  },
  getRouteFromName(state): string {
    return state.routeFromName
  },
  getLastListConfiguration(state): object {
    return state.lastListConfiguration
  },
  isEditingPeriodicBilling(state): boolean {
    return state.editingPeriodicBilling
  },
  getPeriodicBillingVisibleTabStatus(state): boolean {
    return state.visiblePeriodicBillingTab
  },
  getPeriodicBillingEnabledTabStatus(state): boolean {
    return state.enabledPeriodicBillingTab
  },
  getListConfig(state) {
    return JSON.parse((state.currentListConfiguration as any).Config)
  },
  getCustomConfigText(state): string {
    return state.customConfigText
  }
}
