<template lang="pug">
  validation-provider(:rules="validationRules" :class="[{'disabled' : disabled}, 'checkbox-container']" v-slot="{ errors }")
    input(
      ref="checkbox"
      class="checkbox"
      :id="id"
      :name="id"
      :checked="value"
      type="checkbox"
      :disabled="disabled"
      @change="updateCheckboxValue"
    )
    label(:for="id" class="label")
      span {{ typeof labelText === 'string' ? labelText : $t(labelText) }}
    div(class="alert-container" v-if="errors")
      span(class="alert-text") {{ errors[0] }}
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator'
import { ValidationProvider, extend } from 'vee-validate'
import { required } from 'vee-validate/dist/rules'

@Component({
  components: {
    ValidationProvider
  }
})
export default class CheckboxComponent extends Vue {
  @Prop({
    type: String
  })
  validationRules!: string

  @Prop({
    type: String,
    required: true
  })
  id!: string

  @Prop({
    type: Boolean,
    default: false
  })
  value!: boolean

  @Prop({
    type: Boolean,
    default: false
  })
  disabled!: boolean

  @Prop({
    type: String
  })
  labelText!: string

  updateCheckboxValue(e: Event) {
    const element = e.target as HTMLInputElement
    this.$emit('input', element.checked)
  }

  mounted() {
    extend('required', {
      ...required,
      message: this.$t('validations.required') as string
    })
  }
}
</script>

<style lang="scss" scoped>
.checkbox-container {
  &.disabled {
    opacity: 0.4;
  }
}
.checkbox {
  position: absolute;
  opacity: 0;

  & + .label {
    display: inline-flex;
    position: relative;
    font-size: 15px;
    cursor: pointer;
    padding: 0;

    span {
      padding-top: 5px;
    }
  }

  & + .label:before {
    display: inline-block;
    font-family: $lf-icons-font;
    font-size: 20px;
    content: '\e91f';
    margin-right: 10px;
  }

  &:checked + .label:after {
    position: absolute;
    font-family: $lf-icons-font;
    font-size: 20px;
    color: $corporate-color;
    content: '\e92b';
  }
}
</style>
