import { ActionTree } from 'vuex'
import { LegalSearchState, legalSearchMutationTypes } from '@/store/modules/legalSearch/legalSearchTypes'
import { RootState } from '@/store/types/storeGlobalTypes'
import { ExternalService } from '@/services/ExternalService'

export const actions: ActionTree<LegalSearchState, RootState> = {
  async fetchSessionId({ commit }, encryptedIdUserPro) {
    try {
      const url = `${process.env.VUE_APP_LEGAL_SEARCH_GET_SESSION_ID}?ei=${encryptedIdUserPro}`
      const { data } = await new ExternalService().getData(url)
      commit(legalSearchMutationTypes.SET_SEARCH_SESSION_ID, data)
    } catch (error) {
      commit(legalSearchMutationTypes.RESET_SEARCH_SESSION_ID)
    }
  },
  async fetchSearchTabs({ state, commit }, { term, sessionId }) {
    try {
      if (term && state.searchTerm !== term) {
        const originalSearchTerm = encodeURIComponent(term.normalize('NFD').replace(/[\u0300-\u036f]/g, ''))
        const url = `${process.env.VUE_APP_LEGAL_SEARCH_TABS_SERVICE}?jsessionid=${sessionId}&fulltext=${originalSearchTerm}`
        const { data } = await new ExternalService().getData(url)
        commit(legalSearchMutationTypes.SET_SEARCH_TABS, data)
        commit(legalSearchMutationTypes.SET_SEARCH_TERM, term)
      }
    } catch (error) {
      commit(legalSearchMutationTypes.RESET_SEARCH_TABS)
    }
  },
  async fetchSearchData({ commit }, { term, sessionId, index, numPage, numRecs }) {
    try {
      const originalSearchTerm = encodeURIComponent(term.normalize('NFD').replace(/[\u0300-\u036f]/g, ''))
      const startAt = numPage * numRecs
      const url = `${process.env.VUE_APP_LEGAL_SEARCH_DATA_SERVICE}?indice=${index}&jsessionid=${sessionId}&fulltext=${originalSearchTerm}&startat=${startAt}&maxresults=${numRecs}`
      const { data } = await new ExternalService().getData(url)
      commit(legalSearchMutationTypes.SET_SEARCH_TERM, term)
      commit(legalSearchMutationTypes.SET_SEARCH_DATA, { data, index })
    } catch (error) {
      commit(legalSearchMutationTypes.RESET_SEARCH_DATA)
    }
  }
}
