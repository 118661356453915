import { MutationTree } from 'vuex'
import { FirmaState } from '@/store/modules/firma/firmaTypes'
import { formatFieldsTypeDateEPOCH } from '@/helpers/dateTime'

export const mutations: MutationTree<FirmaState> = {
  SET_SIGN_INFO(state, info) {
    state.firmaSignInfo = info
  },
  SET_FIRMA_TOKEN(state, token) {
    state.firmaToken = token
  },
  SET_MONITORED_AUDITS(state, audits) {
    const fieldsTypeDateEPOCH = audits[0] ? Object.keys(audits[0]).filter((key) => key.includes('EPOCH')) : null
    state.monitoredAudits = fieldsTypeDateEPOCH ? formatFieldsTypeDateEPOCH(audits) : audits
  },
  SET_ONE_AUDIT_INFO(state, audit) {
    state.auditsStatusInfo.push(audit)
  },
  RESET_FIRMA_STATE(state) {
    state.auditsStatusInfo = []
    state.monitoredAudits = null
  }
}
