import { Module } from 'vuex'
import { getters } from '@/store/modules/notes/notesGetters'
import { actions } from '@/store/modules/notes/notesActions'
import { mutations } from '@/store/modules/notes/notesMutations'
import { setDefaultStateModule } from '@/store/modules/global/globalTypes'
import { NotesState, initialState } from '@/store/modules/notes/notesTypes'
import { RootState } from '@/store/types/storeGlobalTypes'

export const state: NotesState = setDefaultStateModule(initialState)

const namespaced: boolean = true

export const notesModule: Module<NotesState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations
}
