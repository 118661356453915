<script lang="ts">
import Vue from 'vue'
import { ActionName, ActionBarButton } from '@/components/ActionsBar/types/ActionBarComponentTypes'
import { ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import { Action, Mutation, Getter } from 'vuex-class'
import { Icons } from '@/icons/icons'
import { ContextName } from '@/store/types/storeGlobalTypes'
import TabsView from '@/views/Tabs/TabsView.vue'
import { Component } from 'vue-property-decorator'
import { DialogTypes } from '@/store/modules/dialog/dialogTypes'
import {
  RenderedForm,
  InvoiceTabName,
  InvoicesFormFields
} from '@/components/billing/InvoicesTab/types/InvoicesTabComponentTypes'
import BillingMixin from '@/mixins/BillingMixin.vue'
import { AlertsTypes, ComponentWhereIsRendered } from '@/store/modules/alerts/alertsTypes'
import { TranslateResult } from 'vue-i18n'
import { PrintInvoiceTypes } from '@/store/modules/invoices/invoicesTypes'
import { to, from, next } from '@/router/types/routerTypes'
import { URLS } from '@/router/routes/urlRoutes'
import { printInvoice } from '@/helpers/invoice'
import { ITemplate } from '@/store/modules/template/templateTypes'
import { ExpedientCustomerFact } from '@/store/modules/expedients/expedientsTypes'
import { SpinnerDashboardContext } from '@/store/modules/spinner/spinnerTypes'

const alertsModule = ModuleNamespaces.ALERTS
const authModule = ModuleNamespaces.AUTH
const configurationModule = ModuleNamespaces.CONFIGURATION
const dialogModule = ModuleNamespaces.DIALOG
const expedientsModule = ModuleNamespaces.EXPEDIENTS
const formsModule = ModuleNamespaces.FORMS
const invoicesModule = ModuleNamespaces.INVOICES
const notificationModule = ModuleNamespaces.NOTIFICATIONS
const templateModule = ModuleNamespaces.TEMPLATE

@Component({
  mixins: [BillingMixin]
})
export default class BillingDetailView extends TabsView {
  @Getter('getInvoiceType', { namespace: invoicesModule })
  invoiceType: () => string

  @Getter('getTemplateTree', { namespace: templateModule })
  tree: ITemplate[]

  @Getter('getCompanyTemplateInvoices', { namespace: authModule })
  hasCompanyTemplateInvoice: boolean

  @Getter('getRegisterFormData', { namespace: formsModule })
  getFormData: () => object

  @Getter('showAditionalForm', { namespace: formsModule })
  showAditionalForm: (context: string) => boolean

  @Getter('getExpedientCustomerFact', { namespace: expedientsModule })
  expedientCustomerFactGetter: ExpedientCustomerFact | null

  @Action('sendMailNotification', { namespace: notificationModule })
  sendInvoiceNotification: ({}) => Promise<void>

  @Action('printPdfInvoice', { namespace: invoicesModule })
  printInvoiceAction: ({}) => Promise<void>

  @Action('showDialog', { namespace: dialogModule })
  showDialogAction: ({}) => void

  @Action('showAlert', { namespace: alertsModule })
  showAlert: ({}) => {}

  @Action('convertProformaToCustomerInvoice', { namespace: invoicesModule })
  convertProformaToCustomerInvoice: (idInvoice: number) => Promise<string>

  @Action('setRouteFromName', { namespace: configurationModule })
  setRouteFromName: (routeFromName: string | undefined) => Promise<void>

  @Action('generateTemplate', { namespace: templateModule })
  generateTemplate: ({}) => void

  @Mutation('CLEAR_FORM_IS_VALID', { namespace: formsModule })
  clearFormIsValid: (context: string) => void

  @Mutation('RESET_EXPEDIENT_CUSTOMER_FACT', { namespace: expedientsModule })
  resetExpedientCustomerFactMutation: () => void

  breadcrumbText: TranslateResult = ''

  invoiceSendAvailable = [
    ContextName.BILLING_CUSTOMER_INVOICES,
    ContextName.BILLING_PROFORMA_INVOICES,
    ContextName.BILLING_PROVISIONS
  ]

  renderedInvoiceForm = ''

  routeFromName: string | undefined = ''

  get buttons() {
    const { alias, type } = this.entity(this.getContext)
    const { canSave, canDelete } = this.checkEntityPermissionsGetter(type)

    const buttons = [
      {
        icon: Icons.COIN,
        tooltip: this.$t('action_buttons.convert_to_customer_invoice'),
        action: ActionName.CONVERT_TO_CUSTOMER_INVOICE,
        show: !this.isBilledInvoice && (this.showAditionalForm(this.getContext) || !this.buttonsEnabled ? false : true),
        hidden: !this.isProformaInvoicesTabView || !canSave
      },
      {
        icon: Icons.PRINT,
        tooltip: this.$t('action_buttons.print'),
        action: ActionName.PRINT,
        show: this.showPrintButton,
        hidden: !Object.values(PrintInvoiceTypes).includes(alias as PrintInvoiceTypes)
      },
      {
        icon: Icons.EMAIL,
        tooltip: this.$t('action_buttons.send_email'),
        action: ActionName.SEND_EMAIL,
        show: this.showSendEmailButton,
        hidden: this.isProviderInvoicesTabView || this.isCustomerBankdraftsTabView || this.isProviderBankdraftsTabView
      },
      {
        icon: Icons.REMOVE,
        tooltip: this.$t('action_buttons.remove'),
        action: ActionName.REMOVE,
        show: this.showAditionalForm(this.getContext) || !this.buttonsEnabled ? false : true,
        hidden: !this.selectedInvoiceId || !canDelete
      },
      {
        icon: Icons.CLOSE,
        tooltip: this.$t('action_buttons.close'),
        class: 'red-color',
        action: ActionName.CLOSE,
        show: this.showAditionalForm(this.getContext) || !this.buttonsEnabled ? false : true
      },
      {
        icon: Icons.CHECK,
        tooltip: this.$t('action_buttons.save'),
        class: 'green-color',
        action: ActionName.SAVE,
        show: !this.checkIfFormIsValid(this.renderedInvoiceForm) || !this.buttonsEnabled ? false : true,
        hidden: !canSave
      }
    ]

    return buttons.filter((button: ActionBarButton) => !button.hidden)
  }

  get isBilledInvoice(): number {
    return Number(this.selectedRegisterData(this.context).billed)
  }

  get isProformaInvoicesTabView(): boolean {
    return this.context === ContextName.BILLING_PROFORMA_INVOICES
  }

  get isProviderInvoicesTabView(): boolean {
    return this.context === ContextName.BILLING_PROVIDER_INVOICES
  }

  get isCustomerBankdraftsTabView(): boolean {
    return this.context === ContextName.BILLING_CUSTOMER_BANKDRAFTS
  }

  get isProviderBankdraftsTabView(): boolean {
    return this.context === ContextName.BILLING_PROVIDER_BANKDRAFTS
  }

  get selectedInvoiceId() {
    return this.selectedRegisterId(this.context)
  }

  get showPrintButton() {
    return this.showAditionalForm(this.getContext) ||
      !this.buttonsEnabled ||
      !this.checkIfFormIsValid(this.renderedInvoiceForm)
      ? false
      : true
  }

  get showSendEmailButton() {
    return this.showAditionalForm(this.getContext) ||
      this.getContext.includes((this as any).invoiceSendAvailable) ||
      !this.buttonsEnabled ||
      !this.checkIfFormIsValid(this.renderedInvoiceForm)
      ? false
      : true
  }

  get setDialogText() {
    switch (this.context) {
      case ContextName.BILLING_CUSTOMER_BANKDRAFTS:
        return this.$t('components.dialog.customers_bankdraft_literal')
      case ContextName.BILLING_PROVISIONS:
        return this.$t('components.dialog.provisions_literal')
      case ContextName.BILLING_PROVIDER_BANKDRAFTS:
        return this.$t('components.dialog.providers_bankdraft_literal')
      case ContextName.BILLING_ORDER_FORMS:
        return this.$t('components.dialog.order_form_literal')
      default:
        return this.$t('components.dialog.invoices_literal')
    }
  }

  get selectedRegisterInfo() {
    return {
      dialogText: this.setDialogText
    }
  }

  beforeRouteEnter(_to: to, from: from, next: next<BillingDetailView>) {
    next(async (vm) => {
      try {
        vm.routeFromName = from.name || ''
      } catch (error) {}
    })
  }

  async mounted() {
    this.breadcrumbText = this.setNewRegisterBreadcrumbLiteral()
    await this.$nextTick()
    this.setRouteFromName(this.routeFromName)
  }

  beforeDestroy() {
    this.resetExpedientCustomerFactMutation()
  }

  destroyed() {
    this.clearFormIsValid(this.context)
  }

  executeAction(actionName: string) {
    switch (actionName) {
      case ActionName.CLOSE:
        return this.close()
      case ActionName.SAVE:
        return this.save()
      case ActionName.REMOVE:
        return this.remove()
      case ActionName.SEND_EMAIL:
        return this.sendEmailDialog()
      case ActionName.PRINT:
        return this.showTemplateSelectDialog()
      case ActionName.CONVERT_TO_CUSTOMER_INVOICE:
        return this.showDialogConvertToCustomerInvoice()
    }
  }

  onRenderForm(_tabIndex: number, contextName: string) {
    if (this.showSpinnerLayer) {
      this.hideSpinnerLayerAction()
      this.checkIfComesFromExpedient()
    }
    switch (contextName) {
      case InvoiceTabName.CUSTOMER_GENERAL_DATA:
        this.renderedInvoiceForm = RenderedForm.CUSTOMER_GENERAL_DATA_FORM
        break
      case InvoiceTabName.PROFORMA_GENERAL_DATA:
        this.renderedInvoiceForm = RenderedForm.PROFORMA_GENERAL_DATA_FORM
        break
      case InvoiceTabName.PROVIDER_GENERAL_DATA:
        this.renderedInvoiceForm = RenderedForm.PROVIDER_GENERAL_DATA_FORM
        break
      case InvoiceTabName.CUSTOMER_BANKDRAFTS:
        this.renderedInvoiceForm = RenderedForm.CUSTOMER_BANKDRAFTS_FORM
        break
      case InvoiceTabName.PROVIDER_BANKDRAFTS:
        this.renderedInvoiceForm = RenderedForm.PROVIDER_BANKDRAFTS_FORM
        break
      case InvoiceTabName.PROFORMA_PAYMENT_METHODS:
        this.renderedInvoiceForm = RenderedForm.PROFORMA_PAYMENT_METHODS_FORM
        break
      case ContextName.BILLING_CUSTOMER_BANKDRAFTS:
        this.renderedInvoiceForm = ContextName.BILLING_CUSTOMER_BANKDRAFTS
        break
      case ContextName.BILLING_PROVIDER_BANKDRAFTS:
        this.renderedInvoiceForm = ContextName.BILLING_PROVIDER_BANKDRAFTS
        break
      case ContextName.BILLING_PROVISIONS:
        this.renderedInvoiceForm = ContextName.BILLING_PROVISIONS
        break
      case ContextName.BILLING_ORDER_FORMS:
        this.renderedInvoiceForm = ContextName.BILLING_ORDER_FORMS
        break
    }
  }

  async sendEmailDialog() {
    this.save(false)
    this.showDialog({
      type: DialogTypes.INFO,
      message: this.$t('components.dialog.send_invoce_email'),
      action: this.sendEmail
    })
  }

  async save(redirectToGridTable = true, resolve: any = null, _idObjectType: string = '') {
    ;(this as any).regularizeFieldsOnSave()
    await this.saveRegisterFormData({
      endpoint: this.endpoints(this.entity(this.getContext).type).save,
      idSelectedRegister: this.entity(this.getContext).id,
      goToURL: redirectToGridTable ? this.reviewRedirect() : null,
      context: this.getContext
    })

    this.showDashboardSpinnerMutation(SpinnerDashboardContext.UNPAID_INVOICES_LIST)

    if (resolve) {
      resolve()
    }
  }

  async sendEmail() {
    const { idCustomer, id, numberInvoice } = this.selectedRegisterData(this.getContext)
    await this.sendInvoiceNotification({
      templateType: this.getContext,
      notificationData: {
        idCustomer,
        idInvoice: id,
        numberInvoice
      }
    })
  }

  async showTemplateSelectDialog() {
    await this.save(false)
    if (this.hasCompanyTemplateInvoice) {
      this.showTemplateDialog = this.checkIfOnlyOneInvoice()
    } else {
      this.showPDFDialog()
    }
  }

  showPDFDialog() {
    this.showDialogAction({
      type: DialogTypes.INFO,
      message: this.$t('components.dialog.print_pdf_info'),
      mainButtonText: this.$t('action_buttons.accept'),
      hideSecondaryButton: true,
      action: this.printInvoice
    })
  }

  printInvoice() {
    const id = this.selectedInvoiceId

    this.printInvoiceAction({
      idInvoice: id,
      invoiceType: this.entity(this.getContext).alias
    })
      .then((response: any) => {
        const url = window.URL.createObjectURL(response.data)
        window.open(url)
      })
      .catch(() => {
        this.showDialogAction({
          type: DialogTypes.ERROR,
          message: this.$t('errors.generic_error')
        })
      })
  }

  onFieldChanged(schema: any, formData: any, field: any, value: any) {
    // Charged date field logic when is charged - Billing mixin
    if (field.id === InvoicesFormFields.CHARGED) {
      ;(this as any).chargedSwitchLogic(schema, formData, field, value)
    }

    // Payment date field logic when is paid - Billing mixin
    if (field.id === InvoicesFormFields.PAID) {
      ;(this as any).paymentSwitchLogic(schema, formData, field, value)
    }
  }

  showDialogConvertToCustomerInvoice() {
    this.showDialogAction({
      type: DialogTypes.INFO,
      message: this.$t('components.dialog.associated_invoice'),
      action: this.convertToCustomerInvoice
    })
  }

  async convertToCustomerInvoice() {
    try {
      const newCustomerInvoiceId = await this.convertProformaToCustomerInvoice(this.selectedInvoiceId)

      this.showAlert({
        type: AlertsTypes.SUCCESS,
        message: this.$t('components.alerts.associated_invoice_success'),
        componentWhereIsRendered: ComponentWhereIsRendered.TABS_VIEW
      })

      const routeData = this.$router.resolve({
        name: `${URLS.BILLING}-${URLS.CUSTOMER_INVOICES}`,
        params: { selectedRegisterId: newCustomerInvoiceId }
      })

      window.open(routeData.href, '_blank')

      this.componentKey += 1
    } catch (error) {
      this.showDialogAction({
        type: DialogTypes.ERROR,
        message: this.$t('components.dialog.assocciated_invoice_error')
      })
    }
  }

  setNewRegisterBreadcrumbLiteral() {
    if (ContextName.BILLING_PROVISIONS === this.context) {
      return this.$t('views.selected_register.new_provision')
    } else if (ContextName.BILLING_ORDER_FORMS === this.context) {
      return this.$t('views.selected_register.new_order_form')
    }
    return this.$t('views.selected_register.new_invoice')
  }

  remove() {
    this.showDialog({
      type: DialogTypes.INFO,
      message: this.$t('components.dialog.remove_register_text', {
        text: this.setDialogText,
        register: this.getSelectedRegisterData[this.entity(this.getContext).keyName]
      }),
      action: this.removeRegister
    })
  }

  async generateTemplateDialog(templateSelected: any) {
    await printInvoice(templateSelected, this.entityId, this.entityType)
    this.showTemplateDialog = false
  }

  showAlertResult(message: any, alertType: AlertsTypes) {
    this.showAlert({
      type: alertType,
      message,
      componentWhereIsRendered: ComponentWhereIsRendered.TABS_VIEW
    })
  }

  checkIfOnlyOneInvoice(): boolean {
    if ((this.tree && this.tree.length === 0) || this.tree.length > 1) {
      return true
    }
    const template: ITemplate = this.tree[0]
    if (!template.isDirectory) {
      this.generateTemplateDialog(template)
      return false
    }
    return true
  }

  async checkIfComesFromExpedient() {
    await this.$nextTick()
    if (
      this.routeFromName === `${URLS.EXPEDIENTS}-${URLS.EXPEDIENTS}` &&
      this.getContext === ContextName.BILLING_ORDER_FORMS
    ) {
      Vue.set(this.getFormData, InvoicesFormFields.EXPEDIENT_ID, this.selectedRegisterId(ContextName.EXPEDIENTS))
      if (this.expedientCustomerFactGetter) {
        Vue.set(this.getFormData, InvoicesFormFields.EXPEDIENT_CUSTOMER_FACT, this.expedientCustomerFactGetter)
      }
    }
  }
}
</script>
