import { Module } from 'vuex'
import { getters } from '@/store/modules/professionals/professionalsGetters'
import { actions } from '@/store/modules/professionals/professionalsActions'
import { mutations } from '@/store/modules/professionals/professionalsMutations'
import { ProfessionalsState } from '@/store/modules/professionals/professionalsTypes'
import { RootState } from '@/store/types/storeGlobalTypes'

export const state: ProfessionalsState = {
  professionals: []
}

const namespaced: boolean = true

export const professionalsModule: Module<ProfessionalsState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations
}
