export enum ActionFields {
  ACTION_TYPE = 'typeId',
  APPOINTMENT = 'appointment',
  APPOINTMENT_FIELDS = 'appointmentFields',
  BILLABLE = 'billable',
  BILLED_FIELDS = 'billedFields',
  CATEGORY = 'categoryId',
  CODE = 'code',
  COLOR = 'color',
  DATE_ACTION_AT = 'dateActionAt',
  DURATION = 'duration',
  ECONOMIC_ESTIMATE = 'economicEstimate',
  END = 'end',
  EXPIRATION = 'expiration',
  FULL_DAY = 'appointment_fullDay',
  HAS_APPOINTMENT = 'hasAppointment',
  PRESENTATION = 'presentationDate',
  PUBLIC = 'public',
  RATE_ID = 'rateId',
  RELATIONS = 'relations',
  SCHEDULE_LEXBOX = 'scheduleLexbox',
  STAGE = 'idStage',
  START = 'start',
  STATE = 'stateId',
  TIMING_FIELDS = 'timingFields',
  USER_ID = 'userId'
}

export enum AppointmentFields {
  APPOINTMENT_START = 4,
  APPOINTMENT_END = 5
}
