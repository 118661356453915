<template lang="pug">

  div(:class="['lexon-chronometer-container', { 'time-is-running': timeIsRunning, 'time-is-stopped': (!timeIsRunning && timeStopped) }]")
    span(:class="[icons.hourglass, 'hourglass-icon']")
    div(class="time-container") {{ time }}
    span(
      :class="[icons.stop, 'stop-icon']"
      @click="showDialog"
    )
    span(
      :class="[renderIcon, 'action-icon']"
      @click="startOrStopTime"
    )

</template>

<script lang="ts">
import { changeDateFormat } from '@/helpers/dateTime'
import { Icons } from '@/icons/icons'
import { URLS } from '@/router/routes/urlRoutes'
import { DialogTypes } from '@/store/modules/dialog/dialogTypes'
import { ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import { Vue, Component } from 'vue-property-decorator'
import { Action, Mutation } from 'vuex-class'
import { ChronometerNewActionInfo } from '@/store/modules/actions/actionsTypes'

const actionsModule: string = ModuleNamespaces.ACTIONS
const dialogModule = ModuleNamespaces.DIALOG

@Component
export default class LexonChronometerComponent extends Vue {
  @Action('showDialog', { namespace: dialogModule })
  showDialogAction: ({}) => {}
  @Action('saveSelectedActionType', { namespace: actionsModule })
  saveSelectedActionType: () => void

  @Mutation('SET_CHRONOMETER_NEW_ACTION_INFO', { namespace: actionsModule })
  setChronometerNewActionInfoMutation: (data: ChronometerNewActionInfo) => {}

  icons = {
    hourglass: Icons.HOURGLASS,
    pause: Icons.PAUSE,
    play: Icons.PLAY,
    stop: Icons.STOP
  }

  timeIsRunning = false

  time = '00:00:00'

  timeBegan: null | Date = null

  timeStopped: null | Date = null

  started: number | undefined = undefined

  stoppedDuration = 0

  get renderIcon() {
    return this.timeIsRunning ? this.icons.pause : this.icons.play
  }

  startOrStopTime() {
    if (this.timeIsRunning) {
      this.stop()
      return
    }

    if (!this.timeBegan) {
      this.timeBegan = new Date()
    }

    if (this.timeStopped) {
      const now = new Date() as unknown as number
      this.stoppedDuration += now - (this as any).timeStopped
    }

    this.started = setInterval(this.clockRunning, 10)
    this.timeIsRunning = true
  }

  clockRunning() {
    const currentTime = new Date() as unknown as number
    const timeElapsed = new Date(currentTime - (this as any).timeBegan - this.stoppedDuration)
    const hour = timeElapsed.getUTCHours()
    const min = timeElapsed.getUTCMinutes()
    const sec = timeElapsed.getUTCSeconds()

    this.time = `${this.zeroPrefix(hour, 2)}:${this.zeroPrefix(min, 2)}:${this.zeroPrefix(sec, 2)}`
  }

  zeroPrefix(num: number, digit: number) {
    let zero = ''
    for (let i = 0; i < digit; i++) {
      zero += '0'
    }
    return (zero + num).slice(-digit)
  }

  stop() {
    this.timeIsRunning = false
    this.timeStopped = new Date()
    clearInterval(this.started)
  }

  getMinutes(time: string) {
    const hours = time.substring(0, 2)
    const minutes = time.substring(3, 5)
    const total = Number(hours) * 60 + Number(minutes)
    return total
  }

  calculateNewActionInfo() {
    this.timeStopped = new Date()
    const start = this.timeBegan as unknown as string
    const end = this.timeStopped as unknown as string
    const dateStart = changeDateFormat(start, '', 'DD/MM/YYYY HH:mm')
    const dateEnd = changeDateFormat(end, '', 'DD/MM/YYYY HH:mm')
    const chronometerInfo: ChronometerNewActionInfo = {
      start: dateStart,
      end: dateEnd,
      duration: this.getMinutes(this.time)
    }
    this.setChronometerNewActionInfoMutation(chronometerInfo)
  }

  resetTimer() {
    this.timeIsRunning = false
    this.time = '00:00:00'
    this.timeStopped = null
    this.stoppedDuration = 0
    this.timeBegan = null
    clearInterval(this.started)
  }

  async goToForm() {
    await this.saveSelectedActionType()
    this.$router.push({
      name: `${URLS.ACTIONS}-${URLS.CALLS}`,
      params: { selectedRegisterId: this.$t('views.selected_register.new').toString() }
    })
  }

  showDialog() {
    if (this.timeBegan) {
      this.showDialogAction({
        action: this.goToForm,
        icon: Icons.HOURGLASS,
        message: this.$t('components.dialog.action_management'),
        title: this.$t('components.dialog.action_management_title'),
        type: DialogTypes.CUSTOM_DIALOG
      })
      this.calculateNewActionInfo()
      this.resetTimer()
    }
  }
}
</script>

<style lang="scss" scoped>
.lexon-chronometer-container {
  @include flex;
  position: relative;
  height: 35px;
  top: -2px;
  background-color: $gray-03;
  margin: 0 20px;
  padding: 0 14px;
  border-radius: 25px;

  .hourglass-icon {
    font-size: 18px;
  }

  .time-container {
    @include flex;
    width: 80px;
    font-family: $secondary-font;
    font-size: 16px;
    color: $gray-02;
  }

  .stop-icon {
    font-size: 28px;
    color: $gray-02;
  }

  .action-icon {
    font-size: 28px;
    color: $gray-02;
    cursor: pointer;

    &:hover {
      color: $corporate-color;
    }
  }

  &.time-is-running {
    background-color: $blue-04;
    color: $white-01;

    .time-container {
      color: $white-01;
    }

    .stop-icon {
      color: $white-01;
      cursor: pointer;
    }

    .action-icon {
      color: $white-01;
    }
  }

  &.time-is-stopped {
    background-color: $gold-01;
    color: $white-01;

    .time-container {
      color: $white-01;
    }

    .stop-icon {
      color: $white-01;
      cursor: pointer;
    }

    .action-icon {
      color: $white-01;
    }
  }
}
</style>
