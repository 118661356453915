import { http, httpNoAuthorizationHeader } from '@/plugins/axios'
import { AuthData, authLexonPartnerId, Credentials } from '@/store/modules/auth/authTypes'
import { jwtDecode } from 'jwt-decode'
export class AuthService {
  constructor() {}

  login(url: string, credentials: Credentials) {
    const params = new URLSearchParams()
    params.append('_username', credentials.email)
    params.append('_password', credentials.password)
    return http.post(url, params)
  }

  selectCompany(url: string) {
    return http.post(url)
  }

  refreshToken(url: string, paramsObject: { idCompany: string; userName: string }) {
    const params = new URLSearchParams()
    params.append('userName', paramsObject.userName)
    params.append('idCompany', paramsObject.idCompany)
    return http.post(url, params)
  }

  makeKeepAliveJoomla() {
    const url: string = `${process.env.VUE_APP_URL}/index.php?option=com_lexon&task=api_security.keepalive`
    return httpNoAuthorizationHeader.post(url)
  }

  joomlaLogout() {
    const url: string = `${process.env.VUE_APP_URL}/index.php?option=com_lexon&task=api_security.logout`
    return httpNoAuthorizationHeader.post(url)
  }

  navisionLogin(navisionToken: string, url: string) {
    const params = new URLSearchParams()
    params.append('token', navisionToken)
    return http.post(url, params)
  }

  async partnerTokenExchange(partnerToken: string): Promise<AuthData>{
    const payload: { [key: string]: any } = jwtDecode(partnerToken);
    const headers = {
      'Authorization': `Bearer ${partnerToken}`,
      [authLexonPartnerId]: payload.partnerId
    }
    const { data } = await http.post(
      '/v2/token-exchange',
      null,
      {headers}
    )
    return data as AuthData
  }
}
