import { GetterTree } from 'vuex'
import { ProfessionalsState } from '@/store/modules/professionals/professionalsTypes'
import { RootState } from '@/store/types/storeGlobalTypes'
import { formatFieldsTypeDateEPOCH } from '@/helpers/dateTime'

export const getters: GetterTree<ProfessionalsState, RootState> = {
  getProfessionalsListWithFormattedDates(state) {
    return formatFieldsTypeDateEPOCH(state.professionals)
  }
}
