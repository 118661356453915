<template lang="pug">
  span( :class="[getIcon, 'cell-icon']" )
</template>

<script lang="ts">
import { Vue } from 'vue-property-decorator'
import { Component } from 'vue-property-decorator'

@Component({
  name: 'iconColumnTemplate'
})
export default class IconColumnTemplateComponent extends Vue {
  data: any

  get iconClassName() {
    return (this as any).data.column.customAttributes.iconClassName
  }

  get columnValue() {
    return (this as any).data.iconColumnValue === '1'
  }

  get getIcon() {
    return (this as any).columnValue ? (this as any).iconClassName : ''
  }
}
</script>
