<template lang="pug">

  div(class="firma-dialog-container")

    //- HEADER
    div(class="header-container")
      CustomDialogHeaderComponent(:headerTitle="texts.title")

    div(class="wrapper")
      //- GRID
      GridTable(
        v-if="gridItems && gridItems.length"
        :itemsData="gridItems"
        :gridConfiguration="listConfiguration['Config']"
        :toolbarOptions="[]"
        :contextMenuItems="contextMenuItems"
        :showColumnChooser="false"
        :showHeader="false"
        :saveOnClickedCell="false"
        @show-info-bar="showInfoBar = true"
        @see-details="seeDetails"
        @contextMenuBeforeOpen="onContextMenuBeforeOpen"
        :listName="listConfiguration['Alias']"
        context="Firma"
      )

      //- INFOBAR
      InfoBarComponent(
        class="info-bar"
        v-if="showInfoBar"
        :infoText="texts.errorText"
        :type="errorBar"
      )

    //- SPINNER
    SpinnerLayerComponent(v-if="loadingSpinner || gridItems.length === 0" class="spinner-layer" :loadingText="spinnerText.receiving")


</template>

<script lang="ts">
import { Component, Mixins, Watch } from 'vue-property-decorator'
import FirmaDialogsMixin from '@/mixins/firma/FirmaDialogsMixin.vue'
import { Icons } from '@/icons/icons'
import { InfoBarTypes } from '@/components/InfoBar/types/InfoBarComponentTypes'
import { Action, Getter, Mutation } from 'vuex-class'
import { ListNames } from '@/store/modules/configuration/configurationTypes'
import { ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import {
  FirmaAudit,
  FirmaRequestStatus,
  ParsedFirmaAudit,
  FirmaRequestTypes,
  FirmaAuditDetailEndpoint
} from '@/store/modules/firma/firmaTypes'

const firmaModule = ModuleNamespaces.FIRMA
const configurationModule = ModuleNamespaces.CONFIGURATION

@Component({
  components: {
    CustomDialogHeaderComponent: () => import('@/components/Dialog/CustomDialog/CustomDialogHeaderComponent.vue'),
    GridTable: () => import('@/components/grids/GridTable/GridTableComponent.vue'),
    InfoBarComponent: () => import('@/components/InfoBar/InfoBarComponent.vue'),
    SpinnerLayerComponent: () => import('@/components/Spinner/SpinnerLayerComponent.vue')
  }
})
export default class MonitoringAndAuditsFirmaDialogComponent extends Mixins(FirmaDialogsMixin) {
  @Action('fetchMonitoredAuditsList', { namespace: firmaModule })
  fetchMonitoredAuditsAction: (idDocument: number) => Promise<void>

  @Action('fetchOneAuditInfo', { namespace: firmaModule })
  fetchOneAuditInfoAction: (data: any) => Promise<void>

  @Action('fetchCurrentListConfiguration', { namespace: configurationModule })
  fetchCurrentListConfiguration: (alias: string) => {}

  @Mutation('RESET_FIRMA_STATE', { namespace: firmaModule })
  resetFirmaState: () => void

  @Getter('getMonitoredAuditsListItems', { namespace: firmaModule })
  monitoredAuditsGetter: FirmaAudit[]

  @Getter('getAuditsStatusInfo', { namespace: firmaModule })
  auditsStatusInfoGetter: any[]

  @Getter('getCurrentListConfiguration', { namespace: configurationModule }) listConfiguration: object
  gridItems: ParsedFirmaAudit | FirmaAudit[] | [] = []

  texts = {
    title: this.$t('components.firma_dialogs.monitoring_and_audits.title'),
    errorText: this.$t('components.firma_dialogs.monitoring_and_audits.error_text'),
    contextMenuItems: {
      seeDetails: this.$t('components.context_menu.see_details'),
      downloadOriginalDocument: this.$t('components.context_menu.download_original_document')
    }
  }

  contextMenuItems: any = []

  showInfoBar = false

  errorBar = InfoBarTypes.ERROR

  parseItemsFlag = false

  @Watch('monitoredAuditsGetter')
  async getSecondAPICallInfo() {
    await this.getAuditsInfo()
  }

  @Watch('parseItemsFlag')
  async parseGridItemsWatcher() {
    this.gridItems = this.parsedGridItens()
  }

  async created() {
    await this.fetchCurrentListConfiguration(ListNames.SIGN_AUDIT)
    await this.fetchMonitoredAuditsAction(this.signInfoGetter.selectedFileId)
  }

  beforeDestroy() {
    this.resetFirmaState()
  }

  async getAuditsInfo() {
    await Promise.all(
      this.monitoredAuditsGetter.map(async (audit: any) => {
        if (
          audit.idStatus !== FirmaRequestStatus.SENT_TO_FIRMA &&
          audit.idType !== FirmaRequestTypes.CERTIFIED_DOCUMENT
        ) {
          await this.fetchOneAuditInfoAction({ type: this.getTypeNameAudit(audit.idType), guid: audit.guid })
        }
      })
    ).finally(() => {
      this.parseItemsFlag = true
    })
  }

  getTypeNameAudit(type: FirmaRequestTypes) {
    switch (type) {
      case FirmaRequestTypes.FIRMA_REQUEST:
        return FirmaAuditDetailEndpoint.FIRMA_AUDIT_DETAIL
      case FirmaRequestTypes.CERTIFIED_EMAIL:
        return FirmaAuditDetailEndpoint.EMAIL_AUDIT_DETAIL
      case FirmaRequestTypes.CERTIFIED_SMS:
        return FirmaAuditDetailEndpoint.SMS_AUDIT_DETAIL
    }
  }

  parsedGridItens(): ParsedFirmaAudit[] {
    const itemsFirstAPICall = this.monitoredAuditsGetter
    const itemsSecondAPICall = this.auditsStatusInfoGetter
    const parsedItems: any[] = itemsFirstAPICall

    itemsFirstAPICall.forEach((a: FirmaAudit) => {
      itemsSecondAPICall.forEach((b: any) => {
        if (b && a.guid === b.guid) {
          const item = parsedItems.find((item) => item.guid === b.guid)
          item.documents = b.documents
        }
      })
    })
    return parsedItems
  }

  onContextMenuBeforeOpen(_contextMenuModule: any, _contextMenuGridObject: any, selectedRegister: any) {
    if (
      selectedRegister.idType !== FirmaRequestTypes.FIRMA_REQUEST ||
      (selectedRegister.idType === FirmaRequestTypes.FIRMA_REQUEST &&
        !(
          selectedRegister.idStatus === FirmaRequestStatus.SIGNED ||
          selectedRegister.idStatus === FirmaRequestStatus.SIGNED_WITH_AUDIT
        ))
    ) {
      this.contextMenuItems = [
        {
          text: this.texts.contextMenuItems.seeDetails,
          iconCss: Icons.VISIBLE
        }
      ]
    } else {
      this.contextMenuItems = [
        {
          text: this.texts.contextMenuItems.seeDetails,
          iconCss: Icons.VISIBLE
        },
        {
          text: this.texts.contextMenuItems.downloadOriginalDocument,
          iconCss: Icons.DOWNLOAD
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.firma-dialog-container {
  min-height: 200px;

  .header-container {
    margin-bottom: 20px;
  }

  .wrapper {
    @include flex($flex-direction: column, $justify-content: space-between);
    // min-height: 400px;
    height: 100%;

    ::v-deep .grid-container {
      width: 100%;

      .e-content {
        max-height: 450px;

        .e-movablecontent {
          height: 100%;
        }
      }

      .e-row[aria-selected='true'] {
        background-color: $gray-04;
      }

      .e-rowcell {
        vertical-align: middle;

        &:last-of-type {
          border-bottom: none;
        }

        ul {
          line-height: 1;
          padding: 10px 0px;
        }
      }

      .e-scrollbar {
        display: none !important;
      }

      .audit-grid-item {
        @include flex($justify-content: flex-start);
        padding: 3px 0px;
      }
    }
  }

  .spinner-layer {
    --spinner-layer-min-height: 95%;
    position: absolute;
    top: 50px;
    left: 0;
    right: 0;
    // min-height: 250px;
    background-color: $white-01;
  }

  .info-bar {
    margin-top: 20px;
  }
}
</style>
