<template lang="pug">

  div(v-if="tabsData.length > 0")

    TabHeaderComponent(
      @closeForm="closeTabsEvent"
      @saveForm="save"
      :buttons="tabHeaderButtons"
      :title="getTabHeaderTitle"
    )

    TabsComponent(
      ref="tabsComponent"
      :tabs="tabsData"
      :context="this.context"
      class="tabs-second-level"
      :autoRefreshForm="autoRefreshForm"
      :entity="entity"
      :forceSaveProp="false"
      @changed-tab="onTabChange"
    )

</template>

<script lang="ts">
import { Vue, Prop, Component, Emit } from 'vue-property-decorator'
import { UsersMaintenanceTabs } from '@/components/maintenance/Users/types/UsersMaintenanceComponentTypes'
import { ActionName } from '@/components/ActionsBar/types/ActionBarComponentTypes'
import { Icons } from '@/icons/icons'
import TabHeaderComponent from '@/components/tabs/TabHeader/TabHeaderComponent.vue'
import { Action, Getter, Mutation } from 'vuex-class'
import { ContextName, ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import { ConfigurationTypes } from '@/store/modules/configuration/configurationTypes'
import { MaintenanceEntityId } from '@/general/entityIds'

const configurationModule: string = ModuleNamespaces.CONFIGURATION
const formsModule: string = ModuleNamespaces.FORMS
const selectedRegisterModule: string = ModuleNamespaces.SELECTED_REGISTER
const usersModule: string = ModuleNamespaces.USERS

@Component({
  components: {
    TabsComponent: () => import('@/components/tabs/TabsComponent/TabsComponent.vue'),
    TabHeaderComponent
  }
})
export default class UserManintenanceTabsComponent extends Vue {
  @Prop({
    default: {}
  })
  userData!: { id: string; name: string }

  @Action('fetchCurrentViewConfiguration', { namespace: configurationModule })
  fetchCurrentViewConfiguration: ({}) => Promise<{}>

  @Action('savePermissions', { namespace: usersModule })
  savePermissions: () => void

  @Getter('checkIfFormIsValid', { namespace: formsModule })
  checkIfFormIsValid: (context: string) => boolean

  @Getter('getCurrentViewTabs', { namespace: configurationModule })
  getCurrentViewTabs: (context: string) => []

  @Getter('getRegisterFormData', { namespace: formsModule })
  getFormData: () => { name: string }

  @Getter('permissionsHaveChanged', { namespace: usersModule })
  permissionsHaveChanged: boolean

  @Mutation('FETCH_SELECTED_REGISTER_DATA', { namespace: selectedRegisterModule })
  saveSelectedRegisterData: ({}) => Promise<void>

  @Mutation('RESET_PERMISSIONS', { namespace: usersModule })
  resetPermissionsMutation: () => Promise<void>

  autoRefreshForm = false

  entity = {
    alias: 'maintenance',
    id: MaintenanceEntityId.MAINTENANCE
  }

  tabsData = []

  selectedTab = UsersMaintenanceTabs.GENERAL_DATA

  loadedTabsFlag: number | null = null

  get context() {
    return this.selectedTab === UsersMaintenanceTabs.GENERAL_DATA
      ? ContextName.MAINTENANCE_USERS_CONFIG
      : ContextName.MAINTENANCE_USERS_RELATED_FORM
  }

  get tabHeaderButtons() {
    return [
      {
        icon: Icons.CLOSE,
        tooltip: this.$t('action_buttons.close'),
        class: 'red-color',
        action: ActionName.CLOSE,
        show: true
      },
      {
        icon: Icons.CHECK,
        tooltip: this.$t('action_buttons.save'),
        class: 'green-color',
        action: ActionName.SAVE,
        show:
          (this.checkIfFormIsValid(this.context) && this.selectedTab === UsersMaintenanceTabs.GENERAL_DATA) ||
          this.selectedTab === UsersMaintenanceTabs.CUSTOM_PERMISSIONS
      }
    ]
  }

  get getTabHeaderTitle() {
    const name = this.formData.name
    return this.$t('components.users_maintenance.tabs_title', { userName: name })
  }

  get formData(): any {
    return {
      ...this.getFormData,
      id: this.userData.id
    }
  }

  @Emit('closeTabsEvent')
  closeTabsEvent() {}

  @Emit('saveFormEvent')
  saveFormEvent(formData: object) {
    return formData
  }

  @Emit('saveOnFormTabChangeEvent')
  saveOnFormTabChangeEvent(formData: object) {
    return formData
  }

  @Emit('hideFatherComponentSpinnerEvent')
  hideFatherComponentSpinnerEvent() {}

  created() {
    this.init()
    this.saveSelectedRegisterData({ selectedRegisterData: this.userData, context: this.context })
  }

  beforeDestroy() {
    this.resetPermissionsMutation()
  }

  async init() {
    await this.fetchCurrentViewConfiguration({
      objectType: ConfigurationTypes.VIEW,
      alias: this.context,
      context: this.context
    })
    this.tabsData = this.getCurrentViewTabs(this.context)
    this.hideFatherComponentSpinnerEvent()
  }

  async save() {
    if (this.selectedTab === UsersMaintenanceTabs.CUSTOM_PERMISSIONS) {
      this.savePermissionsIfHaveChanged()
      this.$emit('closeTabsEvent')
      return
    }
    this.saveFormEvent(this.formData)
  }

  onTabChange(tabIndex: number, _tab: any) {
    // Realiza un SAVE del registro si sale de la tab del formulario
    if (this.loadedTabsFlag === 0) {
      this.saveSelectedRegisterData({ selectedRegisterData: this.formData, context: this.context })
      this.saveOnFormTabChangeEvent(this.formData)
    }
    this.savePermissionsIfHaveChanged()
    this.loadedTabsFlag = this.selectedTab = tabIndex
  }

  savePermissionsIfHaveChanged(): void {
    if (this.permissionsHaveChanged) {
      this.savePermissions()
    }
  }
}
</script>
