<template lang="pug">

  section(:class="{'lexon-view-container': !showSpinnerLayer}")
    GridTable(
      v-if="Object.keys(listConfiguration).length && reportsData && !showSpinnerLayer"
      :itemsData="reportsData"
      :gridConfiguration="listConfiguration['Config']"
      :title="$t('views.reports.title')"
      :toolbarOptions="toolbarOptionsReportList"
      :contextMenuItems="contextMenuItemsReportList"
      :listName="listConfiguration['Alias']"
      :context="context"
      allowGrouping
    )

</template>

<script lang="ts">
import { mixins } from 'vue-class-component'
import { ParentCategory } from '@/store/modules/menus/menusTypes'
import { Component } from 'vue-property-decorator'
import GridTable from '@/components/grids/GridTable/GridTableComponent.vue'
import { ContextName, ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import ListViewMixin from '@/mixins/ListViewMixin.vue'
import { ListNames } from '@/store/modules/configuration/configurationTypes'
import { Action, Mutation } from 'vuex-class'
import { i18n } from '@/plugins/vue-i18n'
import { Icons } from '@/icons/icons'

const reportsModule: string = ModuleNamespaces.REPORTS
const entitiesModule: string = ModuleNamespaces.ENTITIES

@Component({
  components: {
    GridTable
  }
})
export default class ReportsList extends mixins(ListViewMixin) {
  @Action('fetchListReports', { namespace: reportsModule })
  fetchListReports: () => []

  @Action('resetReports', { namespace: reportsModule })
  resetReports: () => []

  @Mutation('SAVE_MENU_PARENT_CATEGORY_NAME', { namespace: entitiesModule })
  saveMenuParentCategoryName: (parentCategoryName: string) => void

  toolbarOptionsReportList = [
    {
      id: 'filter',
      prefixIcon: Icons.FILTER,
      text: i18n.t('components.grid_table.tooltip.clean_filter'),
      align: 'left',
      cssClass: 'lf-clean-filter-button'
    },
    {
      id: 'search',
      text: 'Search',
      align: 'right'
    },
    {
      id: 'print',
      prefixIcon: Icons.PRINT,
      tooltipText: i18n.t('components.grid_table.tooltip.print'),
      align: 'right'
    },
    {
      id: 'export',
      prefixIcon: Icons.DOWNLOAD,
      tooltipText: i18n.t('components.grid_table.tooltip.export'),
      align: 'right'
    }
  ]

  contextMenuItemsReportList = [
    {
      text: i18n.t('components.context_menu.open_window'),
      iconCss: Icons.OPEN_WINDOW
    },
    {
      separator: true
    },
    {
      text: i18n.t('components.context_menu.remove'),
      iconCss: Icons.REMOVE,
      action: this.showDialog
    }
  ]

  get context() {
    return ContextName.REPORTS
  }

  async created() {
    await this.resetReports()
    await this.fetchListReports()
    this.saveMenuParentCategoryName(ParentCategory.REPORTS)
    this.saveSelectedEntityName('reports')
    await this.fetchCurrentListConfiguration(ListNames.REPORTS)
    this.hideSpinnerLayerAction()
  }
}
</script>
