import { MutationTree } from 'vuex'
import { TandemFilter, TandemSearchState } from '@/store/modules/tandemSearch/tandemSearchTypes'

export const mutations: MutationTree<TandemSearchState> = {
  SET_SEARCH_DATA(state, data) {
    if (data.items) {
      state.data = data.items
      state.facets = data.facets
    }

    if (data.totalHits >= 0) {
      state.totalOccurrences = data.totalHits
    }
  },
  SET_TOTAL_OCCURRENCES(state, totalOccurrences) {
    state.totalOccurrences = totalOccurrences
  },
  SET_SEARCH_TERM(state, term) {
    state.searchTerm = term
  },
  SET_SEARCH_METADATA(state, metadata) {
    state.searchMetadata = metadata
  },
  SET_FILTERS(state, filters: TandemFilter[]) {
    state.filters = filters
  },
  SET_DESCRIPTORS(state, descriptor) {
    state.descriptors = descriptor
  },
  SET_PAGINATIONDATA(state, paginationData) {
    state.paginationData = paginationData
  },
  RESET_SEARCH_DATA(state) {
    state.data = []
    state.facets = []
  }
}
