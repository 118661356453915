import { GetterTree } from 'vuex'
import { RootState } from '@/store/types/storeGlobalTypes'
import { DocumentOnlineState } from '@/store/modules/documentOnline/documentOnlineTypes'

export const getters: GetterTree<DocumentOnlineState, RootState> = {
  getRequestToken(state) {
    return state.accessToken
  },
  getRequestTokenTtl(state) {
    return state.accessTokenTtl
  },
  getFileUrl(state) {
    return state.fileUrl
  },
  getFileName(state) {
    return state.fileName
  },
  getFileVersion(state) {
    return state.fileVersion
  },
  getFileSize(state) {
    return state.fileSize
  },
  getFileToken(state) {
    return state.fileToken.token
  },
  getApplicationType(state) {
    return state.applicationType
  },
  getNeedsConversion(state) {
    return state.needsConversion
  },
  getReadOnly(state) {
    return state.readOnly
  },
  getFileAction(state) {
    return state.action
  }
}
