<template lang="pug">

  PrintOrSaveInvoicesFieldsetComponent(
    :stepNumber="stepNumber"
    :legend="texts.fieldsetLegend"
    class="invoice-configuration-container"
  )
    div(class="row-1")
      span(:class="[icons.bill, 'bill-icon']")
      div(class="selected-invoices-number-container")
        span(class="selected-invoices-number") {{ selectedInvoicesLength }}
        LexonTooltipComponent(
          :icon="icons.check"
          :message="tooltipText"
          position="top"
        )

    div(class="row-2")
      LexonSwitchComponent(
        v-model="actions.print"
        class="switch-field"
        id="print"
        :label="texts.printLabel"
        :disabled="disableConfiguration"
        hide-details
        @input="configButtons"
      )
      p(class="info-text") {{ texts.printInfo }}

    div(class="row-3" v-if="canSave")
      LexonSwitchComponent(
        v-model="actions.save"
        class="switch-field"
        id="save"
        :label="texts.saveLabel"
        :disabled="disableConfiguration"
        hide-details
        @input="configButtons"
      )
      p(class="info-text") {{ texts.saveInfo }}

</template>

<script lang="ts">
import { Component, Emit, Prop, Vue } from 'vue-property-decorator'
import { Getter } from 'vuex-class'
import LexonSwitchComponent from '@/components/forms/fields/LexonSwitch/LexonSwitchComponent.vue'
import PrintOrSaveInvoicesFieldsetComponent from '@/components/billing/PrintOrSaveInvoicesDialog/PrintOrSaveInvoicesFieldsetComponent.vue'
import { Icons } from '@/icons/icons'
import { PrintOrSaveInvoicesSteps } from './types/PrintOrSaveInvoicesDialogTypes'
import LexonTooltipComponent from '@/components/forms/fields/LexonTooltip/LexonTooltipComponent.vue'
import { ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import { PermissionVerbs } from '@/store/modules/auth/authTypes'

const authModule: string = ModuleNamespaces.AUTH

@Component({
  components: {
    LexonSwitchComponent,
    LexonTooltipComponent,
    PrintOrSaveInvoicesFieldsetComponent
  }
})
export default class InvoiceConfigurationComponent extends Vue {
  @Getter('getPermission', { namespace: authModule })
  checkEntityPermissionsGetter: (entityId: number) => PermissionVerbs

  @Prop({
    type: Number,
    required: true
  })
  selectedInvoicesLength!: number

  @Prop({
    type: Boolean,
    required: true,
    default: true
  })
  disableConfiguration!: boolean

  @Prop({
    type: [Number, String],
    required: true
  })
  idEntityType!: number

  printInvoices = false

  stepNumber = PrintOrSaveInvoicesSteps.CONFIGURE_OPTIONS

  actions = {
    print: true,
    save: false
  }

  texts = {
    fieldsetLegend: this.$t('components.invoice_configuration.fieldset_legend'),
    printLabel: this.$t('action_buttons.print'),
    saveLabel: this.$t('action_buttons.save'),
    printInfo: this.$t('components.invoice_configuration.print_switch_info'),
    saveInfo: this.$t('components.invoice_configuration.save_switch_info')
  }

  icons = {
    bill: Icons.BILL,
    check: Icons.CHECK_ROUND_FULL
  }

  tooltipText = this.$t('components.tooltip.selected_files')

  get canSave() {
    const { canSave } = this.checkEntityPermissionsGetter(this.idEntityType)
    return canSave
  }

  @Emit('configButtons')
  configButtons() {
    return this.actions
  }
}
</script>

<style lang="scss" scoped>
::v-deep .tooltip-icon {
  top: -1.5px;
  margin-left: 0;
}

.invoice-configuration-container {
  padding: 0 20px;

  .info-text {
    font-family: $secondary-font;
    font-size: 13px;
    color: $blue-03;
    margin-bottom: 0;
  }

  .row-1 {
    @include flex($justify-content: flex-end);
    color: $corporate-color;

    .bill-icon {
      font-size: 24px;
      margin-right: 10px;
    }

    .selected-invoices-number-container {
      @include borders($width: 2px);
      @include flex($justify-content: space-between);
      height: 26px;
      width: 48px;
      padding: 4px;
      border-radius: 40px;

      .selected-invoices-number {
        width: 50px;
        font-size: 14px;
        font-family: $secondary-font;
        font-weight: bold;
        text-align: center;
      }
    }
  }

  .row-2,
  .row-3 {
    margin-top: 10px;

    .switch-field {
      ::v-deep .lf-switch-container {
        height: 40px;
      }
    }
  }
}
</style>
