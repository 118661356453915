<template lang="pug">

  DashboardWidgetResumeType(
    :headerIcon="headerIcon"
    :headerTitle="headerTitle"
    :widgetData="customerResumeGetter"
    :titleBorderColor="titleBorderColor"
    :showSpinner="showSpinner"
  )

</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import DashboardWidgetResumeType from '@/components/widgets/DashboardWidgets/widgetTypes/DashboardWidgetResumeType.vue'
 import { vars } from '@/styles/styleVars'
import { Icons } from '@/icons/icons'
import { Action, Getter } from 'vuex-class'
import { ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import { SpinnerDashboardContext } from '@/store/modules/spinner/spinnerTypes'
import { DashboardResumeItem } from '@/store/modules/dashboardWidgets/dashboardWidgetsTypes'

const spinnersModule: string = ModuleNamespaces.SPINNER
const dashboardWidgetsModule: string = ModuleNamespaces.DASHBOARD_WIDGETS

@Component({
  components: {
    DashboardWidgetResumeType
  }
})
export default class DashboardCustomersResumeWidget extends Vue {
  @Action('fetchDashboardCustomersResume', { namespace: dashboardWidgetsModule })
  fetchDashboardCustomersResumeAction: () => Promise<void>

  @Getter('getDashboardWidgetsSpinner', { namespace: spinnersModule })
  showWidgetSpinner: (context: string) => boolean

  @Getter('getDashboardCustomerResume', { namespace: dashboardWidgetsModule })
  customerResumeGetter: DashboardResumeItem[]

  headerIcon = Icons.CONTACTS

  headerTitle = this.$t('components.dashboard_widget_resume_type.customers')

  widgetData: DashboardResumeItem[] = []

  titleBorderColor = vars.customer_color

  get showSpinner() {
    return this.showWidgetSpinner(SpinnerDashboardContext.CUSTOMERS_RESUME)
  }

  async created() {
    // Hace la petición solo si ha guardado algun contacto. Utiliza el estado del spinner como un flag
    if (this.showSpinner) {
      this.fetchDashboardCustomersResumeAction()
    }
  }
}
</script>
