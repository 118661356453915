<template lang="pug">
  //- Los estilos se aplican desde el index.html isnsertando una etiqueta <link>
  div(
    v-if="spinner.show"
    class="main-holder"
  )
    div(
      class="spinner-border white spinner-lg spinner-centered"
      role="status"
    )
      span(class="sr-only") {{ $t('components.spinner.loading') }}

    p(
      v-if="spinner.message"
      class="preloader-text"
      v-html="$t('components.spinner.message')"
    )

</template>

<script lang="ts">
import { Vue, Component } from 'vue-property-decorator'
import { Getter } from 'vuex-class'
import { ModuleNamespaces } from '@/store/types/storeGlobalTypes'

const namespace: string = ModuleNamespaces.SPINNER

@Component
export default class SpinnerComponent extends Vue {
  @Getter('getSpinnerObject', { namespace }) spinner: object
}
</script>

<style lang="scss">
.v-application {
  .spinner-border.white {
    color: #fff !important;
    border: 0.25em solid #fff !important;
    border-right-color: transparent !important;
    background-color: transparent !important;
  }
}
</style>
