<template lang="pug">

  button(class="show-or-hide-button" @click.stop="toggle")
    span(:class="[icon, 'show-or-hide-icon']")
    span(class="toggle-text") {{ text }}

</template>

<script lang="ts">
import { Icons } from '@/icons/icons'
import { Vue, Component, Prop, Emit } from 'vue-property-decorator'
import { ShowOrHideTexts } from '@/components/ShowOrHideButton/types/ShowOrHideButtonComponentTypes'

@Component
export default class ShowOrHideTotalsButton extends Vue {
  @Prop({
    type: Boolean,
    required: true
  })
  show!: boolean

  @Prop({
    type: Object,
    required: true
  })
  texts!: ShowOrHideTexts

  @Emit()
  toggle() {}

  get text() {
    return this.show ? this.texts.hide : this.texts.show
  }

  get icon() {
    return this.show ? Icons.NOT_VISIBLE : Icons.VISIBLE
  }
}
</script>

<style lang="scss" scoped>
.toggle-text {
  min-width: 105px;
}

.show-or-hide-button {
  @include flex;
  font-family: $corporate-font-bold;
  font-size: 14px;
  color: var(--toggle-btn-color, $corporate-color);
  margin: var(--toggle-btn-margin, 0 15px 15px 10px);

  .show-or-hide-icon {
    font-size: 20px;
    padding: 0 5px 5px 0;
  }
}
</style>
