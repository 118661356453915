<template lang="pug">
  div(id="customers-time-status")
    span {{ getTime }}
</template>

<script lang="ts">
import { Vue } from 'vue-property-decorator'
import { Component } from 'vue-property-decorator'
import { numberToHourFormat } from '@/helpers/helpers'

@Component({
  name: 'dataToHourTemplate'
})
export default class DataToHourTemplateComponent extends Vue {
  data: any

  get getTime() {
    return numberToHourFormat((this as any).data.duration)
  }
}
</script>
