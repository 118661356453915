import { GetterTree } from 'vuex'
import { ErrorsState } from '@/store/modules/errors/errorsTypes'
import { RootState } from '@/store/types/storeGlobalTypes'

export const getters: GetterTree<ErrorsState, RootState> = {
  getError(state): string | null {
    return state.error
  },
  existsEndpointErrorStatus(state): boolean {
    return state.endpointErrorFlag
  }
}
