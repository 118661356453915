<template lang="pug">
  div(class="custom-fields-preview-component")
    FormGeneratorComponent(
      v-if="formPreviewSchema && formPreviewSchema.length"
      :schema="formPreviewSchema"
      :context="context"
    )
    CustomFieldsEmptyMessageComponent(v-else)

</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator'
import CustomFieldsEmptyMessageComponent from '@/components/customFields/CustomFieldsCustomFieldsEmptyMessage/CustomFieldsEmptyMessageComponent.vue'
import FormGeneratorComponent from '@/components/forms/FormGenerator/FormGeneratorComponent.vue'
import { customFieldTypesEnum } from '@/store/modules/customFields/customFieldsTypes'

@Component({
  components: {
    CustomFieldsEmptyMessageComponent,
    FormGeneratorComponent
  }
})
export default class CustomFieldsPreviewComponent extends Vue {
  @Prop({
    type: Array,
    required: true,
    default: () => []
  })
  schema!: []

  @Prop({
    type: String,
    required: true
  })
  context!: string

  formPreviewSchema: []
  inFieldset: boolean = false

  created() {
    ;(this as any).formPreviewSchema = JSON.parse(JSON.stringify(this.schema))
    this.formPreviewSchema.forEach((item: any) => {
      if (item.idCustomFieldType === customFieldTypesEnum.GROUP) {
        item.fieldset = true
        item.fieldsetName = item.label
        this.inFieldset = true
        if (item.fields) {
          item.fields.forEach((i: any) => {
            this.parseField(i)
          })
        }
      } else {
        this.inFieldset = false
        this.parseField(item)
      }
    })
  }

  parseField(field: any) {
    let validationRules = null
    field.id = field.id.toString()
    if (field.vid) {
      field.vid = field.vid.toString()
    }
    switch (field.idCustomFieldType) {
      case customFieldTypesEnum.TEXT:
        field.fieldType = 'LexonTextFieldComponent'
        field.text = 'text'
        break
      case customFieldTypesEnum.NUMERIC:
        field.fieldType = 'LexonNumericComponent'
        field.decimals = 2
        field.format = 'N'
        field.max = 9999999999999999
        break
      case customFieldTypesEnum.CURRENCY:
        field.fieldType = 'LexonCurrencyComponent'
        break
      case customFieldTypesEnum.DATE:
        field.fieldType = 'LexonDateTimePickerComponent'
        break
      case customFieldTypesEnum.LIST:
        field.fieldType = 'LexonSelectComponent'
        if (field.listOptions) {
          field.propItems = field.listOptions
          field.propItems.forEach((item: any) => {
            item.name = item.value
          })
        }
        field.paginated = false
        field.isMultiselect = Boolean(field.multipleSelection)
        if (field.multipleSelection) {
          field.renderChips = true
          field.showAllChips = false
        }
        break
      case customFieldTypesEnum.BOOLEAN:
        field.fieldType = 'LexonSwitchComponent'
        break
      case customFieldTypesEnum.TEXTAREA:
        field.fieldType = 'LexonTextAreaComponent'
        field.xs = 12
        field.xl = 12
        if (this.inFieldset) {
          field.md = 12
          field.lg = 12
          field['offset-md'] = 0
          field['offset-lg'] = 0
        } else {
          field.md = 10
          field.lg = 10
          field['offset-md'] = 1
          field['offset-lg'] = 1
        }
        field['closed-menu-lg'] = 12
        field['offset-closed-menu-lg'] = 0
        break
    }
    if (field.required) {
      validationRules = 'required'
    }
    if (field.lenght) {
      validationRules === null ? (validationRules = 'max:' + field.lenght) : (validationRules += '|max:' + field.lenght)
    }
    if (validationRules) {
      field.validationRules = validationRules
    }
  }
}
</script>
