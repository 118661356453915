import { MutationTree } from 'vuex'
import { ErrorsState } from '@/store/modules/errors/errorsTypes'

export const mutations: MutationTree<ErrorsState> = {
  SET_ERROR(state, error) {
    state.error = error
  },
  CLEAR_ERROR(state) {
    state.error = null
  },
  EXISTS_ENDPOINT_ERROR(state) {
    state.endpointErrorFlag = true
  },
  CLEAR_ENDPOINT_ERROR(state) {
    state.endpointErrorFlag = false
  }
}
