<template lang="pug">

  div(class="entity-type-selector" )
    div(
      class="click-area"
      @click.stop="openContextMenuComponent"
    )
      span(:class="[icons.mapMarker, 'icon']")
      span(class="text" v-html="text")
      span(
        :class="[icons.arrow, 'arrow', 'toggle-down']"
      )

    ejs-contextmenu(
      id="contextMenuComponent"
      ref="contextMenuComponent"
      class="entity-type-selector-context-menu"
      :items="actionAddContextMenuItems"
      :beforeItemRender='itemRender'
      :beforeClose="rotateArrow"
    )

</template>

<script lang="ts">
import { Vue, Component } from 'vue-property-decorator'
import { Icons } from '@/icons/icons'
import { ContextMenuPlugin } from '@syncfusion/ej2-vue-navigations'
import { TranslateResult } from 'vue-i18n'

Vue.use(ContextMenuPlugin)

@Component
export default class EntityTypeSelectorComponent extends Vue {
  $refs!: {
    contextMenuComponent: any
  }

  icons = {
    mapMarker: Icons.MAP_MARKER,
    arrow: Icons.ANGLE_DOWN,
    checkIcon: Icons.CHECK
  }

  actionAddContextMenuItems = [
    {
      text: 'Clientes',
      iconCss: this.icons.checkIcon
    },
    {
      text: 'Contrarios',
      iconCss: this.icons.checkIcon
    },
    {
      text: 'Proveedores',
      iconCss: this.icons.checkIcon
    },
    {
      text: 'Abogados propios',
      iconCss: this.icons.checkIcon
    },
    {
      text: 'Abogados contrarios',
      iconCss: this.icons.checkIcon
    },
    {
      text: 'Procuradores propios',
      iconCss: this.icons.checkIcon
    },
    {
      text: 'Procuradores contrarios',
      iconCss: this.icons.checkIcon
    },
    {
      text: 'Notarios',
      iconCss: this.icons.checkIcon
    }
  ]

  selectedEntity: string = 'Clientes'

  contextMenuComponent: any = null

  get mdAndDownBreakPoint(): boolean {
    return this.$vuetify.breakpoint.mdAndDown
  }

  get text(): TranslateResult {
    return this.mdAndDownBreakPoint
      ? this.$t('components.entity_type_selector.text_small', { type: this.selectedEntity })
      : this.$t('components.entity_type_selector.text_long', { type: this.selectedEntity })
  }

  mounted() {
    this.contextMenuComponent = this.$refs.contextMenuComponent
  }

  rotateArrow(): void {
    const upClass = 'toggle-up'
    const downClass = 'toggle-down'
    const arrow = this.$el.querySelector('.arrow')

    if (arrow) {
      if (arrow.classList.contains(downClass)) {
        arrow.classList.replace(downClass, upClass)
      } else {
        arrow.classList.replace(upClass, downClass)
      }
    }
  }

  openContextMenuComponent(args: any): void {
    this.rotateArrow()
    const rect = args.target.getBoundingClientRect()
    const top = rect.top + window.scrollY + 20
    const left = rect.left
    this.contextMenuComponent.open(top, left)
  }

  itemRender(args: any): void {
    if (args.item.text === this.selectedEntity) {
      args.element.classList.add('selected-item')
    }
  }
}
</script>

<style lang="scss" scoped>
.entity-type-selector {
  font-family: $corporate-font-bold;
  font-size: 12px;
  color: $blue-04;
  text-transform: uppercase;
  padding-bottom: 10px;

  .click-area {
    width: 300px;
    cursor: pointer;
  }

  .icon {
    margin-right: 4px;
    font-size: 20px;

    &::before {
      position: relative;
      top: 4px;
    }
  }

  .arrow {
    display: inline-block;
    font-size: 15px;
    margin-top: 2px;
    margin-left: 8px;

    &.toggle-up {
      transform: rotate(180deg);
    }

    &.toggle-down {
      transform: rotate(0deg);
    }
  }

  ::v-deep .selected {
    font-size: 17px;
  }
}
</style>

<style lang="scss">
.e-contextmenu-wrapper {
  .entity-type-selector-context-menu {
    min-width: 230px;

    .e-menu-item {
      padding: 0 !important;

      .e-menu-icon::before {
        position: relative;
        bottom: 2px;
        color: transparent;
      }

      &.selected-item {
        background-color: $corporate-color;
        color: $white-01;

        .e-menu-icon::before {
          color: $white-01;
        }
      }
    }
  }
}
</style>
