import { DetailsViewSettingsModel } from '@syncfusion/ej2-vue-filemanager'

export enum FirmaContextMenuId {
  CERTIFY_DOCUMENT = 'certity-document',
  FIRMA = 'firma',
  MONITORING_REVIEW = 'monitoring-review',
  SEND_CERTIFIED_EMAIL = 'send-certified-email',
  SEND_CERTIFIED_SMS = 'send-certified-sms',
  SEND_FIRMA = 'send-firma'
}

export enum EditContextMenuId {
  EDIT = 'edit',
  CUT = 'cut',
  COPY = 'copy',
  RENAME = 'rename',
  PASTE = 'paste'
}

export enum SendContextMenuId {
  SEND = 'send',
  EMAIL = 'email',
  PUBLISH = 'publish',
  UNPUBLISH = 'unpublish'
}

export enum OpenMSOffice {
  WOPI = 'wopi',
  LOCAL = 'local'
}

export interface DetailsViewSettings extends DetailsViewSettingsModel {
  openMSOffice?: OpenMSOffice
}

export interface HeaderColumn {
  index: number | null
  width: number | null
}
