import { Module } from 'vuex'
import { getters } from '@/store/modules/search/searchGetters'
import { actions } from '@/store/modules/search/searchActions'
import { mutations } from '@/store/modules/search/searchMutations'
import { SearchState } from '@/store/modules/search/searchTypes'
import { RootState } from '@/store/types/storeGlobalTypes'

export const state: SearchState = {
  data: {},
  isSearchView: false,
  searchTerm: ''
}

const namespaced: boolean = true

export const searchModule: Module<SearchState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations
}
