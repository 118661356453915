import { http } from '@/plugins/axios'

export class RepositoryService {
  constructor() {}

  post(url: string, data: object | null = null) {
    if (data) {
      return http.post(url, data)
    } else {
      return http.post(url)
    }
  }

  downloadBlob(url: string, data: any | null = null) {
    return http.post(url, data, { responseType: 'blob' })
  }

  insertDocument(data: any) {
    const response: any = http.post('repository/document/insert', data, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    return response
  }

  updateDocument(data: any) {
    const response: any = http.post('repository/document/update', data, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    return response
  }

  updateFolder(data: any) {
    const response: any = http.post('repository/folder/updateMetadata', data, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    return response
  }

  updateFolderPublic(data: any) {
    const response: any = http.post('repository/folder/updatePublic', data, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    return response
  }

  createNewDocumentOnline(data: any) {
    const response: any = http.post('repository/document/online/insert', data)
    return response
  }

  insertDocumentIntervener(data: any) {
    const response: any = http.post('repository/document/intervener/insert', data)
    return response
  }

  deleteDocumentIntervener(id: any) {
    const response: any = http.delete(`repository/document/intervener/${id}/delete`)
    return response
  }
}
