<template lang="pug">

  WidgetBaseComponent(
    class="dashboard-recent-activity-widget"
    :loadedData="!showSpinner"
    :showFooter="false"
    :showHeader="false"
  )

    template(v-slot:body)

      section(v-resize="calculateHeightToSetScroll")
        h2(class="widget-title") {{ title }}
        ul(
          v-if="allDataLoaded"
          class="activity-list"
          :style="{maxHeight: `${activityListHeight}px`}"
        )
          li(
            v-for="(item, index) in widgetData"
            :key="index"
            class="activity-item"
            :style="getBorderColor(item.entidad)"
          )
            span(class="item-title") {{ item.title }}
            div(class="date-container")
              div(class="date")
                span(:class="[icons.calendar, 'icon']")
                span {{ item.date }}
              div(class="hour")
                span(:class="[icons.clock, 'icon']")
                span {{ item.hour }}

        DashboardNoResultsComponent(v-else :icon="icons.clock" class="no-result-component")

</template>

<script lang="ts">
import { Component, Vue, Watch } from 'vue-property-decorator'
import { Icons } from '@/icons/icons'
 import { vars } from '@/styles/styleVars'
import { DashboardRecentActivityItem } from '@/store/modules/dashboardWidgets/dashboardWidgetsTypes'
import DashboardNoResultsComponent from '@/components/widgets/DashboardWidgets/DashboardNoResultsComponent.vue'
import WidgetBaseComponent from '@/components/widgets/WidgetBaseComponent.vue'
import { Action, Getter } from 'vuex-class'
import { ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import { SpinnerDashboardContext } from '@/store/modules/spinner/spinnerTypes'

const dashboardWidgetsModule: string = ModuleNamespaces.DASHBOARD_WIDGETS
const spinnersModule: string = ModuleNamespaces.SPINNER

@Component({
  components: {
    DashboardNoResultsComponent,
    WidgetBaseComponent
  }
})
export default class DashboardRecentActivityWidget extends Vue {
  @Action('fetchDashboardRecentActivity', { namespace: dashboardWidgetsModule })
  fetchDashboardRecentActivityAction: () => Promise<void>

  @Getter('getDashboardWidgetsSpinner', { namespace: spinnersModule })
  showWidgetSpinner: (context: string) => boolean

  @Getter('getDashboardRecentActivity', { namespace: dashboardWidgetsModule })
  recentActivityItemsGetter: DashboardRecentActivityItem[]

  get showSpinner() {
    return this.showWidgetSpinner(SpinnerDashboardContext.RECENT_ACTIVITY)
  }

  icons = {
    calendar: Icons.CALENDAR_CHECK,
    clock: Icons.CLOCK
  }

  activityListHeight = 0

  widgetData: DashboardRecentActivityItem[] = []

  borderColors = {
    Documentos: vars.document_color,
    Expediente: vars.expedient_color,
    Actuacion: vars.action_color,
    Interviniente: vars.customer_color,
    Factura: vars.invoice_color
  }

  title = this.$t('components.dashboard_recent_activity_widget.title')

  @Watch('widgetData')
  calculateListHeight() {
    this.calculateHeightToSetScroll()
  }

  get allDataLoaded() {
    return this.widgetData && this.widgetData.length
  }

  async created() {
    await this.fetchDashboardRecentActivityAction()
    this.widgetData = this.recentActivityItemsGetter
  }

  async calculateHeightToSetScroll() {
    await this.$nextTick()
    const mainSection = this.$el.querySelector('.main-section')
    if (mainSection) {
      const mainSectionHeight = mainSection.getBoundingClientRect().height
      this.activityListHeight = mainSectionHeight
    }
  }

  getBorderColor(entidad: 'Documentos' | 'Expediente' | 'Actuacion' | 'Interviniente' | 'Factura') {
    return { borderColor: this.borderColors[entidad] }
  }
}
</script>

<style lang="scss" scoped>
.dashboard-recent-activity-widget {
  @include flex($flex-direction: column, $justify-content: flex-start);
  width: 100%;
  padding: 20px;

  &.spinner-layer-container {
    height: 100%;
  }

  &.spinner-layer {
    justify-content: center;
  }

  section {
    width: 100%;
    max-width: 100%;
    height: 100%;
  }

  .widget-title {
    font-family: $corporate-font-black;
    font-size: 1vw;
    color: $corporate-color;
    text-transform: uppercase;
    padding-bottom: 2px;
  }

  .activity-list {
    @include list;
    @include scroll-styles;
    overflow-y: scroll;
    padding-right: 10px;

    .activity-item {
      @include border($direction: left, $width: 5px);
      height: 45px;
      font-family: $secondary-font;
      font-size: 11px;
      color: $corporate-color;
      margin-top: 15px;
      padding-left: 10px;

      .item-title {
        @include ellipsis;
        display: block;
      }

      .icon {
        font-size: 14px;
        margin-right: 4px;
      }

      .date-container {
        font-weight: bold;
        margin-top: 7px;

        .date,
        .hour {
          display: inline;
          margin-right: 10px;
        }
      }
    }
  }
}

@include syncfusionSmAndMdBreakpoint {
  .dashboard-recent-activity-widget {
    .widget-title {
      font-size: 24px;
    }
  }
}
</style>
