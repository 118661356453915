import { MutationTree } from 'vuex'
import { ExpedientsState } from '@/store/modules/expedients/expedientsTypes'

export const mutations: MutationTree<ExpedientsState> = {
  FETCH_EXPEDIENTS(state, expedients) {
    state.expedients = expedients
  },
  FETCH_TOTAL_NUMBER_OF_EXPEDIENTS(state, total) {
    state.total = total
  },
  FETCH_EXPEDIENT_STAGE_STATE(state, data) {
    state.stageStatus = data
  },
  FETCH_EXPEDIENT_STAGE_STATE_FORM(state, data) {
    state.stageStatusForm = data
  },
  FETCH_EXPEDIENT_SUMMARY(state, summary) {
    state.summary = summary
  },
  RESET_EXPEDIENT_SUMMARY(state) {
    state.summary = []
  },
  FETCH_EXPEDIENT_ECONOMIC_DATA(state, summary) {
    state.summary = summary
  },
  FETCH_EXPEDIENT_ACTIONS_SUMMARY(state, summary) {
    state.actionsSummary = summary
  },
  RESET_EXPEDIENT_ACTIONS_SUMMARY(state) {
    state.actionsSummary = []
  },
  LAST_EXPEDIENT_SAVED(state, data) {
    state.lastExpedientSaved = data
  },
  SET_TAXES_ITEMS(state, items) {
    state.taxesItems = items
  },
  RESET_TAXES_ITEMS(state) {
    state.taxesItems = []
  },
  SET_ECONOMIC_DATA_SIMPLE_GRID_ITEMS(state, items) {
    state.economicDataSimpleGridItems = items
  },
  SET_SELECTED_EXPEDIENT_INSURER(state, selectedInsurer) {
    state.selectedExpedientInsurer = selectedInsurer
  },
  RESET_SELECTED_EXPEDIENT_INSURER(state) {
    state.selectedExpedientInsurer = {}
  },
  SET_LAST_LISTEXPEDIENT_FILTER(state, item) {
    state.lastListExpedientFilter = item
  },
  FETCH_EXPEDIENT_SECTIONS_TO_DUPLICATE(state, sections) {
    state.sectionsToDuplicate = sections
  },
  SET_DUPLICATE_EXPEDIENT_ID(state, duplicateExpedientId) {
    state.duplicateExpedientId = duplicateExpedientId
  },
  SET_EXPEDIENT_PROFITABILITY_DETAIL(state, profitabilityDetail) {
    state.expedientProfitabilityDetail = profitabilityDetail
  },
  RESET_EXPEDIENT_PROFITABILITY_DETAIL(state) {
    state.expedientProfitabilityDetail = {}
  },
  SAVE_EXPEDIENT_CUSTOMER_FACT(state, customerFactInfo) {
    state.expedientCustomerFact = customerFactInfo
  },
  RESET_EXPEDIENT_CUSTOMER_FACT(state) {
    state.expedientCustomerFact = null
  }
}
