import { Module } from 'vuex'
import { getters } from '@/store/modules/tandemSearch/tandemSearchGetters'
import { actions } from '@/store/modules/tandemSearch/tandemSearchActions'
import { mutations } from '@/store/modules/tandemSearch/tandemSearchMutations'
import { TandemSearchState } from '@/store/modules/tandemSearch/tandemSearchTypes'
import { RootState } from '@/store/types/storeGlobalTypes'

export const state: TandemSearchState = {
  data: [],
  descriptors: [],
  filters: [],
  facets: [],
  paginationData: {
    recsPerPage: 10,
    numPage: 0
  },
  searchTerm: '',
  searchMetadata: [],
  totalOccurrences: 0
}

const namespaced: boolean = true

export const tandemSearchModule: Module<TandemSearchState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations
}
