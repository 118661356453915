<template lang="pug">
  ValidationProvider(
    ref="searchFieldValidator"
    :rules="validationRules"
    name="searchField"
    v-slot="{ errors }"
    tag="div"
    class="search-component-wrapper"
  )
    div(
      :class="[{ 'error-styles': applyErrorStyles },  'search-field-container']"
    )
      input(v-model="searchTerm" type="text" class="search-field" @keyup.enter="searchOrClearTerm")
      span(class="search-icon" :class="searchIconType" @click.stop="searchOrClearTerm")

    div(class="messages-container")
      span(v-if="showErrors" class="alert-message") {{ errors[0] }}

</template>

<script lang="ts">
import { Icons } from '@/icons/icons'
import { URLS } from '@/router/routes/urlRoutes'
import { Component, Watch, Vue, Prop } from 'vue-property-decorator'
import { ValidationProvider } from 'vee-validate'
import { decodeSearchTerm } from '@/helpers/codeTerm'

const limitOfMinCharacters: number = 3

@Component({
  components: {
    ValidationProvider
  }
})
export default class LexonSearchFieldComponent extends Vue {
  @Prop({
    type: String,
    default: `searchLimit|min:${limitOfMinCharacters}`
  })
  validationRules!: string

  searchTerm: string = ''

  showErrors: boolean = false

  searchIconType: string = Icons.SEARCH

  $refs!: {
    searchFieldValidator: InstanceType<typeof ValidationProvider>
  }

  @Watch('$route')
  clearSearchText() {
    if (!this.isSearchView) {
      this.searchTerm = ''
    } else {
      this.searchTerm = decodeSearchTerm(this.$route.params.searchTerm)
    }

    this.searchIconType = Icons.SEARCH
    if (this.searchTerm) {
      this.searchIconType = Icons.CLOSE_SEARCH
    }
  }

  @Watch('applyErrorStyles')
  showClearTexIcon(status: boolean) {
    if (status) {
      this.searchIconType = Icons.CLOSE_SEARCH
    } else {
      this.searchIconType = Icons.SEARCH
    }
  }

  @Watch('searchTerm')
  changeSearchIcon(value: string) {
    if (value && !value.length) {
      this.searchIconType = Icons.SEARCH
      this.showErrors = false
    }
  }

  get isSearchView(): boolean {
    return this.$route.name === URLS.SEARCH
  }

  get applyErrorStyles() {
    return this.showErrors && this.searchTerm.length < limitOfMinCharacters
  }

  get searchError() {
    return this.$refs.searchFieldValidator.$data.errors.length
  }

  mounted() {
    if (this.isSearchView) {
      this.searchTerm = decodeSearchTerm(this.$route.params.searchTerm)
      this.searchIconType = Icons.CLOSE_SEARCH
    }
  }

  async searchOrClearTerm(event: any) {
    const fieldValidation = await this.$refs.searchFieldValidator.validate()
    const fieldIsValid = fieldValidation.valid
    if (this.isSearchView) {
      if (this.searchIconType === Icons.CLOSE_SEARCH && event.key !== 'Enter') {
        this.searchTerm = ''
        this.searchIconType = Icons.SEARCH
        this.showErrors = false
      } else {
        if (fieldIsValid) {
          this.$emit('searchTerm', this.searchTerm)
          this.searchIconType = Icons.CLOSE_SEARCH
        } else {
          this.showErrors = true
        }
      }
    } else {
      if (fieldIsValid && this.searchTerm.length) {
        this.$emit('searchTerm', this.searchTerm)
        this.searchIconType = Icons.CLOSE_SEARCH
      } else {
        this.showErrors = true
        if (this.searchIconType === Icons.CLOSE_SEARCH) {
          this.searchTerm = ''
          this.showErrors = false
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.search-component-wrapper {
  @include textfield-messages;
  height: 56px;
  margin-top: 17px;
}

.search-field-container {
  @include flex;
  @include borders($color: $corporate-color);
  height: 35px;
  width: 100%;
  margin-right: 0;

  &.error-styles {
    border-color: $error-color;
    color: $error-color;
    background-color: $red-07;

    .search-icon {
      border-color: $error-color;
    }
  }

  .search-field {
    width: 100%;
    font-family: $secondary-font;
    font-weight: bold;
    font-size: 14px;
    padding-left: 10px;
  }

  .search-icon {
    @include flex;
    @include border($direction: left);
    font-size: $icon-size;
    padding: 0 8px;
    cursor: pointer;
  }
}

@include untilTablet {
  .search-field-container {
    margin-right: 5px;
  }
}

@include desktopLarge {
  .search-field-container {
    width: 360px;
  }
}
</style>
