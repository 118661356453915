export enum ProfitabilityFormFields {
  ADMINITRATIVE_STAFF = 'administrativeStaff',
  ADVERTISING = 'advertising',
  COMPUTER_EQUIPMENT = 'computerEquipment',
  DIRECT_COST = 'directCost',
  FINANCE_EXPENSES = 'financeExpenses',
  HOUR_COST = 'hourCost',
  INSURANCES = 'insurances',
  OFFICE_SUPPLIES = 'officeSupplies',
  OTHERS = 'others',
  RENTING = 'renting',
  SUPPLIES = 'supplies',
  TOTAL_INDIRECT_COSTS = 'totalIndirectCosts',
  WORK_HOURS = 'workHours'
}

export const indirectCostsFieldNamesForCalculation = [
  ProfitabilityFormFields.ADMINITRATIVE_STAFF,
  ProfitabilityFormFields.ADVERTISING,
  ProfitabilityFormFields.COMPUTER_EQUIPMENT,
  ProfitabilityFormFields.FINANCE_EXPENSES,
  ProfitabilityFormFields.INSURANCES,
  ProfitabilityFormFields.OFFICE_SUPPLIES,
  ProfitabilityFormFields.OTHERS,
  ProfitabilityFormFields.RENTING,
  ProfitabilityFormFields.SUPPLIES
]
