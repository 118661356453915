<template lang="pug">
  div(class="calendar-container")
    header(class="header")
      span  {{ $t('components.calendar.diary') }}
      span(
        class="header-icon"
        :class="icons.header"
      )
    div(class="month-year-container")
      span(:class="icons.left" class="icon" @click="changeMonth('prev')")
      span(class="month-year-title") {{ monthYear }}
      span(:class="icons.right" class="icon" @click="changeMonth('next')")

    FullCalendar(
      class="fullcalendar"
      ref="fullCalendar"
      v-bind="fullCalendarProps"
    )

</template>

<script lang="ts">
import { Vue, Component } from 'vue-property-decorator'
import { Icons } from '@/icons/icons'
import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import { calendarEventColors, CalendarEventColor } from '@/components/Calendar/types/CalendarComponentTypes'
import { Appointment } from '@/store/modules/appointments/appointmentsTypes'
import { Getter, Action } from 'vuex-class'
import { ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import { ActionTypes } from '@/store/modules/actions/actionsTypes'
import { joomlaURLS } from '@/router/routes/urlRoutes'

const namespace: string = ModuleNamespaces.APPOINTMENTS

@Component({
  components: {
    FullCalendar
  }
})
export default class CalendarComponent extends Vue {
  @Getter('getCalendarAppointmentsFormatted', { namespace }) appointments: Appointment[]

  @Action('fetchCalendarAppointments', { namespace }) fetchCalendarAppointments: ({}) => Promise<Appointment[]>

  calendarPlugins = [dayGridPlugin]

  icons: object = {
    header: Icons.CALENDAR,
    right: Icons.ANGLE_RIGHT,
    left: Icons.ANGLE_LEFT
  }

  monthYear: string = ''

  calendarFirstDay = 1

  eventTimeFormat = {
    hour: '2-digit',
    minute: '2-digit',
    meridiem: false
  }

  get appointmentsWithUrl() {
    const appointmentsWithUrl = this.appointments.map((appointment: Appointment) => {
      return {
        ...appointment,
        url: `${process.env.VUE_APP_URL}/${joomlaURLS.agenda}&id=${appointment.id}`
      }
    })
    return appointmentsWithUrl
  }

  get fullCalendarProps() {
    return {
      defaultView: 'dayGridMonth',
      plugins: this.calendarPlugins,
      events: this.appointmentsWithUrl,
      locale: 'es',
      firstDay: this.calendarFirstDay,
      header: 'false',
      eventTextColor: this.getEventColor('textColor'),
      eventBorderColor: this.getEventColor('borderColor'),
      eventBackgroundColor: this.getEventColor('backgroundColor'),
      eventTimeFormat: this.eventTimeFormat,
      eventRender: this.eventRender,
      datesRender: this.handleMonthChange
    }
  }

  getEventColor(property: string) {
    this.appointmentsWithUrl.forEach((appointment: any) => {
      this.getColor(property, appointment)
    })
  }

  async handleMonthChange(arg: any) {
    const formatDate = (date: Date) => {
      return (this as any).$moment(date).format('YYYY-MM-DD')
    }
    const calendar = arg.view
    const { activeEnd, activeStart } = calendar

    const dates = {
      end: formatDate(activeEnd),
      start: formatDate(activeStart)
    }

    await this.fetchCalendarAppointments(dates)
  }

  getColor(property: string, event: any) {
    switch (event.color) {
      case CalendarEventColor.GRAY:
        return (event[property] = (calendarEventColors as any).gray[property])
      case CalendarEventColor.YELLOW:
        return (event[property] = (calendarEventColors as any).yellow[property])
      case CalendarEventColor.RED:
        return (event[property] = (calendarEventColors as any).red[property])
      case CalendarEventColor.GREEN:
        return (event[property] = (calendarEventColors as any).green[property])
      case CalendarEventColor.ORANGE:
        return (event[property] = (calendarEventColors as any).orange[property])
      case CalendarEventColor.BLUE:
        return (event[property] = (calendarEventColors as any).blue[property])
      case CalendarEventColor.NONE:
        return (event[property] = (calendarEventColors as any).none[property])
      default:
        return (event[property] = (calendarEventColors as any).none[property])
    }
  }

  getEventIcon(eventType: string) {
    switch (eventType) {
      case ActionTypes.CALLS:
        return Icons.CALL
      case ActionTypes.MEETINGS:
        return Icons.MEETING
      case ActionTypes.PROCEDURES:
        return Icons.FORMALITY
      case ActionTypes.OTHERS:
        return Icons.OTHERS_F
      case ActionTypes.EMAILS:
        return Icons.EMAIL
    }
  }

  async getMonthYearTitle(): Promise<void> {
    await this.$nextTick()
    const calendarTitle = (this as any).$refs.fullCalendar.getApi().view.title
    const convertedTitleToArray = calendarTitle.split(' ')
    this.monthYear = `${convertedTitleToArray[0]} ${convertedTitleToArray[2]}`
  }

  changeMonth(selectedOption: string): void {
    this.getMonthYearTitle()
    const calendarApi = (this as any).$refs.fullCalendar.getApi()
    selectedOption === 'next' ? calendarApi.next() : calendarApi.prev()
  }

  eventRender(info: any): void {
    const { type, subject } = info.event.extendedProps
    const icon = this.getEventIcon(type)
    info.el.firstChild.innerHTML = `
    <div class="event-title-container">
      <span class="event-title">${info.el.firstChild.innerHTML}</span>
    <div>
    <div class="event-type-container">
      <span class="${icon} event-icon"></span>
      <span class="event-type">${subject}</span>
    </div>
    `
  }
  async mounted() {
    this.getMonthYearTitle()
  }
}
</script>

<style lang="scss" scoped>
@import '~@fullcalendar/core/main.css';
@import '~@fullcalendar/daygrid/main.css';

.calendar-container {
  @include borders($color: $blue-01);
  @include border($direction: left, $width: 4px, $color: $blue-03);
  height: 100%;
  font-family: $secondary-font;
  background-color: $white-01;
  padding-top: 12px;
  overflow: hidden;
}

.header {
  @include flex($justify-content: space-between);
  @include border($width: 3px);
  font-family: $corporate-font;
  font-size: 16px;
  color: $corporate-color;
  margin: 0 12px;
}

.month-year-container {
  @include flex($justify-content: space-between);
  color: $corporate-color;
  margin: 10px 0;
}

.month-year-title {
  text-transform: capitalize;
  margin: 0 18%;
}

.icon {
  display: inline-flex;
  font-size: 12px;
  cursor: pointer;
  width: 15%;

  &:nth-child(1) {
    justify-content: flex-end;
  }
}

.fullcalendar {
  max-height: 453px;
  overflow: hidden;
  z-index: 1;
}

::v-deep .fc {
  .fc-head-container {
    @include flex;
    font-family: $secondary-font;
    background-color: $corporate-color;
    height: 37px;

    .fc-widget-header {
      @include borders($color: $white-01);
      border-right: none;
      border-left: none;
      margin: 0 !important;
      padding-right: 7px;
    }

    .fc-day-header {
      color: $white-01;
      font-size: 12px;
      vertical-align: middle;
      text-transform: capitalize;
      height: 30px;
    }
  }

  .fc-toolbar.fc-header-toolbar {
    margin-bottom: 0;
  }

  .fc-day {
    @include borders($color: $blue-01);
  }

  .fc-time {
    font-weight: normal;
  }

  .fc-day-number {
    font-size: 11px;
    margin-right: 4px;
  }

  .fc-today {
    color: $white-01;
    background-color: $blue-05;
  }

  .fc-row.fc-week {
    min-height: 80px !important;
  }

  .fc-scroller.fc-day-grid-container {
    @include scroll-styles;
    overflow: hidden scroll !important;
    height: var(--fc-scroller-height, 400px) !important;
  }

  .fc-content {
    font-size: 9px;
    font-weight: bold;
    padding: 2px;
  }

  .fc-event:hover {
    color: $corporate-color;
  }

  .event-type-container {
    @include flex;
  }

  .event-type {
    margin-left: 2px;
  }

  .event-title-container,
  .event-type-container {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
</style>
