<template lang="pug">

  WidgetBaseComponent(
    class="last-actions-widget"
    :headerIcon="headerIcon"
    :loadedData="allDataLoaded"
  )

    //- HEADER
    template(v-slot:header)
      h2 {{ widgetData.title }}

    //- BODY
    template(v-slot:body)
      section(class="body-container element-with-scroll")

        table(v-if="widgetData.body.cells.length" class="last-actions-table")
          thead
            tr
              th(
                v-for="(header, headerIndex) in widgetData.body.headers"
                :key="headerIndex"
              ) {{ header }}

          tbody
            tr(
              v-if="widgetData.body.cells.length !== 0"
              v-for="(row, rowIndex) in widgetData.body.cells"
              :key="rowIndex"
              @click="openActionInNewWindow(row.idType, row.idAction)"
            )
              td(
                v-for="(cell, cellIndex) in widgetData.body.headers"
                :key="cellIndex"
              )
                span(
                  v-if="cellIndex === 0"
                  :class="[checkActionIconHelper(row['idType']), 'table-icon']")
                span {{ Object.values(row)[cellIndex + 2] }}

        //- Last actions table empty
        div(v-else class="empty-table") {{ emptyTableText }}

    //-FOOTER
    template(v-slot:footer)
      footer(:class="['footer', $vuetify.breakpoint.name]")
        section(class="bar-data-container")
          div(v-for="(item, index) in widgetData.footer.barData" class="data-container")
            span(class="data-circle") {{ item.data }}
            span(:class="[item.icon, 'data-icon']")

        div(class="total-container")
          span {{ renderTotalLabel  }}:
          span(class="total-number") {{ widgetData.footer.total.totalNumber }}

</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import WidgetBaseComponent from '@/components/widgets/WidgetBaseComponent.vue'
import { Icons } from '@/icons/icons'
import { Action, Getter } from 'vuex-class'
import { ContextName, ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import { TranslateResult } from 'vue-i18n'
import { ActionTypes, checkActionIcon } from '@/store/modules/actions/actionsTypes'
import { WidgetData } from '@/components/widgets/ExpedientWidgets/ExpedientLastActions/ExpedientLastActionsWidgetTypes'
import { URLS } from '@/router/routes/urlRoutes'

const expedientsModule = ModuleNamespaces.EXPEDIENTS
const selectedRegisterModule = ModuleNamespaces.SELECTED_REGISTER

@Component({
  components: {
    WidgetBaseComponent
  }
})
export default class ExpedientLastActionsWidget extends Vue {
  @Action('fetchExpedientLastActionsWidgetData', { namespace: expedientsModule })
  fetchExpedientLastActionsWidgetDataAction: ({}) => WidgetData

  @Getter('getSelectedRegisterId', { namespace: selectedRegisterModule })
  selectedRegisterIdGetter: (context: string) => number

  emptyTableText: TranslateResult = this.$t('components.widgets.expedient_last_actions.empty_table_text')

  headerIcon = Icons.CLOCK

  checkActionIconHelper = checkActionIcon

  widgetData: WidgetData = {
    title: '',
    body: {
      headers: [],
      cells: []
    },
    footer: {
      barData: [],
      total: {
        label: '',
        totalNumber: 0
      }
    }
  }

  contexts = {
    lastActions: ContextName.EXPEDIENT_LAST_ACTIONS_WIDGET,
    expedientContext: ContextName.EXPEDIENTS
  }

  get title() {
    return this.widgetData.title
  }

  get allDataLoaded() {
    return !!this.widgetData.title
  }

  get total() {
    const { label, totalNumber } = this.widgetData.footer.total
    return `${label}: ${totalNumber}`
  }

  get selectedExpedientId() {
    return this.selectedRegisterIdGetter(this.contexts.expedientContext)
  }

  get isBreakpointXs(): boolean {
    return this.$vuetify.breakpoint.xs
  }

  get renderTotalLabel(): string {
    const totalLabel = this.widgetData.footer.total.label
    return this.isBreakpointXs ? totalLabel.substring(0, 5) : totalLabel
  }

  created() {
    this.fetchLastActionsData()
  }

  async fetchLastActionsData() {
    this.widgetData = await this.fetchExpedientLastActionsWidgetDataAction({
      selectedExpedientId: this.selectedExpedientId,
      filter: {
        source: this.contexts.lastActions
      }
    })
  }

  async openActionInNewWindow(actionType: ActionTypes, selectedActionId: string) {
    let routeData: {} | null = null

    switch (actionType) {
      case ActionTypes.CALLS:
        routeData = this.$router.resolve({
          name: `${URLS.ACTIONS}-${URLS.CALLS}`,
          params: { selectedRegisterId: selectedActionId }
        })
        break
      case ActionTypes.MEETINGS:
        routeData = this.$router.resolve({
          name: `${URLS.ACTIONS}-${URLS.MEETINGS}`,
          params: { selectedRegisterId: selectedActionId }
        })
        break
      case ActionTypes.PROCEDURES:
        routeData = this.$router.resolve({
          name: `${URLS.ACTIONS}-${URLS.PROCEDURES}`,
          params: { selectedRegisterId: selectedActionId }
        })
        break
      case ActionTypes.OTHERS:
        routeData = this.$router.resolve({
          name: `${URLS.ACTIONS}-${URLS.ACTION_OTHERS}`,
          params: { selectedRegisterId: selectedActionId }
        })
        break
      case ActionTypes.LEXNET:
        routeData = this.$router.resolve({
          name: `${URLS.ACTIONS}-${URLS.ACTION_LEXNET}`,
          params: { selectedRegisterId: selectedActionId }
        })
        break
      case ActionTypes.EMAILS:
        routeData = this.$router.resolve({
          name: `${URLS.ACTIONS}-${URLS.ACTION_EMAILS}`,
          params: { selectedRegisterId: selectedActionId }
        })
        break
    }

    window.open((routeData as any).href, '_blank')
  }
}
</script>

<style lang="scss" scoped>
.last-actions-widget {
  .body-container {
    @include flex($flex-direction: column, $justify-content: flex-start);
    @include scroll-styles;
    width: 100%;
    height: 100%;
    margin: 0 1% 1% 1%;

    .last-actions-table {
      width: 100%;
      height: 100%;
      font-family: $secondary-font;
      border-collapse: collapse;

      td,
      th {
        padding: 3px;
        height: 30px;
        padding-left: 8px;
      }

      tr {
        cursor: pointer;

        &:hover {
          background-color: $gray-09;
        }
      }

      tr:nth-child(even) {
        background-color: $gray-04;

        &:hover {
          background-color: $gray-09;
        }
      }

      td:nth-child(2) {
        @include ellipsis;
        max-width: 300px;
        color: $corporate-color;
        font-weight: bold;
      }

      thead {
        @include border;
        text-align: left;
        color: $gray-01;

        tr:hover {
          background-color: transparent;
        }

        th {
          padding-bottom: 4px;
          font-size: 14px;
        }
      }

      .table-icon {
        color: $corporate-color;
        font-weight: bold;
        padding-right: 5px;
      }
    }

    .empty-table {
      @include flex;
      height: 150px;
      width: 100%;
      font-family: $secondary-font;
      color: $corporate-color;
      font-weight: bold;
    }
  }

  .footer {
    .bar-data-container {
      @include flex;

      .data-container {
        @include flex;
        color: $corporate-color;
        margin-right: 18px;

        .data-circle {
          @include flex;
          background-color: $corporate-color;
          width: 20px;
          height: 20px;
          font-family: $corporate-font-medium;
          color: $white-01;
          margin-right: 6px;
          border-radius: 50%;
        }

        .data-icon {
          font-size: 18px;
        }
      }
    }

    .total-container {
      .total-number {
        font-size: 18px;
        margin-left: 2px;
      }
    }

    &.xs {
      .data-container {
        margin-right: 10px;
      }
    }
  }
}
</style>
