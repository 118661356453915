export interface BooleanFilterItem {
  value: any
  booleanValue: boolean
  text: string
}

export interface BooleanFilter {
  [index: string]: BooleanFilterItem[]
}

export interface ListItemsState {
  listItems: any[]
}

export const listItemsMutationTypes = {
  SET_LIST_ITEMS: 'SET_LIST_ITEMS',
  REMOVE_LIST_ITEMS: 'REMOVE_LIST_ITEMS'
}
