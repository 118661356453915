import { GetterTree } from 'vuex'
import { RootState } from '@/store/types/storeGlobalTypes'
import { Entity, EntityState } from '@/store/modules/entities/entitiesTypes'

export const getters: GetterTree<EntityState, RootState> = {
  getEntity:
    (state) =>
    (context: string): Entity => {
      return state.entity[context]
    },
  getSelectedIdEntityType(state) {
    return state.idEntityType
  },
  getSelectedEntityName(state) {
    return state.selectedEntityName
  },
  getMenuParentCategoryName(state) {
    return state.menuParentCategoryName
  }
}
