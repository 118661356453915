import { ActionTree } from 'vuex'
import {
  actionsMutationTypes,
  ActionsState, AssociateEconomicDataToActionRequest,
  MoveExpedientActionRequest,
  RelatedActionResponse
} from '@/store/modules/actions/actionsTypes'
import { ModuleNamespaces, RootState } from '@/store/types/storeGlobalTypes'
import { ActionsService } from '@/services/ActionsService'
import { listItemsMutationTypes } from '@/store/modules/listItems/listItemsTypes'
import { MainService } from '@/services/MainService'
import { AlertsTypes, ComponentWhereIsRendered } from '../alerts/alertsTypes'
import { i18n } from '@/plugins/vue-i18n'

const alertsModule: string = ModuleNamespaces.ALERTS

export const actions: ActionTree<ActionsState, RootState> = {
  async fetchActions({ commit }, serverSideParams) {
    try {
      const url = 'actions/get'
      const { data } = await new ActionsService().postData(url, serverSideParams['json'])
      commit(actionsMutationTypes.FETCH_ACTIONS, data)
    } catch (error) {
      commit(actionsMutationTypes.RESET_ACTIONS)
    }
  },
  async fetchAction({ commit }, idAction) {
    try {
      const url = `actions/action/${idAction}/get`
      const { data } = await new ActionsService().getData(url)
      commit(actionsMutationTypes.FETCH_ACTION, data)
    } catch (error) {}
  },
  async fetchContactActions({ commit }, { listName, selectedRegisterId, contactType }) {
    try {
      const url = `actions/${listName}/${contactType}/${selectedRegisterId}/get`
      const { data } = await new ActionsService().getData(url)
      commit(`${ModuleNamespaces.LIST_ITEMS}/${listItemsMutationTypes.SET_LIST_ITEMS}`, data, { root: true })
    } catch (error) {}
  },
  async saveSelectedActionType({ commit }, actionType) {
    commit(actionsMutationTypes.SAVE_SELECTED_ACTION_TYPE, actionType)
  },
  async fetchActionRelations({ commit }, { selectedRegisterId }) {
    try {
      const url = `action/relations/${selectedRegisterId}/get`
      const { data } = await new ActionsService().getData(url)
      commit(`${ModuleNamespaces.LIST_ITEMS}/${listItemsMutationTypes.SET_LIST_ITEMS}`, data, { root: true })
    } catch (error) {}
  },
  async fetchPanelActions() {
    try {
      const url = '/actions/get'
      const jsonData = '{"source": "panel"}'
      const { data } = await new ActionsService().postData(url, jsonData)
      return data
    } catch (error) {}
  },
  async editPanelAction(_context, data) {
    try {
      const endpoint = 'actions/save'
      await new MainService().postData(endpoint, data)
    } catch (error) {}
  },
  async isUniqueCode(_context, params) {
    try {
      const endpoint = 'maintenance/manActionsCodificationsCheck/post'
      const { data } = await new MainService().postData(endpoint, params)
      return data
    } catch (error) {}
  },
  async getActionByCode(_context, params) {
    try {
      const endpoint = 'maintenance/manActionsCodifications/post'
      const { data } = await new MainService().postData(endpoint, params)
      return data
    } catch (error) {}
  },
  async associateEconomicDataToAction({ dispatch }, request: AssociateEconomicDataToActionRequest ) {
    try {
      const endpoint: string = `v2/economic-data/${request.economicDataId}/relationships/actions`
      const formData: FormData = new FormData()
      request.actionIdLot.forEach((actionId:number, index: number): void => {
        formData.append(`actionId[${index}]`, actionId.toString());
      })
      await new MainService().postFormData(endpoint, formData)
      dispatch(
        `${alertsModule}/showAlert`,
        {
          type: AlertsTypes.SUCCESS,
          message: i18n.t('components.alerts.associated_economic_data_success'),
          componentWhereIsRendered: ComponentWhereIsRendered.TABS_VIEW
        },
        { root: true }
      )
    } catch (error: any ) {
      const { data } = error.response
      const exceptionDescParse = JSON.parse(data.exceptionDesc)
      dispatch(
        `${alertsModule}/showAlert`,
        {
          type: AlertsTypes.ERROR,
          message: exceptionDescParse.exceptionDesc,
          componentWhereIsRendered: ComponentWhereIsRendered.TABS_VIEW
        },
        { root: true }
      )
    }
  },
  async disassociateEconomicDataToAction( {}, relationData ) {
    try {
      const { actionId, economicDataId } = relationData
      const endpoint = `v2/economic-data/${economicDataId}/relationships/action/${actionId}`
      await new MainService().deleteData(endpoint)
    } catch (error: any ) {
    }
  },
  async moveActionToAnotherExpedientAction({ dispatch }, request: MoveExpedientActionRequest ):Promise <void> {
    try {
      const endpoint:string = `v2/expedient/${request.toExpedientId}/relationships/expedient/${request.fromExpedientId}/action`
      const formData:FormData = new FormData()
      request.actionIdLot.forEach((actionId:number, index: number): void => {
        formData.append(`actionId[${index}]`, actionId.toString());
      })
      await new MainService().putFormData(endpoint, formData)
      dispatch(
        `${alertsModule}/showAlert`,
        {
          type: AlertsTypes.SUCCESS,
          message: i18n.t('components.alerts.move_action_to_another_expedient_success'),
          componentWhereIsRendered: ComponentWhereIsRendered.TABS_VIEW
        },
        { root: true }
      )
    } catch (error: any ) {
      const { data } = error.response
      dispatch(
        `${alertsModule}/showAlert`,
        {
          type: AlertsTypes.ERROR,
          message: data.exceptionDesc,
          componentWhereIsRendered: ComponentWhereIsRendered.TABS_VIEW
        },
        { root: true }
      )
    }
  },
  async viewActionsDetail(_context, economicDataId ) {
    try {
      const { data } = await new MainService()
        .getData(`/v2/economic-data/${ economicDataId }/relationships/actions`)
      return data as RelatedActionResponse
    } catch (error) {}
  },
  async viewInvoiceActionsDetailAction(_context, invoiceId ) {
    try {
      const { data } = await new MainService()
        .getData(`/v2/invoice/${ invoiceId }/relationships/actions`)
      return data as RelatedActionResponse
    } catch (error) {}
  }
}
