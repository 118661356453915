import { http } from '@/plugins/axios'

export class AppointmentsService {
  constructor() {}

  getData(url: string) {
    return http.get(url)
  }

  deleteData(url: string) {
    return http.delete(url)
  }
}
