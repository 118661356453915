<template lang="pug">

  div(class="card-footer")
    p(class="help-message") {{ $t('forms.login.need_help') }}
    div(class="contact-info")
      span(class="phone-numbers") 91 210 80 00 - 902 44 33 55 |
      a(class="email" href="mailto:clientes@lefebvre.es") clientes@lefebvre.es

</template>

<script lang="ts">
import { Vue, Component } from 'vue-property-decorator'

@Component
export default class CardFooterComponent extends Vue {}
</script>

<style lang="scss" scoped>
.card-footer {
  @include flex;
  flex-direction: column;
  height: 95px;
  width: 100%;
  font-size: 18px;
  background-color: $blue-07;
}

.help-message {
  font-size: 21px;
  color: $corporate-color;
  margin: 0;
}

.contact-info {
  margin-top: 5px;
  @include flex;
}

.phone-numbers {
  color: $blue-03;
}

.email {
  text-decoration: none;
}
</style>
