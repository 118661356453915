import { ActionTree } from 'vuex'
import { ErrorsState } from '@/store/modules/errors/errorsTypes'
import { RootState } from '@/store/types/storeGlobalTypes'
import { errorsMutationTypes } from '@/store/modules/errors/errorsTypes'

export const actions: ActionTree<ErrorsState, RootState> = {
  setError({ commit }) {
    try {
      commit(errorsMutationTypes.SET_ERROR)
    } catch (error) {}
  },
  clearError({ commit }) {
    try {
      commit(errorsMutationTypes.CLEAR_ERROR)
    } catch (error) {}
  },
  existsEndpointError({ commit }) {
    try {
      commit(errorsMutationTypes.EXISTS_ENDPOINT_ERROR)
    } catch (error) {}
  },
  clearEndpointError({ commit }) {
    try {
      commit(errorsMutationTypes.CLEAR_ENDPOINT_ERROR)
    } catch (error) {}
  }
}
