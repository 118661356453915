<template lang="pug">

  fieldset(class="fieldset-container")

    legend(class="fieldset-legend")
      span(class="fieldset-number") {{ stepNumber }}.
      span {{ legend }}
      LexonTooltipComponent(
        v-if="showInfoIcon"
        :icon="infoIcon"
        :message="tooltipText"
      )

    div(class="fieldset-content")
      slot

</template>

<script lang="ts">
import { Icons } from '@/icons/icons'
import { Component, Prop, Vue } from 'vue-property-decorator'
import LexonTooltipComponent from '@/components/forms/fields/LexonTooltip/LexonTooltipComponent.vue'

@Component({
  components: {
    LexonTooltipComponent
  }
})
export default class PrintOrSaveInvoicesFieldsetComponent extends Vue {
  @Prop({
    type: Number,
    required: true
  })
  stepNumber!: number

  @Prop({
    type: String,
    required: true
  })
  legend!: string

  @Prop({
    type: Boolean,
    default: false
  })
  showInfoIcon!: boolean

  infoIcon = Icons.INFO_FULL

  tooltipText = this.$t('components.tooltip.printInvoice')
}
</script>

<style lang="scss" scoped>
.fieldset {
  &-container {
    @include flex;
    @include borders($color: $blue-04);
    align-items: var(--invoices-fieldset-container-align-items, center);
    width: var(--invoices-fieldset-height, calc(50% - 3px));
    height: 400px;
    padding-right: var(--invoices-fieldset-padding-right, none);
  }

  &-legend {
    font-family: $corporate-font-medium;
    line-height: 16px;
    font-size: 12px;
    color: $blue-04;
    text-transform: uppercase;
    padding: 2.5px 4px 0;
    margin-left: 4px;

    ::v-deep .lf-icon-info-full.tooltip-icon {
      font-size: 16px;
      color: $corporate-color;
      margin-left: 8px;
      cursor: pointer;
    }
  }

  &-number {
    font-size: 18px;
    font-weight: bold;
    margin-right: 5px;
  }

  &-content {
    @include scroll-styles;
    width: 100%;
    height: calc(100% - 15px);
    padding: var(--invoices-fieldset-content-padding, 10px);
  }
}
</style>
