<template lang="pug">

  LexonSelectComponent(
    id="receiver"
    name="receiver"
    v-model="receiver"
    :label="receiverLabel"
    isMultiselect
    renderChips
    showAllChips
    keyName="name"
    keyValue="id"
    validationRules="required"
    serverPagination
    :tableHeaders="receiverTableHeaders"
    :endPointEntity="receiverEndPointEntity"
    returnObject
    validateOnLoad
    @input="emitReceiver"
  )

</template>

<script lang="ts">
import { Component, Emit, Mixins, Prop } from 'vue-property-decorator'
import FirmaDialogsMixin from '@/mixins/firma/FirmaDialogsMixin.vue'

export enum sendTypeSource {
  EMAIL = 'firma_email',
  SMS = 'firma_sms'
}

@Component({
  components: {
    LexonSelectComponent: () => import('@/components/forms/fields/LexonSelect/LexonSelectComponent.vue')
  }
})
export default class ReceiverFieldSelectComponent extends Mixins(FirmaDialogsMixin) {
  @Prop({
    type: String,
    default: sendTypeSource.EMAIL
  })
  source: string

  receiver = []

  receiverTableHeaders = ['name', 'email', 'mobilePhone']

  receiverEndpoint = 'contacts/all/get'

  get receiverEndPointEntity() {
    return {
      url: this.receiverEndpoint,
      method: 'POST',
      params: [
        {
          name: 'source',
          value: this.source
        },
        {
          name: 'order',
          value: 'name'
        },
        {
          name: 'typeOrder',
          value: 'asc'
        }
      ]
    }
  }

  receiverLabel = this.$t('components.firma_dialogs.receiver_label')

  @Emit()
  emitReceiver(receiver: any) {
    return receiver
  }
}
</script>
