<template lang="pug">
  div(class="file-manager-container")
    SpinnerLayerComponent(
        v-if="fileManagerSpinner"
        class="spinner-layer-file-manager-component"
        :loadingText="spinnerText.actual"
      )
    div(class="wrapper" v-if="!isOpeningFile")
      ejs-filemanager(
        ref="fileManager"
        id="file-manager"
        v-show="!showDocumentDetail && !showFolderDetail"
        :locale="locale"
        :view="view"
        :allowDragAndDrop="true"
        :ajaxSettings="ajaxSettings"
        :uploadSettings="uploadSettings"
        :detailsViewSettings="detailsViewSettings"
        :contextMenuSettings="contextMenuSettings"
        :toolbarSettings="toolbarSettings"
        :created="onFileManagerCreated"
        :beforeSend="onFileManagerBeforeSend"
        :beforeImageLoad="onFileManagerBeforeImageLoad"
        :beforePopupOpen="onFileManagerBeforeDialogOpen"
        :success="onFileManagerAfterSuccess"
        :failure="onFileManagerFailure"
        :fileDragStart="onFileManagerDragStart"
        :fileDragStop="onFileManagerDragStop"
        :fileDragging="onFileManagerDragging"
        :fileLoad="onFileLoad"
        :fileSelect="onFileSelect"
        :fileOpen="onFileOpen"
        :menuClick="onContextMenuClick"
        :menuOpen="onContextMenuOpen"
        :toolbarCreate="onToolbarCreate"
        :toolbarClick="onToolbarClick"
        :popupOpen="onPopupOpen",
        :popupClose="onPopUpClose"
        :height="height"
        :path="path"
        :selectedItems="selectedItems"
        :sortBy="sortBy"
        :sortOrder="sortOrder"
      )

      DocumentOnline

      DropFolderComponent(
        v-if="canDropFolder"
        :fileManagerInstance="fileManagerInstance"
        :maxFileUploadSize="maxFileUploadSize"
        :allowedExtensions="allowedExtensions"
        :basePath="currentBasePath"
        :idEntity="idEntity"
        :idEntityType="idEntityType"
        :idStage="idStage"
        :cwd="cwd || {}"
        @cantDropFolders="onCantDropFolders"
        @cantDropFiles="onCantDropFiles"
        @cantUploadsFilesHere="onCantUploadsFilesHere"
        @cantUploadsFoldersHere="onCantUploadsFoldersHere"
        @droppingFolders="droppingFolders=true"
        @droppingFiles="droppingFolders=false"
        @cancelUpload="onCancelUploadFolders"
        @uploadOk="onFoldersUploadOk"
        @dropToZipNotSupported="onDropToZipNotSupported"
      )

      TemplateSelectorComponent(
        v-if="!isTemplateMode && idEntityType"
        :showDialog="showTemplateDialog"
        :idEntityType="idEntityType"
        @closeTemplateDialog="closeTemplateDialog"
        @generateTemplateDialog="generateTemplateDialog"
      )

      FileManagerEditDocument(
        v-if="showDocumentDetail && !isTemplateMode"
        :idDocument="idDocument"
        :idEntity="idEntity"
        :idEntityType="idEntityType"
        :firmaStatus="firmaStatusDoc"
        :basePath="currentDocumentFolderPath"
        :editDocumentMode="editMode"
        :allDataLoaded="everythingLoaded"
        :permissionsEntity="permissionsEntity"
        @close="onCloseDocumentDetail"
        @on-save-document-ok="onSaveDocumentOk"
        @on-save-document-fail="onSaveDocumentFail"
        @on-save-intervener-ok="onSaveIntervenerOk"
        @on-save-v-fail="onSaveIntervenerFail"
        @on-remove-document="onDocumentRemoveAction"
        @showForm="onShowForm"
        @documentInitForm="onDocumentInitForm"
      )

      FileManagerEditFolder(
        v-if="showFolderDetail"
        :idDocument="idDocument"
        :idEntity="idEntity"
        :idEntityType="idEntityType"
        :basePath="currentDocumentFolderPath"
        :editDocumentMode="editMode"
        :allDataLoaded="everythingLoaded"
        :permissionsEntity="permissionsEntity"
        @close="onCloseDocumentDetail"
        @on-save-document-ok="onSaveDocumentOk"
        @on-save-document-fail="onSaveDocumentFail"
        @showForm="onShowForm"
      )

      FileManagerEditTemplate(
        v-if="showDocumentDetail && isTemplateMode"
        :idDocument="idDocument"
        :idEntity="idEntity"
        :idEntityType="idEntityType"
        :templateType="idEntityTypeAdditional"
        :basePath="currentBasePath"
        :id="templateId"
        :context="templateModeContext"
        @close="onCloseDocumentDetail"
        @on-save-document-ok="onSaveDocumentOk"
        @on-save-document-fail="onSaveDocumentFail"
        @on-save-intervener-ok="onSaveIntervenerOk"
        @on-save-v-fail="onSaveIntervenerFail"
        @on-remove-document="onDocumentRemoveAction"
        @showForm="onShowForm"
        @documentInitForm="onDocumentInitForm"
        )

      ejs-contextmenu(
        ref="documentContextMenu"
        id="action-add-document-context-menu"
        v-show="!showDocumentDetail"
        :items="actionAddContextMenuDocumentItems"
        :select="onActionAddClick"
        :beforeOpen="onBeforeOpenContextMenu"
      )

      ejs-contextmenu(
        ref="templateContextMenu"
        id="action-add-template-context-menu"
        v-show="!showDocumentDetail"
        :items="actionAddContextMenuTemplateItems"
        :select="onActionAddClick"
        :beforeOpen="onBeforeOpenContextMenu"
      )

      ejs-contextmenu(
        ref="msOfficeConfigigurationContextMenu"
        id="action-msoffice-configuration-context-menu"
        v-show="!showDocumentDetail"
        :items="msOfficeConfigurationContextMenuItems"
        :select="onOpenMSOfficeConfigClick"
        :beforeOpen="onBeforeOpenMSOfficeConfigContextMenu"
      )

      PromptDialogComponent(
        :title="saveAsZipText"
        :isOpened="openedSaveAsZipDialog"
        :formSchema="saveAsZipDialogFormSchema"
        :context="context"
        :formFieldValues="zipFormData"
        @execute-action="executeSaveAsZipDialogAction"
      )

      PreviewComponent(
        ref="preview"
        :documentPreview="{ id: 0, type: null }"
        :fileManagerInstance="fileManagerInstance"
      )

    div(v-else)
      div(class="file-manager-message") {{openingFileText}}
  </template>
  <script lang="ts">
  import Vue from 'vue'
  import { Component, Mixins, Prop, Watch } from 'vue-property-decorator'
  import { Action, Getter, Mutation } from 'vuex-class'
  import {
    DetailsView,
    FileManagerPlugin,
    NavigationPane,
    Toolbar,
    ContextMenuSettingsModel,
    ColumnModel,
    ToolbarCreateEventArgs,
    FileManagerComponent as FileManager
  } from '@syncfusion/ej2-vue-filemanager'
  import {
    DownloadZipResult,
    EditDocumentMode,
    File,
    fileManagerFields,
    fileManagerFolderType,
    fileManagerMode,
    fileManagerOpers,
    MaxFileUploadSize
  } from '@/store/modules/fileManager/fileManagerTypes'
  import store from '@/store/store'
  import { ContextName, ModuleNamespaces } from '@/store/types/storeGlobalTypes'
  import { ListNames } from '@/store/modules/configuration/configurationTypes'
  import DocumentOnline from '@/components/DocumentOnline/DocumentOnlineComponent.vue'
  import FileManagerEditDocument from '@/components/FileManager/FileManagerEditDocumentComponent.vue'
  import FileManagerEditFolder from '@/components/FileManager/FileManagerEditFolderComponent.vue'
  import FileManagerEditTemplate from '@/components/FileManager/FileManagerEditTemplateComponent.vue'
  import DropFolderComponent from '@/components/FileManager/DropFolderComponent.vue'
  import TemplateSelectorComponent from '@/components/template/TemplateSelectorComponent/TemplateSelectorComponent.vue'
  import { AlertsTypes, ComponentWhereIsRendered } from '@/store/modules/alerts/alertsTypes'
  import { documentOnlineActions } from '@/store/modules/documentOnline/documentOnlineTypes'
  import { URLS } from '@/router/routes/urlRoutes'
  import { authLexonPartnerId } from '@/store/modules/auth/authTypes'
  import PromptDialogComponent from '@/components/Dialog/PromptDialogComponent.vue'
  import { ActionName } from '@/components/ActionsBar/types/ActionBarComponentTypes'
  import { Icons } from '@/icons/icons'
  import { ContextMenuComponent, MenuItemModel } from '@syncfusion/ej2-vue-navigations'
  import { CustomDialogComponentName, CustomDialogData, DialogTypes } from '@/store/modules/dialog/dialogTypes'
  import { SyncfusionService } from '@/services/SyncfusionService'
  import { isSafari } from '@/helpers/navigator'
  import { createLink } from '@/helpers/blob'
  import { openLefebvrePdfViewer } from '@/helpers/pdfViewer'
  import { ITemplate } from '@/store/modules/template/templateTypes'
  import PreviewComponent from '@/components/FileManager/Preview/PreviewComponent.vue'
  import { DocumentPreview, DocumentPreviewType } from '@/components/FileManager/Preview/types/PreviewTypes'
  import {
    FirmaContextMenuId,
    OpenMSOffice,
    DetailsViewSettings,
    EditContextMenuId,
    SendContextMenuId,
    HeaderColumn
  } from '@/components/FileManager/types/FileManagerTypes'
  import { EmailProvider, emailEndpoints, emailProviderName } from '@/store/modules/lexbox/lexboxTypes'
  import { LexnetContextMenuId } from '@/components/Lexnet/types/LexnetTypes'
  import {
    allowedFirmaExtensions,
    FirmaRequestStatus,
    FirmaRequestTypes,
    FirmaSignInfo
  } from '@/store/modules/firma/firmaTypes'
  import { MainService } from '@/services/MainService'
  import LexnetMixin from '@/mixins/LexnetMixin.vue'
  import { RepositoryService } from '@/services/RepositoryService'
  import SpinnerLayerComponent from '@/components/Spinner/SpinnerLayerComponent.vue'
  import PermissionsMixin from '@/mixins/PermissionsMixin.vue'
  import { TranslateResult } from 'vue-i18n'
  import OpenMSOfficeFiles from '@/mixins/OpenMSOfficeFilesMixin.vue'
  import { entityToHtml } from '@/helpers/html'
  import { securityHeaders } from '@/plugins/axios/http'
import { TrackerEvents, trackEvent } from '@/plugins/tracker'

  const authModule = ModuleNamespaces.AUTH
  const configurationModule = ModuleNamespaces.CONFIGURATION
  const dialogModule: string = ModuleNamespaces.DIALOG
  const documentOnlineModule = ModuleNamespaces.DOCUMENT_ONLINE
  const entitiesModule: string = ModuleNamespaces.ENTITIES
  const expedientsModule: string = ModuleNamespaces.EXPEDIENTS
  const fileManagerModule = ModuleNamespaces.FILE_MANAGER
  const firmaModule: string = ModuleNamespaces.FIRMA
  const formsModule: string = ModuleNamespaces.FORMS
  const lexboxModule: string = ModuleNamespaces.LEXBOX
  const selectedRegisterModule = ModuleNamespaces.SELECTED_REGISTER
  const spinnerModule: string = ModuleNamespaces.SPINNER
  const templateModule: string = ModuleNamespaces.TEMPLATE

  Vue.use(FileManagerPlugin)

  @Component({
    components: {
      DocumentOnline,
      DropFolderComponent,
      FileManagerEditDocument,
      FileManagerEditFolder,
      FileManagerEditTemplate,
      PreviewComponent,
      PromptDialogComponent,
      SpinnerLayerComponent,
      TemplateSelectorComponent
    },
    provide: {
      filemanager: [DetailsView, NavigationPane, Toolbar]
    }
  })
  export default class FileManagerComponent extends Mixins(LexnetMixin, PermissionsMixin, OpenMSOfficeFiles) {
    @Prop({
      type: String
    })
    basePath!: string

    @Prop({
      type: String,
      default: ''
    })
    initialBasePath: string

    @Prop({
      type: String,
      default: ''
    })
    initialPath: string

    @Prop({
      type: String
    })
    mainFolderDescription!: string

    @Prop({
      type: Number
    })
    idMainEntity!: number

    @Prop({
      type: Number
    })
    idMainEntityType!: number

    @Prop({
      type: String,
      default: ComponentWhereIsRendered.TABS_VIEW
    })
    alertWhereIsReview!: string

    @Prop({
      type: String,
      default: '650'
    })
    height: string

    @Prop({
      type: Number,
      default: 0
    })
    idEntityTypeBase: number

    @Prop({
      type: String,
      required: true
    })
    context!: string

    @Prop({
      type: Boolean,
      required: true
    })
    canDropFilesOnMainFolder: boolean

    @Prop({
      type: Boolean,
      default: true
    })
    canCreateFoldersOnMainFolder: boolean

    @Prop({
      type: Boolean,
      default: true
    })
    canDropFolder: boolean

    @Prop({
      type: Number,
      default: fileManagerMode.DOCUMENT
    })
    mode: number

    @Prop({
      type: Number,
      default: 14
    })
    permissionsEntity: number

    @Getter('getLastRefreshTokenTime', { namespace: authModule }) lastRefreshTokenTime: number
    @Getter('getAuthToken', { namespace: authModule }) authToken: string
    @Getter('getCompanyTemplate', { namespace: authModule }) hasCompanyTemplate: boolean
    @Getter('getFile', { namespace: fileManagerModule }) blobFile: Blob
    @Getter('getFileToken', { namespace: documentOnlineModule }) fileToken: string
    @Getter('getRequestToken', { namespace: documentOnlineModule }) requestToken: string
    @Getter('getRequestTokenTtl', { namespace: documentOnlineModule }) requestTokenTtl: string
    @Getter('getFileAction', { namespace: documentOnlineModule }) fileAction: string
    @Getter('getMaxFileUploadSize', { namespace: fileManagerModule }) maxFileUploadSize: MaxFileUploadSize
    @Getter('getAllowedExtensions', { namespace: fileManagerModule }) allowedExtensions: string
    @Getter('getDocument', { namespace: fileManagerModule }) document: any
    @Getter('getExpedientStageState', { namespace: expedientsModule }) expedientStageState: []
    @Getter('getCurrentListConfiguration', { namespace: configurationModule }) listConfiguration: object
    @Getter('getEmailProvider', { namespace: lexboxModule }) emailProvider: EmailProvider
    @Getter('getTemplateId', { namespace: templateModule })
    selectedTemplateId: number
    @Getter('getZipResult', { namespace: fileManagerModule }) zipResult: DownloadZipResult
    @Getter('getTemplateTree', { namespace: templateModule })
    tree: ITemplate[]
    @Getter('getIsPortalUser', { namespace: authModule })
    isPortalUser: boolean
    @Getter('getIsLexonDmsUser', { namespace: authModule })
    isDMSUser: boolean
    @Getter('userHasFirma', { namespace: authModule })
    userHasFirma: boolean
    @Getter('refreshFileManagerCounter', { namespace: fileManagerModule })
    refreshFileManagerCounterGetter: number

    @Mutation('SAVE_ACTION', { namespace: documentOnlineModule }) setDocumentOnlineAction: (action: string) => void
    @Mutation('FETCH_SELECTED_REGISTER_DATA', { namespace: selectedRegisterModule })
    saveSelectedRegisterData: ({}) => void
    @Mutation('SET_LOADING_FORM_DATA', { namespace: formsModule })
    setLoadingFormData: (loading: boolean) => void
    @Mutation('SAVE_DOCUMENT', { namespace: fileManagerModule })
    saveDocument: (document: any) => void
    @Mutation('SHOW_HIDE_TEMPLATE_GENERATOR', { namespace: templateModule })
    showHideTemplateGenerator: (show: boolean) => void
    @Mutation('SAVE_TEMPLATE_ID', { namespace: templateModule })
    saveTemplateId: (id: number) => void
    @Mutation('SAVE_CURRENT_PATH', { namespace: fileManagerModule })
    saveCurrentBasePath: (basePath: string) => void
    @Mutation('SAVE_PATH', { namespace: fileManagerModule })
    saveCurrentPath: (path: string) => void
    @Mutation('SAVE_FILE_MANAGER_ITEM', { namespace: fileManagerModule })
    saveFileManagerItem: (fileManagerItem: any) => void
    @Mutation('RESET_PARENT_REGISTER_ID', { namespace: selectedRegisterModule })
    resetParentRegisterId: () => void
    @Mutation('SET_SIGN_INFO', { namespace: firmaModule })
    setSignInfo: (info: FirmaSignInfo) => void

    @Action('downloadFile', { namespace: fileManagerModule }) downloadFile: (id: string) => Blob
    @Action('insertDocument', { namespace: fileManagerModule }) insertDocument: ({}) => void
    @Action('downloadZipResult', { namespace: fileManagerModule }) downloadZipResult: ({}) => void
    @Action('downloadAsZip', { namespace: fileManagerModule }) downloadAsZip: (zipTmpName: string) => void
    @Action('openFile', { namespace: fileManagerModule }) openFile: (id: string) => Blob
    @Action('fetchRequestToken', { namespace: documentOnlineModule }) fetchRequestToken: (params: any) => string
    @Action('fetchAppType', { namespace: documentOnlineModule }) fetchAppType: (params: any) => string
    @Action('saveFileUrl', { namespace: documentOnlineModule }) openOnlineEditor: (fileUrl: string) => void
    @Action('saveFileName', { namespace: documentOnlineModule }) saveFileName: (fileName: string) => void
    @Action('saveFileVersion', { namespace: documentOnlineModule }) saveFileVersion: (fileVersion: string) => void
    @Action('saveFileAction', { namespace: documentOnlineModule }) saveFileAction: (fileAction: string) => void
    @Action('saveFileSize', { namespace: documentOnlineModule }) saveFileSize: (fileSize: number) => void
    @Action('fetchFileToken', { namespace: documentOnlineModule }) fetchFileToken: (idFile: string) => void
    @Action('fetchMaxFileUploadSize', { namespace: fileManagerModule }) fetchMaxFileUploadSize: () => void
    @Action('fetchAllowedExtensions', { namespace: fileManagerModule }) fetchAllowedExtensions: (mode: number) => void
    @Action('fetchDocument', { namespace: fileManagerModule }) fetchDocument: (idDocument: string) => void
    @Action('fetchFolder', { namespace: fileManagerModule }) fetchFolder: (idFolder: number) => void
    @Action('saveSelectedRegisterId', { namespace: selectedRegisterModule }) saveSelectedRegisterId: ({}) => void
    @Action('generateTemplate', { namespace: templateModule }) generateTemplate: ({}) => void
    @Action('fetchCurrentListConfiguration', { namespace: configurationModule })
    fetchCurrentListConfiguration: (alias: string) => {}
    @Action('saveUserCustomConfigurationList', { namespace: configurationModule }) saveListConfiguration: ({}) => void
    @Action('getRootFolder', { namespace: fileManagerModule }) getRootFolder: (basePath: string) => File | null
    @Action('showDialog', { namespace: dialogModule }) showDialog: ({}) => {}
    @Action('saveSelectedIdEntityType', { namespace: entitiesModule })
    saveSelectedIdEntityType: (idEntityType: number) => void
    @Action('saveParentdRegisterId', { namespace: selectedRegisterModule }) saveParentRegisterId: (id: number) => void
    @Action('showCustomDialog', { namespace: dialogModule }) showCustomDialogAction: ({}: CustomDialogData) => {}
    @Action('fetchSignaturitCredits', { namespace: firmaModule }) checkFirmaCredits: (idDocument: number) => number
    @Action('hideSpinnerLayer', { namespace: spinnerModule }) hideSpinnerLayerAction: () => void
    @Action('fetchEmailProvider', { namespace: lexboxModule }) fetchEmailProvider: () => void

    urlBase = process.env.VUE_APP_API_URL
    fileManagerSpinner = false
    spinnerText = {
      actual: this.$t('components.spinner.updating_data'),
      receiving: this.$t('components.spinner.receiving_data'),
      sending: this.$t('components.spinner.sending_data'),
      updating: this.$t('components.spinner.updating_data')
    }
    fileManagerInstance: any = null
    files: File[] = []
    cwd: File | null = null
    showDocumentDetail = false
    showFolderDetail = false
    documentBasePath = ''
    currentBasePath: string = this.basePath
    currentPath = ''
    idDocument = 0
    editMode: EditDocumentMode = EditDocumentMode.uploadEdit

    locale = 'es'
    view = 'Details'

    $refs!: {
      fileManager: InstanceType<typeof FileManager>
      documentContextMenu: InstanceType<typeof ContextMenuComponent>
      templateContextMenu: InstanceType<typeof ContextMenuComponent>
      preview: InstanceType<typeof PreviewComponent>
    }

    refreshTokenInterval = 0

    isRefreshingAccessToken = false

    droppingFolders = false

    selectedItems: string[] = []
    filenameSelected: string | null = null

    editContextMenu: any = []

    ajaxSettings = {
      url: `${this.urlBase}repository/folders/get`,
      getImageUrl: `${this.urlBase}repository/file/%1/get`,
      uploadUrl: `${this.urlBase}repository/file/put`,
      downloadUrl: `${this.urlBase}repository/file/get`
    }

    detailsViewSettingsDocument: DetailsViewSettings | null = null

    detailsViewSettingsAll: DetailsViewSettings | null = null

    contextMenuSettingsSingle = {
      /* TODO: Eliminar todo lo relativo a "custom_separator" (buscar referencias en todo el archivo)
        cuando se actualice la versiín de SYNCFUSION. Anteriormente se utilizaba un separador normal "|" */
      file: [
        'Open',
        'Cut',
        'Copy',
        'Paste',
        'Rename',
        'custom_separator',
        'Edit',
        'custom_separator',
        'Send',
        'custom_separator',
        'Lexnet',
        'custom_separator',
        'Firma',
        'custom_separator',
        'Download',
        'Download_As_Pdf',
        '|',
        'Delete',
        '|',
        'Details'
      ],
      folder: [
        'Open',
        'custom_separator',
        'Permissions',
        'custom_separator',
        'Cut',
        'Copy',
        'Paste',
        'Rename',
        'Edit',
        'custom_separator',
        'Send',
        'custom_separator',
        'Download_Zip',
        'custom_separator',
        'Delete'
      ],
      layout: [
        'Paste',
        'Edit_Layout',
        '|',
        'NewFolder',
        '|',
        'new_word',
        'new_excel',
        'new_ppoint',
        '|',
        'upload_document'
      ],
      visible: true
    }

    contextMenuSettingsMultiple = {
      file: [
        'Cut',
        'Copy ',
        'Edit_Multiple',
        'custom_separator',
        'Send',
        'custom_separator',
        'Download_Zip',
        '|',
        'Delete'
      ],
      folder: [
        'Cut',
        'Copy ',
        'Edit_Multiple',
        'custom_separator',
        'Send',
        'custom_separator',
        'Download_Zip',
        '|',
        'Delete'
      ],
      layout: [
        'Paste',
        'Edit_Layout',
        '|',
        'NewFolder',
        '|',
        'new_word',
        'new_excel',
        'new_ppoint',
        '|',
        'upload_document'
      ],
      visible: true
    }

    contextMenuSettingsData: ContextMenuSettingsModel = this.contextMenuSettingsSingle

    actionAddContextMenuTemplateItems = [
      {
        text: this.$t('components.file_manager.actions.new_folder'),
        iconCss: Icons.FOLDER
      },
      {
        separator: true
      },
      {
        text: this.$t('components.file_manager.actions.new_template'),
        iconCss: Icons.DOCUMENTATION
      }
    ]

    msOfficeConfigurationContextMenuItems = [
      {
        text: this.$t('components.file_manager.actions.config.local'),
        iconCss: Icons.CHECK
      },
      {
        separator: true
      },
      {
        text: this.$t('components.file_manager.actions.config.wopi'),
        iconCss: Icons.CHECK
      }
    ]

    flagActionInProgress = false

    everythingLoaded = false

    lastFileManagerOper = ''

    idEntity: number = this.idMainEntity ? this.idMainEntity : 0
    idEntityType: number = this.idMainEntityType ? this.idMainEntityType : 0
    idEntityTypeAdditional = 0

    foundFolder: any

    minimSearchCharacters = 3

    isOpeningFile = false
    openingFileText: string = this.$t('views.repository.openingFile').toString()

    initializedBasePath = false

    templateModeContext: string = ContextName.TEMPLATE
    templateDocumentId = 0

    openedSaveAsZipDialog = false
    saveAsZipText = this.$t('components.file_manager.dialogs.save_as_zip_title')
    zipFormData = {}
    zipFolderSelected: any = []

    saveAsZipDialogFormSchema = [
      {
        id: 'name',
        name: 'name',
        type: 'text',
        label: 'Nombre',
        fieldType: 'LexonTextFieldComponent',
        validationRules: 'required|max:255',
        xs: 12,
        sm: 12,
        md: 12,
        lg: 12,
        xl: 12,
        'offset-xs': 0,
        'offset-sm': 0,
        'offset-md': 0,
        'offset-lg': 0,
        'offset-xl': 0
      }
    ]

    showTemplateDialog = false

    folderType: number = fileManagerFolderType.DOCUMENT

    fileManagerPath = '/'

    signPending: string[] = []
    inSignaturit: string[] = []
    firmaStatusDoc: number | null = null

    openMSOfficeConfigMode: OpenMSOffice = OpenMSOffice.WOPI

    sortBy = 'name'
    sortOrder = 'Ascending'
    updatedHeaderColumns: HeaderColumn[] = []

    get path() {
      return this.fileManagerPath
    }
    set path(value: string) {
      this.fileManagerPath = value
    }

    get toolbarSettings() {
      const { canSave } = this.checkEntityPermissionsGetter(this.permissionsEntity)

      if (canSave) {
        return {
          items: ['Selection', 'Details', 'Preview', 'View', 'Refresh', 'Actions', 'Open', 'Configuration'],
          visible: true
        }
      }
      return {
        items: ['Selection', 'Details', 'Preview', 'View', 'Refresh', 'Open', 'Configuration'],
        visible: true
      }
    }

    get actionAddContextMenuDocumentItems() {
      return this.hasCompanyTemplate
        ? [
            {
              text: this.$t('components.file_manager.actions.new_folder'),
              iconCss: Icons.FOLDER
            },
            {
              separator: true
            },
            {
              text: this.$t('components.file_manager.actions.new_word_document'),
              iconCss: Icons.WORD
            },
            {
              text: this.$t('components.file_manager.actions.new_excel_document'),
              iconCss: Icons.EXCEL
            },
            {
              text: this.$t('components.file_manager.actions.new_ppoint_document'),
              iconCss: Icons.PPT
            },
            {
              text: this.$t('components.file_manager.actions.from_template_document'),
              iconCss: 'lf-icon-document'
            },
            {
              separator: true
            },
            {
              text: this.$t('components.file_manager.actions.upload_lexon_document'),
              iconCss: Icons.UPLOAD
            }
          ]
        : [
            {
              text: this.$t('components.file_manager.actions.new_folder'),
              iconCss: Icons.FOLDER
            },
            {
              separator: true
            },
            {
              text: this.$t('components.file_manager.actions.new_word_document'),
              iconCss: Icons.WORD
            },
            {
              text: this.$t('components.file_manager.actions.new_excel_document'),
              iconCss: Icons.EXCEL
            },
            {
              text: this.$t('components.file_manager.actions.new_ppoint_document'),
              iconCss: Icons.PPT
            },
            {
              separator: true
            },
            {
              text: this.$t('components.file_manager.actions.upload_lexon_document'),
              iconCss: Icons.UPLOAD
            }
          ]
    }

    get listName(): string {
      if (this.mode === fileManagerMode.DOCUMENT) {
        if (this.folderType === fileManagerFolderType.DOCUMENT) {
          return ListNames.FILE_MANAGER
        } else {
          return ListNames.FILE_MANAGER_ALL
        }
      } else if (this.mode === fileManagerMode.TEMPLATE) {
        return ListNames.FILE_MANAGER_TEMPLATE
      } else {
        return ''
      }
    }

    get detailsViewSettings() {
      return this.folderType === fileManagerFolderType.DOCUMENT
        ? this.detailsViewSettingsDocument
        : this.detailsViewSettingsAll
    }

    get templateId() {
      return this.templateDocumentId
    }

    set templateId(id: number) {
      this.templateDocumentId = id
    }

    get isDocumentMode() {
      return fileManagerMode.DOCUMENT === this.mode
    }

    get isTemplateMode() {
      return fileManagerMode.TEMPLATE === this.mode
    }

    get uploadSettings() {
      return {
        autoUpload: true,
        minFileSize: 0,
        maxFileSize: this.maxFileUploadSize ? this.maxFileUploadSize.size : 0,
        allowedExtensions: this.allowedExtensions,
        autoClose: false
      }
    }

    get contextMenuSettings(): ContextMenuSettingsModel {
      if (this.hasCompanyTemplate) {
        this.contextMenuSettingsData.layout = this.isTemplateMode
          ? ['Paste', 'Edit_Layout', '|', 'NewFolder', '|', 'new_template']
          : [
              'Paste',
              'Edit_Layout',
              '|',
              'NewFolder',
              '|',
              'new_word',
              'new_excel',
              'new_ppoint',
              'from_template',
              '|',
              'upload_document'
            ]
      } else {
        this.contextMenuSettingsData.layout = [
          'Paste',
          'Edit_Layout',
          '|',
          'NewFolder',
          '|',
          'new_word',
          'new_excel',
          'new_ppoint',
          '|',
          'upload_document'
        ]
      }
      return this.contextMenuSettingsData
    }

    set contextMenuSettings(value: ContextMenuSettingsModel) {
      this.contextMenuSettingsData = value
    }

    get contextMenu(): ContextMenuComponent | null {
      switch (this.mode) {
        case fileManagerMode.DOCUMENT:
          return this.$refs.documentContextMenu as ContextMenuComponent
        case fileManagerMode.TEMPLATE:
          return this.$refs.templateContextMenu as ContextMenuComponent
      }
      return null
    }

    get checkIfTablet() {
      return /Android|webOS|iPhone|Mac|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
    }

    get openMSOfficeMode() {
      const config = JSON.parse((this as any).listConfiguration['Config'])
      if (config.openMSOffice && config.openMSOffice.length) {
        return config.openMSOffice
      }
      return OpenMSOffice.WOPI
    }

    @Watch('maxFileUploadSize')
    setMaxFileUploadSize(maxFileUploadSize: MaxFileUploadSize) {
      this.fileManagerInstance.uploadObj.maxFileSize = maxFileUploadSize.size
      this.fileManagerInstance.uploadObj.l10n.currentLocale.invalidMaxFileSize = this.$t(
        'components.file_manager.advises.max_file_upload_size',
        { size: maxFileUploadSize.sizeFormatted }
      )
    }

    @Watch('allowedExtensions')
    setFileAllowedExtensions(allowedExtensions: string) {
      this.fileManagerInstance.uploadObj.allowedExtensions = allowedExtensions
    }

    @Watch('expedientStageState')
    changeStage() {
      this.toggleStageField()
      if (this.fileManagerInstance && !this.showDocumentDetail) {
        this.refresh()
      }
    }

    @Watch('currentPath')
    changePath(path: string) {
      const file: File | undefined = this.files.find((file: File) => path === `${file.filterPath}${file.name}/`)
      if (file !== undefined) {
        this.idEntity = Number(file.idEntity)
        this.idEntityType = Number(file.idEntityType)
      } else {
        if (this.idMainEntity) {
          this.idEntity = this.idMainEntity
          this.idEntityType = this.idMainEntityType
        } else {
          this.idEntity = this.cwd!.idEntity
          this.idEntityType = this.cwd!.idEntityType
        }
      }
    }

    @Watch('refreshFileManagerCounterGetter')
    refreshFileManager() {
      this.refresh()
    }

    get currentDocumentFolderPath() {
      return this.documentBasePath
    }

    get idStage(): number | null {
      if ([ContextName.EXPEDIENTS.toString(), ContextName.EXPEDIENT_ACTIONS.toString()].indexOf(this.context) >= 0) {
        const stage: any = this.expedientStageState
        if (stage.stageId) {
          return Number(stage.stageId)
        }
      }
      return null
    }

    get expedientHasStagesActive(): boolean {
      const stage: any = this.expedientStageState
      return stage.activeStages === 1
    }

    isNeedRefreshToken(dateTime: number): boolean {
      const HOUR = 1000 * 55 * 60
      const anHourAgo = Date.now() - HOUR
      return dateTime < anHourAgo
    }

    async onFileManagerBeforeSend(args: any) {
      if (args.ajaxSettings) {
        if (fileManagerOpers.DETAILS === args.action) {
          args.cancel = true
          return
        }

        if (this.authToken) {
          args.ajaxSettings.beforeSend = (arg: any) => {
            arg.httpRequest.setRequestHeader('Authorization', `Bearer ${this.authToken}`)
            arg.httpRequest.setRequestHeader('accept', 'application/json')
            arg.httpRequest.setRequestHeader(authLexonPartnerId, process.env.VUE_APP_LEXON_PARTNER_ID)
            Object.entries(securityHeaders).map(([key, value]) => arg.httpRequest.setRequestHeader(key, value))
          }
        }

        if (this.isNeedRefreshToken(this.lastRefreshTokenTime)) {
          store.dispatch(`${ModuleNamespaces.AUTH}/refreshToken`)
        }

        const data = JSON.parse(args.ajaxSettings.data)

        if (fileManagerOpers.SEARCH === args.action) {
          if (data.searchString.length < this.minimSearchCharacters + 2) {
            args.cancel = true
            return
          }
        }

        if (fileManagerOpers.UPLOAD !== args.action && (!data.data || null === data.data[0])) {
          args.cancel = true
          return
        }

        if (fileManagerOpers.READ === args.action || fileManagerOpers.CREATE === args.action) {
          args.ajaxSettings.url = `${this.urlBase}repository/folders/get`

          if (this.fileManagerInstance) {
            this.folderType = fileManagerFolderType.DOCUMENT
            if (!this.detailsViewSettingsDocument) {
              await this.fetchFileManagerGridConfig()
            }
            this.fileManagerInstance.detailsViewSettings = this.detailsViewSettings
          }

          data.isRootFolder = false

          if (data.path === '/') {
            data.basePath = this.basePath
            data.isRootFolder = true
            data.rootBasePath = this.basePath
            data.data = []
            data.idEntity = this.idMainEntity
            data.idEntityType = this.idMainEntityType
            data.idEntityTypeAdditional = this.idEntityTypeAdditional
            data.checkCreateFolder = this.fileManagerInstance === null
          } else if (data.data && !data.data.basePath && data.data.length && null !== data.data[0]) {
            if ('' !== data.data[0].filterPath) {
              const file = this.files.find((x) => x.uid === data.data[0].uid && data.data[0].isFile)
              if (file) {
                data.data[0].basePath = file.basePath
              }
            }
          }

          if (
            fileManagerOpers.READ === args.action &&
            data &&
            data.data &&
            data.data[0] &&
            ('-999' === data.data[0].idFolder || data.data[0].basePath.includes('/ver todos:'))
          ) {
            if (this.fileManagerInstance) {
              this.folderType = fileManagerFolderType.VIEW_ALL
              if (!this.detailsViewSettingsAll) {
                setTimeout(() => {
                  this.fetchFileManagerGridConfig()
                })
              }
              this.fileManagerInstance.detailsViewSettings = this.detailsViewSettings
            }
          }
        }

        if (fileManagerOpers.SEARCH === this.lastFileManagerOper) {
          data.foundFolder = this.foundFolder
        }

        if (fileManagerOpers.UPLOAD === args.action) {
          const subData: File | null = this.cwd

          if (this.droppingFolders) {
            this.fileManagerInstance.uploadObj.removing()
            args.cancel = true
            return
          }

          const found = this.files.find((x) => x.basePath === subData!.basePath && x.name === subData!.name)
          if (found !== undefined && !found.isDroppable) {
            if ('' !== subData!.filterPath || ('' === subData!.filterPath && !this.canDropFilesOnMainFolder)) {
              args.cancel = true
              this.$emit('cantUploadsFilesHere')
              return
            }
          }

          if (data[0].path === '/') {
            data.push({ basePath: subData!.basePath })
            data.push({ idEntity: this.idMainEntity ? this.idMainEntity : this.cwd!.idEntity })
            data.push({ idEntityType: this.idMainEntityType ? this.idMainEntityType : this.cwd!.idEntityType })
            data.push({ idEntityTypeAdditional: this.idEntityTypeAdditional })
          } else {
            if (found) {
              data.push({ basePath: found.basePath })
              data.push({ idEntity: found.idEntity })
              data.push({ idEntityType: found.idEntityType })
              data.push({ idEntityTypeAdditional: found.idEntityTypeAdditional })
            }
          }
          data.push({ idStage: this.idStage })
        }

        if (fileManagerOpers.DELETE === args.action) {
          args.ajaxSettings.url = `${this.urlBase}repository/file/delete`
        }

        if (fileManagerOpers.CREATE === args.action && !this.flagActionInProgress) {
          if (data.path && !data.path.toString().includes(':')) {
            if (data.data && data.data.length) {
              data.basePath = data.data[0].basePath
            }
            data.data = []
          }
          this.flagActionInProgress = true
          args.ajaxSettings.url = `${this.urlBase}repository/folder/create`
        }

        if (fileManagerOpers.SEARCH === args.action) {
          data.idEntityTypeBase = this.idEntityTypeBase
        }

        if (fileManagerOpers.MOVE === args.action) {
          args.ajaxSettings.url = `${this.urlBase}repository/files/folders/move`
        }

        if (fileManagerOpers.COPY === args.action) {
          args.ajaxSettings.url = `${this.urlBase}repository/files/folders/copy`
        }

        if (fileManagerOpers.RENAME === args.action) {
          if (data.data[0].isFile) {
            args.ajaxSettings.url = `${this.urlBase}repository/file/update`
          } else {
            args.ajaxSettings.url = `${this.urlBase}repository/folder/update`
          }
        }

        data.idStage = this.idStage

        data.mainFolderDescription = this.mainFolderDescription

        args.ajaxSettings.data = JSON.stringify(data)

        this.lastFileManagerOper = args.action
      }
    }

    async onFileManagerAfterSuccess(data: any) {
      await this.onFileManagerSuccess(data).then(() =>
        setTimeout(() => this.checkIfRenderDescendingIcon(this.sortOrder), 250)
      )
    }

    async onFileManagerSuccess(data: any) {
      let basePath
      let allFiles
      let hideSearch = false

      if (this.fileManagerInstance.detailsviewModule.gridObj) {
        this.fileManagerInstance.detailsviewModule.gridObj.element.addEventListener('pointerup', this.onGridEvent)
      }

      if (data.result) {
        let numFiles = 1
        if (data.result.files) {
          numFiles = data.result.files.length
        }

        if (data.result.error && data.result.error.code) {
          this.showErrorAlert(data.action, numFiles)
          return
        } else {
          if (data.action === fileManagerOpers.MOVE) {
            allFiles = data.result.files
              .map((item: any) => {
                return item.isFile
              })
              .every((e: any) => e === true)
            if (!allFiles) {
              data.action = fileManagerOpers.MOVEFOLDERS
            }
          }
          this.showSuccessAlert(data.action, numFiles)
        }

        if (data.result.cwd) {
          basePath = data.result.cwd.basePath
          this.currentBasePath = basePath
          this.cwd = data.result.cwd
        }

        if (data.result.files) {
          if (numFiles && !this.files.length) {
            const file: File = {
              uid: 0,
              name: this.mainFolderDescription,
              basePath,
              filterPath: '/',
              idEntity: this.idMainEntity,
              idEntityType: this.idMainEntityType,
              canSearch: true,
              isFile: false,
              isDroppable: this.cwd!.isDroppable,
              canCreateDocument: this.cwd!.canCreateDocument,
              canCreateFolder: this.cwd!.canCreateFolder,
              canCreateTemplate: this.cwd!.canCreateTemplate
            }
            this.files.push(file)
          }

          if (data.result.cwd && data.result.cwd.idEntityType > 0) {
            this.idEntity = Number(data.result.cwd.idEntity)
            this.idEntityType = Number(data.result.cwd.idEntityType)
            this.idEntityTypeAdditional = Number(data.result.cwd.idEntityTypeAdditional)
          } else if (data.result.files.length) {
            this.idEntity = parseInt(data.result.files[0].idEntity, 10)
            this.idEntityType = parseInt(data.result.files[0].idEntityType, 10)
            this.idEntityTypeAdditional = parseInt(data.result.files[0].idEntityTypeAdditional, 10)
          }

          this.saveSelectedIdEntityType(this.idEntityType)
          this.saveParentRegisterId(this.idEntity)

          this.currentPath = this.fileManagerInstance.path

          for (let i = 0; i < numFiles; i++) {
            const file: File = data.result.files[i]
            const found = this.files.find((x) => x.uid === file.uid)
            if (typeof found === 'undefined') {
              this.files.push(file)
            }
            if (data.action !== fileManagerOpers.SEARCH && !file.isFile) {
              hideSearch = !file.canSearch
            }
          }
        }
        const search: HTMLElement | null = document.getElementById('file-manager_search')
        if (search) {
          ;(search as any).ej2_instances[0].enabled = !hideSearch
        }
      }
      this.fileManagerInstance.uploadObj.selected = this.uploaderSelected

      this.refreshPreview({ id: 0, type: DocumentPreviewType.Unknown })
    }

    onFileManagerFailure(data: any) {
      const { error } = data
      if (error && error.file) {
        data.error.statusText = this.$t('components.file_manager.uploader.fail')
        return
      }

      if (this.isRefreshingAccessToken) {
        store.dispatch(`${ModuleNamespaces.AUTH}/logout`)
        return
      }

      if (['404', '500'].includes(error.code)) {
        this.isRefreshingAccessToken = true
        store.dispatch(`${ModuleNamespaces.AUTH}/refreshToken`).then(() => {
          this.isRefreshingAccessToken = false
          this.fileManagerInstance.refresh()
        })
        return false
      }
    }

    onFileManagerBeforeImageLoad(data: any) {
      const idDocument = data.fileDetails[0].id
      const url = data.imageUrl.replace('%1', idDocument).split('?')[0]
      data.imageUrl = `${url}?authToken=${this.authToken}`
    }

    async onContextMenuClick(args: any) {
      if (args.item.id === this.fileManagerInstance.element.id + '_cm_upload_document') {
        this.editMode = EditDocumentMode.uploadNew
        this.openDocumentDetail()
      }
      if (args.item.id === this.fileManagerInstance.element.id + '_cm_new_word') {
        this.editMode = EditDocumentMode.wordOnlineNew
        this.setDocumentOnlineAction(documentOnlineActions.NEW)
        this.openDocumentDetail()
      }
      if (args.item.id === this.fileManagerInstance.element.id + '_cm_new_excel') {
        this.editMode = EditDocumentMode.excelOnlineNew
        this.setDocumentOnlineAction(documentOnlineActions.NEW)
        this.openDocumentDetail()
      }
      if (args.item.id === this.fileManagerInstance.element.id + '_cm_new_ppoint') {
        this.editMode = EditDocumentMode.powerPointOnlineNew
        this.setDocumentOnlineAction(documentOnlineActions.NEW)
        this.openDocumentDetail()
      }
      if (args.item.id === this.fileManagerInstance.element.id + '_cm_from_template' && this.hasCompanyTemplate) {
        this.trackClick()
        this.showTemplateDialog = this.checkIfOnlyTemplate()
      }
      if (args.item.id === this.fileManagerInstance.element.id + '_cm_details') {
        if (!this.isTemplateMode) {
          this.editMode = EditDocumentMode.uploadEdit
          this.openDocumentDetail(args.fileDetails[0].id)
        } else {
          this.saveTemplate(args.fileDetails[0])
          this.openTemplateForm()
        }
      }
      if (args.item.id === this.fileManagerInstance.element.id + '_cm_permissions') {
        this.openFolderDetail(args.fileDetails[0].idFolder)
      }
      if (args.item.id === this.fileManagerInstance.element.id + '_cm_download') {
        args.cancel = true
        this.customDownload(args.fileDetails)
      }
      if (args.item.id === this.fileManagerInstance.element.id + '_cm_new_template' && this.hasCompanyTemplate) {
        this.openTemplateForm(false)
      }
      if (
        args.item.id === this.fileManagerInstance.element.id + '_cm_download_zip' ||
        args.item.id === this.fileManagerInstance.element.id + '_cm_download_zip_portal'
      ) {
        this.downloadAsZipPrompt(args.fileDetails[0])
      }
      if (args.item.id === this.fileManagerInstance.element.id + '_cm_download_as_pdf') {
        this.downloadAsPdf(args.fileDetails[0])
      }
      // *** FIRMA SUBMENU *** //
      if (args.item.id === FirmaContextMenuId.CERTIFY_DOCUMENT) {
        this.openFirmaDialog(
          {
            selectedFileId: args.fileDetails[0].id,
            selectedRequestType: FirmaRequestTypes.CERTIFIED_DOCUMENT
          },
          CustomDialogComponentName.CERTIFY_DOCUMENT
        )
      }
      if (args.item.id === FirmaContextMenuId.SEND_FIRMA) {
        this.openFirmaDialog(
          {
            selectedFileId: args.fileDetails[0].id,
            selectedRequestType: FirmaRequestTypes.FIRMA_REQUEST
          },
          CustomDialogComponentName.SEND_TO_FIRMA
        )
      }
      if (args.item.id === FirmaContextMenuId.SEND_CERTIFIED_EMAIL) {
        this.openFirmaDialog(
          {
            selectedFileId: args.fileDetails[0].id,
            selectedRequestType: FirmaRequestTypes.CERTIFIED_EMAIL
          },
          CustomDialogComponentName.SEND_CERTIFIED_EMAIL
        )
      }
      if (args.item.id === FirmaContextMenuId.MONITORING_REVIEW) {
        this.setSignInfo({ selectedFileId: args.fileDetails[0].id })
        this.showCustomDialogAction({
          name: CustomDialogComponentName.MONITORING_AND_AUDITS
        })
      }
      if (args.item.id === FirmaContextMenuId.SEND_CERTIFIED_SMS) {
        this.openFirmaDialog(
          {
            selectedFileId: args.fileDetails[0].id,
            selectedRequestType: FirmaRequestTypes.CERTIFIED_SMS
          },
          CustomDialogComponentName.SEND_CERTIFIED_SMS
        )
      }
      // *** FIN FIRMA SUBMENU *** //
      // *** EDIT SUBMENU *** //
      if (args.item.id === EditContextMenuId.CUT) {
        ;(document as any).getElementById('file-manager_cm_cut').click()
      }
      if (args.item.id === EditContextMenuId.COPY) {
        ;(document as any).getElementById('file-manager_cm_copy').click()
      }
      if (args.item.id === EditContextMenuId.PASTE) {
        ;(document as any).getElementById('file-manager_cm_paste').click()
      }
      if (args.item.id === EditContextMenuId.RENAME) {
        ;(document as any).getElementById('file-manager_cm_rename').click()
      }
      // *** FIN EDIT SUBMENU *** //
      // *** SEND SUBMENU *** //
      if (args.item.id === SendContextMenuId.EMAIL) {
        trackEvent(TrackerEvents.ATTACH_TO_MAIL)
        this.sendDocMail(args.fileDetails)
      }
      if (args.item.id === SendContextMenuId.PUBLISH) {
        this.publishActions(args.fileDetails, true)
      }
      if (args.item.id === SendContextMenuId.UNPUBLISH) {
        this.publishActions(args.fileDetails, false)
      }
      // *** FIN SEND SUBMENU *** //
      // *** LEXNET SUBMENU *** //
      if (
        args.item.id === LexnetContextMenuId.PROCEDURE ||
        args.item.id === LexnetContextMenuId.SUBJECT ||
        args.item.id === LexnetContextMenuId.EXECUTION ||
        args.item.id === LexnetContextMenuId.DEFENSE ||
        args.item.id === LexnetContextMenuId.PERSONATION ||
        args.item.id === LexnetContextMenuId.APPEAL
      ) {
        this.showFileManagerSpinner(this.spinnerText.sending)
        await this.getLexNetToken()
        this.openNewEscritoLexnetApp((this as any).getLexnetIdFromName(args.item.id), args.fileDetails[0].id)
        this.hideFileManagerSpinner()
      }
      // *** FIN LEXNET SUBMENU *** //
    }

    trackClick() {
      if (this.context === ContextName.EXPEDIENTS) {
        trackEvent(TrackerEvents.NEW_DOCUMENT_TEMPLATE)
      }
    }

    async openFirmaDialog(setSignInfoData: any, name: string) {
      this.showFileManagerSpinner(this.spinnerText.sending)
      try {
        if (await this.checkFirmaCredits(setSignInfoData.selectedFileId)) {
          this.setSignInfo(setSignInfoData)
          this.showCustomDialogAction({
            name
          })
        }
      } catch (error) {
        this.showErrorAlert(fileManagerOpers.OPEN_FIRMA_SERVICE, 0)
      }
      this.hideFileManagerSpinner()
    }

    actionOpenFile(file: any) {
      if (file.isOnlineEditable) {
        if (!this.isTemplateMode) {
          if (this.openMSOfficeConfigMode === OpenMSOffice.WOPI) {
            this.setDocumentOnlineAction(documentOnlineActions.EDIT)
            this.editDocumentOnline(file)
          } else {
            this.openMSOfficeLocal(file)
          }
        } else {
          this.saveTemplate(file)
          this.saveCurrentBasePath(this.currentBasePath)
          this.saveCurrentPath(this.currentPath)
          this.showHideTemplateGenerator(true)
        }
      } else if (file._fm_iconClass === 'e-fe-pdf' || file._fm_iconClass.includes('e-fe-xyz')) {
        const { pdfViewerEnabled } = navigator as any
        if (pdfViewerEnabled) {
          openLefebvrePdfViewer(file.id)
          return
        }
        this.customOpenFile(file)
      } else {
        if (file._fm_iconClass !== 'e-fe-image' || ['.tif', '.tiff'].includes(file.type.toString().toLowerCase())) {
          this.customOpenFile(file)
        }
      }
    }

    openTemplateForm(edit = true) {
      this.saveCurrentBasePath(this.currentBasePath)
      this.saveCurrentPath(this.currentPath)
      this.templateId = edit ? this.selectedTemplateId : 0
      this.showDocumentDetail = true
      this.$emit('openForm')
    }

    saveTemplate(file: any) {
      this.saveTemplateId(Number(file.idEntity))
    }

    async sendDocMail(item: any) {
      await this.fetchEmailProvider()
      if (this.emailProvider !== null) {
        const idDocs: string[] = []
        item.forEach((i: any) => {
          idDocs.push(i.id)
        })
        this.showFileManagerSpinner(this.spinnerText.sending)
        const url = '/mail/doc/send'
        const jsonData = { idDocument: idDocs, env: process.env.VUE_APP_LEXBOX_ENV ? process.env.VUE_APP_LEXBOX_ENV : '' }
        try {
          const { data } = await new MainService().postData(url, jsonData)
          let urlMail = `${(emailEndpoints as any)[this.emailProvider.provider].url}access/${data.token}`
          switch (this.emailProvider.provider) {
            case emailProviderName.IMAP:
              urlMail += `?prov=IM0&account=${btoa(this.emailProvider.email)}`
              break
            case emailProviderName.GOOGLE:
              urlMail += `?prov=GO0&email=${this.emailProvider.email}`
              break
            case emailProviderName.OUTLOOK:
              urlMail += '?prov=OU0'
              break
          }
          window.open(urlMail, '_blank')
        } catch (error) {
          this.showErrorAlert(fileManagerOpers.OPEN_MAIL_SERVICE, 0)
        }
        this.hideFileManagerSpinner()
      } else {
        this.showErrorAlert(fileManagerOpers.SEND_EMAIL, 0)
      }
    }

    async publishActions(items: any, publish: boolean) {
      const jsonAll: any = []
      let result = false
      let action = ''
      let actionFolder: string | null = null
      items.forEach((item: any) => {
        const json: any = {}
        json.public = publish ? 1 : 0
        if (item.isFile) {
          json.Id = item.id
          json.isFolder = false
          json.idEntity = item.idEntity
          json.idEntityType = item.idEntityType
          action = publish ? fileManagerOpers.PUBLISH : fileManagerOpers.UNPUBLISH
        } else {
          json.id = item.idFolder
          json.isFolder = true
          actionFolder = publish ? fileManagerOpers.PUBLISHFOLDER : fileManagerOpers.UNPUBLISHFOLDER
        }
        jsonAll.push(json)
      })
      action = actionFolder || action
      const formData = new FormData()
      formData.append('json', JSON.stringify(jsonAll))
      const { data } = await new RepositoryService().updateFolderPublic(formData)
      result = data.result
      if (result) {
        this.showSuccessAlert(action, items.length)
      } else {
        this.showErrorAlert(action, items.length)
      }
      this.refresh()
    }

    async editDocumentOnline(file: any | null) {
      this.saveFileName(file.originalFileName)

      const formData = new FormData()
      formData.set('FileName', file.originalFileName)
      formData.set('FileVersion', Date.now().toString())
      formData.set('wopiUserId', this.idUser.toString())

      await this.fetchRequestToken(formData)

      formData.set('AccessToken', this.requestToken)
      formData.set('AccessTokenTtl', this.requestTokenTtl)

      await this.fetchAppType(formData)

      await this.fetchFileToken(file.id)

      if (this.fileAction === documentOnlineActions.NEW) {
        this.saveFileSize(0)
      }

      this.openOnlineEditor(`${process.env.VUE_APP_FILE_EDIT_URL}${this.fileToken}`)
    }

    openBlobFile(id: number, newPage = true) {
      this.isOpeningFile = true
      this.customOpenFile({ id }, true, newPage)
    }

    async customOpenFile(file: any, openDirectly = false, newPage = true) {
      const { id, originalFileName } = file

      if (openDirectly || this.fileManagerInstance.selectedItems.length !== 0) {
        await this.openFile(id)

        if (isSafari() && !openDirectly) {
          const url = `/${URLS.REPOSITORY}?fileId=${id}`
          createLink(url, '', true)
          return
        }

        const blobUrl = this.getBlobUrl()
        createLink(blobUrl, originalFileName, newPage)
      }
    }

    getBlobUrl() {
      if (this.blobFile.type === 'text/plain') {
        const blobFileParsed = this.blobFile.slice(0, this.blobFile.size, 'text/plain; charset=UTF-8')
        return URL.createObjectURL(blobFileParsed)
      }
      return URL.createObjectURL(this.blobFile)
    }

    async customDownload(files: any[]) {
      const flag: boolean = this.fileManagerInstance.selectedItems.length !== 0
      const numFiles = files.length
      if (numFiles !== 0 || flag) {
        for (let i = 0; i < numFiles; i++) {
          const file = files[i]

          await this.downloadFile(file.id)

          const blobUrl = URL.createObjectURL(this.blobFile)
          createLink(blobUrl, file.originalFileName)
        }
      }
    }

    togglePreview(documentPreview: DocumentPreview) {
      const preview: PreviewComponent = this.$refs.preview as PreviewComponent

      if (preview) {
        preview.toggle(documentPreview)
        return
      }
    }

    hidePreview() {
      const preview: PreviewComponent = this.$refs.preview as PreviewComponent

      if (preview) {
        preview.hide()
        return
      }
    }

    refreshPreview(documentPreview: DocumentPreview) {
      const preview: PreviewComponent = this.$refs.preview as PreviewComponent

      if (this.fileManagerInstance.selectedItems.length > 1) {
        documentPreview.type = DocumentPreviewType.Unknown
      }

      if (preview) {
        preview.refresh(documentPreview)
        return
      }
    }

    prepareDetailsViewTableForPreview() {
      const table1 = document.querySelector('#file-manager .e-headercontent colgroup')
      if (table1) {
        ;(table1.childNodes[1] as any).style.width = '80px'
      }
      const table2 = document.querySelector('#file-manager .e-gridcontent colgroup')
      if (table2) {
        ;(table2.childNodes[1] as any).style.width = '80px'
      }
    }

    createPreviewButtonOnDetailsViewTable(detailsViewElement: any, documentPreview: DocumentPreview) {
      const elem = detailsViewElement.querySelector('.e-fe-grid-icon .e-fe-icon')
      if (elem) {
        const buttonPreview = document.createElement('span')
        buttonPreview.id = `preview-item-${documentPreview.id}`
        buttonPreview.classList.add('e-fe-icon')
        buttonPreview.classList.add(Icons.VISIBLE)
        buttonPreview.classList.add('icon-preview')
        // buttonPreview.style.cursor = 'pointer'
        buttonPreview.style.paddingLeft = '5px'
        // buttonPreview.addEventListener('click', this.previewAction);
        ;(buttonPreview as any).documentPreview = JSON.stringify({
          id: documentPreview.id,
          type: documentPreview.type
        })
        elem.parentNode.appendChild(buttonPreview)
      }
    }

    onFileLoad(args: any) {
      if ('NavigationPane' === args.module) {
        if (args.fileDetails) {
          const fullRow: HTMLElement | null = args.element.querySelector('.e-fullrow')
          if (fullRow) {
            let droppable = args.fileDetails.isDroppable === true ? 'true' : 'false'

            if (this.canDropFilesOnMainFolder && '' === args.fileDetails.filterPath) {
              droppable = 'true'
            }

            fullRow.setAttribute('droppable', droppable)
          }
        }
      }

      if ('DetailsView' === args.module) {
        const previewExtensions = (this.$refs.preview as any).getPreviewExtensions()
        if (
          args.fileDetails &&
          args.fileDetails.type &&
          previewExtensions.includes(args.fileDetails.type.toLowerCase())
        ) {
          this.prepareDetailsViewTableForPreview()
          this.createPreviewButtonOnDetailsViewTable(args.element, {
            id: args.fileDetails.id,
            type: args.fileDetails.type as DocumentPreviewType
          })
        }
      }
    }

    onFileManagerDragStart(fileDragEventArgs: any) {
      const items = fileDragEventArgs.fileDetails
      if (items.length) {
        for (let i = 0; i < items.length; i++) {
          if (!fileDragEventArgs.fileDetails[i].isDraggable) {
            fileDragEventArgs.cancel = true
            break
          }
        }
      }
      return fileDragEventArgs
    }

    onFileManagerDragStop(ev: any) {
      const canDrop =
        !ev.target.className.includes('e-fullrow') ||
        (ev.target.hasAttribute('droppable') && ev.target.getAttribute('droppable') === 'true')

      if (!canDrop) {
        ev.cancel = true
      }
      return ev
    }

    onFileManagerDragging(ev: any) {
      const helper: HTMLElement | null = this.$el.querySelector('.e-fe-clone')
      const fileManager: HTMLElement | null = this.$el.querySelector('#file-manager')

      if (helper && fileManager) {
        const canDrop =
          !ev.target.className.includes('e-fullrow') ||
          (ev.target.hasAttribute('droppable') && ev.target.getAttribute('droppable') === 'true')

        fileManager.className = fileManager.className.replace(' lexon-no-drop', '')

        if (!canDrop) {
          fileManager.className += ' lexon-no-drop'
        }
      }
    }

    showSuccessAlert(action: string, numFiles: number) {
      let message: any = ''

      switch (action) {
        case fileManagerOpers.DELETE:
          this.showDialogResult(
            this.$tc('components.file_manager.success_messages.deleted', numFiles),
            DialogTypes.SUCCESS
          )
          break
        case fileManagerOpers.MOVE:
          message = this.$tc('components.file_manager.success_messages.moved', numFiles)
          break
        case fileManagerOpers.MOVEFOLDERS:
          message = this.$tc('components.file_manager.success_messages.moved_folder', numFiles)
          break
        case fileManagerOpers.RENAME:
          message = this.$t('components.file_manager.success_messages.renamed')
          break
        case fileManagerOpers.UPDATE:
          message = this.$t('components.file_manager.success_messages.document_updated')
          break
        case fileManagerOpers.UPDATE_INTERVENER:
          message = this.$t('components.file_manager.success_messages.document_intervener_updated')
          break
        case fileManagerOpers.CREATE_INTERVENER:
          message = this.$t('components.file_manager.success_messages.document_intervener_created')
          break
        case fileManagerOpers.CANCEL_UPLOAD_FOLDERS:
          message = this.$t('components.file_manager.success_messages.cancel_upload_folders')
          break
        case fileManagerOpers.FINISH_UPLOAD_FOLDERS:
          message = this.$t('components.file_manager.success_messages.finish_upload_folders')
          break
        case fileManagerOpers.DOWNLOAD_AS_ZIP:
          message = this.$t('components.file_manager.success_messages.download_as_zip')
          break
        case fileManagerOpers.PUBLISH:
          message = this.$tc('components.file_manager.success_messages.publish', numFiles)
          break
        case fileManagerOpers.PUBLISHFOLDER:
          message = this.$tc('components.file_manager.success_messages.publish_folder', numFiles)
          break
        case fileManagerOpers.UNPUBLISH:
          message = this.$tc('components.file_manager.success_messages.unpublish', numFiles)
          break
        case fileManagerOpers.UNPUBLISHFOLDER:
          message = this.$tc('components.file_manager.success_messages.unpublish_folder', numFiles)
          break
      }

      if (message) {
        this.showAlertResult(message, AlertsTypes.SUCCESS)
      }
    }

    showErrorAlert(action: string, numFiles: number) {
      let message: any = this.$t('errors.generic_error')
      let alertType = AlertsTypes.ERROR
      switch (action) {
        case fileManagerOpers.DELETE:
          this.showDialogResult(
            this.$tc('components.file_manager.error_messages.cant_delete', numFiles),
            DialogTypes.ERROR
          )
          break
        case fileManagerOpers.MOVE:
          message = this.$tc('components.file_manager.error_messages.cant_move', numFiles)
          break
        case fileManagerOpers.RENAME:
          message = this.$t('components.file_manager.error_messages.cant_rename')
          break
        case fileManagerOpers.UPDATE:
          message = this.$t('components.file_manager.error_messages.cant_update_document')
          break
        case fileManagerOpers.UPDATE_INTERVENER:
          message = this.$t('components.file_manager.success_messages.cant_update_document_intervener')
          break
        case fileManagerOpers.CREATE_INTERVENER:
          message = this.$t('components.file_manager.success_messages.cant_create_document_intervener')
          break
        case fileManagerOpers.DOWNLOAD_AS_ZIP:
          message = this.$t('components.file_manager.error_messages.cant_download_as_zip')
          break
        case fileManagerOpers.CONVERT_TO_PDF:
          message = this.$t('components.file_manager.error_messages.cant_create_pdf')
          break
        case fileManagerOpers.OPEN_FIRMA_SERVICE:
          message = this.$t('components.file_manager.error_messages.cant_open_firma_service')
          break
        case fileManagerOpers.OPEN_MAIL_SERVICE:
          message = this.$t('components.file_manager.error_messages.cant_open_mail_service')
          break
        case fileManagerOpers.PUBLISH:
          message = this.$tc('components.file_manager.error_messages.cant_publish', numFiles)
          break
        case fileManagerOpers.PUBLISHFOLDER:
          message = this.$tc('components.file_manager.error_messages.cant_publish_folder', numFiles)
          break
        case fileManagerOpers.SEND_EMAIL:
          alertType = AlertsTypes.WARNING
          message = this.$tc('components.file_manager.error_messages.cant_send_email', numFiles)
          break
        case fileManagerOpers.UNPUBLISH:
          message = this.$tc('components.file_manager.error_messages.cant_unpublish', numFiles)
          break
        case fileManagerOpers.UNPUBLISHFOLDER:
          message = this.$tc('components.file_manager.error_messages.cant_unpublish_folder', numFiles)
          break
      }
      this.showAlertResult(message, alertType)
    }

    showAlertResult(message: any, alertType: AlertsTypes) {
      store.dispatch(
        `${ModuleNamespaces.ALERTS}/showAlert`,
        {
          type: alertType,
          message,
          componentWhereIsRendered: this.alertWhereIsReview
        },
        { root: true }
      )
    }

    showDialogResult(message: any, alertType: DialogTypes) {
      store.dispatch(
        `${ModuleNamespaces.DIALOG}/showDialog`,
        {
          type: alertType,
          message
        },
        { root: true }
      )
    }

    onCantDropFolders() {
      this.showAlertResult(this.$t('components.file_manager.error_messages.cant_upload_folders'), AlertsTypes.WARNING)
    }

    onCantDropFiles() {
      this.showAlertResult(
        this.$t('components.file_manager.error_messages.cant_upload_files_restrictions'),
        AlertsTypes.WARNING
      )
      this.hideOverlay()
    }

    onCantUploadsFilesHere() {
      this.showAlertResult(this.$t('components.file_manager.advises.cant_upload_files_here'), AlertsTypes.ERROR)
      this.hideOverlay()
    }

    onCantUploadsFoldersHere() {
      this.showAlertResult(this.$t('components.file_manager.advises.cant_upload_folders_here'), AlertsTypes.ERROR)
      this.hideOverlay()
    }

    onPopupOpen(args: any) {
      if ('Error' === args.name) {
        args.popupModule.hide()
      }

      if ('popupOpen' === args.name) {
        const popupElem: HTMLElement | null = document.getElementById(args.popupModule.element.id)
        if (null !== popupElem) {
          if ('Delete' === args.popupName || 'Error' === args.popupName) {
            const header = popupElem.querySelector('.e-dlg-header')
            if (null != header) {
              header.classList.add('lf-dlg-icon')
            }
          } else {
            const parent: HTMLElement | null = popupElem.querySelector('.e-input-group')
            const input = popupElem.querySelector('input')
            const span: HTMLElement | null = document.createElement('span')
            const label: HTMLElement | null = document.createElement('label')
            if (null !== parent) {
              if (null !== input) {
                span.classList.add('lf-clean-field')
                span.addEventListener('click', () => {
                  input.value = ''
                  span.style.display = 'none'
                })
                parent.appendChild(span)

                input.setAttribute('maxlength', '255')
                input.addEventListener('keyup', () => {
                  span.style.display = 'block'
                })
                parent.addEventListener('mouseenter', () => {
                  if ('' !== input.value) {
                    span.style.display = 'block'
                  }
                })
                parent.addEventListener('mouseleave', () => {
                  span.style.display = 'none'
                })
                if ('newname' === input.id) {
                  span.style.display = 'none'
                }
                if (null != label) {
                  label.innerHTML = this.$t(
                    'components.file_manager.dialogs.' + args.popupName.replace(/\s/g, '_').toLowerCase() + '_label'
                  ).toString()
                  label.classList.add('lf-dlg-label')
                  const spanLabel = document.createElement('span')
                  spanLabel.innerHTML = '*'
                  label.appendChild(spanLabel)
                  const labelParent = parent.parentNode
                  if (null != labelParent) {
                    labelParent.prepend(label)
                  }
                }

                const errorSpan: HTMLElement | null = popupElem.querySelector('.e-fe-error')
                if (null != errorSpan) {
                  errorSpan.addEventListener('DOMSubtreeModified', () => {
                    if ('' === errorSpan.innerHTML) {
                      label.classList.remove('lf-fe-label-error')
                      input.classList.remove('lf-fe-input-error')
                    } else {
                      label.classList.add('lf-fe-label-error')
                      input.classList.add('lf-fe-input-error')
                    }
                  })
                }
              }
            }
          }
        }
      }
    }

    onPopUpClose(args: any) {
      this.flagActionInProgress = false
      const popupElem: HTMLElement | null = document.getElementById(args.popupModule.element.id)
      if (null != popupElem) {
        const clearSpan = popupElem.querySelector('.lf-clean-field')
        if (null != clearSpan) {
          clearSpan.remove()
        }
        const clearLabel = popupElem.querySelector('.lf-dlg-label')
        if (null != clearLabel) {
          clearLabel.remove()
        }
      }
    }

    onContextMenuOpen(args: any) {
      const { canSave, canDelete } = this.checkEntityPermissionsGetter(this.permissionsEntity)

      let folderOrDocument = null
      if (args.fileDetails && args.fileDetails[0]) {
        folderOrDocument = args.fileDetails[0]
      }

      const disabledItems = []

      for (const item of args.items) {
        switch (item.id) {
          case this.fileManagerInstance.element.id + '_cm_custom_separator':
            item.id = 'custom_separator'
            break
          case this.fileManagerInstance.element.id + '_cm_open':
            if (args.target.className === 'e-fullrow' && !folderOrDocument.isFile) {
              setTimeout(() => {
                ;(document as any).getElementById('file-manager_cm_open').click()
              })
              disabledItems.push(item.id)
            }
            break
          case this.fileManagerInstance.element.id + '_cm_details':
            if (this.isPortalUser) {
              disabledItems.push(item.id)
              item.id = 'hidden-cm-option'
            }
            break
          case this.fileManagerInstance.element.id + '_cm_edit':
            if (this.isPortalUser || !canSave) {
              item.id = 'hidden-cm-option'
            } else {
              this.createEditMenu(item)
            }
            break
          case this.fileManagerInstance.element.id + '_cm_edit_layout':
            if (this.isPortalUser) {
              item.id = 'hidden-cm-option'
            } else {
              this.createEditLayoutMenu(item, folderOrDocument.isFile)
            }
            break
          case this.fileManagerInstance.element.id + '_cm_edit_multiple':
            if (this.isPortalUser) {
              item.id = 'hidden-cm-option'
            } else {
              this.createEditMultipleMenu(item, folderOrDocument.isFile)
            }
            break
          case this.fileManagerInstance.element.id + '_cm_send':
            if (this.isPortalUser || this.isDMSUser) {
              item.id = 'hidden-cm-option'
            } else if ((folderOrDocument && !folderOrDocument.canPublish) || !canSave) {
              item.iconCss = Icons.SHARE
              item.text = this.$t('components.file_manager.actions.send.title') as string
              disabledItems.push(item.id)
            } else {
              this.createSendMenu(item)
            }
            break
          case this.fileManagerInstance.element.id + '_cm_delete':
            if (folderOrDocument && !folderOrDocument.canDelete) {
              disabledItems.push(item.id)
            }
            if (this.isPortalUser || !canDelete) {
              disabledItems.push(item.id)
              item.id = 'hidden-cm-option'
            }
            break
          case this.fileManagerInstance.element.id + '_cm_cut':
          case this.fileManagerInstance.element.id + '_cm_copy':
            if (folderOrDocument && !folderOrDocument.canDelete) {
              disabledItems.push(item.id)
            }
            if (this.isPortalUser) {
              disabledItems.push(item.id)
              item.id = 'hidden-cm-option'
            }
            break
          case this.fileManagerInstance.element.id + '_cm_rename':
            if (folderOrDocument && !folderOrDocument.canRename) {
              disabledItems.push(item.id)
            }
            if (this.isPortalUser) {
              disabledItems.push(item.id)
              item.id = 'hidden-cm-option'
            }
            break
          case this.fileManagerInstance.element.id + '_cm_paste':
            if (folderOrDocument && (!folderOrDocument.canCreateDocument || !folderOrDocument.canCreateTemplate)) {
              disabledItems.push(item.id)
            }
            if (this.isPortalUser) {
              disabledItems.push(item.id)
              item.id = 'hidden-cm-option'
            }
            break
          case this.fileManagerInstance.element.id + '_cm_download_zip':
          case this.fileManagerInstance.element.id + '_cm_download_zip_portal':
            item.iconCss = Icons.ZIP
            item.text = this.$t('components.file_manager.actions.context_menu.download_zip')
            if (this.isPortalUser) {
              item.id = 'file-manager_cm_download_zip_portal'
            }
            if (folderOrDocument && !folderOrDocument.canZip) {
              disabledItems.push(item.id)
            }
            break
          case this.fileManagerInstance.element.id + '_cm_newfolder':
            item.iconCss = Icons.FOLDER
            item.text = this.$t('components.file_manager.actions.new_folder')
            if (
              (folderOrDocument && !folderOrDocument.canCreateFolder) ||
              (folderOrDocument && !folderOrDocument.basePath.includes('/') && !this.canCreateFoldersOnMainFolder)
            ) {
              disabledItems.push(item.id)
            }
            if (this.isPortalUser) {
              disabledItems.push(item.id)
              item.id = 'hidden-cm-option'
            }
            break
          case this.fileManagerInstance.element.id + '_cm_new_word':
            item.iconCss = Icons.WORD
            item.text = this.$t('components.file_manager.actions.context_menu.new_word_document')
            if (folderOrDocument && !folderOrDocument.canCreateDocument) {
              disabledItems.push(item.id)
            }
            if (this.isPortalUser) {
              disabledItems.push(item.id)
              item.id = 'hidden-cm-option'
            }
            break
          case this.fileManagerInstance.element.id + '_cm_new_excel':
            if (folderOrDocument && !folderOrDocument.canCreateDocument) {
              disabledItems.push(item.id)
            }
            item.iconCss = Icons.EXCEL
            item.text = this.$t('components.file_manager.actions.context_menu.new_excel_document')
            if (this.isPortalUser) {
              disabledItems.push(item.id)
              item.id = 'hidden-cm-option'
            }
            break
          case this.fileManagerInstance.element.id + '_cm_new_ppoint':
            if (folderOrDocument && !folderOrDocument.canCreateDocument) {
              disabledItems.push(item.id)
            }
            item.iconCss = Icons.PPT
            item.text = this.$t('components.file_manager.actions.context_menu.new_ppoint_document')
            if (this.isPortalUser) {
              disabledItems.push(item.id)
              item.id = 'hidden-cm-option'
            }
            break
          case this.fileManagerInstance.element.id + '_cm_from_template':
            if (folderOrDocument && (!folderOrDocument.canCreateDocument || !folderOrDocument.canCreateTemplate)) {
              disabledItems.push(item.id)
            }
            item.iconCss = Icons.TEMPLATE
            item.text = this.$t('components.file_manager.actions.context_menu.from_template_document')
            if (this.isPortalUser) {
              disabledItems.push(item.id)
              item.id = 'hidden-cm-option'
            }
            break
          case this.fileManagerInstance.element.id + '_cm_upload_document':
            if (folderOrDocument && !folderOrDocument.canCreateDocument) {
              disabledItems.push(item.id)
            }
            item.iconCss = Icons.UPLOAD
            item.text = this.$t('components.file_manager.actions.context_menu.upload_lexon_document')
            if (this.isPortalUser) {
              disabledItems.push(item.id)
            }
            break
          case this.fileManagerInstance.element.id + '_cm_new_template':
            if (folderOrDocument && !folderOrDocument.canCreateTemplate) {
              disabledItems.push(item.id)
            }
            item.iconCss = Icons.DOCUMENTATION
            item.text = this.$t('components.file_manager.actions.new_template')
            if (this.isPortalUser) {
              disabledItems.push(item.id)
              item.id = 'hidden-cm-option'
            }
            break
          case this.fileManagerInstance.element.id + '_cm_download_as_pdf':
            const isConvertible = this.isTransformableToPdf(folderOrDocument)
            if ((folderOrDocument && !folderOrDocument.canCreateDocument) || !isConvertible) {
              disabledItems.push(item.id)
            }
            item.iconCss = Icons.PDF
            item.text = this.$t('components.file_manager.actions.download_as_pdf')
            if (this.isPortalUser) {
              disabledItems.push(item.id)
              item.id = 'hidden-cm-option'
            }
            break
          case this.fileManagerInstance.element.id + '_cm_permissions':
            item.iconCss = Icons.OPTIONS
            item.text = this.$t('components.file_manager.actions.permissions')
            if (folderOrDocument && !folderOrDocument.canRename) {
              disabledItems.push(item.id)
            }
            if (this.isPortalUser) {
              disabledItems.push(item.id)
              item.id = 'hidden-cm-option'
            }
            break
          case this.fileManagerInstance.element.id + '_cm_firma':
            if (this.userHasFirma && !this.isPortalUser && !this.isDMSUser) {
              this.createFirmaMenu(item)
              if (!folderOrDocument.type || !allowedFirmaExtensions.includes(folderOrDocument.type) || !canSave) {
                disabledItems.push(item.id)
              }
            } else {
              item.id = 'hidden-cm-option'
            }
            break
          case this.fileManagerInstance.element.id + '_cm_lexnet':
            if (!this.isPortalUser && !this.isDMSUser) {
              this.createLexnetMenu(item)
              if (!folderOrDocument.type || folderOrDocument.type !== '.pdf') {
                disabledItems.push(item.id)
              }
            } else {
              item.id = 'hidden-cm-option'
            }
            break
          case FirmaContextMenuId.SEND_FIRMA:
            if (folderOrDocument && folderOrDocument.isFile && folderOrDocument.type) {
              if (folderOrDocument.status) {
                const statusArray = folderOrDocument.status.split(',').map((t: string) => parseInt(t, 10))
                if (
                  statusArray.includes(FirmaRequestStatus.SENT_TO_FIRMA) ||
                  statusArray.includes(FirmaRequestStatus.PENDING_SIGNATURE) ||
                  statusArray.includes(FirmaRequestStatus.SIGNED) ||
                  statusArray.includes(FirmaRequestStatus.SIGNED_WITH_AUDIT) ||
                  statusArray.includes(FirmaRequestStatus.DOCUMENT_CERTIFIED)
                ) {
                  disabledItems.push(FirmaContextMenuId.SEND_FIRMA)
                  disabledItems.push(FirmaContextMenuId.CERTIFY_DOCUMENT)
                }
              } else if (!folderOrDocument.status) {
                disabledItems.push(FirmaContextMenuId.MONITORING_REVIEW)
              }
            }
            break
          case EditContextMenuId.CUT:
          case EditContextMenuId.PASTE:
            if (folderOrDocument && !folderOrDocument.canDelete) {
              disabledItems.push(EditContextMenuId.CUT)
              disabledItems.push(EditContextMenuId.COPY)
            }
            if (folderOrDocument && !folderOrDocument.canRename) {
              disabledItems.push(EditContextMenuId.RENAME)
            }
            if (folderOrDocument && (!folderOrDocument.canCreateDocument || !folderOrDocument.canCreateTemplate)) {
              disabledItems.push(EditContextMenuId.PASTE)
            }
            if (this.isPortalUser) {
              item.id = 'hidden-cm-option'
            }
            if (this.pasteIsDisable()) {
              disabledItems.push(EditContextMenuId.PASTE)
            }
            break
          case SendContextMenuId.EMAIL:
            if (this.fileManagerInstance.getSelectedFiles().length === 0) {
              disabledItems.push(SendContextMenuId.EMAIL)
              break
            }
            this.fileManagerInstance.getSelectedFiles().forEach((element: any) => {
              if (!element.isFile) {
                disabledItems.push(SendContextMenuId.EMAIL)
              }
            })
            break
          case SendContextMenuId.PUBLISH:
            if (folderOrDocument && !folderOrDocument.canPublish) {
              disabledItems.push(SendContextMenuId.PUBLISH)
              disabledItems.push(SendContextMenuId.UNPUBLISH)
            }
            break
        }
      }

      if (disabledItems.length) {
        this.fileManagerInstance.disableMenuItems(disabledItems)
      }
    }

    pasteIsDisable() {
      const originalPasteButton = document.getElementById('file-manager_cm_paste')
      if (originalPasteButton && originalPasteButton.classList.contains('e-disabled')) {
        return true
      }
      return false
    }

    createEditMenu(item: MenuItemModel) {
      item.iconCss = Icons.EDIT
      item.text = this.$t('components.file_manager.actions.edit.title') as string
      item.id = EditContextMenuId.EDIT
      item.items = [
        {
          text: this.$t('components.file_manager.actions.edit.cut') as string,
          iconCss: Icons.CUT,
          id: EditContextMenuId.CUT
        },
        {
          separator: true
        },
        {
          text: this.$t('components.file_manager.actions.edit.copy') as string,
          iconCss: Icons.COPY,
          id: EditContextMenuId.COPY
        },
        {
          separator: true
        },
        {
          text: this.$t('components.file_manager.actions.edit.paste') as string,
          iconCss: Icons.PASTE,
          id: EditContextMenuId.PASTE
        },
        {
          separator: true
        },
        {
          text: this.$t('components.file_manager.actions.edit.rename') as string,
          iconCss: Icons.RENAME,
          id: EditContextMenuId.RENAME
        }
      ]
    }

    createEditLayoutMenu(item: MenuItemModel, isFile: boolean) {
      item.iconCss = Icons.EDIT
      item.text = this.$t('components.file_manager.actions.edit.title') as string
      item.id = EditContextMenuId.EDIT
      item.items = [
        {
          text: this.$t('components.file_manager.actions.edit.paste') as string,
          iconCss: Icons.PASTE,
          id: EditContextMenuId.PASTE
        }
      ]

      if (isFile) {
        item.items.splice(5, 2)
      }
    }

    createEditMultipleMenu(item: MenuItemModel, isFile: boolean) {
      item.iconCss = Icons.EDIT
      item.text = this.$t('components.file_manager.actions.edit.title') as string
      item.id = EditContextMenuId.EDIT
      item.items = [
        {
          text: this.$t('components.file_manager.actions.edit.cut') as string,
          iconCss: Icons.CUT,
          id: EditContextMenuId.CUT
        },
        {
          separator: true
        },
        {
          text: this.$t('components.file_manager.actions.edit.copy') as string,
          iconCss: Icons.COPY,
          id: EditContextMenuId.COPY
        }
      ]

      if (isFile) {
        item.items.splice(5, 2)
      }
    }

    createSendMenu(item: MenuItemModel) {
      item.iconCss = Icons.SHARE
      item.text = this.$t('components.file_manager.actions.send.title') as string
      item.id = SendContextMenuId.SEND
      item.items = [
        {
          text: this.$t('components.file_manager.actions.send.email') as string,
          iconCss: Icons.ATACH,
          id: SendContextMenuId.EMAIL
        },
        {
          separator: true
        },
        {
          text: this.$t('components.file_manager.actions.send.publish') as string,
          iconCss: Icons.PERIODIC_COM,
          id: SendContextMenuId.PUBLISH
        },
        {
          separator: true
        },
        {
          text: this.$t('components.file_manager.actions.send.unpublish') as string,
          iconCss: Icons.PERIODIC_COM,
          id: SendContextMenuId.UNPUBLISH
        }
      ]
    }

    createFirmaMenu(item: MenuItemModel) {
      item.iconCss = Icons.SIGNATURE_CERTIFICATE
      item.text = this.$t('components.file_manager.actions.firma.signature_and_certification') as string
      item.id = FirmaContextMenuId.FIRMA
      item.items = [
        {
          text: this.$t('components.file_manager.actions.firma.send_to_firma') as string,
          iconCss: Icons.SIGNATURE,
          id: FirmaContextMenuId.SEND_FIRMA
        },
        {
          separator: true
        },
        {
          text: this.$t('components.file_manager.actions.firma.certify_document') as string,
          iconCss: Icons.CERTIFIED_DOCUMENT,
          id: FirmaContextMenuId.CERTIFY_DOCUMENT
        },
        {
          separator: true
        },
        {
          text: this.$t('components.file_manager.actions.firma.send_certified_email') as string,
          iconCss: Icons.CERTIFIED_EMAIL,
          id: FirmaContextMenuId.SEND_CERTIFIED_EMAIL
        },
        {
          separator: true
        },
        {
          text: this.$t('components.file_manager.actions.firma.send_SMS_email') as string,
          iconCss: Icons.CERTIFIED_SMS,
          id: FirmaContextMenuId.SEND_CERTIFIED_SMS
        },
        {
          separator: true
        },
        {
          text: this.$t('components.file_manager.actions.firma.monitoring_and_audits') as string,
          iconCss: Icons.REVIEW,
          id: FirmaContextMenuId.MONITORING_REVIEW
        }
      ]
    }

    createLexnetMenu(item: MenuItemModel) {
      item.iconCss = Icons.LEXNET
      item.text = this.$t('components.file_manager.actions.lexnet.title') as string
      item.id = LexnetContextMenuId.LEXNET
      item.items = [
        {
          text: this.$t('components.file_manager.actions.lexnet.procedure') as string,
          iconCss: Icons.FORMS,
          id: LexnetContextMenuId.PROCEDURE
        },
        {
          separator: true
        },
        {
          text: this.$t('components.file_manager.actions.lexnet.subject') as string,
          iconCss: Icons.FORMS,
          id: LexnetContextMenuId.SUBJECT
        },
        {
          separator: true
        },
        {
          text: this.$t('components.file_manager.actions.lexnet.execution') as string,
          iconCss: Icons.FORMS,
          id: LexnetContextMenuId.EXECUTION
        },
        // {
        //   separator: true
        // },
        // {
        //   text: this.$t('components.file_manager.actions.lexnet.defense') as string,
        //   iconCss: Icons.FORMS,
        //   id: LexnetContextMenuId.DEFENSE
        // },
        {
          separator: true
        },
        {
          text: this.$t('components.file_manager.actions.lexnet.personation') as string,
          iconCss: Icons.FORMS,
          id: LexnetContextMenuId.PERSONATION
        }
        // {
        //   separator: true
        // },
        // {
        //   text: this.$t('components.file_manager.actions.lexnet.appeal') as string,
        //   iconCss: Icons.FORMS,
        //   id: LexnetContextMenuId.APPEAL
        // }
      ]
    }

    uploaderSelected(args: any) {
      let idx = args.filesData.length - 1

      while (idx >= 0) {
        const _filesData = args.filesData[idx]
        if (!_filesData || (_filesData.name === '' && _filesData.type === '')) {
          args.filesData.splice(idx, 1)
        }
        idx -= 1
      }

      if (args.filesData.length && !this.droppingFolders) {
        this.fileManagerInstance.uploadDialogObj.show()
      }
    }

    initInterface() {
      // this.refreshTokenInterval = setInterval(() =>
      //   store.dispatch(`${ModuleNamespaces.AUTH}/refreshToken`)
      //   , 900 * 1000
      // )
      this.fileManagerInstance.uploadObj.selected = this.uploaderSelected
      this.fetchFileManagerGridConfig().then(() => {
        setTimeout(() => {
          this.setFileManagerGridOrder()
          if (this.filenameSelected) {
            this.selectedItems.push(this.filenameSelected)
          }
        }, 500)
      })
    }

    onGridEvent(ev: Event) {
      let target: HTMLElement | null = null

      if (ev.target) {
        target = ev.target as HTMLElement
      }

        const clickClass = [
          'e-headercell',
          'e-headercelldiv',
          'e-headertext',
          'e-rhandler',
          'e-rhelper',
          'e-sortfilterdiv'
        ]

        if (target && clickClass.some((item) => target!.classList.contains(item))) {
          this.saveColumnsWidth(ev.target)
          this.saveListConfig()
        }
      }

      saveColumnsWidth(element: EventTarget | null) {
        const parsedElement = element as HTMLElement

        if (!parsedElement) {
          return
        }

        if (parsedElement.classList.contains('e-rhelper')) {
          const gridHeaderContent = parsedElement.parentNode!.querySelector('.e-gridheader .e-headercontent')
          const columns = gridHeaderContent!.querySelectorAll('.e-headercell')
          const colsData: HeaderColumn[] = Array.from(columns).slice(2).map((item) => ({
            index: Number(item.getAttribute('data-colindex')),
            width: (item as HTMLElement).offsetWidth
          }))

          this.updatedHeaderColumns = colsData
          return
        }

        const parent = parsedElement.parentNode as HTMLElement
        let columnData: HeaderColumn = { index: null, width: null }
        columnData.index = Number(parent.getAttribute('data-colindex'))
        columnData.width = parent.offsetWidth

        if (parent.classList.contains('e-headercelldiv')) {
          const grandParent = parent.parentNode as HTMLElement
          columnData.index = Number(grandParent.getAttribute('data-colindex'))
          columnData.width = grandParent.offsetWidth
        }

        if (parent.classList.contains('e-columnheader')) {
          columnData.index = Number(parsedElement.getAttribute('data-colindex'))
          columnData.width = parsedElement.offsetWidth
        }

        this.updatedHeaderColumns.push(columnData)
      }

    getStatusTemplate(): string {
      const statusTemplate = `
        \${if(isFile)}
          <div class="firma-column">
            \${if(firmaStatus===${FirmaRequestStatus.SENT_TO_FIRMA})}
              <span class=\"firma-status\">${this.$t('components.firma_status.send_to_firma')}</span>
            \${else}
              \${if(firmaStatus===${FirmaRequestStatus.PENDING_SIGNATURE})}
                <span class=\"firma-status\">${this.$t('components.firma_status.pending_signature')}</span>
              \${else}
                \${if(firmaStatus===${FirmaRequestStatus.SIGNED})}
                  <span class=\"firma-status\">${this.$t('components.firma_status.signed')}</span>
                \${else}
                  \${if(firmaStatus===${FirmaRequestStatus.SIGNED_WITH_AUDIT})}
                    <span class=\"firma-status\">${this.$t('components.firma_status.signed_with_audit')}</span>
                  \${else}
                    \${if(firmaStatus===${FirmaRequestStatus.CANCEL})}
                      <span class=\"firma-status\">${this.$t('components.firma_status.cancel')}</span>
                    \${else}
                      \${if(firmaStatus===${FirmaRequestStatus.DOCUMENT_CERTIFIED})}
                        <span class=\"firma-status\">${this.$t('components.firma_status.certified')}</span>
                      \${else}
                        <span class=\"firma-status\"></span>
                      \${/if}
                    \${/if}
                  \${/if}
                \${/if}
              \${/if}
            \${/if}
            \${if(firmaMailOrSms)}
              <span class="firma-certified lf-icon-certificate"></span>
            \${else}
            \${/if}
          </div>
        \${else}
        \${/if}`
      return statusTemplate
    }

    setTemplateForStatusColumn() {
      if (this.detailsViewSettingsDocument && this.detailsViewSettingsDocument.columns) {
        this.detailsViewSettingsDocument.columns = this.detailsViewSettingsDocument.columns.map((column: ColumnModel) => {
          if (column.field === 'status') {
            column.template = this.getStatusTemplate()
          }
          return column
        })
      }
    }

    async fetchFileManagerGridConfig() {
      try {
        await this.fetchCurrentListConfiguration(this.listName)
        if (this.folderType === fileManagerFolderType.DOCUMENT) {
          this.detailsViewSettingsDocument = JSON.parse((this as any).listConfiguration['Config'])
        } else {
          this.detailsViewSettingsAll = JSON.parse((this as any).listConfiguration['Config'])
        }
        if (this.detailsViewSettings && this.detailsViewSettings.openMSOffice) {
          this.openMSOfficeConfigMode = this.detailsViewSettings.openMSOffice
        }
        this.setTemplateForStatusColumn()
        this.configSafari()
      } catch (error) {
      }
    }

    setFileManagerGridOrder() {
      if (
        !this.fileManagerInstance ||
        !this.fileManagerInstance.detailsviewModule ||
        !this.fileManagerInstance.detailsviewModule.gridObj ||
        !this.fileManagerInstance.detailsviewModule.gridObj.sortModule
      ) {
        return
      }

      const userSort: any = this.detailsViewSettings!.columns!.find((item: any) => {
        return item.sortDirection
      })

      if (userSort) {
        this.sortBy = userSort.field
        this.sortOrder = userSort.sortDirection
      }

      this.checkIfRenderDescendingIcon(this.sortOrder)
    }

    async checkIfRenderDescendingIcon(sortDirection: string) {
      await this.$nextTick()
      if (sortDirection === 'Descending') {
        const ascendingIcon = this.$el.querySelector('.e-ascending')
        if (ascendingIcon) {
          ascendingIcon.classList.add('e-descending', 'e-icon-descending')
          ascendingIcon.classList.remove('e-ascending', 'e-icon-ascending')
        }
      }
    }

    onToolbarClick(toolBar: any) {
      if (toolBar.item.id.includes('_tb_details') && toolBar.fileDetails.length) {
        if (1 === toolBar.fileDetails.length) {
          const selected = toolBar.fileDetails[0]
          if (selected.isFile) {
            if (!this.isTemplateMode) {
              this.editMode = EditDocumentMode.uploadEdit
              this.openDocumentDetail(selected.id)
            } else {
              this.saveTemplate(selected)
              this.openTemplateForm()
            }
          }
        } else {
          this.showAlertResult(this.$t('components.file_manager.advises.only_one_file_selected'), AlertsTypes.ERROR)
        }
      }

      if (toolBar.item.id.includes('_tb_actions')) {
        const btn: null | HTMLElement = document.getElementById(toolBar.item.id)
        if (null !== btn) {
          let contextMenuId = 'action-add-document-context-menu'

          if (this.isTemplateMode) {
            contextMenuId = 'action-add-template-context-menu'
          }

          const rect: DOMRect | ClientRect = btn.getBoundingClientRect()
          const elem: any = document.getElementById(contextMenuId)
          if (null !== elem) {
            elem.ej2_instances[0].open(rect.top + 45, rect.left + 20)
          }
        }
      }

      if (toolBar.item.id.includes('_tb_open')) {
        const selectedFileObject = {
          cancel: false,
          module: 'DetailsView',
          name: 'fileOpen',
          fileDetails: toolBar.fileDetails[0]
        }
        this.onFileOpen(selectedFileObject)
      }

      if (toolBar.item.id.includes('_tb_preview')) {
        let id = 0
        let type = DocumentPreviewType.Unknown
        if (toolBar.fileDetails[0] && this.fileManagerInstance.selectedItems.length === 1) {
          id = toolBar.fileDetails[0].id
          type = toolBar.fileDetails[0].type
        }
        this.togglePreview({ id, type } as DocumentPreview)
      }

      if (toolBar.item.id.includes('_tb_configuration')) {
        const btn: null | HTMLElement = document.getElementById(toolBar.item.id)
        if (null !== btn) {
          const contextMenuId = 'action-msoffice-configuration-context-menu'
          const rect: DOMRect | ClientRect = btn.getBoundingClientRect()
          const elem: any = document.getElementById(contextMenuId)
          if (null !== elem) {
            elem.ej2_instances[0].open(rect.top + 45, rect.left + 20)
          }
        }
      }
    }

    onActionAddClick(args: any) {
      switch (args.item.text) {
        case this.$t('components.file_manager.actions.new_folder'):
          this.fileManagerInstance.createFolder()
          break
        case this.$t('components.file_manager.actions.new_word_document'):
          this.setDocumentOnlineAction(documentOnlineActions.NEW)
          this.editMode = EditDocumentMode.wordOnlineNew
          this.openDocumentDetail()
          break
        case this.$t('components.file_manager.actions.new_excel_document'):
          this.setDocumentOnlineAction(documentOnlineActions.NEW)
          this.editMode = EditDocumentMode.excelOnlineNew
          this.openDocumentDetail()
          break
        case this.$t('components.file_manager.actions.new_ppoint_document'):
          this.setDocumentOnlineAction(documentOnlineActions.NEW)
          this.editMode = EditDocumentMode.powerPointOnlineNew
          this.openDocumentDetail()
          break
        case this.$t('components.file_manager.actions.from_template_document'):
          this.showTemplateDialog = this.checkIfOnlyTemplate()
          break
        case this.$t('components.file_manager.actions.upload_lexon_document'):
          this.editMode = EditDocumentMode.uploadNew
          this.openDocumentDetail()
          break
        case this.$t('components.file_manager.actions.new_template'):
          this.editMode = EditDocumentMode.templateNew
          this.openTemplateForm()
          break
      }
    }

    onOpenMSOfficeConfigClick(args: any) {
      switch (args.item.text) {
        case this.$t('components.file_manager.actions.config.local'):
          this.openMSOfficeConfigMode = OpenMSOffice.LOCAL
          break
        case this.$t('components.file_manager.actions.config.wopi'):
          this.openMSOfficeConfigMode = OpenMSOffice.WOPI
          break
        default:
          break
      }
      this.saveListConfig()
    }

    checkIfOnlyTemplate(): boolean {
      if ((this.tree && this.tree.length === 0) || this.tree.length > 1) {
        return true
      }
      const template: ITemplate = this.tree[0]
      if (!template.isDirectory) {
        this.generateTemplateDialog(template)
        return false
      }
      return true
    }

    onToolbarCreate(toolBar: ToolbarCreateEventArgs) {
      if (toolBar.items && Array.isArray(toolBar.items)) {
        toolBar.items.forEach((item: any) => {
          item.align = 'Right'
          item.overflow = 'Show'
          item.showTextOn = 'Overflow'
          item.cssClass = 'e-tbtn-align'

          if (item.id.includes('_tb_preview')) {
            item.prefixIcon = 'e-icons lf-icon-visible main-preview-button'
            item.tooltipText = this.$t('components.file_manager.tooltip.preview')
            item.cssClass = 'e-tbtn-align'
          }

          if (item.id.includes('_tb_selection')) {
            item.cssClass = 'lf-selection-item e-tbtn-align'
          }

          if (item.id.includes('_tb_details')) {
            item.cssClass = 'first-item e-tbtn-align e-overlay'
          }

          if (item.id.includes('_tb_actions')) {
            item.prefixIcon = 'e-icons e-fe-actions'
            item.tooltipText = this.$t('components.file_manager.tooltip.moreActions')
          }

          if (item.id.includes('_tb_open')) {
            item.cssClass = `e-tbtn-align e-overlay ${this.checkIfTablet ? '' : 'e-hidden'}`
            item.prefixIcon = 'e-icons e-fe-open'
            item.tooltipText = this.$t('components.file_manager.tooltip.open')
          }

          if (item.id.includes('_tb_configuration')) {
            item.cssClass = 'last-item e-tbtn-align'
            item.prefixIcon = 'e-icons e-fe-configuration'
            item.tooltipText = this.$t('components.file_manager.tooltip.edit')
          }
        })
      }
    }

    onBeforeOpenContextMenu() {
      if (!this.cwd || !this.contextMenu) {
        return null
      }

      const contextMenu = this.hasCompanyTemplate
        ? [
            this.$t('components.file_manager.actions.new_folder').toString(),
            this.$t('components.file_manager.actions.new_template').toString(),
            this.$t('components.file_manager.actions.new_word_document').toString(),
            this.$t('components.file_manager.actions.new_excel_document').toString(),
            this.$t('components.file_manager.actions.new_ppoint_document').toString(),
            this.$t('components.file_manager.actions.upload_lexon_document').toString(),
            this.$t('components.file_manager.actions.from_template_document').toString()
          ]
        : [
            this.$t('components.file_manager.actions.new_folder').toString(),
            this.$t('components.file_manager.actions.new_word_document').toString(),
            this.$t('components.file_manager.actions.new_excel_document').toString(),
            this.$t('components.file_manager.actions.new_ppoint_document').toString(),
            this.$t('components.file_manager.actions.upload_lexon_document').toString(),
            this.$t('components.file_manager.actions.from_template_document').toString()
          ]

      this.contextMenu.enableItems(contextMenu, true)

      if (!this.cwd.canCreateFolder) {
        this.contextMenu.enableItems([this.$t('components.file_manager.actions.new_folder').toString()], false)
      }

      if (!this.cwd.canCreateTemplate && this.hasCompanyTemplate) {
        this.contextMenu.enableItems(
          [
            this.$t('components.file_manager.actions.new_template').toString(),
            this.$t('components.file_manager.actions.from_template_document').toString()
          ],
          false
        )
      }
      if (!this.cwd.canCreateDocument) {
        this.contextMenu.enableItems(
          [
            this.$t('components.file_manager.actions.new_word_document').toString(),
            this.$t('components.file_manager.actions.new_excel_document').toString(),
            this.$t('components.file_manager.actions.new_ppoint_document').toString(),
            this.$t('components.file_manager.actions.upload_lexon_document').toString(),
            this.$t('components.file_manager.actions.from_template_document').toString()
          ],
          false
        )
      }
    }

    onBeforeOpenMSOfficeConfigContextMenu(args: any) {
      if (this.openMSOfficeConfigMode === OpenMSOffice.WOPI) {
        args.items[0].iconCss = 'e-empty-icon'
        args.items[2].iconCss = 'lf-icon-check'
      }
      if (this.openMSOfficeConfigMode === OpenMSOffice.LOCAL) {
        args.items[0].iconCss = 'lf-icon-check'
        args.items[2].iconCss = 'e-empty-icon'
      }
    }

    onFileSelect(args: any) {
      if (this.lastFileManagerOper !== fileManagerOpers.SEARCH) {
        if (this.fileManagerInstance.getSelectedFiles().length > 1) {
          this.contextMenuSettings = this.contextMenuSettingsMultiple
        } else {
          this.contextMenuSettings = this.contextMenuSettingsSingle
        }
        this.fileManagerInstance.disableToolbarItems(['Open'])
        this.fileManagerInstance.disableToolbarItems(['Details'])
        if (this.fileManagerInstance.getSelectedFiles().length === 1 && args.fileDetails.isFile) {
          this.fileManagerInstance.enableToolbarItems(['Open'])
          this.fileManagerInstance.enableToolbarItems(['Details'])
        }
        if (args.fileDetails && args.fileDetails.length && 1 === args.fileDetails.length && args.fileDetails[0].isFile) {
          this.fileManagerInstance.enableToolbarItems(['Details'])
        }
      } else if (fileManagerOpers.SELECT === args.action && args.fileDetails && 1 === args.fileDetails.length) {
        this.foundFolder = args.fileDetails[0]
      }

      if (fileManagerOpers.SELECT === args.action) {
        this.refreshPreview({
          id: Number(args.fileDetails.id),
          type: args.fileDetails.type
        })
      }

      if (fileManagerOpers.UNSELECT === args.action && 1 === this.fileManagerInstance.selectedItems.length) {
        this.refreshPreview({
          id: Number(this.fileManagerInstance.itemData[0].id),
          type: this.fileManagerInstance.itemData[0].type
        })
      }

      if (fileManagerOpers.UNSELECT === args.action && 0 === this.fileManagerInstance.selectedItems.length) {
        this.refreshPreview({
          id: 0,
          type: DocumentPreviewType.Unknown
        })
      }

      this.checkSignaturitState(args)
    }

    onFileOpen(args: any) {
      this.resetSignaturitStates()
      if ('DetailsView' === args.module && 'fileOpen' === args.name && args.fileDetails && args.fileDetails.isFile) {
        if (['.tif', '.tiff'].includes(args.fileDetails.type.toString().toLowerCase())) {
          args.cancel = true
        }
        this.actionOpenFile(args.fileDetails)
      }
    }

    resetSignaturitStates() {
      this.signPending = []
      this.inSignaturit = []
    }

    checkSignaturitState(args: any) {
      const item = args.fileDetails.length !== undefined ? args.fileDetails[0] : args.fileDetails
      this.firmaStatusDoc = item.firmaStatus
      if (item.isFile && item.firmaStatus !== null) {
        if (item.firmaStatus === FirmaRequestStatus.PENDING_SIGNATURE) {
          fileManagerOpers.SELECT === args.action
            ? this.signPending.push(item.id)
            : (this.signPending = this.signPending.filter((f) => f !== item.id))
        } else if (item.firmaStatus >= FirmaRequestStatus.SENT_TO_FIRMA) {
          fileManagerOpers.SELECT === args.action
            ? this.inSignaturit.push(item.id)
            : (this.inSignaturit = this.inSignaturit.filter((f) => f !== item.id))
        }
      } else if (item.firmaMailOrSms) {
        fileManagerOpers.SELECT === args.action
          ? this.inSignaturit.push(item.id)
          : (this.inSignaturit = this.inSignaturit.filter((f) => f !== item.id))
      }
    }

    onFileManagerBeforeDialogOpen(args: any) {
      if ('Delete' === args.popupName) {
        args.popupModule.element.classList.add('lf-dialog-delete')
        args.popupModule.element.classList.remove('lf-dialog-error')
        args.popupModule.dlgContainer.querySelector('.e-dlg-header').innerText = this.$t('components.dialog.type.info')
        args.popupModule.dlgContainer.querySelector('.e-dlg-content').innerHTML = args.popupModule.properties.content
        if (this.inSignaturit.length > 0) {
          args.popupModule.dlgContainer.querySelector('.e-dlg-content').innerText = this.$t(
            'components.firma_dialogs.warning_delete_in_signaturit'
          )
        }
        if (this.signPending.length > 0) {
          args.popupModule.element.classList.add('lf-dialog-error')
          args.popupModule.element.classList.remove('lf-dialog-delete')
          args.popupModule.dlgContainer.querySelector('.e-dlg-header').innerText = this.$t('components.dialog.type.error')
          args.popupModule.dlgContainer.querySelector('.e-footer-content .e-primary').remove()
          args.popupModule.dlgContainer.querySelector('.e-footer-content .e-btn').innerText =
            this.$t('action_buttons.accept')
          args.popupModule.dlgContainer.querySelector('.e-dlg-content').innerText = this.$t(
            'components.firma_dialogs.warning_delete_sign_pending'
          )
        }
        args.popupModule.element.style.width = '700px'
      } else if ('Error' === args.popupName) {
        args.popupModule.element.classList.add('lf-dialog-error')
        args.popupModule.element.classList.remove('lf-dialog-delete')
        args.popupModule.element.style.width = '700px'
      } else {
        args.popupModule.element.classList.remove('lf-dialog-delete')
        args.popupModule.element.classList.remove('lf-dialog-error')
        args.popupModule.element.style.width = '350px'
      }
    }

    getBasePathFolder(basePath: string): string {
      const parts = basePath.split('/')
      parts.pop()
      return parts.join('/')
    }

    async openFolderDetail(folderId: number) {
      this.idDocument = folderId ? Number(folderId) : 0
      let document = {}

      this.saveFileManagerItem({
        id: folderId,
        isFolder: true
      })

      await this.fetchFolder(folderId)
      await this.$nextTick()
      document = this.document

      this.documentBasePath = this.currentBasePath

      await this.saveSelectedRegisterData({
        selectedRegisterData: document,
        context: ContextName.DOCUMENT
      })
      this.showDocumentDetail = false
      this.showFolderDetail = true
      await this.$nextTick()
      this.everythingLoaded = true
      this.$emit('openForm')
    }

    async openDocumentDetail(documentId = '') {
      this.idDocument = documentId ? Number(documentId) : 0

      this.saveSelectedRegisterId({
        id: documentId,
        context: ContextName.DOCUMENT
      })

      this.saveFileManagerItem({
        id: documentId,
        isFolder: false
      })

      let document = {}

      if (documentId) {
        await this.fetchDocument(documentId)
        await this.$nextTick()
        document = this.document
      } else {
        this.saveDocument(document)
      }

      this.documentBasePath = this.currentBasePath

      await this.saveSelectedRegisterData({
        selectedRegisterData: document,
        context: ContextName.DOCUMENT
      })

      this.showDocumentDetail = true
      await this.$nextTick()
      this.everythingLoaded = true
      this.$emit('openForm')
    }

    onCloseDocumentDetail() {
      if (this.showFolderDetail === true) {
        this.showFolderDetail = false
        if (this.fileManagerInstance.activeModule === 'navigationpane') {
          let parentPath = this.fileManagerInstance.path.split('/')
          parentPath.splice(parentPath.length - 2, 1)
          parentPath = parentPath.join('/')
          this.fileManagerInstance.path = parentPath
        } else {
          this.fileManagerInstance.refresh()
        }
      }
      if (this.showDocumentDetail === true) {
        this.showDocumentDetail = false
        this.fileManagerInstance.refresh()
      }
      this.$emit('closeForm')
    }

    onSaveDocumentOk(file: any) {
      if (file) {
        setTimeout(() => {
          this.openMSOfficeConfigMode === OpenMSOffice.WOPI ? this.editDocumentOnline(file) : this.openMSOfficeLocal(file)
        }, 1000)
        this.showSuccessAlert(fileManagerOpers.UPDATE, 1)
        this.onCloseDocumentDetail()
      } else {
        this.showSuccessAlert(fileManagerOpers.UPDATE, 1)
        this.onCloseDocumentDetail()
      }
    }

    onSaveDocumentFail() {
      this.showErrorAlert(fileManagerOpers.UPDATE, 1)
    }

    onSaveIntervenerOk(isEditMode: boolean) {
      if (isEditMode) {
        this.showSuccessAlert(fileManagerOpers.UPDATE_INTERVENER, 1)
      } else {
        this.showSuccessAlert(fileManagerOpers.CREATE_INTERVENER, 1)
      }
    }

    onSaveIntervenerFail(isEditMode: boolean) {
      if (isEditMode) {
        this.showErrorAlert(fileManagerOpers.UPDATE_INTERVENER, 1)
      } else {
        this.showErrorAlert(fileManagerOpers.CREATE_INTERVENER, 1)
      }
    }

    async onDocumentRemoveAction() {
      this.showDocumentDetail = false
      await this.$nextTick()
      this.fileManagerInstance.deleteFiles(this.fileManagerInstance.selectedItems)
    }

    onFileManagerCreated() {}

    refresh() {
      this.fileManagerInstance.refresh()
    }

    mounted() {
      this.fileManagerInstance = (this as any).$refs.fileManager.ej2Instances
      this.initInterface()
      if (ContextName.EXPEDIENTS.toString() !== this.context) {
        this.hideStageField()
      }
      this.hideSpinnerLayerAction()
      this.permissionOnShortCuts()
    }

    permissionOnShortCuts() {
      const { canSave, canDelete } = this.checkEntityPermissionsGetter(this.permissionsEntity)
      if (canSave && canDelete) {
        return
      }

      this.$refs.fileManager.$el.addEventListener('keydown', (args: KeyboardEvent) => {
        const { ctrlKey, metaKey, code } = args
        const isCtrlKey = ctrlKey || metaKey ? true : code === 'ControlRight' || code === 'ControlLeft'

        const arrayKeyCodes = ['KeyV', 'KeyC', 'KeyX']
        if (!canSave && isCtrlKey && arrayKeyCodes.includes(code)) {
          this.$refs.fileManager.clearSelection()
        }

        if (!canDelete && code === 'Delete') {
          this.$refs.fileManager.clearSelection()
        }
      })
    }

    created() {
      if (Object.keys(this.$route.query).length > 0 && !this.$route.query.action) {
        if (this.$route.query.fileId) {
          this.openBlobFile(Number(this.$route.query.fileId), false)
        }

        if (this.initialPath && this.initialBasePath) {
          this.path = this.initialPath
        } else if (this.$route.query.path) {
          const path: string = this.$route.query.path.toString()
          this.path = atob(decodeURI(path))
        }

        if (this.$route.query.fileName) {
          this.filenameSelected = this.$route.query.fileName as string
          this.selectedItems.push(this.filenameSelected)
        }

        this.$router.replace({
          ...this.$router.currentRoute,
          params: this.$router.currentRoute.params,
          name: this.$router.currentRoute.name || '',
          query: {}
        })
      }

      this.fetchMaxFileUploadSize()
      this.fetchAllowedExtensions(this.mode)
    }

    beforeDestroy() {
      /* TODO- Este código lo comenté para solucionar la PLEXON-7192 el 18=05/23.
        Aparentemente no es necesario guardar al desmontar.
        ELiminar si finalmente QA no sacá ninguna incidencia */
      // this.saveListConfig()
      clearInterval(this.refreshTokenInterval)
      this.resetParentRegisterId()
      // remove EventListener for permissions
      this.$refs.fileManager.$el.removeEventListener('keydown', () => {})
    }

    saveListConfig() {
      setTimeout(async () => {
        await this.prepareListConfigToSave(this.detailsViewSettings)
      })
    }

    setSortAfterChangeValues(data: any) {
      if (typeof data.Config.columns !== 'undefined') {
        const userSort: any = data.Config.columns.find((item: any) => {
          return item.sortDirection
        })

        if (userSort) {
          this.sortBy = userSort.field
          this.sortOrder = userSort.sortDirection
        }
      }
    }

    prepareListConfigToSave(list: any) {
      const gridObj = this.fileManagerInstance.detailsviewModule.gridObj

      if (gridObj && list) {
        list.columns.map((column: any) => {
          if (column.field === gridObj.sortModule.lastSortedCol) {
            column.sortDirection = gridObj.sortModule.direction
          } else {
            delete column.sortDirection
          }
          const columnUser = gridObj.columnModel.find((item: any) => {
            return item.field === column.field
          })

            column.width = columnUser.width || 100
        })

          list.openMSOffice = this.openMSOfficeConfigMode

    // Actualiza el ancho de las columnas al guardar
    if (this.updatedHeaderColumns.length) {
      this.updatedHeaderColumns.forEach((column: HeaderColumn) => {
        if (column.index) {
          const adjustedIndex = column.index - 2
          list.columns[adjustedIndex].width = column.width
        }
      })

      this.updatedHeaderColumns = []
    }

        const dataToSend = {
          IdUser: this.idUser,
          Alias: this.listName,
          Config: list
        }
        this.setSortAfterChangeValues(dataToSend)
        this.saveListConfiguration(dataToSend)
      }
    }

    configSafari() {
      if (isSafari()) {
        this.detailsViewSettings!.columns!.map((column: any) => {
          if ('dateCreated' === column.field || 'dateModified' === column.field) {
            column.type = ''
          }
        })
      }
    }

    hideStageField() {
      const stageField = this.$el.querySelector('#idStage') as HTMLElement | null

      if (null !== stageField) {
        stageField.style.display = 'none'
      }
    }

    async onShowForm() {
      await this.$nextTick()
      this.toggleStageField()
    }

    toggleStageField() {
      if (!this.showDocumentDetail) {
        return true
      }

      if (ContextName.EXPEDIENTS.toString() !== this.context) {
        this.hideStageField()
        return true
      }

      const stageField = this.$el.querySelector('#idStage') as HTMLElement | null

      if (null !== stageField) {
        if (this.expedientHasStagesActive) {
          stageField.style.display = 'inherit'
        } else {
          stageField.style.display = 'none'
        }
      }
    }

    onDocumentInitForm(formData: any) {
      const isNew = !this.idDocument

      if (isNew) {
        formData[fileManagerFields.STAGE] = this.idStage ? this.idStage : null
      }
    }

    onCancelUploadFolders() {
      this.showSuccessAlert(fileManagerOpers.CANCEL_UPLOAD_FOLDERS, 0)
    }

    onFoldersUploadOk() {
      this.showSuccessAlert(fileManagerOpers.FINISH_UPLOAD_FOLDERS, 0)
    }

    onDropToZipNotSupported() {
      this.showDialog({
        type: DialogTypes.WARNING,
        message: this.$t('components.file_manager.error_messages.cant_upload_from_zip'),
        hideSecondaryButton: true
      })
      this.fileManagerInstance.refresh()
    }

    downloadAsZipPrompt(itemSel: any) {
      this.openedSaveAsZipDialog = true
      this.zipFolderSelected = [itemSel]
    }

    executeSaveAsZipDialogAction(actionName: string) {
      switch (actionName) {
        case ActionName.CLOSE:
          return (this.openedSaveAsZipDialog = false)
        case ActionName.SAVE:
          return this.saveAsZip()
      }
    }

    async saveAsZip() {
      const name = (this.zipFormData as any).name

      this.openedSaveAsZipDialog = false

      this.showFileManagerSpinner(this.spinnerText.receiving)

      await this.downloadZipResult({
        files: JSON.stringify(
          this.fileManagerInstance.getSelectedFiles().length > 0
            ? this.fileManagerInstance.getSelectedFiles()
            : this.zipFolderSelected
        ),
        name
      })

      if (this.zipResult && this.zipResult.zip!.length) {
        await this.downloadAsZip(this.zipResult.zip)

        this.hideFileManagerSpinner()

          let downloadFileName = entityToHtml(name)
          downloadFileName = `${downloadFileName}.zip`
          const blobUrl = URL.createObjectURL(this.blobFile)
          createLink(blobUrl, downloadFileName)
        } else {
          this.hideFileManagerSpinner()
        }
        this.setDownloadZipResultMessage(this.zipResult)
      }

    setDownloadZipResultMessage(zipResult: DownloadZipResult | null) {
      if (!zipResult || !zipResult.zip || zipResult.zip === '') {
        this.showErrorAlert(fileManagerOpers.DOWNLOAD_AS_ZIP, 0)
        return
      }

      if (zipResult && 0 === zipResult.wrongFiles.length) {
        this.showSuccessAlert(fileManagerOpers.DOWNLOAD_AS_ZIP, 0)
        return
      }

      if (zipResult && zipResult.wrongFiles.length) {
        let wrongFiles = '<ul class="zip-wrong-files">'
        zipResult.wrongFiles.map((file: string) => {
          wrongFiles += `<li>${file}</li>`
        })
        wrongFiles += '</ul>'

        this.showDialog({
          type: DialogTypes.WARNING,
          message: this.$t('components.file_manager.error_messages.download_zip_errors', { wrongFiles }),
          hideSecondaryButton: true
        })
      }
    }

    closeTemplateDialog() {
      this.showTemplateDialog = false
    }

    async generateTemplateDialog(templateSelected: any) {
      const filter = {
        templateName: templateSelected.templateName,
        idEntityOrigin: templateSelected.idEntity,
        name: templateSelected.name,
        folderTargetPath: this.currentBasePath,
        idEntity: this.idEntity,
        idEntityType: this.idEntityType,
        ...(this.idStage && { idStage: this.idStage })
      }
      const data: any = await this.generateTemplate(filter)
      this.showTemplateDialog = false
      this.refresh()
      if (data && data.id !== null) {
        this.showAlertResult(this.$t('components.file_manager.advises.template_created'), AlertsTypes.SUCCESS)
      } else {
        this.showAlertResult(this.$t('components.file_manager.advises.template_error'), AlertsTypes.ERROR)
      }
    }

    async downloadAsPdf(file: any) {
      this.showFileManagerSpinner(this.spinnerText.receiving)

      await this.downloadFile(file.id)

      if (!this.blobFile) {
        this.hideFileManagerSpinner()
        this.showErrorAlert(fileManagerOpers.CONVERT_TO_PDF, 0)
        return
      }
      const { data } = await new SyncfusionService().exportToPdf(this.blobFile)
      file = this.setupDocumentToInsert(file)
      const blobUrl = URL.createObjectURL(data)
      createLink(blobUrl, file.ArchivoInicial, true)
      await this.insertDocument({
        document: JSON.stringify(file),
        fileData: data
      })
      await this.$nextTick()
      this.fileManagerInstance.refresh()
      this.hideFileManagerSpinner()
    }
    setupDocumentToInsert(file: any): any {
      file.ArchivoInicial = `${file.originalFileName.substr(0, file.originalFileName.lastIndexOf('.'))}.pdf`
      const pos = file.name.lastIndexOf('.')
      file.name = `${file.name.substr(0, pos < 0 ? file.name.length : pos)}.pdf`
      file.fileName = `${file.title} (PDF)`
      file.basePath = this.currentBasePath
      file.id = 0
      return file
    }
    isTransformableToPdf(file: any) {
      return process.env.VUE_APP_SYNCFUSION_PDF_CONVERT_VALID_EXTENSIONS.includes(file.type.toString().toLowerCase())
    }
    isMSOfficeFile(file: any) {
      const msOfficeExtensions = ['.doc', '.docx', '.xls', '.xlsx', '.ppt', '.pptx']
      return msOfficeExtensions.includes(file.type)
    }
    showFileManagerSpinner(text: TranslateResult) {
      this.spinnerText.actual = text
      this.fileManagerSpinner = true
    }
    hideFileManagerSpinner() {
      this.fileManagerSpinner = false
    }
    hideOverlay() {
      const dragOverElement = this.fileManagerInstance.element.querySelector('.e-upload-drag-hover')
      if (dragOverElement) {
        dragOverElement.classList.remove('e-upload-drag-hover')
      }
    }
  }
  </script>
  <style lang="scss">
  @import '~@syncfusion/ej2-base/styles/material.css';
  @import '~@syncfusion/ej2-icons/styles/material.css';
  @import '~@syncfusion/ej2-inputs/styles/material.css';
  @import '~@syncfusion/ej2-popups/styles/material.css';
  @import '~@syncfusion/ej2-buttons/styles/material.css';
  @import '~@syncfusion/ej2-splitbuttons/styles/material.css';
  @import '~@syncfusion/ej2-layouts/styles/material.css';
  @import '~@syncfusion/ej2-grids/styles/material.css';
  @import '~@syncfusion/ej2-vue-filemanager/styles/material.css';
  @import '~@syncfusion/ej2-pdfviewer/styles/material.css';

  @include public;

  .e-address {
    .e-address-list-item {
      text-overflow: ellipsis;
      overflow: hidden;
      max-width: 190px;
      white-space: nowrap;
      vertical-align: bottom;
    }
  }

  .e-filemanager {
    border: none;
  }

  .file-manager-message {
    font-family: $corporate-font-bold;
    font-size: 25px;
    color: $corporate-color;
  }

  /* Set border color for component */

  .e-filemanager div,
  .e-filemanager,
  .e-filemanager #file-manager_layout,
  .e-filemanager .e-address {
    border-color: $corporate-color;
  }

  .e-filemanager .e-splitter .e-split-bar.e-split-bar-horizontal {
    background-color: $corporate-color;
  }

  .e-splitter .e-split-bar.e-split-bar-horizontal.e-resizable-split-bar::after {
    width: 5px;
  }

  .e-filemanager .e-toolbar {
    border: none !important;
  }

  #file-manager_layout {
    border: 1px solid;
  }

  /* toolbar */
  .e-filemanager .e-toolbar .e-toolbar-items {
    background: $white-01;
  }

  .e-filemanager .e-toolbar-right {
    top: 4px !important;
    margin-right: 10px;

    .e-tbar-btn {
      height: 43px !important;
      background-color: transparent !important;
    }

    .e-fe-clear::before {
      color: $corporate-color;
    }
  }

  #file-manager_tb_sortby .e-icon-right.e-caret {
    display: none;
  }

  .file-manager-container .e-filemanager .e-toolbar-item:not(.lf-selection-item) .e-tbar-btn .e-btn-icon {
    font-family: $lf-icons-font;
    background-image: none;
    vertical-align: top;
    color: $white-01;
    font-size: 19px;
  }

  .e-filemanager .e-toolbar-items .e-fe-details:before {
    content: '\e973';
  }

  .e-filemanager .e-toolbar-items .e-fe-sort:before,
  .e-filemanager .e-toolbar-items .e-fe-sortfiles:before {
    content: '\eaa6';
  }

  .e-filemanager .e-toolbar-items .e-fe-large:before {
    content: '\e908';
  }

  .e-filemanager .e-toolbar-items .e-fe-grid:before {
    content: '\e992';
  }

  .e-filemanager .e-toolbar-items .e-fe-refresh:before {
    content: '\e9c9';
  }

  .e-filemanager .e-toolbar-items .e-fe-actions:before {
    content: '\e9e2';
  }

  .e-filemanager .e-toolbar-items .e-fe-open:before {
    content: '\e9b3';
  }

  .e-filemanager .e-toolbar-items .e-fe-configuration:before {
    content: '\e93d';
  }

  .e-filemanager .e-toolbar-items .last-item ~ .e-toolbar-item {
    display: none;
  }

  .e-filemanager .e-toolbar .e-toolbar-items .e-toolbar-item:not(.lf-selection-item) .e-tbar-btn.e-btn:hover,
  .e-filemanager .e-toolbar .e-toolbar-items .e-toolbar-item:not(.lf-selection-item) .e-tbar-btn.e-btn.e-active {
    border-radius: 50px;
    width: 43px;
    height: 43px;
    border-color: rgba(0, 25, 120, 0.2);
  }

  .e-filemanager .e-toolbar .e-tbtn-align.lf-selection-item,
  .e-filemanager .e-toolbar .e-tbtn-align.lf-selection-item .e-tbar-btn {
    background-color: $white-01;
  }

  .e-toolbar.e-toolpop .e-toolbar-items .e-toolbar-item.e-popup-text.lf-selection-item .e-tbar-btn-text {
    display: inline-flex;
  }

  .e-filemanager .e-toolbar .lf-selection-item .e-btn-icon {
    display: inline-flex;
    background-color: transparent;
    color: $gray-01;
    width: 25px !important;
  }

  .e-filemanager .e-toolbar .lf-selection-item .e-btn-icon:hover {
    border-color: $white-01 !important;
  }

  /* toolbar: view */
  #file-manager_tb_view-popup {
    border: 1px solid $corporate-color;
  }

  #file-manager_tb_view-popup ul {
    padding: 5px;
    color: $corporate-color;
  }

  #file-manager_ddl_large {
    padding-right: 40px;
    border-bottom: 1px solid;
  }

  #file-manager_tb_view-popup span.e-icons.e-menu-icon {
    vertical-align: baseline;
  }

  .e-fe-popup.e-dropdown-popup ul .e-item .e-fe-tick,
  .e-dropdown-popup ul .e-item .e-menu-icon {
    color: $corporate-color;
  }

  .e-dropdown-popup ul .e-item:hover {
    color: $white-01;
    background-color: $corporate-color;
  }

  .e-dropdown-popup ul .e-item:hover .e-menu-icon,
  .e-dropdown-popup ul .e-item .e-menu-icon:hover {
    color: $white-01 !important;
  }

  .e-filemanager .e-toolbar-items {
    background: $white-01;
  }

  #file-manager_tb_sortby .e-icon-right.e-caret {
    display: none;
  }

  .e-filemanager .e-toolbar-item:not(.lf-selection-item) .e-tbar-btn .e-btn-icon {
    font-family: $lf-icons-font;
    background-image: none;
    vertical-align: top;
    color: $white-01;
    font-size: 19px;
  }

  .e-filemanager .e-toolbar-items .e-fe-details:before {
    content: '\e973';
  }

  .e-filemanager .e-toolbar-items .e-fe-sort:before {
    content: '\eaa6';
  }

  .e-filemanager .e-toolbar-items .e-fe-large:before {
    content: '\e908';
  }

  .e-filemanager .e-toolbar-items .e-fe-grid:before {
    content: '\e992';
  }

  .e-filemanager .e-toolbar-items .e-fe-refresh:before {
    content: '\e9c9';
  }

  .e-filemanager .e-toolbar-items .e-fe-actions:before {
    content: '\e9e2';
  }

  .e-filemanager .e-overlay .e-btn-icon {
    background-color: $disabled-color !important;
    color: white !important;
  }

  /* grid */

  .e-filemanager .e-grid .e-gridheader .e-headercell {
    height: 50px;
  }

  .e-filemanager .e-grid .e-gridheader .e-headercell .e-rcursor,
  .e-filemanager .e-grid .e-gridheader .e-headercell .e-rsuppress {
    border: none;
    border-right: 1px solid $white-01;
  }

  .e-filemanager .e-grid .e-gridheader .e-headercell.e-fe-checkbox .e-rsuppress {
    border: none;
  }

  .e-filemanager .e-grid .e-gridheader .e-headercell.e-fe-grid-icon .e-headercelldiv:before {
    content: 'Tipo';
    color: $corporate-color;
    font-family: $corporate-font;
    font-size: 16px;
  }

  .e-filemanager .e-grid .e-checkbox-wrapper .e-check::before,
  .e-filemanager .e-grid .e-css.e-checkbox-wrapper .e-check::before {
    top: -2px;
    font-family: $lf-icons-font;
    content: '\e92e';
    font-size: 13px !important;
    background-color: $corporate-color;
  }

  .e-filemanager .e-grid .e-gridheader .e-headercell.e-fe-checkbox .e-frame.e-check::before {
    content: '\e938';
    font-family: $lf-icons-font;
    font-size: 9px !important;
  }

  .e-filemanager .e-grid .e-gridheader .e-headercell.e-fe-checkbox .e-frame.e-icons.e-stop {
    background-color: $corporate-color;
  }

  .e-filemanager .e-grid .e-gridheader .e-headercell.e-fe-checkbox .e-frame.e-icons.e-stop::before {
    color: $white-01;
    top: -1px;
  }

  .e-filemanager .e-checkbox-wrapper .e-frame.e-check {
    background-color: $corporate-color;
  }

  .e-filemanager .e-checkboxl-wrapper:hover .e-frame.e-check,
  .e-filemanager .e-css.e-checkbox-wrapper:hover .e-frame.e-check {
    background-color: $corporate-color;
  }

  .e-headercontent .e-table thead tr th .e-checkbox-wrapper.e-css.e-small,
  .e-content .e-table tbody tr td .e-checkbox-wrapper.e-css.e-small {
    position: relative;
    left: -3px;
  }

  .e-filemanager .e-grid .e-gridheader .e-headercell.e-fe-checkbox .e-headercelldiv:before,
  .e-filemanager .e-grid .e-gridheader .e-headercell.e-fe-grid-icon .e-headercelldiv:before {
    position: relative;
    top: -2px;
  }

  .e-filemanager .e-grid .e-gridheader tr:first-child th {
    height: 30px;
    background-color: $blue-07;
    font-size: 16px;
    color: $corporate-color;
  }

  .e-filemanager .e-grid .e-gridheader .e-fe-grid-icon + .e-headercell {
    padding-left: 20px;
  }

  .e-filemanager .e-grid .e-gridheader .e-checkbox-wrapper {
    visibility: visible;
  }

  .e-filemanager .e-grid .e-row .e-checkbox-wrapper {
    visibility: visible;
  }

  .e-filemanager .e-address .e-address-list-item:last-child .e-list-text,
  .e-filemanager .e-address .e-list-text {
    color: $corporate-color;
    text-transform: uppercase;
  }

  .e-filemanager .e-treeview .e-list-parent .e-list-item .e-list-text {
    color: $corporate-color;
  }

  .e-filemanager .e-treeview .e-list-item.e-active > .e-text-content .e-list-text {
    color: $corporate-color;
    text-transform: uppercase;
  }

  .e-filemanager .e-grid .e-content {
    @include scroll-styles;
  }
  .e-filemanager .e-grid .e-content .e-table {
    margin-bottom: 40px;
  }

  .e-filemanager .e-large-icons {
    background-color: $white-01;
  }

  .e-filemanager .e-large-icons .e-checkbox-wrapper .e-icons.e-check {
    background-color: $blue-01;
  }

  .e-checkbox-wrapper .e-check::before,
  .e-css.e-checkbox-wrapper .e-stop::before,
  .e-checkbox-wrapper .e-check::before,
  .e-css.e-checkbox-wrapper .e-check::before {
    top: -2px;
    position: relative;
  }

  .e-filemanager .e-fe-overlay {
    border-color: $blue-09;
  }

  .e-filemanager .e-gridcontent .e-scrollShow {
    @include scroll-styles;
  }

  .e-filemanager #file-manager_grid {
    border: none;
  }

  .e-filemanager #file-manager_content {
    @include scroll-styles;
  }

  /* Icons */

  .e-filemanager .e-list-icon {
    font-family: $lf-icons-font;
    background-image: none;
    vertical-align: top;
    color: $corporate-color;
  }

  .e-filemanager .e-rowcell > .e-fe-icon {
    font-family: $lf-icons-font;
    background-image: none;
    vertical-align: top;
    color: $corporate-color;
    font-size: 22px;
  }

  .e-filemanager .e-empty-icon {
    font-family: $lf-icons-font;
    background-image: none;
    vertical-align: top;
    color: $blue-04;
    font-size: 68px;
  }

  .e-upload .e-upload-files .e-icons {
    font-family: $lf-icons-font;
  }

  .e-upload .e-upload-files .e-icons.e-file-reload-btn::before {
    content: '\e9cd' !important;
    color: $corporate-color;
    font-size: 16px;
  }

  .e-upload .e-upload-files .e-file-remove-btn.e-icons::before {
    content: '\e9f0' !important;
    color: $corporate-color;
    font-size: 16px;
  }

  .e-dialog .e-icon-dlg-close::before {
    top: -2px;
  }

  .e-upload .e-upload-files .e-file-reload-btn.e-icons:hover {
    background-color: $blue-06;
  }

  .e-filemanager .e-upload .e-upload-files .e-file-remove-btn.e-icons:hover {
    background-color: $blue-06 !important;
  }
  .e-filemanager .e-dlg-closeicon-btn:hover {
    background-color: $blue-06 !important;
  }

  .e-filemanager .e-large-icons .e-list-icon {
    font-size: 32px;
  }

  .e-filemanager .e-fe-folder {
    background-image: none;
    vertical-align: top;
    color: $corporate-color;
  }

  .e-filemanager .e-headercelldiv .e-fe-folder:before {
    content: '';
  }

  .forbidden {
    background-color: red;
  }

  .e-filemanager .e-fe-folder:before {
    font-family: $lf-icons-font;
    content: '\e964';
  }

  @include file-icons;

  .e-filemanager .e-fe-rtf:before {
    content: '\ea48';
  }

  .e-filemanager .e-node-collapsed > .e-icon-wrapper > .e-fe-folder:before {
    content: '\e966';
  }

  .e-filemanager .e-treeview .e-list-item.e-active > .e-icon-wrapper > .e-fe-folder:before {
    content: '\e964';
  }

  .e-filemanager .e-fe-folder:before {
    content: '\e966';
  }

  .e-dialog .e-south-east::before,
  .e-dialog .e-south-west::before {
    font-family: $lf-icons-font;
    content: '\e942';
  }

  /* Empty folder */
  .e-filemanager .e-grid .e-empty {
    font-family: $corporate-font;
    color: $blue-04;
    .e-empty-icon {
      color: $blue-04;
    }
    .e-empty-content {
      font-size: 20px;
      padding: 0px;
    }
    .e-empty-inner-content {
      font-size: 14px;
      opacity: 1;
      padding: 0px;
    }
  }

  /* Dialogs */
  .e-dlg-modal {
    font-family: $corporate-font;
  }

  .e-dropdown-popup {
    background-color: $white-01;
    color: $corporate-color;
    border: 1px solid $corporate-color;
  }

  .e-dropdown-popup ul {
    padding: 2px;
  }

  .e-dropdown-popup ul .e-separator {
    border-bottom-color: $corporate-color;
  }

  .e-fe-popup.e-dropdown-popup ul .e-item .e-fe-dot,
  .e-fe-popup.e-dropdown-popup ul .e-item .e-fe-tick {
    color: $corporate-color;
    vertical-align: baseline;
  }

  .e-filemanager .e-dialog .e-footer-content .e-btn {
    background-color: $white-01;
    color: $corporate-color;
    border: 1px solid $corporate-color;
  }

  .e-filemanager .e-dialog .e-footer-content .e-btn:hover {
    background-color: $corporate-color;
    color: $white-01;
  }

  .e-filemanager .e-dialog.e-control .e-footer-content {
    border: none;
  }

  .e-fe-popup .e-upload {
    border: none;
  }

  .e-file-abort-btn:before {
    content: '\e98b' !important;
    color: $corporate-color;
    font-size: 16px;
  }

  .e-file-status {
    padding-bottom: 0px !important;
    font-size: 10px !important;
  }
  .e-file-status:before {
    content: '';
    display: inline-block;
    width: 10px;
    height: 10px;
    position: absolute;
    right: 0px;
    bottom: 0px;
  }
  .e-upload-fails,
  .e-validation-fails {
    @include border($direction: bottom, $width: 1px, $color: $red-01);
    color: $red-01 !important;
  }
  .e-upload-fails:before,
  .e-validation-fails:before {
    background-color: $red-01;
  }
  .e-upload-success {
    @include border($direction: bottom, $width: 1px, $color: $green-01);
    color: $green-01 !important;
  }
  .e-upload-success:before {
    background-color: $green-01;
  }

  .e-upload-progress-bar {
    background: $orange-02;
  }

  .e-progress-inner-wrap {
    background-color: $blue-09;
  }

  .e-filemanager .e-dlg-overlay {
    background-color: $white-01;
  }

  .e-filemanager .e-dlg-header {
    color: $blue-01;
  }

  .lf-dialog-delete .e-dlg-header-content,
  .lf-dialog-error .e-dlg-header-content {
    margin-bottom: 20px;
  }

  .lf-dialog-delete .e-dlg-header-content {
    background-color: $blue-01;
  }

  .lf-dialog-error .e-dlg-header-content {
    background-color: $red-01;
  }

  .lf-dialog-delete .e-dlg-header,
  .lf-dialog-error .e-dlg-header {
    color: $white-01;
  }

  .lf-dialog-delete .e-btn-icon.e-icon-dlg-close.e-icons,
  .lf-dialog-error .e-btn-icon.e-icon-dlg-close.e-icons {
    color: $white-01 !important;
  }

  .lf-dialog-delete .e-dlg-closeicon-btn {
    &:hover .e-icon-dlg-close {
      color: $blue-01 !important;
    }
  }

  .lf-dialog-error .e-dlg-closeicon-btn {
    &:hover .e-icon-dlg-close {
      color: $red-01 !important;
    }
  }

  .lf-dialog-delete .lf-dlg-icon:before,
  .lf-dialog-error .lf-dlg-icon:before {
    font-family: $lf-icons-font;
    color: $white-01;
    font-size: 25px;
    margin-right: 10px;
    position: relative;
    top: 2px;
    left: 1px;
  }

  .lf-dialog-delete .lf-dlg-icon:before {
    content: '\e973';
  }

  .lf-dialog-error .lf-dlg-icon:before {
    content: '\e935';
  }

  .lf-clean-field:before {
    font-family: $lf-icons-font;
    content: '\e935' !important;
    cursor: pointer;
    position: absolute;
    color: $blue-01;
    right: 9px;
    top: 9px;
    font-size: 20px;
  }
  .e-dlg-content .e-input-group.e-control-wrapper label {
    position: relative;
  }

  .e-fe-popup .e-fe-error {
    color: $red-01;
    font-size: 10px;
    padding-left: 5px;
  }

  .e-upload .e-upload-files {
    border: none;
    .e-upload-file-list {
      border: none;
    }
  }

  .e-filemanager .e-dlg-content .e-input-group.e-control-wrapper input.e-input {
    border: 1px solid $gray-04;
    padding: 10px 35px 10px 8px;
    background-color: $white-01;
  }

  .e-filemanager .e-dlg-content .e-input-group.e-control-wrapper input.e-input:not(.lf-fe-input-error):focus,
  .e-filemanager .e-dlg-content .e-input-group.e-control-wrapper input.e-input:not(.lf-fe-input-error):hover {
    border: 1px solid $corporate-color !important;
    background-color: $corporate-color-10;
  }

  .e-filemanager .e-dlg-container .e-dlg-content label {
    padding: 0px 7px;
    color: $blue-01;
    font-size: 14px;
    line-height: 20px;
  }

  .e-filemanager .e-dlg-container .e-dlg-content label span {
    color: $red-01;
  }
  .e-filemanager .e-dlg-container .e-dlg-content .lf-fe-input-error {
    border: 1px solid $red-01 !important;
    background-color: $red-07 !important;
  }

  .e-filemanager .e-dlg-container .e-dlg-content .lf-fe-label-error {
    color: $red-01;
  }

  .e-filemanager .e-dlg-container .e-footer-content .e-btn {
    min-width: 50px;
  }
  .e-filemanager .e-dlg-container .e-footer-content .e-btn.e-primary {
    position: relative;
    float: right;
  }

  .e-filemanager .e-input-group:not(.e-float-icon-left),
  .e-filemanager .e-input-group.e-control-wrapper:not(.e-float-icon-left) {
    border: none !important;
  }

  .e-filemanager .e-input-group.e-input-focus:not(.e-success):not(.e-warning):not(.e-error):not(.e-float-icon-left),
  .e-filemanager .e-input-group:hover:not(.e-success):not(.e-warning):not(.e-error):not(.e-float-icon-left),
  .e-filemanager span.e-input-group.e-control-wrapper.e-valid-input,
  .e-filemanager span.e-input-group.e-control-wrapper.e-valid-input:before,
  .e-filemanager span.e-input-group.e-control-wrapper.e-valid-input:after,
  .e-filemanager span.e-input-group.e-control-wrapper.e-valid-input.e-input-focus,
  .e-filemanager span.e-input-group.e-control-wrapper.e-valid-input.e-input-focus:before,
  .e-filemanager span.e-input-group.e-control-wrapper.e-valid-input.e-input-focus:after {
    border: none;
    background-color: transparent;
    transition: none;
    margin: 0;
    bottom: 0;
  }

  .e-filemanager span.e-input-group.e-control-wrapper.e-valid-input:before,
  .e-filemanager span.e-input-group.e-control-wrapper.e-valid-input:after,
  .e-filemanager span.e-input-group.e-control-wrapper.e-valid-input.e-input-focus:before,
  .e-filemanager span.e-input-group.e-control-wrapper.e-valid-input.e-input-focus:after {
    height: 0;
  }

  .e-filemanager .e-dialog .e-btn .e-btn-icon.e-icon-dlg-close {
    color: $corporate-color;
  }

  /* Context menu */
  #file-manager_contextmenu .e-menu-icon {
    font-family: $lf-icons-font;
    background-image: none;
    vertical-align: top;
    color: $corporate-color;
    font-size: 19px;
  }

  #file-manager_contextmenu .e-menu-item.e-focused .e-menu-icon {
    color: $white-01;
  }

  #file-manager_contextmenu .e-menu-item.e-disabled .e-menu-icon {
    color: $disabled-color;
  }

  #file-manager_contextmenu .e-menu-icon:hover {
    color: $white-01;
  }

  #file-manager_contextmenu .e-menu-icon.e-fe-details::before {
    content: '\e973';
  }
  #file-manager_contextmenu .e-menu-icon.e-fe-open::before {
    content: '\e9b3';
  }
  #file-manager_contextmenu .e-menu-icon.e-fe-copy::before {
    content: '\e941';
  }
  #file-manager_contextmenu .e-menu-icon.e-fe-cut::before {
    content: '\e943';
  }
  #file-manager_contextmenu .e-menu-icon.e-fe-paste::before {
    content: '\e9b5';
  }
  #file-manager_contextmenu .e-menu-icon.e-fe-rename::before {
    content: '\e9ca';
  }
  #file-manager_contextmenu .e-menu-icon.e-fe-download::before {
    content: '\e94f';
  }
  #file-manager_contextmenu .e-menu-icon.e-fe-downloadzip::before {
    content: '\e94f';
  }
  #file-manager_contextmenu .e-menu-icon.e-fe-delete::before {
    content: '\e9f0';
  }

  #file-manager_contextmenu #custom_separator {
    position: relative;
    height: 8px;
    color: transparent;

    &.e-blankicon.e-focused,
    &:hover {
      background-color: $white-01 !important;
    }

    &::after {
      display: block;
      position: absolute;
      top: 4;
      left: 0;
      height: 1px;
      width: 100%;
      content: '';
      background: $corporate-color;
    }
  }

  #file-manager_contextmenu #hidden-cm-option,
  #file-manager_contextmenu #file-manager_cm_cut,
  #file-manager_contextmenu #file-manager_cm_copy,
  #file-manager_contextmenu #file-manager_cm_paste,
  #file-manager_contextmenu #file-manager_cm_rename {
    display: none;

    + .e-separator {
      display: none;
    }
  }

  #file-manager_contextmenu li#hidden-cm-option + li#custom_separator {
    display: none;
  }

  #file-manager_contextmenu #file-manager_cm_download_zip_portal {
    + .e-separator {
      display: none;
    }
  }
  .e-filemanager .e-fe-grid::before,
  .e-fe-popup .e-fe-grid::before {
    font-family: $lf-icons-font;
    content: '\e992';
  }

  .e-filemanager .e-fe-large::before,
  .e-fe-popup .e-fe-large::before {
    font-family: $lf-icons-font;
    content: '\e908';
  }

  .e-treeview .e-list-item div.e-icons::before {
    font-family: $lf-icons-font;
    content: '\e90d' !important;
  }

  .e-treeview .e-list-icon::before {
    position: absolute;
    top: 2px;
  }

  .e-treeview .e-list-item.e-active.e-hover > .e-text-content .e-list-text {
    color: $corporate-color !important;
  }

  /* Context Menu Add */
  #action-add-document-context-menu {
    width: 300px;
  }
  #action-add-template-context-menu {
    width: 200px;
  }

  #action-add-document-context-menu .e-menu-icon,
  #action-add-template-context-menu .e-menu-icon {
    vertical-align: baseline;
  }

  /* Search box */
  .e-filemanager .e-address .e-search-wrap {
    @include borders;
    height: 29px;
    width: 202px;
    font-family: $corporate-font;
    font-size: 16px;
    margin: 2px 10px 0 0;
    padding: 0 3px 0 7px;
    background-color: $white-01;
    .e-icons {
      @include border($direction: left);
      font-size: 19px !important;
      color: $corporate-color;
      position: relative;
      top: 3px;
    }
    .e-control-wrapper {
      color: $corporate-color;
      padding: 0 5px;
      position: relative;
      float: left;
      left: 0px;
    }
    .e-input-group {
      background: rgba(250, 250, 250, 0) !important;
      .e-input {
        margin: 0 0 0 0px;
        padding: 0 25px 0 0;
      }
    }
  }
  .e-filemanager .e-address .e-fe-search {
    margin: 3px -25px;
    padding: 0px 5px;
  }
  .e-fe-search::before {
    font-family: $lf-icons-font;
    content: '\e9d0' !important;
  }

  .e-input-group.e-control-wrapper:not(.e-float-icon-left):not(.e-float-input)::before,
  .e-input-group.e-control-wrapper:not(.e-float-icon-left):not(.e-float-input)::after {
    background: none !important;
  }

  .e-filemanager .e-address .e-search-wrap .e-input-group .e-clear-icon {
    margin: 2px -7px 0 0px;
    z-index: 999;
    background-color: $white-01;
    height: 22px;
    width: 25px;
    padding: 0px;
  }

  .e-filemanager .e-input-group .e-clear-icon::before,
  .e-input-group.e-control-wrapper .e-clear-icon::before {
    font-family: $lf-icons-font;
    content: '\e936';
    font-size: 19px;
    color: $corporate-color;
    left: -2px;
    position: relative;
  }

  .e-filemanager .e-address .e-search-wrap .e-input-group .e-input::selection,
  .e-dlg-content .e-input-group.e-control-wrapper input.e-input::selection {
    background-color: $selection-color;
    color: $black-01 !important;
  }

  .e-filemanager .e-address .e-search-wrap .e-input-group .e-input::-moz-selection,
  .e-dlg-content .e-input-group.e-control-wrapper input.e-input::-moz-selection {
    background-color: $selection-color;
    color: $black-01 !important;
  }

  .e-filemanager .e-grid .e-gridheader tr:first-child th:hover,
  .e-filemanager .e-grid .e-gridheader tr:first-child th div:hover {
    background: $blue-07 !important;
  }

  .e-filemanager .e-grid .e-gridheader .e-headercell .e-headertext {
    font-size: 16px;
  }

  .e-filemanager .e-address .e-addressbar-ul li:last-child span:last-child {
    color: $gray-01;
  }

  /* Table */
  tr {
    // border: 1px solid red;
  }

  /* Scrollbar */
  .e-layout .e-navigation {
    @include scroll-styles();
  }

  /* Progress Bar */
  .e-upload-progress-bar.e-upload-progress {
    background: $orange-02 !important;
  }

  .e-progress-inner-wrap {
    background-color: $blue-09 !important;
  }

  /* Drag&Drop */
  .e-filemanager .e-fe-drop-folder .e-fullrow {
    cursor: grab;
  }
  .e-filemanager.lexon-no-drop .e-fe-drop-folder .e-fullrow {
    cursor: not-allowed;
  }
  .e-fe-clone .e-fe-icon:before {
    content: '';
  }

  .e-filemanager .firma-column {
    @include flex;
    width: 100;
    justify-content: space-between;
    .firma-status {
      @include flex;
    }
    .firma-certified {
      @include flex;
      color: $corporate-color;
      font-size: 20px;
    }
  }

  .e-filemanager #file-manager_navigation,
  .e-filemanager #file-manager_breadcrumbbar {
    background-color: $white-01;
  }
  .spinner-layer-file-manager-component {
    --spinner-layer-min-height: 100%;
    --spinner-layer-background: rgba(255, 255, 255, 0.5);
    --spinner-layer-position: absolute;
  }
  </style>
  <style lang="scss">
  .zip-wrong-files {
    margin-top: 20px;
    padding: 10px;
    max-height: 200px;
    overflow-y: auto;
  }
  </style>
