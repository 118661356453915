<template lang="pug">

  WidgetBaseComponent(
    class="dashboard-chart-widget"
    :loadedData="!showSpinner"
    :showFooter="false"
    :showHeader="false"
    customClass="dashboard-widget"
    :key="componentKey"
  )

    template(v-slot:body)

      div(:class="['chart-container', { 'no-results': !allDataLoaded }]")
        p(class="chart-title") {{ chartTitle }}
        ejs-chart(
          v-if="allDataLoaded"
          ref="chart"
          :id="id"
          class="chart"
          v-bind="chartProps"
        )
          e-series-collection
            e-series(
              v-for="key in primaryYAxisKeys"
              :key="key"
              :dataSource="finalChartData"
              type="Column"
              xName="month"
              :yName="key"
              :name="renderLegend(key)"
              v-bind="serieProps"
            )

        DashboardNoResultsComponent(v-if="showNoResultsComponent")

</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import WidgetBaseComponent from '@/components/widgets/WidgetBaseComponent.vue'
import DashboardNoResultsComponent from '@/components/widgets/DashboardWidgets/DashboardNoResultsComponent.vue'
import { DashboardChartBarItem } from '@/store/modules/dashboardWidgets/dashboardWidgetsTypes'
import { ChartPlugin, ColumnSeries, Category, Legend, Tooltip } from '@syncfusion/ej2-vue-charts'
 import { vars } from '@/styles/styleVars'

Vue.use(ChartPlugin)

@Component({
  components: {
    DashboardNoResultsComponent,
    WidgetBaseComponent
  },
  provide: {
    chart: [ColumnSeries, Category, Legend, Tooltip]
  }
})
export default class DashboardWidgetChartBarType extends Vue {
  @Prop({
    type: String,
    required: true
  })
  id!: string

  @Prop({
    type: String,
    required: true
  })
  chartTitle!: string

  @Prop({
    type: Array,
    required: true
  })
  chartDataProp!: DashboardChartBarItem[]

  @Prop({
    type: Boolean,
    default: true
  })
  showSpinner!: boolean

  chartProps = {
    primaryXAxis: {
      valueType: 'Category',
      majorGridLines: {
        width: 0
      },
      labelStyle: {
        fontFamily: vars.secondary_font,
        size: '10px',
        color: vars.gray_07
      }
    },
    primaryYAxis: {
      lineStyle: { width: 1 },
      majorGridLines: {
        width: 0
      },
      labelStyle: {
        fontFamily: vars.secondary_font,
        size: '10px',
        color: vars.gray_07
      }
    },
    chartArea: {
      border: {
        width: 0
      }
    },
    legendSettings: {
      visible: true,
      position: 'Bottom',
      textStyle: {
        fontFamily: vars.corporate_font,
        size: '10px',
        color: vars.gray_07
      }
    },
    tooltip: { enable: true },
    palettes: [vars.gray_13, vars.green_11]
  }

  serieProps = {
    pointColorMapping: 'color',
    columnWidth: 0.9
  }

  finalChartData: DashboardChartBarItem[] = []

  componentKey = 1

  monthColors = [
    vars.brown_02,
    vars.green_12,
    vars.gold_01,
    vars.purple_05,
    vars.brown_01,
    vars.green_11,
    vars.brown_03,
    vars.blue_16,
    vars.pink_02,
    vars.gray_13,
    vars.green_13,
    vars.green_10
  ]

  @Watch('chartDataProp', { immediate: true })
  async renderColor(value: DashboardChartBarItem[]) {
    if (value) {
      this.finalChartData = this.addBarColors()
      await this.$nextTick()
      this.componentKey += 1
    }
  }

  get allDataLoaded() {
    return this.finalChartData && this.finalChartData.length ? true : false
  }

  get showNoResultsComponent() {
    return this.chartDataProp && this.chartDataProp.length ? false : true
  }

  get primaryYAxisKeys() {
    const keys = Object.keys(this.chartDataProp[0])
    return keys.filter((key) => key !== 'month' && key !== 'color')
  }

  addBarColors() {
    const dataItems = this.chartDataProp
    if (dataItems.length === 12) {
      // Significa que se renderizan los 12 meses así qie le añadimos el color
      dataItems.forEach((item, index) => (item.color = this.monthColors[index]))
    }
    return dataItems
  }

  renderLegend(key: string): string | null {
    switch (key) {
      case 'hoursWorked':
        return 'Horas trabajadas'
      case 'hoursInvoiced':
        return 'Horas facturadas'
      default:
        return ''
    }
  }
}
</script>

<style lang="scss" scoped>
.dashboard-chart-widget {
  &.spinner-layer-container {
    height: 100%;
    min-height: 100%;
  }

  .chart-container {
    @include flex($flex-direction: column);
    width: 100%;
    height: 100%;
    padding: 10px;

    .chart-title {
      align-self: flex-start;
      width: 100%;
      font-family: $corporate-font-medium;
      font-size: 18px;
      color: $corporate-color;
      padding: 0 0 2px 3px;
      margin: 0;
    }

    .chart {
      width: 100%;
      height: 100%;
    }

    &.no-results {
      justify-content: flex-start;
    }
  }
}
</style>
