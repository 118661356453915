import axios, { AxiosRequestConfig } from 'axios'
import store from '@/store/store'
import { ModuleNamespaces } from '@/store/types/storeGlobalTypes'

const axiosConfiguration: AxiosRequestConfig = {
  headers: {}
}

const externalServices = axios.create(axiosConfiguration)

externalServices.interceptors.request.use(
  (config) => {
    const accessToken = store.getters[`${ModuleNamespaces.AUTH}/getAuthToken`]

    if (accessToken) {
      const source = axios.CancelToken.source()
      config.cancelToken = source.token
      store.commit(`${ModuleNamespaces.AUTH}/ADD_CANCEL_TOKEN`, source)
      store.dispatch(`${ModuleNamespaces.AUTH}/keepSessionAlive`)
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

export default externalServices
