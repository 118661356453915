import { Module } from 'vuex'
import { getters } from '@/store/modules/errors/errorsGetters'
import { actions } from '@/store/modules/errors/errorsActions'
import { mutations } from '@/store/modules/errors/errorsMutations'
import { ErrorsState } from '@/store/modules/errors/errorsTypes'
import { RootState } from '@/store/types/storeGlobalTypes'

export const state: ErrorsState = {
  error: null,
  endpointErrorFlag: false
}

const namespaced: boolean = true

export const errorsModule: Module<ErrorsState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations
}
