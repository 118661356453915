import { GetterTree } from 'vuex'
import { ContextMenuState, ContextMenuItem } from '@/store/modules/contextMenu/contextMenuTypes'
import { RootState } from '@/store/types/storeGlobalTypes'

export const getters: GetterTree<ContextMenuState, RootState> = {
  getSelectedRegisterInfo(state): object {
    return state.selectedRegister
  },
  getContextMenuItems(state): ContextMenuItem[] {
    return state.items
  }
}
