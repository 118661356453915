<template lang="pug">

div(class="breadcrumbs-container")
  div(
    v-for="(item, index) in items"
    :key="index"
    :class="['breadcrumb', {'to-lower-case': item.lowerCase}]"
  )
    router-link(
      v-if="item.url"
      :to="item.url"
      class="breadcrumb-link"
    ) {{ item.text | replaceDash }}
    span(v-else class="breadcrumb-text") {{ item.text | replaceDash }}
    span(v-if="(index + 1) < numberOfItems" :class="[separatorType, 'separator']")

</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator'
import { Icons } from '@/icons/icons'

@Component({
  filters: {
    replaceDash(value: string) {
      return value.replace(/-/g, ' ')
    }
  }
})
export default class LexonBreadcrumbsComponent extends Vue {
  @Prop({
    type: Array
  })
  items!: []

  get numberOfItems() {
    return this.items.length
  }

  separatorType = Icons.ANGLE_RIGHT
}
</script>

<style lang="scss" scoped>
.breadcrumbs-container {
  @include flex;

  .breadcrumb {
    @include flex;
    font-family: $corporate-font-medium;
    font-size: 15px;
    color: $gray-01;
    text-transform: uppercase;

    &.to-lower-case {
      text-transform: capitalize;
    }

    .breadcrumb-text {
      @include ellipsis;
      max-width: 200px;
    }
  }

  .breadcrumb-link {
    color: $corporate-color;
    text-decoration: none;

    &:hover {
      color: $blue-04;
    }
  }

  .separator {
    font-size: 30px;
    margin: 0 5px;
    color: $gray-02;

    &::before {
      position: relative;
      top: -3px;
      z-index: 1;
    }
  }
}
</style>
