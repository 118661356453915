import { MutationTree } from 'vuex'
import { TabsState } from '@/store/modules/tabs/tabsTypes'

export const mutations: MutationTree<TabsState> = {
  CHANGE_DISABLED_TABS(state, status) {
    state.disabledTabs = status
  },
  SET_CURRENT_TAB_INFO(state, { name, index }) {
    state.currentTabInfo = {
      name,
      index
    }
  },
  SET_CURRENT_SEARCH_TAB_INFO(state, { name, index }) {
    state.currentSearchTabInfo = {
      name,
      index
    }
  },
  FETCH_TAB_SELECTOR_OPTIONS(state, { idEntity, tabs }) {
    state.tabSelectorOptions[idEntity] = tabs
  }
}
