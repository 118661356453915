import { Module } from 'vuex'
import { getters } from '@/store/modules/contacts/contactsGetters'
import { actions } from '@/store/modules/contacts/contactsActions'
import { mutations } from '@/store/modules/contacts/contactsMutations'
import { ContactsState } from '@/store/modules/contacts/contactsTypes'
import { RootState } from '@/store/types/storeGlobalTypes'

export const state: ContactsState = {
  contacts: null,
  contactTypeSelected: null
}

const namespaced: boolean = true

export const contactsModule: Module<ContactsState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations
}
