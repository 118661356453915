import { GetterTree } from 'vuex'
import {
  MaintenanceState,
  Maintenance,
  DashboardMaintenance,
  StageStatus
} from '@/store/modules/maintenance/maintenanceTypes'
import { RootState } from '@/store/types/storeGlobalTypes'
import { formatFieldsTypeDateEPOCH } from '@/helpers/dateTime'

export const getters: GetterTree<MaintenanceState, RootState> = {
  getAllMaintenance(state): Maintenance[] {
    return state.maintenance
  },
  getDashboardMaintenanceList(state): DashboardMaintenance[] {
    return state.dashboardMaintenanceList
  },
  getTotalNumberOfMaintenance(state): string {
    return state.total
  },
  getMaintenanceListWithFormattedDates(state) {
    return formatFieldsTypeDateEPOCH(state.maintenance)
  },
  getMaintenanceStageState(state): StageStatus[] {
    return state.stageStatus
  },
  getMaintenanceSummary(state) {
    return state.summary
  },
  getMaintenanceActionsSummary(state) {
    return state.actionsSummary
  },
  getLastMaintenanceSaved(state): number | null {
    return state.lastMaintenanceSaved
  },
  getTaxesItems(state) {
    return state.taxesItems
  },
  getSimpeGridItems(state) {
    return state.economicDataSimpleGridItems
  },
  getSimpleGridItemsWithFormattedDates(_state, getters) {
    const listItems = getters.getSimpeGridItems
    const fieldsTypeDateEPOCH = listItems[0] ? Object.keys(listItems[0]).filter((key) => key.includes('EPOCH')) : null
    return fieldsTypeDateEPOCH ? formatFieldsTypeDateEPOCH(listItems) : listItems
  },
  getSelectedMaintenanceInsurer(state) {
    return state.selectedMaintenanceInsurer
  }
}
