<script lang="ts">
import { Vue, Component } from 'vue-property-decorator'
import { Icons } from '@/icons/icons'

@Component
export default class ArchivedFilterMixin extends Vue {
  archivedSelectedOption: number | null = 0

  renderGrid: boolean = false

  actionsArchivedContextMenuItems = [
    {
      text: this.$i18n.t('navigation.drawer-menu-items.actions_not_archived'),
      iconCss: Icons.CHECK,
      actionType: 0
    },
    {
      text: this.$i18n.t('navigation.drawer-menu-items.actions_archived'),
      actionType: 1
    },
    {
      text: this.$i18n.t('navigation.drawer-menu-items.actions_all'),
      actionType: 2
    }
  ]

  expedientsArchivedContextMenuItems = [
    {
      text: this.$i18n.t('navigation.drawer-menu-items.expedient_not_archived'),
      iconCss: Icons.CHECK,
      actionType: 0
    },
    {
      text: this.$i18n.t('navigation.drawer-menu-items.expedient_archived'),
      actionType: 1
    },
    {
      text: this.$i18n.t('navigation.drawer-menu-items.expedient_all'),
      actionType: 2
    }
  ]

  invoicesArchivedContextMenuItems = [
    {
      text: this.$i18n.t('navigation.drawer-menu-items.invoices_this_tear'),
      actionType: 0
    },
    {
      text: this.$i18n.t('navigation.drawer-menu-items.invoices_last_two'),
      iconCss: Icons.CHECK,
      actionType: 1
    },
    {
      text: this.$i18n.t('navigation.drawer-menu-items.invoices_tree'),
      actionType: 2
    }
  ]

  get invoicesArchivedContextMenuItemsParsed() {
    const allNode = {
      text: this.$i18n.t(`navigation.drawer-menu-items.invoices_all_${this.$route.meta?.entityType}`),
      actionType: 3
    }
    const newContextMenu = this.invoicesArchivedContextMenuItems.concat(allNode)
    return newContextMenu
  }

  async initializeArchivedSelectedOption({
    selectedOption,
    contextMenu
  }: {
    selectedOption: number
    contextMenu: string
  }) {
    if ((this as any).listConfiguration['Config']) {
      const config = JSON.parse((this as any).listConfiguration['Config'])
      this.archivedSelectedOption = config.hasOwnProperty('archived') ? config.archived : selectedOption
      await this.$nextTick()
      this.changeContextMenuIcons(this.archivedSelectedOption, contextMenu)
    }
  }

  changeContextMenuIcons(actionType: number | null, idContextMenu: string) {
    const elem: any = document.getElementById(idContextMenu)
    if (null !== elem) {
      elem.ej2_instances[0].items.map((item: any) => {
        if (item.actionType === actionType) {
          item.iconCss = Icons.CHECK
        } else {
          item.iconCss = null
        }
      })
    }
  }
}
</script>
