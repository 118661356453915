export enum DocumentPreviewType {
  Unknown = 'UNKNOWN',
  Pdf = '.pdf',
  WordDot = 'dotx',
  WordCm = 'docm',
  WordTm = 'dotm',
  WordDotOld = 'dot',
  Word = '.docx',
  Excel = '.xlsx',
  PowerPoint = '.pptx',
  WordOld = '.doc',
  ExcelOld = '.xls',
  PowerPointOld = '.ppt',
  Rtf = '.rtf',
  Txt = '.txt',
  Html = '.html',
  Jpeg = '.jpeg',
  Jpg = '.jpg',
  Png = '.png',
  Gif = '.gif',
  Jfif = '.jfif',
  Xyz = '.xyz'
}

export enum ComponentPreview {
  Excel = 'ExcelPreviewComponent',
  NotContent = 'NotPreviewContentComponent',
  Pdf = 'PdfPreviewComponent',
  Jpg = 'ImagePreviewComponent',
  Word = 'WordPreviewComponent'
}

export interface DocumentPreview {
  id: number
  type: DocumentPreviewType | null
}

export const getPreviewComponentByExtension = (extension: DocumentPreviewType | null): string => {
  if (!extension) {
    return ComponentPreview.NotContent
  }
  if (wordComponent.includes(extension)) {
    return ComponentPreview.Word
    // } else if (excelComponent.includes(extension)) {
    //   return ComponentPreview.Excel
  } else if (extension === DocumentPreviewType.Pdf || extension === DocumentPreviewType.Xyz) {
    return ComponentPreview.Pdf
  } else if (imageComponent.includes(extension)) {
    return ComponentPreview.Jpg
  } 

  return ComponentPreview.NotContent
}

const wordComponent: DocumentPreviewType[] = [
  DocumentPreviewType.Word,
  DocumentPreviewType.WordOld,
  DocumentPreviewType.WordCm,
  DocumentPreviewType.WordDot,
  DocumentPreviewType.WordDotOld,
  DocumentPreviewType.WordDotOld,
  DocumentPreviewType.WordTm,
  DocumentPreviewType.Rtf,
  DocumentPreviewType.Txt
]

const imageComponent: DocumentPreviewType[] = [
  DocumentPreviewType.Jpg,
  DocumentPreviewType.Jpeg,
  DocumentPreviewType.Png,
  DocumentPreviewType.Gif,
  DocumentPreviewType.Jfif
]

// const excelComponent: DocumentPreviewType[] = [
//   DocumentPreviewType.Excel,
//   DocumentPreviewType.ExcelOld
// ]
