import { MutationTree } from 'vuex'
import { SelectedRegisterState } from '@/store/modules/selectedRegister/selectedRegisterTypes'
import { Vue } from 'vue-property-decorator'

export const mutations: MutationTree<SelectedRegisterState> = {
  FETCH_SELECTED_REGISTER_DATA(state, { selectedRegisterData, context }) {
    Vue.set(state.selectedRegisterData, context, selectedRegisterData)
  },
  FETCH_SELECTED_RELATED_DATA(state, selectedRelatedData) {
    state.selectedRelatedData = selectedRelatedData
  },
  RESET_SELECTED_REGISTER_DATA(state, context) {
    Vue.set(state.selectedRegisterData, context, {})
  },
  RESET_SELECTED_RELATED_DATA(state) {
    state.selectedRelatedData = {}
  },
  SAVE_SELECTED_REGISTER_ID(state, { id, context }) {
    Vue.set(state.selectedRegisterId, context, id)
  },
  REMOVE_SELECTED_REGISTER_DATA_CONTEXT(state, context) {
    delete state.selectedRegisterData[context]
  },
  SAVE_PARENT_REGISTER_ID(state, id) {
    state.parentRegisterId = id
  },
  RESET_PARENT_REGISTER_ID(state) {
    state.parentRegisterId = 0
  }
}
