<script lang="ts">
import { Icons } from '@/icons/icons'
import { i18n } from '@/plugins/vue-i18n'
import { DialogTypes } from '@/store/modules/dialog/dialogTypes'
import { ContextName, ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import { Vue, Component, Prop } from 'vue-property-decorator'
import { Action, Getter, Mutation } from 'vuex-class'
import { Endpoint } from '@/store/modules/endpoint/endpointTypes'
import { Entity } from '@/store/modules/entities/entitiesTypes'
import { ActionName } from '@/components/ActionsBar/types/ActionBarComponentTypes'
import { SearchTab } from '@/store/modules/tabs/tabsTypes'

const selectedRegisterModule: string = ModuleNamespaces.SELECTED_REGISTER
const entitiesModule: string = ModuleNamespaces.ENTITIES
const dialogModule: string = ModuleNamespaces.DIALOG
const errorsModule: string = ModuleNamespaces.ERROR
const formsModule = ModuleNamespaces.FORMS
const expedientsModule = ModuleNamespaces.EXPEDIENTS
const configurationModule = ModuleNamespaces.CONFIGURATION
const contactsModule = ModuleNamespaces.CONTACTS
const tabsModule = ModuleNamespaces.TABS

@Component
export default class RelatedFormMixin extends Vue {
  @Prop({
    type: String,
    required: true
  })
  context!: string

  @Prop({
    type: Object
  })
  entity!: Entity

  @Action('fetchRelatedSelectedRegister', { namespace: selectedRegisterModule })
  fetchRelatedSelectedRegister: ({}) => {}

  @Action('listRelatedForm', { namespace: formsModule })
  listRelatedForm: ({}) => {}

  @Action('removeRelatedFormItem', { namespace: formsModule })
  removeRelatedFormItem: ({}) => {}

  @Action('showDialog', { namespace: dialogModule })
  showDialog: ({}) => void

  @Action('prepareRegisterFormDataToSave', { namespace: formsModule })
  prepareFormData: ({}) => void

  @Action('setContactTypeSelected', { namespace: contactsModule })
  setContactTypeSelected: ({}) => void

  @Mutation('CHANGE_RELATED_FORM_STATUS', { namespace: formsModule })
  changeRelatedFormStatus: ({}) => void

  @Mutation('RESET_FORM_IS_VALID', { namespace: formsModule })
  setFormIsInValid: (context: string) => void

  @Mutation('SET_FORM_IS_VALID', { namespace: formsModule })
  setFormIsValid: (context: string) => void

  @Mutation('RESET_REGISTER_FORM_DATA', { namespace: formsModule })
  resetFormData: () => void

  @Mutation('SET_LOADING_FORM_DATA', { namespace: formsModule })
  setLoadingFormData: (loading: boolean) => void

  @Getter('getSelectedRegisterData', { namespace: selectedRegisterModule })
  selectedRegisterData: (context: string) => any

  @Getter('showRelatedForm', { namespace: formsModule }) showRelatedForm: (context: string) => boolean

  @Getter('getSelectedEntityName', { namespace: entitiesModule })
  selectedEntityName: string

  @Getter('existsEndpointErrorStatus', { namespace: errorsModule })
  existsEndpointError: boolean

  @Getter('registerNameFilled', { namespace: formsModule })
  registerNameFilled: string

  @Getter('tabsViewFormWithSelectedRegisterData', { namespace: selectedRegisterModule })
  formFieldsData: (context: string, name: string) => string

  @Getter('getLoadingFormData', { namespace: formsModule })
  isLoadingFormData: string

  @Getter('getExpedientStageState', { namespace: expedientsModule })
  expedientStageState: []

  @Getter('getCurrentViewTabs', { namespace: configurationModule })
  getCurrentViewTabs: (context: string) => []

  @Getter('getCurrentTabInfo', { namespace: tabsModule })
  currentTabInfo: SearchTab

  formData = {}

  relatedData = {
    code: '',
    colorName: '',
    id: '',
    idConfiguracion: '',
    idUser: null,
    name: '',
    userName: ''
  }

  relatedEndpoints: Endpoint

  formatedEndpoints: Endpoint = { get: '', save: '', delete: '', list: '' }

  currentTabName: string

  maintenanceTabContextMenuItems = [
    {
      text: i18n.t('components.context_menu.edit'),
      iconCss: Icons.EDIT
    },
    {
      separator: true
    },
    {
      text: i18n.t('components.context_menu.remove'),
      iconCss: Icons.REMOVE,
      action: ActionName.REMOVE
    }
  ]

  maintenanceUsersTabComponentProfessionalRelatedForm = [
    {
      text: i18n.t('components.context_menu.remove'),
      iconCss: Icons.REMOVE,
      action: ActionName.REMOVE
    }
  ]

  contactsTabcontextMenuItems = [
    {
      text: i18n.t('components.context_menu.edit'),
      iconCss: Icons.EDIT
    },
    {
      text: i18n.t('components.context_menu.remove'),
      iconCss: Icons.REMOVE
    }
  ]

  intervenersTabcontextMenuItems = [
    {
      text: i18n.t('components.context_menu.edit'),
      iconCss: Icons.EDIT
    },
    {
      text: i18n.t('components.context_menu.edit_intervener'),
      iconCss: Icons.EDIT
    },
    {
      text: i18n.t('components.context_menu.open_window'),
      iconCss: Icons.OPEN_WINDOW
    },
    {
      text: i18n.t('components.context_menu.remove'),
      iconCss: Icons.REMOVE
    }
  ]

  classificationTabcontextMenuItems = [
    {
      text: i18n.t('components.context_menu.edit'),
      iconCss: Icons.EDIT
    },
    {
      text: i18n.t('components.context_menu.open_window'),
      iconCss: Icons.OPEN_WINDOW
    },
    {
      text: i18n.t('components.context_menu.remove'),
      iconCss: Icons.REMOVE
    }
  ]

  get isContactContext() {
    return (
      this.context === ContextName.CONTACT_AGENCIES ||
      this.context === ContextName.CONTACT_COURTS ||
      this.context === ContextName.CONTACT_CUSTOMERS ||
      this.context === ContextName.CONTACT_INSURERS ||
      this.context === ContextName.CONTACT_NOTARIES ||
      this.context === ContextName.CONTACT_OPPONENTS ||
      this.context === ContextName.CONTACT_OPPONENT_ATTORNEYS ||
      this.context === ContextName.CONTACT_OPPONENT_LAWYERS ||
      this.context === ContextName.CONTACT_OTHERS ||
      this.context === ContextName.CONTACT_OWN_ATTORNEYS ||
      this.context === ContextName.CONTACT_OWN_LAWYERS ||
      this.context === ContextName.CONTACT_PROVIDERS
    )
  }

  destroyed() {
    this.changeRelatedFormStatus({
      status: false,
      context: this.context
    })
    this.resetFormData()
  }

  onSaveFormData(schema: [], formData: object, field: object, value: any) {
    this.prepareFormData(formData)
    this.$emit('field-changed', schema, formData, field, value)
  }

  onOpenNewGridRelatedForm() {
    this.setFormIsInValid(this.context)
    this.changeRelatedFormStatus({
      status: true,
      context: this.context
    })
    this.setFormIsValid(this.context)
  }

  onOpenNewRelatedForm() {
    this.setFormIsInValid(this.context)
    this.changeRelatedFormStatus({
      status: true,
      context: this.context
    })
  }

  async onOpenEditRelatedForm(data: any) {
    let filterList: object | null = null

    const excludedContexts: string[] = [
      ContextName.MAINTENANCE,
      ContextName.MAINTENANCE_USERS_RELATED_FORM,
      ContextName.USERS
    ]

    if (!excludedContexts.includes(this.context)) {
      if (this.isContactContext) {
        this.formatedEndpoints.get = String.format(
          this.relatedEndpoints.get,
          this.selectedEntityName,
          data.idContact,
          data.id
        )
      } else {
        if (data.idIntervenerType && !data.principal) {
          this.setContactTypeSelected(data.alias)
          this.formatedEndpoints.get = String.format(this.relatedEndpoints.get, data.alias, data.idIntervener)
        } else {
          this.formatedEndpoints.get = String.format(this.relatedEndpoints.get, data.id)
        }
        if (this.context === ContextName.EXPEDIENTS) {
          filterList = {
            idStage: (this as any).expedientStageState['stageId']
          }
        }
      }
      await this.fetchRelatedSelectedRegister({
        url: this.formatedEndpoints.get,
        filterData: filterList
      })
    }
    this.changeRelatedFormStatus({
      status: true,
      context: this.context
    })
  }

  onRemoveRelatedItem(relatedData: any, currentTabName: string) {
    this.relatedData = relatedData
    this.currentTabName = currentTabName
    const message: any = this.$t('components.dialog.' + this.currentTabName + '_remove', {
      associateRelated:
        this.relatedData.name || this.relatedData.code || this.relatedData.userName || this.relatedData.colorName,
      related: (this as any).selectedRegisterData(this.context).name
    })
    this.showDialog({
      type: DialogTypes.INFO,
      message,
      action: this.removeItem
    })
  }

  async removeItem() {
    let filterList: object | null = null
    if (this.context === ContextName.MAINTENANCE) {
      const currentTabData = (this as any).getCurrentViewTabs(this.context)[this.currentTabInfo.index].items[0]
      this.formatedEndpoints.delete = `maintenance/${currentTabData.maintenanceType}/${this.relatedData.id}`
      this.formatedEndpoints.list = `maintenance/${currentTabData.maintenanceType}/get`
    } else if (this.context === ContextName.MAINTENANCE_USERS_RELATED_FORM) {
      const { id: selectedUserId } = this.selectedRegisterData(ContextName.MAINTENANCE_USERS_CONFIG)
      this.formatedEndpoints.delete = `user/professionals/${this.relatedData.idConfiguracion}`
      this.formatedEndpoints.list = `user/professionals?idUser=${selectedUserId}`
    } else if (this.currentTabName === 'agendas') {
      this.formatedEndpoints.delete = `schedule/${this.relatedData.id}`
      this.formatedEndpoints.list = `schedule/${this.relatedData.idUser}/get`
    } else if (this.isContactContext) {
      this.formatedEndpoints.delete = String.format(
        this.relatedEndpoints.delete,
        this.selectedEntityName,
        this.relatedData.id
      )
      this.formatedEndpoints.list = String.format(
        this.relatedEndpoints.list,
        this.selectedEntityName,
        this.$route.params.selectedRegisterId
      )
    } else {
      this.formatedEndpoints.delete = String.format(this.relatedEndpoints.delete, this.relatedData.id)
      this.formatedEndpoints.list = String.format(this.relatedEndpoints.list, this.$route.params.selectedRegisterId)
      if (this.context === ContextName.EXPEDIENTS) {
        filterList = {
          idStage: (this as any).expedientStageState['stageId']
        }
      }
      if (this.context === ContextName.EXPEDIENT_ACTIONS) {
        this.formatedEndpoints.list = String.format(
          this.relatedEndpoints.list,
          (this as any).selectedRegisterData(this.context)[this.entity.keyId]
        )
      }
    }
    await this.removeRelatedFormItem({
      url: this.formatedEndpoints.delete
    })
    if (!this.existsEndpointError) {
      const message: any = this.$t('components.dialog.' + this.currentTabName + '_remove_success', {
        associateRelated: this.relatedData.name || this.relatedData.code || this.relatedData.colorName,
        related: (this as any).selectedRegisterData(this.context).name
      })
      this.showDialog({
        type: DialogTypes.SUCCESS,
        message
      })
      await this.listRelatedForm({
        url: this.formatedEndpoints.list,
        filterData: filterList
      })
    }
  }
}
</script>
