import { GetterTree } from 'vuex'
import { LegalSearchState, LegalSearchTabType } from '@/store/modules/legalSearch/legalSearchTypes'
import { RootState } from '@/store/types/storeGlobalTypes'

export const getters: GetterTree<LegalSearchState, RootState> = {
  getSearchData(state) {
    return state.data
  },
  getSearchTabs(state): LegalSearchTabType[] {
    return state.tabs
  },
  getTotalOccurrences(state): number {
    return state.totalOccurrences
  },
  getSessionId(state) {
    return state.sessionId
  },
  checkIfItsSearchRoute(state) {
    return state.isSearchView
  },
  getSearchTerm(state) {
    return state.searchTerm
  }
}
