<template lang="pug">
  div(class="expedient-actions")

    SpinnerLayerComponent(v-show="showSpinner" class="spinner-layer")

    div(v-show="!showSpinner")

      GridTableComponent(
        ref="grid"
        v-if="!showAddAction"
        :itemsData="gridData"
        :gridConfiguration="gridColumns"
        :title="title"
        :toolbarOptions="customToolbarOptions"
        :contextMenuItems="contextMenuItemsAction"
        :listName="listName"
        :context="actionContext"
        :showHeader="false"
        isInTab
        checkboxSelectColumn
        @cellClicked="onCellClicked"
        @contextMenuBeforeOpen="customOnContextMenuBeforeOpen"
        @contextMenuClicked="onContextMenuClicked"
        @toolbarClicked="onToolbarClicked"
        @gridTableFilter="onGridTableFilter"
        @selectedRecords="onSelectedRecords"
      )
      div(v-else)
        TabHeaderComponent(
          @closeForm="onCloseForm"
          @saveForm="onSaveForm"
          @removeItem="onRemoveItem"
          @executeAction="executeAction"
          :buttons="buttons"
          :title="getTitle"
        )
        TabsComponent(
          ref="tabsComponent"
          :tabs="getCurrentViewTabs(actionContext)"
          :context="actionContext"
          :entity="entity(actionContext)"
          :autoRefreshForm="autoRefreshForm"
          :permissionsEntityProp="permissionsEntity"
          :simpleGridContextMenuItemsProp="simpleGridContextMenuItems"
          class="tabs-second-level"
          @save-on-tab-change="saveOnTabChange"
          @form-created="onCreatedForm"
          @form-mounted="onInitActionForm"
          @field-changed="onActionFieldChanged"
          @changed-tab="onTabChange"
          :beforeSetComponentProperties="onBeforeSetComponentProperties"
        )

      ejs-contextmenu(
        id="expedientActionsContextMenuComponent"
        class="context-menu-component"
        :items="actionsContextMenuItems"
        :select="onActionAddClick"
      )

</template>

<script lang="ts">
import { Action, Getter, Mutation } from 'vuex-class'
import { ActionFields } from '@/views/actions/Actions/types/ActionDetailViewTypes'
import { ActionName, ActionBarButton } from '@/components/ActionsBar/types/ActionBarComponentTypes'
import { ActionRelationTypes, ActionTypes, setBasePathByActionType } from '@/store/modules/actions/actionsTypes'
import { AlertsTypes, ComponentWhereIsRendered } from '@/store/modules/alerts/alertsTypes'
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator'
import {
  ConfigurationAlias,
  ConfigurationElements,
  ConfigurationTypes
} from '@/store/modules/configuration/configurationTypes'
import { ContextName, ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import { CustomDialogComponentName, CustomDialogData, DialogTypes } from '@/store/modules/dialog/dialogTypes'
import { endpoints } from '@/store/modules/endpoint/endpointTypes'
import { entity, entityKeyFields } from '@/store/modules/entities/entitiesTypes'
import { Icons } from '@/icons/icons'
import { MainService } from '@/services/MainService'
import { URLS } from '@/router/routes/urlRoutes'
import ActionsMixin from '@/mixins/ActionsMixin.vue'
import GridTableComponent from '@/components/grids/GridTable/GridTableComponent.vue'
import SpinnerLayerComponent from '@/components/Spinner/SpinnerLayerComponent.vue'
import LexnetMixin from '@/mixins/LexnetMixin.vue'
import store from '@/store/store'
import GridContextMenuOptionsMixin from '@/mixins/GridContextMenuOptionsMixin.vue'
import GridToolbarOptionsMixin from '@/mixins/GridToolbarOptionsMixin.vue'
import { MenuItemModel } from '@syncfusion/ej2-vue-navigations'
import { TrackerEvents, trackEvent } from '@/plugins/tracker'
import { ConfigurationAppointmentVersion } from '@/store/modules/configuration/configurationTypes'

const actionsModule: string = ModuleNamespaces.ACTIONS
const configurationModule: string = ModuleNamespaces.CONFIGURATION
const dialogModule: string = ModuleNamespaces.DIALOG
const entitiesModule: string = ModuleNamespaces.ENTITIES
const expedientsModule: string = ModuleNamespaces.EXPEDIENTS
const fileManagerModule: string = ModuleNamespaces.FILE_MANAGER
const formsModule: string = ModuleNamespaces.FORMS
const listItemsModule = ModuleNamespaces.LIST_ITEMS
const selectedRegisterModule: string = ModuleNamespaces.SELECTED_REGISTER

@Component({
  components: {
    GridTableComponent,
    SpinnerLayerComponent,
    TabHeaderComponent: () => import('@/components/tabs/TabHeader/TabHeaderComponent.vue'),
    TabsComponent: () => import('@/components/tabs/TabsComponent/TabsComponent.vue')
  }
})
export default class ExpedientActionsComponent extends Mixins(
  ActionsMixin,
  GridContextMenuOptionsMixin,
  GridToolbarOptionsMixin,
  LexnetMixin
) {
  @Prop({
    type: Number,
    required: true
  })
  expedientId!: string

  @Prop({
    type: String,
    default: ''
  })
  columns!: string

  @Prop({
    type: String,
    default: ''
  })
  title!: string

  @Prop({
    type: String,
    default: ''
  })
  listName!: string

  @Prop({
    type: Boolean,
    default: false
  })
  showGrid!: boolean

  @Prop({
    type: Boolean,
    default: false
  })
  showFilters!: boolean

  @Action('createEntity', { namespace: entitiesModule })
  createEntity: ({}) => Promise<void>

  @Action('saveSelectedRegisterId', { namespace: selectedRegisterModule })
  saveSelectedRegisterId: ({}) => Promise<void>

  @Action('fetchSelectedRegisterData', { namespace: selectedRegisterModule })
  fetchSelectedRegisterData: ({}) => Promise<{}>

  @Action('deleteRegister', { namespace: selectedRegisterModule })
  deleteAction: ({}) => Promise<void>

  @Action('fetchCurrentViewConfiguration', { namespace: configurationModule })
  fetchCurrentViewConfiguration: ({}) => Promise<void>

  @Action('fetchCurrentListConfiguration', { namespace: configurationModule })
  fetchCurrentListConfiguration: (alias: string) => Promise<void>

  @Action('fetchExpedientsActionsSummary', { namespace: expedientsModule })
  fetchExpedientsActionsSummary: ({}) => void

  @Action('fetchExpedientsActions', { namespace: expedientsModule })
  fetchExpedientsActions: ({}) => void

  @Action('setExpedientStageStateForm', { namespace: expedientsModule })
  setExpedientStageStateForm: ({}) => void

  @Action('showCustomDialog', { namespace: dialogModule })
  showCustomDialogAction: ({}: CustomDialogData) => {}

  @Mutation('REMOVE_LIST_ITEMS', { namespace: listItemsModule })
  removeListItems: () => Promise<[]>

  @Getter('checkIfFormIsValid', { namespace: formsModule })
  checkIfFormIsValid: (context: string) => boolean

  @Getter('getExpedientStageState', { namespace: expedientsModule })
  expedientStageState: []

  @Getter('getExpedientStageStateForm', { namespace: expedientsModule })
  expedientStageStateForm: string

  @Getter('getCurrentViewTabs', { namespace: configurationModule })
  getCurrentViewTabs: (context: string) => []

  @Getter('getListItemsWithFormattedDates', { namespace: listItemsModule })
  gridData: []

  @Getter('getSelectedRegisterId', { namespace: selectedRegisterModule })
  selectedRegisterId: (context: string) => any

  @Getter('getSelectedActionType', { namespace: actionsModule })
  selectedActionType: string

  @Getter('getListItemsWithFormattedDates', { namespace: listItemsModule })
  listItems: object

  @Getter('getCurrentListConfiguration', { namespace: configurationModule })
  listConfiguration: object

  @Getter('showRelatedForm', { namespace: formsModule })
  showRelatedForm: (context: string) => boolean

  @Getter('isOpenFileManagerEditComponent', { namespace: fileManagerModule })
  isOpenFileManagerEditComponent: boolean

  entityType: number = 0

  showAddAction: boolean = false
  buttonsEnabled: boolean = true

  stageField: { field: any; index: number } = { field: null, index: 0 }

  actionDescription: string = ''

  autoRefreshForm: boolean = false

  contextMenuSelectedRegister: any = null

  lastObjectType: string = ConfigurationElements.FORM

  gridColumns = this.columns

  refreshTabFlag = false

  showSpinner: boolean = false

  reloadGridItemsFlag: boolean = false

  selectedActionId: number | null = null

  entityId: number = 0

  selectedActions: any[] = []

  get selectedActionIds() {
    return this.selectedActions.map((action) => action.actionId)
  }

  get selectedMoreThanOneAction() {
    return this.selectedActions.length > 1
  }

  get simpleGridContextMenuItems() {
    const { editItem, lookItem, removeItem, openWindow } = this.contextMenuOptionsTexts
    const { canSave, canDelete } = this.checkEntityPermissionsGetter(this.permissionsEntity)
    return [
      {
        text: canSave ? editItem : lookItem,
        iconCss: canSave ? Icons.EDIT : Icons.VISIBLE
      },
      {
        text: openWindow,
        iconCss: Icons.OPEN_WINDOW
      },
      ...(canDelete
        ? [
            {
              separator: true
            },
            {
              text: removeItem,
              iconCss: Icons.REMOVE,
              action: this.showDialog
            }
          ]
        : [])
    ]
  }

  get currentId() {
    if (this.selectedRegisterData(this.actionContext) && this.entity(this.actionContext)) {
      return (this as any).selectedRegisterData(this.actionContext)[this.entity(this.actionContext).keyId]
    }
    return 0
  }

  get idStage(): number | null {
    const stage: any = this.expedientStageState
    if (stage.stageId) {
      return stage.stageId
    }
    return null
  }

  get expedientHasStagesActive(): boolean {
    const stage: any = this.expedientStageState
    return stage.activeStages === 1
  }

  get getColumns() {
    if (this.columns) {
      return this.columns
    }
    return ''
  }

  get getTitle() {
    if (this.currentId) {
      const name = (this.selectedRegisterData(this.actionContext) as any)[this.entity(this.actionContext).keyName]
      return this.$t('forms.actions.edit_title', { name })
    }
    switch (this.selectedActionType) {
      case ActionTypes.CALLS:
        return this.$t('forms.actions.new_call_title')
      case ActionTypes.EMAILS:
        return this.$t('forms.actions.new_email_title')
      case ActionTypes.MEETINGS:
        return this.$t('forms.actions.new_meeting_title')
      case ActionTypes.OTHERS:
        return this.$t('forms.actions.new_other_title')
      case ActionTypes.PROCEDURES:
        return this.$t('forms.actions.new_procedure_title')
    }
  }

  get buttons() {
    if (this.currentId) {
      const { typeId } = this.selectedRegisterData(this.actionContext) as any
      const buttons = [
        {
          icon: Icons.LEXNET,
          tooltip: this.contextMenuOptionsTexts.openLexnet,
          action: ActionName.OPEN_IN_LEXNET,
          show: true,
          hidden: !(typeId === ActionTypes.LEXNET)
        },
        {
          icon: Icons.REMOVE,
          tooltip: this.$t('action_buttons.remove'),
          action: ActionName.REMOVE,
          show: !(
            this.showRelatedForm(this.actionContext) ||
            !this.buttonsEnabled ||
            this.isOpenFileManagerEditComponent
          ),
          hidden: !this.checkEntityPermissionsGetter(this.permissionsEntity).canDelete
        },
        {
          icon: Icons.CLOSE,
          tooltip: this.$t('action_buttons.close'),
          class: 'red-color',
          action: ActionName.CLOSE,
          show: !(
            this.showRelatedForm(this.actionContext) ||
            !this.buttonsEnabled ||
            this.isOpenFileManagerEditComponent
          )
        },
        {
          icon: Icons.CHECK,
          tooltip: this.$t('action_buttons.save'),
          class: 'green-color',
          action: ActionName.SAVE,
          show: !(
            !this.checkIfFormIsValid(this.actionContext) ||
            this.showRelatedForm(this.actionContext) ||
            !this.buttonsEnabled
          ),
          hidden: !this.checkEntityPermissionsGetter(this.permissionsEntity).canSave
        }
      ]
      return buttons.filter((button: ActionBarButton) => !button.hidden)
    }

    return [
      {
        icon: Icons.CLOSE,
        tooltip: 'Cerrar',
        class: 'red-color',
        action: ActionName.CLOSE,
        show: !(this.showRelatedForm(this.actionContext) || !this.buttonsEnabled)
      },
      {
        icon: Icons.CHECK,
        tooltip: 'Guardar',
        class: 'green-color',
        action: ActionName.SAVE,
        show: !(
          !this.checkIfFormIsValid(this.actionContext) ||
          this.showRelatedForm(this.actionContext) ||
          !this.buttonsEnabled
        )
      }
    ]
  }

  get renderingFromTheGlobalVisionNextEventsWidget(): boolean {
    return !!this.$route.query.action
  }

  get contextMenuItemsAction() {
    // El texto cambia por ver o editar en la función onContextMenuBeforeOpen del ActionMixin
    const { editItem, removeItem, lookItem } = this.contextMenuOptionsTexts
    return [
      {
        text: editItem,
        iconCss: Icons.EDIT
      },
      {
        text: lookItem,
        iconCss: Icons.VISIBLE
      },
      {
        text: removeItem,
        iconCss: Icons.REMOVE,
        action: this.showDialog
      }
    ]
  }

  get customToolbarOptions() {
    const { add, exportText, filter, print } = this.toolbarOptionsTooltipTexts
    return [
      {
        id: 'filter',
        prefixIcon: Icons.FILTER,
        text: filter,
        align: 'left',
        cssClass: 'lf-clean-filter-button'
      },
      {
        id: 'search',
        text: 'Search',
        align: 'right'
      },
      {
        id: 'print',
        prefixIcon: Icons.PRINT,
        tooltipText: print,
        align: 'right'
      },
      {
        id: 'export',
        prefixIcon: Icons.DOWNLOAD,
        tooltipText: exportText,
        align: 'right'
      },
      ...(this.userHasPermissionToSaveAtLeastOneActionTypeIncludingEmails
        ? [
            {
              id: 'add',
              prefixIcon: Icons.ADD,
              tooltipText: add,
              align: 'right'
            }
          ]
        : [])
    ]
  }

  get permissionsEntity() {
    return this.entityId
  }

  @Watch('expedientStageState')
  changeStage() {
    this.refreshExpedientActionsSummary()
    this.toggleStageField()
  }

  @Watch('$route', { immediate: true })
  async onRouteChange(to: any) {
    if (to.query.action) {
      this.actionContext = ContextName.EXPEDIENT_ACTIONS
      this.onActionAddClick(to.query.action)
    }
  }

  @Watch('showAddAction')
  async emitDisabledTabs(disable: boolean) {
    if (disable) {
      this.$emit('disabledTabs')
    } else {
      this.refreshExpedientActionsSummary()
      this.$emit('enabledTabs')
    }
  }

  created() {
    this.actionContext = ContextName.EXPEDIENT_ACTIONS
    this.$emit('setDisabledTabsMessage', this.$t('components.alerts.close_action_form'))
  }

  mounted() {
    this.checkIfOpenFormOnFirstLoad()
  }

  beforeDestroy() {
    if (this.renderingFromTheGlobalVisionNextEventsWidget) {
      this.$router.push(this.$route.path)
    }
  }

  onInitActionForm(params: any) {
    const { formData } = params

    this.toggleStageField()

    const isNew = !this.entity(this.actionContext).id

    if (isNew) {
      formData[ActionFields.STAGE] = this.idStage ? this.idStage : null
      formData[ActionFields.ACTION_TYPE] = this.selectedActionType
    }

    this.onInitForm(params)
  }

  onActionFieldChanged(schema: any, formData: any, field: any, value: any) {
    if (ActionFields.STAGE === field.id) {
      this.setExpedientStageStateForm(value)
    }

    this.onFieldChanged(schema, formData, field, value)

    if (ActionFields.ACTION_TYPE === field.id) {
      this.checkFormPermissions(schema, this.entityType)
    }
  }

  onBeforeSetComponentProperties(component: any, _tab: any, _tabIndex: number) {
    component.basePath = setBasePathByActionType(this.getSelectedActionType)
  }

  toggleStageField() {
    if (!this.showAddAction) {
      return true
    }

    const stageField = this.$el.querySelector('#idStage') as HTMLElement | null

    if (null !== stageField) {
      if (this.expedientHasStagesActive) {
        stageField.style.display = 'inherit'
      } else {
        stageField.style.display = 'none'
      }
    }
  }

  executeAction(actionName: string) {
    if (ActionName.OPEN_IN_LEXNET === actionName) {
      this.openInLexnet(this.selectedRegisterData(this.actionContext))
    }
  }

  async onActionAddClick(args: any) {
    if (args.item.actionType === ActionTypes.LEXNET) {
      this.openLexnetInNewWindow()
      return
    }
    if (args.item.actionType === ActionTypes.EMAILS && this.companyMailVersion === ConfigurationAppointmentVersion.LEXBOX_VERSION) {
      this.$tracker.trackEvent(TrackerEvents.CREATE_MAIL_ACTION)
    }
    this.$emit('openForm')
    let actionType = args
    if (typeof args === 'object') {
      actionType = args.item.actionType
      this.entityId = args.item.entityId
    }
    await this.saveSelectedRegisterId({
      id: 0,
      context: this.actionContext
    })
    this.contextMenuSelectedRegister = null
    await this.openAction(actionType)
  }

  async onCellClicked(selectedRegister: any) {
    trackEvent(TrackerEvents.EDIT_ACTION)
    this.contextMenuSelectedRegister = selectedRegister
    this.entityId = selectedRegister.idEntity
    this.$emit('openForm')
    if (this.isPortalUser) {
      return
    }

    if (this.selectedActionId !== selectedRegister.actionId) {
      this.selectedActionId = selectedRegister.actionId
      await this.onEditAction(selectedRegister)
    }
  }

  async onEditAction(selectedRegister: any) {
    this.setExpedientStageStateForm(selectedRegister.idStage)
    await this.openAction(selectedRegister.typeId, selectedRegister.actionId)
  }

  async onRemoveAction(selectedRegister: any) {
    const entityType = this.getEntityTypeByActionType(selectedRegister.typeId)

    await this.createEntity({
      entityType,
      idEntity: selectedRegister[entityKeyFields.actions.id],
      context: this.actionContext
    })

    await this.saveSelectedRegisterId({
      id: selectedRegister[entityKeyFields.actions.id],
      context: this.actionContext
    })

    this.actionDescription = selectedRegister[entityKeyFields.actions.name]
    this.showRemoveDialog(this.actionDescription)
  }

  showRemoveDialog(title: string) {
    this.showDialog({
      type: DialogTypes.INFO,
      message: this.$t('components.dialog.remove_register_text', {
        text: this.$t('components.dialog.actions_literal'),
        register: title
      }),
      action: this.removeAction
    })
  }

  getEntityTypeByActionType(actionType: ActionTypes) {
    switch (actionType) {
      case ActionTypes.CALLS:
        return entity.actions.calls.type
      case ActionTypes.MEETINGS:
        return entity.actions.meetings.type
      case ActionTypes.LEXNET:
        return entity.actions.lexnet.type
      case ActionTypes.PROCEDURES:
        return entity.actions.procedures.type
      case ActionTypes.OTHERS:
        return entity.actions.others.type
      case ActionTypes.EMAILS:
        return entity.actions.emails.type
    }
  }

  async openAction(actionType: ActionTypes, idEntity: number = 0, target: string = '_self') {
    const configurationType: string = ConfigurationTypes.VIEW
    let configurationAlias: string = ''

    switch (actionType) {
      case ActionTypes.CALLS:
        this.entityType = entity.actions.calls.type
        this.saveSelectedActionType(ActionTypes.CALLS)
        configurationAlias = ConfigurationAlias.ACTION
        break
      case ActionTypes.MEETINGS:
        this.entityType = entity.actions.meetings.type
        this.saveSelectedActionType(ActionTypes.MEETINGS)
        configurationAlias = ConfigurationAlias.ACTION
        break
      case ActionTypes.PROCEDURES:
        this.entityType = entity.actions.procedures.type
        this.saveSelectedActionType(ActionTypes.PROCEDURES)
        configurationAlias = ConfigurationAlias.ACTION
        break
      case ActionTypes.OTHERS:
        this.entityType = entity.actions.others.type
        this.saveSelectedActionType(ActionTypes.OTHERS)
        configurationAlias = ConfigurationAlias.ACTION
        break
      case ActionTypes.LEXNET:
        this.entityType = entity.actions.lexnet.type
        this.saveSelectedActionType(ActionTypes.LEXNET)
        configurationAlias = ConfigurationAlias.ACTION_LEXNET
        break
      case ActionTypes.EMAILS:
        if (idEntity > 0) {
          if (target === '_blank') {
            const routeData = this.$router.resolve({
              name: `${URLS.ACTIONS}-${URLS.ACTION_EMAILS}`,
              params: { selectedRegisterId: idEntity as any, from: URLS.ACTION_ALL }
            })
            window.open((routeData as any).href, '_blank')
          } else {
            this.entityType = entity.actions.emails.type
            this.saveSelectedActionType(ActionTypes.EMAILS)
            configurationAlias = ConfigurationAlias.ACTION_EMAILS
          }
        } else {
          let url = `/${URLS.EMAIL}/${this.expedientId}`
          target = '_blank'
          if (this.contextMenuSelectedRegister) {
            url = `/${URLS.EMAIL}/edit/${this.contextMenuSelectedRegister.actionId}`
          }
          window.open(`${url}`, target)
          return true
        }
        break
    }

    await this.createEntity({
      entityType: this.entityType,
      idEntity,
      context: this.actionContext
    })

    this.autoRefreshForm = true

    if (idEntity) {
      this.autoRefreshForm = false

      await this.saveSelectedRegisterId({
        id: idEntity,
        context: this.actionContext
      })

      await this.fetchSelectedRegisterData({
        endpoint: this.endpoints(this.entity(this.actionContext).type).get,
        context: this.actionContext,
        post: { idFile: this.expedientId }
      })
    }

    await this.fetchCurrentViewConfiguration({
      objectType: configurationType,
      alias: configurationAlias,
      context: this.actionContext
    })

    this.showAddAction = true
  }

  async onTabChange(tabIndex: number, objectType: number, forceSave: boolean) {
    if (tabIndex !== 0) {
      this.refreshTabFlag = true
      this.reloadGridItemsFlag = true
    }

    if (this.currentId) {
      if (this.lastObjectType !== ConfigurationElements.FORM && (0 === tabIndex || forceSave)) {
        await this.resetFormData()
        await this.fetchSelectedRegisterData({
          endpoint: this.endpoints(this.entity(this.actionContext).type).get,
          context: this.actionContext,
          post: { idFile: this.expedientId }
        })
      }

      if (0 === tabIndex && this.refreshTabFlag && this.$children && this.$children.length > 1) {
        // Refresca el componente dinamico creado en el TabsComponent
        if (typeof (this as any).$children[2].refreshComponentManually === 'function') {
          ;(this as any).$children[2].refreshComponentManually()
        }
      }

      this.lastObjectType = objectType.toString()
    }
  }

  async saveOnTabChange(_redirectToGridTable = true, resolve: any = null) {
    this.save(false, resolve)
  }

  async save(closeForm: boolean = true, resolve: any = null) {
    await this.saveSelectedRegisterId({
      id: this.entity(this.actionContext).id,
      context: this.actionContext
    })

    const isNew = !this.entity(this.actionContext).id

    this.isSavingForm = true

    this.regularizeFieldsOnSave(this.selectedActionType)

    await this.$nextTick()

    await this.saveRegisterFormData({
      endpoint: this.endpoints(this.entity(this.actionContext).type).save,
      idSelectedRegister: this.entity(this.actionContext).id,
      goToURL: null,
      context: this.actionContext
    })

    this.isSavingForm = false

    if (isNew && this.selectedRegisterId(this.actionContext)) {
      const data: {
        idAction: number
        idRelation: string
        idActionRelationType: string
        idStage: string | null
      } = {
        idAction: this.selectedRegisterId(this.actionContext),
        idRelation: this.expedientId,
        idActionRelationType: ActionRelationTypes.EXPEDIENT,
        idStage: this.expedientStageStateForm
      }

      await new MainService().postData(endpoints.actionsRelations.save, data)
    }

    if (closeForm) {
      await this.refreshActionList()
      this.showAddAction = false
      this.showAlertResult(this.$t('components.alerts.register_save'), AlertsTypes.SUCCESS)
    }

    if (resolve) {
      resolve()
    }
  }

  async onSaveForm() {
    this.selectedActionId = null
    await this.save()
    this.$emit('closeForm')
  }

  async onCloseForm() {
    if (this.renderingFromTheGlobalVisionNextEventsWidget) {
      this.$router.push(this.$route.path)
      return
    }

    this.showSpinner = true

    if (this.reloadGridItemsFlag && !this.renderingFromTheGlobalVisionNextEventsWidget) {
      await this.fetchExpedientsActions({
        selectedRegisterId: this.selectedRegisterId('expedients')
      })
      this.reloadGridItemsFlag = false
    }

    await this.fetchCurrentListConfiguration('listExpedientsActions')
    this.gridColumns = (this as any).listConfiguration['Config']
    this.showAddAction = false
    this.selectedActionId = null
    this.showSpinner = false
    this.$emit('closeForm')
  }

  onRemoveItem() {
    trackEvent(TrackerEvents.REMOVE_ACTION)
    this.actionDescription = (this.selectedRegisterData(this.actionContext) as any)[
      this.entity(this.actionContext).keyName
    ]
    this.showRemoveDialog(this.actionDescription)
    this.$emit('closeForm')
  }

  async refreshActionList() {
    const filter: any = {
      idStage: this.idStage
    }

    await this.removeListItems()
    await this.fetchCurrentListConfiguration('listExpedientsActions')
    await this.$store.dispatch(`expedientsModule/fetchExpedientsActions`, {
      selectedRegisterId: this.expedientId,
      listName: 'listExpedientsActions',
      filter
    })

    this.gridColumns = (this as any).listConfiguration['Config']
  }

  async removeAction() {
    await this.deleteAction({
      deleteEndpoint: this.endpoints(this.entity(this.actionContext).type).delete,
      listEndpoint: this.endpoints(this.entity(this.actionContext).type).list,
      goToURL: null,
      name: this.actionDescription,
      dialogText: this.$t('components.dialog.actions_literal')
    })
    await this.refreshActionList()
    this.refreshExpedientActionsSummary()
    this.showAddAction = false
  }

  downloadActions() {
    if (this.$refs.grid) {
      ;(this.$refs.grid as any).excelExport()
    }
  }

  showAlertResult(message: any, alertType: AlertsTypes) {
    store.dispatch(
      `${ModuleNamespaces.ALERTS}/showAlert`,
      {
        type: alertType,
        message,
        componentWhereIsRendered: ComponentWhereIsRendered.TABS_VIEW
      },
      { root: true }
    )
  }

  refreshExpedientActionsSummary(filters: any = {}) {
    this.fetchExpedientsActionsSummary({
      idExpedient: this.expedientId,
      idStage: this.idStage,
      ...filters
    })
  }

  async onContextMenuClicked(args: any, selectedRegister: any) {
    const { editItem, lookItem, removeItem, openLfMail, openLexnet, openWindow, associateAction, moveAction } =
      this.contextMenuOptionsTexts
    if (args.item) {
      if (args.item.text === editItem || args.item.text === lookItem) {
        this.onEditAction(selectedRegister)
      }
      if (args.item.text === removeItem) {
        trackEvent(TrackerEvents.REMOVE_ACTION)
        this.onRemoveAction(selectedRegister)
      }
      if (args.item.text === openLfMail) {
        trackEvent(TrackerEvents.EDIT_ACTION)
        trackEvent(TrackerEvents.OPEN_LEFEBVRE_MAIL)
        let url = `/${URLS.EMAIL}`
        const target = '_blank'
        if (this.contextMenuSelectedRegister) {
          url = `/${URLS.EMAIL}/edit/${this.contextMenuSelectedRegister.actionId}`
        }
        window.open(`${url}`, target)
      }
      if (args.item.text === openLexnet) {
        trackEvent(TrackerEvents.EDIT_ACTION)
        this.openInLexnet(selectedRegister)
      }
      if (args.item.text === openWindow) {
        trackEvent(TrackerEvents.EDIT_ACTION)
        await this.openAction(selectedRegister.typeId, selectedRegister.actionId, '_blank')
      }
      if (args.item.text === associateAction) {
        this.showCustomDialogAction({
          name: CustomDialogComponentName.ASSOCIATE_ACTION,
          styles: {
            maxWidth: '750px'
          },
          props: {
            selectedActionIds: this.selectedActionIds,
          }
        })
      }
      if (args.item.text === moveAction) {
        this.showCustomDialogAction({
          name: CustomDialogComponentName.MOVE_ACTION,
          styles: {
            maxWidth: '750px'
          },
          props: {
            selectedActionIds: this.selectedActionIds,
            filter: {
              idStage: this.idStage
            }
          }
        })
      }
    }
    args.cancel = true
  }

  checkIfOpenFormOnFirstLoad() {
    if (this.renderingFromTheGlobalVisionNextEventsWidget) {
      this.openAction(this.$route.query.action as ActionTypes)
    }
  }

  onGridTableFilter(filters: any) {
    this.refreshExpedientActionsSummary(filters)
  }

  customOnContextMenuBeforeOpen(contextMenuModule: any, contextMenuGridObject: any, selectedRegister: any) {
    this.onContextMenuBeforeOpen(contextMenuModule, contextMenuGridObject, selectedRegister)
    this.enableContextMenuItems(contextMenuModule, contextMenuGridObject)
  }

  enableContextMenuItems(contextMenuModule: any, contextMenuGridObject: any): void {
    contextMenuGridObject.items.forEach((item: MenuItemModel) => {
      const enable = (
        !this.selectedMoreThanOneAction ||
        (item.text === this.contextMenuOptionsTexts.moveAction || item.text === this.contextMenuOptionsTexts.associateAction)
      )
      contextMenuModule.enableItems([item.text], enable)
      contextMenuGridObject.enableItems([item.text], enable)
    })
  }

  onSelectedRecords(selectedItems: any) {
    this.selectedActions = Array.isArray(selectedItems) ? selectedItems : ([] as any)
  }
}
</script>

<style lang="scss" scoped>
.expedient-actions {
  .spinner-layer {
    width: 100%;
    height: 100%;
    min-height: 470px;
    background-color: $white-01;
  }

  .tab-header-container {
    border: none;
    margin-bottom: 0;
    padding-bottom: 0;
  }

  ::v-deep #file-manager_layout {
    border: 1px solid $corporate-color !important;
  }
}
</style>
