import { MutationTree } from 'vuex'
import { LexboxState } from '@/store/modules/lexbox/lexboxTypes'

export const mutations: MutationTree<LexboxState> = {
  FETCH_CALENDAR_DATA(state, data) {
    state.calendarData = data
  },
  RESET_CALENDAR_DATA(state) {
    state.calendarData = null
  },
  FETCH_CALENDAR_PROVIDER(state, data) {
    state.calendarProvider = data
  },
  RESET_CALENDAR_PROVIDER(state) {
    state.calendarProvider = null
  },
  FETCH_EMAIL_PROVIDER(state, { provider, email }) {
    state.emailProvider = { provider, email }
  },
  RESET_EMAIL_PROVIDER(state) {
    state.emailProvider = null
  },
  SAVE_CALENDAR_SERVICE_URL(state, url) {
    state.calendarServiceUrl = url
  },
  RESET_CALENDAR_SERVICE_URL(state) {
    state.calendarServiceUrl = null
  },
  SAVE_EVENT_CALENDAR_SERVICE_URL(state, url) {
    state.eventCalendarServiceUrl = url
  },
  RESET_EVENT_CALENDAR_SERVICE_URL(state) {
    state.eventCalendarServiceUrl = ''
  },
  FETCH_ACCESS_TOKEN(state, res) {
    state.accessToken = res.data.token
  }
}
