export interface NumerationDetailsItem {
  idNumerationType: number
  idSeparator: number
  idNumerationFormat: number
  numOrder: number
  value: string | number
}

export enum ComponentFormFields {
  USER = 'idUser',
  EXPEDIENT_TYPE = 'idExpedientType'
}
