<template lang="pug">

  section(class="error-page-container")

    HeaderComponent

    section(class="error-page-section")

      div(class="error-page-card")

        span(class="error-number") 404

        span(class="error-text") {{ $t('views.error_page.error_text') }}

        div(class="error-message")
          p {{ $t('views.error_page.error_ocurred') }}
          p {{ $t('views.error_page.requested_page_not_found') }}

        button(
          @click="goToDashboard"
          class="go-to-dashboard-button"
          ) {{ $t('views.error_page.go_to_dashboard')}}

        CardFooterComponent

</template>

<script lang="ts">
import { Vue, Component } from 'vue-property-decorator'
import HeaderComponent from '@/components/Header/HeaderComponent.vue'
import { URLS } from '@/router/routes/urlRoutes'
import CardFooterComponent from '@/components/CardFooter/CardFooterComponent.vue'

@Component({
  components: {
    HeaderComponent,
    CardFooterComponent
  }
})
export default class PageNotFoundView extends Vue {
  goToDashboard() {
    this.$router.push({ name: URLS.DASHBOARD })
  }
}
</script>

<style lang="scss" scoped>
.error-page {
  &-container {
    display: grid;
    grid-template-rows: 60px 1fr;
    height: 100%;
    font-family: $corporate-font;
    color: $corporate-color;
  }

  &-section {
    @include flex;
    margin: 30px;
  }

  &-card {
    @include flex($flex-direction: column, $justify-content: flex-start);
    @include borders;
    width: 450px;
    padding-top: 50px;
  }
}

.error-number {
  position: relative;
  font-family: $secondary-font;
  font-size: 120px;
  font-weight: bold;
  line-height: 120px;

  &::after {
    display: inline-block;
    position: absolute;
    top: -25px;
    font-family: $lf-icons-font;
    font-size: 30px;
    content: '\ea03';
  }
}

.error-text {
  text-transform: uppercase;
}

.error-message {
  color: $gray-07;
  text-align: center;
  padding: 80px 85px 22px;

  > p {
    margin-bottom: 0;
  }
}

.go-to-dashboard-button {
  @include blue-button;
  margin-bottom: 35px;
}
</style>
