<script lang="ts">
import { Vue, Component } from 'vue-property-decorator'
import { downloadExcelByArray } from '@/helpers/downloadExcel'
import { ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import { Action } from 'vuex-class'
import { DialogTypes } from '@/store/modules/dialog/dialogTypes'
import { getArrayValuesByProp } from '@/helpers/array'

const invoicesModule = ModuleNamespaces.INVOICES
const dialogModule = ModuleNamespaces.DIALOG

@Component
export default class InvoicesToolBarMixin extends Vue {
 
  @Action('showDialog', { namespace: dialogModule }) 
  openDialog: ({}) => void
  @Action('fetchSageInvoices', { namespace: invoicesModule })
  fetchSageInvoices: ({}) => Promise<[]>
  
  get invoicesExportContextMenuItems() {
    return [
      {
        text: 'Excel',
        actionType: 'downloadExcelGrid'
      },
      {
        text: 'Sage',
        actionType: 'downloadExcelSage'
      }
    ]  
  }

  onInvoiceExportToolbarClicked(args: any) {
    const target = args.originalEvent.target.closest('button')
    if (target && target.id === 'downloadExcel') {
      this.toggleExportContextMenu(args.originalEvent)
      args.cancel = true
    }
  }

  toggleExportContextMenu(event: MouseEvent) {
    if (event) {
      const btn: EventTarget | null = event.target
      if (btn) {
        const rect: DOMRect = (btn as HTMLHtmlElement).getBoundingClientRect()
        const elem: any = document.getElementById('invoicesActionsContextMenuComponent')
        if (null !== elem) {
          elem.style.width = "125px"
          elem.ej2_instances[0].open(rect.top + 43, rect.left - 83)
        }
      }
    }
  }

  async  onInvoicesExportContextMenuAddClick(args: any) {
    const actionType = args.item.actionType || ''
    switch (actionType) {
      case 'downloadExcelGrid':
          this.downloadExcelGrid()
        break;
      case 'downloadExcelSage':
          this.downloadExcelSage()
        break;
    }
  }

  downloadExcelGrid() {
    if (this.$refs.grid) {
      const gridComponent = this.$refs.grid as any
      gridComponent.excelExport()
    }
  }

  async downloadExcelSage() {
    const gridComponent = this.$refs.grid ? this.$refs.grid as any : false
    if(!gridComponent) {
      return false
    }

    const selectedData: any[] = gridComponent.gridInstance.selectionModule.deSelectedData || []
    if(selectedData.length <= 0) {
      this.openDialog({
        type: DialogTypes.WARNING,
        message: this.$t('components.alerts.customer_invoices_sage_info'),
        hideSecondaryButton: true
      })
      return false
    }

    const ids: any[] = getArrayValuesByProp(selectedData, 'id')
    const invoices = await this.fetchSageInvoices(ids)
    const isSageExcelDownloaded = downloadExcelByArray(
      invoices, 
      this.$t('files_name.invoice_sage_export').toString(),
      'Emitidas',
      false
    )

    if (!isSageExcelDownloaded) {
      this.openDialog({
        type: DialogTypes.ERROR,
        message: this.$t('components.alerts.customer_invoices_sage_error')
      })
    }
  }
}
</script>
