export interface Maintenance {
  idFile: string | null
}

export interface SummaryItem {
  alias: string
  detail: string
  totalAmount: string
}

export interface ActionsSummaryItem {
  alias: string
  detail: string
  total: string
}

export interface DashboardMaintenance {
  idMaintenance: string
  idOwnFile: string
  description: string
  startDate: string
  modifiedDate: string
  canEdit: string
  canDelete: string
}

export interface StageStatus {
  activeStages: number
  stageId: number
}

export interface MaintenanceState {
  actionsSummary: ActionsSummaryItem[]
  dashboardMaintenanceList: DashboardMaintenance[]
  economicDataSimpleGridItems: []
  maintenance: Maintenance[]
  lastMaintenanceSaved: number | null
  stageStatus: StageStatus[]
  summary: SummaryItem[]
  taxesItems: []
  total: string
  selectedMaintenanceInsurer: {}
}

export const maintenanceMutationTypes = {
  FETCH_DASHBOARD_MAINTENANCE: 'FETCH_DASHBOARD_MAINTENANCE',
  FETCH_MAINTENANCE: 'FETCH_MAINTENANCE',
  FETCH_MAINTENANCE_ACTIONS_SUMMARY: 'FETCH_MAINTENANCE_ACTIONS_SUMMARY',
  FETCH_MAINTENANCE_ECONOMIC_DATA: 'FETCH_MAINTENANCE_ECONOMIC_DATA',
  FETCH_MAINTENANCE_STAGE_STATE: 'FETCH_MAINTENANCE_STAGE_STATE',
  FETCH_MAINTENANCE_SUMMARY: 'FETCH_MAINTENANCE_SUMMARY',
  FETCH_TOTAL_NUMBER_OF_MAINTENANCE: 'FETCH_TOTAL_NUMBER_OF_MAINTENANCE',
  LAST_MAINTENANCE_SAVED: 'LAST_MAINTENANCE_SAVED',
  RESET_MAINTENANCE_ACTIONS_SUMMARY: 'RESET_MAINTENANCE_ACTIONS_SUMMARY',
  RESET_MAINTENANCE_SUMMARY: 'RESET_MAINTENANCE_SUMMARY',
  RESET_SELECTED_MAINTENANCE_INSURER: 'RESET_SELECTED_MAINTENANCE_INSURER',
  RESET_TAXES_ITEMS: 'RESET_TAXES_ITEMS',
  SET_ECONOMIC_DATA_SIMPLE_GRID_ITEMS: 'SET_ECONOMIC_DATA_SIMPLE_GRID_ITEMS',
  SET_SELECTED_MAINTENANCE_INSURER: 'SET_SELECTED_MAINTENANCE_INSURER',
  SET_TAXES_ITEMS: 'SET_TAXES_ITEMS'
}
