<template lang="pug">

  ul(class="recipients-list")
    li(
      v-for="(recipient, index) in recipients"
      :key="index"
      class="audit-grid-item"
    ) {{ recipient }}

</template>

<script lang="ts">
import { Vue } from 'vue-property-decorator'
import { Component } from 'vue-property-decorator'
import { ParsedFirmaAudit } from '@/store/modules/firma/firmaTypes'

@Component({
  name: 'monitoringDialogRecipientColumnTemplate'
})
export default class MonitoringDialogRecipientColumnTemplateComponent extends Vue {
  data: ParsedFirmaAudit

  get recipients() {
    // TODO: Quitar trim cuando implementen en firma
    const recipients = this.data.recipients.map((recipient) => recipient.recipient.trim())
    return recipients.sort()
  }
}
</script>

<style lang="scss" scoped>
.recipients-list {
  @include list;
}
</style>
