import { GetterTree } from 'vuex'
import { DialogState, DialogData } from '@/store/modules/dialog/dialogTypes'
import { RootState } from '@/store/types/storeGlobalTypes'

export const getters: GetterTree<DialogState, RootState> = {
  showDialog(state) {
    return state.showDialog
  },
  showCustomDialog(state) {
    return state.showCustomDialog
  },
  getDialogData(state): DialogData | null {
    return state.dialogData
  },
  getCustomDialogData(state) {
    return state.customDialogData
  }
}
