<template lang="pug">

  div(class="email-template-customer-portal-tab-container" v-if="!showEditTemplate")
    GridTable(
      v-if="Object.keys(listConfiguration).length"
      :itemsData="listItems"
      :gridConfiguration="listConfiguration['Config']"
      :title="$t('views.contacts_opponents.title')"
      :toolbarOptions="toolbarOptions"
      :contextMenuItems="contextMenu"
      :listName="listConfiguration['Alias']"
      :context="context"
      :showHeader="false"
      isInTab
      :showColumnChooser="false"
      @contextMenuClicked="onContextMenuClicked"
      @cellClicked="onCellClicked"
    )

    SpinnerLayerComponent(v-else class="spinner-layer")

  EditTemplateComponent(
    v-else
    :template="selectedTemplate"
    @on-close-form="showGrid"
  )
</template>

<script lang="ts">
import { mixins } from 'vue-class-component'
import { Component } from 'vue-property-decorator'
import GridTable from '@/components/grids/GridTable/GridTableComponent.vue'
import SpinnerLayerComponent from '@/components/Spinner/SpinnerLayerComponent.vue'
import { ContextName, ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import ListViewMixin from '@/mixins/ListViewMixin.vue'
import { ConfigurationAlias, ListNames } from '@/store/modules/configuration/configurationTypes'
import { Icons } from '@/icons/icons'
import EditTemplateComponent from './EditTemplateComponent.vue'
import { Action } from 'vuex-class'
import { EmailTemplate } from '@/components/maintenance/customerPortal/types/maintenanceCustomerPortalTypes'

const maintenanceModule = ModuleNamespaces.MAINTENANCE

@Component({
  components: {
    GridTable,
    SpinnerLayerComponent,
    EditTemplateComponent
  }
})
export default class EmailTemplatesCustomerPortalTabComponent extends mixins(ListViewMixin) {
  @Action('fetchMaintenanceType', { namespace: maintenanceModule })
  fetchTemplatesAction: ({}) => Promise<EmailTemplate[]>

  showEditTemplate = false

  selectedTemplate: EmailTemplate = { id: '', name: '', subject: '' }

  get toolbarOptions() {
    const { exportText, filter, print } = this.toolbarOptionsTooltipTexts
    return [
      {
        id: 'filter',
        prefixIcon: Icons.FILTER,
        text: filter,
        align: 'left',
        cssClass: 'lf-clean-filter-button'
      },
      {
        id: 'search',
        text: 'Search',
        align: 'right'
      },
      {
        id: 'print',
        prefixIcon: Icons.PRINT,
        tooltipText: print,
        align: 'right'
      },
      {
        id: 'export',
        prefixIcon: Icons.DOWNLOAD,
        tooltipText: exportText,
        align: 'right'
      }
    ]
  }

  get contextMenu() {
    const { editItem } = this.contextMenuOptionsTexts
    return [
      {
        text: editItem,
        iconCss: Icons.EDIT
      }
    ]
  }

  get context() {
    return ContextName.MAINTENANCE
  }

  created() {
    this.init()
  }

  async init() {
    await this.fetchTemplatesAction({ maintenanceType: ConfigurationAlias.MAINTENANCE_CUSTOMER_PORTAL_TEMPLATES })
    await this.fetchCurrentListConfiguration(ListNames.CUSTOMER_PORTAL_TEMPLATES_MAINTENANCE)
  }

  openEmailTemplate(selectedRegister: EmailTemplate) {
    this.selectedTemplate = selectedRegister
    this.showEditTemplate = true
  }

  onContextMenuClicked(_args: any, selectedRegister: EmailTemplate) {
    this.openEmailTemplate(selectedRegister)
  }

  async showGrid() {
    await this.fetchTemplatesAction({ maintenanceType: ConfigurationAlias.MAINTENANCE_CUSTOMER_PORTAL_TEMPLATES })
    this.showEditTemplate = false
  }

  async onCellClicked(selectedRegister: EmailTemplate) {
    this.openEmailTemplate(selectedRegister)
  }
}
</script>

<style lang="scss" scoped>
.email-template-customer-portal-tab-container {
  .spinner-layer {
    height: 200px;
  }

  ::v-deep .e-toolbar-right .e-toolbar-item:last-child {
    border-radius: 0 50% 50% 0 !important;
    padding: 0;
  }
}
</style>
