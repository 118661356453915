// Globalization
import { setCulture, L10n, loadCldr, setCurrencyCode } from '@syncfusion/ej2-base'
import components from '@/translations/syncFusionComponents/components.json'
import fileManagerTranslations from '@/translations/syncFusionComponents/fileManager.json'
import documentEditorContainer from '@/translations/syncFusionComponents/documentEditorContainer.json'
import richTextEditor from '@/translations/syncFusionComponents/richTextEditor.json'
import currencies from 'cldr-data/main/es/currencies.json'
import currencyData from 'cldr-data/supplemental/currencyData.json'
import numberingSystems from 'cldr-data/supplemental/numberingSystems.json'
import gregorian from 'cldr-data/main/es/ca-gregorian.json'
import numbers from 'cldr-data/main/es/numbers.json'
import timeZoneNames from 'cldr-data/main/es/timeZoneNames.json'
import weekData from 'cldr-data/supplemental/weekData.json'
// Spinner
import { setSpinner } from '@syncfusion/ej2-popups'
// Registering Syncfusion license key
import { registerLicense } from '@syncfusion/ej2-base'
registerLicense('ORg4AjUWIQA/Gnt2VFhhQlJBfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hSn5QdEJjWn1ecHRXQGNb')

setCulture('es')
setCurrencyCode('EUR')

loadCldr(numberingSystems, gregorian, numbers, timeZoneNames, weekData, currencyData, currencies)

L10n.load(components)
L10n.load(fileManagerTranslations)
L10n.load(documentEditorContainer)
L10n.load(richTextEditor)

setSpinner({
  template: `
    <div class='grid-table-spinner-container'>
      <div class='grid-table-spinner-background'></div>
      <span class='spinner-lefebvre spinner-md'></span>
    </div>
`
})
