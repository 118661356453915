import { render, staticRenderFns } from "./LexboxRedirectView.vue?vue&type=template&id=a3022eb8&scoped=true&lang=pug"
import script from "./LexboxRedirectView.vue?vue&type=script&lang=ts"
export * from "./LexboxRedirectView.vue?vue&type=script&lang=ts"
import style0 from "./LexboxRedirectView.vue?vue&type=style&index=0&id=a3022eb8&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a3022eb8",
  null
  
)

export default component.exports