import { MutationTree } from 'vuex'
import { FileState } from '@/store/modules/fileManager/fileManagerTypes'

export const mutations: MutationTree<FileState> = {
  BLOB_FILE(state, file) {
    state.file = file
  },
  SAVE_MAX_FILE_UPLOAD_SIZE(state, maxFileUploadSize) {
    state.maxFileUploadSize = maxFileUploadSize
  },
  SAVE_ALLOWED_EXTENSIONS(state, allowedExtensions) {
    state.allowedExtensions = allowedExtensions
  },
  SAVE_CURRENT_PATH(state, path) {
    state.currentPath = path
  },
  SAVE_DOCUMENT(state, document) {
    state.document = document
  },
  SAVE_PATH(state, path) {
    state.path = path
  },
  SAVE_ZIP_RESULT(state, zipResult) {
    state.downloadZipResult = zipResult
  },
  SAVE_FILE_MANAGER_ITEM(state, data) {
    state.fileManagerItem = data
  },
  REFRESH_FILE_MANAGER(state) {
    state.refreshFileManagerCounter++
  },
  OPEN_FILE_MANAGER_EDIT_COMPONENT(state) {
    state.isOpenFileManagerEditComponent = true
  },
  CLOSE_FILE_MANAGER_EDIT_COMPONENT(state) {
    state.isOpenFileManagerEditComponent = false
  }
}
